import { combineReducers } from "redux";

import tokenReducer from "./tokenReducer.js";
import userReducer from "./userReducer.js";

const allReducers = combineReducers({
  token: tokenReducer,
  user: userReducer,
});

export default allReducers;