import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../services/api';
import api_amr from '../../services/api_amr';

import { CircleFlag } from 'react-circle-flags'
import { Chart } from 'react-google-charts';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import { getToken, checarPermissao } from '../../utils/utils';
import { func } from "prop-types";

const LoadingIndicator = (props) => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        promiseInProgress && (
            <div
                style={{
                    width: '100%',
                    height: '100',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
            </div>
        )
    );
};

function AssociacaoProdutosPadrao(props) {
    const [permitido, setPermitido] = useState(false)
    const [dustrol, setDustrol] = useState(false);
    const [inibidor, setInibidor] = useState(false);
    const [porto, setPorto] = useState('')
    const [portos, setPortos] = useState('')
    const [dolar, setDolar] = useState('')
    const [frete, setFrete] = useState(0)
    const [N, setN] = useState(0)
    const [P2O5, setP2O5] = useState(0)
    const [K2O, setK2O] = useState(0)
    const [jurousd, setJurousd] = useState(0)
    const [jurobr, setJurobr] = useState(0)
    const [vencimento, setVencimento] = useState((new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).toLocaleDateString('pt-BR'))
    const [pref, setPref] = useState([]);
    const [rem, setRem] = useState([]);
    const [micro, setMicro] = useState([]);
    const [materias, setMaterias] = useState('');
    const [micronutri, setMicronutri] = useState('');
    const [lastUpdate, setLastUpdate] = useState('');
    const [formulas, setFormulas] = useState('');
    const [formulas_default, setFormulas_default] = useState('')
    const [nomeProdutoLista, setNomeProdutoLista] = useState(0)
    const [idProduto, setIdProduto] = useState(0)
    const [associa, setAssocia] = useState([])
    const [NPKArray, setNPKArray] = useState('')

    const [showJuros, setShowJuros] = useState(false)
    const [showObgRemDust, setShowObgRemDust] = useState(false)
    const [showMicro, setShowMicro] = useState(false)


    const classes = useStyles();

    useEffect(() => {
        async function fetchMyAPI() {

            setPermitido(await checarPermissao('associacao_produtos_padrao', props))
            const response_materias = await api_amr.get('/return_indice_materias', { headers: { 'x-api-key': getToken() } });
            setMaterias(response_materias.data);
            // Retorna todos os produtos
            const reponse_produtos_lista = await api_amr.get('/return_produtos_padrao', { params: { 'all': 0 }, headers: { 'x-api-key': getToken() } });
            setNomeProdutoLista(reponse_produtos_lista.data)


            const response_return_portos = await api_amr.get('/return_portos', { params: { 'all': 1 }, headers: { 'x-api-key': getToken() } });
            setPortos(response_return_portos.data)

            const respose_dolar_hora = await api.get('/DolarApi');

            setDolar(respose_dolar_hora.data)

            const respose_update = await api_amr.get('/return_last_update', { headers: { 'x-api-key': getToken() } });
            setLastUpdate(respose_update.data);

            const response_micronutri = await api_amr.get('/return_micro_nutrientes', { headers: { 'x-api-key': getToken() } });
            setMicronutri(response_micronutri.data);

        }
        fetchMyAPI();
    }, []);

    async function getFormulas() {
        setFormulas('')
        let auxPref = [];
        let auxRem = [];
        let auxMicro = [];
        pref.forEach((index) => (auxPref = [...auxPref, index.id]));
        rem.forEach((index) => (auxRem = [...auxRem, index.id]));
        Object.keys(micro).map((index) => (auxMicro = [...auxMicro, index + ':' + micro[index]])); // funciona com object.keys mas com foreach não, vai sbaer o pq dessa bosta



        if (N === -1 && P2O5 === -1 && K2O === -1) {
            alert('Selecione os nutrientes!');
        } else if (porto === '') {
            alert('Selecione o porto de Origem!');
        } else {

            const response_formulas = await api_amr.get('/returnFormulas', {
                params: {
                    nitrogenio: N,
                    p2o5: P2O5,
                    k2o: K2O,
                    'porto': porto.id,
                    dolar: dolar,
                    frete: frete,
                    pref: auxPref.toString(),
                    rem: auxRem.toString(),
                    add_micro: auxMicro.join(['-']),
                    dustrol: dustrol.toString(),
                    inibidor: inibidor.toString(),
                    'valorJuroUSD': jurousd === '' ? 0.0 : jurousd,
                    'valorJuroBR': jurobr === '' ? 0.0 : jurobr,
                    'dataVencimento': vencimento
                }, headers: { 'x-api-key': getToken() }
            });
            // console.log(response_formulas.data)
            const response_formulas_default = await api.get('returnFormulasDefault', {
                params: {
                    nitrogenio: N,
                    p2o5: P2O5,
                    k2o: K2O,
                    porto: porto,
                    frete: frete,
                    dolar: dolar,
                },
            });
           
            setFormulas('');
            setFormulas_default('');
            // Caso retorne a formula ele associa o NPK a um produto
            if (response_formulas.data !== 'NOK') AssociaNPK()

            if (response_formulas.data === 'NOK') {
                alert('Não foi possível gerar formulas com essas configurações!');
            } else {
                setFormulas(response_formulas.data);
                if (response_formulas_default.data !== 'NOK') {
                    setFormulas_default(response_formulas_default.data);
                }
            }
        }

    }

    async function AssociaNPK() {
        const response_associaNPK = await api_amr.post('/associa_NPK', { 'id_produto': idProduto.id, 'n': N, 'p': P2O5, 'k': K2O }, { headers: { 'x-api-key': getToken() } })

    }
    async function Associafechamento(array_formulas) {
        const response_associafechamento = await api_amr.post('/associa_fechamento', { 'id_produto': idProduto.id, array_formulas }, { headers: { 'x-api-key': getToken() } })
        if (response_associafechamento.data === 'OK1') {
            alert('Formula associada com sucesso')
        }
        else if (response_associafechamento.data === 'OK2') {
            alert('Associação de produto substituida')
        }
        else {
            alert('Erro ao criar associação, entre em contato com o responsável pelo sistema')
        }
    }


    function montaMicro(item, val) {
        let aux = micro;
        if (val >= 50) {

            aux[item] = val;
            setMicro(aux);
        } else {
            aux[item] = 0;
            setMicro(aux);
        }

    }

    function montaArray(formulas_item) {

        let array_formulas = []
        Object.keys(formulas_item).map((item_produto, index_produto) =>
            (item_produto.includes('_peso') & formulas_item[item_produto] !== 0) ?
                array_formulas.push([item_produto.split('#')[0], formulas_item[item_produto]])
                : null)
       
        Associafechamento(array_formulas)

        return array_formulas
    }





    return (
        permitido ?



            <div className={classes.container} >
                <div>
                    <h2 className={classes.inputh1}> Selecionar Produtos Padrão</h2>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {/* Inputs esquerda */}
                    <div>
                        <b className={classes.input} >Matérias-primas atualizadas em: {lastUpdate}</b>
                        <div className={classes.inputsDiv} >{/*Margin top 10 para ajeitar o primiero elemento */}
                            <div style={{ paddingRight: 28 }} >



                                <div >
                                    <Tooltip title="Porto" placement="top-end"   >
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <b className={classes.input}>Porto:</b>
                                            <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                        </div>
                                    </Tooltip>
                                </div>


                                <Autocomplete

                                    options={portos}
                                    getOptionLabel={(option) => (option.id + '-' + option.nome_porto + '-' + option.cidade + '/' + option.UF)}
                                    className={classes.autoCompleteFieldBig}
                                    onChange={(e, v) => setPorto(v)}

                                    classes={{
                                        input: classes.input,

                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder="Selecionar Porto"
                                        variant='outlined'
                                        value={porto}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />}
                                />

                            </div>
                            <div style={{ paddingRight: 28 }} >

                                <div >
                                    <Tooltip title="Valor do dolar em R$ a ser utilizado para calculo do valor final em Reais" placement="top-end" >
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <b className={classes.input}>Dólar:</b>
                                            <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                        </div>
                                    </Tooltip>
                                </div>

                                <TextField
                                    placeholder="Dólar $"
                                    variant='outlined'
                                    onChange={(event) => setDolar(event.target.value)}
                                    value={dolar}
                                    className={classes.autoCompleteField}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                />
                            </div>
                            <div style={{ paddingRight: 28 }} >

                                <div >
                                    <Tooltip title="Valor em R$ do frete, da origem do produto até o destino final" placement="top-end" >
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <b className={classes.input}>Frete:</b>
                                            <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                        </div>
                                    </Tooltip>
                                </div>

                                <TextField
                                    placeholder="Valor Frete R$"
                                    variant='outlined'
                                    onChange={(event) => setFrete(event.target.value)}
                                    className={classes.autoCompleteField}
                                    value={frete}
                                    InputProps={{

                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}

                                />


                            </div>
                        </div>
                        <div className={classes.inputsDiv}>
                            <div style={{ paddingRight: 28 }}>
                                <div >
                                    <Tooltip title="Produto Padrão" placement="top-end" >
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <b className={classes.input}>Produto Padrão</b>
                                            <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                        </div>
                                    </Tooltip>
                                </div>
                                <Autocomplete
                                    key='produto'
                                    options={nomeProdutoLista}
                                    getOptionLabel={(option) => (option.id + ' - ' + option.nome_produto)}
                                    className={classes.autoCompleteFieldBig}

                                    onChange={(e, v) => (setIdProduto(v), console.log({ idProduto }))}

                                    classes={{
                                        input: classes.input,

                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder="Selecionar Produto"
                                        variant='outlined'
                                        value={idProduto}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />}
                                />
                            </div>
                            <div style={{ paddingRight: 28 }} >
                                <div >
                                    <Tooltip title="Quantidade de Nitrogênio da fórmula a ser calculada seguindo o modelo N-P-K, por exemplo: em um produto 15-12-10 o nitrôgenio seria 15" placement="top-end" >
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <b className={classes.input}>Nitrogênio (N)</b>
                                            <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                        </div>
                                    </Tooltip>
                                </div>
                                <TextField
                                    required
                                    key='N'
                                    className={classes.autoCompleteField}
                                    variant='outlined'
                                    placeholder={(idProduto != null ? idProduto.nitrogenio : '') && (idProduto.nitrogenio == -1 ? '' : idProduto.nitrogenio)}
                                    type='number'
                                    onChange={(event) => setN(event.target.value < 0 ? 0 : event.target.value)}

                                    InputProps={{

                                        disableUnderline: true,
                                        inputProps: { min: 0 },
                                        classes: { notchedOutline: classes.noBorder }
                                    }}

                                />

                            </div>
                            <div style={{ paddingRight: 28 }} >
                                <div >
                                    <Tooltip title="Quantidade de Fósforo (P2O5) da fórmula a ser calculada seguindo o modelo N-P-K, por exemplo: em um produto 15-12-10 o Fósforo seria 12" placement="top-end" >
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <b className={classes.input}>Fósforo (P2O5)</b>
                                            <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                        </div>
                                    </Tooltip>
                                </div>
                                <TextField
                                    // key={P2O5}
                                    key='P2O5'
                                    className={classes.autoCompleteField}
                                    variant='outlined'
                                    placeholder={(idProduto != null ? idProduto.fosforo : '') && (idProduto.fosforo == -1 ? '' : idProduto.fosforo)}
                                    type='number'
                                    onChange={(event) => setP2O5(event.target.value < 0 ? 0 : event.target.value)}

                                    InputProps={{

                                        disableUnderline: true,
                                        inputProps: { min: 0 },
                                        classes: { notchedOutline: classes.noBorder }
                                    }}

                                />

                            </div>
                            <div style={{ paddingRight: 28 }} >

                                <div >
                                    <Tooltip title="Quantidade de Potássio (K2O) da fórmula a ser calculada seguindo o modelo N-P-K, por exemplo: em um produto 15-12-10 o Potássio seria 10" placement="top-end" >
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <b className={classes.input}>Potássio (K2O)</b>
                                            <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                        </div>
                                    </Tooltip>
                                </div>
                                <TextField
                                    key='K2O'
                                    className={classes.autoCompleteField}
                                    placeholder={(idProduto != null ? idProduto.potassio : '') && (idProduto.potassio == -1 ? '' : idProduto.potassio)}
                                    variant='outlined'

                                    type='number'
                                    onChange={(event) => setK2O(event.target.value < 0 ? 0 : event.target.value)}
                                    InputProps={{

                                        disableUnderline: true,
                                        inputProps: { min: 0 },
                                        classes: { notchedOutline: classes.noBorder }
                                    }}

                                />
                            </div>
                        </div>
                        {showJuros === true ?
                            < div className={classes.inputsDiv}>
                                <div style={{ paddingRight: 28 }} >

                                    <div >
                                        <Tooltip title="Juro USD" placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Juro USD</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>

                                    <TextField
                                        className={classes.autoCompleteField}
                                        placeholder="Digite..."
                                        variant='outlined'
                                        onChange={(event) => setJurousd(event.target.value)}
                                        value={jurousd}
                                        InputProps={{

                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />

                                </div>
                                <div style={{ paddingRight: 28 }} >
                                    <div >
                                        <Tooltip title="Juro BRL" placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Juro BRL</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>

                                    <TextField
                                        className={classes.autoCompleteField}
                                        placeholder="Digite..."
                                        variant='outlined'
                                        value={jurobr}
                                        onChange={(event) => setJurobr(event.target.value)}
                                        InputProps={{

                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />


                                </div>
                                <div style={{ paddingRight: 28 }} >
                                    <div >
                                        <Tooltip title="Data de Vencimento" placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Data de Vencimento</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        className={classes.autoCompleteField}
                                        placeholder="Digite..."
                                        variant='outlined'
                                        value={vencimento}
                                        onChange={(event) => setVencimento(event.target.value)}
                                        InputProps={{

                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />


                                </div>
                            </div> : null}
                        {showObgRemDust === true ?
                            <div className={classes.inputsDiv}>
                                <div style={{ paddingRight: 28 }} >

                                    <div >
                                        <Tooltip title="Matérias-primas que devem ser utilizadas para fechamento " placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Matérias obrigatórias nas fórmulas</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>


                                    <Autocomplete
                                        multiple

                                        getOptionLabel={(option) => option.id + '-' + option.abrev}
                                        className={classes.autoCompleteFieldBig}
                                        options={materias}
                                        onChange={(e, v) => setPref(v)}

                                        classes={{
                                            input: classes.input,

                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            placeholder="Selecionar"
                                            variant='outlined'
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                classes: { notchedOutline: classes.noBorder }
                                            }}

                                        />}
                                    />

                                </div>
                                <div style={{ paddingRight: 28 }} >

                                    <div >
                                        <Tooltip title="Matérias-primas que não deve ser utilizadas no calculo de fechamento" placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Matérias para remover das fórmulas</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>

                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option) => option.id + '-' + option.abrev}
                                        className={classes.autoCompleteFieldBig}
                                        options={materias}
                                        onChange={(e, v) => setRem(v)}
                                        classes={{
                                            input: classes.input,

                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            placeholder="Selecionar"
                                            variant='outlined'
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                classes: { notchedOutline: classes.noBorder }
                                            }}

                                        />}
                                    />

                                </div>

                            </div> : null}
                        <div className={classes.inputsDiv} style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ paddingRight: 28 }} >


                                    <Checkbox
                                        checked={dustrol}
                                        onChange={() => setDustrol(!dustrol)}


                                    />
                                    <b className={classes.inputCheckbox}>Inserir Dustrol</b>
                                </div>
                                <div style={{ paddingRight: 28 }} >

                                    <Checkbox
                                        checked={inibidor}
                                        onChange={() => setInibidor(!inibidor)}
                                        classes={{
                                            root: classes.root,
                                            checked: classes.checked,
                                        }}
                                    />
                                    <b className={classes.inputCheckbox}>Inserir inibidor NBPT</b>
                                </div>
                            </div>
                            <div >
                                <div style={{ paddingRight: 28 }} >
                                    <Checkbox
                                        checked={showObgRemDust}
                                        onChange={() => setShowObgRemDust(!showObgRemDust)}
                                    />
                                    <b className={classes.inputCheckbox}>Remoção e obrigatoriedade de matérias</b>
                                </div>
                                <div style={{ paddingRight: 28 }}>
                                    <Checkbox
                                        checked={showJuros}
                                        onChange={() => setShowJuros(!showJuros)}
                                    />
                                    <b className={classes.inputCheckbox}>Adicionar Juros</b>
                                </div>
                                <div style={{ paddingRight: 28 }} >
                                    <Checkbox
                                        checked={showMicro}
                                        onChange={() => setShowMicro(!showMicro)}
                                    />
                                    <b className={classes.inputCheckbox}>Adicionar Micronutrientes</b>
                                </div>
                                <div className={classes.calcDiv}>
                                    <Button onClick={() => trackPromise(getFormulas())} variant="contained" className={classes.calcButton}> Calcular</Button>
                                </div>
                            </div>

                        </div>

                    </div>
                    {/* Fim Inputs esquerda */}
                    {/* Inputs Direita */}
                    {showMicro === true ?
                        <div style={{ marginLeft: 15 }}>
                            <b className={classes.input} style={{ position: 'relative' }}>Micronutrientes</b>
                            <div style={{ fontFamily: 'Poppins', fontSize: '12px' }}>(Valor mínimo: 50 kg)</div>
                            {Object.keys(micronutri.slice(0, micronutri.length / 2)).map((item, index) => (
                                <div className={classes.inputsDiv} id={item} key={item}>
                                    <div className={classes.nutriDiv} >
                                        <div >
                                            <Tooltip title="Matérias-primas que não deve ser utilizadas no calculo de fechamento" placement="top-end" >
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <b className={classes.input}>{micronutri[item * 2].descNome === 'SSP micrado - Base Micrada' ? 'BM 02-18-00+2%Zn +1%B +1%Cu +2%Mn' : micronutri[item * 2].descNome}</b>
                                                    <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                                </div>
                                            </Tooltip>
                                        </div>

                                        <TextField
                                            placeholder='0'
                                            className={classes.autoCompleteField}
                                            variant='outlined'
                                            id={micronutri[item * 2].abrev}
                                            onChange={(evt) => montaMicro(micronutri[item * 2].id, evt.target.value)}
                                            InputProps={{

                                                disableUnderline: true,
                                                classes: { notchedOutline: classes.noBorder }
                                            }}

                                        />
                                    </div>
                                    <div className={classes.nutriDiv} >
                                        <div >
                                            <Tooltip title="Matérias-primas que não deve ser utilizadas no calculo de fechamento" placement="top-end" >
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <b className={classes.input}>{micronutri[item * 2 + 1].descNome === 'SSP micrado - Base Micrada' ? 'BM 02-18-00+2%Zn +1%B +1%Cu +2%Mn' : micronutri[item * 2 + 1].descNome}</b>
                                                    <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <TextField
                                            placeholder='0'
                                            className={classes.autoCompleteField}
                                            variant='outlined'
                                            id={micronutri[item * 2 + 1].abrev}
                                            onChange={(evt) => montaMicro(micronutri[item * 2 + 1].id, evt.target.value)}
                                            InputProps={{

                                                disableUnderline: true,
                                                classes: { notchedOutline: classes.noBorder }
                                            }}

                                        />
                                    </div>
                                </div>
                            ))}

                        </div> : null}
                    {/* Inputs Direita */}
                </div>

                {
                    formulas !== '' ?
                        <div>
                            <div >
                                <h2 className={classes.inputh1}>Fechamentos calculados</h2>

                            </div>
                            <div >
                                {Object.keys(formulas).map((item, index) =>

                                    <div key={item} style={{ marginTop: 20, width: 300, height: '100%', borderRadius: 6, display: 'flex', flexDirection: 'column', boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)' }}>


                                        <h3 className={classes.inputh1} style={{ textAlign: "center" }}>Matérias-primas</h3>
                                        {Object.keys(formulas[item]).map((item_produto, index_produto) =>

                                            (item_produto.includes('_peso') & formulas[item][item_produto] !== 0) ?

                                                <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'row' }} kye={item_produto} >
                                                    <p className={classes.inputForm}> {((item_produto.split('#')[0] !== item_produto ? item_produto.split('#')[1] : item_produto.split('_')[0]).split('_')[0])} </p>
                                                    <p className={classes.inputForm}>  {formulas[item][item_produto]} kg</p>


                                                </div> : null

                                        )}
                                        <Button
                                            style={{ margin: 2, alignSelf: 'center', flexDirection: 'row', paddingBottom: 5 }}
                                            onClick={() => montaArray(formulas[item])}
                                            variant="contained"
                                            className={classes.calcButton}>
                                            Associar Fechamento
                                        </Button>





                                    </div>
                                )}
                            </div>
                        </div>
                        : <LoadingIndicator />
                }

            </div >
            : <LoadingIndicator />
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 100,
    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    noBorder: {
        border: "none",
    },
    autoCompleteField: {
        marginTop: 10,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 10,
        width: 360,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    inputCheckbox: {

        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000'
    },
    inputh1: {
        fontFamily: 'Poppins',

        color: '#383838'
    },
    root: {
        "&$checked": {
            color: "black",
            backgroundColor: 'white'
        }
    },
    checked: {
        borderColor: 'black'
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
    calcDiv: {
        marginTop: 40,
        width: '146px',

        height: 45
    },
    nutriDiv: {
        paddingRight: 28,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 228
    },
    inputForm: {
        width: '50%',
        fontFamily: 'Poppins',
        color: '#383838'
    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    }
}));

export default AssociacaoProdutosPadrao;
