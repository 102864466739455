import React, { useState, useEffect } from 'react'
import GridDashboard from './EstruturaDashboard360/GridDashboard'
import PopoverAddComponent from './EstruturaDashboard360/PopoverAddComponent';
import { getToken } from 'utils/utils';
import api_amr from 'services/api_amr';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from "@material-ui/core/styles";
import TopBar from './EstruturaDashboard360/TopBar';
import ComponentPreview from './EstruturaDashboard360/ComponentPreview';
import ComponentPreviewTabelaEditavel from './EstruturaDashboard360/ComponentPreviewTabelaEditavel';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

export default function Dashboard360() {
    const handle = useFullScreenHandle();
    const [layout, setLayout] = useState([])

    const [anchorEl, setAnchorEl] = useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const [currentColumn, setCurrentColumn] = useState(0)
    const [currentRow, setCurrentRow] = useState(0)
    const [idDashboard, setIdDashboard] = useState('')
    const [dashboardName, setDashboardName] = useState('')

    const [adding, setAdding] = useState(false)
    const [saving, setSaving] = useState(false)

    const [openComponentPreview, setOpenComponentPreview] = useState(false)

    const [selectedComponent, setSelectedComponent] = useState('')
    const [locked, setLocked] = useState(false)
    const [LayoutChanged, setLayoutChanged] = useState(false)

    const classes = useStyles()

    useEffect(() => {
        fetchApi()
    }, [])

    const fetchApi = async () => {
        const dashboardResponse = await api_amr.get("/return_layoutDashboard", { headers: { 'x-api-key': getToken() } })
        setLayout(dashboardResponse.data.components)
        setIdDashboard(dashboardResponse.data.id_dashboard)
        setDashboardName(dashboardResponse.data.dashboard_name)
    }

    const handleLayoutChange = (newLayout) => {
        setLayoutChanged(!LayoutChanged)
        const updatedLayout = layout.map(item => {
            const matchingItem = newLayout.find(newItem => newItem.i === item.i)
            if (matchingItem) {
                return {
                    ...item,
                    w: matchingItem.w,
                    h: matchingItem.h,
                    x: matchingItem.x,
                    y: matchingItem.y,
                }
            }
            return item
        })
        setLayout(updatedLayout)
    }

    function getSizes(selectedComponent, selectedData) {
        let stringKey = ''
        if (selectedData !== '') {
            stringKey = `${selectedComponent}_${selectedData}`

        } else {
            stringKey = `${selectedComponent}`
        }

        const sizes = {
            card_historico_preco: {
                w: 5,
                h: 1,
            },
            card_relacao_troca: {
                w: 2,
                h: 2,
            },
            card_dolar: {
                w: 1,
                h: 1,
            },
            tabela_historico_preco: {
                w: 6,
                h: 2,
            },
            tabela_relacao_troca: {
                w: 3,
                h: 3,
            },
            tabela_futuros: {
                w: 3,
                h: 3
            },
            grafico_historico_preco: {
                w: 3,
                h: 2,
            },
            grafico_relacao_troca: {
                w: 3,
                h: 2,
            },
            grafico_relacao_troca_formulacoes: {
                w: 3,
                h: 2,
            },
            grafico_futuros: {
                w: 3,
                h: 2
            },
            note: {
                w: 2,
                h: 1,
            },
            tabela_editavel_historico_preco: {
                w: 6,
                h: 3,
            },
            previsao: {
                w: 3,
                h: 3,
            },
            imagem: {
                w: 3,
                h: 3,
            },
            mapa: {
                w: 3,
                h: 3,
            },
            tabela_formulas_salvas: {
                w: 7,
                h: 2,
            }
        }

        return sizes[stringKey]
    }

    const handleAddComponent = async (selectedComponent, selectedData, selectedParams, componentTitle) => {
        setAdding(true)

        const newLayout = [...layout, {
            i: uuidv4(),
            x: currentColumn,
            y: currentRow,
            w: getSizes(selectedComponent, selectedData).w,
            h: getSizes(selectedComponent, selectedData).h,
            component_name: selectedData,
            component_type: selectedComponent,
            params: selectedParams,
            component_title: componentTitle
        }]

        setLayout(newLayout)

        let nextColumn = currentColumn + 4
        if (nextColumn >= 12) {
            setCurrentColumn(0)
            setCurrentRow(currentRow + 1)
        } else {
            setCurrentColumn(nextColumn)
        }

        await handleSaving(newLayout)
        setOpenComponentPreview(false)
        setAdding(false)
    }

    const handleSaving = async (newLayout) => {
        setSaving(true)
        await api_amr.post("/save_layoutDashboard", {
            "componentes": newLayout,
            "id_dashboard": idDashboard
        }, { headers: { 'x-api-key': getToken() } })
        fetchApi()
        setSaving(false)
    }

    const handleRemove = async (component_id) => {
        await api_amr.post("/remove_dashboard_component", {
            "component_id": component_id
        }, { headers: { 'x-api-key': getToken() } })
        fetchApi()
    }

    const handlePopoverComponentSelect = (component) => {
        setSelectedComponent(component)
        setOpenComponentPreview(true)
    }

    const handlePopoverOpen = (anchorElement) => {
        setAnchorEl(anchorElement);
        setIsPopoverOpen(true);
    }

    const handlePopoverClose = () => {
        setIsPopoverOpen(false);
    }

    const handleLock = () => {
        setLocked(!locked)
        handleSaving(layout)
    }
    const handleClosePreview = () => {
        setOpenComponentPreview(false)
        setSelectedComponent('')
    }


    return (
        <div>
            <div>
                <PopoverAddComponent
                    anchorEl={anchorEl}
                    isOpen={isPopoverOpen}
                    onClose={handlePopoverClose}
                    onComponentSelect={handlePopoverComponentSelect}
                />
            </div>

            <div>
                {selectedComponent !== 'tabelaEditavel' ?
                    <ComponentPreview
                        adding={adding}
                        onAddComponent={handleAddComponent}
                        onClose={handleClosePreview}
                        open={openComponentPreview}
                        popoverComponent={selectedComponent}
                    />
                    :
                    <ComponentPreviewTabelaEditavel
                        adding={adding}
                        onAddComponent={handleAddComponent}
                        onClose={() => setOpenComponentPreview(false)}
                        open={openComponentPreview}
                        popoverComponent={selectedComponent}
                    />}
            </div>

            <TopBar
                saving={saving}
                idDashboard={idDashboard}
                dashboardName={dashboardName}
                onDashboardNameChange={(value) => setDashboardName(value)}
                onHandlePopoverOpen={handlePopoverOpen}
                locked={locked}
                onLock={handleLock}
                handleFullScreen={handle}
            />

            <FullScreen handle={handle} className={classes.gridWrapper}>
                <GridDashboard
                    layout={layout}
                    locked={locked}
                    onRemoveComponent={handleRemove}
                    onLayoutChange={handleLayoutChange}
                    LayoutChanged={LayoutChanged}
                />
            </FullScreen>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    gridWrapper: {
        marginTop: 69,
        background: 'rgb(238,238,238)',
        backgroundImage: 'radial-gradient(50% 50% at 22% 44%, #F2FFB1FF 0%, #073AFF00 98%),radial-gradient(18% 28% at 18% 71%, #FFFFFF59 6%, #073AFF00 100%),radial-gradient(70% 53% at 36% 76%, #DDF0CBFF 0%, #073AFF00 100%),radial-gradient(42% 53% at 15% 94%, #FFFFFFFF 7%, #073AFF00 100%),radial-gradient(42% 53% at 34% 72%, #FFFFFFFF 7%, #073AFF00 100%),radial-gradient(18% 28% at 35% 87%, #FFFFFFFF 7%, #073AFF00 100%),radial-gradient(31% 43% at 7% 98%, #FFFFFFFF 24%, #073AFF00 100%),radial-gradient(21% 37% at 72% 23%, #D9F5A2D1 24%, #073AFF00 100%),radial-gradient(35% 56% at 91% 74%, #FFFFFFFF 9%, #073AFF00 100%),radial-gradient(74% 86% at 71% 90%, #F0FFDBFF 23%, #07FFE114 41%),linear-gradient(125deg, #FFFFFFFF 65%, #a3cc51 100%)',
    }
}))
