import React, { useState, useEffect } from 'react';
import { TextField, Button, makeStyles, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from '../../services/api_amr';
import { getToken } from '../../utils/utils';
import ReactECharts from 'echarts-for-react';
import { ResizableBox } from 'react-resizable';
import logoImage from '../../assets/img/logo_amr.png';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  formControl: {
    marginRight: theme.spacing(3),
    minWidth: 200,
  },
  chartContainer: {
    position: 'relative',
    padding: theme.spacing(2),
    border: '2px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#f7f7f7',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
}));

const ChartsEntregaBrasil = () => {
  const classes = useStyles();
  const [culturas, setCulturas] = useState([]);
  const [formData, setFormData] = useState({ cultura: null });
  const [chartData, setChartData] = useState([]);
  const [weeksLabels, setWeeksLabels] = useState([]);
  const [chartWidth, setChartWidth] = useState(600);
  const [seriesDataId4, setSeriesDataId4] = useState([]);
  const [seriesDataId3, setSeriesDataId3] = useState([]);
  const [seriesDataId2, setSeriesDataId2] = useState([]);

  useEffect(() => {
    const fetchCulturas = async () => {
      const response = await api.get('/read_culturas', { headers: { 'x-api-key': getToken() } });
      setCulturas(response.data);
    };
    fetchCulturas();
  }, []);

  const handleCulturaChange = (event, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, cultura: value }));
  };

  const loadChartData = async (cultura) => {
    try {
      const response = await api.get('/read_percentual_acumulado', {
        params: { cultura: cultura },
        headers: { 'x-api-key': getToken() },
      });

      if (response.data && response.data.length > 0) {
        const chartData = [];
        const weeksLabels = [];
        const id4Data = [];
        const id3Data = [];
        const id2Data = []
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        response.data.forEach(item => {
          chartData.push(item.percentual_acumulado);
          const date = new Date(item.ano + 1, 0, (item.semana - 1) * 7);
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          console.log(year)

          if (item.id_ano_safra === 4) {
            if (year === currentYear) {
              id4Data.push(item.percentual_acumulado);
            } else if (year !== currentYear && month <= currentMonth) {
              id4Data.push(item.percentual_acumulado);
            }
            else {
              id4Data.push(null); // Manter a posição original
            }

          } else if (item.id_ano_safra === 5) {
            if (year !== 2022 && month >= 7) {
              id2Data.push(item.percentual_acumulado);
            } 
            else {
              id2Data.push(null); // Manter a posição original
            }
           
          } else if (item.id_ano_safra === 3) {
            id3Data.push(item.percentual_acumulado);
            const date = new Date(item.ano + 1, 0, (item.semana - 1) * 7);
            weeksLabels.push(date.toISOString().split('T')[0]);
          }
        });

        setChartData(chartData);
        setWeeksLabels(weeksLabels);
        setSeriesDataId4(id4Data);
        setSeriesDataId3(id3Data);
        setSeriesDataId2(id2Data)
      } else {
        console.error('No data received from the server.');
      }
    } catch (error) {
      console.error('Error loading chart data:', error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    loadChartData(formData.cultura?.id_cultura);
  };

  const getChartOptions = () => {
    const legendNamesAndColors = [
      { name: 'Ano Safra 23/24', color: '#0839fd' }, // Azul moderno
      { name: 'Ano Safra 22/23', color: '#866320' }, // Marrom sofisticado
      { name: 'Ano Safra 21/22', color: 'yellow' }, // Marrom sofisticado

    ];

    return {
      title: {
        text: 'Percentual Acumulado por Semana',
        subtext: formData.cultura ? `Cultura: ${formData.cultura.nome_cultura}` : 'Todas as Culturas',
        left: 'center',
        textStyle: {
          fontSize: 16,
          fontWeight: 'bold',
        },
        subtextStyle: {
          fontSize: 12,
          color: '#666',
        },
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderColor: '#ccc',
        borderWidth: 1,
        textStyle: { color: '#333' },
        formatter: function (params) {
          if (params.length === 0) return '';
          let tooltip = `${new Date(params[0].value[0]).toLocaleDateString()}<br/>`;
          params.forEach(param => {
            tooltip += param.marker + param.seriesName + ': ' + (param.value[1] || 0).toFixed(2) + '%<br/>';
          });
          return tooltip;
        },
      },
      xAxis: {
        type: 'time',
        axisLabel: {
          formatter: function (value) {
            const date = new Date(value);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return `${date.getDate()} ${monthNames[date.getMonth()]}`;
          },
          rotate: 45,
          textStyle: {
            color: '#333', // Cor dos escritos dos eixos
          },
        },
        splitNumber: 15,
        splitLine: { show: false },
      },
      yAxis: {
        type: 'value',
        max: 100,
        axisLabel: {
          formatter: '{value}%',
          textStyle: {
            color: '#333', // Cor dos escritos dos eixos
          },
        },
        splitLine: { lineStyle: { color: '#eee' } },
      },
      series: [
        {
          name: 'Ano Safra 23/24',
          data: seriesDataId4.map((value, index) => [weeksLabels[index], value]),
          type: 'line',
          smooth: true,
          symbol: 'none',
          itemStyle: { color: 'red' }, // Cor saturada
          lineStyle: { width: 3 },
        },
        {
          name: 'Ano Safra 22/23',
          data: seriesDataId3.map((value, index) => [weeksLabels[index], value]),
          type: 'line',
          smooth: true,
          symbol: 'none',
          itemStyle: { color: 'green' }, // Cor saturada
          lineStyle: { width: 3 },
        },
        {
          name: 'Ano Safra 21/22',
          data: seriesDataId2.map((value, index) => [weeksLabels[index], value]),
          type: 'line',
          smooth: true,
          symbol: 'none',
          itemStyle: { color: 'yellow' }, // Cor saturada
          lineStyle: { width: 3 },
        },
      ],
      legend: {
        data: legendNamesAndColors.map(item => item.name),
        textStyle: { color: '#333' },
        top: '10%',
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '15%',
        containLabel: true,
      },
      graphic: [
        {
          type: 'image',
          id: 'logo',
          left: 'center',
          top: 'center',
          bounding: 'raw',
          z: -10,
          style: {
            image: logoImage,
            opacity: 0.4,
            aspect: 'contain',
          },
        },
      ],
    };
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <Autocomplete
            options={culturas}
            getOptionLabel={(option) => option.nome_cultura}
            value={formData.cultura}
            onChange={handleCulturaChange}
            renderInput={(params) => <TextField {...params} label="Cultura" variant="outlined" />}
            style={{ width: 200, marginRight: '10px' }}
          />
          <Button type="submit" variant="contained" color="primary">Carregar dados</Button>
        </div>
      </form>
      <ResizableBox width={chartWidth} height={400} onResizeStop={(e, data) => setChartWidth(data.size.width)}>
        <Paper className={classes.chartContainer} style={{ width: chartWidth }}>
          <ReactECharts option={getChartOptions()} style={{ height: 400 }} />
        </Paper>
      </ResizableBox>
    </div>
  );
};

export default ChartsEntregaBrasil;
