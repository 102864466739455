import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import api_amr from 'services/api_amr';
import { getToken } from 'utils/utils';

import logoLoader from 'assets/img/loader_gif.gif';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const OverLayLogoAMRcarregando = () => {
    /**
     * Componente a ser renderizado como overlay customizado caso a tabela esteja carregando
     */
    return (
        <div style={{ width: 'auto', height: '70%', position: 'relative' }}>
            <img src={logoLoader} alt="AMRLoader" style={{ width: 'auto', height: 'auto' }} />
        </div>
    );
};


export default function HistPrecoTabelaEditavel({ uuid, getConfig, getHeaders, selectedColumns, enableColumnSelection }) {
    /**
     * Componente Tabela Editalvel utilizando a biblioteca AG-Grid
     * Para mais informações consulte a documentação em: https://www.ag-grid.com/react-data-grid/
     */

    // Variaveis/States
    const gridRef = useRef(null);
    const [data, setData] = useState([]);
    const [colunaDefs, setColunaDefs] = useState([]);

    const myLocaleText = {
        // outras traduções aqui...
        // Para o filtro de texto
        contains: 'Contém',
        notContains: 'Não contém',
        startsWith: 'Começa com',
        endsWith: 'Termina com',
        equals: 'Igual',
        notEqual: 'Diferente',
        blank: 'Vazio',
        notBlack: 'Preenchido',

        // Para o filtro numérico
        lessThan: 'Menor que',
        greaterThan: 'Maior que',
        lessThanOrEqual: 'Menor ou igual',
        greaterThanOrEqual: 'Maior ou igual',
        inRange: 'No intervalo',
    };

    useEffect(() => {
        handleGridReady();
        if (enableColumnSelection) {
            updateColumnVisibility();
        }
    }, [selectedColumns]);

    const updateColumnVisibility = () => {
        if (gridRef.current && gridRef.current.columnApi) {
            colunaDefs.forEach((col) => {
                const isVisible = !selectedColumns.some(selectedCol => selectedCol.field === col.field);
                gridRef.current.columnApi.setColumnVisible(col.field, isVisible);
            });
        } else {
            console.log('Error: gridRef or columnApi is not yet available');
        }
    };


    const handleGridReady = async () => {
        /*
         * Esta função é executada quando o evento onGridReady ocorre.
         * O evento ocorre quando a tabela é renderizada, portanto serve como um useEffect do componente AG-Grid
         */

        // Chamada de dados e definição das colunas no back
        try {
            const response = await api_amr.get('/return_component_histPreco', {
                params: {
                    'formato': 'tabela_editavel',
                    'uuid': uuid,
                },
                headers: { 'x-api-key': getToken() }
            });
            /*
            * Antes de setar as configurações é necessário altera-la para adicionar um formatador de valores
            * O proprio ag-grid tem um propriedade para onde é passado uma função parar formatar os dados, essa é a maneira correta,
            * para maiores informações, acesse a documentação em: https://www.ag-grid.com/react-data-grid/value-formatters/#value-formatter-vs-cell-renderer
            * 
            * Aqui tambem podemos adicionar as configurações para cada coluna, como filtros que podem ser de texto, data ou numero. Para maiores
            * informações, acesse a documentação em: https://www.ag-grid.com/react-data-grid/filtering-overview/
            */
            if (enableColumnSelection) {
                getHeaders(response.data.headers)
            }

            const updatedHeaders = response.data.headers.map(header => {
                if (header.field === 'data') {
                    return {
                        ...header,
                        valueFormatter: formatDate,
                        filter: 'agDateColumnFilter',
                        floatingFilter: true,
                        sortable: true,
                    };
                } else if (['cfr', 'fob_porto_granel', 'fob_porto_bag'].includes(header.field)) {
                    return {
                        ...header,
                        valueFormatter: formatCurrencyUSD,
                        filter: 'agNumberColumnFilter',
                        floatingFilter: true,
                        sortable: true,

                    };
                } else if (header.field === 'dolar') {
                    return {
                        ...header,
                        valueFormatter: formatCurrencyBRL,
                        filter: 'agNumberColumnFilter',
                        floatingFilter: true,
                    }
                } else if (header.field === 'nome_porto') {
                    return {
                        ...header,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,

                    };
                } else if (header.field === 'descricao') {
                    return {
                        ...header,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    };
                }

                return header;
            });
            setData(response.data.dados);
            setColunaDefs(updatedHeaders);
            const columnState = JSON.parse(response.data.states).state;
            const columnfilters = JSON.parse(response.data.states).filters;

            gridRef.current.columnApi.applyColumnState({
                state: columnState,
            });
            gridRef.current.api.setFilterModel(columnfilters)

        } catch (error) {
            console.error("Erro ao buscar dados: ", error, enableColumnSelection);
        }
    };

    const setTableConfig = async (config) => {
        await api_amr.post("/save_tabelaEditavel_config", {
            "uuid": uuid,
            "config": config,
        }, { headers: { 'x-api-key': getToken() } })
    }

    const formatDate = (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString('pt-BR');
    };

    const formatCurrencyBRL = (params) => {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(params.value);
    };

    const formatCurrencyUSD = (params) => {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'USD' }).format(params.value);
    };

    const onColumnStateChanged = () => {
        /**
         * Esta função é executada ao ocorrer um determinado evento que ocorre referente a uma coluna, 
         * para cara evento todo o seu state(que é um JSON) é armazenado, consulte a documentação para
         * ver todos os eventos disponiveis em: https://www.ag-grid.com/react-data-grid/column-updating-definitions/#column-events
         */

        const filterModel = gridRef.current.api.getFilterModel();//Puxa os filtros aplicados as colunas
        const columnState = gridRef.current.columnApi.getColumnState();//Puxa as configurações aplicadas a tabela
        const finalstate = { 'state': columnState, 'filters': filterModel }
        if (enableColumnSelection) {
            setTableConfig(JSON.stringify(finalstate));
            /**  getConfig é usado no Preview do componente, e só existe quando o componente é renderizado na pagina de preview,
            *   caso o componente esteja sendo renderizado na dashboard, getConfig é undefined
            */
            if (getConfig !== undefined) {
                getConfig(JSON.stringify(finalstate))

            }
        }
    };

    const noRowsOverlayComponent = useMemo(() => {
        return OverLayLogoAMRcarregando
    }, []);

    const loadingOverlayComponent = useMemo(() => {
        return OverLayLogoAMRcarregando
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
        };
    }, []);

    return (
        <div id="myGrid" className="ag-theme-alpine" style={{ height: "100%" }}>
            <AgGridReact
                ref={gridRef}//Referencia da tabela
                localeText={myLocaleText}//Chama o arquivo com as traduções das opções padrão
                rowData={data}//Dados da tabela
                columnDefs={colunaDefs}//Carrega as configurações aplicaveis as colunas individualmente
                defaultColDef={defaultColDef}//Carrega as configurações aplicaveis a todas as colunas
                onGridReady={handleGridReady}//Adicioanando um Listener para o evento de Carregamento da tabela
                onColumnResized={onColumnStateChanged}//Adicioanando um Listener para o evento de Mudança de tamanho das colunas
                onColumnVisible={onColumnStateChanged}//Adicioanando um Listener para o evento de visibilidade de uma coluna
                onSortChanged={onColumnStateChanged}//Adicioanando um Listener para o evento de ordenação de uma coluna
                onFilterChanged={onColumnStateChanged}//Adicioanando um Listener para o evento de filtragem de uma coluna
                onColumnPinned={onColumnStateChanged}//Adicioanando um Listener para o evento de pinagem de uma coluna
                loadingOverlayComponent={loadingOverlayComponent}//Adicioanando um Listener para o evento de carregamento dos dados
                noRowsOverlayComponent={noRowsOverlayComponent}//Adicioanando um Listener para o evento de não ter dados para serem mostrados      
                suppressDragLeaveHidesColumns={true}//Impede que uma coluna seja arrastada para fora da grid e seja removida          
                pagination={true}//Ativa a paginação natural do componente
            />
        </div>
    );
}
