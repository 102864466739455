
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "./layouts/Admin.js";
import Footer from "components/Footer/Footer.js";
import FooterStyle from "components/Footer/FooterStyle.js"

import SignInSide from "./siginIn/SignInSide";
import Cadastro from "./siginIn/Cadastro";
import ValidaCadastro from "ViewsDinamicAux/ValidaCadastro/ValidaCadastro.js";
import Recovery from "siginIn/recovery.js";

import { createStore } from "redux";
import { Provider } from "react-redux";
import allReducers from "./flux/reducers/index";
import MostrarNoticia from "views/Posts/VisualizarPosts/MostrarNoticia.js";


    

const store = createStore(allReducers);

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      {/* <FooterStyle> */}
      <Switch >
        <Route path="/" exact component={SignInSide} />
        {/* <Route path="/Pesquisa/:id/:uid/:token" component={PesquisaGeral} /> */}
        <Route path="/ValidaCadastro/:token" component={ValidaCadastro} />
        <Route path="/recovery/:uuid" exact component={Recovery} />
        <Route path="/cadastro" exact component={Cadastro} />
        
        <Route path="/" exact component={SignInSide} />
        <Route path="/pages" component={Admin} />
        <Redirect from="/admin/:subpath" to="/pages/:subpath" />
        <Redirect from='/RetornaFormulas' to="/pages/RetornaFormulas" />
        <Redirect from='/Formulador' to="/pages/Formulador" />
        <Redirect from='/PowerBiImportacoes' to="/pages/PowerBiImportacoes" />
        <Redirect from='/PrecificaPorto' to="/pages/PrecificaPorto" />
        <Redirect from='/AndamentoMercadoBrasil' to="/pages/AndamentoMercadoBrasil" />

        <Redirect from='/CadastroADM' to="/pages/CadastroADM" />
        <Redirect from='/ConfigGrupos' to="/pages/ConfigGrupos" />
        <Redirect from='/ConfigFazendas' to="/pages/ConfigFazendas" />
        <Redirect from='/PerfilUsuario' to="/pages/PerfilUsuario" />
        <Redirect from='/ListaDePrecos' to="/pages/ListaDePrecos" />
        <Redirect from='/HistoricoUsabilidade' to="/pages/HistoricoUsabilidade" />
        <Redirect from='/HistoricoFretes' to="/pages/HistoricoFretes" />
        <Redirect from='/HistRelacaoDeTroca' to="/pages/HistRelacaoDeTroca" />
        <Redirect from='/PrevisaoClimatica' to="/pages/PrevisaoClimatica" />
        <Redirect from='/ConfigPortos' to="/pages/ConfigPortos" />
        <Redirect from='/AssociacaoProdutosPadrao' to="/pages/AssociacaoProdutosPadrao" />
        <Redirect from='/ProdutosPadraoAssociados' to="/pages/ProdutosPadraoAssociados" />
        <Redirect from='/GerenciarPosts' to="/pages/GerenciarPosts" />
        <Redirect from='/CriarPost' to="/pages/CriarPost" />
        <Redirect from='/Noticias' to="/pages/Noticias" />
        <Redirect from='/News' to="/pages/News" />
        <Redirect from='/Dashboard' to="/pages/Dashboard" />

      </Switch >
      {/* </FooterStyle> */}
    </Router>
  </Provider>,
  document.getElementById("root")
);
