import React, { useEffect, useState } from 'react';
import api from '../../services/api_amr';
import { getToken } from '../../utils/utils';
import {
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
    },
    table: {
        minWidth: 650,
    },
    title: {
        marginBottom: theme.spacing(2),
    },
}));

const AtualizacoesAndamentoMercado = () => {
    const classes = useStyles();
    const [atualizacoes, setAtualizacoes] = useState([]);

    useEffect(() => {
        const fetchAtualizacoes = async () => {
            try {
                const response = await api.get('/atualizacoes_andamento_mercado', {
                    headers: {
                        'x-api-key': getToken()
                    }
                });
                setAtualizacoes(response.data);
            } catch (error) {
                console.error("Erro ao buscar atualizações", error);
            }
        };

        fetchAtualizacoes();
    }, []);

    return (
        <Container className={classes.container}>
            <Typography variant="h4" component="h1" className={classes.title}>
                Atualizações do Andamento de Mercado
            </Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Hora</TableCell>
                            <TableCell>Nome Cultura</TableCell>
                            <TableCell>Estado</TableCell>
                            <TableCell>Ano</TableCell>
                            <TableCell>Semana</TableCell>
                            <TableCell>Quantidade Antiga</TableCell>
                            <TableCell>Quantidade Nova</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {atualizacoes.map((atualizacao, index) => (
                            <TableRow key={index}>
                                <TableCell>{new Date(atualizacao.data_atualizacao).toLocaleString()}</TableCell>
                                <TableCell>{atualizacao.nome_cultura}</TableCell>
                                <TableCell>{atualizacao.nome_estado}</TableCell>
                                <TableCell>{atualizacao.ano}</TableCell>
                                <TableCell>{atualizacao.semana}</TableCell>
                                <TableCell>{atualizacao.quantidade_antiga}</TableCell>
                                <TableCell>{atualizacao.quantidade_nova}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default AtualizacoesAndamentoMercado;
