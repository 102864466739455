import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import api_amr from "services/api_amr";
import { getToken } from "utils/utils";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import FullscreenRoundedIcon from '@material-ui/icons/FullscreenRounded';

export default function TopBar({ idDashboard, dashboardName, onDashboardNameChange, onHandlePopoverOpen, locked, onLock, saving, handleFullScreen }) {
    const [hovered, setHovered] = useState(false)
    const [editTitle, setEditTitle] = useState(false)
    const [title, setTitle] = useState('')
    const [titleBuffer, setTitleBuffer] = useState('')

    const classes = useStyles()

    useEffect(() => {
        setTitle(dashboardName)
    }, [dashboardName])

    const handleTitleChange = (event) => {
        setTitleBuffer(event.target.value)
    }

    const handleSave = async () => {
        if (!titleBuffer.trim() || titleBuffer === title) {
            alert("Nome do dashboard inválido ou já em uso");
            return
        }

        try {
            setEditTitle(false)
            const response = await api_amr.post('change_dashboard_name', {
                'dashboard_id': idDashboard,
                'new_name': titleBuffer
            }, { headers: { 'x-api-key': getToken() } })

            if (response.data === 'OK') {
                setTitle(titleBuffer)
                if (onDashboardNameChange) {
                    onDashboardNameChange(titleBuffer)
                }
            } else if (response.data === 'NOK') {
                alert("Erro ao mudar o nome do dashboard")
                setTitleBuffer(title);
            } else {
                console.error("Resposta inesperada: ", response.data)
            }
        } catch (error) {
            console.error("Um erro ocorreu durante a mudança de nome do dashboard: ", error);
        }
    }

    const handlePopoverOpen = (event) => {
        if (onHandlePopoverOpen) onHandlePopoverOpen(event.currentTarget);
    }

    return (
        <Box className={classes.mainWrapper}>
            <Grid container justifyContent="space-between" alignItems="center" >
                <Grid
                    item
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    onClick={() => setEditTitle(true)}>
                    {editTitle ?
                        <Box display="flex" alignItems="center" className={classes.editWrapper}>
                            <TextField
                                InputProps={{
                                    style: { width: `${title.length * 10}px`, minWidth: 200 },
                                    disableUnderline: true,
                                }}
                                defaultValue={title}
                                onChange={handleTitleChange}
                                autoFocus
                            />
                            <Box className={classes.iconContainer1} onClick={(e) => {
                                e.stopPropagation()
                                handleSave()
                            }}>
                                <CheckIcon />
                            </Box>
                            <Box className={classes.iconContainer2} onClick={(e) => {
                                e.stopPropagation() // isso aqui impede que o onClick do parent seja acionado
                                setEditTitle(false)
                            }}>
                                <CloseIcon />
                            </Box>
                        </Box>
                        :
                        <Typography className={classes.title}>
                            {dashboardName} {hovered && <EditIcon style={{ fontSize: 18 }} />}
                        </Typography>
                    }
                </Grid>

                <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid item>
                        <Button
                            style={{
                                textTransform: 'capitalize'
                            }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handlePopoverOpen}
                        >
                            <AddRoundedIcon />&nbsp;Adicionar Componente
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: 5 }} >
                <Grid item >
                    {locked ?
                        <Button color="primary" size='small' onClick={onLock} className={classes.lockIconDiv}>
                            <CheckIcon className={classes.editIcons} />  Salvar Alterações
                        </Button>
                        :
                        <Button color="primary" disabled={saving} size='small' onClick={onLock} className={classes.lockIconDiv}>
                            <EditIcon className={classes.editIcons} /> Editar Dashboard
                        </Button>
                    }
                </Grid>

                <Grid item>
                    <span
                        className={classes.fullscreenIcon}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleFullScreen.enter}
                    >
                        <FullscreenRoundedIcon /> Tela Cheia
                    </span>
                </Grid>
            </Grid>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    fullscreenIcon: {
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid lightgray',
        borderRadius: 5,
        paddingLeft: 2,
        paddingRight: 2,
        fontFamily: 'Poppins',
        fontSize: 12,
        color: '#303030',
        cursor: 'pointer',
        "&:hover": {
            color: 'black',
            border: '1px solid #303030',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
        }
    },

    lockIconDiv: {
        fontSize: 12
    },

    editIcons: {
        verticalAlign: 'middle',
        fontSize: 16,
        marginTop: -4,
        marginRight: 3
    },

    mainWrapper: {
        background: 'white',
        border: '1px solid lightgray',
        top: 0,
        zIndex: 2,
        padding: 5,
        position: 'fixed',
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 28px)'
        },
        width: 'calc(100% - 108px)'
    },

    editWrapper: {
        border: '1px solid #006bc2',
        background: '#EFF1F5',
        paddingLeft: 5,
        borderRadius: 5,
        minWidth: 200
    },

    iconContainer1: {
        width: 30,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        background: '#006bc2',
        '&:hover': {
            backgroundColor: '#0953bf',
            cursor: 'Pointer',
            boxShadow: '2px 4px 10px rgba(0,0,0,0.1)',
        },
    },

    iconContainer2: {
        width: 30,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#383838',
        '&:hover': {
            color: 'black',
            cursor: 'Pointer',
            boxShadow: '2px 4px 10px rgba(0,0,0,0.1)',
        },
    },

    title: {
        paddingLeft: 5,
        paddingRight: 5,
        color: '#383838',
        fontSize: 21,
        fontFamily: "Poppins",
        borderRadius: 5,
        width: 'fit-content',
        '&:hover': {
            backgroundColor: '#EFF1F5',
            cursor: 'Pointer',
        },
    },
}))