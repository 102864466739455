import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import useStyles from '../styles';
import { Typography, Button } from '@material-ui/core';
import { WhatsappShareButton, FacebookShareButton, TwitterShareButton, FacebookIcon, WhatsappIcon, TwitterIcon } from "react-share";
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import { useLocation, Link, useHistory, useParams } from 'react-router-dom';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { getComponentValue } from "../utils.js";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getToken } from 'utils/utils';
import api_amr from 'services/api_amr';
import warningImage from '../../../assets/img/login_warning.png'
import PersonIcon from '@material-ui/icons/Person';
import LoaderGif from '../../../assets/img/loader_gif.gif'
import ReactMarkdown from 'react-markdown';

export default function RenderPost() {
    const location = useLocation()
    const history = useHistory()
    const classes = useStyles()
    const { nid } = useParams()
    const [loginWarning, setLoginWarning] = useState(true)
    const [post, setPost] = useState()
    const [generatingPdf, setGeneratingPdf] = useState(false)

    const [showLoader, setShowLoader] = useState(true);



    useEffect(() => {
        if (location.state !== undefined) {
            if (getToken() !== '' && location.state.post.login_required === 1) {

                setLoginWarning(false)
            }

        }

        fetchMyApi()
        setTimeout(() => {
            smoothScrollTo(0, 1000);

        }, 300);
        setTimeout(() => {
            setShowLoader(false);
        }, 700);


    }, [])

    async function fetchMyApi() {
        var auxPost
        if (location.state === undefined) {
            const postResponse = await api_amr.get('/return_post_components_by_uuid', { params: { 'uuid': nid }, headers: { 'x-api-key': getToken() } })
            auxPost = postResponse.data
        } else {
            auxPost = location.state.post
        }
        setPost(auxPost)

        if (getToken() === '') {
            await api_amr.get('/increment_post_view_unlogged_new', { params: { 'post_id': auxPost.post_id } })
        } else {
            await api_amr.get('increment_post_view_logged_new', { params: { 'post_id': auxPost.post_id }, headers: { 'x-api-key': getToken() } })
        }

        if (getToken() !== '') {
            setLoginWarning(false)
        }
        else if (getToken() === '' && auxPost.login_required === 0) {
            setLoginWarning(false)
        }

        console.log(loginWarning)
    }

    function smoothScrollTo(targetY, duration = 500) {
        const startY = window.scrollY;
        const distance = targetY - startY;
        let startTime = null;

        function animation(currentTime) {
            if (startTime === null) startTime = currentTime;
            const timeElapsed = currentTime - startTime;
            const progress = Math.min(timeElapsed / duration, 1);
            const ease = progress < 0.5 ? 2 * progress * progress : (4 - 2 * progress) * progress - 1;
            window.scrollTo(0, startY + distance * ease);

            if (timeElapsed < duration) {
                requestAnimationFrame(animation);
            }
        }

        requestAnimationFrame(animation);
    }

    const handleLink = (component) => {
        if ('link' in component) {
            return component.link
        } else {
            return component.value
        }
    }

    const handleShareString = (post_date, post_tags, post_title) => {
        return (
            `*${post_title}*\n${new Date(post_date).toLocaleString('pt-br', { dateStyle: 'long', timeZone: 'UTC' })}\n_${post_tags}_\n\n${window.location.href}`
        )
    }

    const renderComponent = (item, index) => {
        switch (item.type) {
            case 'paragraph':
                return (
                    <Grid item key={index} xs={12}>
                    <Typography key={index} className={classes.paragraph}>
                      {item.value.split(/\n/).filter(line => line.length > 0).map((line, index) =>
                        <div key={line} className={line.length > 100 ? '' : classes.line}>
                          <ReactMarkdown>{line.replace(/\*([^*]+)\*/g, '**$1**').replace(/_([^_]+)_/g, '_$1_')}</ReactMarkdown>
                        </div>
                      )}
                    </Typography>
                  </Grid>
                )
                
            case 'title':
                return (
                    <Grid item key={index}>
                        <Typography variant="h4" className={classes.postTitle}>
                            {item.value}
                        </Typography>
                    </Grid>
                )
            case 'image':
                return (
                    <Grid item >
                        <img width={'100%'} src={handleLink(item)} />
                    </Grid>
                )
            case 'audio':
                return (
                    <audio style={{ width: '100%' }} controls>
                        <source src={item.link} type="audio/mp3" />
                    </audio>
                )
            case 'video':
                return (
                    <Grid item key={index} >
                        <video width="100%" controls>
                            <source src={handleLink(item)} type="video/mp4" />
                        </video>
                    </Grid>
                )
            case 'file':
                return (
                    <Grid item key={index}>

                        <button disabled={generatingPdf} onClick={() => handleDownload(item)} style={{ textDecoration: 'none', width: '100%', display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <div>
                                <DescriptionRoundedIcon style={{ fontSize: '30px' }} />
                            </div>
                            {item.filename}
                        </button>
                    </Grid>
                )
            default:
                return null
        }
    }
    const handleDownload = async (item) => {
        // Send a GET request to the backend, and wait for the response
        setGeneratingPdf(true)
        const response = await api_amr.get('/download_assinado', { params: { 'comp_id': item.component_id }, headers: { 'x-api-key': getToken() } })

        // response.data should be the URL of the modified PDF file
        const fileUrl = response.data;

        // Create a temporary <a> element and use it to download the file
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = item.filename; // Replace with the desired filename
        link.click();
        setGeneratingPdf(false)

    };



    if (post) {
        return (
            <div className={classes.root} >
                {showLoader && (
                    <div className={classes.loaderOverlay}>
                        <img src={LoaderGif} alt="Loading..." className={classes.loader} />
                    </div>
                )}
                <Grid container spacing={2} direction='column' style={{ borderRight: '1px solid lightgray' }} xs={12}>
                    <Grid container item direction="column" xs={12}>
                        <Grid item className={classes.tag} xs={12}>
                            <Typography style={{ fontSize: '12px' }}>
                                {post.tagsPrimarias}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.bigBlackTitle}>
                                {getComponentValue(post, 'main_title')}
                            </Typography>
                        </Grid>

                        <Grid item className={classes.postInfo} xs={12}>
                            <Typography variant="subtitle2" className={classes.postInfoItem} >
                                {post.user_name}
                            </Typography>
                            <Typography variant="subtitle2" className={classes.postInfoItem}>
                                {new Date(post.post_date).toLocaleDateString('pt-BR', { month: 'long', day: 'numeric' }).split(' de ').reverse().join(' ').replace(/^\w/, c => c.toUpperCase())}
                            </Typography>
                            <Typography variant="subtitle2" className={classes.postInfoItem}>
                                <span style={{ display: 'flex', alignItems: 'center', color: '#929292' }}>
                                    <VisibilityIcon fontSize="inherit" style={{ marginRight: 4 }} />
                                    {((post.views_counter_logged || 0) + (post.views_counter_unlogged || 0))}
                                </span>
                            </Typography>

                            <Typography variant="subtitle2" className={classes.postInfoItem}>
                                {Boolean(post.verified) ?
                                    <span style={{ display: 'flex', alignItems: 'center', color: '#929292' }}>
                                        <CheckRoundedIcon fontSize="inherit" style={{ marginRight: 4 }} />
                                        Verificado
                                    </span>
                                    : null}
                            </Typography>
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid item className={classes.shareItem}>
                                <WhatsappShareButton url={handleShareString(post.post_date, post.tag_name, getComponentValue(post, 'main_title'))} className={classes.shareButton}>
                                    <WhatsappIcon size={18} />
                                    <span className={classes.shareText}> Whatsapp</span>
                                </WhatsappShareButton>
                            </Grid>
                            <Grid item className={classes.shareItem}>
                                <FacebookShareButton url={window.location.href} className={classes.shareButton}>
                                    <FacebookIcon size={18} />
                                    <span className={classes.shareText}> Facebook</span>
                                </FacebookShareButton>
                            </Grid>
                            <Grid item className={classes.shareItem}>
                                <TwitterShareButton url={window.location.href} className={classes.shareButton}>
                                    <TwitterIcon size={18} />
                                    <span className={classes.shareText}> Twitter</span>
                                </TwitterShareButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div style={{ width: '100%', height: '100%', overflow: 'x', padding: 10 }}>
                        <Button
                            onClick={() => console.log(loginWarning)}>
                        </Button>
                        {loginWarning === false ?
                            <Grid container item spacing={2} xs={12}>
                                {/* <Grid item>
                            <img width={'100%'} src={getComponentValue(post, 'cover_image') === null ?getComponentValue(post, 'cover_image') : 'https://back.plataformaamrbi.com/files/posts/file_server/115/logo_amr2.png'} />
                        </Grid> */}

                                <Grid container item xs={12} spacing={2}>
                                    {post.components.map((component, index) =>
                                        <React.Fragment key={index}>
                                            {renderComponent(component, index)}
                                        </React.Fragment>)}
                                </Grid>
                            </Grid>
                            : <Grid container item xs={12} justifyContent='center' >
                                <Grid item xs={12} >
                                    <img src={warningImage} width='100%' />
                                </Grid>

                                <Grid item >
                                    <Button
                                        component={Link}
                                        to={{ pathname: '/' }}
                                        className={classes.greenButton}>
                                        <PersonIcon style={{ marginRight: 5 }} />
                                        Login
                                    </Button>
                                </Grid>
                            </Grid>}
                    </div>
                    <Grid item>
                        <Button onClick={() => history.goBack()} variant="contained" className={classes.greenButton}>
                            Voltar
                        </Button>
                    </Grid>
                </Grid >
            </div >
        )

    } else { return null }
}
