import React, { useEffect, useState } from "react";
import api_amr from "services/api_amr";
import { getToken } from "utils/utils";
import { Line, Bar, Scatter } from 'react-chartjs-2';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from "@material-ui/lab";
import { Grid } from '@material-ui/core';
import backLogo from '../../../assets/img/logo_arm_opaque.png';
import { makeStyles } from "@material-ui/core/styles";

const colors = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf', '#4CB1CB'];

export default function GraficoFuturosComponent({ parametros }) {
    const classes = useStyles();
    const [dados, setDados] = useState([]);
    const [formattedDates, setFormattedDates] = useState([]);
    const [tabela, setTabela] = useState('')
    const tabelaFuturoOptions = {
        'ureia_egy_futuro': 'Ureia Egyto' ,
        'ureia_me_futuro': 'Ureia Mediterrâneo' ,
        'dap_nola_futuro': 'DAP Nola' ,
        'map_br_futuro': 'MAP Brasil' ,
        'ureia_us_futuro': 'Ureia US' ,
        'ureia_br_futuro': 'Ureia BR' ,}
    useEffect(() => {
        fetchMyApi();
    }, [parametros]);

    const fetchMyApi = async () => {
        const response = await api_amr.get('/return_component_Futuros', {
            params: {
                formato: 'grafico',
                parametros: { ...parametros, data: '2024-01-01' },
            },
            headers: { 'x-api-key': getToken() },
        });
       
       
        if(Object.keys(response.data).length !== 0){
            const newFormattedDates = Object.values(response.data)[0].map((item) => {
                // Parse the date string into a JavaScript Date object
                const dateObj = new Date(item.data_extracao);
            
                // Extract day, month, and year
                const day = String(dateObj.getUTCDate()).padStart(2, '0');
                const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = dateObj.getUTCFullYear();
            
                // Return the formatted date
                return `${day}/${month}/${year}`;
            });
            setDados(response.data)
            setFormattedDates(newFormattedDates);
            setTabela(tabelaFuturoOptions[parametros.tabela])
        }
        
    };

    const data = {
        labels: formattedDates,
        datasets: Object.keys(dados).map((item, index) => ({
            label: `${item}`,
            data: dados[item].map((item) => item.changes),
            fill: false,
            backgroundColor: colors[index % colors.length], // Dynamic color assignment
            borderColor: colors[index % colors.length],   // Dynamic color assignment
            pointRadius: 0,
        }))
    };

    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                grid: {
                    display: true,
                },
                title: {
                    display: false,

                },
                ticks: {
                    callback: function (value, index, values) {

                        return index % 5 === 0 ? formattedDates[index] : undefined
                    },
                },

            },
            y: {
                grid: {
                    display: false,
                },
                title: {
                    display: false,

                },
                ticks: {
                    callback: function (value, index, values) {
                        return parseFloat(value).toLocaleString('pt-br', { style: 'currency', currency: 'USD' }); // This will append a dollar sign to the values on the y-axis
                    },
                },

            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.dataset.label}: ${context.parsed.y}`;
                    },
                },
            },
            legend: {
                display: true, // This hides the legend labels
            },
            title: {
                display: true, // Set to true to display the title
                text: `Contratos Futuros de  ${tabela} `, // The text you want for the title
                font: {
                    size: 16,
                    font: 'Poppins'
                },
                display: true, // This hides the legend labels
            },

        },

    };
    return (
        <div className={classes.mainWrapper}>
            <img
                src={backLogo}
                alt="Logo"
                className={classes.img}
            />
            <Line data={data} options={options} />
        </div>
    )
}
const useStyles = makeStyles({
    mainWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: 'white'
    },

    img: {
        position: 'absolute',
        zIndex: 1,
        width: '50%',
        height: 'auto',
    }
})
