import React, { useEffect, useState } from "react";
import { getToken, checarPermissao, getUserInfo } from '../../utils/utils'
import api_amr from '../../services/api_amr'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import logo from "../../assets/img/logo_arm_opaque.png";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Line } from 'react-chartjs-2';
import exportFromJSON from "export-from-json";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import hbStyle from "./hbStyle";
import Grid from "@material-ui/core/Grid";


function HistoricoBasis(props) {
    const [permitido, setPermitido] = useState(false)
    const [tabValue, setTabValue] = useState(0)
    const [tipoBasisSoja, setTipoBasisSoja] = useState(0)
    const [tipoBasisFert, setTipoBasisFert] = useState(0)
    const [basisVigenteFert, setBasisVigenteFert] = useState('')
    const [basisHistoricoFert, setBasisHistoricoFert] = useState('')

    const [origensBasisSoja, setOrigensBasisSoja] = useState([])
    const [origemSoja, setOrigemSoja] = useState('')
    const [basisVigenteSoja, setBasisVigenteSoja] = useState('')
    const [basisHistoricoSoja, setBasisHistoricoSoja] = useState('')
    const [basisSojaStr, setBasisSojaStr] = useState('')
    const [origemFert, setOrigemFert] = useState('')
    const [topBasis, setTopBasis] = useState('')
    const [polyMean, setPolyMean] = useState({})
    const [fetchingBasis, setFetchingBasis] = useState(false)
    const [userGroup, setUserGroup] = useState(0)

    const classes = hbStyle();

    useEffect(() => {
        async function fetchMyAPI() {
            setPermitido(await checarPermissao('historico_basis', props))
            const validate_permissions = await api_amr.get('/get_user_permissions', { headers: { 'x-api-key': getToken() } })
            setUserGroup(validate_permissions.data[0].group_id)

            const response_origens = await api_amr.get('/return_index_basis', { headers: { 'x-api-key': getToken() } });
            setOrigensBasisSoja(response_origens.data);
        }
        fetchMyAPI();
    }, [])

    async function getBasis() {
        setFetchingBasis(true)
        const polyMean = await api_amr.get('/get_basis_polynomial_mean', { params: { 'city': origemSoja }, headers: { 'x-api-key': getToken() } });
        let statePoly = {
            labels: polyMean.data.total_weeks,
            options: {
                elements: {
                    line: {
                        tension: 0.5
                    }
                },
                responsive: true,

                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                stacked: false,
                plugins: {
                    title: {
                        display: true,
                        text: `Basis ${origemSoja}`
                    },
                    legend: {
                        labels: {
                            // This more specific font property overrides the global property
                            font: {
                                fontFamily: 'Poppins'
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false
                        }
                    },
                    y: {

                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false
                        }
                    }
                },
            },

            datasets: [
                {
                    label: 'Basis 2024',
                    data: polyMean.data.current_basis,
                    borderColor: 'blue',
                    backgroundColor: 'blue',
                    fill: false,
                    borderWidth: 0
                },
                {
                    label: 'Linha tendência (Polinomial)',
                    data: polyMean.data.polynomial_mean,
                    borderColor: '#A3CC51',
                    backgroundColor: '#A3CC51',
                },
            ],

        }
        setPolyMean(statePoly)

        const response_basis = await api_amr.get('/return_basis', {
            params: {
                cidade: origemSoja,
            }, headers: { 'x-api-key': getToken() }
        });

        const response_basis_str = await api_amr.get('/return_basis_str', {
            params: {
                cidade: origemSoja,
            }, headers: { 'x-api-key': getToken() }
        });
        setBasisSojaStr(response_basis_str.data);

        const dados = response_basis.data
        //dados vigente
        let state = {
            labels: dados.vigente,
            options: {
                elements: {
                    line: {
                        tension: 0.5
                    }
                },
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                stacked: false,
                plugins: {
                    title: {
                        display: true,
                        text: 'Histórico Basis 1 Ano ' + origemSoja
                    },
                    legend: {
                        labels: {
                            // This more specific font property overrides the global property
                            font: {
                                fontFamily: 'Poppins'
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false
                        }
                    },
                    y: {

                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false
                        }
                    }
                },
            },

            datasets: [
                {
                    label: 'Basis',
                    data: dados.basis_vigente,
                    borderColor: '#A3CC51',
                    backgroundColor: '#A3CC51',
                }
            ],

        }
        setBasisVigenteSoja(state);

        //dados Historico
        let state_historico = {
            labels: dados.historico,
            options: {
                elements: {
                    line: {
                        tension: 0.5
                    }
                },
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                stacked: false,
                plugins: {
                    title: {
                        display: true,
                        text: 'Histórico Basis Soja 2019-01 ~ Data atual ' + origemSoja
                    },
                    legend: {
                        labels: {
                            // This more specific font property overrides the global property
                            font: {
                                fontFamily: 'Poppins'
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false
                        }
                    },
                    y: {

                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false
                        }
                    }
                },
            },

            datasets: [
                {
                    label: 'Histórico Basis Soja',
                    data: dados.basis_historico,
                    borderColor: '#A3CC51',
                    backgroundColor: '#A3CC51',
                }
            ],

        }
        setBasisHistoricoSoja(state_historico)
        const responseTopBasis = await api_amr.get('/get_top_basis', { params: { 'city': origemSoja }, headers: { 'x-api-key': getToken() } })
        setTopBasis(responseTopBasis.data)
        setFetchingBasis(false)
    }

    async function getBasisFert() {
        setFetchingBasis(true)
        const response_basis = await api_amr.get('/return_basis_fertilizantes', {
            params: {
                cidade: origemFert,
            }, headers: { 'x-api-key': getToken() }
        });

        const dados = response_basis.data
        //dados vigente
        let state = {
            labels: dados.vigente,
            options: {
                elements: {
                    line: {
                        tension: 0.5
                    }
                },
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                stacked: false,
                plugins: {
                    title: {
                        display: true,
                        text: 'Histórico Basis Fertilizantes 1 Ano ' + origemFert,

                    },
                    legend: {
                        labels: {
                            // This more specific font property overrides the global property
                            font: {
                                fontFamily: 'Poppins'
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false
                        }
                    },
                    y: {

                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false
                        }
                    }
                },
            },

            datasets: [
                {
                    label: 'Basis',
                    data: dados.basis_vigente,
                    borderColor: '#A3CC51',
                    backgroundColor: '#A3CC51',
                }
            ],

        }
        setBasisVigenteFert(state);

        //dados Historico
        let state_historico = {
            labels: dados.historico,
            options: {
                elements: {
                    line: {
                        tension: 0.5
                    }
                },
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                stacked: false,
                plugins: {
                    title: {
                        display: true,
                        text: 'Histórico Basis Fertilizantes 2019-01 -> Data atual ' + origemFert,
                        fontFamily: 'Poppins'
                    },
                    legend: {
                        labels: {
                            // This more specific font property overrides the global property
                            font: {
                                fontFamily: 'Poppins'
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false
                        }
                    },
                    y: {

                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false
                        }
                    }
                },
            },

            datasets: [
                {
                    label: 'Histórico Basis Fertilizantes',
                    data: dados.basis_historico,
                    borderColor: '#A3CC51',
                    backgroundColor: '#A3CC51',
                    fontFamily: 'Poppins'
                }
            ],

        }
        setBasisHistoricoFert(state_historico)
        setFetchingBasis(false)
    }

    function tabPanel() {
        switch (tabValue) {
            case 0:
                return (
                    <>
                        <Grid item xs={8} lg={4} xl={3}>
                            <Autocomplete
                                id="combo-box-demo"
                                options={origensBasisSoja}
                                getOptionLabel={(option) => option.cidade}
                                key='14'
                                className={classes.autocomplete}
                                classes={{ input: classes.input }}
                                onInputChange={(e, v) => setOrigemSoja(v)}
                                renderInput={(params) => <TextField
                                    {...params}
                                    component={Paper}
                                    placeholder="Selecionar Origem"
                                    variant='outlined'
                                    value={origemSoja}
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}

                                />} />

                        </Grid>

                        <Grid item xs={4} md={4} style={{ paddingLeft: 20 }} >
                            {fetchingBasis ? <CircularProgress style={{ 'color': '#A3CC51' }} />
                                : <Button
                                    onClick={() => getBasis()}
                                    variant="contained"

                                    className={classes.calcButton}>
                                    Carregar Basis Soja
                                </Button>}
                        </Grid>

                        <Grid item xs={12}>
                            {basisVigenteSoja !== '' ?
                                <AppBar position="static" className={classes.appBar}>
                                    <Tabs indicatorColor="primary" value={tipoBasisSoja}>
                                        <Tab label="Basis 1 Ano" value={0} onClick={() => setTipoBasisSoja(0)} className={classes.inputh1} />
                                        <Tab label="Histórico Total" value={1} onClick={() => setTipoBasisSoja(1)} className={classes.inputh1} />
                                    </Tabs>
                                </AppBar> : null}
                        </Grid>

                        {tipoBasisSoja === 0 && basisVigenteSoja !== '' ?
                            <Grid item xs={12} md={6} >
                                <div className={classes.divChart} style={{ backgroundImage: `url(${logo})` }}>
                                    <Line data={basisVigenteSoja} options={basisVigenteSoja.options} Legend />
                                </div>
                            </Grid> : null}

                        {tipoBasisSoja === 1 && basisHistoricoSoja !== '' ?
                            <Grid item s={12} md={6} >
                                <div className={classes.divChart} style={{ backgroundImage: `url(${logo})` }}>
                                    <Line data={basisHistoricoSoja} options={basisHistoricoSoja.options} Legend />
                                </div>
                            </Grid> : null}

                        {basisVigenteSoja !== '' ?
                            <Grid item xs={12} md={6} style={{ borderLeft: '1px solid lightgray' }}>
                                <div className={classes.divChart} style={{ backgroundImage: `url(${logo})` }}>
                                    <Line data={polyMean} options={polyMean.options} Legend />
                                </div>
                            </Grid> : null}

                    </>)
            case 1:
                return (
                    <>
                        <Grid container alignItems="center">
                            <Grid item xs={8} lg={4} xl={3} >
                                <Autocomplete
                                    id="combo-box-demo2"
                                    key='123'
                                    options={[
                                        { name: 'Urea (Granular) FOB Egypt' },
                                        { name: 'Urea (Granular) FOB Middle East' },
                                        { name: 'MAP CFR Brazil' },
                                        { name: 'Urea (Granular) CFR Brazil' },
                                        { name: 'Urea (Granular) FOB US Gulf' },
                                        { name: 'DAP FOB Nola' },
                                    ]}
                                    getOptionLabel={(option) => option.name}
                                    className={classes.autocomplete}
                                    classes={{ input: classes.input }}
                                    onInputChange={(e, v) => setOrigemFert(v)}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        component={Paper}
                                        placeholder="Selecionar Origem"
                                        variant='outlined'
                                        value={origemFert}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}
                                    />} />
                            </Grid>

                            <Grid item xs={4} md={8} style={{ paddingLeft: 20 }}>
                                {fetchingBasis ? <CircularProgress style={{ 'color': '#A3CC51' }} />
                                    : <Button
                                        onClick={() => getBasisFert()}
                                        variant="contained"
                                        className={classes.calcButton}>
                                        Carregar Basis Fertilizantes
                                    </Button>}
                            </Grid>
                        </Grid>

                        <Grid container >
                            <Grid item xs={12} >
                                {basisVigenteFert !== '' ?
                                    <AppBar position="static" className={classes.appBar}>
                                        <Tabs indicatorColor="primary" value={tipoBasisFert}>
                                            <Tab label="Basis 1 Ano" value={0} onClick={() => setTipoBasisFert(0)} />
                                            <Tab label="Histórico Total" value={1} onClick={() => setTipoBasisFert(1)} />
                                        </Tabs>
                                    </AppBar> : null}
                            </Grid>

                            {tipoBasisFert === 0 && basisVigenteFert !== '' ?
                                <Grid item xs={6}>
                                    <div className={classes.divChart} style={{ backgroundImage: `url(${logo})` }}>
                                        <Line data={basisVigenteFert} options={basisVigenteFert.options} Legend />
                                    </div>
                                </Grid> : null}

                            {tipoBasisFert === 1 && basisHistoricoFert !== '' ?
                                <Grid item xs={6} >
                                    <div className={classes.divChart} style={{ backgroundImage: `url(${logo})` }}>
                                        <Line data={basisHistoricoFert} options={basisHistoricoFert.options} Legend />
                                    </div>
                                </Grid> : null}
                        </Grid>
                    </>)
        }
    }

    function downloadBasis() {
        const data = basisSojaStr
        const fileName = 'bassis'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }

    return (
        permitido ?
            <Grid xs={11} spacing={2} container item className={classes.container} >
                <Grid item xs={12}>
                    <Grid item xs={12} >
                        <AppBar position="static" style={{ backgroundColor: '#A3CC51' }}>
                            <Tabs value={tabValue} indicatorColor="primary">
                                <Tab label="Basis Soja" value={0} onClick={() => setTabValue(0)} className={classes.inputh1} />
                                <Tab label="Basis Fertilizantes" value={1} onClick={() => setTabValue(1)} className={classes.inputh1} />
                            </Tabs>
                        </AppBar>
                    </Grid>

                    <Grid container item xs={12} component={Paper} alignItems='center' style={{}}>
                        {tabPanel()}
                    </Grid>

                </Grid>

                {topBasis !== '' && tabValue === 0 ?
                    <>
                        <Grid xs={12} xl={5} item>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>CME Valor ($)</TableCell>
                                            <TableCell>Físico ($)</TableCell>
                                            <TableCell>Câmbio (R$)</TableCell>
                                            <TableCell>Basis ($/bu)</TableCell>
                                            <TableCell>Cidade</TableCell>
                                            <TableCell>Data</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {topBasis.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell>{row.cme_valor}</TableCell>
                                                <TableCell>{row.fisico}</TableCell>
                                                <TableCell>{row.cambio}</TableCell>
                                                <TableCell>{row.basis}</TableCell>
                                                <TableCell>{row.cidade}</TableCell>
                                                <TableCell>{row.data}</TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {userGroup === 1 ?
                            <Grid xs={12} item >
                                {basisSojaStr !== '' ?
                                    <Button
                                        onClick={() => downloadBasis()}
                                        variant="contained"
                                        className={classes.calcButton}
                                    >
                                        Baixar Basis
                                    </Button>
                                    : null}
                            </Grid>
                            : null}

                    </>
                    : null}
            </Grid > : null
    )
}


export default HistoricoBasis;
