import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

export default function PrevisaoComponent({ parametros }) {
  const [title, setTitle] = useState(''); // 0, 1, 2 para diferentes tipos de previsão
  const [link, setLink] = useState('');
  const [open, setOpen] = useState(false); // 0, 1, 2 para diferentes tipos de previsão
  const classes = useStyles();
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLink(retornaLink())
  }, [])

  const retornaLink = () => {
    switch (parametros.periodo) {
      case "acumulado":
        switch (parametros.regiao) {

          case "america_sul":
            setTitle('Acumulado de Chuvas: 7 dias América do Sul')
            return 'https://www.cpc.ncep.noaa.gov/products/JAWF_Monitoring/Brazil/daily/p.7day.figa.gif';

          case "america_norte":
            setTitle('Acumulado de Chuvas: 7 dias América do Norte')
            return 'https://www.cpc.ncep.noaa.gov/products/Precip_Monitoring/Figures/NAMS/p.7day.figa.gif'

          case "asia":
            setTitle('Acumulado de Chuvas: 7 dias Ásia')
            return 'https://www.cpc.ncep.noaa.gov/products/Precip_Monitoring/Figures/Asia_Aust/p.7day.figa.gif'

          default:
            return false;
        }
      case "1_7dias":
        switch (parametros.regiao) {

          case "america_sul":
            setTitle('Previsão de Chuvas: 1 a 7 dias América do Sul')
            return 'https://www.cpc.ncep.noaa.gov/products/JAWF_Monitoring/GFS/BR_curr.p.gfs1a.gif'

          case "america_norte":
            setTitle('Previsão de Chuvas: 1 a 7 dias América do Norte')
            return 'https://www.cpc.ncep.noaa.gov/products/Precip_Monitoring/Figures/GFS/NA_curr.p.gfs1a.gif'

          case "asia":
            setTitle('Previsão de Chuvas: 1 a 7 dias Ásia')
            return 'https://www.cpc.ncep.noaa.gov/products/Precip_Monitoring/Figures/GFS/AS_curr.p.gfs1a.gif'

          default:
            return false;
        }

      case "8_14dias":
        switch (parametros.regiao) {

          case "america_sul":
            setTitle('Previsão de Chuvas: 8 a 14 dias América do Sul')
            return 'https://www.cpc.ncep.noaa.gov/products/JAWF_Monitoring/GFS/BR_curr.p.gfs2a.gif'

          case "america_norte":
            setTitle('Previsão de Chuvas: 8 a 14 dias América do Norte')
            return 'https://www.cpc.ncep.noaa.gov/products/Precip_Monitoring/Figures/GFS/NA_curr.p.gfs2a.gif'

          case "asia":
            setTitle('Previsão de Chuvas: 8 a 14 dias Ásia')
            return 'https://www.cpc.ncep.noaa.gov/products/Precip_Monitoring/Figures/GFS/AS_curr.p.gfs2a.gif'

          default:
            return false;
        }

      default:
        return false;

    }
  }

  return (
    <div className={classes.previsaoStyle}>
      <h4 className={classes.previsaoTitle}>
        {title}
      </h4>
      <div onClick={handleClickOpen}>
        <img
          style={{ width: '95%', height: 'auto' }}
          src={link}
        />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth={true}
        PaperProps={{
          style: {
            height: 'auto',
            width: '80vw',
          },
        }}
      >
        <DialogContent>
          <img
            style={{
              width: '80%',
              height: 'auto',
              objectFit: 'contain'
            }}
            alt=""
            src={link}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  previsaoStyle: {
    backgroundColor: 'white',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  previsaoTitle: {
    fontFamily: 'Poppins, sans-serif',
    textAlign: 'center',
    backgroundColor: 'transparent',
    fontSize: '14px',
    marginTop: '0px'
  },
}));