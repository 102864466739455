import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import api_amr from '../services/api_amr'


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import ReturnSidebarRoutes from "../components/Sidebar/ReturnSidebarRoutes";
import DropdownMenu from "components/Sidebar/DropdownMenu";

// paginas
import CriarPost from "views/Posts/CriarPost/CriarPost";
import LandingPage from "views/Posts/pages/LandingPage";
import RenderPage from "views/Posts/pages/RenderPage";
import New from "views/News/New";
import Manutencao from "views/Manutencao/Manutencao";
import { getToken } from '../utils/utils';

// Icones
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import logo from "assets/img/logo_amr.png";
import logoIcon from "assets/img/iconeAMR.png";
import { useMediaQuery, AppBar, Toolbar, IconButton } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
let ps;

function getPermissoes() {
  const permissoes = localStorage.getItem('permissoes')

  if (permissoes === null) {
    return ''
  }
  else {
    return JSON.parse(permissoes)
  }
}

const switchRoutes = (
  <Switch>
    {ReturnSidebarRoutes().map((prop, key) => {
      if (prop.layout === "/pages") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Route
      path={'/pages/Manutencao'}
      component={Manutencao}
    />
    <Route
      path={'/pages/Noticias'}
      component={LandingPage}
    />
    {getPermissoes().gerenciar_posts === 1 ?
      <Route
        path={'/pages/CriarPost'}
        component={CriarPost}
      />
      : null}
    <Route
      path={'/pages/Noticia/:nid'}
      component={RenderPage}
    />
    {getPermissoes().news === 1 ?
      <Route
        path={'/pages/New/:nid'}
        component={New}
      />
      : null}
    <Redirect from="/pages" to="/pages/Noticias" />
  </Switch>
);

const useStyles = makeStyles(styles);
export default function Admin(props, { ...rest }) {
  const [mostrarSidebar, setMostrarSidebar] = React.useState(false)
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [color, setColor] = React.useState("blue");
  const smallerThan600px = useMediaQuery("(max-width: 600px)")
  const [mostrarmenu, setMostrarmenu] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const handleDrawerToggle = (isExpanded) => {
    console.log("handleDrawerToggle called:", isExpanded);
    setMostrarSidebar(isExpanded);
    console.log("mostrarSidebar set to:", isExpanded);
  };
  const handleMenuToggle = () => {
    setDropdownOpen(!dropdownOpen); // Alterna o estado de visibilidade da sidebar
  };
  useEffect(() => {
    async function fetchMyAPI() {
      const response_permissions = await api_amr.get('/get_user_permissions', { headers: { 'x-api-key': getToken() } })
      localStorage.setItem('permissoes', JSON.stringify(response_permissions.data[0]))
    }
    fetchMyAPI();
  }, [])



  return (
    <div style={{ height: '100%' }}>
      <div>
        {smallerThan600px && (
          <AppBar position="static" style={{ backgroundColor: '#f5f5f5' }}> {/* cinza muito claro */}
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleMenuToggle}
                className={classes.menuButton}
              >
                <MenuIcon style={{ color: '#606060' }} />
              </IconButton>
            </Toolbar>
          </AppBar>
        )}

        {!smallerThan600px &&
          <Sidebar
            isSidebarOpen={mostrarSidebar || (!mostrarSidebar)} // Here's the message we're sending it
            routes={ReturnSidebarRoutes(true)}
            logo={logo}
            logoMin={logoIcon}
            color={color}
            onSidebarToggle={handleDrawerToggle}
            {...rest}
          />
        }
        <DropdownMenu
          open={dropdownOpen}
          onClose={() => setDropdownOpen(false)}
          routes={ReturnSidebarRoutes(true)}
        />
      </div>
      <div className={smallerThan600px ? null : mostrarSidebar ? classes.mainPanel : classes.mainPanel2} ref={mainPanel}>
        <div className={classes.content}>
          <div>{switchRoutes}</div>
        </div>
      </div>
    </div>
  );
}
