import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    buttonDiv: {
        margin: 10,
        position: 'absolute',
        right: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center'
    },

    postDiv: {
        position: 'relative',
        padding: 20,
        marginBottom: 20,
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)',
    },

    textDiv: {
        marginLeft: 10,
        width: '80%',
        maskImage: 'linear-gradient(black 20%, transparent);'
    },

    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },
    },

    mainDiv: {
        fontFamily: 'Poppins',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 100,
        paddingLeft: 20
    },

    miniButtonRed: {
        padding: 0,
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: 40,
        width: 40,
        background: '#E03C3C',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(224, 60, 60, 0.7)",
        },
        '&:active': {
            transform: 'translate(0, 2px)',
            background: "#bf3232",
        },
    },

    infiniteScroll: {
        padding: 20,
        border: '1px solid lightgrey',
        width: '90%'
    }
}))
