import React, { useEffect, useState } from 'react';
import api_amr from 'services/api_amr';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { getToken } from 'utils/utils';
import LoaderGif from '../../../assets/img/amr_loader_transparent.gif';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

export default function RelTrocaCard({ parametros }) {
    const classes = useStyles();

    const [relTrocaCardData, setRelTrocaCardData] = useState(null)

    useEffect(() => {
        const fetchMyApi = async () => {
            const responseGetRelTrocaCard = await api_amr.get('/return_component_relTroca', {
                params: {
                    'formato': 'card', 'parametros': { ...parametros, data: '2024-01-01' }
                },
                headers: { 'x-api-key': getToken() }
            })

            setRelTrocaCardData(responseGetRelTrocaCard.data)
        }

        fetchMyApi()
    }, [parametros])

    const formatDateToBrazilian = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    };

    if (relTrocaCardData) {
        return (
            <div style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
                <Box style={{ width: '100%', height: '100%', }}>

                    <Grid container style={{ paddingRight: '2px', }}>

                        {/* General Info */}
                        <Grid item xs={12} >
                            <div> <span className={classes.nomeMateriaText}>{relTrocaCardData.info.nome_cultura}</span></div>

                            <div>
                                <span className={classes.materiaText}>Materia:</span>
                                <span className={classes.nomeMateriaText}>{relTrocaCardData.info.nome_materia}</span>
                            </div>

                            <div>
                                <span className={classes.materiaText}>Porto:</span>
                                <span className={classes.nomeMateriaText}>{relTrocaCardData.info.nome_porto}</span>
                            </div>
                        </Grid>

                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                        {/* Ultimo Valor */}
                        <Grid item xs={12} style={{ marginTop: '10px' }}>
                            <Typography className={classes.titleText} variant="subtitle1">Ultimo Valor:</Typography>

                            <div>
                                <span className={classes.materiaText}>Data:</span>
                                <span className={classes.nomeMateriaText}>{formatDateToBrazilian(relTrocaCardData.dados.ultimo_valor.data)}</span>
                            </div>

                            <div>
                                <span className={classes.materiaText}>Relação de Troca:</span>
                                <span className={classes.nomeMateriaText}>{relTrocaCardData.dados.ultimo_valor.rel_troca}</span>
                            </div>
                        </Grid>
                        {/* Info Atual */}
                        <Grid item xs={12} style={{
                            marginTop: '10px',
                        }}>
                            <Typography className={classes.titleText} variant="subtitle1">Informações Atuais:</Typography>

                            <div>
                                <span className={classes.materiaText}> Media Anual:</span>
                                <span className={classes.nomeMateriaText}>{relTrocaCardData.dados.info_atual.media_anual.rel_troca}</span>
                            </div>

                            <div>
                                <span className={classes.materiaText}> Media Mais Alta:</span>
                                <span className={classes.nomeMateriaText}>{relTrocaCardData.dados.info_atual.media_mais_alta.rel_troca}  ({relTrocaCardData.dados.info_atual.media_mais_alta.mes})</span>
                            </div>

                            <div>
                                <span className={classes.materiaText}>  Media Mais Baixa:</span>
                                <span className={classes.nomeMateriaText}>{relTrocaCardData.dados.info_atual.media_mais_baixa.rel_troca} ({relTrocaCardData.dados.info_atual.media_mais_baixa.mes})</span>
                            </div>
                        </Grid>
                    </Grid>

                </Box>
            </div >
        );
    }
    else {
        return (
            <div className={classes.mainWrapper}>
                <div className={classes.loader}>
                    <img src={LoaderGif} alt="Loading..." className={classes.loader} />
                </div>
            </div>
        );
    }
}

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        backgroundColor: 'white',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    biggerTextVariacao: {
        fontWeight: 700,
        fontSize: '15px',
        marginRight: 10, // Reduce marginRight
        color: '#3D3D3D',
        fontFamily: 'Poppins',
    },

    biggerTextVariacao1: {
        justifyContent: 'space bwtween',
        fontWeight: 700,
        fontSize: '14px',
        color: '#3D3D3D',
        fontFamily: 'Poppins',
    },

    titleText: {
        fontWeight: 'bold',
        fontSize: '11px',
        color: '#939598',
        fontFamily: 'Poppins',
    },

    biggerTextVariacao: {
        fontWeight: 700,
        fontSize: '11px', // Set to 15px
        marginRight: 10,
        color: '#3D3D3D',
        fontFamily: 'Poppins',
    },

    biggerTextVariacao1: {
        justifyContent: 'space bwtween',
        fontWeight: 700,
        fontSize: '14px',
        color: '#3D3D3D',
        fontFamily: 'Poppins',
    },

    loader: {
        width: "70%",
        height: "auto",
        borderRadius: "10px",
        opacity: 0.9
    },

    materiaText: {
        justifyContent: 'space bwtween',
        fontWeight: 600,
        fontSize: '13px',
        color: '#3D3D3D',
        fontFamily: 'Poppins',
        marginRight: theme.spacing(1),
    },

    nomeMateriaText: {
        justifyContent: 'space bwtween',
        fontWeight: 400,
        fontSize: '13px',
        color: '#3D3D3D',
        fontFamily: 'Poppins',
    },
}))
