import React, { useEffect, useState } from 'react';
import api_amr from 'services/api_amr';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { getToken } from 'utils/utils';
import LoaderGif from '../../../assets/img/amr_loader_transparent.gif';

export default function RelTrocaTabela({ parametros }) {
    const classes = useStyles();
    const item_ = {
        'contagem': 'Contagem', 'media': 'Média', 'media_rs': 'Média em R$', 'mediana': 'Mediana', 'mediana_rs': 'Mediana em R$',
        'minimo': 'Mínimo', 'minimo_rs': 'Mínimo em R$', 'maximo': 'Máximo', 'maximo_rs': 'Máximo em R$', 'desvio_padrao': 'Desvio Padrão', 'desvio_padrao_rs': 'Desvio Padrão em R$',
        'mudanca_ano_anterior': 'Mudança do Ano Anterior', 'mudanca_ano_anterior_rs': 'Mudança do Ano Anterior em R$', 'ultimo_valor': 'Último Valor', 'ultimo_valor_rs': 'Último Valor em R$'
    }

    const [responseGetRelTrocaTabela, setResponseGetRelTrocaTabela] = useState(null)

    useEffect(() => {
        const fetchMyApi = async () => {
            const responseGetRelTrocaTabela = await api_amr.get('/return_component_relTroca', {
                params: {
                    'formato': 'tabela', 'parametros': { ...parametros, data: '2024-01-01' }
                },
                headers: { 'x-api-key': getToken() }
            })

            setResponseGetRelTrocaTabela(responseGetRelTrocaTabela.data)
        }

        fetchMyApi()
    }, [parametros])

    if (responseGetRelTrocaTabela) {
        return (
            <div className={classes.mainDiv1}>
                <TableContainer>
                    <div className={classes.linhaTexto}>
                        <span className={classes.materiaText}>Materia:</span>
                        <span className={classes.nomeMateriaText}>
                            {responseGetRelTrocaTabela?.info?.nome_materia ?? 'N/A'}
                        </span>
                    </div>
                    <div className={classes.linhaTexto}>
                        <span className={classes.materiaText}>Porto:</span>
                        <span className={classes.nomeMateriaText}>
                            {responseGetRelTrocaTabela?.info?.nome_porto ?? 'N/A'}
                        </span>
                    </div>
                    <div className={classes.linhaTexto}>
                        <span className={classes.materiaText}>Cultura:</span>
                        <span className={classes.nomeMateriaText}>
                            {responseGetRelTrocaTabela?.info?.nome_cultura ?? 'N/A'}
                        </span>
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCell}>
                                    <b className={classes.materiaText}>Métrica</b>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <b className={classes.materiaText}>Ano Atual</b>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <b className={classes.materiaText}>Ano Anterior</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(responseGetRelTrocaTabela?.dados ?? {}).map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className={classes.tableCell}>
                                        <b className={classes.materiaText}>{item_[item]}</b>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <b className={classes.nomeMateriaText}>
                                            {responseGetRelTrocaTabela?.dados[item]?.ano_atual ?? 'N/A'}
                                        </b>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <b className={classes.nomeMateriaText}>
                                            {responseGetRelTrocaTabela?.dados[item]?.ano_anterior ?? 'N/A'}
                                        </b>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    } else {
        return (
            <div className={classes.mainDiv2}>
                <div className={classes.loader}>
                    <img src={LoaderGif} alt="Loading..." className={classes.loader} />
                </div>
            </div>
        );
    }
}

const useStyles = makeStyles((theme) => ({
    mainDiv1: {
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: 'white'
    },

    mainDiv2: {
        backgroundColor: 'white',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    tableCell: {
        padding: 0,
    },

    materiaText: {
        justifyContent: 'space bwtween',
        fontWeight: 600,
        fontSize: '13px',
        color: '#3D3D3D',
        fontFamily: 'Poppins',
        marginRight: theme.spacing(1),
    },

    nomeMateriaText: {
        justifyContent: 'space bwtween',
        fontWeight: 400,
        fontSize: '13px',
        color: '#3D3D3D',
        fontFamily: 'Poppins',
    },

    flexGrow: {
        flexGrow: 1,
    },

    root: {
        "& .MuiDataGrid-cell": {
            borderRight: "1px solid #000"
        }
    },

    green: {
        color: "green"
    },

    red: {
        color: "red"
    },

    grey: {
        color: "grey"
    },

    loader: {
        width: "70%",
        height: "auto",
        borderRadius: "10px",
        opacity: 0.9
    },
}));