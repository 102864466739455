import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, Button, Typography } from '@material-ui/core';
import api_amr from "../../../services/api_amr";
import { getToken } from "utils/utils";
import { makeStyles } from "@material-ui/core/styles";

export default function EditComponent({ open, onClose, layoutItem }) {
    const [titleBuffer, setTitleBuffer] = useState('')
    const classes = useStyles()

    const handleSaveEdit = async () => {
        try {
            let aux = titleBuffer || layoutItem.component_title;

            onClose();

            const response = await api_amr.post('/edit_component', {
                component_title: aux,
                component_id: layoutItem.id_component
            }, { headers: { 'x-api-key': getToken() } });

            if (response.status === 200) {
                window.location.reload()
            } else {
                alert("Houve um problema ao atualizar o componente. Por favor, tente novamente mais tarde.");
            }
        } catch (error) {
            alert("Ocorreu um erro ao atualizar o componente.");
        }
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle style={{ fontFamily: 'Poppins' }}>
                Edição do Componente
            </DialogTitle>

            <DialogContent>
                <div>
                    <Typography>
                        Nome
                    </Typography>

                    <TextField
                        onChange={(e) => setTitleBuffer(e.target.value)}
                        fullWidth
                        defaultValue={layoutItem.component_title} variant="outlined" size="small"
                    />
                </div>

                <div className={classes.buttons}>
                    <Button size="small" onClick={onClose} variant="outlined">Cancelar</Button>
                    <Button style={{ marginLeft: 10 }} size="small" onClick={handleSaveEdit} color="primary" variant="contained">Salvar</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

const useStyles = makeStyles({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 20
    }
})