import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

export default function ImageComponent({ parametros }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    console.log("Clicado!"); // Para depuração
    setOpen(true);
  };

  const handleClose = () => {
    console.log('é pra fechar');
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleClickOpen}>
        <img
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain'
          }}
          alt=""
          src={parametros.link}
        />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md" // Define o maxWidth
        fullWidth={true} // Faz o Dialog ocupar toda a largura
        PaperProps={{
          style: {
            height: 'auto', // 80% da altura da tela
            width: '80vw',  // 80% da largura da tela
          },
        }}
      >
        <DialogContent>
          <img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }}
            alt=""
            src={parametros.link}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
