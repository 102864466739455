import React, { useEffect, useState } from "react";
import api_amr from 'services/api_amr';
import { getToken } from '../../../../utils/utils'
import { getId } from '../../../../utils/utils'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles({
    tableContainer: {
        maxHeight: '100%',
        overflow: 'auto',
        marginBottom: 10
    },
    autoCompleteFieldBig: {
        marginTop: 10,
        marginRight: 10,
        width: 250,
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        borderRadius: 6,
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: 150,
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },
    },
});

function TableUsabilidadeUser({ sortedData, sortedColumn, sortOrder, handleUserSelection }) {
    const [userList, setUserList] = useState([]);
    const classes = useStyles();
    const [userUsabilidade, setUserUsabilidade] = useState(null);
    const [tipo, setTipo] = useState('mes');
    const [ano, setAno] = useState(new Date().getFullYear());
    const [mes, setMes] = useState('');
    const [userId, setUserId] = useState(getId());
    const [loading, setLoading] = useState(true);



    useEffect(() => {
        const fetchUserDashData = async () => {
            try {
                const responseReturnDashboard = await api_amr.get('/return_DashBoardUsabilidade', {
                    params: {
                        'ano': ano, 'mes': mes, 'tipo': tipo, 'all_info': 0, 'id_user': userId
                    },
                    headers: { 'x-api-key': getToken() }
                });

                if (responseReturnDashboard.data === 'NOK') {
                    console.log('Server responded with NOK.');
                } else {
                    console.log('Server response:', responseReturnDashboard.data);

                    if (responseReturnDashboard.data && responseReturnDashboard.data.paginas) {
                        const parsedPaginasData = JSON.parse(responseReturnDashboard.data.paginas);
                        setUserUsabilidade(parsedPaginasData);
                        console.log('tabela inferior direito', parsedPaginasData.data)
                    } else {
                        console.log('responseReturnDashboard.data or responseReturnDashboard.data.paginas is undefined');
                    }
                    setLoading(false);
                }
            } catch (error) {
                console.error('Failed to fetch news data: ', error);
            }
        };

        const handleUserSelectionTableUsabilidadeUser = (user) => {
            handleUserSelection(user ? user.name : '');
        };

        const fetchUserListData = async () => {
            try {
                const responseUsersList = await api_amr.get('/get_user_info', {
                    params: {
                        'all': 1,
                    },
                    headers: { 'x-api-key': getToken() }
                });

                if (responseUsersList.data === 'NOK') {
                    throw new Error('Erro ao Carregar DashBoard.');
                } else {
                    setUserList(responseUsersList.data);
                }
            } catch (error) {
                console.error('Failed to fetch news data: ', error);
            }
        }

        fetchUserDashData();
        fetchUserListData();

    }, [userId]);

    const handleUserSelectionTableUsabilidadeUser = (user) => {
        handleUserSelection(user ? user.name : '');
    };

    return (
        <div >
            {loading ?
                <div>Carregando...</div> :
                <div className={classes.tableContainer}>
                    <div style={{ marginBottom: 15 }}>
                        <Autocomplete
                            options={userList}
                            getOptionLabel={(option) => option.name}
                            className={classes.autoCompleteFieldBig}
                            renderInput={(params) => <TextField {...params} label="Escolha usuário" variant="outlined" />}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setUserId(newValue.id);
                                    handleUserSelectionTableUsabilidadeUser(newValue);
                                } else {
                                    setUserId('');
                                    handleUserSelectionTableUsabilidadeUser(null);
                                }
                            }}
                        />
                    </div>
                    <TableContainer component={Paper}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Página</TableCell>
                                    <TableCell align="left">
                                        Total {sortedColumn === "total" && <span>{sortOrder === "asc" ? "▲" : "▼"}</span>}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userUsabilidade && userUsabilidade.data.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        <TableCell align="left">{row[1]}</TableCell>
                                        <TableCell align="left">{row[3]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
        </div>
    );
}


export default TableUsabilidadeUser;