import React from 'react';
import { List, ListItem, ListItemText, Drawer, Icon } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames'; // assumindo que você está usando o pacote 'classnames'
import { makeStyles } from '@material-ui/core/styles';

import {
    defaultFont,
    grayColor,
    hexToRgb
  } from "assets/jss/material-dashboard-react.js";


// Exemplo de função activeRoute
const activeRoute = (routeName) => {
  return window.location.pathname === routeName;
};

const DropdownMenu = ({ open, onClose, routes, color }) => {
  const classes = useStyles();
  const handleItemClick = () => {
    onClose(); // Chama a função onClose passada como prop para fechar o Drawer
  };

  return (
    <Drawer anchor="top" open={open} onClose={onClose}>
      <List>
        {routes.map((prop, key) => {
          if (prop.name) {
            return (
              <NavLink
                to={prop.layout + prop.path}
                className={classes.item}
                activeClassName="active"
                key={key}
                onClick={handleItemClick} // Adiciona o evento de clique que fecha o menu
              >
                <ListItem button className={classNames(classes.itemLink, { [classes[color]]: activeRoute(prop.layout + prop.path) })}>
                  {typeof prop.icon === "string" ? (
                    <Icon className={classNames(classes.itemIcon, { [classes[color]]: true })}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={classNames(classes.itemIcon, { [classes[color]]: true })} />
                  )}
                  <ListItemText
                    primary={prop.name}
                    className={classNames(classes.itemText, { [classes[color]]: true, [classes.textHidden]: !open })}
                    disableTypography={true}
                  />
                </ListItem>
              </NavLink>
            );
          }
          return null;
        })}
      </List>
    </Drawer>
  );
};
const useStyles = makeStyles(theme => ({
    drawerPaper: {
      backgroundColor: theme.palette.background.paper, // Use a cor de fundo do tema
      color: '#383838', // Cor do texto adaptada da sidebar
      ...defaultFont,
      width: '100%', // Ocupa a largura toda para o menu suspenso
      zIndex: 1300, // Mantenha em linha com a z-index do Material-UI para drawers
      boxShadow: `0 8px 10px -5px rgba(${hexToRgb(grayColor[6])}, 0.2)`, // Sombra adaptada da sidebar
      // Adicione mais estilos conforme necessário
    },
    list: {
      // Estilos para a lista adaptados da sidebar
      marginTop: "5px",
      paddingLeft: "0",
      paddingTop: "0",
      paddingBottom: "0",
      marginBottom: "0",
      listStyle: "none",
    },
    item: {
      // Estilos para os itens da lista adaptados da sidebar
      textDecoration: "none",
      '&:hover': {
        color: '#A3CC51', // Cor ao passar o mouse adaptada da sidebar
      }
    },
    itemLink: {
      // Estilos para os links dos itens adaptados da sidebar
      transition: "all 300ms linear",
      borderRadius: "3px",
      display: "flex",
      alignItems: "center",
      padding: "10px 15px",
      textDecoration: "none",
      '&:hover': {
        backgroundColor: theme.palette.action.hover, // Cor de fundo ao passar o mouse
      },
      ...defaultFont,
    },
    itemIcon: {
      // Estilos para os ícones dos itens adaptados da sidebar
      color: "#383838",
      marginRight: "15px",
      '&:hover': {
        color: '#A3CC51',
      }
    },
    itemText: {
      // Estilos para o texto dos itens adaptados da sidebar
      ...defaultFont,
      color: '#383838',
      '&:hover': {
        color: '#A3CC51',
      }
    },
    // ... Adicione mais adaptações de estilos conforme necessário ...
  }));

export default DropdownMenu;
