import React, {useEffect} from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/img/logo_amr.png';
import api_amr from "../../services/api_amr";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        margin: theme.spacing(2),
        fontFamily: 'Poppins',
    },
    message: {
        color: theme.palette.text.secondary,
        margin: theme.spacing(2),
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
}));

const Manutencao = () => {
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        
        const fetchData = async () => {
            try {
                await api_amr.get('/login', { timeout: 5000 });
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 405) {
                        history.push('/');
                        return;
                    }
                    console.error("API is not running, status code:", error.response.status);
                } else if (error.request) {
                    console.error("API is not running, no response received:", error.request);
                } else {
                    console.error("Error during login:", error);
                }
            }
        };

        fetchData();
    }, [history]);

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} md={5} >
                <Typography variant="h2" className={classes.title}>
                    Voltaremos Logo.
                </Typography>
                <Typography variant="body1" className={classes.message}>
                    Caros associados,
                    <br />
                    Nosso plataforma está temporariamente fora do ar para manutenção.
                    <br />
                    <br />
                    Agradecemos sua compreensão!
                    <br />
                    Atenciosamente,
                    <br />
                    Equipe AMR Business Intelligence
                    <br />
                    <br />
                    <img src={logo} width={200} />
                </Typography>


            </Grid>
            <Grid item xs={12} md={5} >
                {/* possivel imagem */}
            </Grid>
        </Grid>
    );
};

export default Manutencao
