import React, { useState } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
} from '@material-ui/core';

export default function PrecosRegistradosTable({ filteredData, handleDelete, dolar, handleUsarPreco }) {
  const classes = useStyles();

  // Estados para paginação
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Handlers para mudança de página e de linhas por página
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const adjustDateToLocal = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return localDate.toISOString().split('T')[0];
  };

  const formatCurrency = (value, currency) => {
    if (value == null || isNaN(value)) return '-';
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  return (
    <Paper className={classes.container}>
      <TableContainer>
        <Table className={classes.table} aria-label="Tabela de Preços Registrados">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>Data Registro</TableCell>
              <TableCell className={classes.headerCell}>Empresa</TableCell>
              <TableCell className={classes.headerCell}>Porto</TableCell>
              <TableCell className={classes.headerCell}>Origem</TableCell>
              <TableCell className={classes.headerCell}>Destino</TableCell>
              <TableCell className={classes.headerCell}>Volume (T)</TableCell>
              <TableCell className={classes.headerCell}>Frete</TableCell>
              <TableCell className={classes.headerCell}>Fonte</TableCell>
              <TableCell className={classes.headerCell}>Produto</TableCell>
              <TableCell className={classes.headerCell}>Embalagem</TableCell>
              <TableCell className={classes.headerCell}>Preço Pago ($/R$)</TableCell>
              <TableCell className={classes.headerCell}>CFR Equiv. ($/R$)</TableCell>
              <TableCell className={classes.headerCell}>CFR Plataforma ($/R$)</TableCell>
              <TableCell className={classes.headerCell}>Dólar</TableCell>
              <TableCell className={classes.headerCell}>Margem ($/R$)</TableCell>
              <TableCell className={classes.headerCell}>Data Pagamento</TableCell>
              <TableCell className={classes.headerCell}>Juros</TableCell>
              <TableCell className={classes.headerCell}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : filteredData
            ).map((row) => {
              const margemUSD = parseFloat(row.margem);
              const margemBRL = margemUSD * parseFloat(dolar);

              // Limitar margem entre -100 e 100
              const cappedMargemUSD = Math.max(-100, Math.min(100, margemUSD));

              // Calcular o matiz correspondente
              const hue = ((-cappedMargemUSD + 100) / 200) * 120;

              // Definir cor de fundo usando HSL
              const margemBackgroundColor = `hsl(${hue}, 50%, 80%)`;

              return (
                <TableRow key={row.id}>
                  <TableCell className={classes.headerCell}>{adjustDateToLocal(row.data)}</TableCell>
                  <TableCell className={classes.headerCell}>{row.empresa}</TableCell>
                  <TableCell className={classes.headerCell}>
                    {row.nome_porto}
                  </TableCell>
                  <TableCell className={classes.headerCell}>
                    {row.produto_nacional === 1 ? row.origem_cidade+'-'+row.origem_UF : '-'}
                    
                  </TableCell>
                  <TableCell className={classes.headerCell}>
                    {row.destino_cidade}-{row.destino_UF}
                  </TableCell>
                  <TableCell className={classes.headerCell}>{row.volume}</TableCell>
                  <TableCell className={classes.headerCell}>
                    {formatCurrency(parseFloat(row.frete), 'BRL')}
                  </TableCell>

                  <TableCell className={classes.headerCell}>
                    {row.associado === -1 ? row.fonte_dados : row.name_associado}
                  </TableCell>
                  <TableCell className={classes.headerCell}>{row.produto_abreviacao}</TableCell>
                  <TableCell className={classes.headerCell}>{row.embalagem}</TableCell>
                  {/* Preço Pago ($/R$) */}
                  <TableCell className={classes.headerCell}>
                    {row.preco_compra ? (
                      <>
                        <strong>{formatCurrency(parseFloat(row.preco_compra), 'USD')}</strong>
                        <br />
                        <span style={{ color: 'gray', fontSize: '0.8em' }}>
                          (
                          {formatCurrency(
                            parseFloat(row.preco_compra) * parseFloat(row.dolar),
                            'BRL'
                          )}
                          )
                        </span>
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  {/* CFR Equiv. ($/R$) */}
                  <TableCell className={classes.headerCell}>
                    {row.cfr_eqv ? (
                      <>
                        <strong>{formatCurrency(parseFloat(row.cfr_eqv), 'USD')}</strong>
                        <br />
                        <span style={{ color: 'gray', fontSize: '0.8em' }}>
                          (
                          {formatCurrency(
                            parseFloat(row.cfr_eqv) * parseFloat(row.dolar),
                            'BRL'
                          )}
                          )
                        </span>
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  {/* CFR Plataforma ($/R$) */}
                  <TableCell className={classes.headerCell}>
                    {row.cfr_semana ? (
                      <>
                        <strong>{formatCurrency(parseFloat(row.cfr_semana), 'USD')}</strong>
                        <br />
                        <span style={{ color: 'gray', fontSize: '0.8em' }}>
                          (
                          {formatCurrency(
                            parseFloat(row.cfr_semana) * parseFloat(row.dolar),
                            'BRL'
                          )}
                          )
                        </span>
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  {/* Dólar */}
                  <TableCell className={classes.headerCell}>
                    {row.dolar ? (
                      <strong>{formatCurrency(parseFloat(row.dolar), 'BRL')}</strong>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  {/* Margem ($/R$) */}
                  <TableCell
                    className={classes.headerCell}
                    style={{ backgroundColor: margemBackgroundColor }}
                  >
                    {row.margem ? (
                      <>
                        <strong>{formatCurrency(margemUSD, 'USD')}</strong>
                        <br />
                        <span style={{ color: 'gray', fontSize: '0.8em' }}>
                          ({formatCurrency(margemBRL, 'BRL')})
                        </span>
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell className={classes.headerCell}>
                    {row.data_pagamento !== 'None' ? adjustDateToLocal(row.data_pagamento) : ''}
                  </TableCell>
                  <TableCell className={classes.headerCell}>
                    {row.juros_usd !== -1 || row.juros_brl !== -1 ? (
                      <>
                        {row.juros_usd !== -1 && (
                          <>
                            <strong>USD: {row.juros_usd}%</strong>
                            <br />
                          </>
                        )}
                        {row.juros_brl !== -1 && (
                          <>
                            <strong>BRL: {row.juros_brl}%</strong>
                          </>
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell className={classes.headerCell}>
                    <Button onClick={() => handleDelete(row.id)}>Deletar</Button>
                    <Button onClick={() => handleUsarPreco(row)}>Usar</Button>
                  </TableCell>
                
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Controle de paginação movido para fora da tabela */}
      <TablePagination
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
      />
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    marginTop: theme.spacing(2),
    // Adicione overflow se necessário
    // overflowX: 'auto',
  },
  headerCell: {
    fontSize: '0.7rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));
