import React, { useState, useEffect, useRef } from 'react';
import useStyles from '../styles/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Line, Bar } from 'react-chartjs-2';
import { Box } from "@material-ui/core";
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import logo from "../../../assets/img/logo_arm_opaque.png";
import { getToken } from '../../../utils/utils'

import api_amr from 'services/api_amr';

const GraficoDashboard = ({ hoveredNews, chartData, materia, selectedMateria, selectedPorto, datePick, handleSetPosts }) => {
    const [dadosHist, setDadosHist] = useState('');

    useEffect(() => {
        async function fetchMyAPI() {
            const newDadosHist = await getHistorico(1, 1);
            setDadosHist({ ...newDadosHist });
        }

        fetchMyAPI();
    }, [hoveredNews, chartData, materia]);

    const chartRef = useRef(null);

    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                const responseReturnDashboard = await api_amr.get('/return_DashBoard', {
                    params: { 'id_materia': selectedMateria, 'porto': selectedPorto, 'data': datePick },
                    headers: { 'x-api-key': getToken() }
                })

                if (responseReturnDashboard.data === 'NOK') {
                    throw new Error('Erro ao Carregar DashBoard.');
                } else {
                    handleSetPosts(responseReturnDashboard.data);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchNewsData();
    }, [selectedMateria, selectedPorto, datePick]);

    const getHoveredDateIndex = () => {
        if (!hoveredNews || !hoveredNews.post_date || !chartData) return -1;
        const hoveredDate = new Date(hoveredNews.post_date);

        let closest = Infinity;
        let closestIndex = -1;

        chartData.forEach((item, index) => {
            const currentDate = new Date(item.date);
            const diff = Math.abs(hoveredDate - currentDate);

            if (diff < closest) {
                closest = diff;
                closestIndex = index;
            }
        });

        return closestIndex;
    };


    const classes = useStyles();

    async function getHistorico(porto, id_materia) {
        const hoveredIndex = getHoveredDateIndex();

        let state = {
            labels: Array.isArray(chartData) ? chartData.map((item) => item.date) : [],
            options: {
                elements: {
                    line: {
                        tension: 0.2 // This is your line smoothing parameter
                    }
                },

                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                stacked: false,
                plugins: {
                    title: {
                        display: true,
                        text: 'Histórico Preço'
                    },
                    tooltip: {
                        enabled: true,

                    },

                },
                scales: {
                    x: {
                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false
                        }
                    },
                    y: {

                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false
                        }
                    }
                },
            },
            datasets: [
                {
                    label: materia.abreviacao,
                    borderColor: '#A3CC51',
                    backgroundColor: '#A3CC51',
                    data: Array.isArray(chartData) ? chartData.map((item) => item.cfr) : [],
                    pointBackgroundColor: Array.isArray(chartData) ? chartData.map((_, index) => {
                        return index === hoveredIndex ? 'red' : '#A3CC51';
                    }) : [],
                    pointRadius: Array.isArray(chartData) ? chartData.map((_, index) => {
                        return index === hoveredIndex ? 12 : 3;
                    }) : [],
                },
            ],
        }
        return state
    }

    return (
        <div >
            {dadosHist !== '' ?
                <div style={{ display: 'flex', flexDirection: 'row', backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} >
                    <Line ref={chartRef} data={dadosHist} options={dadosHist.options} Legend />
                    <div>
                        <Box id="tooltip-container" className={classes.tooltipContainer}>
                        </Box>
                    </div>

                </div> : <CircularProgress style={{ 'color': '#A3CC51' }} />}
        </div>
    );
};


export default GraficoDashboard;