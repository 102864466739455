import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getToken, checarPermissao, DataFormatoCerto, DataMaior } from '../../utils/utils'
import api_amr from '../../services/api_amr'
import Loader from 'react-loader-spinner';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid } from '@material-ui/core';

import { Autocomplete } from "@material-ui/lab";

function FechamentosPadrao(props) {
    const [permitido, setPermitido] = useState(false)

    const [nomeFechamento, setNomeFechamento] = useState('');
    const [materiaPrima, setMateriaPrima] = useState(null);
    const [materiaPrimaTabela, setMateriaPrimaTabela] = useState([]);
    const [listaMateriaPrima, setListaMateriaPrima] = useState('');
    const [listaMateriaPrimaArray, setListaMateriaPrimaArray] = useState([]);
    const [listaIdPeso, setListaIdPeso] = useState('');
    const [quilos, setQuilos] = useState('');
    const [materiasAutocomplete, setMateriasAutocomplete] = useState('');
    const [fechamentosManuais, setFechamentosManuais] = useState('')

    const [npkFechamento, setNpkFechamento] = useState([])

    const [arrayIdweight, setArrayIdweight] = useState([])

    const [dustrol, setDustrol] = useState(false);
    const [inibidor, setInibidor] = useState(false);
    const [pesoInibidor, setPesoInibidor] = useState('');


    const [checkboxDisable, setCheckboxDisable] = useState(false);
    const [exibirMaterias, setExibirMaterias] = useState(false);
    const [atualizando, setAtualizando] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true);
    const [abrirNegocio, setAbrirNegocio] = useState(false);
    const [micronutri, setMicronutri] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [compatArray, setCompatArray] = useState('')
    const [compat, setCompat] = useState('')

    const classes = useStyles();


    useEffect(() => {
        async function fetchMyAPI() {
            setPermitido(await checarPermissao('fechamentos_padrao', props))

            responseMicronytri()

            responseFechamentosManuais()

            responseMaterias()

            const response_compat = await api_amr.get('/get_compatibilidade', { headers: { 'x-api-key': getToken() } });
            setCompat(response_compat.data);

        }
        fetchMyAPI();

    }, []);

    // UseEffect PARA TODA FUNÇAO QUE DEPENDE DE UPDATE DO STATE adiconar materias.


    async function responseMaterias() {
        // Response para materias e IF para filtro de dustrol
        const response_get_materias = await api_amr.get('/get_materias', { headers: { 'x-api-key': getToken() } });
        setMateriasAutocomplete(response_get_materias.data);

        if (dustrol === true) {
            setMateriasAutocomplete(response_get_materias.data.filter(x => x.abreviacao !== 'UR' && x.abreviacao !== 'NAM' && x.abreviacao !== 'NAM_EST'));
        }
    }

    async function responseMicronytri() {
        const response_micronutri = await api_amr.get('/return_micro_nutrientes', { headers: { 'x-api-key': getToken() } });
        const micronutriWithPeso = response_micronutri.data.map((item) => ({ ...item, peso: 0 }));
        setMicronutri(micronutriWithPeso);
    }

    function montaLista(materia, peso) {
        // validação
        if ((quilos === '') | (materiaPrima === '') | (!materiaPrima?.id_materias) | (nomeFechamento === '')) {
            alert('Alguns dos campos obrigatórios estão incompletos')
        } else {
            const idMateria = materiaPrima.id_materias;
            const abreviacao = materiaPrima.abreviacao;
            const n = materiaPrima.nitrogenio;
            const p = materiaPrima.fosforo;
            const k = materiaPrima.potassio;
            const input = {
                idMateria,
                peso,
                abreviacao,
                n,
                p,
                k,
            };

            const index = arrayIdweight.findIndex((item) => item.idMateria === idMateria);
            if (index === -1) {
                // add new object
                const newArrayIdweight = [...arrayIdweight, input];
                let checka = checkCompatibility(newArrayIdweight);
                if (checka === -1) {
                    alert('Produtos Incompatíveis');
                    return;
                }
                let passa_pesa = calculaNpk(newArrayIdweight);
                if (passa_pesa === false) {
                    return;
                }
                if (inibidor === true) {
                    adicionaInibidor(newArrayIdweight);
                } else {
                    setArrayIdweight(newArrayIdweight);
                }
            } else {
                // update existing object
                const newArrayIdweight = [...arrayIdweight];
                newArrayIdweight[index].peso += peso;
                let checka = checkCompatibility(newArrayIdweight);
                if (checka === -1) {
                    alert('Produtos Incompatíveis');
                    return;
                }
                let passa_pesa = calculaNpk(newArrayIdweight);
                if (passa_pesa === false) {
                    return;
                }
                if (inibidor === true) {
                    adicionaInibidor(newArrayIdweight);
                } else {
                    setArrayIdweight(newArrayIdweight);
                }
            }

            setQuilos('');
            setMateriaPrima(null);
            setAtualizando(true);
            // setListaMateriaPrimaArray((aux.split(";").slice(0, -1)));
        }
    }



    function checkCompatibility(array_new) {
        const ids = [];
        let aux = [];

        // Filter out items with micronutriente: true from array_new
        array_new = array_new.filter((item) => item.micronutriente !== true);

        for (let i = 0; i < array_new.length; i++) {
            if (array_new[i].abreviacao === "DU") {
                continue;
            }
            const filteredArr = compat.filter(
                (obj) => obj.id_materias === array_new[i].idMateria
            );
            aux[array_new[i].idMateria] = "";
            for (let a = i + 1; a < array_new.length; a++) {
                if (array_new[a].abreviacao === "DU") {
                    continue;
                }
                let abrev_compat = compat.filter(
                    (obj) => obj.id_materias === array_new[a].idMateria
                )[0].abreviacao;
                if (filteredArr[0]["id_" + array_new[a].idMateria.toString()] === 0) {
                    if (abrev_compat !== filteredArr[0]["abreviacao"]) {
                        aux[array_new[i].idMateria] =
                            aux[array_new[i].idMateria] +
                            " - " +
                            filteredArr[0]["abreviacao"] +
                            " x " +
                            abrev_compat;
                        aux[array_new[a].idMateria] =
                            aux[array_new[a].idMateria] +
                            " - " +
                            abrev_compat +
                            " x " +
                            filteredArr[0]["abreviacao"];
                    } else if (abrev_compat === filteredArr[0]["abreviacao"]) {
                        //pass
                    }
                } else if (filteredArr[0]["id_" + array_new[a].idMateria.toString()] === -1) {
                    return -1;
                }
            }
        }
        setCompatArray(aux);
        return 0;
    }


    function calculaNpk(array) {

        // Calcula NPK
        let totalWeight = 0;
        let totalN = 0;
        let totalP = 0;
        let totalK = 0;

        for (let i = 0; i < array.length; i++) {
            totalWeight += array[i].peso;
            totalN += ((array[i].n * array[i].peso) / 100);
            totalP += ((array[i].p * array[i].peso) / 100);
            totalK += ((array[i].k * array[i].peso) / 100);
        }

        const totalNPK = {
            n: (totalWeight === 0 ? 0 : ((totalN / totalWeight) * 100).toFixed(2)),
            p: (totalWeight === 0 ? 0 : ((totalP / totalWeight) * 100).toFixed(2)),
            k: (totalWeight === 0 ? 0 : ((totalK / totalWeight) * 100).toFixed(2))
        };


        if (totalWeight >= 1000) {
            totalWeight = Math.floor(totalWeight);
        } else {
            totalWeight = Math.ceil(totalWeight);
        }
        // Alert
        if (totalWeight > 1000) {
            alert('Total(kg) superior a 1000')
            setIsDisabled(true)
            return false
        }
        //se peso for 1000kg, libera botão salvar fechamento
        setNpkFechamento({ 'totalWeight':totalWeight, totalNPK });
        if (totalWeight === 1000) {
            setIsDisabled(false)
            return
        }
        else { setIsDisabled(true) }

        return { 'totalWeight':totalWeight, totalNPK };
    }


    function continuarFechamento() {
        if (dustrol === true) { adicionaDustrol() }
        if (inibidor === true) {
            // Alert Inibidor 
            alert('Aviso Inibidor: Ao selecionar Inibidor será adicionado automaticamente 2.5kg para cada 1000 kgs de UR')
        }
        setCheckboxDisable(true)
        setExibirMaterias(true)
    }

    async function salvarFechamento() {
        if (npkFechamento.totalWeight === 1000) {

            //Chamada para Postar Fechamento
            const response_insert_fechamento_padrao = await api_amr.post('/insert_fechamento_padrao', {
                'list_name': nomeFechamento,
                'nitrogenio': npkFechamento.totalNPK.n,
                'fosforo': npkFechamento.totalNPK.p,
                'potassio': npkFechamento.totalNPK.k,
                'listaIdPeso': JSON.stringify(arrayIdweight),
                'dustrol': dustrol.toString(),
                'inibidor': inibidor.toString(),
                'compatArray': JSON.stringify(compatArray)
            }, { headers: { 'x-api-key': getToken() } });

            if (response_insert_fechamento_padrao.data === 'OK') {
                alert('Fechamento Salvo com Sucesso')
                limpar()
            }
            if (response_insert_fechamento_padrao.data === 'existing_name') {
                alert('Erro ao salvar fechamento: Nome de fechameto já existente')
                limpar()
            }
        }
    }


    function adicionaDustrol() {

        if (dustrol === true) {   //monta string para salvar fechamento no back
            //alerta
            alert('Aviso dustrol: Ao selecinar DUSTROL serão adicionado 2 kgs desse produto na formulação e você não poderá selecionar as matérias UR, NAM e NAM_EST')
            //dustrol id 111 peso 2kg
            // Create an object with the values and push it to the array
            const peso = 2
            const idMateria = 111
            const abreviacao = 'DU'
            const n = 0
            const p = 0
            const k = 0
            const input = {
                idMateria,
                peso,
                abreviacao,
                n,
                p,
                k,
            };
            const newArrayIdweight = [...arrayIdweight, input]; // create a new array with the new element added
            setArrayIdweight(newArrayIdweight); // update the state with the new array
            calculaNpk(newArrayIdweight)
        }
    }
    console.log('compatArray', compatArray)

    function adicionaInibidor(arrayIdweight) {
        if (inibidor === true) {
            let pesoInibi = 0;
            for (let i = 0; i < arrayIdweight.length; i++) {
                if (arrayIdweight[i].idMateria === 81) {
                    pesoInibi += (2.5 / 1000) * arrayIdweight[i].peso;
                }
            }
            pesoInibi = parseFloat(pesoInibi.toFixed(2)); // Round pesoInibi to 2 decimal places

            // Check if NBPT is already in the list
            const nbptIndex = arrayIdweight.findIndex((item) => item.idMateria === 117);

            if (nbptIndex === -1) {
                // NBPT not found in list, add it
                const peso = pesoInibi;
                const idMateria = 117;
                const abreviacao = 'NBPT';
                const n = 0;
                const p = 0;
                const k = 0;
                const input = {
                    idMateria,
                    peso,
                    abreviacao,
                    n,
                    p,
                    k,
                };
                const newArrayIdweight = [...arrayIdweight, input]; // create a new array with the new element added
                setArrayIdweight(newArrayIdweight); // update the state with the new array
                calculaNpk(newArrayIdweight)
            } else {
                // NBPT already in list, update its weight
                const newArrayIdweight = [...arrayIdweight];
                newArrayIdweight[nbptIndex].peso = pesoInibi;
                setArrayIdweight(newArrayIdweight); // update the state with the new array
                calculaNpk(newArrayIdweight)
            }
        }
    }

    async function limpar() {
        //Limpa matérias-primas adicionadas
        responseMicronytri()
        setArrayIdweight([])
        setListaIdPeso('')
        setNomeFechamento('')
        setMateriaPrima(null)
        setMateriaPrimaTabela([])
        setListaMateriaPrima('')
        setListaMateriaPrimaArray([])
        setQuilos('')
        setNpkFechamento([])
        setAtualizando(false)
        setIsDisabled(true)
        responseFechamentosManuais()
        setDustrol(false)
        setInibidor(false)
        setCheckboxDisable(false)
        setExibirMaterias(false)
        setCompatArray('')
    };

    async function responseFechamentosManuais() {
        //Chamada para pegar fechamentos manuais
        const response_get_fechamentos_manuais = await api_amr.get('/get_fechamentos_manuais', { headers: { 'x-api-key': getToken() } });
        setFechamentosManuais(response_get_fechamentos_manuais.data)
    };

    //Arruma index para display na tebela
    const letters = materiaPrimaTabela.filter((item, index) => index % 2 === 0);
    const values = materiaPrimaTabela.filter((item, index) => index % 2 !== 0);

    // Fecha config avancadas
    const handleClickClose = () => {
        setAbrirNegocio(false);
    };
    const handleClickOpen = () => {
        setAbrirNegocio(true);
    };

    // adiciona Micronutriente from textfield. 
    const adicionaMicronutrientes = () => {
        // Loop through micronutriente items to update the 'peso' property
        micronutri.forEach((item) => {
            if (item.pesoInput !== "") {
                // If pesoInput is not empty, set the peso property to the input value
                item.peso = parseInt(item.pesoInput);
            } else {
                // If pesoInput is empty, set the peso property to 0
                item.peso = 0;
            }
        });

        // Filter items with peso >= 50 and store them in selectedItems state
        const filteredItems = micronutri.filter((item) => item.peso >= 50);
        setSelectedItems(filteredItems);

        // Create an array of objects with selected items and default values for n, p, and k
        const newItems = filteredItems.map((item) => ({
            idMateria: item.id,
            peso: item.peso,
            abreviacao: item.abrev,
            n: 0,
            p: 0,
            k: 0,
            micronutriente: true
        }));

        // Filter out items with micronutriente: true from arrayIdweight
        const filteredArrayIdweight = arrayIdweight.filter((item) => item.micronutriente !== true);

        // Add the new items to the filtered array
        const newArrayIdweight = [...filteredArrayIdweight, ...newItems];

        // Update the state with the new array
        setArrayIdweight(newArrayIdweight);
        calculaNpk(newArrayIdweight)

    };

    return (
        permitido ?
            <div className={classes.container}>
                <div className={classes.containerLeft}>
                    <div style={{ width: 470, }}>

                        <div >
                            <h2 className={classes.inputh1}>Fechamentos Padrão</h2>
                        </div>
                        <b className={classes.inputh1}>Adicionar Fechamento</b>

                        <div >
                            <div className={classes.content1}>

                                <TextField
                                    className={classes.autoCompleteField}
                                    variant='outlined'
                                    label="Nome do Fechamento"
                                    value={nomeFechamento}
                                    disabled={atualizando}
                                    onChange={e => setNomeFechamento(e.target.value)} />
                            </div>
                            <div className={classes.content1}>
                                <div className={classes.checkbox}>
                                    <Checkbox checked={dustrol} onChange={() => setDustrol(!dustrol)}
                                        disabled={checkboxDisable}
                                    />
                                    < b className={classes.inputCheckbox} > Inserir Dustrol </b>
                                </div>
                                < div style={{ paddingRight: 28, width: 360 }}>

                                    <Checkbox checked={inibidor} onChange={() => (setInibidor(!inibidor))}
                                        disabled={checkboxDisable}
                                        classes={{
                                            root: classes.root,
                                            checked: classes.checked,
                                        }}
                                    />
                                    < b className={classes.inputCheckbox} > Inserir inibidor NBPT </b>
                                </div>

                            </div>

                            {exibirMaterias === false ?
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
                                    <div>
                                        <button
                                            variant="contained"
                                            className={classes.calcButton}
                                            onClick={() => (continuarFechamento(), responseMaterias())}
                                            disabled={checkboxDisable}
                                        >Continuar cadastro de fechamento</button>
                                    </div>
                                </div>
                                : null}

                            {exibirMaterias ?
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'row', }}>
                                        <div >
                                            <Autocomplete
                                                options={materiasAutocomplete}
                                                getOptionLabel={(option) => (option.id_materias + '-' + option.abreviacao)}
                                                className={classes.autoCompleteField}
                                                value={materiaPrima}
                                                onChange={(e, v) => setMateriaPrima(v)}
                                                classes={{
                                                    input: classes.input,
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Selecionar Matéria..."
                                                        variant='outlined'
                                                        disabled={checkboxDisable}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            classes: { notchedOutline: classes.noBorder },
                                                            underline: false
                                                        }}
                                                    />
                                                )}
                                            />

                                        </div>
                                        <div>
                                            <TextField
                                                key="Quilos"
                                                label="Quilos"
                                                variant="outlined"
                                                type="number"
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    setQuilos(parseFloat(value));
                                                }}
                                               
                                                value={quilos}
                                                className={classes.autoCompleteField}
                                                style={{ marginLeft: 15 }}
                                                InputProps={{
                                                    disableunderline: true,
                                                    classes: { notchedOutline: classes.noBorder }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                        <div>
                                            <Button
                                                variant="contained"
                                                className={classes.calcButton}
                                                onClick={() => (montaLista(materiaPrima, quilos))}
                                            >ADICIONAR MATÉRIA-PRIMA</Button>
                                        </div>
                                        <div >
                                            <Button onClick={() => (handleClickOpen())} variant="contained"
                                                className={classes.calcButton} > CONFIGURAÇÕES AVANÇADAS </Button>
                                        </div>
                                        <div>
                                            <Button variant="contained"
                                                className={classes.calcButton}
                                                onClick={() => (limpar())}
                                            >LIMPAR</Button>
                                        </div>

                                    </div>

                                </div>

                                : null}
                            <div >
                                <div style={{ display: 'flex', flexDirection: 'row', overflowy: 'scroll', }}>

                                    {arrayIdweight !== '' ?
                                        <div>
                                            <TableContainer className={classes.tableModels}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell width={200}>
                                                                Matéria-Prima
                                                            </TableCell>
                                                            <TableCell width={200}>
                                                                Quilos
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {Object.keys(arrayIdweight).map((item, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>
                                                                    {arrayIdweight[item].abreviacao}
                                                                </TableCell>
                                                                <TableCell >
                                                                    {arrayIdweight[item].peso}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>

                                            </TableContainer>
                                            <div>

                                                {compatArray !== '' ?

                                                    compatArray.map((item, index) => (
                                                        <p >{` ${item}`} {"\n"} </p>
                                                    ))
                                                    : 'Matérias compatíveis'}
                                            </div>
                                        </div>

                                        : null}

                                </div>

                                <div>
                                    {npkFechamento != [] ?

                                        <TableContainer className={classes.tableModels} style={{ overflowY: 'scroll', width: '100%' }}>
                                            <Table className={classes.table} aria-label="simple table2">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width={30}>
                                                            Nitrogênio
                                                        </TableCell>
                                                        <TableCell width={30}>
                                                            Fósforo
                                                        </TableCell>
                                                        <TableCell width={30}>
                                                            Potássio
                                                        </TableCell>
                                                        <TableCell width={30}>
                                                            Total(kg)
                                                        </TableCell>
                                                        <TableCell width={30}>
                                                            Falta(kg)
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Object.keys(npkFechamento).map((item, index) => (
                                                        item !== "totalWeight" && (
                                                            <TableRow key={index}>
                                                                <TableCell>{npkFechamento[item].n}</TableCell>
                                                                <TableCell>{npkFechamento[item].p}</TableCell>
                                                                <TableCell>{npkFechamento[item].k}</TableCell>
                                                                <TableCell>{npkFechamento.totalWeight}</TableCell>
                                                                <TableCell>{1000 -npkFechamento.totalWeight}</TableCell>

                                                            </TableRow>
                                                        )
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div className={classes.calcDiv}>
                            <Button onClick={() => (setIsDisabled(true), salvarFechamento())}
                                variant="contained"
                                disabled={isDisabled}
                                className={classes.calcButton}> Salvar Fechamento</Button>
                        </div>

                    </div>
                </div>

                < div className={classes.tableFechamentos}>
                    <b className={classes.inputh1}>Fechamentos</b>
                    <div>
                        {fechamentosManuais != '' ?
                            <TableContainer className={classes.tableModels} >
                                <Table className={classes.table
                                } aria-label="simple table2">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={50}>
                                                Nome do Fechamento
                                            </TableCell>
                                            <TableCell width={50}>
                                                Nitrogênio
                                            </TableCell>
                                            <TableCell width={50}>
                                                Fósforo
                                            </TableCell>
                                            <TableCell width={50}>
                                                Potássio
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(fechamentosManuais).map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell width={50}>
                                                    {fechamentosManuais[item].nome_formula}
                                                </TableCell>
                                                <TableCell width={50}>
                                                    {fechamentosManuais[item].nitrogenio}
                                                </TableCell>
                                                <TableCell width={50}>
                                                    {fechamentosManuais[item].fosforo}
                                                </TableCell>
                                                <TableCell width={50}>
                                                    {fechamentosManuais[item].potassio}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : null}
                    </div>

                    {abrirNegocio !== false ?
                        <div >
                            <Dialog PaperProps={{ style: { minWidth: "30%" } }} open={abrirNegocio} onClose={handleClickClose} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">
                                    <b className={classes.inputh1}>Micronutrientes  (Valor mínimo: 50 kg)</b>
                                </DialogTitle>
                                <DialogContent className={classes.dialogContent}>
                                    <div >
                                        <div
                                            className={classes.dialogContentGrid}>
                                            {micronutri.map((item, index) => (
                                                <div key={item.id} className={classes.inputWrapper}>

                                                    <TextField
                                                        type="number"
                                                        defaultValue={item.pesoInput}
                                                        className={classes.autoCompleteField}
                                                        label={item.abrev}

                                                        onChange={(e) => {
                                                            const updatedMicronutri = [...micronutri];
                                                            updatedMicronutri[index] = {
                                                                ...updatedMicronutri[index],
                                                                pesoInput: e.target.value,
                                                            };
                                                            setMicronutri(updatedMicronutri);
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>

                                        <DialogActions>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}>
                                                <div>
                                                    <Button onClick={() => {
                                                        adicionaMicronutrientes();
                                                        handleClickClose();
                                                    }} variant="contained" className={classes.calcButton}> SALVAR</Button>
                                                </div>
                                                <div style={{ margin: '0 50px' }}>
                                                    <Button onClick={() => {
                                                        handleClickClose();
                                                    }}
                                                        className={classes.calcButton} >
                                                        CANCELAR
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button onClick={() => {
                                                        responseMicronytri();
                                                        handleClickClose();
                                                        const filteredArrayIdweight = arrayIdweight.filter((item) => item.micronutriente !== true);
                                                        setArrayIdweight(filteredArrayIdweight)
                                                        calculaNpk(filteredArrayIdweight);
                                                        // Add additional functionality here
                                                    }}
                                                        className={classes.calcButton}
                                                    >
                                                        LIMPAR
                                                    </Button>
                                                </div>
                                            </div>
                                        </DialogActions>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </div > : null
                    }

                </div >
            </div > : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    )
}

const useStyles = makeStyles((theme) => ({
    checkbox: {
        paddingRight: 10,
        width: 400
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    content1: {
        display: 'flex',
        alignItems: 'center',
    },
    dialogContentGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: '1rem',
        marginTop: '1rem',
    },

    inputWrapper: {
        fontFamily: 'Poppins',
        color: '#383838',
        display: 'flex',
        flexDirection: 'column',
    },

    input: {
        padding: '0.5rem',
        borderRadius: 6,
        backgroundColor: '#F5F5F5',
        border: 'none',
    },
    tableFechamentos: {
        marginTop: 77,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    container: {
        width: '95%',
        display: 'flex',
        flexDirection: 'row',
    },
    containerLeft: {
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    noBorder: {
        border: "none",
    },
    autoCompleteFieldSmall: {
        marginTop: 15,
        minWidth: 104,
        maxWidth: 104,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteField: {
        marginTop: 15,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 15,
        width: 471,
        marginBottom: 10,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    inputCheckbox: {

        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000'
    },
    inputh1: {
        fontFamily: 'Poppins',

        color: '#383838'
    },
    root: {
        "&$checked": {
            color: "black",
            backgroundColor: 'white'
        }
    },
    checked: {
        borderColor: 'black'
    },
    calcButton: {

        marginTop: 20,
        border: 'none',
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
    calcDiv: {

        maxWidth: '146px',
        height: 45
    },
    nutriDiv: {
        paddingRight: 28,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 228
    },
    inputForm: {
        width: '50%',
        fontFamily: 'Poppins',
        color: '#383838'
    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    }, table: {
        minWidth: 400,
        maxWidth: '100%'
    },
    rowSize: {
        maxWidth: 500
    },
    tableModels: {
        overflow: 'scroll',
        marginTop: 20,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },


}));

export default FechamentosPadrao;
