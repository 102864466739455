import React, { useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Collapse,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardArrowDown, KeyboardArrowUp, Delete } from '@material-ui/icons';

const TabelaDefensivos = ({
  defensivosComDose,
  regioesOptions,
  openModal,
  handleOpenModal,
  handleCloseModal,
  handleSavePreco,
  selectedDefensivoRow,
  regiao,
  setRegiao,
  preco,
  setPreco,
  dataPreco,
  setDataPreco,
  precos,
  fetchPrecos,
}) => {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Produtos com Doses Cadastradas
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="Defensivos com Dose">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Defensivo</TableCell>
              <TableCell>Nível de Investimento</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {defensivosComDose.map((defensivo) => (
              <DefensivoRow
                key={defensivo.id_marca_comercial}
                defensivo={defensivo}
                handleOpenModal={handleOpenModal}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal */}
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>Adicionar Preço</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Região */}
            <Grid item xs={12}>
              <Autocomplete
                options={regioesOptions}
                getOptionLabel={(option) => option.nome_regiao}
                value={regiao}
                onChange={(event, newValue) => setRegiao(newValue)}
                renderInput={(params) => (
                  <TextField {...params} label="Região" variant="outlined" fullWidth />
                )}
              />
            </Grid>
            {/* Data do Preço */}
            <Grid item xs={12}>
              <TextField
                label="Data do Preço"
                type="date"
                variant="outlined"
                fullWidth
                value={dataPreco}
                onChange={(e) => setDataPreco(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {/* Preço */}
            <Grid item xs={12}>
              <TextField
                label="Preço"
                variant="outlined"
                fullWidth
                value={preco}
                onChange={(e) => setPreco(e.target.value)}
              />
            </Grid>
            {/* Preços Existentes */}
            {precos.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h6">Preços Cadastrados</Typography>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Região</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Preço</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {precos.map((preco) => (
                        <TableRow key={preco.id_preco}>
                          <TableCell>{preco.nome_regiao}</TableCell>
                          <TableCell>{preco.data_preco}</TableCell>
                          <TableCell>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(preco.preco)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSavePreco} color="primary" variant="contained">
            Salvar Preço
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DefensivoRow = ({ defensivo, handleOpenModal }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{defensivo.marca_comercial}</TableCell>
        <TableCell>{defensivo.nivel_investimento}</TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleOpenModal(defensivo);
            }}
          >
            +
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="culturas">
                <TableHead>
                  <TableRow>
                    <TableCell>Cultura</TableCell>
                    <TableCell>Praga</TableCell>
                    <TableCell>Dose Mínima</TableCell>
                    <TableCell>Dose Máxima</TableCell>
                    <TableCell>Dose Usual</TableCell>
                    <TableCell>Segmento</TableCell>
                    <TableCell>Função Principal</TableCell>
                    {/* <TableCell>Ação</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {defensivo.culturas.map((cultura) => (
                    <TableRow
                      key={`${defensivo.id_marca_comercial}-${cultura.id_cultura}-${cultura.id_praga}`}
                    >
                      <TableCell>{cultura.CULTURA}</TableCell>
                      <TableCell>{cultura.PRAGA_NOME_COMUM}</TableCell>
                      <TableCell>{cultura.dose_min}</TableCell>
                      <TableCell>{cultura.dose_max}</TableCell>
                      <TableCell>{cultura.dose_usual}</TableCell>
                      <TableCell>{cultura.segmento}</TableCell>
                      <TableCell>{cultura.funcao_principal}</TableCell>
                      {/* <TableCell>
                        <IconButton size="small" onClick={() => console.log('cuuuuuultura', cultura)}>
                          <Delete />
                        </IconButton>
                      </TableCell> */}

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TabelaDefensivos;
