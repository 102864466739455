import React, { useEffect, useState } from 'react';
import api_amr from 'services/api_amr';
import { getToken } from 'utils/utils';
import { Grid } from '@material-ui/core';
import useStyles from '../styles';
import { Card, CardMedia, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getComponentValue } from "../utils.js";
import { truncateString } from '../utils.js';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

export default function TopPosts(props) {
    const [posts, setPosts] = useState([])
    const classes = useStyles()

    useEffect(() => {
        fetchMyApi()
    }, [])

    const fetchMyApi = async () => {
        const responseLatest = await api_amr.get('/return_latest_post', { headers: { 'x-api-key': getToken() } })
        if (responseLatest.data !== '') {
            setPosts(responseLatest.data.posts)
        }
    }

    function BeautifulCard({ imageUrl, title, tag, summary, viewcount, post_date, user_name, verified }) {
        return (
            <Card className={classes.card}>
                <CardMedia
                    style={{
                        height: 0,
                        paddingTop: '40%',
                        backgroundSize: '100%, 60%, 60%',
                        backgroundPosition: 'center',
                        borderRadius: 10
                    }}

                    image={imageUrl}
                    title={title}
                />

                <Typography variant="h6" className={classes.newsTitle}>
                    {title ? truncateString(title, 86) : ''}
                </Typography>

                <Typography variant="h6" className={classes.cardSubText}>
                    {summary ? truncateString(summary, 86) : ''}
                </Typography>
                <div>
                    <div className={classes.tagTextWrapper}>
                        <Typography variant="h6" style={{ fontSize: '0.8rem', fontFamily: 'Lato', color: '#A59B6B', }}>
                            {tag.toUpperCase()}
                        </Typography>

                    </div>
                    <Grid item className={classes.postInfo}>
                        <Typography variant="subtitle2" className={classes.postInfoItem1}>
                            {post_date}
                        </Typography>
                        <Typography variant="subtitle2" className={classes.postInfoItem}>
                            <span style={{ display: 'flex', alignItems: 'center', color: '#929292' }}>
                                <VisibilityIcon fontSize="inherit" style={{ marginRight: 4 }} />
                                {viewcount}
                            </span>
                        </Typography>
                    </Grid>
                  
                </div>
            </Card>
        )
    }

    if (posts.length !== 0) {
        return (
            <>
                <Typography variant="h5" className={classes.topTitle}>
                    ÚLTIMAS NOTÍCIAS
                </Typography>

                <Grid container >
                    <Grid item container xs={12} sm={12} md={12} lg={12}>
                        <Grid item xs={12} sm={12} md={12} lg={6} className={classes.customSpacing}>
                            <Link
                                to={{
                                    pathname: `Noticia/${posts[0].uuid}`,
                                    state: { post: posts[0] }
                                }}
                                className={classes.link}>
                                <BeautifulCard
                                    imageUrl={getComponentValue(posts[0], 'cover_image') !== null ? getComponentValue(posts[0], 'cover_image') : 'https://back.plataformaamrbi.com/files/posts/file_server/115/logo_amr2.png'} //getComponentValue(posts[0], 'cover_image') === null ? getComponentValue(posts[0], 'cover_image') :
                                    title={getComponentValue(posts[0], 'main_title')}
                                    tag={posts[0].tag_name}
                                    summary={getComponentValue(posts[0], 'paragraph')}
                                    viewcount={((posts[0].views_counter_logged || 0) + (posts[0].views_counter_unlogged || 0))}
                                    post_date={new Date(posts[0].post_date).toLocaleDateString('pt-BR', { month: 'long', day: 'numeric' }).split(' de ').reverse().join(' ').replace(/^\w/, c => c.toUpperCase())}
                                    user_name={posts[0].user_name}
                                    verified={posts[0].verified}
                                />
                            </Link>
                        </Grid>

                        <Grid item container xs={12} sm={12} md={12} lg={6} >
                            {posts.slice(1, 6).map((post, index) => (
                                <Grid item xs={12} sm={12} md={12} lg={6} key={post.post_id} className={classes.customSpacing}>
                                    <Link
                                        to={{
                                            pathname: `Noticia/${post.uuid}`,
                                            state: { post: post },
                                        }}
                                        className={classes.link}>
                                        <BeautifulCard
                                            imageUrl={getComponentValue(post, 'cover_image') !== null ? getComponentValue(post, 'cover_image') : 'https://back.plataformaamrbi.com/files/posts/file_server/115/logo_amr2.png'} //getComponentValue(posts[0], 'cover_image') === null ?getComponentValue(posts[0], 'cover_image') : 
                                            title={getComponentValue(post, 'main_title')}
                                            tag={post.tag_name}
                                            summary={getComponentValue(post, 'paragraph')}
                                            viewcount={((post.views_counter_logged || 0) + (post.views_counter_unlogged || 0))}
                                            post_date={new Date(post.post_date).toLocaleDateString('pt-BR', { month: 'long', day: 'numeric' }).split(' de ').reverse().join(' ').replace(/^\w/, c => c.toUpperCase())}
                                            user_name={post.user_name}
                                            verified={post.verified}
                                        />
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    } else { return null }
}
