import React from "react";

// Views
import PowerBiImportacoes from 'views/PowerBiImportacoes/PowerBiImportacoes.js'
import RetornaFormulas from 'views/RetornaFormulas/RetornaFormulas.js'
import Formulador from "views/Formulador/Formulador.js";
import PrecificaPorto from 'views/PrecificaPorto/PrecificaPorto.js'
import AndamentoMercadoBrasil from 'views/AndamentoMercadoBrasil/AndamentoMercadoBrasil.js'

import ConfigClientes from 'views/ConfigClientes/ConfigClientes.js'
import ConfigGrupos from 'views/ConfigGrupos/ConfigGrupos.js'
import ConfigMaterias from 'views/ConfigMaterias/ConfigMaterias.js'
import ConfigFazendas from 'views/ConfigFazendas/ConfigFazendas.js'
import PerfilUsuario from 'views/PerfilUsuario/PerfilUsuario.js'
import ListaDePrecos from 'views/ListaDePrecos/ListaDePrecos.js'
import HistoricoUsabilidade from 'views/HistoricoUsabilidade/HistoricoUsabilidade.js'
import ConfigPortos from 'views/ConfigPortos/ConfigPortos.js'
import ProdutosPadraoAssociados from 'views/ProdutosPadraoAssociados/ProdutosPadraoAssociados.js'
import HistoricoBasis from 'views/HistoricoBasis/HistoricoBasis.js'
import HistoricoFretes from 'views/HistoricoFretes/HistoricoFretes.js'
import HistRelacaoDeTroca from 'views/HistRelacaoDeTroca/HistRelacaoDeTroca.js'
import PrevisaoClimatica from 'views/PrevisaoClimatica/PrevisaoClimatica.js'
import AssociacaoProdutosPadrao from 'views/AssociacaoProdutosPadrao/AssociacaoProdutosPadrao.js'
import CriarPost from "views/Posts/CriarPost/CriarPost";
import LandingPage from "views/Posts/pages/LandingPage";
import GerenciarPosts from 'views/Posts/GerenciarPosts.js'
import FechamentosPadrao from 'views/FechamentosPadrao/FechamentosPadrao.js'
import RenderPage from "views/Posts/pages/RenderPage";
import Dashboard from "views/Dashboard/pages/Dashboard";
import UsabilidadeTabs from "views/Usabilidade/pages/UsabilidadeTabs.js";
import News from "views/News/News.js";
import New from "views/News/New";
import Manutencao from "views/Manutencao/Manutencao";
import Dashboard360 from "views/DashboardCustomizavel/Dashboard360";
import ImportacoesMundo from "views/ImportacoesMundo/PowerBiImportacoes";
import Notificacoes from "views/Notificacoes/Notificacoes";
import CadastroDefensivos from "views/CadastroDefensivos/CadastroDefensivos";

// Icons
import StorageIcon from '@material-ui/icons/Storage';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import UndoIcon from '@material-ui/icons/Undo';
import TableChartIcon from '@material-ui/icons/TableChart';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import CloudIcon from '@material-ui/icons/Cloud';
import Brightness5Icon from '@material-ui/icons/Brightness5';
import EcoIcon from '@material-ui/icons/Eco';
import WarningIcon from '@material-ui/icons/Warning';
import PollIcon from '@material-ui/icons/Poll';
import PublicIcon from '@material-ui/icons/Public';
import NotificationsIcon from '@material-ui/icons/Notifications';

import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';
import NewReleasesRoundedIcon from '@material-ui/icons/NewReleasesRounded';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';

const getPermissoes = () => {
    const permissoes = localStorage.getItem('permissoes')

    return permissoes ? JSON.parse(permissoes) : '';
}
const getUsuario = () => {
    const usuario = localStorage.getItem('usuario')

    return usuario ? JSON.parse(usuario) : '';
}

/**
 * Return a list of dashboard routes based on user permissions
 * @param {boolean} sideBar
 */

const ReturnSidebarRoutes = (sideBar) => {
    let dashboardRoutes = []
    const permissoes = getPermissoes();
    const usuario = getUsuario()
    console.log(usuario==='')
   
    if (permissoes.retorna_formulas === 1) {
        dashboardRoutes.push({
            path: "/RetornaFormulas",
            name: sideBar === true ? "Retorna Fórmulas" : '',
            icon: StorageIcon,
            component: RetornaFormulas,
            layout: "/pages"
        })
    }
    if (permissoes.configuracoes_clientes === 1) {
        dashboardRoutes.push({
            path: "/Formulador",
            name: sideBar === true ? "Formulador" : '',
            icon: StorageIcon,
            component: Formulador,
            layout: "/pages"
        })
    }
    if (permissoes.configuracoes_clientes === 1) {
        dashboardRoutes.push({
            path: "/ImportacoesMundo",
            name: sideBar === true ? "Importações Mundo" : '',
            icon: PublicIcon,
            component: ImportacoesMundo,
            layout: "/pages"
        })
    }

    if (permissoes.importacao_comex_pwbi === 1) {
        dashboardRoutes.push({
            path: "/DadosImportacoes",
            name: sideBar === true ? "Dados de Importações" : '',
            icon: ImportExportIcon,
            component: PowerBiImportacoes,
            layout: "/pages"
        })
    }
    if (permissoes.precifica_porto === 1) {
        dashboardRoutes.push({
            path: "/PrecificaPorto",
            name: sideBar === true ? "Precificação Porto MP" : '',
            icon: DirectionsBoatIcon,
            component: PrecificaPorto,
            layout: "/pages"
        })
    }
    if (permissoes.andamento_mercado_brasil === 1) {
        dashboardRoutes.push({
            path: "/AndamentoMercadoBrasil",
            name: sideBar === true ? "Andamento Mercado Brasil" : '',
            icon: DonutLargeIcon,
            component: AndamentoMercadoBrasil,
            layout: "/pages"
        })
    }
    
    if (permissoes.configuracoes_clientes === 1) {
        dashboardRoutes.push({
            path: "/ConfigClientes",
            name: sideBar === true ? "Configurações de cliente" : '',
            icon: PersonOutlineIcon,
            component: ConfigClientes,
            layout: "/pages"
        })
    }
    if (permissoes.configuracoes_grupos === 1) {
        dashboardRoutes.push({
            path: "/ConfigGrupos",
            name: sideBar === true ? "Configurações de grupos" : '',
            icon: PeopleOutlineIcon,
            component: ConfigGrupos,
            layout: "/pages"
        })
    }
    if (permissoes.configuracoes_materias === 1) {
        dashboardRoutes.push({
            path: "/ConfigMaterias",
            name: sideBar === true ? "Configurações de Matérias" : '',
            icon: TableChartIcon,
            component: ConfigMaterias,
            layout: "/pages"
        })
    }
    if (permissoes.configuracoes_fazendas === 1) {
        dashboardRoutes.push({
            path: "/ConfigFazendas",
            name: sideBar === true ? "Configurações de Demandas" : '',
            icon: HomeIcon,
            component: ConfigFazendas,
            layout: "/pages"
        })
    }
    if (permissoes.perfil === 1) {
        dashboardRoutes.push({
            path: "/PerfilUsuario",
            name: sideBar === true ? "Perfil Usuário" : '',
            icon: AccountCircleIcon,
            component: PerfilUsuario,
            layout: "/pages"
        })
    }
    if (permissoes.historico_usabilidade === 1) {
        dashboardRoutes.push({
            path: "/HistoricoUsabilidade",
            name: sideBar === true ? "Histórico Usabilidade" : '',
            icon: ArtTrackIcon,
            component: HistoricoUsabilidade,
            layout: "/pages"
        })
    }

    if (permissoes.historico_fretes === 1) {
        dashboardRoutes.push({
            path: "/HistoricoFretes",
            name: sideBar === true ? "Histórico de Fretes" : '',
            icon: LocalShippingIcon,
            component: HistoricoFretes,
            layout: "/pages"
        })
    }

    if (permissoes.lista_de_precos === 1) {
        dashboardRoutes.push({
            path: "/ListaDePrecos",
            name: sideBar === true ? "Lista de Preços" : '',
            icon: LocalOfferIcon,
            component: ListaDePrecos,
            layout: "/pages"
        })
    }

    if (permissoes.hist_rel_troca === 1) {
        dashboardRoutes.push({
            path: "/HistRelacaoDeTroca",
            name: sideBar === true ? "Histórico da Relação de Troca" : '',
            icon: SwapHorizontalCircleIcon,
            component: HistRelacaoDeTroca,
            layout: "/pages"
        })
    }
    if (permissoes.previsao_climatica === 1) {
        dashboardRoutes.push({
            path: "/PrevisaoClimatica",
            name: sideBar === true ? "Previsão Climática" : '',
            icon: CloudIcon,
            component: PrevisaoClimatica,
            layout: "/pages"
        })
    }

    if (permissoes.gerenciar_posts === 1) {
        dashboardRoutes.push({
            path: "/GerenciarPosts",
            name: sideBar === true ? "Gerenciar Postagens" : '',
            icon: PostAddRoundedIcon,
            component: GerenciarPosts,
            layout: "/pages"
        })
    }

    if (permissoes.configuracoes_portos === 1) {
        dashboardRoutes.push({
            path: "/ConfigPortos",
            name: sideBar === true ? "Configurações de porto" : '',
            icon: Brightness5Icon,
            component: ConfigPortos,
            layout: "/pages"
        })
    }
    if (permissoes.associacao_produtos_padrao === 1) {
        dashboardRoutes.push({
            path: "/AssociacaoProdutosPadrao",
            name: sideBar === true ? "Produtos Padrão" : '',
            icon: UndoIcon,
            component: AssociacaoProdutosPadrao,
            layout: "/pages"
        })
    }
    if (permissoes.produtos_padrao_associados === 1) {
        dashboardRoutes.push({
            path: "/ProdutosPadraoAssociados",
            name: sideBar === true ? "Produtos Padrão Associados" : '',
            icon: EcoIcon,
            component: ProdutosPadraoAssociados,
            layout: "/pages"
        })
    }
    if (permissoes.historico_basis === 1) {
        dashboardRoutes.push({
            path: "/HistoricoBasis",
            name: sideBar === true ? "Basis Soja e Fertilizantes" : '',
            icon: ShowChartIcon,
            component: HistoricoBasis,
            layout: "/pages"
        })
    }
    if (permissoes.fechamentos_padrao === 1) {
        dashboardRoutes.push({
            path: "/FechamentosPadrao",
            name: sideBar === true ? "Fechamentos Padrao" : '',
            icon: DashboardIcon,
            component: FechamentosPadrao,
            layout: "/pages"
        })
    }

    dashboardRoutes.push({
        path: "/LandingPage",
        name: sideBar === true ? "Notícias" : '',
        icon: NewReleasesRoundedIcon,
        component: LandingPage,
        layout: "/pages"
    })

    if (permissoes.news === 1) {
        dashboardRoutes.push({
            path: "/News",
            name: sideBar === true ? "News" : '',
            icon: WarningIcon,
            component: News,
            layout: "/pages"
        })
    }

    if (permissoes.dashboard === 1) {
        dashboardRoutes.push({
            path: "/Dashboard",
            name: sideBar === true ? "Dashboard" : '',
            icon: PollIcon,
            component: Dashboard,
            layout: "/pages"
        })
    }

    if (permissoes.usabilidade === 1) {
        dashboardRoutes.push({
            path: "/Usabilidade",
            name: sideBar === true ? "Usabilidade" : '',
            icon: WarningIcon,
            component: UsabilidadeTabs,
            layout: "/pages"
        })
    }

    if ((permissoes.dashboardCustomizavel === 1) | (usuario.id == 5)) {
        dashboardRoutes.push({
            path: "/DashboardCustomizavel",
            name: sideBar === true ? "Dashboard 360" : '',
            icon: ViewQuiltIcon,
            component: Dashboard360,
            layout: "/pages"
        })
    }

    if (permissoes.notificacoes === 1) {
        dashboardRoutes.push({
            path: "/Notificacoes",
            name: sideBar === true ? "Notificações" : '',
            icon: NotificationsIcon,
            component: Notificacoes,
            layout: "/pages"
        })
    }
    if (permissoes.defensivos === 1) {
        dashboardRoutes.push({
            path: "/defensivos",
            name: sideBar === true ? "Cadastro Defensivos" : '',
            icon: FormatColorFillIcon,
            component: CadastroDefensivos,
            layout: "/pages"
        })
    }


    return dashboardRoutes
}

export default ReturnSidebarRoutes;
