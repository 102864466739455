import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import api from 'services/api_amr';
import { useMediaQuery } from "@material-ui/core";
import { getToken, checarPermissao } from '../../utils/utils'
import Button from "@material-ui/core/Button";
import styles from 'views/Posts/styles';

const NewsList = (props) => {
    const classes = useStyles();
    const [news, setNews] = useState([])
    const [tags, setTags] = useState([])
    const [permitido, setPermitido] = useState(false)
    const smallerThan600px = useMediaQuery("(max-width: 900px)")
    const today = new Date().toISOString().substring(0, 10)

    useEffect(() => {
        fetchMyApi()
    }, [])

    const fetchMyApi = async () => {
        setPermitido(await checarPermissao('news', props))
        const response = await api.get('/get_news', { headers: { 'x-api-key': getToken() } })
        setNews(response.data)




        const responseTags = await api.get('/get_news_tags', { headers: { 'x-api-key': getToken() } })
        setTags(responseTags.data)
        const scrollPosition = sessionStorage.getItem('scrollPosition');
        if (scrollPosition) {
            window.scrollTo(0, parseInt(scrollPosition, 10));
        }
    }

    const filter = async (domain) => {
        if (domain === null) {
            const response = await api.get('/get_news', { headers: { 'x-api-key': getToken() } })
            setNews(response.data)
        } else {
            const response = await api.get('/get_news_by_domain', { params: { 'domain': domain }, headers: { 'x-api-key': getToken() } })
            setNews(response.data)
        }
    }

    const filterByDate = async (date) => {
        if (date === null || date === undefined || date === '') { return }
        if (date === today) {
            const response = await api.get('/get_news', { headers: { 'x-api-key': getToken() } })
            setNews(response.data)
            return
        }
        const response = await api.get('/get_news_by_date', { params: { 'date': date }, headers: { 'x-api-key': getToken() } })
        setNews(response.data)
    }

    const pushNews = (id) => {

        sessionStorage.setItem('scrollPosition', window.pageYOffset.toString());
        props.history.push({ pathname: `New/${id}` })
    }
    const handleFeeback = async (tipo, id) => {
        const response = await api.get('/set_feedback_ai', { params: { 'id': id, 'tipo': tipo }, headers: { 'x-api-key': getToken() } })

    }

    return (
        permitido ?
            <Grid container style={{ paddingTop: 10, fontFamily: 'Poppins', width: '95%' }}>
                <Grid item xs={smallerThan600px ? 12 : 2} className={classes.filterDiv}>
                    <Grid container>
                        <Grid item xs={12} className={classes.filterTitle} >
                            Filtros
                        </Grid>

                        <Grid item xs={12} style={{ padding: 5, marginTop: 10, fontSize: 'calc(9px + 0.4vw)' }}>
                            Site publicado
                            <Autocomplete
                                classes={{
                                    paper: classes.paper
                                }}
                                onChange={(e, v) => filter(v)}
                                options={tags.map(tags => tags.site)}
                                renderInput={(params) => <TextField {...params} variant='outlined' />}
                            />
                            Data
                            <div>
                                <TextField defaultValue={new Date().toISOString().substring(0, 10)} variant='outlined' style={{ width: '100%' }} type='date' onChange={(e) => filterByDate(e.target.value)} inputProps={{ max: '9999-12-31' }} />

                            </div>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={smallerThan600px ? 12 : 10} style={{}}>
                    <Grid container>
                        {news.map((obj, index) =>

                            <Grid key={index} item xs={12} className={classes.new}>
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={2} lg={1} >
                                        <img className={classes.img} onClick={() => pushNews(obj.id)} width='100%' src={obj.image} />
                                    </Grid>
                                    <Grid item xs={11} md={10} lg={11}>
                                        <div className={classes.title} onClick={() => pushNews(obj.id)}>
                                            {obj.title}
                                        </div>
                                        <div className={classes.tags}>
                                            {obj.tags}

                                        </div>
                                        <div className={classes.text}>
                                            {obj.text.substring(0, 200).trim()}...
                                        </div>
                                        <Grid container direction='column' justifyContent='flex-end' alignItems='flex-end'>
                                            <Grid item className={classes.dateStyle}>Publicado em {obj.publication_date}</Grid>
                                            <Grid item className={classes.dateStyle}>Extraído em {new Date(obj.extraction_date).toLocaleString('pt-br', { dateStyle: 'short', timeZone: 'UTC' })}</Grid>
                                            <Grid container direction="column" spacing={3}>

                                                {obj.assunto_noticia !== null && obj.assunto_noticia !== '' ?
                                                    <Grid item>
                                                        Assunto Principal: <b>{obj.assunto_noticia.charAt(0).toUpperCase() + obj.assunto_noticia.slice(1)}</b>

                                                    </Grid> : null}
                                                {obj.resumo_curto !== null && obj.resumo_curto !== '' ?

                                                    <Grid item>
                                                        Resumo:  <b>{obj.resumo_curto.charAt(0).toUpperCase() + obj.resumo_curto.slice(1)}</b>
                                                        <br></br>
                                                    </Grid> : null}
                                                {obj.bullet_points !== null && obj.bullet_points !== '' ?

                                                    <Grid item>
                                                        Pontos chave:
                                                        <ul>
                                                            {obj.bullet_points.split('; ').filter(bp => bp !== '').map((point, index) => (
                                                                <li key={index}>
                                                                    <b>{point.charAt(0).toUpperCase() + point.slice(1)}</b>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </Grid> : null}

                                                {obj.importancia_segmento !== null && obj.importancia_segmento !== '' ?

                                                    <Grid item>
                                                        Importância Estimada para o segmento (0-10):  <b>{obj.importancia_segmento}</b>

                                                    </Grid> : null}
                                                {obj.impacto_noticia !== null && obj.impacto_noticia !== '' ?

                                                    <Grid item>
                                                        Impacto estimado: <b>{obj.impacto_noticia == '0' ? 'Neutro' : obj.impacto_noticia == '1' ? 'Positivo' : 'Negativo'}</b>
                                                        <br></br>
                                                    </Grid> : null}
                                                {obj.impacto_noticia !== null && obj.impacto_noticia !== '' ?
                                                    <Grid item>
                                                        Feedback AI

                                                        <Button
                                                            className={obj.feedback_ai === 1 ? classes.highlight : ""}
                                                            style={{borderStyle:'solid',borderWidth:1,marginLeft:10}}
                                                                                                                        
                                                            disabled={obj.feedback_ai !== 0} onClick={() => handleFeeback(1, obj.id)}>
                                                            👍

                                                        </Button>
                                                       
                                                        <Button
                                                            className={obj.feedback_ai === -1 ? classes.highlightRed : ""}
                                                            style={{borderStyle:'solid',borderWidth:1,marginLeft:10}}
                                                            disabled={obj.feedback_ai !== 0} onClick={() => handleFeeback(-1, obj.id)}  >
                                                            👎

                                                        </Button></Grid> : null}
                                            </Grid>
                                        </Grid>



                                    </Grid>
                                </Grid>
                            </Grid>

                        )}

                    </Grid>
                </Grid >
            </Grid > : null
    )

};

const useStyles = makeStyles({
    paper: {
        minWidth: 300
    },

    text: {
        fontSize: 'calc(12px + 0.2vw)',
    },

    filterTitle: {
        padding: 5,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontSize: 'calc(10px + 0.4vw)',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    filterDiv: {
        background: '#FAFCF6',
        border: '1px', /* Change this value to your desired color */
        borderRadius: 20,
        padding: '20px',
        /* Change this value to your desired background color */
        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',
        /* Add a subtle shadow */
        marginBottom: 10
    },

    new: {
        padding: 20,
        borderRadius: 10,
        margin: 10,
        marginTop: 0,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderBottom: '2px solid #A3CC51', /* Change this value to your desired color */
        borderRadius: '20px',
        padding: '20px',
        /* Change this value to your desired background color */
        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',
        /* Add a subtle shadow */
    },

    title: {
        fontFamily: 'Inter',
        fontSize: '24px',
        fontWeight: 700,

        letterSpacing: '0em',
        textAlign: 'left',
        "&:hover": {
            cursor: "pointer",
            color: 'rgba(0, 0, 0, .6)'
        }
    },

    img: {
        "&:hover": {
            cursor: "pointer",
            color: 'rgba(0, 0, 0, .6)'
        }
    },

    tags: {
        fontWeight: 'bold',
        color: 'gray',
        fontSize: 'calc(12px + 0.2vw)'
    },

    dateStyle: {
        fontSize: 'calc(9px + 0.2vw)'
    },
    button: {
        marginTop: 20,
        fontFamily: 'Poppins',
        height: 35,
        fontSize: '10px',
        background: '#A3CC51',
        width: 250,
        fontWeight: 'bold',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },
    },
    highlight: {
        backgroundColor: '#A3CC51',
    },
    highlightRed: {
        backgroundColor: '#EA5611',
    }, border: {
        border: '2px solid #000',  // Change the border style as per your needs
      },
});

export default NewsList;