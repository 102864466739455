import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    mainDiv: {
        fontFamily: 'Poppins',
        display: 'flex',
        flexDirection: 'column',
        width: '95vw',

    },

    postDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },

    postDiv2: {
        width: '95%',
        maxWidth: 800,
        paddingBottom: 50,
    },

    miniInfo: {
        display: 'flex',
        flexDirection: 'row',
        right: 0,
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },

    miniDateDiv: {
        display: 'flex',
        flexDirection: 'row',
        right: 0,
        alignContent: 'center',
        alignItems: 'center'
    },

    miniDate: {
        marginRight: 20,
        fontSize: 14,
        color: 'rgba(0, 0, 0, .4)',
        fontWeight: 'bold'
    },

    shareDiv: {
        height: 25, width: 90, display: 'flex', justifyContent: 'space-between'
    },

    warningDiv: {
        width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center', alignItems: 'center'
    },

    loaderDiv: {
        display: 'flex', justifyContent: 'center', alignContent: 'center'
    },

    tagsDiv: {
        display: 'flex', 
        flexDirection: 'row', 
        position: 'relative', 
        color: '#7E6F23', 
        fontWeight: 'bold', 
        marginRight: 10,
        fontFamily:'Poppins'
        
    },

    imageDiv: {
        width: '100%',
        maxWidth: 800
    },

    audioDiv: {
        marginTop: 10,
        marginBottom: 5,
    },

    miniVerified: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginLeft: 10
    },

    miniUser: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },

    button: {
        marginTop: 20,
        fontFamily: 'Poppins',
        height: 35,
        fontSize: '10px',
        background: '#A3CC51',
        width: 100,
        fontWeight: 'bold',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },
    },
}))
