import React, { useEffect, useState } from "react";
import { getToken } from '../../utils/utils';
import api_amr from '../../services/api_amr';
import { TextField, Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails, Button, makeStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Box } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';
import RecomendacoesTable from './RecomendacoesTable';
import AddRecomendacaoForm from './AddRecomendacaoForm';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
        width: '100%',
    },
    card: {
        width: '100%',
        padding: theme.spacing(1),
        boxShadow: theme.shadows[3],
    },
    filterField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    accordionSummary: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: theme.spacing(0, 1),
        maxHeight: '40px', // Diminuir a altura mínima do resumo do acordeão
    },
    detailRow: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
    detailItem: {
        flex: '1 1 25%',
        padding: theme.spacing(0.25), // Diminuir o padding
        fontSize: '0.75rem', // Diminuir a fonte
    },
    cardRoot: {
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        backgroundColor: '#fafafa',
    },
    cardContent: {
        padding: theme.spacing(1),
    },
    addButton: {
        marginTop: theme.spacing(2),
    },
    statusOpen: {
        color: 'green',
    },
    statusClosed: {
        color: 'red',
    },
}));

function VisualizarRecomendacoes() {
    const [demandas, setDemandas] = useState([]);
    const [recomendacoes, setRecomendacoes] = useState({});
    const [selectedDemandaId, setSelectedDemandaId] = useState(null);
    const [filters, setFilters] = useState({
        search: '',
        safra: null,
        cultura: null,
        status: null,
        hasRecommendations: 'all', // new filter for recommendations
    });
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    async function fetchDemandas() {
        const response = await api_amr.get('/get_demandas', { headers: { 'x-api-key': getToken() } });
        setDemandas(response.data);
    }

    async function fetchRecomendacoes(demandaId) {
        const response = await api_amr.get('/get_recomendacoes', {
            headers: { 'x-api-key': getToken() },
            params: { demanda_id: demandaId }
        });
        setRecomendacoes(prevState => ({
            ...prevState,
            [demandaId]: Array.isArray(response.data) ? response.data : []
        }));
    }

    useEffect(() => {
        fetchDemandas();
    }, []);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const handleAutocompleteChange = (event, value, name) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const handleDeleteRecomendacao = (id) => {
        setRecomendacoes(prevState => {
            const updatedRecomendacoes = { ...prevState };
            Object.keys(updatedRecomendacoes).forEach(key => {
                updatedRecomendacoes[key] = updatedRecomendacoes[key].filter(recomendacao => recomendacao.id !== id);
            });
            return updatedRecomendacoes;
        });
    };

    const filteredDemandas = demandas.filter((demanda) => {
        const searchTerm = filters.search.toLowerCase();
    
        const isMatch = (field) =>
            field && field.toString().toLowerCase().includes(searchTerm);
    
        const matchesRecommendationsFilter = (demanda) => {
            if (filters.hasRecommendations === 'with') {
                return demanda.total_recomendacoes > 0;
            }
            if (filters.hasRecommendations === 'without') {
                return demanda.total_recomendacoes === 0;
            }
            return true; // 'all' case
        };
    
        return (
            (isMatch(demanda.nome_fazenda) ||
                isMatch(demanda.name_associado) ||
                isMatch(demanda.produto) ||
                isMatch(demanda.quantidade) ||
                isMatch(demanda.nome_cultura) ||
                isMatch(demanda.ano_safra) ||
                isMatch(demanda.status) ||
                isMatch(demanda.preco_total_pago) ||
                isMatch(demanda.fornecedor) ||
                isMatch(demanda.obs)) &&
            (!filters.safra || demanda.ano_safra === filters.safra) &&
            (!filters.cultura || demanda.nome_cultura === filters.cultura) &&
            (!filters.status || demanda.status === filters.status) &&
            matchesRecommendationsFilter(demanda)
        );
    });

    const handleOpen = (demandaId) => {
        setSelectedDemandaId(demandaId);
        setOpen(true);
        fetchRecomendacoes(demandaId);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedDemandaId(null);
    };

    const uniqueSafras = [...new Set(demandas.map(demanda => demanda.ano_safra))];
    const uniqueCulturas = [...new Set(demandas.map(demanda => demanda.nome_cultura))];
    const uniqueStatus = [...new Set(demandas.map(demanda => demanda.status))];

    return (
        <div className={classes.container}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Visualizar Recomendações
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <TextField
                                label="Filtrar"
                                variant="outlined"
                                size="small"
                                name="search"
                                onChange={handleFilterChange}
                                className={classes.filterField}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Autocomplete
                                options={uniqueSafras}
                                getOptionLabel={(option) => option}
                                onChange={(event, value) => handleAutocompleteChange(event, value, 'safra')}
                                renderInput={(params) => <TextField {...params} label="Safra" variant="outlined" size="small" className={classes.filterField} />}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Autocomplete
                                options={uniqueCulturas}
                                getOptionLabel={(option) => option}
                                onChange={(event, value) => handleAutocompleteChange(event, value, 'cultura')}
                                renderInput={(params) => <TextField {...params} label="Cultura" variant="outlined" size="small" className={classes.filterField} />}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Autocomplete
                                options={uniqueStatus}
                                getOptionLabel={(option) => option}
                                onChange={(event, value) => handleAutocompleteChange(event, value, 'status')}
                                renderInput={(params) => <TextField {...params} label="Status" variant="outlined" size="small" className={classes.filterField} />}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                select
                                label="Recomendações"
                                value={filters.hasRecommendations}
                                onChange={(event) => handleFilterChange({ target: { name: 'hasRecommendations', value: event.target.value } })}
                                variant="outlined"
                                size="small"
                                className={classes.filterField}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value="all">Todas</option>
                                <option value="with">Com Recomendações</option>
                                <option value="without">Sem Recomendações</option>
                            </TextField>
                        </Grid>
                    </Grid>
                    {filteredDemandas.map((demanda) => {
                        const statusClass = demanda.status.toLowerCase() === 'aberto' ? classes.statusOpen : classes.statusClosed;
                        return (
                            <Accordion key={demanda.id_demanda} className={classes.cardRoot}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    onClick={() => fetchRecomendacoes(demanda.id_demanda)}
                                    className={classes.accordionSummary}
                                >
                                    <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
                                        <Box className={classes.detailItem}>
                                            <Typography variant="subtitle2"><strong>Nome Fazenda:</strong> {demanda.nome_fazenda}</Typography>
                                            <Typography variant="subtitle2"><strong>Associado:</strong> {demanda.name_associado}</Typography>
                                        </Box>
                                        <Box className={classes.detailItem}>
                                            <Typography variant="subtitle2"><strong>Produto:</strong> {demanda.produto}</Typography>
                                            <Typography variant="subtitle2"><strong>Quantidade:</strong> {demanda.quantidade}</Typography>
                                        </Box>
                                        <Box className={classes.detailItem}>
                                            <Typography variant="subtitle2"><strong>Cultura:</strong> {demanda.nome_cultura}</Typography>
                                            <Typography variant="subtitle2"><strong>Ano Safra:</strong> {demanda.ano_safra}</Typography>
                                        </Box>
                                        <Box className={classes.detailItem}>
                                            <Typography variant="subtitle2" className={statusClass}><strong>Status:</strong> {demanda.status}</Typography>
                                            <Typography variant="subtitle2"><strong>Recomendações:</strong> {demanda.total_recomendacoes}</Typography>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails className={classes.cardContent}>
                                    <div>
                                        <RecomendacoesTable
                                            recomendacoes={recomendacoes[demanda.id_demanda]}
                                            onDelete={handleDeleteRecomendacao}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.addButton}
                                            onClick={() => handleOpen(demanda.id_demanda)}
                                        >
                                            Adicionar Recomendação
                                        </Button>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </CardContent>
            </Card>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>Adicionar Recomendação</DialogTitle>
                <DialogContent>
                    <AddRecomendacaoForm demandaId={selectedDemandaId} onClose={handleClose} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default VisualizarRecomendacoes;
