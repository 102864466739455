import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, TextField, Typography, Paper, Box } from '@material-ui/core';
import ReactCompareImage from 'react-compare-image';
import { getToken, checarPermissao } from '../../utils/utils'
import Button from "@material-ui/core/Button";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import landData from '../../fazenda_vale_do_ouro.json'; // Adjust the path to where your GeoJSON file is located
import { point, polygon, inside } from '@turf/turf';
import * as echarts from 'echarts';
import landData2 from '../../fazenda_pontal.json'; // Adjust the path to where your GeoJSON file is located
import api_amr from '../../services/api_amr';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@material-ui/core';
import * as XLSX from 'xlsx';

const useStyles = makeStyles((theme) => ({
  root: {
  
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  slider: {
    marginTop: theme.spacing(3),
  },
  imageContainer: {
    width: '100%', // O contêiner da imagem ocupa 100% da largura disponível
    height: '70vh', // A altura é automática e depende do conteúdo
    display: 'flex', // Usa flexbox para alinhar a imagem
    justifyContent: 'center', // Centraliza a imagem horizontalmente
    alignItems: 'center', // Centraliza a imagem verticalmente
    overflow: 'hidden', // Garante que nada seja cortado
  },
  image: {
    maxWidth: '100%', // A imagem pode ocupar no máximo 100% da largura do contêiner
    maxHeight: '70vh', // A imagem pode ocupar no máximo 70% da altura da tela
    objectFit: 'contain', // Ajusta a imagem para ser contida dentro do contêiner sem cortar
  },
  dateInput: {
    marginRight: theme.spacing(2),
    width: 150,
  },
  tabContent: {
    marginTop: theme.spacing(2),
  },

  compareImageContainer: {
    width: '100%', // Container takes up 100% of the width of its parent
    height: '70vh', // Height is set to auto to maintain aspect ratio
    // Remove specific maxHeight, let the image decide its height based on width
  },
  compareImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'column'
    // Posiciona as abas no lado direito
  },
  tabsVertical: {
    borderRight: `1px solid ${theme.palette.divider}`,
    marginRight: theme.spacing(2),
  },
  calcButton: {
    fontFamily: 'Poppins',
    height: 40,
    fontSize: '10px',
    background: '#A3CC51',
    width: '146px',
    color: 'white',
    borderRadius: 6,
    '&:hover': {
      background: "rgba(163, 204, 81, 0.7)",
    },
  },
}));

function PrevisaoClimatica(props) {
  const classes = useStyles();
  const minDate = '2023-08-19';
  const minDate2 = '2023-12-10';
  const maxDate = new Date().toISOString().split('T')[0]; // current date in YYYY-MM-DD format
  const [permitido, setPermitido] = useState('')
  const [show, setShow] = useState(0);
  const [dadosHist, setDadosHist] = useState('')
  const [fazendasShape, setFazendasShape] = useState([])

  useEffect(() => {
    async function fetchMyAPI() {

      setPermitido(await checarPermissao('previsao_climatica', props))

    }
    fetchMyAPI();
    getFazendasShape()
  }, []);

  const getFazendasShape = async () => {
    const response = await api_amr.get('/retorna_fazendas_shape', { headers: { 'x-api-key': getToken() } })


    if (response.status === 200) {

      setFazendasShape(response.data.fazendas.features)

    }

  }
  const generateDateRange = (start, end) => {
    let dates = [];
    let currentDate = new Date(start);
    let endDate = new Date(end);

    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };
  const [tabValue, setTabValue] = useState(0);
  const [compareDate1, setCompareDate1] = useState(minDate);
  const [compareDate2, setCompareDate2] = useState(minDate2);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [startDate, setStartDate] = useState(minDate);
  const [endDate, setEndDate] = useState(maxDate);
  const [dates, setDates] = useState(generateDateRange(minDate, maxDate));
  const [selectedDate, setSelectedDate] = useState(minDate);

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    setDates(generateDateRange(newStartDate, endDate));
    setSelectedDate(newStartDate);
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
    setDates(generateDateRange(startDate, newEndDate));
    setSelectedDate(startDate);
  };

  const handleSliderChange = (event, newValue) => {
    setSelectedDate(dates[newValue]);
  };
  const imageStyles = {
    width: '100%', // Images fill the width of their container
    maxHeight: '70vh',// Remove maxHeight to allow the images to define their height based on their aspect ratio
    objectFit: 'contain', // This will ensure the image fits within its bounding box
  };
  const api_key = 'eb077c742bbbf57c1ccca207afca8723';  // Replace with your actual API key
  const layer = 'precipitation_new';  // Example layer

  const RainChart = ({ forecastData }) => {
    const chartRef = useRef(null);

    useEffect(() => {
      const chartInstance = echarts.init(chartRef.current);

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          {
            type: 'category',
            data: forecastData.list.map(item =>
              `${new Date(item.dt * 1000).toLocaleDateString()} ${new Date(item.dt * 1000).toLocaleTimeString()}`
            ),
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: 'Rain (mm)',
            min: 0,
            max: 50,
            interval: 10,
            axisLabel: {
              formatter: '{value} mm'
            }
          },
          {
            type: 'value',
            name: 'Cumulative Rain',
            min: 0,
            max: 50,
            interval: 10,
            axisLabel: {
              formatter: '{value} mm'
            }
          }
        ],
        series: [
          {
            name: 'Hourly Rain',
            type: 'bar',
            data: forecastData.list.map(item =>
              item.rain ? item.rain['3h'] : 0
            )
          },
          {
            name: 'Cumulative Rain',
            type: 'line',
            yAxisIndex: 1,
            data: forecastData.list.map(item => item.cumulativeRain)
          }
        ]
      };

      chartInstance.setOption(option);

      return () => {
        chartInstance.dispose();
      };
    }, [forecastData]);

    return (
      <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>
    );
  };
  const LayerSelector = ({ currentLayer, onLayerChange }) => {
    return (
      <select value={currentLayer} onChange={(e) => onLayerChange(e.target.value)}>
        <option value="precipitation_new">Precipitação</option>
        <option value="clouds_new">Nuvens</option>
        <option value="pressure_new">Pressão</option>
        <option value="wind_new">Vento</option>
        <option value="temp_new">Temperatura</option>
      </select>
    );
  };

  const MiniScreen = ({ weatherData, onClose, api_key, historicalRain, fazendasShape }) => {
    const [forecastData, setForecastData] = useState(null);
    const [cumulativeRain, setCumulativeRain] = useState(0);
    const [tabValue, setTabValue] = useState(0);
    const [fazendaInfo, setFazendaInfo] = useState('')
    const [histTabela, setHistTabela] = useState([])
    const [histTabelaSemana1, setHistTabelaSemana1] = useState([])
    const [histTabelaSemana2, setHistTabelaSemana2] = useState([])
    const [codImovelMaisProximo, setCodImovelMaisProximo] = useState('')
    const [pivot, setPivot] = useState(false)

    const kelvinToCelsius = (kelvin) => (kelvin - 273.15).toFixed(2);
    const getBarHeight = (rainAmount, maxRain) => {
      return maxRain > 0 ? (rainAmount / maxRain) * 100 : 0;
    };

    useEffect(() => {
      fetchForecast();
      fetchFarmInfo();
      fetchTabelaInfo();
    }, [weatherData.coord.lat, weatherData.coord.lon, api_key]);

    const fetchForecast = async () => {
      // console.log('fazendasShape', fazendasShape)
      // console.log('weatherData', weatherData)

      try {
        const response = await fetch(
          `https://api.openweathermap.org/data/2.5/forecast?lat=${weatherData.coord.lat}&lon=${weatherData.coord.lon}&appid=${api_key}&lang=pt`
        );
        const data = await response.json();

        // Add cumulative rain calculation to each forecast interval
        let cumulativeRain = 0;
        data.list.forEach(item => {
          const rainAmount = item.rain ? item.rain['3h'] : 0;
          cumulativeRain += rainAmount;
          item.cumulativeRain = cumulativeRain.toFixed(2);
        });

        setForecastData(data);
      } catch (error) {
        console.error('Error fetching forecast data:', error);
      }
    };
    // Gambiarra pra puxar o cod_imovel da fazenda
    function calculaDistancia(lat1, lon1, lat2, lon2) {
      const raioTerra = 6371; // Raio da Terra em km
      const dLat = grausParaRadianos(lat2 - lat1);
      const dLon = grausParaRadianos(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(grausParaRadianos(lat1)) * Math.cos(grausParaRadianos(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return raioTerra * c;
    }

    function grausParaRadianos(graus) {
      return graus * (Math.PI / 180);
    }

    function encontraFazendaMaisProxima(fazendasShape, weatherData) {
      let fazendaMaisProxima = null;
      let menorDistancia = Infinity;

      fazendasShape.forEach(fazenda => {
        const coordenadas = fazenda.geometry.coordinates[0];
        let latMedia = 0, lonMedia = 0;

        coordenadas.forEach(coord => {
          lonMedia += coord[0];
          latMedia += coord[1];
        });

        latMedia /= coordenadas.length;
        lonMedia /= coordenadas.length;

        const distancia = calculaDistancia(weatherData.coord.lat, weatherData.coord.lon, latMedia, lonMedia);

        if (distancia < menorDistancia) {
          menorDistancia = distancia;
          fazendaMaisProxima = fazenda.properties.cod_imovel;
        }
      });

      return fazendaMaisProxima;
    }

    const fetchFarmInfo = async () => {
      const response = await api_amr.get('/retorna_fazenda_info', { params: { 'car': encontraFazendaMaisProxima(fazendasShape, weatherData) }, headers: { 'x-api-key': getToken() } })
      if (response.status === 200) {
        console.log('fazenda', response.data)
        setFazendaInfo(response.data.nome)
      }
    }
    const fetchTabelaInfo = async () => {

      const response = await api_amr.get('/historico_precipitação_tabela', { params: { 'car': encontraFazendaMaisProxima(fazendasShape, weatherData) }, headers: { 'x-api-key': getToken() } })

      if (response.status === 200) {
        setHistTabela(response.data.tabela_mes)
        setHistTabelaSemana1(response.data.tabela_semana_1)
        setHistTabelaSemana2(response.data.tabela_semana_2)
      }
    }

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };


    function TabelaHistAnual({ data }) {
      // Definindo as colunas para a tabela
      const columns = [
        { id: 'year', label: 'Ano' },
        { id: 'Jan', label: 'Jan' },
        { id: 'Feb', label: 'Fev' },
        { id: 'Mar', label: 'Mar' },
        { id: 'Apr', label: 'Abr' },
        { id: 'May', label: 'Mai' },
        { id: 'Jun', label: 'Jun' },
        { id: 'Jul', label: 'Jul' },
        { id: 'Aug', label: 'Ago' },
        { id: 'Sep', label: 'Set' },
        { id: 'Oct', label: 'Out' },
        { id: 'Nov', label: 'Nov' },
        { id: 'Dec', label: 'Dez' },
        { id: 'Total Anual', label: 'Total Anual' },
      ];

      const formatValue = (value) => {
        return typeof value === 'number' ? parseFloat(value.toFixed(3)) : value;
      };
      const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Histórico Anual");
        XLSX.writeFile(wb, "Historico_Anual.xlsx");
      };

      return (
        <><button onClick={exportToExcel}>Baixar Excel</button>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="tabela simples">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{ backgroundColor: rowIndex === data.length - 1 ? '#e0e0e0' : '' }}
                  >
                    {columns.map((column, columnIndex) => (
                      <TableCell key={column.id}
                        style={{ backgroundColor: columnIndex === columns.length - 1 ? '#f0f0f0' : '' }}
                      >
                        {formatValue(row[column.id])}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    }

    function TabelaSemanal({ data }) {
      const [page, setPage] = useState(0);
      const years = [...new Set(data.map(item => item.year))]; // Extraindo os anos
      const currentPageData = data.filter(item => item.year === years[page]); // Dados do ano atual

      // Colunas para a tabela
      const columns = [
        { id: 'year', label: 'Ano' },
        { id: 'week', label: 'Semana' },
        { id: 'raw_value', label: 'Valor Bruto' },
      ];

      // Função para formatar os valores numéricos
      const formatValue = (value) => {
        return typeof value === 'number' ? parseFloat(value.toFixed(3)) : value;
      };

      // Manipulador de mudança de página
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      const exportToExcelPagina = () => {
        const ws = XLSX.utils.json_to_sheet(currentPageData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Dados");
        XLSX.writeFile(wb, "Dados_Tabela.xlsx");
      };
      const exportToExcelTotal = () => {
        // Utiliza 'data' em vez de 'currentPageData' para incluir todos os dados
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Dados");
        XLSX.writeFile(wb, "Dados_Tabela_Completa.xlsx");
      };
      return (
        <>
          <button onClick={exportToExcelTotal}>Baixar Excel Total</button>
          <button onClick={exportToExcelPagina}>Baixar Excel Pagina</button>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="tabela de dados semanais">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => (
                      <TableCell key={column.id}>
                        {formatValue(row[column.id])}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={years.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={1}
            rowsPerPageOptions={[1]} // Um ano por página
          />
        </>
      );

    }
    function TabelaSemanal2({ data }) {
      // Definindo as colunas para a tabela
      // Adiciona o 'year' no início e move 'Total Anual' para o final
      const columns = ['year', ...Array.from({ length: 53 }, (_, i) => (i + 1).toString()), 'Total Anual'].map(key => ({
        id: key,
        label: key === 'Total Anual' ? 'Total Anual' : key === 'year' ? 'Ano' : `Semana ${key}`
      }));

      // Função para formatar os valores numéricos
      const formatValue = (value) => {
        return typeof value === 'number' ? parseFloat(value.toFixed(3)) : value;
      };
      const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Dados Semanais");
        XLSX.writeFile(wb, "Dados_Semanais.xlsx");
      };

      return (
        <>
          <button style={{ width: 100 }} onClick={exportToExcel}>Baixar Excel</button>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="tabela de dados anuais">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => (
                      <TableCell key={column.id}>
                        {formatValue(row[column.id])}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    }

    const handlePivot = () => {
      setPivot(!pivot)
    }

    const Previsao = () => {

      const cardStyle = {
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        margin: '1em',
        padding: '1em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '250px', // Increase maxWidth for larger card size
        width: '100%', // Ensure the card expands to the maxWidth
        boxSizing: 'border-box' // Include padding and borders in the width
      };

      const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        marginTop: '1em',
        flexWrap: 'nowrap',
        width: '100%', // Use full width of the container
        maxWidth: '1100px', // Adjust maxWidth to fit four cards and buttons
        margin: '0 auto' // Center the container if it's not as wide as the screen
      };

      const buttonStyle = {
        border: 'none',
        background: '#fff',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        padding: '0.5em 1em',
        margin: '1em',
        cursor: 'pointer',
        fontSize: '1.5rem'
      };

      const [visibleIndex, setVisibleIndex] = useState(0);
      const cardsPerPage = 4;
      const maxIndex = forecastData ? forecastData.list.length - cardsPerPage : 0;

      const goNext = () => {
        setVisibleIndex(prevIndex => (prevIndex < maxIndex ? prevIndex + cardsPerPage : prevIndex));
      };

      const goPrev = () => {
        setVisibleIndex(prevIndex => (prevIndex > 0 ? prevIndex - cardsPerPage : 0));
      };


      return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
          <h2 style={{ fontSize: '1.75rem', margin: '0.5em 0' }}>
            {fazendaInfo}
          </h2>
          <p style={{ fontSize: '2em', fontWeight: 'bold', margin: '0.25em 0' }}>
            {kelvinToCelsius(weatherData.main.temp)}°C
          </p>
          <p style={{ fontSize: '1.25rem', margin: '0.25em 0' }}>
            {weatherData.weather[0].description}
          </p>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <button style={buttonStyle} onClick={goPrev} disabled={visibleIndex === 0}>{"<"}</button>
            <div style={containerStyle}>
              {forecastData && forecastData.list.slice(visibleIndex, visibleIndex + cardsPerPage).map((forecast, index) => (
                <div key={index} style={cardStyle}>
                  <span>
                    {new Date(forecast.dt * 1000).toLocaleDateString()}{' '}
                    {new Date(forecast.dt * 1000).toLocaleTimeString()}
                  </span>
                  <span>Chuva: {forecast.rain ? forecast.rain['3h'].toFixed(2) : '0.00'} mm</span>
                  <span>Acumulado: {forecast.cumulativeRain} mm</span>
                  <span>Temperatura: {kelvinToCelsius(forecast.main.temp)}°C</span>
                  <img src={`http://openweathermap.org/img/w/${forecast.weather[0].icon}.png`}
                    alt={forecast.weather[0].description}
                    style={{ width: '50px', height: '50px' }} />
                  <span>{forecast.weather[0].description}</span>
                </div>
              ))}

            </div>
            <button style={buttonStyle} onClick={goNext} disabled={visibleIndex === maxIndex}>{">"}</button>
          </div>
        </div>
      );
    }

    return (
      <Paper style={{
        position: 'absolute',
        top: '10%',
        left: '10%',
        width: '85%',
        height: '60vh', // Set a fixed height to allow scrolling
        backgroundColor: 'white',
        border: '10px solid #ddd',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: '20px',
        zIndex: 100000,
        overflowY: 'auto', // Enable vertical scrolling
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',

      }}>
        <button onClick={onClose} style={{
          alignSelf: 'flex-end',
          padding: '0.5em 1em',
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          fontSize: '1.5rem',
        }}>X</button>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label="Previsão do Tempo" />
          <Tab label="Historico Precipitação anual" />
          <Tab label="Historico Precipitação semanal" />
          <Tab label="Grafico Previsão" />
          <Tab label="Grafico Historico de precipitação" />

        </Tabs>

        <Box p={3}>
          {tabValue === 0 && (
            <div style={{ width: '100%' }}>
              <Previsao />
            </div>
          )}

          {tabValue === 1 && (
            <div>
              <TabelaHistAnual data={histTabela} />
            </div>
          )}
          {tabValue === 2 && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <button style={{ width: 100 }} onClick={() => handlePivot()} >Rotacionar</button>

              {!pivot ?
                <TabelaSemanal2 data={histTabelaSemana2} /> : <TabelaSemanal data={histTabelaSemana1} />}
            </div>
          )}
          {tabValue === 3 && (
            <div style={{ width: 650 }}>
              {forecastData && <RainChart forecastData={forecastData} />}

            </div>
          )}
          {tabValue === 4 && (
            <div style={{ width: 650 }}>
              {historicalRain && <RainChartHist historicalRain={historicalRain} />}
            </div>
          )}

        </Box>
      </Paper>
    );

  };

  const RainChartHist = ({ historicalRain }) => {
    const chartRef = useRef(null);

    useEffect(() => {
      if (historicalRain && historicalRain.length) {
        const chartInstance = echarts.init(chartRef.current);

        // Calculate the cumulative rain
        let cumulativeRain = 0;
        const cumulativeRainData = historicalRain.map(item => {
          cumulativeRain += item.avg_value;
          return cumulativeRain;
        });

        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          toolbox: {
            feature: {
              saveAsImage: { show: true },
              dataZoom: { show: true },
            }
          },
          legend: {
            data: ['Average Rainfall', 'Cumulative Rainfall']
          },
          dataZoom: [
            {
              type: 'slider',
              start: 0,
              end: 100
            }
          ],
          xAxis: {
            type: 'category',
            data: historicalRain.map(item => new Date(item.date).toLocaleDateString()),
            axisPointer: {
              type: 'shadow'
            }
          },
          yAxis: [
            {
              type: 'value',
              name: 'Rain (mm)',
              axisLabel: {
                formatter: '{value} mm'
              }
            }
          ],
          series: [
            {
              name: 'Average Rainfall',
              type: 'bar',
              data: historicalRain.map(item => item.avg_value)
            }
          ]
        };

        chartInstance.setOption(option);

        return () => {
          chartInstance.dispose();
        };
      }
    }, [historicalRain]);

    return (
      <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>
    );
  };




  const falcatruaPage = `http://localhost:3001/?token=${encodeURIComponent(getToken())}`
  return (
    <Box className={classes.tabsContainer}>
      <div>
        <Tabs
          orientation="horizontal"
          value={tabValue}
          onChange={handleTabChange}
          className={classes.tabsVertical}
        >
          <Tab label="Mapa Fazendas" />
          <Tab label="Previsão acumulada" />
        </Tabs>
      </div>
      <Box flex={1}>
        {tabValue === 0 && (
          <Box className={classes.root}>
            <div className="App" >
              <iframe
                src={falcatruaPage}
                title="Página Externa"
                width={window.innerWidth*0.85}
                height={window.innerHeight*0.85}
                style={{ border: 'none' }}
              />
              {/* <WeatherMap layer={layer} api_key={api_key} landData={landData} landData2={landData2} /> */}
            </div>
          </Box>)}
        {tabValue === 2 && (
          <Box className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="subtitle1" gutterBottom>
                Comparação de images: {compareDate1} - {compareDate2}
              </Typography>
              <Box className={classes.compareImageContainer}>
                <ReactCompareImage
                  leftImage={`https://amrbibucket.s3.sa-east-1.amazonaws.com/maps/observado/map_${compareDate1.replaceAll('-', '')}.png`}
                  rightImage={`https://amrbibucket.s3.sa-east-1.amazonaws.com/maps/observado/map_${compareDate2.replaceAll('-', '')}.png`}
                  leftImageCss={imageStyles}
                  rightImageCss={imageStyles}
                />
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                <TextField
                  id="compare-date-1"
                  label="Data inicial"
                  type="date"
                  defaultValue={minDate}
                  className={classes.dateInput}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: minDate, max: maxDate }}
                  onChange={(e) => setCompareDate1(e.target.value)}
                />
                <TextField
                  id="compare-date-2"
                  label="Data Final"
                  type="date"
                  defaultValue={minDate2}
                  className={classes.dateInput}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: minDate, max: maxDate }}
                  onChange={(e) => setCompareDate2(e.target.value)}
                />
              </Box>
            </Paper>
          </Box>

        )}
        {tabValue === 1 && (<div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ margin: 5 }}>
            <Button className={classes.calcButton} onClick={() => setShow(0)}>
              <b className={classes.input}>Acumulado </b>
            </Button>
            <Button className={classes.calcButton} style={{ marginLeft: 10 }} onClick={() => setShow(1)}>
              <b className={classes.input}>1 A 7 DIAS </b>
            </Button>
            <Button className={classes.calcButton} style={{ marginLeft: 10 }} onClick={() => setShow(2)}>
              <b className={classes.input}> 8 A 14 DIAS </b>
            </Button>
          </div>

          <div>
            {show === 0 ?
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: 10 }}>
                  <h4>Acumulado de chuvas - 7 dias América do Sul </h4>
                  <img
                    style={{ width: '400px', height: '350px' }}
                    src={'https://www.cpc.ncep.noaa.gov/products/JAWF_Monitoring/Brazil/daily/p.7day.figa.gif'}
                  />
                </div>
                <div>
                  <h4>América do Norte </h4>
                  <img
                    style={{ width: '400px', height: '350px' }}
                    src={'https://www.cpc.ncep.noaa.gov/products/Precip_Monitoring/Figures/NAMS/p.7day.figa.gif'}
                  />
                </div>
                <div>
                  <h4>Ásia (Índia e China) </h4>
                  <img
                    style={{ width: '400px', height: '350px' }}
                    src={'https://www.cpc.ncep.noaa.gov/products/Precip_Monitoring/Figures/Asia_Aust/p.7day.figa.gif'}
                  />
                </div>
              </div> : null}
            {show === 1 ?
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: 10 }}>
                  {' '}
                  <h4>Previsão de Chuvas - 1 a 7 dias América do Sul </h4>
                  <img style={{ width: '400px', height: '350px' }} src={'https://www.cpc.ncep.noaa.gov/products/JAWF_Monitoring/GFS/BR_curr.p.gfs1a.gif'} />
                </div>
                <div>
                  {' '}
                  <h4>América do Norte </h4>
                  <img style={{ width: '400px', height: '350px' }} src={'https://www.cpc.ncep.noaa.gov/products/Precip_Monitoring/Figures/GFS/NA_curr.p.gfs1a.gif'} />{' '}

                </div>
                <div>
                  {' '}
                  <h4>Ásia (Índia e China) </h4>
                  <img style={{ width: '400px', height: '350px' }} src={'https://www.cpc.ncep.noaa.gov/products/Precip_Monitoring/Figures/GFS/AS_curr.p.gfs1a.gif'} />{' '}

                </div>
              </div>
              : null}
            {show === 2 ?
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: 10 }}>
                  {' '}
                  <h4>Previsão de Chuvas - 1 a 7 dias América do Sul </h4>
                  <img style={{ width: '400px', height: '350px' }} src={'https://www.cpc.ncep.noaa.gov/products/JAWF_Monitoring/GFS/BR_curr.p.gfs2a.gif'} />{' '}
                </div>
                <div>
                  {' '}
                  <h4>América do Norte </h4>
                  <img style={{ width: '400px', height: '350px' }} src={'https://www.cpc.ncep.noaa.gov/products/Precip_Monitoring/Figures/GFS/NA_curr.p.gfs2a.gif'} />{' '}

                </div>
                <div>
                  {' '}
                  <h4>Ásia (Índia e China) </h4>
                  <img style={{ width: '400px', height: '350px' }} src={'https://www.cpc.ncep.noaa.gov/products/Precip_Monitoring/Figures/GFS/AS_curr.p.gfs2a.gif'} />{' '}

                </div>

              </div>

              : null}


          </div>


        </div >)}
      </Box>
    </Box >
  );
}

export default PrevisaoClimatica;
