import React, { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, DoughnutController, ArcElement } from 'chart.js';

Chart.register(DoughnutController, ArcElement);

const parseChartData = (str) => {
    const specialValuePlaceholder = "SPECIAL_VALUE";
    const escapedStr = str.replace(/B2,5/g, specialValuePlaceholder);
    const items = escapedStr.split(',');
    const labels = [];
    const data = [];
    const backgroundColors = [];

    items.forEach(item => {
        const correctedItem = item.replace(new RegExp(specialValuePlaceholder, 'g'), 'B2,5');
        const [label, value] = correctedItem.split(':');

        const trimmedLabel = label.trim();
        if (colors.hasOwnProperty(trimmedLabel)) { // Check if label exists in colors object
            labels.push(trimmedLabel);
            data.push(parseFloat(value));
            backgroundColors.push(colors[trimmedLabel]);
        }
    });

    return { labels, data, backgroundColors };
};


const colors = {
    "UR": "#0713f0", // cor fixa da materia UR
    "SAM": "#3498DB",
    "CAM": "#2980B9",
    "MAP 52": "#154079",
    "MAP 10-50": "#FFC300",
    "MAP 11-44": "#FF9A00",
    "DAP": "#FF6E00",
    "MES 09": "#FF4300",
    "MES 15": "#2ECC71",
    "NPS 12-46": "#27AE60",
    "YUC 10-40": "#1A8440",
    "YUC 09-46": "#0E5A29",
    "STP": "#9B59B6",
    "SSP 21": "#8E44AD",
    "SSP 19": "#6C3483",
    "SSP 2-18": "#4A235A",
    "SSP 3-17": "#900C3F",
    "SSP 23": "#C70039",
    "KCL": "#ED1C1C", // cor fixa da materia KCL
    "SOP": "#581845",
    "KMgS": "#FF5733",
    "B10": "#D6DBDF",
    "B2,5": "#B2BABB",
    "BoMo": "#839192",
    "Zn10": "#2C3E50",
    "Zn15": "#273746",
    "Zn20": "#1E2833",
    "Cu10": "#151C20",
    "NAM": "#F1C40F",
    "NAM_EST": "#D4AC0D",
    "DU": "#AF8C0A",
    "KCL 60.5": "#8A6607",
    "FMA BR12": "#E67E22",
    "Bentonita 90": "#CA6F1E",
    "Sulfato de Mg Kieserita": "#AF4C18",
    "SSP micrado": "#73300C",
    "NBPT": "#E74C3C",
    "NPK15-15-15": "#CB4335",
    "ASPIRE": "#A93226",
    "NPS 08-40-00+5S": "#7B0F17",
    "NPK 16-16-16": "#7B0F17"
};

const MateriasDoughnutGrafico = ({ Chartdata }) => {
    const materiasData = useMemo(() => parseChartData(Chartdata.materias), [Chartdata.materias]);
    const micronutrientesData = useMemo(() => parseChartData(Chartdata.micronutrientes), [Chartdata.micronutrientes]);

    const labels = [...materiasData.labels, ...micronutrientesData.labels];
    const data = [...materiasData.data, ...micronutrientesData.data];
    const backgroundColors = [...materiasData.backgroundColors, ...micronutrientesData.backgroundColors];

    const chartData = {
        labels: labels,
        datasets: [{
            data: data,
            backgroundColor: backgroundColors,
            hoverOffset: 4,
        }],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false, // Updated
        plugins: {
            legend: {
                display: true,
                onClick: null,
            },
            title: { display: true, text: 'Materias and Micronutrientes' },
        },
    };

    // Set the width and height of the container div
    return (
        <div style={{
            width: '400px',
            height: '300px',
        }}>
            <Doughnut data={chartData} options={options} />
        </div>
    );
};

export default MateriasDoughnutGrafico;
