// src/autocompleteOptions.js

export const niveisInvestimentoOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
  ];
  
  export const segmentosOptions = [
    {
      name: 'Inseticidas',
      funcoesPrincipais: ['Mastigadores', 'Sugadores', 'Outros'],
    },
    {
      name: 'Herbicidas',
      funcoesPrincipais: [
        'Dessecante',
        'Pré-emergente',
        'Latifolicida',
        'Graminicida',
        'Amplo Espectro',
      ],
    },
    {
      name: 'Fungicidas',
      funcoesPrincipais: [
        'DFC',
        'Ferrugem',
        'Mancha Alvo',
        'Contato',
        'Fisiológico',
        'Multissítio',
      ],
    },
    {
      name: 'Adjuvantes',
      funcoesPrincipais: [
        'Óleo Vegetal',
        'Óleo Mineral',
        'Regulador de pH',
        'Estabilizador de calda',
        'Espalhante Adesivo não iônico',
      ],
    },
    {
      name: 'Inoculantes',
      funcoesPrincipais: [],
    },
    {
      name: 'Enraizadores',
      funcoesPrincipais: [],
    },
  ];
  