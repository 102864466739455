import React, { useState, useEffect, useRef } from 'react';
import {
  makeStyles, Drawer, List, ListItem, ListItemText,
  Icon, Divider, Menu, MenuItem, Popover, Typography, Tooltip, Box, Badge, Button
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { getUserInfo } from '../../utils/utils'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications'
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import api_amr from 'services/api_amr';
import { getToken } from '../../utils/utils'
import Zoom from '@material-ui/core/Zoom';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles(styles);

function activeRoute(routeName) {
  return window.location.href.indexOf(routeName) > -1;
}

export default function Sidebar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const [iconInverted, setIconInverted] = useState(false);
  const [isHoverExpanded, setIsHoverExpanded] = useState(false);
  const [isButtonExpanded, setIsButtonExpanded] = useState(false);
  const [manuallyExpanded, setManuallyExpanded] = useState(false);
  const [notifications, setNotifications] = useState([])
  const [notificationPopoverOpen, setNotificationPopoverOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(null);
  const id = notificationPopoverOpen ? 'Notificações Popup' : undefined;
  const notificationElement = useRef(null)
  let hoverTimer;
  const isExpanded = isHoverExpanded || isButtonExpanded;
  const history = useHistory();
  useEffect(() => {
    // Fechar o menu ancorado quando a barra lateral for expandida/recolhida
    setAnchorEl(null);
    retornaNotifications();
  }, []);

  const handleNotificationIconClick = (event) => {
    setNotificationPopoverOpen(!notificationPopoverOpen);
    // Aqui usamos o currentTarget do evento como anchorEl
    setAnchorElNotification(event.currentTarget);
  };

  const handleNotificationPopoverClose = () => {
    setNotificationPopoverOpen(false);
  };

  const handleTooltipOpen = (index) => {
    setOpenTooltip(index);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(null);
  };


  const handleMouseEnter = () => {
    if (manuallyExpanded) return;
    hoverTimer = setTimeout(() => {
      setIsHoverExpanded(true);
    }, 400);
    setAnchorEl(null);
  };

  const handleMouseLeave = () => {
    if (manuallyExpanded) return;
    clearTimeout(hoverTimer);
    setIsHoverExpanded(false);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    if (anchorEl) { // If it's already open, close it.
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  // utilizado no menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseProfile = () => {
    localStorage.clear();
    window.location.replace(window.location.href.includes('http://localhost:3000/') ? 'http://localhost:3000/' : 'https://plataformaamrbi.com/');
  };

  const toggleSidebarAndIcon = () => {
    const newIsButtonExpanded = !isButtonExpanded;
    setIconInverted(!iconInverted);
    setIsButtonExpanded(newIsButtonExpanded);
    setManuallyExpanded(newIsButtonExpanded);
    setNotificationPopoverOpen(false)

    setAnchorEl(null);



    props.onSidebarToggle && props.onSidebarToggle(newIsButtonExpanded);
  };
  let userInfo = ''
  try {
    userInfo = JSON.parse(getUserInfo());
    // You can proceed with your logic using userInfo here
  } catch (e) {
    // If an exception is caught, redirect to plataformaamrbi.com
    alert('sessão expirada')
    window.location.replace("http://plataformaamrbi.com");
  }
  const userName = (userInfo && userInfo.name && userInfo.name.split(' ')[0]) || "";
  const userEmail = (userInfo && userInfo.email) || "";

  const { routes, logo, logoMin, color } = props;

  const marcarComoLido = async (id_notificacao) => {
    try {
      handleTooltipClose();
      var response = await api_amr.post('/atualiza_status_notificacao', { id_notificacao }, { headers: { 'x-api-key': getToken() } });
      if (response.status === 200) {
        await retornaNotifications();
      }
    } catch (error) {
      if (error.response) {
        alert(`Erro no servidor, ${error.response.status}: ${error.response.data.erro}`);
      } else if (error.request) {
        alert("Erro de rede ou servidor não respondeu");
      } else {
        alert("Erro ao configurar a requisição");
      }
    }
  };
  const visualizarTudo = async () => {
    try {

      var response = await api_amr.get('/visualizar_tudo_notificacoes_usuario', { headers: { 'x-api-key': getToken() } });
      if (response.status === 200) {
        alert('Notificações marcadas como visualizadas')
        await retornaNotifications();
      }
    } catch (error) {
      if (error.response) {
        alert(`Erro no servidor, ${error.response.status}: ${error.response.data.erro}`);
      } else if (error.request) {
        alert("Erro de rede ou servidor não respondeu");
      } else {
        alert("Erro ao configurar a requisição");
      }
    }
  };

  const retornaNotifications = async () => {
    try {
      var response = await api_amr.get('/retornar_notificacoes_usuario', { headers: { 'x-api-key': getToken() } });
      console.log('resp', response.data)
      setNotifications(response.data.notificacoes);

    } catch (error) {
      if (error.response) {
        alert(`Erro no servidor, ${error.response.status}: ${error.response.data.erro}`);
      } else if (error.request) {
        alert("Erro de rede ou servidor não respondeu");
      } else {
        alert("Erro ao configurar a requisição");
      }
    }
  };

  const HandleClickNotification = (notification) => {

    const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}|[0-9a-f]{32}/g;

    if (notification.categoria_id === 2 && notification.lida === 0) {
      const uuid = notification.link.match(uuidRegex);
      marcarComoLido(notification.id)
      setNotificationPopoverOpen(false)
      history.push({ pathname: `Noticia/${uuid}` });
    }
    else if (notification.categoria_id === 2 && notification.lida === 1) {
      const uuid = notification.link.match(uuidRegex);

      setNotificationPopoverOpen(false)
      history.push({ pathname: `Noticia/${uuid}` });
    }
    else if (notification.categoria_id !== 2 && notification.lida === 0) {
      marcarComoLido(notification.id)
    }
  }

  const OverflowTooltip = ({ text, lida }) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
      if (textRef.current) {
        setIsOverflowed(textRef.current.scrollWidth > textRef.current.clientWidth);
      }
    }, [text]);

    return (
      <Tooltip title={text} disableHoverListener={!isOverflowed} TransitionComponent={Zoom}>
        <Typography
          variant="caption"
          ref={textRef}
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: lida === 1 ? 'darkgray' : null }}
        >
          {text}
        </Typography>
      </Tooltip>
    )
  }

  return (
    <div>
      <Drawer style={{ position: 'absolute', }}
        anchor={"left"}
        variant="permanent"
        open
        className={isExpanded ? classes.drawerPaper : classes.drawerPaperCollapsed}
        classes={{ paper: isExpanded ? classes.drawerPaper : classes.drawerPaperCollapsed }} // Use this to override default MUI styles
      >
        <div className={classes.logo} >
          {isExpanded ? (
            <div>
              <a
                key="expanded"
                href=""
                className={classNames(classes.logoLink, {
                  [classes.logoLinkRTL]: props.rtlActive
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={classes.logoImage}>
                  <img src={logo} alt="logo" className={classes.img} />
                </div>
              </a>
            </div>
          ) : (
            <div style={{ height: '35px' }}>
              <div className={classes.logoImage} >
                <img src={logoMin} alt="logo" className={classes.imgMin} />
              </div>
            </div>
          )}
        </div>

        <List style={{ display: 'flex', flexDirection: 'row', padding: 0, marginBottom: '5px' }}>
          <ListItem button onClick={handleClick} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1, maxWidth: (isExpanded ? '70%' : '100%') }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isExpanded ?
                <AccountCircleIcon className={classes.itemIconUser} />
                :
                <Badge
                  badgeContent={notifications.filter(n => n.lida === 0).length}
                  color="secondary"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  style={{
                    top: 0, // Ajuste esses valores conforme necessário para mover verticalmente
                    right: 0, // Ajuste esses valores conforme necessário para mover horizontalmente
                  }}
                >
                  <AccountCircleIcon className={classes.itemIconUser} />
                </Badge>
              }
              <ListItemText
                primary={`Olá, ${userName}`}
                className={classNames(classes.itemText, !isExpanded && classes.textHidden)}
              />
            </div>
          </ListItem>
          {isExpanded && (
            <ListItem button onClick={handleNotificationIconClick} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1, maxWidth: '30%' }}>
              <div ref={notificationElement}>
                <Badge
                  badgeContent={notifications.filter(n => n.lida === 0).length}
                  color="secondary"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  style={{
                    top: '12%', // Ajuste esses valores conforme necessário para mover verticalmente
                    right: '12%', // Ajuste esses valores conforme necessário para mover horizontalmente
                  }}
                >
                  <NotificationsIcon className={classes.itemIconUser} />
                </Badge>
              </div>
            </ListItem>)}
          {/* Menu para logar e acessar a pagina de usuario */}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            style={{ position: 'absolute', zIndex: 99999 }}
          >
            <MenuItem disabled>{userEmail}</MenuItem>
            <NavLink to="/PerfilUsuario" onClick={handleClose} className={classes.customNavLink}>
              <MenuItem>Perfil Usuário</MenuItem>
            </NavLink>
            <Divider light />
            <MenuItem onClick={handleCloseProfile} className={classes.dropdownItem}>
              Deslogar
            </MenuItem>
          </Menu>
          {/* Lista das notificações */}
          <Popover
            id={id}
            open={notificationPopoverOpen && isExpanded}
            anchorEl={anchorElNotification}
            onClose={handleNotificationPopoverClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Button
              onClick={() => visualizarTudo()}
              style={{
                width: '100%',
                borderTop: '1px solid #e0e0e0',
                borderRadius: 0,
                textAlign: 'center',
                padding: '10px',
                boxSizing: 'border-box'
              }}
            >
              Visualizar tudo
            </Button>
            <List style={{ width: 360, bgcolor: 'background.paper' }}>
              {notifications.length === 0 ? (
                <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography style={{ color: 'text.secondary' }}>
                    Não existem notificações, tudo limpo!
                  </Typography>
                </ListItem>
              ) : (
                <>
                  {notifications.map((notification, index) => (
                    <React.Fragment key={index}>
                      <Tooltip
                        title="Clique para marcar como lido"
                        placement="right"
                        disableHoverListener={Boolean(notification.lida)}
                        open={openTooltip === index}
                        onClose={handleTooltipClose}
                        onOpen={() => handleTooltipOpen(index)}
                      >
                        <ListItem
                          onClick={() => HandleClickNotification(notification)}
                          style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'start' }}
                        >
                          <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <Box style={{ alignItems: 'center', display: 'flex', width: '75%', textAlign: 'left' }}>
                              <OverflowTooltip text={notification.titulo} lida={notification.lida} />
                            </Box>
                            <Box style={{ alignItems: 'center', display: 'flex', width: '20%' }}>
                              <Typography variant="caption" style={{ color: 'text.secondary' }}>
                                {new Date(notification.criado_em).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                              </Typography>
                            </Box>
                          </Box>

                          <Box style={{ textAlign: 'left' }}>
                            <Typography
                              variant="subtitle1"
                              style={{
                                color: notification.lida === 1 ? 'darkgray' : null,
                                maxWidth: notification.titulo === 'Nova publicação' ? '100%' : 'none',
                                whiteSpace: notification.titulo === 'Nova publicação' ? 'nowrap' : 'normal',
                                overflow: notification.titulo === 'Nova publicação' ? 'hidden' : 'visible',
                                textOverflow: notification.titulo === 'Nova publicação' ? 'ellipsis' : 'clip'
                              }}
                            >
                              {notification.mensagem}
                            </Typography>
                          </Box>
                        </ListItem>
                      </Tooltip>

                      {index < notifications.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </>
              )}
            </List>

          </Popover>

          <Divider variant="middle" light />
        </List>

        <div className={classes.navbar} style={{ overflow: 'auto', }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <List className={classes.list}>
            {routes.map((prop, key) => {
              if (prop.name) {
                return (
                  <NavLink
                    to={prop.layout + prop.path}
                    className={classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <ListItem button className={classes.itemLink + (activeRoute(prop.layout + prop.path) ? ` ${classes[color]}` : "")}>
                      {typeof prop.icon === "string" ? (
                        <Icon className={classNames(classes.itemIcon, classes[color])}>
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon className={classNames(classes.itemIcon, classes[color])} />
                      )}
                      <ListItemText
                        primary={prop.name}
                        secondary={null}
                        className={classNames(classes.itemText, classes[color], !isExpanded && classes.textHidden)}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              }
              return null;
            })}
          </List>
        </div>
      </Drawer>
      <button
        className={`${classes.toggleButton} ${isExpanded ? classes.toggleButtonExpanded : ''}`}
        onClick={toggleSidebarAndIcon}
      >
        <ExpandMoreIcon className={iconInverted ? classes.iconInverted : classes.iconNormal} />
      </button>
    </div>
  );
}
