import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button";
import cpStyle from "./cpStyle.js";
import { FormControlLabel, TextField } from "@material-ui/core";
import api from "services/api_amr";
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import SubdirectoryArrowLeftRoundedIcon from '@material-ui/icons/SubdirectoryArrowLeftRounded';
import { Box } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { getToken, checarPermissao } from '../../../utils/utils'
import Checkbox from "@material-ui/core/Checkbox";
import Loader from 'react-loader-spinner';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation, useHistory } from 'react-router-dom';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';

export default function CriarPost(props) {
    const classes = cpStyle()

    const [postStructure, setPostStructure] = useState([{ value: '', type: 'main_title' }])
    const [existingTagsPrimarias, setExistingTagsPrimarias] = useState('')
    const [saving, setSaving] = useState(false)
    const [abrirNegocio, setAbrirNegocio] = useState(false)

    const [loginRequired, setLoginRequired] = useState(true)
    const [verified, setVerified] = useState(true)

    const [tagsPrimarias, setTagsPrimarias] = useState([
        { "tag_id": 5, "tag_name": "Nitrogênio", "tag_image_link": "" },
        { "tag_id": 6, "tag_name": "Fósforo", "tag_image_link": "" },
        { "tag_id": 7, "tag_name": "Potássio", "tag_image_link": "" },
        { "tag_id": 18, "tag_name": "Destaques da Semana", "tag_image_link": "" },
        { "tag_id": 11, "tag_name": "Mapeamento de Preços", "tag_image_link": "" },
        { "tag_id": 10, "tag_name": "Andamento de Mercado", "tag_image_link": "" },
        { "tag_id": 19, "tag_name": "Enxofre, Ácidos e Rocha", "tag_image_link": "" },
        { "tag_id": 20, "tag_name": "Gás Natural e Amônia", "tag_image_link": "" },
        { "tag_id": 14, "tag_name": "Micronutriente", "tag_image_link": "" },
        { "tag_id": 8, "tag_name": "Indústria e Mineração", "tag_image_link": "" },
        { "tag_id": 21, "tag_name": "Logística e Importação", "tag_image_link": "" },
        { "tag_id": 16, "tag_name": "Informativos Técnicos de Fertilizantes", "tag_image_link": "" },
        { "tag_id": 17, "tag_name": "Legislação de Fertilizantes", "tag_image_link": "" },
        { "tag_id": 1, "tag_name": "Geopolitica", "tag_image_link": "" },
        { "tag_id": 2, "tag_name": "Macroeconomia", "tag_image_link": "" },
        { "tag_id": 3, "tag_name": "Agricultura Brasil", "tag_image_link": "" },
        { "tag_id": 4, "tag_name": "Agricultura Mundo", "tag_image_link": "" },
        { "tag_id": 22, "tag_name": "Defensivos", "tag_image_link": "" }

    ])
    const [tagPrimaria, setTagPrimaria] = useState('')
    const [permitido, setPermitido] = useState(false)
    const [generating, setGenerating] = useState(false)

    const history = useHistory();

    const location = useLocation();
    const [postIdEdit, setPostIdEdit] = useState()
    const [isEditing, setIsEditing] = useState()

    const [sentiment, setSentiment] = useState(0)
    const [tipos, setTipos] = useState([])
    const [selectedTipo, setSelectedTipo] = useState([])
    const [selectedTipoEdit, setSelectedTipoEdit] = useState([])
    const [materias, setMaterias] = useState([])
    const [selectedmaterias, setSelectedMaterias] = useState([])
    const [selectedmateriasEdit, setSelectedMateriasEdit] = useState([])
    const [culturas, setCulturas] = useState([])
    const [selectedCulturas, setSelectedCulturas] = useState([])
    const [selectedCulturasEdit, setSelectedCulturasEdit] = useState([])

    // console.log(location)
    useEffect(() => {
        if (location.state) {
            if (location.state.isAi) {
                setGenerating(true)
                handleAiPost()
            } else if (location.state.isEditing) {
                
                setPostIdEdit(location.state.post.post_id)
                setIsEditing(location.state.isEditing)
                setPostStructure(location.state.post.components)
                setVerified(parseInt(location.state.post.verified))
                setSelectedMateriasEdit(location.state.post.vinculo_materia)
                setSelectedTipoEdit(location.state.post.vinculo_tipo)
                setSelectedCulturasEdit(location.state.post.vinculo_cultura)
                setExistingTagsPrimarias({ 'tag_id': location.state.post.tag_id, 'tag_name': location.state.post.tag_name })
                setSentiment(location.state.post.sentiment)
            }
        }
        fetchMyApi()
    }, [])

    const fetchMyApi = async () => {
        setPermitido(await checarPermissao('gerenciar_posts', props))
      
        fetch_materias_e_tipos()
        fetch_culturas()
    }
    const fetch_materias_e_tipos = async () => {

        const response_materias = await api.get('/get_tipo_materias', { headers: { 'x-api-key': getToken() } })
        if (response_materias.data !== 'NOK') {
            setTipos(response_materias.data.tipos)
            setMaterias(response_materias.data.materias)

        }

    }

    const fetch_culturas = async () => {
         
        const response_culturas = await api.get('/return_culturas', { headers: { 'x-api-key': getToken() } })
        if (response_culturas.data !== 'NOK'){
            setCulturas(response_culturas.data)
        }
       
    }

    const fetchTags = async () => {
        const responseTags_pri = await api.get('/return_all_tags')
        setTagsPrimarias(responseTags_pri.data)

    }

    const handleAiPost = async () => {
        const response_gpt = await api.get('/chat_gpt_sumrz_id', { params: { 'id': location.state.id }, headers: { 'x-api-key': getToken() } })

        if (response_gpt.data === 'NOK_Excess') {
            alert('Número de tokens excedidos')
        }

        setLoginRequired(1)
        setVerified(1)

        setPostStructure([
            {
                "filename": "",

                "type": "main_title",
                "value": response_gpt.data.titulo
            },
            {
                "filename": "",

                "type": "paragraph",
                "value": response_gpt.data.noticia
            },
            {
                "filename": "",

                "type": "paragraph",
                "value": "Sentimento AMR de impacto no mercado: " + response_gpt.data.sent
            }
        ])
        setGenerating(false)
    }

    const addComponent = (type) => {
        let aux = [...postStructure]

        if (type === 'paragraph') {
            aux.push({ value: '', type: 'paragraph' })
        } else if (type === 'title') {
            aux.push({ value: '', type: 'title' })
        }
        else if (type === 'chart') {
            aux.push({ value: '', type: 'chart' })
        }
        setPostStructure(aux)
    }

    const changeValue = (index, value) => {
        let aux = [...postStructure]
        aux[index].value = value
        setPostStructure(aux)
    }

    const deleteComponent = (index) => {
        let aux = [...postStructure]
        aux.splice(index, 1)
        setPostStructure(aux)
    }

    const handleVoltar = () => {
        // Navigate back to the previous page
        window.history.back();
    };

    const moveComponent = (direction, index) => {
        let aux = [...postStructure]
        if (direction === 'up') {
            if (index - 1 === -1) { return }

            let prior = aux[index - 1]
            aux[index - 1] = aux[index]
            aux[index] = prior

            aux[index - 1].index = index - 1
            prior.index = index
            setPostStructure(aux)
        } else if (direction === 'down') {
            if (index + 1 === postStructure.length) { return }

            let prior = aux[index + 1]
            aux[index + 1] = aux[index]
            aux[index] = prior

            aux[index + 1].index = index + 1
            prior.index = index
            setPostStructure(aux)
        }
    }

    const addImages = (files) => {
        let aux = [...postStructure]
        Object.keys(files).map(key => {
            aux.push({ value: files[key], filename: files[key].name, type: 'image' })
        })
        setPostStructure(aux)
    }
    const addImage_capa = (files) => {
        let aux = [...postStructure]
        Object.keys(files).map(key => {
            aux.push({ value: files[key], filename: files[key].name, type: 'cover_image' })
        })
        setPostStructure(aux)
    }

    const addAudios = (files) => {
        let aux = [...postStructure]
        Object.keys(files).map(key => {
            aux.push({ value: files[key], filename: files[key].name, type: 'audio' })
        })
        setPostStructure(aux)
    }

    const addVideos = (files) => {
        let aux = [...postStructure]
        Object.keys(files).map(key => {
            aux.push({ value: files[key], filename: files[key].name, type: 'video' })
        })
        setPostStructure(aux)
    }

    const addFiles = (files) => {
        let aux = [...postStructure]
        Object.keys(files).map(key => {
            aux.push({ value: files[key], filename: files[key].name, type: 'file' })
        })
        setPostStructure(aux)
    }

    const savePost = async () => {
        setSaving(true)
        let filesForm = new FormData()
        let auxPostStructure = []
        if (postStructure !== '') {
            postStructure.map((component) => {
                if (component.type === 'image' || component.type === 'audio' || component.type === 'video' || component.type === 'file' || component.type === 'cover_image') {
                    let newFile = new File([component.value], component.filename, { type: component.value.type })
                    filesForm.append('files', newFile)
                }
            })
            auxPostStructure = [...postStructure]
        } else {
            auxPostStructure = []
        }

        try {
            if (isEditing) { // a post_id do post sendo editado vem da página anterior

                if (existingTagsPrimarias === '' && tagPrimaria === '') {
                    alert('Favor inserir uma Tag a noticia')
                    setSaving(false)
                    return
                }
                // const hasCoverImage = auxPostStructure.some(item => item.type === 'cover_image')
                // if (!hasCoverImage) {
                //     alert('Favor inserir uma imagem de capa a noticia')
                //     setSaving(false)
                //     return
                // }
                const hasTitle = auxPostStructure.some(item => item.type === 'main_title')
                if (!hasTitle) {
                    alert('Favor inserir uma Titulo para a notícia')
                    setSaving(false)
                    return
                }
                const hasParagraph = auxPostStructure.some(item => item.type === 'paragraph')
                if (!hasParagraph) {
                    alert('Favor inserir uma Paragrafo a notícia')
                    setSaving(false)
                    return
                }

                try {
                    const savePostResponse = await api.post('/save_post_edit', {
                        'postStructure': auxPostStructure,
                        'post_id_edit': postIdEdit,
                        loginRequired,
                        verified,
                        'tag_id': existingTagsPrimarias.tag_id,
                        'sentiment': sentiment,
                        'materia_tipo': selectedTipoEdit,
                        'materias': selectedmateriasEdit,
                        'culturas' : selectedCulturasEdit,
                    }, { headers: { 'x-api-key': getToken() } });

                    if (savePostResponse.status !== 200) {
                        alert(`Erro ao salvar o post. Cod.: ${savePostResponse.status}`)
                        console.error('Error saving post:', savePostResponse.statusText);
                    } else {
                        filesForm.append('post_id', postIdEdit);
                        const saveFilesResponse = await api.post('/save_post_files', filesForm, { headers: { 'x-api-key': getToken() } });

                        if (saveFilesResponse.status !== 200) {
                            alert(`Erro ao salvar arquivos. Cod.: ${savePostResponse.status}`)
                            console.error('Error saving files:', saveFilesResponse.statusText);
                        } else {
                            console.log('Post and files saved successfully');
                        }
                    }
                } catch (error) {
                    console.error('Error:', error.message);
                }

            } else { // a post_id de um novo post vem da resposta da chamada save_post


                if (tagPrimaria === '') {
                    alert('Favor inserir uma Tag a noticia')
                    setSaving(false)
                    return
                }

                // const hasCoverImage = postStructure.some(item => item.type === 'cover_image')

                // if (!hasCoverImage) {
                //     alert('Favor inserir uma imagem de capa a noticia')
                //     setSaving(false)
                //     return
                // }
                const hasTitle = postStructure.some(item => item.type === 'main_title')
                if (!hasTitle) {
                    alert('Favor inserir uma Titulo para a notícia')
                    setSaving(false)
                    return
                }
                const hasParagraph = postStructure.some(item => item.type === 'paragraph')
                if (!hasParagraph) {
                    alert('Favor inserir uma Paragrafo a notícia')
                    setSaving(false)
                    return
                }



                const savePostResponse = await api.post('/save_post_new', {
                    'postStructure': auxPostStructure,
                    loginRequired,
                    verified,
                    'tag_id': tagPrimaria.tag_id,
                    'sentiment': sentiment,
                    'materia_tipo': selectedTipo,
                    'materias': selectedmaterias,
                    'culturas': selectedCulturas,
                }, { headers: { 'x-api-key': getToken() } })
                let post_id = savePostResponse.data
                filesForm.append('post_id', post_id)
                const saveFilesResponse = await api.post('/save_post_files', filesForm, { headers: { 'x-api-key': getToken() } })
            }
            history.goBack()
        } catch {
            alert('Erro ao salvar publicação...')
            setSaving(false)
        }
    }

    const renderComponent = (item, index) => {
        switch (item.type) {
            case 'paragraph':
                return (
                    <div className={classes.textField}>
                        <TextField
                            label='Parágrafo'
                            multiline
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    input: classes.text
                                }
                            }}
                            value={postStructure[index].value}
                            variant="outlined" onChange={(e) => changeValue(index, e.target.value)} />
                    </div>
                )
            case 'title':
                return (
                    <div className={classes.textField}>
                        <TextField
                            label='Título'
                            multiline
                            className={classes.titleField}
                            InputProps={{
                                classes: {
                                    input: classes.title
                                }
                            }}
                            value={postStructure[index].value}
                            variant="outlined" onChange={(e) => changeValue(index, e.target.value)} />
                    </div>
                )
            case 'image':
                return (
                    <div style={{ border: '1px solid black', width: '100%', marginTop: 10 }}>
                        <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={handleLink(item)} />
                    </div>
                )
            case 'cover_image':
                return (
                    <div style={{ border: '1px solid black', width: '100%', marginTop: 10 }}>
                        <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={handleLink(item)} />
                    </div>
                )
            case 'audio':
                return (
                    <div style={{ border: '1px solid black', width: '100%', marginTop: 10 }}>
                        <audio controls>
                            <source src={handleLink(item)} type="audio/ogg" />
                        </audio>
                    </div>
                )
            case 'video':
                return (
                    <div style={{ border: '1px solid black', width: '100%', marginTop: 10 }}>
                        <video width="800" height="600" controls>
                            <source src={handleLink(item)} type="video/mp4" />
                        </video>
                    </div>
                )
            case 'file':
                return (
                    <div className={classes.fileStyle}>
                        <a href={handleLink(item)} download>
                            {item.filename}
                        </a>

                    </div>
                )
            case 'main_title':
                return (
                    <div className={classes.textField}>
                        <TextField
                            label='Título principal'
                            multiline
                            className={classes.titleField}
                            InputProps={{
                                classes: {
                                    input: classes.title
                                }
                            }}
                            value={postStructure[index].value}
                            variant="outlined" onChange={(e) => changeValue(index, e.target.value)} />


                    </div>
                )
            default:
                return <></>
        }
    }

    const handleLink = (component) => {
        if ('link' in component) {
            return component.link
        } else {
            return URL.createObjectURL(component.value)
        }
    }

    const handleCheckbox = (value, operation) => {
        switch (operation) {
            case 'login':
                setLoginRequired(value)
                break

            case 'verified':
                setVerified(value)
                break
        }
    }

    const handleClickClose = () => {
        setAbrirNegocio(false);
    };
    return (
        permitido ? (
            <div className={classes.container} style={{ width: "90vw", marginTop: '50px', display: 'flex', flexDirection: 'row' }}>
                {/* BEGIN Sidebar components*/}
                <div className={classes.sideBar}>
                    {/* BEGIN componentes post */}
                    <div className={classes.buttonContainer}>
                        <Box display="flex" flexDirection="column" >
                            <Box display="flex" flexDirection="row" >
                                <Button
                                    variant="contained"
                                    onClick={() => addComponent('title')}
                                    className={classes.calcButton}>
                                    Título
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => addComponent('paragraph')}
                                    className={classes.calcButton}>
                                    Parágrafo
                                </Button>
                            </Box>
                            <Box display="flex" flexDirection="row" >
                                <Button
                                    className={classes.calcButton}
                                    variant="contained"
                                    component="label">
                                    Imagem
                                    <input
                                        multiple
                                        accept="image/png, image/gif, image/jpeg, image/jpg"
                                        type="file" id="myfileinput"
                                        hidden
                                        onChange={(e) => { addImages(e.target.files) }}
                                    />
                                </Button>

                                <Button
                                    className={classes.calcButton}
                                    variant="contained"
                                    component="label">
                                    Imagem de Capa
                                    <input
                                        multiple
                                        accept="image/png, image/gif, image/jpeg, image/jpg"
                                        type="file" id="myfileinput"
                                        hidden
                                        onChange={(e) => { addImage_capa(e.target.files) }}
                                    />
                                </Button>
                            </Box>
                            <Box display="flex" flexDirection="row" >
                                <Button
                                    className={classes.calcButton}
                                    variant="contained"
                                    component="label">
                                    Audio
                                    <input
                                        multiple
                                        accept="audio/mpeg, audio/ogg, audio/wav, audio/m4a,audio/mp4a-latm"
                                        type="file" id="myfileinput"
                                        hidden
                                        onChange={(e) => { addAudios(e.target.files) }}
                                    />
                                </Button>

                                <Button
                                    className={classes.calcButton}
                                    variant="contained"
                                    component="label">
                                    Vídeo
                                    <input
                                        multiple
                                        accept="video/mp4, video/webm, video/ogg"
                                        type="file" id="myfileinput"
                                        hidden
                                        onChange={(e) => { addVideos(e.target.files) }}
                                    />
                                </Button>
                            </Box>
                            <Button
                                className={classes.calcButton}
                                variant="contained"
                                component="label">
                                Arquivo
                                <input
                                    multiple
                                    accept="application/pdf"
                                    type="file" id="myfileinput"
                                    hidden
                                    onChange={(e) => { addFiles(e.target.files) }}
                                />
                            </Button>



                        </Box>
                    </div>
                    {/* END componentes post */}
                    {/* BEGIN Sentimento AMR */}
                    <div className={classes.SentimentContainer}>

                        <div>
                            <p className={classes.SentimentText}>Sentimento AMR</p>
                        </div>

                        <div>
                            <Button onClick={() => { setSentiment(-1) }}>
                                {sentiment === -1 ?
                                    <TrendingDownIcon className={classes.SentimentIcon} style={{ color: 'red', fontSize: 35 }} />
                                    :
                                    <TrendingDownIcon className={classes.SentimentIcon} />
                                }

                            </Button>
                            <Button onClick={() => { setSentiment(0) }}>
                                {sentiment === 0 ?
                                    <RemoveCircleOutlineRoundedIcon className={classes.SentimentIcon} style={{ color: '#C9C422' }} />
                                    :
                                    <RemoveCircleOutlineRoundedIcon className={classes.SentimentIcon} style={{ color: 'grey' }} />
                                }
                            </Button>
                            <Button onClick={() => { setSentiment(1) }}>
                                {sentiment === 1 ?
                                    <TrendingUpIcon className={classes.SentimentIcon} style={{ color: 'green', fontSize: 35 }} />
                                    :
                                    <TrendingUpIcon className={classes.SentimentIcon} />
                                }

                            </Button>
                        </div>




                    </div>
                    {/* END Sentimento AMR */}
                    {/* BEGIN Tags e vinculos post */}
                    <div className={classes.AutocompleteContainer}>
                        {/* Autocomplete da TAG */}
                        <Autocomplete
                            options={tagsPrimarias}
                            getOptionLabel={(option) => `${option.tag_id.toString()} - ${option.tag_name}`}
                            // onChange={(e, v) => (setTagPrimaria(v), setExistingTagsPrimarias(''))}
                            onChange={(e, v) => (isEditing ? setExistingTagsPrimarias(v) : setTagPrimaria(v))}
                            value={isEditing && existingTagsPrimarias ? existingTagsPrimarias : undefined}
                            // value={
                            //     isEditing
                            //         ? existingTagsPrimarias
                            //         : null
                            // }
                            className={classes.autoCompleteFieldBig}
                            classes={{
                                input: classes.input,
                                notchedOutline: classes.noBorder,
                                paper: classes.paper,
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Tag"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder },
                                    }}
                                />
                            )}
                        />


                        {/* Autocomplete do Lista Tipo de Matéria */}
                        <Autocomplete
                            multiple
                            options={tipos}
                            getOptionLabel={(option) => option.tipo_id + ' - ' + option.tipo}
                            onChange={(e, v) => (isEditing ? setSelectedTipoEdit(v) : setSelectedTipo(v))}
                            value={isEditing && selectedTipoEdit ? selectedTipoEdit : undefined}
                            className={classes.autoCompleteFieldBig}
                            classes={{
                                input: classes.input,
                                notchedOutline: classes.noBorder,
                                paper: classes.paper,
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Tipo de matéria"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder },
                                    }}
                                />
                            )}
                        />


                        {/* Autocomplete da lista Matéria vinculada */}
                        <Autocomplete
                            multiple
                            options={materias}
                            getOptionLabel={(option) => option.id_materias + ' - ' + option.abreviacao}
                            onChange={(e, v) => (isEditing ? setSelectedMateriasEdit(v) : setSelectedMaterias(v))}
                            value={isEditing && selectedmateriasEdit ? selectedmateriasEdit : undefined}
                            className={classes.autoCompleteFieldBig}

                            classes={{
                                input: classes.input,
                                notchedOutline: classes.noBorder,
                                paper: classes.paper

                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                placeholder="Matéria"
                                variant='outlined'
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    classes: { notchedOutline: classes.noBorder }
                                }}

                            />}
                        />
                         {/* Autocomplete da lista culturas vinculada */}
                         <Autocomplete
                            multiple
                            options={culturas}
                            getOptionLabel={(option) => option.id + ' - ' + option.cultura}
                            onChange={(e, v) => (isEditing ? setSelectedCulturasEdit(v) : setSelectedCulturas(v))}
                            value={isEditing && selectedCulturasEdit ? selectedCulturasEdit : undefined}
                            className={classes.autoCompleteFieldBig}

                            classes={{
                                input: classes.input,
                                notchedOutline: classes.noBorder,
                                paper: classes.paper

                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                placeholder="Cultura"
                                variant='outlined'
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    classes: { notchedOutline: classes.noBorder }
                                }}

                            />}
                        />


                    </div>
                    {/* END Tags e vinculos post */}
                    {/* BEGIN checkboxs */}
                    <div className={classes.checkBoxContainer}>
                        <div style={{ minWidth: 300 }}>
                            <FormControlLabel checked={Boolean(loginRequired)} control={<Checkbox color="primary" onChange={(event, value) => handleCheckbox(value, 'login')} />} label='Login necessário' />
                        </div>

                        <div>
                            <FormControlLabel checked={Boolean(verified)} control={<Checkbox color="primary" onChange={(event, value) => handleCheckbox(value, 'verified')} />} label='Verificado' />
                        </div>
                    </div>
                    {/* END checkboxs */}
                    {/* BENGIN Botões de saida */}
                    <div className={classes.exitButtonsContainer}>
                        <div >
                            <div>
                                <Button disabled={saving} variant="contained" onClick={() => savePost()} className={classes.exitButton}>Salvar</Button>
                            </div>
                        </div>
                        <div >
                            <div>
                                <Button variant="contained" className={classes.exitButton} onClick={handleVoltar}>Voltar</Button>
                            </div>
                        </div>
                    </div>
                    {/* END Botões de saida */}



                </div>
                {/* END SideBar components*/}
                {/* BEGIN Corpo Post */}
                <div className={classes.mainDiv}>
                    <div className={classes.postDiv}>

                        {postStructure === '' ?
                            <div style={{ width: '100%', height: '100' }}>
                                <div style={{ width: 'fit-content' }}>
                                    <SubdirectoryArrowLeftRoundedIcon style={{ transform: 'rotate(90deg)' }} />
                                    &nbsp; Adicione algum componente
                                </div>
                            </div>
                            : null}

                        {postStructure === '' && isEditing ?
                            <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" /> : null}

                        {/* componentes do post */}

                        {generating ?
                            <div> Gerando notícia, aguarde... <CircularProgress style={{ 'color': '#A3CC51' }} /></div> :
                            postStructure.map((item, index) =>
                                <div key={index} className={classes.componentDiv}>

                                    {/* função que decide o que renderizar */}
                                    {renderComponent(item, index)}

                                    {/* deletar - mover */}
                                    {item.type !== "main_title" ?
                                        <div style={{ width: '30%' }}>

                                            <div style={{ display: 'flex', flexDirection: 'row', width: 'fit-content', marginLeft: 30 }}>
                                                <div onClick={() => deleteComponent(index)} className={classes.miniButtonRed}>
                                                    <DeleteForeverRoundedIcon style={{ marginTop: 4, fontSize: 30, color: 'white' }} />

                                                </div>

                                                <div onClick={() => moveComponent('up', index)} className={classes.miniButton}>
                                                    <ArrowUpwardRoundedIcon style={{ marginTop: 4, fontSize: 30, color: 'white' }} />
                                                </div>

                                                <div onClick={() => moveComponent('down', index)} className={classes.miniButton}>
                                                    <ArrowDownwardRoundedIcon style={{ marginTop: 4, fontSize: 30, color: 'white' }} />

                                                </div>

                                            </div>

                                        </div>
                                        : <div style={{ width: '30%' }}></div>}
                                </div>)}

                    </div>
                </div>
                {/* END Corpo Post */}
            </div>
        ) : null
    )

}



