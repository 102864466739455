import React, { useRef, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import api_amr from 'services/api_amr';
import { getToken } from 'utils/utils';
import backLogo from '../../../assets/img/logo_arm_opaque.png'

export default function RelacaoTroca({ materia, idCultura, nomeCultura, date, porto, handlePosts, hoveredNews }) {
    const [inputData, setInputData] = useState([])
    const [previousYearData, setPreviousYearData] = useState([])

    useEffect(() => {
        const fetchMyApi = async () => {
            const responsePosts = await api_amr.get('/return_DashBoardRelacaoTroca', {
                params: { 'id_cultura': idCultura, 'data': date, 'id_porto': porto, 'materia': materia
             },
                headers: { 'x-api-key': getToken() }
            })

            setInputData(responsePosts.data.relacao_troca.current_year)
            setPreviousYearData(responsePosts.data.relacao_troca.previous_year)
            handlePosts(responsePosts.data)
        }

        fetchMyApi()
    }, [materia, idCultura, nomeCultura, date, porto])

    const data = {
        labels: previousYearData.map(item => item.data.replace('2023','2024')),
        datasets: [
            {
                label: '2024',
                data: inputData.map(item => item.rel_troca),
                fill: false,
                backgroundColor: '#1F4B80',
                borderColor: '#0066C0',
                pointRadius: inputData.map(item => (hoveredNews && item.data === hoveredNews.post_date) ? 12 : 3),
                pointBackgroundColor:  inputData.map(item => (hoveredNews && item.data === hoveredNews.post_date) ? 'red' : '#1F4B80')

            },

            {
                label: '2023',
                data: previousYearData.map(item => item.rel_troca),
                fill: false,
                borderColor: '#4CB1CB',
                backgroundColor: '#4CB1CB',
            },
        ],
    }

    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },

        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
            }
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', backgroundImage: `url(${backLogo})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
            <Line data={data} options={options} />
        </div>
    )
}
