import React, { useState, useEffect } from 'react';
import { TextField, Button, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from '../../services/api_amr'; // Import the api service
import { getToken } from '../../utils/utils'; // Adjust the import path as necessary
import ReactECharts from 'echarts-for-react'; // Import ECharts

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  formControl: {
    marginRight: theme.spacing(3),
    minWidth: 200,
  },
}));

const ChartsComparativoCulturas = () => {
  const classes = useStyles();
  const [anosSafra, setAnosSafra] = useState([]);
  const [culturas, setCulturas] = useState([]);
  const [formData, setFormData] = useState({
    anoSafra: null,
    culturas: [],
  });
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchAnosSafra = async () => {
      const response = await api.get('/read_anos_safra', { headers: { 'x-api-key': getToken() } });
      setAnosSafra(response.data);
    };
    fetchAnosSafra();
  }, []);

  const handleAnoSafraChange = async (event, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, anoSafra: value, culturas: [] }));
    if (value) {
      const response = await api.get('/read_culturas', { params: { ano_safra: value.id_ano_safra }, headers: { 'x-api-key': getToken() } });
      setCulturas(response.data);
    } else {
      setCulturas([]);
    }
  };

  const handleCulturasChange = (event, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, culturas: value }));
  };

  const loadChartData = async (anoSafra, culturas) => {
    try {
      const culturaIds = culturas.map(cultura => cultura.id_cultura).join(',');
      const response = await api.get('/read_percentual_acumulado_multiplas_culturas', {
        params: {
          ano_safra: anoSafra,
          culturas: culturaIds,
        },
        headers: { 'x-api-key': getToken() },
      });

      const chartData = response.data.reduce((acc, curr) => {
        const { ano, semana, id_cultura, percentual_acumulado } = curr;
        const key = `${ano}-Semana ${semana}`;
        if (!acc[key]) acc[key] = { [id_cultura]: percentual_acumulado };
        else acc[key][id_cultura] = percentual_acumulado;
        return acc;
      }, {});

      setChartData(chartData);
    } catch (error) {
      console.error('Error loading chart data:', error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    loadChartData(formData.anoSafra.id_ano_safra, formData.culturas);
  };

  const getChartOptions = () => {
    const series = formData.culturas.map(cultura => ({
      name: cultura.nome_cultura,
      data: Object.values(chartData).map(weekData => weekData[cultura.id_cultura] || 0),
      type: 'line',
      smooth: true,
      areaStyle: { opacity: 0.3 },
      symbolSize: 8,
    }));

    return {
      title: {
        text: 'Comparativo de Percentual Acumulado por Semana',
        subtext: `Ano Safra: ${formData.anoSafra?.descricao}`,
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: formData.culturas.map(cultura => cultura.nome_cultura),
        top: '10%',
      },
      xAxis: {
        type: 'category',
        data: Object.keys(chartData),
      },
      yAxis: {
        type: 'value',
        max: 100,
        axisLabel: {
          formatter: '{value}%',
        },
      },
      series: series,
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
    };
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <Autocomplete
            options={anosSafra}
            getOptionLabel={(option) => option.descricao}
            value={formData.anoSafra}
            onChange={handleAnoSafraChange}
            renderInput={(params) => <TextField {...params} label="Ano/Safra" variant="outlined" />}
            style={{ width: 200, marginRight: '10px' }}
          />
          <Autocomplete
            multiple
            options={culturas}
            getOptionLabel={(option) => option.nome_cultura}
            value={formData.culturas}
            onChange={handleCulturasChange}
            renderInput={(params) => <TextField {...params} label="Culturas" variant="outlined" />}
            style={{ width: 300, marginRight: '10px' }}
            disabled={!formData.anoSafra}
          />
          <Button type="submit" variant="contained" color="primary">Carregar dados</Button>
        </div>
      </form>
      <ReactECharts option={getChartOptions()} style={{ height: 400 }} />
    </div>
  );
};

export default ChartsComparativoCulturas;
