import React, { useEffect, useState } from "react";
import api_amr from "services/api_amr";
import { getToken } from "utils/utils";
import { Line, Bar, Scatter } from 'react-chartjs-2';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from "@material-ui/lab";
import { Grid } from '@material-ui/core';

const colors = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf', '#4CB1CB'];


export default function Futuros() {
    const [values, setValues] = useState([])
    const [date, setDate] = useState('2024-01-01')
    const [chartInfo, setChartInfo] = useState('')
    const [tableOption, setTableOption] = useState({ value: 'ureia_egy_futuro ', name: 'Ureia Egyto' })

    const tablesOptions = [
        { value: 'ureia_egy_futuro', name: 'Ureia Egyto' },
        { value: 'ureia_me_futuro', name: 'Ureia Mediterrâneo' },
        { value: 'dap_nola_futuro', name: 'DAP Nola' },
        { value: 'map_br_futuro', name: 'MAP Brasil' },
        { value: 'ureia_us_futuro', name: 'Ureia US' },
        { value: 'ureia_br_futuro', name: 'Ureia BR' }
    ]

    const updateChartInfo = (values) => {
        const datasets = [];
        console.log(values)
        for (let key in values['dict_meses']) {
            let value= values['dict_meses'][key];
            let dataset = {
                label: key, // Label of the line
                data: [],
                fill: false,
                backgroundColor: colors[datasets.length % colors.length], // Use color based on index
                borderColor: colors[datasets.length % colors.length], // Use color based on index
                pointRadius: 0,

            };
            for (let date in value) {
                dataset.data.push({x: date, y: value[date]});
            }
            datasets.push(dataset);
        }
        console.log(datasets)
        const data = {
            labels: values['labels_datas'], 
            datasets:datasets
        };

        const options = {
            responsive: true,
            interaction: {
                mode: 'index',
                intersect: false,
            },

            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                }
            }
        }
        setChartInfo({ 'data': data, 'options': options })
        console.log({ 'data': data, 'options': options })
    }

    useEffect(() => {
        const fetchApi = async () => {
            const response = await api_amr.get("/retorna_dashboard_futuros",
                {
                    params: { "data_frontEnd": date, "tabela_futuro": tableOption.value },
                    headers: { 'x-api-key': getToken() }
                })

            
            updateChartInfo(response.data)
        }

        fetchApi()
    }, [date, tableOption])

    return (
        <Grid container style={{ padding: 20 }}>
            <Grid item xs={12} >
               
                <Autocomplete
                    defaultValue={{ value: 'ureia_egy_futuro ', name: 'Ureia Egyto' }}
                    style={{ marginTop: 10, maxWidth: 300 }}
                    closeIcon={null}
                    size='small'
                    options={tablesOptions}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, v) => setTableOption(v)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Região"
                            variant="outlined"
                        />
                    )}
                />
            </Grid>
            {chartInfo !== '' ?
                <Grid item xs={12}>
                    <Line data={chartInfo.data} options={chartInfo.options} />
                </Grid>
                : null}

        </Grid>
    )
}
