import React, { useState, useEffect } from 'react';
import { Autocomplete } from "@material-ui/lab";
import TextField from '@material-ui/core/TextField';
import api_amr from 'services/api_amr';
import useStyles from '../styles/styles';
import Grid from '@material-ui/core/Grid';
import { getToken, checarPermissao } from '../../../utils/utils';
import Box from '@material-ui/core/Box';
import moment from 'moment';

const AutocompleteTopDashboard = ({ handleFirstAutocomplete, handleSecondAutocomplete, handleDatePick, handleCultura, tabIndex }) => {
    const [materiasOptions, setMateriasOptions] = useState([]);
    const [portosOptions, setPortosOptions] = useState([]);
    const [gruposOptions, setGruposOptions] = useState([]);
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(moment("2023-01-01 00:00:00").format('YYYY-MM-DD')); // Define selectedDate as 2022-01-01

    const [permitido, setPermitido] = useState(false)
    const [culturas, setCulturas] = useState([])


    useEffect(() => {
        const fetchMyApi = async () => {
            setPermitido(await checarPermissao('dashboard'))
            try {

                const responseCulturas = await api_amr.get('/return_culturas', { headers: { 'x-api-key': getToken() } });
                setCulturas(responseCulturas.data);

                const responsePortos = await api_amr.get('/return_portos', { headers: { 'x-api-key': getToken() } });
                setPortosOptions(responsePortos.data);

                const responseMaterias = await api_amr.get('/get_materias', { headers: { 'x-api-key': getToken() } });
                setMateriasOptions(responseMaterias.data);
            } catch (error) {
                console.log("Error fetching options:");
            }
        };
        fetchMyApi();
    }, []);

    const handleDateChange = (dateValue) => {
        setSelectedDate(dateValue)
        handleDatePick(dateValue)
    }

    return (
        permitido ?
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Box display="flex" justifyContent="space-between" >
                        <Autocomplete
                            closeIcon={null}
                            size='small'
                            options={materiasOptions}
                            getOptionLabel={(option) => option.abreviacao}
                            className={classes.autoCompleteFieldBig}
                            onChange={(e, v) => {
                                handleFirstAutocomplete(v);
                            }}
                            defaultValue={{ abreviacao: "UR" }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Selecionar Matéria"
                                    variant="outlined"
                                />
                            )}
                        />
                        <Autocomplete
                            closeIcon={null}
                            size='small'
                            options={portosOptions}
                            getOptionLabel={(option) => option.nome_porto}
                            className={classes.autoCompleteFieldBig}
                            onChange={(e, v) => {
                                if (v && v.id) {
                                    handleSecondAutocomplete(v);
                                } else {
                                    handleSecondAutocomplete({}); // Empty object
                                }
                            }}
                            defaultValue={{ nome_porto: "Paranguá/PR" }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Selecionar Porto"
                                    variant="outlined"
                                />
                            )}
                        />

                      
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Box display="flex" justifyContent="space-between" >


                        {tabIndex === 1 ?
                            <Autocomplete
                                closeIcon={null}
                               
                                options={culturas}
                                defaultValue={{
                                    "id":4,
                                    "cultura": "INDICADOR DA SOJA ESALQ/BM&FBOVESPA - PARANAGUÁ"
                                }}
                                getOptionLabel={(option) => option.cultura}
                                className={classes.autoCompleteFieldCulturas}
                                onChange={(e, v) => {
                                    if (v && v.id) {
                                        handleCultura(v);
                                    } else {
                                        handleCultura({}); // Empty object
                                    }
                                }}
                                // defaultValue={{ cultura: "Soja" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Selecionar Cultura"
                                        variant="outlined"
                                    />
                                )}
                            />
                            : null}


                    </Box>
                </Grid>
            </Grid>
            : null
    );
};

export default AutocompleteTopDashboard;

