import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingRight: theme.spacing(3),
        marginBottom: theme.spacing(2),
        overflow: 'hidden',
        fontFamily: 'Poppins',
    },
    img:{
        width:'50%',
        height:'100%'
    },

    chartAndCardWrapper: {
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        padding: 20,
        paddingTop: 0,
        borderRadius: 5
    },

    tab: {
        fontSize: '11px',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,


        marginRight: 5,
        marginTop: 10,
        '&:not(.Mui-selected)': {
            background: '#F4F3F3',
        },
        minHeight: 0,
        height: 40,
        margin: 0,
        minWidth: 0,
        padding: 5,
        fontFamily: 'Poppins',
    },

    tabsIndicator: {
        backgroundColor: 'lightgreen',
    },

    dashChart1: {
        marginTop: 10,
        border: '1px solid lightgray',
        borderRadius: 5
    },

    //CardMaterias
    positiveValue: {
        color: 'green',
    },
    negativeValue: {
        color: '#FF005C',
    },

    cardWrapper: {
        borderRadius: 10,
    },

    rootCard: {
        flexGrow: 1,
        paddingRight: theme.spacing(3),
        overflow: 'hidden',
        fontFamily: 'Poppins',
    },
    cardContent: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontFamily: 'Poppins',
        fontWeight: 'bold',
    },
    titleText: {
        fontWeight: 'bold',
        fontSize: 'smaller',
        color: '#939598',
        fontFamily: 'Poppins',
    },
    biggerTextMateria: {
        fontWeight: 700,
        fontSize: '17px',
        color: '#3D3D3D',
        fontFamily: 'Poppins',
    },
    biggerTextVariacao: {
        fontWeight: 700,
        fontSize: '15px',
        marginRight: 10, // Reduce marginRight
        color: '#3D3D3D',
        fontFamily: 'Poppins',
        width: '5rem', // Reduce width
    },
    biggerTextVariacao1: {
        justifyContent: 'space bwtween',
        fontWeight: 700,
        fontSize: '14px',
        color: '#3D3D3D',
        fontFamily: 'Poppins',
    },
    variacaoPercent: {
        fontWeight: 'bold !important',
        fontSize: '14px',
        color: '#3D3D3D',
        fontFamily: 'Poppins',
    },
    flexEnd: {
        marginLeft: 'auto',
    },

    centerContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    variacaoGrid: {
        borderTop: '1px solid #eee',
        paddingTop: theme.spacing(2),
    },

    composicaoGrid: {
        borderTop: '1px solid #eee',
        paddingTop: theme.spacing(2),
    },
    precoFreteGrid: {
        borderTop: '1px solid #eee',
        paddingTop: theme.spacing(2),
    },

    flagUS: {
        position: 'relative',
        top: 10,
        left: 0,
    },
    flagBR: {
        position: 'relative',
        top: 0,
        right: 10,
    },
    arrowIconPositive: {
        fontSize: '1.5rem',
        color: 'green', // Changed color to white
        position: 'absolute',
        zIndex: 1,
    },


    percentageValue: {
        fontWeight: 700,
        fontSize: '15px',
        marginRight: 10, // Reduce marginRight
        color: '#3D3D3D',
        fontFamily: 'Poppins',
        width: '6rem', // Reduce width
    },
    arrowVariacao: {
        marginLeft: 'auto',
    },

    arrowIconNegative: { //Card arrow collor
        fontSize: '1.5rem',
        color: 'white', // Changed color to white
        position: 'absolute',
        zIndex: 1,
        transform: 'rotate(180deg)',
    },

    circleIcon: {
        fontSize: '2.5rem',
        color: 'white',
        position: 'absolute',
        zIndex: 0,
    },

    bolinha: {
        justifyContent: 'center',
        alignContent: 'center',
        display: 'flex'
    },

    arrowDown: {
        fontSize: '21px',
        background: '#FF005C',
        borderRadius: 50,
        transform: 'rotate(180deg)',
        color: 'white'
    },

    arrowUp: {
        background: '#44FF57',
        borderRadius: 50,
        color: 'white'
    },

    //AutocompleteTop
    noBorder: {
        borderColor: 'transparent',
    },
    autoCompleteFieldBig: {
        marginTop: 10,
        marginRight: 10,
        width: 250,
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        borderRadius: 6,
    },
    autoCompleteFieldCulturas: {
        marginTop: 10,
        marginRight: 10,
        width: 600,
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        borderRadius: 6,
    },
    autoCompleteFieldBig2: {
        backgroundColor: 'white',
        marginRight: 10,
        marginTop: 10,
        width: '93%',
        borderRadius: 6,
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    },
    autoCompleteField: {
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        marginTop: 10,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
    },

    // NoticiasDashboard
    rootNews: {

    },
    SentimentIcon: {

        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },
        '&:active': {
            transform: 'translate(0, 2px)',
            background: "rgba(155, 194, 79)",
        },
    },

    noticiasContainer: {
        padding: theme.spacing(2),
    },

    newsList: {
        maxHeight: 600,
        // overflowY: 'auto',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
    },

    title: {
        marginTop: 10,
        fontWeight: 'bold',
        color: '#3D3D3D',
        fontFamily: 'Poppins',
        display: 'flex',
        alignItems: 'center',
    },
    newsDate: {
        color: '#717171',
        fontFamily: 'Poppins',
    },
    newsTitle: {
        fontSize: '17px',
        fontWeight: 'bold',
        color: '#4F4F4F',
        fontFamily: 'Poppins',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    arrowIcon: {
        fontSize: '1.5rem', // Increase the size of the arrow icon
        color: 'white',
        position: 'absolute',
        zIndex: 1,
    },


    newsItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '30px',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,

        '&:hover': {
            backgroundColor: '#f5f5f5',
            cursor: 'pointer',
            border: '1px solid lightgreen',
        },
    },

    newsDate: {
        color: '#717171',
        fontFamily: 'Poppins',
    },

    newsDashboardContainer: {
        borderRadius: theme.shape.borderRadius,
        maxHeight: '115vh', // Adjust this value according to your needs
        overflow: 'auto',
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'
    },

    negativeCircleIcon: {
        fontSize: '2.5rem',
        color: 'red',
        position: 'absolute',
        zIndex: 0,
    },



    //chart
    hoveredNewsItem: {
        borderColor: 'red',
    },



}));