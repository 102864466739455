import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getToken, checarPermissao } from '../../utils/utils'
import api_amr from '../../services/api_amr'
import Loader from 'react-loader-spinner';
import { TextField } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Autocomplete } from "@material-ui/lab";
import { isTemplateExpression } from "typescript";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function HistoricoFretes(props) {
    const [permitido, setPermitido] = useState(false)
    const [origem, setOrigem] = useState(null)
    const [destino, setDestino] = useState(null)
    const [ano, setAno] = useState('')
    const [janeiro, setJaneiro] = useState('0')
    const [fevereiro, setFevereiro] = useState('0')
    const [marco, setMarco] = useState('0')
    const [abril, setAbril] = useState('0')
    const [maio, setMaio] = useState('0')
    const [junho, setJunho] = useState('0')
    const [julho, setJulho] = useState('0')
    const [agosto, setAgosto] = useState('0')
    const [setembro, setSetembro] = useState('0')
    const [outubro, setOutubro] = useState('0')
    const [novembro, setNovembro] = useState('0')
    const [dezembro, setDezembro] = useState('0')
    const [cidadesList, setCidadesList] = useState('')
    const [histFretes, setHistFretes] = useState('')

    const classes = useStyles();

    useEffect(() => {
        async function fetchMyAPI() {

            setPermitido(await checarPermissao('historico_fretes', props))
            getCidade()
            getHistFretes()
        }
        fetchMyAPI();
    }, []);




    async function postHistFrete() {
        if ((origem === '') | (ano === '') | (destino === '')) {
            alert('Alguns campos obrigatórios estão incompletos')
            return
        }
        if ((origem === null) | (destino === null)) {
            alert('Alguns campos obrigatórios estão incompletos')
            return
        }
        else {
            const response_create_historico_fretes = await api_amr.post('/create_historico_fretes', {
                'id_origem': origem.id, 'id_destino': destino.id, 'ano': ano, 'janeiro': janeiro, 'fevereiro': fevereiro, 'marco': marco, 'abril': abril, 'maio': maio,
                'junho': junho, 'julho': julho, 'agosto': agosto, 'setembro': setembro, 'outubro': outubro, 'novembro': novembro, 'dezembro': dezembro,
            }, { headers: { 'x-api-key': getToken() } })


            if (response_create_historico_fretes.data === 'OK') {
                alert('Adicionado com Sucesso')
                getHistFretes()
                window.location.reload()
            }
        }
    }
    async function getCidade() {

        const response_lista_cidades = await api_amr.get('/return_cidades_brasil', { headers: { 'x-api-key': getToken() } });

        setCidadesList(response_lista_cidades.data)

    }

    async function getHistFretes() {

        const response_lista_fretes = await api_amr.get('/return_historico_fretes', { headers: { 'x-api-key': getToken() } });

        setHistFretes(response_lista_fretes.data)
    }

    return (
        permitido ?
            <div className={classes.container} >
                <div >
                    <h2 className={classes.inputh1}>Configurações Histórico Fretes</h2>
                </div>
                <div style={{ flexDirection: 'column', width: window.innerWidth - 400 }}>

                    <div>
                        <div>


                            <div style={{ paddingRight: 28, marginLeft: 15 }} >

                                <Autocomplete

                                    options={cidadesList}
                                    getOptionLabel={(option) => (option.id + ' - ' + option.cidade + ' - ' + option.UF)}
                                    className={classes.autoCompleteFieldBig}

                                    onChange={(e, v) => setOrigem(v)}

                                    classes={{
                                        input: classes.input,

                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder="Selecionar ORIGEM"
                                        variant='outlined'
                                        value={origem}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />}
                                />


                                <Autocomplete

                                    options={cidadesList}
                                    getOptionLabel={(option) => (option.id + ' - ' + option.cidade + ' - ' + option.UF)}
                                    className={classes.autoCompleteFieldBig}

                                    onChange={(e, v) => setDestino(v)}

                                    classes={{
                                        input: classes.input,

                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder="Selecionar DESTINO"
                                        variant='outlined'
                                        value={destino}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />}
                                />

                            </div>


                            <Autocomplete style={{ marginLeft: 15 }}

                                options={['2019', '2020', '2021', '2022', '2023','2024']}

                                className={classes.autoCompleteFieldBig}

                                onChange={(e, v) => setAno(v)}

                                classes={{
                                    input: classes.input,

                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    placeholder="Selecionar ANO"
                                    variant='outlined'

                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}

                                />}
                            />

                        </div>
                        <div>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <TextField
                                    key='Janeiro'
                                    label="Janeiro"
                                    variant='outlined'
                                    onChange={(event) => setJaneiro(event.target.value < 0 ? 0 : event.target.value)}
                                    value={janeiro}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        type: 'number',
                                        inputProps: { min: 0 },
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    key='Fevereiro'
                                    label="Fevereiro"
                                    variant='outlined'
                                    onChange={(event) => setFevereiro(event.target.value < 0 ? 0 : event.target.value)}
                                    value={fevereiro}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        type: 'number',
                                        inputProps: { min: 0 },
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                />
                            </div>

                            <div>
                                <TextField
                                    key='Março'
                                    label="Março"
                                    variant='outlined'
                                    onChange={(event) => setMarco(event.target.value < 0 ? 0 : event.target.value)}
                                    value={marco}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        type: 'number',
                                        inputProps: { min: 0 },
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                />
                            </div>


                            <div>
                                <TextField
                                    key='Abril'
                                    label="Abril"
                                    variant='outlined'
                                    onChange={(event) => setAbril(event.target.value < 0 ? 0 : event.target.value)}
                                    value={abril}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        type: 'number',
                                        inputProps: { min: 0 },
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <TextField
                                    key='Maio'
                                    label="Maio"
                                    variant='outlined'
                                    onChange={(event) => setMaio(event.target.value < 0 ? 0 : event.target.value)}
                                    value={maio}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        type: 'number',
                                        inputProps: { min: 0 },
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    key='Junho'
                                    label="Junho"
                                    variant='outlined'
                                    onChange={(event) => setJunho(event.target.value < 0 ? 0 : event.target.value)}
                                    value={junho}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        type: 'number',
                                        inputProps: { min: 0 },
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    key='Julho'
                                    label="Julho"
                                    variant='outlined'
                                    onChange={(event) => setJulho(event.target.value < 0 ? 0 : event.target.value)}
                                    value={julho}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        type: 'number',
                                        inputProps: { min: 0 },
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    key='Agosto'
                                    label="Agosto"
                                    variant='outlined'
                                    onChange={(event) => setAgosto(event.target.value < 0 ? 0 : event.target.value)}
                                    value={agosto}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        type: 'number',
                                        inputProps: { min: 0 },
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <TextField
                                    key='Setembro'
                                    label="Setembro"
                                    variant='outlined'
                                    onChange={(event) => setSetembro(event.target.value < 0 ? 0 : event.target.value)}
                                    value={setembro}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        type: 'number',
                                        inputProps: { min: 0 },
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    key='Outubro'
                                    label="Outubro"
                                    variant='outlined'
                                    onChange={(event) => setOutubro(event.target.value < 0 ? 0 : event.target.value)}
                                    value={outubro}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        type: 'number',
                                        inputProps: { min: 0 },
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    key='Novembro'
                                    label="Novembro"
                                    variant='outlined'
                                    onChange={(event) => setNovembro(event.target.value < 0 ? 0 : event.target.value)}
                                    value={novembro}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        type: 'number',
                                        inputProps: { min: 0 },
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    key='Dezembro'
                                    label="Dezembro"
                                    variant='outlined'
                                    onChange={(event) => setDezembro(event.target.value < 0 ? 0 : event.target.value)}
                                    value={dezembro}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        type: 'number',
                                        inputProps: { min: 0 },
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ height: 100, marginLeft: 15, marginTop: 15 }}>
                            <Button onClick={() => postHistFrete()} variant="contained" className={classes.calcButton}> Cadastrar Frete</Button>
                        </div>

                    </div>
                    <div>
                        <TableContainer className={classes.tableModels}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>

                                        <TableCell >
                                        <b className={classes.inputh1}>Cidade de Origem</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}> UF de Origem</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}>  Cidade de Destino</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}>  UF de Destino</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}>  Ano</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}> Janeiro</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}> Fevereiro</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}> Março</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}> Abril</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}> Maio</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}> Junho</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}> Julho</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}> Agosto</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}> Setembro</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}> Outubro</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}> Novembro</b>
                                        </TableCell>
                                        <TableCell >
                                        <b className={classes.inputh1}> Dezembro</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>


                                <TableBody>
                                    {Object.keys(histFretes).map((item, index) => (

                                        <TableRow key={index}>




                                            <TableCell>
                                            <b className={classes.inputh1}>{histFretes[item].cidade_origem}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{histFretes[item].UF_origem}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{histFretes[item].cidade_destino}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}> {histFretes[item].UF_destino}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}> {histFretes[item].ano}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}> {histFretes[item].janeiro}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}> {histFretes[item].fevereiro}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{histFretes[item].marco}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>  {histFretes[item].abril}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{histFretes[item].maio}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}> {histFretes[item].junho}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{histFretes[item].julho}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{histFretes[item].agosto}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}> {histFretes[item].setembro}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}> {histFretes[item].outubro}</b>
                                            </TableCell>
                                            <TableCell>
                                                <b className={classes.inputh1}>{histFretes[item].novembro}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{histFretes[item].dezembro}</b>
                                            </TableCell>

                                        </TableRow>))
                                    }</TableBody>
                            </Table>
                        </TableContainer >


                    </div>


                </div>
            </div > : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 100,
    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    noBorder: {
        border: "none",
    },
    autoCompleteFieldSmall: {
        marginTop: 15,
        minWidth: 104,
        maxWidth: 104,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteField: {
        marginTop: 15,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 15,
        width: 471,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    inputCheckbox: {

        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000'
    },
    inputh1: {
        fontFamily: 'Poppins',

        color: '#383838'
    },
    root: {
        "&$checked": {
            color: "black",
            backgroundColor: 'white'
        }
    },
    checked: {
        borderColor: 'black'
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
    calcDiv: {
        marginTop: 40,
        width: '146px',
        marginLeft: 325,
        height: 45
    },
    nutriDiv: {
        paddingRight: 28,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 228
    },
    inputForm: {
        width: '50%',
        fontFamily: 'Poppins',
        color: '#383838'
    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    }, table: {
        minWidth: 400,
        maxWidth: '100%'
    },
    rowSize: {
        maxWidth: 500
    },
    tableModels: {
        marginTop: 20,
        maxWidth: '90%',
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
}));

export default HistoricoFretes;
