import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getToken, checarPermissao } from '../../utils/utils'
import api_amr from '../../services/api_amr'
import Loader from 'react-loader-spinner';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";


function ConfigFazendas(props) {
    const [permitido, setPermitido] = useState(false)
    const [logChamadas, setLogChamadas] = useState('')
    const [logPaginas, setLogPaginas] = useState('')

    const [user, setUser] = useState('')
    const [userInfo, setUserInfo] = useState('')
    const [usuarioLog, setUsuarioLog] = useState('')
    const [dataInicio, setDataInicio] = useState('2023-01-01')
    const [dataFinal, setDataFinal] = useState(new Date().toISOString().split('T')[0])


    const classes = useStyles();

    useEffect(() => {
        async function fetchMyAPI() {
            setPermitido(await checarPermissao('historico_usabilidade', props))
           
            
            const response_user_info = await api_amr.get('/get_user_info', { params: { 'all': 1 }, headers: { 'x-api-key': getToken() } });
            setUserInfo(response_user_info.data);
            console.log(response_user_info.data)

        }
        fetchMyAPI();

    }, []);


    async function GetUserUsability() {
        if(user===''){
            alert('Usuario não selecionado')
            return
        }
        const response_log_chamadas = await api_amr.get('/return_usabilidade_chamadas', { params: { 'all': 0, 'id_user': user.id, 'data_ini': dataInicio, 'data_fim': dataFinal }, headers: { 'x-api-key': getToken() } });
        setLogChamadas(response_log_chamadas.data);
        
        const response_log_paginas = await api_amr.get('/return_usabilidade_paginas', { params: { 'all': 0, 'id_user': user.id, 'data_ini': dataInicio, 'data_fim': dataFinal }, headers: { 'x-api-key': getToken() } });
        setLogPaginas(response_log_paginas.data);
    }

    return (
        permitido ?
            <div className={classes.container}  >
                <div >
                <h2 className={classes.inputh1}>Histórico de Usabilidade </h2>

                    <div>

                        {usuarioLog === '' ? <Autocomplete

                            options={userInfo}
                            getOptionLabel={(option) => option.id + '-' + option.name}
                            className={classes.autoCompleteFieldBig}
                            onChange={(e, v) => setUser(v)}

                            classes={{
                                input: classes.input,

                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                placeholder="Selecionar Usuario"
                                variant='outlined'
                                value={user}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    classes: { notchedOutline: classes.noBorder }
                                }}

                            />}
                        /> : null}
                    </div>
                    <div>
                        <TextField
                            type="date"
                            key='Data de início'
                            label="Data de início"
                            variant='outlined'
                            onChange={(event) => setDataInicio(event.target.value)}
                            value={dataInicio}
                            className={classes.autoCompleteField}
                            InputProps={{
                                disableUnderline: true,
                                classes: { notchedOutline: classes.noBorder },

                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            type="date"
                            key='Data final'
                            label="Data Final"
                            variant='outlined'
                            onChange={(event) => setDataFinal(event.target.value)}
                            value={dataFinal}
                            className={classes.autoCompleteField}
                            style={{ marginLeft: 15 }}
                            InputProps={{
                                disableUnderline: true,
                                classes: { notchedOutline: classes.noBorder },

                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className={classes.autoCompleteField}>
                    <Button onClick={() => GetUserUsability()} variant="contained" className={classes.calcButton}> Buscar dados do Usuário</Button>
                    </div>

                    <div>
                        <TableContainer className={classes.tableModels}>

                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>

                                        <TableCell width={50}>
                                        <b className={classes.inputh1}>Usuário</b>
                                        </TableCell>
                                        <TableCell width={250}>
                                        <b className={classes.inputh1}>Chamada</b>
                                        </TableCell>
                                        <TableCell width={250}>
                                        <b className={classes.inputh1}> N. de Chamadas</b>
                                        </TableCell>
                                        <TableCell width={250}>
                                        <b className={classes.inputh1}> Última Chamada</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {Object.keys(logChamadas).map((item, index) => (

                                        <TableRow key={index}>

                                            <TableCell>
                                            <b className={classes.inputh1}>{logChamadas[item].name}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}> {logChamadas[item].chamada}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{logChamadas[item].total_chamadas}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}> {new Date(logChamadas[item].data_max).toLocaleString("pt-BR")}</b>
                                            </TableCell>


                                        </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div >
                    <div>
                        <TableContainer className={classes.tableModels}>

                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>

                                        <TableCell width={50}>
                                        <b className={classes.inputh1}>Usuário</b>
                                        </TableCell>
                                        <TableCell width={250}>
                                        <b className={classes.inputh1}>Página Acessada</b>
                                        </TableCell>
                                        <TableCell width={250}>
                                        <b className={classes.inputh1}> N. de acessos</b>
                                        </TableCell>
                                        <TableCell width={250}>
                                        <b className={classes.inputh1}>Último acesso</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {Object.keys(logPaginas).map((item, index) => (

                                        <TableRow key={index}>

                                            <TableCell>
                                            <b className={classes.inputh1}> {logPaginas[item].name}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}> {logPaginas[item].argumentos}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>  {logPaginas[item].total_chamadas}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}> {new Date(logPaginas[item].data_max).toLocaleString("pt-BR")}</b>
                                            </TableCell>


                                        </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div >
                </div >

            </div > : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 100,
    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    noBorder: {
        border: "none",
    },
    autoCompleteFieldSmall: {
        marginTop: 15,
        minWidth: 104,
        maxWidth: 104,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteField: {
        marginTop: 15,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 15,
        width: 471,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    inputCheckbox: {

        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000'
    },
    inputh1: {
        fontFamily: 'Poppins',

        color: '#383838'
    },
    root: {
        "&$checked": {
            color: "black",
            backgroundColor: 'white'
        }
    },
    checked: {
        borderColor: 'black'
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: 471,
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
    calcDiv: {
        marginTop: 40,
        width: '146px',
        marginLeft: 325,
        height: 45
    },
    nutriDiv: {
        paddingRight: 28,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 228
    },
    inputForm: {
        width: '50%',
        fontFamily: 'Poppins',
        color: '#383838'
    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    }, table: {
        minWidth: 400,
        maxWidth: '100%'
    },
    rowSize: {
        maxWidth: 500
    },
    tableModels: {
        marginTop: 20,
        maxWidth: '90%',
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
}));

export default ConfigFazendas;
