import React, { useState } from 'react';
import { ListItem, List, ListItemText } from '@material-ui/core';
import styles from '../styles';

const PageNav = ({ currentPage, totalPages, onPageChange }) => {
    const [displayedPages, setDisplayedPages] = useState([]);
    const classes = styles();

    const updateDisplayedPages = (startFrom) => {
        const maxDisplayedPages = 7;
        let startPage = startFrom || 1;
        let endPage = startPage + maxDisplayedPages - 1;

        if (endPage > totalPages) {
            endPage = totalPages;
        }

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        setDisplayedPages(pages);
    };

    const shiftDisplayedPages = (direction) => {
        const firstPage = displayedPages[0];
        const lastPage = displayedPages[displayedPages.length - 1];

        if (direction === 1 && lastPage < totalPages) {
            updateDisplayedPages(firstPage + 1);
        } else if (direction === -1 && firstPage > 1) {
            updateDisplayedPages(firstPage - 1);
        }
    };

    const handlePageClick = (page) => {
        onPageChange(page);
    };

    React.useEffect(() => {
        updateDisplayedPages();
    }, [totalPages]);

    return (
        <nav>
            <List className={classes.pageList}>
                {displayedPages[0] > 1 && (
                    <ListItem button className={classes.pageNumber} onClick={() => shiftDisplayedPages(-1)}>
                        <ListItemText primary="<" />
                    </ListItem>
                )}
                {displayedPages.map((page) => (
                    <ListItem
                        button
                        key={page}
                        className={`${classes.pageNumber} ${page === currentPage ? classes.active : ''}`}
                        onClick={() => handlePageClick(page)}
                    >
                        <ListItemText primary={page} />
                    </ListItem>
                ))}
                {displayedPages[displayedPages.length - 1] < totalPages && (
                    <ListItem button className={classes.pageNumber} onClick={() => shiftDisplayedPages(1)}>
                        <ListItemText primary=">" />
                    </ListItem>
                )}
            </List>
        </nav>
    );
};

export default PageNav;
