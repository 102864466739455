import { makeStyles } from "@material-ui/core/styles";
import { BorderColor } from "@material-ui/icons";

export default makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        // border: '1px dashed grey',
      
    },

    topBar: {
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)',
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        width: '90vw',
        position: 'fixed',
        background: 'white',
        zIndex: 2,
        marginRight: 130
    },
    sideBar: {
        // border: '1px dashed grey',
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        width: '20%', 
        minHeight:'85vh',     
        // overflowY:'auto',   
        // overflowX:'hidden',     
        zIndex: 2,      
        minWidth: 300,
        flexGrow: 1,
        alignContent: 'center',
        alignItems: 'center',    
        
        
    },
    SentimentContainer:{
        // border: '1px dashed grey',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent:'center',
        
    },
    SentimentText:{
        fontFamily: 'Poppins',
    },
    SentimentIcon:{
        
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },
        '&:active': {
            transform: 'translate(0, 2px)',
            background: "rgba(155, 194, 79)",
        },
    },
    mainDiv: {
        // border: '1px dashed grey',
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Poppins',
        display: 'flex',
        overflowY:'auto',
        overflowX:'hidden',
        flexDirection: 'column',      
        width:'80%',        
        flexGrow: 1,     
        
    },
    postDiv: {
        // border: '1px dashed grey',
        marginTop: 100,
        marginBottom: 100,
        padding: 20,
        width: '70%',        
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)',

    },

    buttonContainer:{
        //  border: '1px dashed grey',
    },
    AutocompleteContainer:{
        // border: '1px dashed grey',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent:'center',
        
    },
    checkBoxContainer:{
        // border: '1px dashed grey',
    },
    exitButtonsContainer:{
        // border: '1px dashed grey',
        display: 'flex',
        flexDirection: 'row', 
    },
    exitButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '130px',
        color: 'white',
        borderRadius: 6,
        margin: 5,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },
    },

    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        margin: 5,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },
    },

    miniButton: {
        margin: 2,
        padding: 0,
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: 40,
        width: 40,
        background: '#A3CC51',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },
        '&:active': {
            transform: 'translate(0, 2px)',
            background: "rgba(155, 194, 79)",
        },
    },

    miniButtonRed: {
        margin: 2,
        padding: 0,
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: 40,
        width: 40,
        background: '#E03C3C',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(224, 60, 60, 0.7)",
        },
        '&:active': {
            transform: 'translate(0, 2px)',
            background: "#bf3232",
        },
    },

    

   
    componentDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '130%',
        position: 'relative',
        alignItems: 'center',
        alignContent: 'center',
        textAlign: 'center'
    },

    textField: {
        width: '100%',
        marginTop:10
    },

    titleField: {
        width: '100%'
    },

    text: {
        textIndent: 50
    },

    title: {
        fontSize: '32pt',
        fontWeight: 'bolder',
        height: 200,
        lineHeight: 1,
    },

    tagDivDiv: {
        border: '1px solid lightgray',
        marginTop: 20,
        minWidth: 400,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 10
    },

    tag: {
        margin: 10,
        display: 'flex',
        fontFamily: 'Poppins',
        background: 'lightgreen',
        width: 'fit-content',
        height: 'fit-content',
        borderRadius: 20,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },

    delTag: {
        marginLeft: 10,
        paddingLeft: 2,
        paddingBottom: 2,
        paddingRight: 6,
        paddingTop: 5,
        background: '#E03C3C',
        height: 20,
        width: 20,
        borderRadius: 20,
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        '&:hover': {
            background: "rgba(224, 60, 60, .5)",
            cursor: 'pointer'
        },
        '&:active': {
            transform: 'translate(0, 2px)',
            background: "#a83e3e",
        },
    },

    fileStyle: {
        background: 'lightblue',
        border: '1px solid black',
        width: '100%',
        marginTop:10
    },

    tagCategoryDiv: {
        paddingLeft: 10,
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid lightgray',
        'flexWrap': 'wrap',
        fontFamily: 'Poppins'
    },

    tagCategory: {
        margin: 10,
        border: '1px solid lightgray',
        padding: 5,
        borderRadius: 10
    },

    componentButtons: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        marginLeft: '10%',
        alignContent: 'center',
        alignItems: 'center',
        background:'red'
    },
    paper: {
        width: "300px", /* set the desired width */
        backgroundColor:'lightgray',
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)',
        BorderColor:'gray',
        border: '1px solid grey',

    },

    autoCompleteFieldBig: {
        // border: '1px dashed grey',
        backgroundColor: 'white',
        
        marginTop: 10,
        width: "250px", 
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)',
        alignSelf:'center'
    },

}))
