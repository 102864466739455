import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import api_amr from 'services/api_amr';
import useStyles from '../styles';
import TagFilter from '../components/TagFilter';
import PageNav from '../components/PageNav';
import NewsDisplay from '../components/NewsDisplay';
import { getToken } from '../../../utils/utils.js'
const NewsListDisplay = () => {
    const classes = useStyles();
    const [selectedTag, setSelectedTag] = useState('');
    const [newsData, setNewsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await api_amr.get('/return_used_tags');
                setTags(response.data);
            } catch (error) {
                console.log("Error fetching tags:", error)
            }
        };
        fetchTags();
    }, [])

    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                const response = await api_amr.get('/return_posts_new', {
                    params: {
                        page: currentPage,
                        tag: selectedTag,
                        posts_per_page: 5,
                        include_latest: 0
                    },
                    headers: { 'x-api-key': getToken() }
                });
                setNewsData(response.data.posts);
                setTotalPages(response.data.pages);
            } catch (error) {
                console.log("Error fetching news:", error)
            }
        };
        fetchNewsData();
    }, [currentPage, selectedTag])

    const handleTagClick = async (tag) => {
        setSelectedTag(tag.tag_id);
        setCurrentPage(1);
        try {
            const response = await api_amr.get('/return_posts_new', {
                params: {
                    'page': 1,
                    'tag': tag.tag_id,
                    'posts_per_page': 5,
                    include_latest: 1
                },
                headers: { 'x-api-key': getToken() }
            });
            setNewsData(response.data.posts)
            setTotalPages(response.data.pages)
        } catch (error) {
            console.log("Error fetching news:", error)
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    return (
        <Grid container direction="column">
            <Grid item>
                <Typography variant="h5" className={classes.topTitle} >
                    NOTÍCIAS AMR
                </Typography>
            </Grid>

            <Grid item>
                <TagFilter tags={tags} onTagClick={handleTagClick} />
            </Grid>

            <Grid item xs={12}>
                <NewsDisplay newsData={newsData} />
            </Grid>

            <Grid item className={classes.PageNavPos}>
                <PageNav
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </Grid>
        </Grid>
    );
};

export default NewsListDisplay;
