import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';  // Leaflet CSS import

import marker from 'leaflet/dist/images/marker-icon.png';
import marker2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: marker,
    iconRetinaUrl: marker2x,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

function ControleUsabilidadeLocation({ locations }) {
    const mapRef = useRef(null);
    const mapInstance = useRef(null);  // reference to store map instance
    console.log('locations', locations)
    useEffect(() => {
        // Initialize the map only if it hasn't been initialized yet
        if (mapRef.current && !mapInstance.current) {
            // Coordinates for the United States
            const BRCoordinates = [-15.7801, -47.9292];

            // Initialize the map with the US coordinates
            mapInstance.current = L.map(mapRef.current).setView(BRCoordinates, 4);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(mapInstance.current);
        }

        // Clean up on unmount
        return () => {
            if (mapInstance.current) {
                mapInstance.current.remove();
            }
        };
    }, []); // This useEffect has no dependencies


    useEffect(() => {
        // This will only work if the map instance has been created
        if (mapInstance.current && locations) {
            // Add markers for each location
            locations.forEach(location => {
                L.marker([location.longitude, location.latitude])
                    .bindTooltip(`Name: ${location.name}<br>Total Chamadas: ${location.total_chamadas}`)  // Add tooltip here
                    .addTo(mapInstance.current);
            });

        }
    }, [locations]);  // Dependency array should only contain 'locations'

    return <div ref={mapRef} style={{ height: '100%', width: '100%' }}></div>;
}
export default ControleUsabilidadeLocation;
