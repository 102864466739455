import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, TextField, Divider, Button, Typography } from '@material-ui/core';
import HistPrecoTabelaEditavel from '../HistPreco/HistPrecoTabelaEditavel';
import { Autocomplete } from "@material-ui/lab";
import Tooltip from '@material-ui/core/Tooltip';


export default function ComponentPreviewTabelaEditavel({ open, onClose, onAddComponent, adding }) {
    const classes = useStyles();
    const [tableStates, setTableStates] = useState([]);
    const [componentTitle, setComponentTitle] = useState('');
    const [headers, setHeaders] = useState([]);
    const [removedHeaders, setRemovedHeaders] = useState([]);

    const closeClean = () => {
        setComponentTitle('')
        setRemovedHeaders([])
        onClose()
    }

    const save = () => {
        onAddComponent('tabela_editavel', 'historico_preco', tableStates, componentTitle)
        closeClean()

    }

    return (
        <Dialog open={open} onClose={closeClean} maxWidth="lg" fullWidth >
            <DialogTitle>Histórico de Preço</DialogTitle>

            <DialogContent className={classes.dialog}>
                <div className={classes.topBarDiv}>
                    <div className={classes.topBarContent}>
                        <Typography className={classes.typo}>Nome da Tabela:</Typography>
                        <TextField
                            onChange={(e) => setComponentTitle(e.target.value)}
                            size='small'
                            InputProps={{
                                classes: {
                                    root: classes.inputRoot,
                                }, disableUnderline: true,
                            }}
                        />
                    </div>

                    <div className={classes.divider}></div>

                    <div className={classes.topBarContent}>
                        <Typography className={classes.typo}>Colunas Removidas:</Typography>

                        <Tooltip title="Selecione as colunas a serem removidas da tabela abaixo, caso queira retornar a coluna à tabela, remova-a da caixa de seleção clicando no 'x' do item">
                            <Autocomplete
                                size='small'
                                key={'headerSelected'}
                                multiple
                                ChipProps={{ classes: { root: classes.chip } }}
                                onChange={(e, value) => setRemovedHeaders(value)}
                                getOptionLabel={(option) => option.headerName}
                                options={headers}
                                filterOptions={(options) => {
                                    return options.filter(
                                        (option) =>
                                            !removedHeaders.some(
                                                (removedHeader) => removedHeader.headerName === option.headerName
                                            )
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            classes: {
                                                root: classes.inputRoot,
                                            },
                                            disableUnderline: true,
                                        }}
                                    />
                                )}
                            />
                        </Tooltip>
                    </div>
                </div>

                <div className={classes.histDiv}>
                    <HistPrecoTabelaEditavel
                        getConfig={(data) => setTableStates(data)}//puxa as informações da tabela
                        getHeaders={(data) => setHeaders(data)}//puxa os headers da tabela
                        selectedColumns={removedHeaders}//define os header a serem removidos
                        enableColumnSelection={true}
                    />
                </div>

                <Divider />

                <div className={classes.buttonDiv}>
                    <Button
                        disabled={adding}
                        onClick={save}
                        variant='contained'
                        size='small' color='primary'>
                        Salvar
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

const useStyles = makeStyles(() => ({
    divider: {
        marginLeft: 9,
        marginRight: 9
    },

    histDiv: {
        height: 500,
        maxWidth: "100%"
    },

    buttonDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 10
    },

    topBarDiv: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10
    },

    topBarContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },

    dialog: {
        overflowX: 'hidden'
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    inputRoot: {
        fontSize: 14,
        minWidth: '150px',  // Largura inicial
        width: 'auto',  // Permitir que ele cresça
        display: 'flex',
        flexWrap: 'nowrap', // Manter todos os itens na mesma linha
        borderRadius: 2,
        paddingLeft: 10,
        border: '1px solid lightgray'
    },

    label: {
        borderRight: '1px solid lightgray',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: 5,
        paddingRight: 5
    },

    typo: {
        fontSize: 14,
        marginRight: 5
    },

    chip: {
        display: 'inline-flex', // para manter os chips inline
        margin: '0 5px 0 0',    // alguma margem para espaço entre os chips
    },
}));