import React, { useState, useEffect } from "react";
import navio_login from 'assets/img/navio_login.png';
import logo_amr from 'assets/img/logo_amr.png'
import "@fontsource/poppins";
import { makeStyles } from "@material-ui/core/styles";
import api_amr from "services/api_amr";

function ValidaCadastro(props) {

    const classes = useStyles();

    useEffect(() => {
        async function fetchMyAPI() {
            const response_verify = await api_amr.post('/verify_email_cadastro',{'token': props.match.params.token });
            if(response_verify.data === 'OK'){
                alert('Email Verificado com Sucesso')
                window.location.replace( window.location.href.includes('http://localhost:3000/') ?'http://localhost:3000':'plataformaamrbi.com')
            }
            else if(response_verify.data ==='NF'){
                alert("Cadastro nao existe")
                window.location.replace( window.location.href.includes('http://localhost:3000/') ?'http://localhost:3000':'plataformaamrbi.com')
            }
            else if(response_verify.data ==='JV'){
                alert("Email ja verificado")
                window.location.replace( window.location.href.includes('http://localhost:3000/') ?'http://localhost:3000':'plataformaamrbi.com')
            }
            else{
                alert('ERRO')
                window.location.replace( window.location.href.includes('http://localhost:3000/') ?'http://localhost:3000':'plataformaamrbi.com')
            }
            
            

            

        }
        fetchMyAPI();
    }, []);


    return (

        <div className={classes.container}>
            <div className={classes.imgDiv}>
                <img src={navio_login} alt="login" style={{ width: '100%' }} />
            </div>
            <div className={classes.loginDiv}>

                <img src={logo_amr} alt="login" style={{ width: '35%', height: '7%' }} />
                
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'row'
    },
    imgDiv: {
        width: '50%',
        height: '100%'
    },
    loginDiv: {
        width: '50%',
        height: '100%',

        alignItems: 'center',
        justifyContent: 'center',

        display: 'flex',
        flexDirection: 'column'
    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    input: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px'
    }
}));

export default ValidaCadastro;
