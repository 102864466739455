import React, { useEffect, useState } from "react";
import { getToken } from '../../utils/utils';
import api_amr from '../../services/api_amr';
import { Autocomplete } from "@material-ui/lab";
import { TextField, Button, Card, CardContent, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
    card: {
        minWidth: 275,
        maxWidth: 800,
        padding: theme.spacing(2),
        boxShadow: theme.shadows[3],
    },
    formField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

function CadastroDemandas(props) {
    const [associados, setAssociados] = useState([]);
    const [users, setUsers] = useState('')
    const [selectedAssociado, setSelectedAssociado] = useState(null);
    const [fazendas, setFazendas] = useState([]);
    const [materias, setMaterias] = useState([]);
    const [culturas, setCulturas] = useState([]);
    const [anosSafra, setAnosSafra] = useState([])
    const [escolhaMaterias, setEscolhaMaterias] = useState(true)//True é para matérias primas que já existem, false mostra as cadastradas manualmente com campo aberto
    const [nomesMaterias, setNomeMaterias] = useState([])

    const [demanda, setDemanda] = useState({
        id_fazenda: '',
        id_materia: 0,
        quantidade: '',
        id_cultura: '',
        ano_safra: '',
        id_ano_safra: '',
        status: 'Aberto',
        preco_total_pago: '',
        cotacao_dolar: '',
        tipo_compra: '',
        fornecedor: '',
        obs: '',
        data_entrega: '',
        data_abertura: '',
        id_usuario: '',
        nome_materia: '',
        id_fechamento:0
    });

    const classes = useStyles();

    useEffect(() => {
        async function fetchAssociados() {
            const response_associados = await api_amr.get('/get_associados', { headers: { 'x-api-key': getToken() } });
            setAssociados(response_associados.data);
        }
        fetchAssociados();
    }, []);



    useEffect(() => {
        async function fetchMaterias() {
            const response_materias = await api_amr.get('/get_materias', { headers: { 'x-api-key': getToken() } });
            setMaterias(response_materias.data);
        }
        fetchMaterias();
    }, []);

    useEffect(() => {
        async function fetchCulturas() {
            const response_culturas = await api_amr.get('/get_culturas', { headers: { 'x-api-key': getToken() } });
            setCulturas(response_culturas.data);
        }
        fetchCulturas();
    }, []);
    useEffect(() => {
        async function fetchAnosSafra() {
            const response_AnosSafra = await api_amr.get('/read_anos_safra', { headers: { 'x-api-key': getToken() } });
            setAnosSafra(response_AnosSafra.data);
        }
        fetchAnosSafra();
    }, []);

    useEffect(() => {
        if (selectedAssociado) {
            fetchFazendas(selectedAssociado.id_associado);
        }
    }, [selectedAssociado]);
    async function fetchNomeMaterias() {
        const response_NomeMaterias = await api_amr.get('/get_nomes_materias', { headers: { 'x-api-key': getToken() } });
        console.log(response_NomeMaterias.data)
        setNomeMaterias(response_NomeMaterias.data);
        
    }
    useEffect(() => {
       
        fetchNomeMaterias();
    },[]);

    async function fetchFazendas(id_associado) {
        const response = await api_amr.get(`/get_farms?id_associado=${id_associado}`, { headers: { 'x-api-key': getToken() } });
        setFazendas(response.data);
    }

    function handleInputChange(event) {
       
        const { name, value } = event.target;
        setDemanda({ ...demanda, [name]: value });
    }

    function handleAutocompleteChange(event, newValue, field) {
        setDemanda({ ...demanda, [field]: newValue ? newValue.id : '' });
    }
    function handleAutocompleteChangeCultura(event, newValue, field) {
        setDemanda({ ...demanda, [field]: newValue ? newValue.id_cultura : '' });
    }
    function handleAutocompleteChangeAnoSafra(event, newValue, field) {
        setDemanda({ ...demanda, [field]: newValue ? newValue.id_ano_safra : '' });
        console.log(demanda)
    }


    function handleFazendaChange(event, newValue) {
        console.log(newValue,123)
        setDemanda({ ...demanda, id_fazenda: newValue ? newValue.id_farm : '' });
    }
    function handleUserChange(event, newValue) {
        console.log(newValue)
        setDemanda({ ...demanda, id_usuario: newValue ? newValue.id : '' });
    }

    function handleMateriaChange(event, newValue) {
        setDemanda({ ...demanda, id_materia: newValue ? newValue.id_materias : '' });
    }
    const handleStatusChange = (event, newValue) => {
        setDemanda({ ...demanda, status: newValue });
    };
    const handleNomeMateriaChange = (event, newValue) => {
       

        // Determine if newValue is a string or an object
        const selectedValue = typeof newValue === 'string' ? newValue : newValue ? newValue.nome_materia : '';
        console.log(newValue)
        setDemanda({
            ...demanda,
            nome_materia: selectedValue, // Use the determined value
            id_fechamento: newValue.id_formulacao, // Use the determined value

        });

    };
    async function handleSubmit() {
        try {
            const response = await api_amr.post('/create_demanda', demanda, { headers: { 'x-api-key': getToken() } });
            if (response.data === 'OK') {
                alert('Demanda criada com sucesso');
                // Limpar o formulário
                fetchNomeMaterias()
                setDemanda({
                    id_fazenda: '',
                    id_materia: '',
                    quantidade: '',
                    id_cultura: '',
                    ano_safra: '',
                    status: '',
                    id_ano_safra: '',
                    preco_total_pago: '',
                    cotacao_dolar: '',
                    tipo_compra: '',
                    fornecedor: '',
                    obs: '',
                    data_entrega: '',
                    data_abertura: '',
                    id_usuario: '',
                    nome_materia: '',
                    id_fechamento:0
                });
            } else {
                alert('Erro ao criar demanda:', response.data);
            }
        } catch (error) {
            alert('Erro ao criar demanda:', error);
        }
    }

    async function selecionarAssociado(valor) {
        setSelectedAssociado(valor)
        const response_Users = await api_amr.get('/get_users', { params: { 'associado': valor.id_associado }, headers: { 'x-api-key': getToken() } });
        setUsers(response_Users.data);
    }



    return (
        <div className={classes.container}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Criar Demanda
                    </Typography>
                    <Autocomplete
                        options={associados}
                        getOptionLabel={(option) => option.name_associado}
                        onChange={(event, newValue) => selecionarAssociado(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Selecionar Associado"
                                variant="outlined"
                                className={classes.formField}
                                size="small"
                            />
                        )}
                    />
                    {users !== '' ?
                        < Autocomplete
                            options={users}
                            getOptionLabel={(option) => option.name}
                            onChange={handleUserChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Selecionar Usuário"
                                    variant="outlined"
                                    className={classes.formField}
                                    size="small"
                                />
                            )}
                        /> : null}


                    <Autocomplete
                        options={fazendas}
                        getOptionLabel={(option) => option.nome_fazenda}
                        onChange={handleFazendaChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Selecionar Fazenda"
                                variant="outlined"
                                className={classes.formField}
                                size="small"
                            />
                        )}
                    />
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <Checkbox checked={escolhaMaterias} onChange={() => setEscolhaMaterias(!escolhaMaterias)} />
                        {escolhaMaterias ? <h3>Matérias plataforma</h3> : <h3>Outras Matérias</h3>}
                    </div>
                    {escolhaMaterias == true ?
                        <Autocomplete
                            options={materias}
                            getOptionLabel={(option) => option.abreviacao}
                            onChange={handleMateriaChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Selecionar Produto"
                                    variant="outlined"
                                    className={classes.formField}
                                    size="small"
                                />
                            )}
                        /> :
                        <Grid item xs={12}>
                            <Autocomplete
                                freeSolo
                                options={nomesMaterias}
                                getOptionLabel={(option) => option.nome_materia || ''}
                                getOptionSelected={(option, value) => option.nome_materia === value.nome_materia}
                                value={nomesMaterias.find((opt) => opt.nome_materia === demanda.nome_materia) || { nome_materia: demanda.nome_materia }}
                                onInputChange={handleNomeMateriaChange}
                                onChange={handleNomeMateriaChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Selecionar Produto"
                                        variant="outlined"
                                        className={classes.formField}
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>}

                    <Autocomplete
                        options={culturas}
                        getOptionLabel={(option) => option.nome_cultura}
                        onChange={(event, newValue) => handleAutocompleteChangeCultura(event, newValue, 'id_cultura')}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Selecionar Cultura"
                                variant="outlined"
                                className={classes.formField}
                                size="small"
                            />
                        )}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                label="Quantidade (tons)"
                                name="quantidade"
                                variant="outlined"
                                className={classes.formField}
                                value={demanda.quantidade}
                                onChange={handleInputChange}
                                size="small"
                                fullWidth
                                type="number"

                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={anosSafra}
                                getOptionLabel={(option) => option.descricao}
                                onChange={(event, newValue) => handleAutocompleteChangeAnoSafra(event, newValue, 'id_ano_safra')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Selecionar Ano Safra"
                                        variant="outlined"
                                        className={classes.formField}
                                        size="small"
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={['Aberto', 'Fechado']}
                                getOptionLabel={(option) => option}
                                value={demanda.status}
                                onChange={handleStatusChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Status"
                                        name="status"
                                        variant="outlined"
                                        className={classes.formField}
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Preço Total Pago"
                                name="preco_total_pago"
                                variant="outlined"
                                className={classes.formField}
                                value={demanda.preco_total_pago}
                                onChange={handleInputChange}
                                size="small"
                                fullWidth
                                type="number"

                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Cotação Dólar (data compra)"
                                name="cotacao_dolar"
                                variant="outlined"
                                className={classes.formField}
                                value={demanda.cotacao_dolar}
                                onChange={handleInputChange}
                                size="small"
                                fullWidth
                                type="number"

                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField

                                variant="outlined"
                                className={classes.formField}
                                value={demanda.tipo_compra}
                                label="Tipo Compra"
                                name="tipo_compra"
                                onChange={handleInputChange}

                                size="small"
                                fullWidth

                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="Fornecedor"
                                name="fornecedor"
                                variant="outlined"
                                className={classes.formField}
                                value={demanda.fornecedor}
                                onChange={handleInputChange}
                                size="small"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="Data de Entrega"
                                name="data_entrega"
                                type="date"
                                variant="outlined"
                                className={classes.formField}
                                value={demanda.data_entrega}
                                onChange={handleInputChange}
                                size="small"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Data de Abertura da Demanda"
                                name="data_abertura"
                                type="date"
                                variant="outlined"
                                className={classes.formField}
                                value={demanda.data_abertura}
                                onChange={handleInputChange}
                                size="small"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Observações"
                            name="obs"
                            variant="outlined"
                            className={classes.formField}
                            value={demanda.obs}
                            onChange={handleInputChange}
                            size="small"
                            fullWidth
                            multiline
                            rows={4} // You can adjust the number of rows as needed
                        />
                    </Grid>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleSubmit}
                    >
                        Criar Demanda
                    </Button>
                </CardContent>
            </Card>
        </div>
    );
}

export default CadastroDemandas;
