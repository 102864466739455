import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getToken, checarPermissao, DataFormatoCerto, DataMaior } from '../../utils/utils'
import api_amr from '../../services/api_amr'
import Loader from 'react-loader-spinner';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Autocomplete } from "@material-ui/lab";

function ConfigFazendas(props) {
    const [permitido, setPermitido] = useState(false)
    const [farmArray, setFarmarray] = useState('')
    const [userFarms, setUserFarms] = useState('')
    const [selectedFarm, setSelectedFarm] = useState('')
    const [descriMaterial, setDescriMaterial] = useState('')
    const [dataInicio, setDataInicio] = useState(new Date())
    const [dataFinal, setDataFinal] = useState(new Date())
    const [farmsDemand, setFarmsDemand] = useState('')
    const [abrirNegocio, setAbrirNegocio] = useState(false)
    const [popuparqDemand, setPopupArqDemand] = useState(false)
    const [farmDemandId, setFarmDemandId] = useState('')
    const [culturas, setCulturas] = useState('')
    const [cultura, setCultura] = useState('')
   
    const [hectares, setHectares] = useState(0)
   
    const [ano, setAno] = useState(0)
    const [nomeProdutoLista, setNomeProdutoLista] = useState(0)
    const [idProduto, setIdProduto] = useState(null)

    const [datafechamento, setDatafechamento] = useState('')
    const [empresa, setEmpresa] = useState('')
    const [valorcompra, setValorcompra] = useState(0)

    const classes = useStyles();

    useEffect(() => {
        async function fetchMyAPI() {
            setPermitido(await checarPermissao('configuracoes_fazendas', props))

            const reponse_farm = await api_amr.get('/return_farms', { params: { 'all': 1 }, headers: { 'x-api-key': getToken() } });
            setUserFarms(reponse_farm.data);
            setFarmarray(Array.from({ length: reponse_farm.data.length }, i => i = false))

            const response_culturas = await api_amr.get('/return_culturas', { headers: { 'x-api-key': getToken() } });
            setCulturas(response_culturas.data)
            // Retorna os produtos que ja tem um npk associado
            const reponse_produtos_lista = await api_amr.get('/return_produtos_padrao', { params: { 'all': 1 },headers: { 'x-api-key': getToken() } });
            setNomeProdutoLista(reponse_produtos_lista.data)

        }
        fetchMyAPI();

    }, []);

    async function alterSelected(index, estadoBotao) {

        let aux = Array.from({ length: farmArray.length }, i => i = false)
        aux[index] = estadoBotao
        setFarmarray(JSON.parse(JSON.stringify(aux)))
        if (estadoBotao === true) {
            setSelectedFarm(index)
            getDemandas(index)

        } else {
            setSelectedFarm('')
            setFarmsDemand('')
        }
    }


    async function getDemandas(index = selectedFarm) {

        const response_demanda_fazendas = await api_amr.get('/return_farms_demands', { params: { 'id_farm': userFarms[index].id, 'all': 0 }, headers: { 'x-api-key': getToken() } })
        setFarmsDemand(response_demanda_fazendas.data)
    }



    async function postDemanda() {

        if ((idProduto === '') | (dataInicio === '') | (dataFinal === '')) {
            alert('Alguns campos obrigatórios estão incompletos')
            return
        }
        if (DataMaior(dataInicio,dataFinal)){
            alert('Data inicial nao pode ser maior que data final')
            return
         }
        const response_create_demanda = await api_amr.post('/insert_farm_demands', {
            'id_farm': userFarms[selectedFarm].id, 'nome_cultura': cultura, 'ano': ano, 'nitrogenio': idProduto.nitrogenio, 'fosforo': idProduto.fosforo, 'potassio': idProduto.potassio, 'toneladas_hectare': hectares,
            'id_produto': idProduto.id, 'dataInicio': dataInicio, 'dataFinal': dataFinal
        }, { headers: { 'x-api-key': getToken() } })


        if (response_create_demanda.data === 'OK') {
            alert('Demanda adicionada com sucesso')
            getDemandas()
            ResetStatesDemandas()
            return
        }
        else {
            alert('Erro ao adicionar demanda')
            return
        }
    }




    async function RemoverDemanda(id_demanda) {
        const response_edit_demanda = await api_amr.post('/edit_farm_demands', {
            'id_demanda': id_demanda, 'update_delete': 'delete'
        }, { headers: { 'x-api-key': getToken() } })



        if (response_edit_demanda.data === 'OK') {
            alert('Demanda removida com sucesso')
            getDemandas()
            return
        }
        else {
            alert('Erro ao remover demanda')
            return
        }

    }

    async function arquivarDemanda(id_demanda) {

        if (((empresa === '') && (farmsDemand[farmDemandId].empresa === '')) | ((farmsDemand[farmDemandId].valor_compra === 0) && (valorcompra === 0)) | ((farmsDemand[farmDemandId].data_fechamento === '') && (datafechamento === ''))) {
            alert('Alguns campos obrigatórios estão incompletos\n' + ((empresa === '') ? 'Empresa\n' : '')
                + ((valorcompra === 0) ? 'Valor da Compra\n' : '') + ((datafechamento === '') ? 'Data Fechamento\n' : ''))
            return
        }

        const response_edit_demanda = await api_amr.post('/edit_farm_demands', {
            'id_demanda': id_demanda, 'update_delete': 'arquivar', 'demanda': '1',
            'empresa': (empresa !== '' ? empresa : farmsDemand[farmDemandId].empresa),
            'valor': (valorcompra !== 0 ? valorcompra : farmsDemand[farmDemandId].valor_compra),
            'datafechamento': (datafechamento !== '' ? datafechamento : DataFormatoCerto(farmsDemand[farmDemandId].data_fechamento))
        }, { headers: { 'x-api-key': getToken() } })



        if (response_edit_demanda.data === 'OK') {
            alert('Demanda arquivada com sucesso')
            getDemandas()
            setEmpresa('')
            setValorcompra(0)
            setDatafechamento('')
            setFarmDemandId(0)
            return
        }
        else {
            alert('Erro ao arquivar demanda')
            return
        }
    }
    const ResetStatesDemandas = () => {
        setCultura('')
        setHectares(0)
        setIdProduto(null)
        setAno('')        
        setDataInicio('')
        setDataFinal('')
    };



    async function desarquivarDemanda(id_demanda, item) {

        const response_edit_demanda = await api_amr.post('/edit_farm_demands', {
            'id_demanda': id_demanda, 'update_delete': 'desarquivar', 'demanda': '0'
        }, { headers: { 'x-api-key': getToken() } })



        if (response_edit_demanda.data === 'OK') {
            alert('Demanda desarquivada com sucesso')
            getDemandas()
            setFarmDemandId(0)
            return
        }
        else {
            alert('Erro ao desarquivar demanda')
            return
        }
    }

    const handleClickOpen = () => {
        setAbrirNegocio(true);
    };
    const handleClickOpen2 = () => {
        setPopupArqDemand(true);
    };

    const handleClickClose = () => {
        setAbrirNegocio(false);
    };
    const handleClickClose2 = () => {
        setPopupArqDemand(false);
    };

    function setIdOpenConfirmation(item) {
        setFarmDemandId(item)
        handleClickOpen()
    }
    function setIdOpenConfirmation2(item) {
        setFarmDemandId(item)

        handleClickOpen2()
    }



    return (
        permitido ?
            <div className={classes.container}  >
                <div >
                    <h2 className={classes.inputh1}>Lista de fazendas</h2>


                    <div >
                        {userFarms !== '' ? <TableContainer className={classes.tableModels}>

                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={50}>

                                        </TableCell>
                                        <TableCell width={50}>
                                        <b className={classes.inputh1}>Id</b>
                                        </TableCell>
                                        <TableCell width={250}>
                                        <b className={classes.inputh1}>Nome da Fazenda</b>
                                        </TableCell>
                                        <TableCell width={250}>
                                        <b className={classes.inputh1}>Inscrição Estadual</b>
                                        </TableCell>
                                        <TableCell width={250}>
                                        <b className={classes.inputh1}>Cidade - Estado</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {Object.keys(userFarms).map((item, index) => (

                                        <TableRow key={index} style={{ backgroundColor: farmArray[index] === true ? '#e9f5d6' : 'white' }} >
                                            <TableCell>

                                                {farmArray[index] === true ? <IconButton
                                                    style={{ color: '#A3CC51' }}
                                                    aria-label="toggle password visibility"
                                                    onClick={() => alterSelected(index, false)}
                                                >
                                                    < CheckCircleOutlineIcon />
                                                </IconButton> : <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => alterSelected(index, true)}

                                                >
                                                    <RadioButtonUncheckedIcon />
                                                </IconButton>}
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{userFarms[item].id}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{userFarms[item].farm_name}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{userFarms[item].inscr_estadual}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{userFarms[item].cidade + ' - ' + userFarms[item].UF}</b>
                                            </TableCell>


                                        </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer> : <CircularProgress />}
                    </div>

                    <div style={{ height: 50 }}>
                    </div>

                    <div style={{ height: 40 }}>
                        <h2 className={classes.inputh1}>Cadastrar Demanda</h2>
                    </div>

                </div >

                <div>
                    {selectedFarm !== '' ?
                        < div >
                            <div style={{ display: 'flex', flexDirection: 'row' }} >

                                <Autocomplete

                                    options={nomeProdutoLista}
                                    getOptionLabel={(option) => (option.id + ' - ' + option.nome_produto)}
                                    className={classes.autoCompleteFieldBig}                                                                        
                                    value={idProduto}
                                    onChange={(e, v) => setIdProduto(v)}

                                    classes={{
                                        input: classes.input,

                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label="Selecionar Produto"
                                        variant='outlined'

                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />}
                                />
                                {culturas ? <Autocomplete

                                    options={culturas}
                                    getOptionLabel={(option) => option.cultura}
                                    className={classes.autoCompleteField}
                                    inputValue={cultura}
                                    onInputChange={(e, v) => setCultura(v?.cultura || v)}
                                    classes={{
                                        input: classes.input,
                                    }}
                                    style={{ marginLeft: 15 }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label="Selecionar Cultura"
                                        variant='outlined'
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />}
                                /> : <CircularProgress />}
                                <Autocomplete

                                    options={['2022', '2023', '2024', '2025']}
                                    getOptionLabel={(option) => option}
                                    className={classes.autoCompleteField}
                                    inputValue={ano}
                                    onInputChange={(e, v) => setAno(v?.ano || v)}
                                    classes={{
                                        input: classes.input,

                                    }}
                                    style={{ marginLeft: 15 }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label="Selecionar Ano da Safra"
                                        variant='outlined'
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />}
                                />
                                <TextField
                                    key='Hectares'
                                    label="Toneladas por Hectares"
                                    variant='outlined'
                                    type='number'
                                    onChange={(event) => setHectares(event.target.value<0 ? 0:event.target.value)}
                                    value={hectares}
                                    style={{ marginLeft: 15 }}
                                    className={classes.autoCompleteField}
                                    InputProps={{
                                        disableUnderline: true,
                                        inputProps: { min: 0 },
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }} >


                                <TextField
                                    id="filled-read-only-input"
                                    label="Nitrogênio"
                                    variant='outlined'
                                    type='number'                                    
                                    value={idProduto!=null? idProduto.nitrogenio:''}
                                    className={classes.autoCompleteField}
                                    InputProps={{                                       
                                        readOnly: true,
                                        inputProps: { min: 0 },
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                     }}
                                />
                                <TextField
                                    key='p2o5'
                                    label="Fósforo"
                                    variant='outlined'
                                    type='number'
                                    
                                    value={idProduto!=null? idProduto.fosforo:''}
                                    style={{ marginLeft: 15 }}
                                    className={classes.autoCompleteField}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: { min: 0 },
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                     }}
                                />
                                <TextField
                                    key='k2o'
                                    label="Potássio"
                                    variant='outlined'
                                    type='number'
                                    
                                    value={idProduto!=null? idProduto.potassio:''}
                                    style={{ marginLeft: 15 }}
                                    className={classes.autoCompleteField}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: { min: 0 },
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                     }}
                                />
                            </div>
                            <TextField
                                type="date"
                                key='Data de início'
                                label="Data de início"
                                variant='outlined'
                                onChange={(event) => setDataInicio(event.target.value)}
                                value={dataInicio}

                                className={classes.autoCompleteField}

                                InputProps={{
                                    disableUnderline: true,
                                    classes: { notchedOutline: classes.noBorder },

                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                type="date"
                                key='Data final'
                                label="Data Final"
                                variant='outlined'
                                onChange={(event) => setDataFinal(event.target.value)}
                                value={dataFinal}
                                className={classes.autoCompleteField}
                                style={{ marginLeft: 15 }}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: { notchedOutline: classes.noBorder },

                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <div className={classes.calcDiv}>
                                <Button onClick={() => postDemanda()} variant="contained" className={classes.calcButton}> Cadastrar</Button>
                            </div>
                        </div>



                        : <CircularProgress />}


                </div>

                <div style={{ height: 10 }}>
                </div>

                <div style={{ height: 60 }}>
                    <h2 className={classes.inputh1}> Demandas</h2>
                </div>
                {farmsDemand !== '' ? <TableContainer className={classes.tableModels}>

                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell width={50}>
                                <b className={classes.inputh1}>Id</b>
                                </TableCell>
                                <TableCell width={100}>
                                <b className={classes.inputh1}>Cultura</b>
                                </TableCell>
                                <TableCell width={100}>
                                <b className={classes.inputh1}>Ano</b>
                                </TableCell>
                                <TableCell width={100}>
                                <b className={classes.inputh1}>Produto</b>
                                </TableCell>
                                <TableCell width={100}>
                                <b className={classes.inputh1}>Toneladas por Hectare</b>
                                </TableCell>
                                <TableCell width={100}>
                                <b className={classes.inputh1}>Nitrogênio</b>
                                </TableCell>
                                <TableCell width={100}>
                                <b className={classes.inputh1}>Fósforo</b>
                                </TableCell>
                                <TableCell width={100}>
                                <b className={classes.inputh1}>Potássio</b>
                                </TableCell>
                                <TableCell width={100}>
                                <b className={classes.inputh1}>Data de Início</b>
                                </TableCell>
                                <TableCell width={100}>
                                <b className={classes.inputh1}>Data Final</b>
                                </TableCell>
                                <TableCell width={100}>
                                <b className={classes.inputh1}>Arquivar</b>
                                </TableCell>
                                <TableCell width={100}>
                                <b className={classes.inputh1}>Remover</b>
                                </TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {Object.keys(farmsDemand).map((item, index) => (
                                farmsDemand[item].arquivado === 0 ?
                                    <TableRow key={index}>
                                        <TableCell>
                                        <b className={classes.inputh1}>{farmsDemand[item].id_demand}</b>

                                        </TableCell>
                                        <TableCell>
                                        <b className={classes.inputh1}>{farmsDemand[item].nome_cultura}</b>
                                        </TableCell>
                                        <TableCell>
                                        <b className={classes.inputh1}>{farmsDemand[item].ano}</b>
                                        </TableCell>
                                        <TableCell>
                                        <b className={classes.inputh1}>{farmsDemand[item].nome_produto}</b>
                                        </TableCell>
                                        <TableCell>
                                        <b className={classes.inputh1}>{farmsDemand[item].toneladas_hectare}</b>
                                        </TableCell>
                                        <TableCell>
                                        <b className={classes.inputh1}>{farmsDemand[item].nitrogenio}</b>
                                        </TableCell>
                                        <TableCell>
                                        <b className={classes.inputh1}>{farmsDemand[item].fosforo}</b>
                                        </TableCell>
                                        <TableCell>
                                        <b className={classes.inputh1}>{farmsDemand[item].potassio}</b>
                                        </TableCell>
                                        <TableCell>
                                        <b className={classes.inputh1}>{new Date(farmsDemand[item].data_inicio).toLocaleDateString("pt-BR", { timeZone: 'UTC' })}</b>
                                        </TableCell>
                                        <TableCell>
                                        <b className={classes.inputh1}>{new Date(farmsDemand[item].data_fim).toLocaleDateString("pt-BR", { timeZone: 'UTC' })}</b>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => ((setIdOpenConfirmation2(item)))}> < ArchiveIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => (setIdOpenConfirmation(farmsDemand[item].id_demand),console.log(farmsDemand[item].id_demand))}> < DeleteIcon />
                                            </IconButton>
                                        </TableCell>

                                    </TableRow> : null))
                            }</TableBody>

                    </Table>


                </TableContainer> : <CircularProgress />}



                <div style={{ height: 60 }}>
                    <h2 className={classes.inputh1}> Demandas Arquivadas</h2>
                </div>

                {
                    farmsDemand !== '' ? <TableContainer className={classes.tableModels}>

                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={10}>
                                    <b className={classes.inputh1}>Id</b>
                                    </TableCell>
                                    <TableCell width={50}>
                                    <b className={classes.inputh1}>Ano</b>
                                    </TableCell>
                                    <TableCell width={150}>
                                    <b className={classes.inputh1}>Descrição do Material</b>
                                    </TableCell>
                                    <TableCell width={150}>
                                    <b className={classes.inputh1}>Empresas</b>
                                    </TableCell>
                                    <TableCell width={150}>
                                    <b className={classes.inputh1}> Valor compra</b>
                                    </TableCell>
                                    <TableCell width={100}>
                                    <b className={classes.inputh1}>Data de Início</b>
                                    </TableCell>
                                    <TableCell width={100}>
                                    <b className={classes.inputh1}>Data Final</b>
                                    </TableCell>
                                    <TableCell width={100}>
                                    <b className={classes.inputh1}>Data Fechamento</b>
                                    </TableCell>
                                    <TableCell width={50}>
                                    <b className={classes.inputh1}>Desarquivar</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>


                            <TableBody>
                                {Object.keys(farmsDemand).map((item, index) => (
                                    farmsDemand[item].arquivado === 1 ?
                                        <TableRow key={index}>


                                            <TableCell>
                                            <b className={classes.inputh1}>{farmsDemand[item].id_demand}</b>

                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{farmsDemand[item].ano}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}> {farmsDemand[item].nome_produto}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{farmsDemand[item].empresa}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{farmsDemand[item].valor_compra}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}> {new Date(farmsDemand[item].data_inicio).toLocaleDateString("pt-BR", { timeZone: 'UTC' })}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}>{new Date(farmsDemand[item].data_fim).toLocaleDateString("pt-BR", { timeZone: 'UTC' })}</b>
                                            </TableCell>
                                            <TableCell>
                                            <b className={classes.inputh1}> {new Date(farmsDemand[item].data_fechamento).toLocaleDateString("pt-BR", { timeZone: 'UTC' })}</b>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => (desarquivarDemanda(farmsDemand[item].id_demand, item))}> <UnarchiveIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow> : null))
                                }</TableBody>



                        </Table>
                    </TableContainer> : <CircularProgress />
                }



                <div>



                    {abrirNegocio !== false ?
                        <div>

                            <Dialog open={abrirNegocio} onClose={handleClickClose} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">
                                <b className={classes.inputh1}>Remover Demanda</b>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                    <b className={classes.inputh1}>Você esta removendo uma demanda, deseja prosseguir?</b>
                                    </DialogContentText>


                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={(handleClickClose)} className={classes.calcButton} style={{ marginRight: 20 }}>
                                        Cancelar
                                    </Button>

                                    <Button onClick={() => (RemoverDemanda(farmDemandId), handleClickClose())} className={classes.calcButton} style={{ marginLeft: 20 }}>
                                        Confirmar
                                    </Button>

                                </DialogActions>
                            </Dialog>
                        </div> : null}
                    {popuparqDemand !== false ?
                        <div>

                            <Dialog open={popuparqDemand} onClose={handleClickClose2} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title"><b className={classes.inputh1}>Insira os dados para arquivar a Demanda</b></DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {console.log(farmDemandId)}

                                            <TextField
                                                key='Empresa'
                                                variant='outlined'
                                                label='Empresa'
                                                defaultValue={farmsDemand[farmDemandId].empresa}
                                                className={classes.autoCompleteFieldBig}
                                                onChange={(event) => setEmpresa(event.target.value)}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: { notchedOutline: classes.noBorder }
                                                }}


                                            />
                                            <TextField
                                                key='Valorcompra'
                                                type='number'
                                                label='Valor Compra'
                                                variant='outlined'
                                                defaultValue={farmsDemand[farmDemandId].valor_compra}
                                                value={valorcompra}
                                                className={classes.autoCompleteFieldBig}
                                                onChange={(event) => setValorcompra(event.target.value < 0 ? 0 : event.target.value)}
                                                
                                                InputProps={{
                                                    min:0,
                                                    step: 0.01,
                                                    disableUnderline: true,
                                                    classes: { notchedOutline: classes.noBorder }
                                                }}


                                            />
                                            <TextField
                                                type="date"
                                                key='Data de Fechamento'
                                                label="Data de Fechamento"
                                                variant='outlined'
                                                format='dd/MM/yyyy'
                                                defaultValue={DataFormatoCerto(farmsDemand[farmDemandId].data_fechamento)}
                                                onChange={(event) => setDatafechamento(event.target.value)}
                                                // value={datafechamento}

                                                className={classes.autoCompleteFieldBig}

                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: { notchedOutline: classes.noBorder },

                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </div>
                                    </DialogContentText>


                                </DialogContent>
                                <DialogActions style={{ justifyContent: 'center' }} >
                                    <Button onClick={(handleClickClose2)} className={classes.calcButton} style={{ marginRight: 20 }}>
                                        Cancelar
                                    </Button>

                                    <Button onClick={() => (arquivarDemanda(farmsDemand[farmDemandId].id_demand), handleClickClose2())} className={classes.calcButton} style={{ marginLeft: 20 }}>
                                        Confirmar
                                    </Button>

                                </DialogActions>
                            </Dialog>
                        </div> : null}
                </div>








            </div > : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 100,
    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    noBorder: {
        border: "none",
    },
    autoCompleteFieldSmall: {
        marginTop: 15,
        minWidth: 104,
        maxWidth: 104,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteField: {
        marginTop: 15,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 15,
        width: 471,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    inputCheckbox: {

        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000'
    },
    inputh1: {
        fontFamily: 'Poppins',

        color: '#383838'
    },
    root: {
        "&$checked": {
            color: "black",
            backgroundColor: 'white'
        }
    },
    checked: {
        borderColor: 'black'
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
    calcDiv: {
        marginTop: 40,
        width: '146px',
        marginLeft: 325,
        height: 45
    },
    nutriDiv: {
        paddingRight: 28,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 228
    },
    inputForm: {
        width: '50%',
        fontFamily: 'Poppins',
        color: '#383838'
    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    }, table: {
        minWidth: 400,
        maxWidth: '100%'
    },
    rowSize: {
        maxWidth: 500
    },
    tableModels: {
        marginTop: 20,
        maxWidth: '90%',
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
}));

export default ConfigFazendas;
