import React, { memo } from "react";
import HistPrecoTabela from '../HistPreco/HistPrecoTabela';
import RelTrocaTabela from '../RelTroca/RelTrocaTabela';
import HistPrecoCard from '../HistPreco/HistPrecoCard';
import RelTrocaCard from '../RelTroca/RelTrocaCard';
import HistPrecoGrafico from '../HistPreco/HistPrecoGrafico';
import RelTrocaGrafico from '../RelTroca/RelTrocaGrafico';
import DolarComponent from '../Outros/DolarComponent';
import NotesComponent from '../Outros/NotesComponent';
import PrevisaoComponent from "../Outros/PrevisaoComponent";
import PortosMapaComponent from "../Outros/PortosMapaComponent";
import TabelaFuturosComponent from "../Futuros/TabelaFuturosComponent";
import GraficoFuturosComponent from "../Futuros/GraficoFuturosComponent";
import MinhasFormulacoesTabelaEditavel from '../Outros/MinhasFormulacoesTabelaEditavel';
import ImageComponent from "../Outros/ImageComponent";

const RelTrocaTabelaMemo = memo(RelTrocaTabela)
const RelTrocaGraficoMemo = memo(RelTrocaGrafico)
const RelTrocaCardMemo = memo(RelTrocaCard)
const HistPrecoTabelaMemo = memo(HistPrecoTabela)
const HistPrecoGraficoMemo = memo(HistPrecoGrafico)
const HistPrecoCardMemo = memo(HistPrecoCard)
const DolarComponentMemo = memo(DolarComponent)
const NotesComponentMemo = memo(NotesComponent)
const PrevisaoComponentMemo = memo(PrevisaoComponent)
const PortosMapaComponentMemo = memo(PortosMapaComponent)
const TabelaFuturosComponentMemo = memo(TabelaFuturosComponent)
const GraficoFuturosComponentMemo = memo(GraficoFuturosComponent)
const MinhasFormulacoesTabelaEditavelMemo = memo(MinhasFormulacoesTabelaEditavel)
const ImageComponentMemo = memo(ImageComponent)

export default function Preview({ selectedComponent, selectedData, selectedParams }) {
    // função que valida se o usuário completou os parametros para renderizar o preview do componente
    function shouldRender(selectedComponent, selectedData, selectedParams) {
        switch (selectedComponent) {
            case "tabela":
                switch (selectedData) {
                    case "historico_preco":
                        return !!selectedParams.porto;
                    case "relacao_troca":
                        return !!(selectedParams.id_materia && selectedParams.porto && selectedParams.cultura);
                    case "futuros":
                        return !!(selectedParams.contratos && selectedParams.tabela)
                    case "formulas_salvas":
                        return true
                    default:
                        return false;
                }

            case "grafico":
                switch (selectedData) {
                    case "historico_preco":
                        return !!(selectedParams.id_materia && selectedParams.porto)
                    case "relacao_troca":
                        return !!(selectedParams.id_materia && selectedParams.porto && selectedParams.cultura);
                    case "futuros":
                        return !!(selectedParams.contratos && selectedParams.tabela)
                    default:
                        return false
                }
            case "card":
                switch (selectedData) {
                    case "historico_preco":
                        return !!(selectedParams.id_materia && selectedParams.porto);
                    case "relacao_troca":
                        return !!(selectedParams.id_materia && selectedParams.porto && selectedParams.cultura);
                    case "dolar":
                        return true
                    default:
                        return false;
                }

            case "note":
                return !!selectedParams.text

            case "previsao":
                return !!(selectedParams.regiao && selectedParams.periodo);
            case "imagem":
                return !!selectedParams.link
            case "mapa":
                return true

            case "imagem":

                return !!selectedParams.link

            default:
                return false
        }
    }

    const renderComponent = () => {
        if (!shouldRender(selectedComponent, selectedData, selectedParams)) return null;

        switch (selectedComponent) {
            case "tabela":
                switch (selectedData) {
                    case "relacao_troca":
                        return <RelTrocaTabelaMemo parametros={selectedParams} />;
                    case "historico_preco":
                        return <HistPrecoTabelaMemo parametros={selectedParams} />;
                    case "futuros":
                        return <TabelaFuturosComponentMemo parametros={selectedParams} />
                    case "formulas_salvas":
                        return <MinhasFormulacoesTabelaEditavelMemo />
                    default:
                        return null;
                }

            case "grafico":
                switch (selectedData) {
                    case "relacao_troca":
                        return <RelTrocaGraficoMemo parametros={selectedParams} />;
                    case "historico_preco":
                        return <HistPrecoGraficoMemo parametros={selectedParams} />;
                    case "futuros":
                        return <GraficoFuturosComponentMemo parametros={selectedParams} />;
                    default:
                        return null;
                }

            case "card":
                switch (selectedData) {
                    case "relacao_troca":
                        return <RelTrocaCardMemo parametros={selectedParams} />;
                    case "historico_preco":
                        return <HistPrecoCardMemo parametros={selectedParams} />;
                    case "dolar":
                        return <DolarComponentMemo />;
                    default:
                        return null;
                }

            case "note":
                return <NotesComponentMemo parametros={selectedParams} />;

            case "previsao":
                return <PrevisaoComponentMemo parametros={selectedParams} />;

            case "mapa":

                return <PortosMapaComponentMemo parametros={selectedParams} />

            case "imagem":

                return <ImageComponentMemo parametros={selectedParams} />

            default:
                return null;
        }
    }

    return (
        <div>
            {renderComponent()}
        </div>
    )
}