import React, { useState, useEffect } from 'react';
import api_amr from '../../services/api_amr';
import { getToken } from '../../utils/utils';
import {
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    makeStyles,
    CircularProgress,
    Grid,
    Button,
    Switch,
    FormControlLabel,
    TextField,
    Card,
    CardContent,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';

const SumarizacaoDemandas = () => {
    const classes = useStyles();
    const [demandas, setDemandas] = useState(null);
    const [status, setStatus] = useState('TODOS');
    const [culturas, setCulturas] = useState([]);
    const [anosSafra, setAnosSafra] = useState([]);
    const [portosList, setPortosList] = useState([]);

    const [selectedCultura, setSelectedCultura] = useState(null);
    const [selectedAnoSafra, setSelectedAnoSafra] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [viewMode, setViewMode] = useState('associados'); // 'associados' ou 'produtos'
    const statusOptions = ['TODOS', 'Aberto', 'Fechado'];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [responseCulturas, responseAnosSafra, responsePortos] = await Promise.all([
                    api_amr.get('/get_culturas', { headers: { 'x-api-key': getToken() } }),
                    api_amr.get('/read_anos_safra', { headers: { 'x-api-key': getToken() } }),
                    api_amr.get('/return_portos', { headers: { 'x-api-key': getToken() } }),
                ]);
                setCulturas(responseCulturas.data);
                setAnosSafra(responseAnosSafra.data);
                setPortosList(responsePortos.data);
            } catch (error) {
                console.error('Erro ao buscar dados', error);
                setError('Erro ao buscar dados');
            }
        };
        fetchData();
    }, []);

    const fetchDemandas = async () => {
        if (!status || !selectedCultura || !selectedAnoSafra) {
            setError('Por favor, preencha todos os campos.');
            return;
        }

        setLoading(true);
        setError(null);
        try {
            const response = await api_amr.get('/get_sumarizacao_demandas', {
                headers: {
                    'x-api-key': getToken(),
                },
                params: {
                    status: status,
                    cultura: selectedCultura ? selectedCultura.id_cultura : null,
                    ano_safra: selectedAnoSafra ? selectedAnoSafra.id_ano_safra : null,
                    viewMode: viewMode,
                },
            });

            if (response.data === 'NOK') {
                throw new Error('Erro ao buscar demandas sumarizadas');
            }
            if (response.data === 'NF') {
                alert('Nenhuma demanda encontrada para os dados inseridos.');
                return;
            }
            setDemandas(response.data);
        } catch (error) {
            console.error(error);
            setError('Erro ao buscar demandas sumarizadas');
        } finally {
            setLoading(false);
        }
    };

    const formatNumber = (value) => {
        if (value == null || isNaN(value)) return '-';
    
        // Arredonda para o inteiro mais próximo
        const roundedValue = Math.round(value);
    
        // Formata com separador de milhar
        return roundedValue.toLocaleString('pt-BR');
    };
    

    const isButtonDisabled = !status || !selectedCultura || !selectedAnoSafra;

    const renderPortosTableHead = () => {
        return portosList.map((porto, index) => (
            <TableCell
                key={porto.id}
                className={classes.tableCellHeader}
                padding="none"
                style={index === portosList.length - 1 ? { borderRight: 'none' } : {}}
            >
                {porto.nome_porto}
            </TableCell>
        ));
    };

    // Modificar renderTotals para mostrar o total como uma coluna no final
    const renderTotals = () => {
        if (loading) {
            return (
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    <CircularProgress size={16} />
                </div>
            );
        }

        if (viewMode === 'associados' && demandas?.total_geral_per_associado) {
            // Ensure totalItem is an array
            const totalItems = Array.isArray(demandas.total_geral_per_associado)
                ? demandas.total_geral_per_associado
                : [demandas.total_geral_per_associado];

            return totalItems.map((totalItem, idx) => {
                // Initialize mapping of quantities per port
                const portoTotals = {};
                portosList.forEach((porto) => {
                    portoTotals[porto.id] = '-';
                });

                // Fill quantities
                totalItem.portos.forEach((porto) => {
                    portoTotals[porto.id_porto] = porto.total;
                });

                return (
                    <Card key={`total_geral_per_associado-${idx}`} className={classes.card} style={{ background: '#d1cfcf' }}>
                        <CardContent className={classes.cardContent}>
                            <Typography variant="body2" className={classes.typographyHeader}>
                                Total
                            </Typography>
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCellHeader}
                                                padding="none"
                                                style={{ borderRight: '1px solid #ddd', width: "7%" }}
                                            >
                                                Porto
                                            </TableCell>
                                            {renderPortosTableHead()}
                                            <TableCell className={classes.tableCellHeader} padding="none">
                                                Total
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Totals Row */}
                                        <TableRow className={classes.totalRow}>
                                            <TableCell
                                                className={classes.totalCellCombo}
                                                padding="none"
                                                style={{ borderRight: '1px solid #ddd', width: "7%" }}
                                            >
                                                Total
                                            </TableCell>
                                            {portosList.map((porto, indexPorto) => (
                                                <TableCell
                                                    key={`total-${porto.id}`}
                                                    className={`${classes.tableCellBody} ${classes.totalCell}`}
                                                    padding="none"
                                                    style={
                                                        indexPorto === portosList.length - 1 ? { borderRight: 'none' } : {}
                                                    }
                                                >
                                                    {formatNumber(portoTotals[porto.id]) || '-'}
                                                </TableCell>
                                            ))}
                                            <TableCell
                                                className={`${classes.tableCellBody} ${classes.totalCell}`}
                                                padding="none"
                                            >
                                                {formatNumber(totalItem.total_geral)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                );
            });
        } else if (viewMode === 'produtos' && demandas?.total_geral_per_produto) {
            // Ensure totalItem is an array
            const totalItems = Array.isArray(demandas.total_geral_per_produto)
                ? demandas.total_geral_per_produto
                : [demandas.total_geral_per_produto];

            return totalItems.map((totalItem, idx) => {
                // Initialize mapping of quantities per port
                const portoTotals = {};
                portosList.forEach((porto) => {
                    portoTotals[porto.id] = '-';
                });

                // Fill quantities
                totalItem.portos.forEach((porto) => {
                    portoTotals[porto.id_porto] = porto.total;
                });

                return (
                    <Card key={`total_geral_per_produto-${idx}`} className={classes.card} style={{ background: '#d1cfcf' }}>
                        <CardContent className={classes.cardContent}>
                            <Typography variant="body2" className={classes.typographyHeader}>
                                Total
                            </Typography>
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCellHeader}
                                                padding="none"
                                                style={{ borderRight: '1px solid #ddd', width: "7%"}}
                                            >
                                                Porto
                                            </TableCell>
                                            {renderPortosTableHead()}
                                            <TableCell className={classes.tableCellHeader} padding="none">
                                                Total
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Totals Row */}
                                        <TableRow className={classes.totalRow}>
                                            <TableCell
                                                className={classes.totalCellCombo}
                                                padding="none"
                                                style={{ borderRight: '1px solid #ddd', width: "7%"}}
                                            >
                                                Total
                                            </TableCell>
                                            {portosList.map((porto, indexPorto) => (
                                                <TableCell
                                                    key={`total-${porto.id}`}
                                                    className={`${classes.tableCellBody} ${classes.totalCell}`}
                                                    padding="none"
                                                    style={
                                                        indexPorto === portosList.length - 1 ? { borderRight: 'none' } : {}
                                                    }
                                                >
                                                    {formatNumber(portoTotals[porto.id]) || '-'}
                                                </TableCell>
                                            ))}
                                            <TableCell
                                                className={`${classes.tableCellBody} ${classes.totalCell}`}
                                                padding="none"
                                            >
                                                {formatNumber(totalItem.total_geral)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                );
            });
        }
        return null;
    };

    const renderSummary = () => {
        if (loading) {
            return (
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    <CircularProgress size={16} />
                </div>
            );
        }

        if (viewMode === 'associados' && demandas?.summary_per_associado) {
            return demandas.summary_per_associado.map((associado) => {
                // Construir conjunto de produtos únicos
                const produtos = associado.total_produtos;

                // Inicializar mapeamento de quantidades
                const produtoPortoQuantities = {};
                produtos.forEach((produto) => {
                    produtoPortoQuantities[produto.produto] = {};
                    portosList.forEach((porto) => {
                        produtoPortoQuantities[produto.produto][porto.id] = '-';
                    });
                });

                // Preencher quantidades
                associado.portos.forEach((porto) => {
                    porto.users.forEach((user) => {
                        user.produtos.forEach((produto) => {
                            produtoPortoQuantities[produto.produto][porto.id_porto] = produto.quantidade;
                        });
                    });
                });

                return (
                    <Card key={associado.associado} className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <Typography variant="body2" className={classes.typographyHeader}>
                                {associado.associado}
                            </Typography>
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCellHeader}
                                                padding="none"
                                                style={{ borderRight: '1px solid #ddd', width: "7%"}}
                                            >
                                                Produto
                                            </TableCell>
                                            {renderPortosTableHead()}
                                            <TableCell className={classes.tableCellHeader} padding="none">
                                                Total
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {produtos.map((produto, index) => (
                                            <TableRow
                                                key={produto.produto}
                                                className={index % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd}
                                            >
                                                <TableCell
                                                    className={classes.tableCellBody}
                                                    padding="none"
                                                    style={{ borderRight: '1px solid #ddd' }}
                                                >
                                                    {produto.produto}
                                                </TableCell>
                                                {portosList.map((porto, indexPorto) => (
                                                    <TableCell
                                                        key={`${produto.produto}-${porto.id}`}
                                                        className={classes.tableCellBody}
                                                        padding="none"
                                                        style={
                                                            indexPorto === portosList.length - 1
                                                                ? { borderRight: 'none' }
                                                                : {}
                                                        }
                                                    >
                                                        {formatNumber(produtoPortoQuantities[produto.produto][porto.id]) || '-'}
                                                    </TableCell>
                                                ))}
                                                <TableCell
                                                    className={`${classes.tableCellBody} ${classes.totalCell}`}
                                                    padding="none"
                                                >
                                                    {formatNumber(produto.total_quantidade)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {/* Linha de Totais */}
                                        <TableRow className={classes.totalRow}>
                                            <TableCell
                                                className={classes.totalCellCombo}
                                                padding="none"
                                                style={{ borderRight: '1px solid #ddd', width: "7%" }}
                                            >
                                                Total
                                            </TableCell>
                                            {portosList.map((porto, index) => (
                                                <TableCell
                                                    key={`total-${porto.id}`}
                                                    className={`${classes.tableCellBody} ${classes.totalCell}`}
                                                    padding="none"
                                                    style={
                                                        index === portosList.length - 1 ? { borderRight: 'none' } : {}
                                                    }
                                                >
                                                    {formatNumber(associado.portos.find((p) => p.id_porto === porto.id)?.quantidade) ||
                                                        '-'}
                                                </TableCell>
                                            ))}
                                            <TableCell
                                                className={`${classes.tableCellBody} ${classes.totalCell}`}
                                                padding="none"
                                            >
                                                {formatNumber(associado.total_quantidade)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                );
            });
        } else if (viewMode === 'produtos' && demandas?.summary_per_produto) {
            return demandas.summary_per_produto.map((produtoItem) => {
                // Construir conjunto de associados únicos
                const associadoSet = new Set();
                produtoItem.portos.forEach((porto) => {
                    porto.associados.forEach((associado) => {
                        associadoSet.add(associado.associado);
                    });
                });
                const associados = Array.from(associadoSet);

                // Inicializar mapeamento de quantidades
                const associadoPortoQuantities = {};
                associados.forEach((associado) => {
                    associadoPortoQuantities[associado] = {};
                    portosList.forEach((porto) => {
                        associadoPortoQuantities[associado][porto.id] = '-';
                    });
                });

                // Preencher quantidades
                produtoItem.portos.forEach((porto) => {
                    porto.associados.forEach((associado) => {
                        associadoPortoQuantities[associado.associado][porto.id_porto] = associado.quantidade;
                    });
                });

                // Obter totais por porto diretamente dos dados
                const portTotals = {};
                produtoItem.portos.forEach((porto) => {
                    portTotals[porto.id_porto] = porto.quantidade;
                });

                return (
                    <Card key={produtoItem.produto} className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <Typography variant="body2" className={classes.typographyHeader}>
                                {produtoItem.produto}
                            </Typography>                            
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCellHeader}
                                                padding="none"
                                                style={{ borderRight: '1px solid #ddd',width: "7%" }}
                                            >
                                                Associado
                                            </TableCell>
                                            {renderPortosTableHead()}
                                            <TableCell className={classes.tableCellHeader} padding="none">
                                                Total
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {associados.map((associado, index) => (
                                            <TableRow
                                                key={associado}
                                                className={index % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd}
                                            >
                                                <TableCell
                                                    className={classes.tableCellBody}
                                                    padding="none"
                                                    style={{ borderRight: '1px solid #ddd' }}
                                                >
                                                    {associado}
                                                </TableCell>
                                                {portosList.map((porto, indexPorto) => (
                                                    <TableCell
                                                        key={`${associado}-${porto.id}`}
                                                        className={classes.tableCellBody}
                                                        padding="none"
                                                        style={
                                                            indexPorto === portosList.length - 1
                                                                ? { borderRight: 'none' }
                                                                : {}
                                                        }
                                                    >
                                                        {formatNumber(associadoPortoQuantities[associado][porto.id]) || '-'}
                                                    </TableCell>
                                                ))}
                                                <TableCell
                                                    className={`${classes.tableCellBody} ${classes.totalCell}`}
                                                    padding="none"
                                                >
                                                    {formatNumber(produtoItem.portos.reduce((acc, porto) => {
                                                        const associadoData = porto.associados.find(
                                                            (a) => a.associado === associado
                                                        );
                                                        return acc + (associadoData ? associadoData.quantidade : 0);
                                                    }, 0))}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {/* Linha de Totais */}
                                        <TableRow className={classes.totalRow}>
                                            <TableCell
                                                className={classes.totalCellCombo}
                                                padding="none"
                                                style={{ borderRight: '1px solid #ddd', width: "7%" }}
                                            >
                                                Total
                                            </TableCell>
                                            {portosList.map((porto, index) => (
                                                <TableCell
                                                    key={`total-${porto.id}`}
                                                    className={`${classes.tableCellBody} ${classes.totalCell}`}
                                                    padding="none"
                                                    style={
                                                        index === portosList.length - 1 ? { borderRight: 'none' } : {}
                                                    }
                                                >
                                                    {formatNumber(portTotals[porto.id]) || '-'}
                                                </TableCell>
                                            ))}
                                            <TableCell
                                                className={`${classes.tableCellBody} ${classes.totalCell}`}
                                                padding="none"
                                            >
                                                {formatNumber(produtoItem.total_quantidade)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                );
            });
        }
        return null;
    };

    return (
        <Container className={classes.container} maxWidth={false}>
            <Typography variant="body2" component="h1" className={classes.title}>
                Sumarização das Demandas
            </Typography>

            {error && <Alert severity="error">{error}</Alert>}

            <Grid container spacing={1} className={classes.filterContainer}>
                <Grid item xs={12} sm={3}>
                    <Autocomplete
                        className={classes.autocomplete}
                        options={statusOptions}
                        value={status}
                        onChange={(event, newValue) => setStatus(newValue)}
                        renderInput={(params) => (
                            <TextField {...params} label="Status" variant="outlined" size="small" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Autocomplete
                        className={classes.autocomplete}
                        options={culturas}
                        getOptionLabel={(option) => option.nome_cultura}
                        value={selectedCultura}
                        onChange={(event, newValue) => setSelectedCultura(newValue)}
                        renderInput={(params) => (
                            <TextField {...params} label="Cultura" variant="outlined" size="small" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Autocomplete
                        className={classes.autocomplete}
                        options={anosSafra}
                        getOptionLabel={(option) => option.descricao}
                        value={selectedAnoSafra}
                        onChange={(event, newValue) => setSelectedAnoSafra(newValue)}
                        renderInput={(params) => (
                            <TextField {...params} label="Ano Safra" variant="outlined" size="small" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={viewMode === 'produtos'}
                                onChange={(event) => setViewMode(event.target.checked ? 'produtos' : 'associados')}
                                color="primary"
                                size="small"
                            />
                        }
                        label={
                            <Typography variant="caption">
                                {viewMode === 'produtos' ? 'Produtos' : 'Associados'}
                            </Typography>
                        }
                    />
                </Grid>
            </Grid>

            <div className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={fetchDemandas}
                    disabled={isButtonDisabled}
                    size="small"
                >
                    Buscar Demandas
                </Button>
            </div>

            <div className={classes.summaryContainer}>
                {renderSummary()}
                {renderTotals()}
            </div>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(0.5),
    },
    title: {
        marginBottom: theme.spacing(0.5),
        fontSize: '0.9rem',
    },
    filterContainer: {
        marginBottom: theme.spacing(0.5),
    },
    autocomplete: {
        width: '100%',
    },
    buttonContainer: {
        textAlign: 'center',
        marginBottom: theme.spacing(1),
    },
    summaryContainer: {
        marginTop: theme.spacing(0.1),
    },
    card: {
        marginBottom: theme.spacing(0.1),
        padding: theme.spacing(0.1)
    },
    tableContainer: {
        marginTop: theme.spacing(0.5),
    },
    tableCellHeader: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '0.7rem',
        borderRight: '1px solid #ddd',
        backgroundColor: '#1a6b25',
        color: 'white',
    },
    tableCellBody: {
        textAlign: 'center',
        fontSize: '0.6rem',
        borderRight: '1px solid #ddd',
    },
    typographySmall: {
        fontSize: '0.8rem',
    },
    typographyHeader: {
        fontSize: '0.9rem',
        fontWeight: 'bold',
    },
    tableRowEven: {
        backgroundColor: '#d1cfcf',
    },
    tableRowOdd: {
        backgroundColor: '#f0f1f0',
    },
    totalCell: {
        backgroundColor: '#c3f3cb',
        fontWeight: 'bold',
    },
    totalRow: {
        backgroundColor: '#c3f3cb',
    },
    totalCellCombo: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '0.7rem',
        borderRight: '1px solid #1a6b25',
        color: '#1a6b25',
        backgroundColor: '#c3f3cb',
    },
    cardContent: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0,
        },
    },
}));

export default SumarizacaoDemandas;
