import React, { useEffect, useState } from "react";
import './Dashboard.css'
import api2 from "../../services/api2";
import { checarPermissao } from '../../utils/utils';

import { PowerBIEmbed } from "powerbi-client-react";
import { models } from 'powerbi-client'
import Loader from 'react-loader-spinner';

function ImportacoesMundo(props) {

  const [token, setToken] = useState('')
  const [permitido, setPermitido] = useState(false)



  useEffect(() => {
    async function fetchMyAPI() {
      setPermitido(await checarPermissao('configuracoes_clientes', props))
      const response_token = await api2.get("/returnToken");
      setToken(response_token.data)
    }
    fetchMyAPI();

  }, [])



  return (


    permitido ?
      <div style={{ height: '80vh', width: '80vw', overflow: 'hidden' }}>

        <PowerBIEmbed

          embedConfig={{
            type: 'report',   // Supported types: report, PowerBIImportacoes, tile, visual and qna
            id: 'e1c32453-8c5e-4e84-a40e-21fc548353a7',
            embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=e1c32453-8c5e-4e84-a40e-21fc548353a7&groupId=95451c7a-f10b-4819-b6c4-1831871614fb',
            accessToken: token,
            tokenType: models.TokenType.Aad,

            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false
                }, pageNavigation: {
                  visible: false
                },

              },


            }
          }}

          eventHandlers={
            new Map([
              ['loaded', function () { console.log('Report loaded'); }],
              ['rendered', function () { console.log('Report rendered'); }],
              ['error', function (event) { console.log(event.detail); }]
            ])
          }

          cssClassName={"Embed-container"}

          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      </div>
      : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
  );
}

export default ImportacoesMundo;
