import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    container: {
        paddingBottom: 50,
        padding: 20,
        paddingTop: 0,
        marginBottom: 50
    },

    noBorder: {
        border: "none",
    },

    appBar: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: 'black',
        marginTop: 10,
        MarginBottom: 10,

    },

    autocomplete: {

    },

    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },

    inputh1: {
        fontFamily: 'Poppins',
    },

    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },
    },

    divChart: {
        paddingLeft: 20,
        marginBottom: 50,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }
}));
