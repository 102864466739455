import React, { useEffect, useState } from 'react';
import {
    TextField,
    Button,
    Grid,
    Paper,
    Typography,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import api_amr from '../../services/api_amr';
import { getToken } from '../../utils/utils';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(4),
        margin: 'auto',
        maxWidth: 1200,
    },
    title: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
    },
    button: {
        height: '56px', // Altura para igualar com o TextField
    },
    table: {
        marginTop: theme.spacing(4),
    },
}));

export default function MateriasPrima() {
    const [nomeMateria, setNomeMateria] = useState('');
    const [materiasCadastradas, setMateriasCadastradas] = useState([]);
    const [regioes, setRegioes] = useState([]);
    const [regiaoSelecionada, setRegiaoSelecionada] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [precoMateria, setPrecoMateria] = useState('');
    const [precosMateriaSelecionada, setPrecosMateriaSelecionada] = useState([]);
    const [dataPreco, setDataPreco] = useState(new Date().toISOString().substr(0, 10));
    const [materiaSelecionada, setMateriaSelecionada] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        fetchApi();
    }, []);

    const fetchApi = async () => {
        try {
            const [response_materias, response_precos] = await Promise.all([
                api_amr.get('/retornar_materias_primas', { headers: { 'x-api-key': getToken() } }),
                api_amr.get('/retorna_regioes', { headers: { 'x-api-key': getToken() } }),
            ]);

            if (response_materias.status === 200) {
                setMateriasCadastradas(response_materias.data);
            }
            if (response_precos.status === 200) {
                setRegioes(response_precos.data);
            }
        } catch (error) {
            alert('Erro ao buscar dados');
        }
    };

    const handleAddMateria = async () => {
        if (!nomeMateria) {
            alert('O campo Nome da Matéria Prima é obrigatório.');
            return;
        }
        try {
            const response = await api_amr.post(
                '/cadastro_materias_primas',
                { nome_materia: nomeMateria },
                { headers: { 'x-api-key': getToken() } }
            );
            if (response.status === 200) {
                alert('Matéria Prima salva com sucesso');
                setNomeMateria('');
                fetchApi();
            }
        } catch (e) {
            const errorMessage = e.response?.data?.error || 'Erro desconhecido';
            alert(`Erro: ${errorMessage}`);
        }
    };

    const handleOpenModal = (materia) => {
        setMateriaSelecionada(materia);
        fetchPrecosMateriaSelecionada(materia);
        setOpenModal(true);
    };

    const fetchPrecosMateriaSelecionada = async (materia) => {
        try {
            const response = await api_amr.get('/retorna_preco_materia_prima', {
                headers: { 'x-api-key': getToken() },
                params: {
                    id_materia: materia.id,
                },
            });
            setPrecosMateriaSelecionada(response.data);
        } catch (error) {
            alert('Erro ao buscar preços da matéria-prima');
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setMateriaSelecionada(null);
        setRegiaoSelecionada(null);
        setPrecoMateria('');
        setDataPreco(new Date().toISOString().substr(0, 10));
        setPrecosMateriaSelecionada([]);
    };

    const handleVinculaPrecoRegiao = async () => {
        if (!regiaoSelecionada || !precoMateria) {
            alert('Preencha todos os campos');
            return;
        }
        try {
            const response = await api_amr.post(
                '/salva_preco_materia_prima',
                {
                    id_materia: materiaSelecionada.id,
                    id_regiao: regiaoSelecionada.id_regiao,
                    data_preco: dataPreco,
                    preco: precoMateria,
                },
                {
                    headers: { 'x-api-key': getToken() },
                }
            );
            if (response.status === 200) {
                alert('Preço salvo com sucesso');
                fetchPrecosMateriaSelecionada(materiaSelecionada);
                // Limpar campos após salvar
                setRegiaoSelecionada(null);
                setPrecoMateria('');
                setDataPreco(new Date().toISOString().substr(0, 10));
            }
        } catch (e) {
            const errorMessage = e.response?.data?.error || 'Erro desconhecido';
            alert(`Erro: ${errorMessage}`);
        }
    };

    return (
        <Paper className={classes.paper} elevation={3}>
            <Typography variant="h4" className={classes.title} align="center">
                Cadastro de Matérias-Primas
            </Typography>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={10}>
                    <TextField
                        label="Nome da Matéria Prima"
                        variant="outlined"
                        fullWidth
                        value={nomeMateria}
                        onChange={(e) => setNomeMateria(e.target.value)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        onClick={handleAddMateria}
                        color="primary"
                        variant="contained"
                        fullWidth
                        className={classes.button}
                        startIcon={<AddBoxIcon />}
                    >
                        Adicionar
                    </Button>
                </Grid>
                <Grid item xs={12} className={classes.table}>
                    <Typography variant="h5" gutterBottom>
                        Matérias-Primas Cadastradas
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="Matérias-Primas Cadastradas">
                            <TableHead>
                                <TableRow>

                                    <TableCell>Nome da Matéria-Prima</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {materiasCadastradas.length > 0 ? (
                                    materiasCadastradas.map((materia) => (
                                        <TableRow key={materia.id}>
                                            <TableCell>{materia.nome_materia}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleOpenModal(materia)}
                                                >
                                                    +
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={3} align="center">
                                            Nenhuma matéria-prima cadastrada
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
                <DialogTitle>Adicionar Preço</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {/* Região */}
                        <Grid item xs={12}>
                            <Autocomplete
                                options={regioes}
                                getOptionLabel={(option) => option.nome_regiao}
                                value={regiaoSelecionada}
                                onChange={(event, newValue) => setRegiaoSelecionada(newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Região" variant="outlined" fullWidth />
                                )}
                            />
                        </Grid>
                        {/* Data do Preço */}
                        <Grid item xs={12}>
                            <TextField
                                label="Data do Preço"
                                type="date"
                                variant="outlined"
                                fullWidth
                                value={dataPreco}
                                onChange={(e) => setDataPreco(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        {/* Preço */}
                        <Grid item xs={12}>
                            <TextField
                                label="Preço"
                                variant="outlined"
                                fullWidth
                                value={precoMateria}
                                onChange={(e) => setPrecoMateria(e.target.value)}
                            />
                        </Grid>
                        {/* Preços Cadastrados */}
                        {precosMateriaSelecionada.length > 0 && (
                            <Grid item xs={12}>
                                <Typography variant="h6">Preços Cadastrados</Typography>
                                <TableContainer component={Paper}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Região</TableCell>
                                                <TableCell>Data</TableCell>
                                                <TableCell>Preço</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {precosMateriaSelecionada.map((preco) => (
                                                <TableRow key={preco.id_preco}>
                                                    <TableCell>{preco.nome_regiao}</TableCell>
                                                    <TableCell>
                                                        {preco.data_preco}
                                                    </TableCell>
                                                    <TableCell>
                                                        {new Intl.NumberFormat('pt-BR', {
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                        }).format(preco.preco)}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleVinculaPrecoRegiao} color="primary" variant="contained">
                        Salvar Preço
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}
