import React, { memo } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import HistPrecoTabela from '../HistPreco/HistPrecoTabela';
import RelTrocaTabela from '../RelTroca/RelTrocaTabela';
import HistPrecoCard from '../HistPreco/HistPrecoCard';
import RelTrocaCard from '../RelTroca/RelTrocaCard';
import HistPrecoGrafico from '../HistPreco/HistPrecoGrafico';
import RelTrocaGrafico from '../RelTroca/RelTrocaGrafico';
import RelTrocaFormulacoesGrafico from '../RelTroca/RelTrocaFormulacoesGrafico';

import DolarComponent from '../Outros/DolarComponent';
import NotesComponent from '../Outros/NotesComponent';
import PrevisaoComponent from '../Outros/PrevisaoComponent';
import HistPrecoTabelaEditavel from '../HistPreco/HistPrecoTabelaEditavel';
import PortosMapaComponent from '../Outros/PortosMapaComponent';
import TabelaFuturosComponent from '../Futuros/TabelaFuturosComponent';
import GraficoFuturosComponent from '../Futuros/GraficoFuturosComponent';
import MinhasFormulacoesTabelaEditavel from '../Outros/MinhasFormulacoesTabelaEditavel';
import ImageComponent from '../Outros/ImageComponent';
import { makeStyles } from '@material-ui/core';
import Socket from './Socket';

const ResponsiveGridLayout = WidthProvider(Responsive)

const RelTrocaTabelaMemo = memo(RelTrocaTabela)
const RelTrocaGraficoMemo = memo(RelTrocaGrafico)
const RelTrocaFormulacoesGraficoMemo = memo(RelTrocaFormulacoesGrafico)
const RelTrocaCardMemo = memo(RelTrocaCard)
const HistPrecoTabelaMemo = memo(HistPrecoTabela)
const HistPrecoGraficoMemo = memo(HistPrecoGrafico)
const HistPrecoCardMemo = memo(HistPrecoCard)
const DolarComponentMemo = memo(DolarComponent)
const NotesComponentMemo = memo(NotesComponent)
const HistPrecoTabelaEditavelMemo = memo(HistPrecoTabelaEditavel)
const PrevisaoComponentMemo = memo(PrevisaoComponent)
const PortosMapaComponentMemo = memo(PortosMapaComponent)
const TabelaFuturosComponentMemo = memo(TabelaFuturosComponent)
const MinhasFormulacoesTabelaEditavelMemo = memo(MinhasFormulacoesTabelaEditavel)
const GraficoFuturosComponentMemo = memo(GraficoFuturosComponent)
const ImageComponentMemo = memo(ImageComponent)

const GridDashboard = ({ layout, locked, onLayoutChange, onRemoveComponent, LayoutChanged }) => {

    const classes = useStyles()

    const renderComponent = (componentName, componentType, params, uuid) => {
        switch (componentType) {
            case "tabela":
                switch (componentName) {
                    case "relacao_troca":
                        return <RelTrocaTabelaMemo parametros={params} />
                    case "historico_preco":
                        return <HistPrecoTabelaMemo parametros={params} />
                    case "futuros":
                        return <TabelaFuturosComponentMemo parametros={params} />
                    case "grafico":
                        return <HistPrecoGraficoMemo parametros={params} />
                    case "card":
                        return <HistPrecoCardMemo parametros={params} />
                    case "tabela_editavel":
                        return <HistPrecoTabelaEditavelMemo parametros={params} uuid={uuid} enableColumnSelection={false} />
                    case "formulas_salvas":
                        return <MinhasFormulacoesTabelaEditavelMemo />
                    default:
                        return null
                }

            case "grafico":
                switch (componentName) {
                    case "relacao_troca":
                        return <RelTrocaGraficoMemo parametros={params} />
                    case "relacao_troca_formulacoes":
                        return <RelTrocaFormulacoesGraficoMemo parametros={params} />
                        
                    case "historico_preco":
                        return <HistPrecoGraficoMemo parametros={params} />
                    case "futuros":
                        return <GraficoFuturosComponentMemo parametros={params} />
                    default:
                        return null
                }

            case "card":
                switch (componentName) {
                    case "relacao_troca":
                        return <RelTrocaCardMemo parametros={params} />
                    case "historico_preco":
                        return <HistPrecoCardMemo parametros={params} />
                    case "dolar":
                        return <DolarComponentMemo LayoutChanged={LayoutChanged} />
                    default:
                        return null
                }

            case "tabela_editavel":
                if (componentName === "historico_preco") {
                    return <HistPrecoTabelaEditavelMemo parametros={params} uuid={uuid} enableColumnSelection={false} />
                }
                return null;

            case "note":
                return <NotesComponentMemo parametros={params} />
            case "previsao":
                return <PrevisaoComponentMemo parametros={params} />

            case "mapa":
                return <PortosMapaComponentMemo parametros={params} />

            case "imagem":
                return <ImageComponentMemo parametros={params} />

            default:
                return null;
        }
    }

    function getMinSizes(selectedComponent, selectedData) {
        let stringKey = ''
        if (selectedData !== '') {
            stringKey = `${selectedComponent}_${selectedData}`

        } else {
            stringKey = `${selectedComponent}`
        }

        const sizes = {
            card_historico_preco: {
                minW: 5,
                minH: 1
            },
            card_relacao_troca: {
                minW: 2,
                minH: 2
            },
            card_dolar: {
                minW: 1,
                minH: 1
            },
            tabela_historico_preco: {
                minW: 6,
                minH: 1
            },
            tabela_relacao_troca: {
                minW: 3,
                minH: 3
            },
            tabela_futuros: {
                minW: 6,
                minH: 2
            },
            grafico_historico_preco: {
                minW: 3,
                minH: 2
            },
            grafico_relacao_troca: {
                minW: 3,
                minH: 2
            },
            grafico_relacao_troca_formulacoes: {
                minW: 3,
                minH: 2
            },

            grafico_futuros: {
                minW: 3,
                minH: 2
            },
            note: {
                minW: 2,
                minH: 1
            },
            tabela_editavel_historico_preco: {
                minW: 6,
                minH: 3,
            },
            previsao: {
                minW: 3,
                minH: 3,
            },
            imagem: {
                minW: 2,
                minH: 1,
            },
            mapa: {
                minW: 3,
                minH: 3,
            },
            tabela_formulas_salvas: {
                minW: 6,
                minH: 1
            }
        }

        return sizes[stringKey]
    }

    return (
        <ResponsiveGridLayout
            onLayoutChange={(newLayout) => onLayoutChange(newLayout)}
            isDraggable={locked}
            isResizable={locked}
            className={classes.responsiveGrid}
            cols={{ xxs: 12, xs: 12, sm: 12, md: 12, lg: 12 }}
            layout={layout}
            draggableHandle="#myDragHandle"
        >
            {layout.map(layoutItem =>
                <div
                    key={layoutItem.i}
                    data-grid={{
                        x: layoutItem.x,
                        y: layoutItem.y,
                        w: layoutItem.w,
                        h: layoutItem.h,
                        minW: 2,
                        minH: 2
                    }}
                    className={classes.gridItem}>
                    <Socket
                        titulo={layoutItem.component_title}
                        locked={locked} layoutItem={layoutItem}
                        onRemoveComponent={() => onRemoveComponent(layoutItem.id_component)}>
                        {renderComponent(layoutItem.component_name, layoutItem.component_type, layoutItem.params, layoutItem.i)}
                    </Socket>
                </div>
            )}
        </ResponsiveGridLayout>
    )
}

const useStyles = makeStyles({
    responsiveGrid: {
        minHeight: 1080,
    },

    gridItem: {
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        '& .react-resizable-handle.react-resizable-handle-se': {
            visibility: 'hidden',
        },
        '&:hover .react-resizable-handle.react-resizable-handle-se': {
            visibility: 'visible',
        },
    },
})

export default GridDashboard
