import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import api_amr from 'services/api_amr';
import { getToken } from 'utils/utils';
import { Dialog, DialogContent } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { makeStyles } from "@material-ui/core/styles";

import logoLoader from 'assets/img/loader_gif.gif';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import HistPrecoGrafico from './HistPrecoGrafico';

const OverLayLogoAMRcarregando = () => {
    /* Componente a ser renderizado como overlay customizado caso a tabela esteja carregando */
    return (
        <div style={{ width: 'auto', height: '70%', position: 'relative' }}>
            <img src={logoLoader} alt="AMRLoader" style={{ width: 'auto', height: 'auto' }} />
        </div>
    );
};

const ArrowCellRenderer = ({ value }) => {
    let color;
    let arrow = "";

    if (parseFloat(value) > 0) {
        color = "green";
        arrow = "↑";
    } else if (parseFloat(value) < 0) {
        color = "red";
        arrow = "↓";
    } else {
        color = "black";
    }

    return (
        <div style={{ color: color }}>
            {value} {arrow}
        </div>
    );
};
export default function HistPrecoTabela({ props, parametros }) {
    /*
     * Componente Tabela Historico de Preço utilizando a biblioteca AG-Grid
     * Para mais informações consulte a documentação em: https://www.ag-grid.com/react-data-grid/
    */


    // Variaveis/States
    const gridRef = useRef(null);
    const classes = useStyles()
    const [data, setData] = useState([]);
    const [porto, setPorto] = useState('');
    const [selected, setSelected] = useState('')
    const [idPorto, setIdPorto] = useState('')
    const [openChart, setOpenChart] = useState(false)


    useEffect(() => {
        handleGridReady()
    }, [parametros])

    const columnDefs = [
        { headerName: "Matéria-Prima", field: "abreviacao", flex: 1 },
        { headerName: "id_materia", field: "id_materias", flex: 1, hide: true },
        { headerName: "Preço Atual", field: "valor_atual", flex: 1, valueFormatter: (params) => `${parseFloat(params.value).toLocaleString('pt-br', { style: 'currency', currency: 'USD' })}` },
        {
            headerName: "Variação Semanal",
            field: "variacao_semana_passada",
            flex: 1,
            valueFormatter: (params) => {
                return params.value !== 'inf' && params.value !== Infinity ? `${CallArrowCell(params.value)}${params.value}%` : null
            },
            hide: false,
            cellStyle: params => getCellStyle(params.value)
        },
        {
            headerName: "Variação Mensal",
            field: "variacao_mes_passado",
            flex: 1,
            valueFormatter: (params) => {
                return params.value !== 'inf' && params.value !== Infinity ? `${CallArrowCell(params.value)}${params.value}%` : null
            },
            hide: false,
            cellStyle: params => getCellStyle(params.value)
        },
        {
            headerName: "Variação Anual",
            field: "variacao_ano_passado",
            flex: 1,
            valueFormatter: (params) => {
                return params.value !== 'inf' && params.value !== Infinity ? `${CallArrowCell(params.value)}${params.value}%` : null
            },
            hide: false,
            cellStyle: params => getCellStyle(params.value)
        }
    ];

    // Function to get the cell style based on value
    const getCellStyle = (value) => {
        let color;
        if (parseFloat(value) > 0) {
            color = "green";
        } else if (parseFloat(value) < 0) {
            color = "red";
        } else {
            color = "black";
        }
        return { color };
    };

    const CallArrowCell = (value) => {
        let arrowUp = " ↑ ";
        let arrowDown = " ↓ "

        if (parseFloat(value) > 0) {
            return arrowUp;
        }
        else if (parseFloat(value) < 0) {
            return arrowDown
        }
        else {
            return '-'
        }
    }

    function filterAndSortByAbreviacao(array) {
        let allowedValues = [
            'UR',
            'SAM',
            'NAM',
            'MAP 52',
            'MAP 10-50',
            'MAP 11-44',
            'DAP',
            'STP',
            'SSP 21',
            'SSP 19',
            'SSP 2-18',
            'SSP 3-17',
            'SSP 23',
            'MES 09',
            'MES 15',
            'NPS 12-46',
            'NPK15-15-15',
            'NPK 16-16-16',
            'KCL',
            'KMgS',
            'ASPIRE',
            'Sulfato de Mg Kieserita'
        ]

        let filteredArray = array.filter(obj => allowedValues.includes(obj.abreviacao))

        filteredArray.sort((a, b) => allowedValues.indexOf(a.abreviacao) - allowedValues.indexOf(b.abreviacao))

        return filteredArray
    }

    const handleGridReady = async () => {
        /*
         * Esta função é executada quando o evento onGridReady ocorre.
         * O evento ocorre quando a tabela é renderizada, portanto serve como um useEffect do componente AG-Grid
         */

        // Chamada de dados e definição das colunas no back
        try {
            const response = await api_amr.get('/return_component_histPreco', { params: { 'formato': 'tabela', 'parametros': parametros }, headers: { 'x-api-key': getToken() } });


            /*
            * Antes de setar as configurações é necessário altera-la para adicionar um formatador de valores
            * O proprio ag-grid tem um propriedade para onde é passado uma função parar formatar os dados, essa é a maneira correta,
            * para maiores informações, acesse a documentação em: https://www.ag-grid.com/react-data-grid/value-formatters/#value-formatter-vs-cell-renderer
            * 
            * Aqui tambem podemos adicionar as configurações para cada coluna, como filtros que podem ser de texto, data ou numero. Para maiores
            * informações, acesse a documentação em: https://www.ag-grid.com/react-data-grid/filtering-overview/
            */

            setData(filterAndSortByAbreviacao(response.data.dados));
            setPorto(response.data.info.nome_porto)
            setIdPorto(response.data.info.id_porto)


        } catch (error) {
            console.error("Erro ao buscar dados: ", error);
        }

    };

    const noRowsOverlayComponent = useMemo(() => {
        return OverLayLogoAMRcarregando
    }, []);

    const loadingOverlayComponent = useMemo(() => {
        return OverLayLogoAMRcarregando
    }, []);

    const onSelectionChanged = useCallback(() => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelected(selectedRows.length === 1 ? parseInt(selectedRows[0].id_materias) : '')
        setOpenChart(true)
    }, []);

    const handleClose = () => {
        gridRef.current.api.deselectAll()
        setOpenChart(false)
    }

    return (
        <div id="myGrid" className="ag-theme-alpine" style={{ height: "100%", width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', backgroundColor: 'white' }}>
                <h3 style={{ margin: 0 }}>{porto}</h3>
            </div>
            <div style={{ height: 300 }}>
                <AgGridReact
                    ref={gridRef}//Referencia da tabela                
                    rowData={data}//Dados da tabela
                    columnDefs={columnDefs}//Carrega as configurações aplicaveis as colunas individualmente               
                    onGridReady={handleGridReady}//Adicioanando um Listener para o evento de Carregamento da tabela              
                    loadingOverlayComponent={loadingOverlayComponent}//Adicioanando um Listener para o evento de carregamento dos dados
                    noRowsOverlayComponent={noRowsOverlayComponent}//Adicioanando um Listener para o evento de não ter dados para serem mostrados      
                    suppressDragLeaveHidesColumns={true}//Impede que uma coluna seja arrastada para fora da grid e seja removida  
                    rowHeight={25}
                    frameworkComponents={{ arrowCellRenderer: ArrowCellRenderer }}
                    rowSelection={'single'}
                    onSelectionChanged={onSelectionChanged}
                />
                {selected && (
                    <Dialog open={openChart} onClose={handleClose || selected === ''} maxWidth="sm" fullWidth >
                        <DialogContent className={classes.mainWrapper}>
                            <div >
                                <div className={classes.bar}>
                                    <CloseRoundedIcon onClick={() => handleClose()} className={classes.icon} />
                                </div>

                                <div className={classes.contentWrapper}>
                                    <HistPrecoGrafico parametros={{ "id_materia": [selected], "porto": idPorto, "data": '2024-01-01' }} />
                                </div>
                            </div>

                        </DialogContent>
                    </Dialog>
                )}
            </div>
        </div>
    );
}
const useStyles = makeStyles({
    mainWrapper: {
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        border: '1px solid lightgray',
        boxSizing: 'border-box'
    },

    contentWrapper: {
        overflow: 'auto',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        background: 'white',

    },

    bar: {
        minHeight: 22,
        display: 'flex',
        justifyContent: 'flex-end',
        borderBottom: '1px solid lightgray',

        fontFamily: 'Poppins',
        fontSize: 14,
        userSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
    },

    icon: {
        color: '#747474',
        fontSize: 22,
        cursor: 'pointer',
        '&:hover': {
            color: '#383838'
        }
    }
})
