import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getToken, checarPermissao, DataMaior, validateInscEstadual } from '../../utils/utils'
import { validateCPF, validateCNPJ, validateCEP } from '../../utils/utils'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import api_amr from '../../services/api_amr'
import Loader from 'react-loader-spinner';
import { colors, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MuiPhoneInput from 'material-ui-phone-number';
import Button from "@material-ui/core/Button";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Chip } from "@material-ui/core";

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PropTypes from 'prop-types';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import GroupIcon from '@material-ui/icons/Group';




import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function a11yProps2(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const newsTags = [
    { tag_id: 5, tag_name: "Nitrogênio", tag_image_link: "" },
    { tag_id: 6, tag_name: "Fósforo", tag_image_link: "" },
    { tag_id: 7, tag_name: "Potássio", tag_image_link: "" },
    { tag_id: 18, tag_name: "Destaques da Semana", tag_image_link: "" },
    { tag_id: 11, tag_name: "Mapeamento de Preços", tag_image_link: "" },
    { tag_id: 10, tag_name: "Andamento de Mercado", tag_image_link: "" },
    { tag_id: 19, tag_name: "Enxofre, Ácidos e Rocha", tag_image_link: "" },
    { tag_id: 20, tag_name: "Gás Natural e Amônia", tag_image_link: "" },
    { tag_id: 14, tag_name: "Micronutriente", tag_image_link: "" },
    { tag_id: 8, tag_name: "Indústria e Mineração", tag_image_link: "" },
    { tag_id: 21, tag_name: "Logística e Importação", tag_image_link: "" },
    { tag_id: 16, tag_name: "Informativos Técnicos de Fertilizantes", tag_image_link: "" },
    { tag_id: 17, tag_name: "Legislação de Fertilizantes", tag_image_link: "" },
    { tag_id: 1, tag_name: "Geopolitica", tag_image_link: "" },
    { tag_id: 2, tag_name: "Macroeconomia", tag_image_link: "" },
    { tag_id: 3, tag_name: "Agricultura Brasil", tag_image_link: "" },
    { tag_id: 4, tag_name: "Agricultura Mundo", tag_image_link: "" },
    { tag_id: 22, tag_name: "Defensivos", tag_image_link: "" }
];
function PerfilUsuario(props) {
    const [user, setUser] = useState('')
    const [userbyadm, setUserbyadm] = useState('')
    const [telefone, setTelefoneCadastro] = useState('')
    const [nome, setNome] = useState('')
    const [cidade, setCidade] = useState('')
    const [estado, setEstado] = useState('')
    const [cep, setCep] = useState('')
    const [cpfCnpj, setCpfCnpf] = useState('')
    const [bairro_setor, setBairroSetor] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [endereco, setEndereco] = useState('')
    const [atualizando, setAtualizando] = useState(false)
    const [permitido, setPermitido] = useState(false)
    const [selectedTags, setSelectedTags] = useState([]);

    const [culturas, setCulturas] = useState('')

    const [cidadesList, setCidadesList] = useState('')
    const [nomeProdutoLista, setNomeProdutoLista] = useState(0)


    const [showPassword, setShowPassword] = useState(false)
    const classes = useStyles();
    const [altSenha, setAltSenha] = useState(false)

    const handleChange = (event, newValue) => { setValue(newValue) }
    const [value, setValue] = React.useState('1');
    const handleChange2 = (event, newValue) => { setValue2(newValue) }
    const [value2, setValue2] = React.useState('1');


    useEffect(() => {
        async function fetchMyAPI() {
            setPermitido(await checarPermissao('perfil', props))

            const response_user = await api_amr.get('/get_user_info', { params: { 'all': 0 }, headers: { 'x-api-key': getToken() } });
            setUser(response_user.data)
            console.log(response_user.data);

            const reponse_produtos_lista = await api_amr.get('/return_produtos_padrao', { params: { 'all': 1 }, headers: { 'x-api-key': getToken() } });
            setNomeProdutoLista(reponse_produtos_lista.data)

            const response_culturas = await api_amr.get('/return_culturas', { headers: { 'x-api-key': getToken() } });
            setCulturas(response_culturas.data)
            getCidadesList(1)


        }
        fetchMyAPI();
    }, []);


    async function getCidadesList() {

        const response_cidades_list = await api_amr.get('/return_cidades_brasil', { headers: { 'x-api-key': getToken() } })
        setCidadesList(response_cidades_list.data)
    }


    async function atualizaUsuario() {
        console.log(selectedTags)
        if ((nome === '') && (cpfCnpj === '') && (telefone === '') && (cep === '') && (cidade === '') && (bairro_setor === '') && (endereco === '') && (password === '' && password2 === '')&&(selectedTags.length == 0)) {
            alert('Modifique algum campo para atualizar')
            return;
        }
        if (password !== password2) {
            alert('Senhas diferentes')
            return;
        }
    
        if (!validateCEP(cep) && cep !== '') {
            alert('CEP Inválido')
            return;
        }
    
        setAtualizando(true);
        const response_atualiza_informacoes = await api_amr.post('/update_user', { 
            id: '', 
            nome, 
            cpfCnpj, 
            telefone, 
            cep, 
            'id_cidade': (cidade !== '' ? cidade.id : ''), 
            estado, 
            bairro_setor, 
            endereco, 
            password, 
            selectedTags: selectedTags.map(tag => tag.tag_id)  
        }, { headers: { 'x-api-key': getToken() } });
    
        if ((response_atualiza_informacoes.data === 'OK')) {
            alert('Dados atualizados');
            window.location.reload();
        } else {
            alert('Erro ao atualizar dados');
        }
        setAtualizando(false);
    }

    const handleClickClose = () => {
        setAltSenha(false);
    }

    return (
        permitido ?
            <div className={classes.container} style={{ height: "85vh", width: "90vw", marginTop: '50px', display: 'flex', flexDirection: 'row' }}>
            {user !== '' ? <div style={{ borderRadius: 10, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', overflowY: 'scroll', background: "#FAFCF6", marginTop: '10px', width: "77%", display: 'flex', flexDirection: 'column', }}>
                    <Box >


                        <div style={{
                           
                            display: 'flex', flexDirection: 'column'
                        }}>
                            <div style={{
                                display: 'flex', borderRadius: 10, marginTop: '10px',
                                flexDirection: 'column'
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <b className={classes.input}>Nome:</b>
                                    <TextField
                                        key='nome cliente'
                                        //label='Nome'
                                        disabled
                                        value={user[0].name}
                                        variant='outlined'
                                        style={{ borderRadius: 10 }}
                                        className={classes.fieldGrande}
                                        onChange={(event) => setNome(event.target.value)}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            </div>

                            <div style={{
                                display: 'flex', marginRight: '0px', marginTop: '30px', flexDirection: 'row'
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <b className={classes.input}>CPF(Apenas Números):</b>
                                    <TextField
                                        placeholder={user[0].cpf_cnpj}
                                        //label='CPF/CNPJ (Apenas Números)'
                                        variant='outlined'
                                        style={{ borderRadius: 10 }}
                                        onChange={(event) => (setCpfCnpf(event.target.value), console.log(cpfCnpj.length), console.log(cpfCnpj))}
                                        value={(cpfCnpj.length >= 11 && cpfCnpj.length != 13 ? cpfCnpj : cpfCnpj.length === 13 ? setCpfCnpf('') : cpfCnpj)}
                                        className={classes.fieldPeq}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}
                                        InputLabelProps={{ shrink: true }}

                                    />
                                </div>
                                <div style={{ marginLeft: 15, display: 'flex', flexDirection: 'column' }}>
                                    <b className={classes.input}>Telefone:</b>
                                    <MuiPhoneInput
                                        defaultCountry={"br"}
                                        variant='outlined'
                                        key='cellphone'
                                        //placeholder={user[0].cellphone}//  contato do recebedo é diferente do contato de cobrança, colocar do lado do campos de logistica
                                        onChange={(e) => setTelefoneCadastro(e)}
                                        value={user[0].cellphone}

                                        style={{ borderRadius: 10 }}
                                        //label='Telefone'
                                        className={classes.fieldPeq}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />
                                </div>
                            </div>
                            <div style={{
                                display: 'flex', marginTop: '30px', flexDirection: 'row'
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <b className={classes.input}>Email:</b>
                                    <TextField
                                        key='email'
                                        //label='Email'
                                        disabled
                                        value={user[0].email}
                                        style={{ borderRadius: 10 }}

                                        variant='outlined'
                                        className={classes.fieldGrande}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}
                                        InputLabelProps={{ shrink: true }}

                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '30px', flexDirection: 'column' }}>
                                <b className={classes.input}>Tags de Notícias:</b>
                                <Autocomplete
                                    multiple
                                    options={newsTags}
                                    getOptionLabel={(option) => option.tag_name}
                                    value={selectedTags}
                                    onChange={(event, newValue) => setSelectedTags(newValue)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip label={option.tag_name} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Selecione as tags"
                                            className={classes.fieldGrande}
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                classes: { notchedOutline: classes.noBorder }
                                            }}
                                        />
                                    )}
                                />
                            </div>

                            <div style={{
                                display: 'flex', marginTop: '30px', flexDirection: 'row'
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <b className={classes.input}>CEP:</b>
                                    <TextField
                                        placeholder={user[0].cep}
                                        //label='CEP (Apenas Números)'
                                        variant='outlined'
                                        onChange={(event) => (event.target.value, console.log(cep.length), setCep(event.target.value))}
                                        value={cep.length >= 8 && cep.length != 9 ? cep : cep.length === 9 ? '' : cep}
                                        style={{ borderRadius: 10, color: 'white' }}
                                        className={classes.fieldPeq}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}
                                        InputLabelProps={{ shrink: true }}


                                    />
                                </div>
                                <div style={{ marginLeft: 15, display: 'flex', flexDirection: 'column' }}>
                                    <b className={classes.input}>Cidade:</b>
                                    <Autocomplete

                                        options={cidadesList}
                                        getOptionLabel={(option) => (option.id + ' - ' + option.cidade + ' - ' + option.UF)}
                                        className={classes.fieldMedio}

                                        onChange={(e, v) => setCidade(v)}
                                        style={{ borderRadius: 10 }}
                                        classes={{
                                            input: classes.input,

                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            placeholder={user[0].cidade + ' - ' + user[0].UF}
                                            variant='outlined'
                                            value={cidade}

                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                classes: { notchedOutline: classes.noBorder }
                                            }}

                                        />}
                                    />

                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '30px', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <b className={classes.input}>Endereço:</b>

                                    <TextField
                                        placeholder={user[0].endereco}
                                        //label='Endereço'
                                        variant='outlined'
                                        onChange={(event) => setEndereco(event.target.value)}
                                        value={endereco}
                                        style={{ borderRadius: 10 }}
                                        className={classes.fieldMedio}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                                <div style={{ marginLeft: 15, display: 'flex', flexDirection: 'column' }}>
                                    <b className={classes.input}>Bairro:</b>

                                    <TextField
                                        placeholder={user[0].bairro_setor}
                                        //label='Bairro'
                                        variant='outlined'
                                        onChange={(event) => setBairroSetor(event.target.value)}
                                        value={bairro_setor}
                                        className={classes.fieldPeq}
                                        style={{ borderRadius: 10 }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}
                                        InputLabelProps={{ shrink: true }}

                                    />
                                </div>
                            </div>
                            <div>

                            </div>
                            <div style={{
                                display: 'flex', marginTop: '30px', flexDirection: 'row', alignItems: 'center', alignContent: 'center'
                            }} className={classes.calcDiv}>
                                {/*<Button onClick={() => (handleClickOpen())} variant="contained" className={classes.calcButton}> Alterar Senha</Button>  */}
                                <Button disabled={atualizando} style={{ marginLeft: '609px' }} onClick={() => (atualizaUsuario())} variant="contained" className={classes.calcButton}> Atualizar dados de usuário</Button>

                            </div>
                        </div>
                        {altSenha !== false ?
                            <div >
                                <Dialog open={altSenha} onClose={handleClickClose} aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title"><b className={classes.inputh1}>Digite a nova Senha</b></DialogTitle>
                                    <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
                                        <DialogContentText style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{
                                                display: 'flex', flexDirection: 'row'
                                            }}><div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <b className={classes.input}>Senha:</b>
                                                    <InputBase
                                                        key='senha'
                                                        variant='outlined'
                                                        id="standard-adornment-password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        autoComplete='new-password'
                                                        onChange={(event) => setPassword(event.target.value)}
                                                        className={classes.fieldSenha}
                                                        InputProps={{

                                                            disableUnderline: true,
                                                            classes: { notchedOutline: classes.noBorder }
                                                        }}
                                                        style={{ borderRadius: 10, height: 50 }}
                                                        //placeholder='Senha'
                                                        InputLabelProps={{ shrink: true }}

                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => setShowPassword(true)}
                                                                    onMouseDown={() => setShowPassword(false)}
                                                                >
                                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </div>
                                                <div style={{ marginLeft: 15, display: 'flex', flexDirection: 'column' }}>
                                                    <b className={classes.input}>Repita a senha:</b>
                                                    <InputBase
                                                        variant='outlined'
                                                        id="standard-adornment-password2"
                                                        type={showPassword ? 'text' : 'password'}
                                                        value={password2}
                                                        InputLabelProps={{ shrink: true }}
                                                        //placeholder='Redigite a senha'
                                                        onChange={(event) => setPassword2(event.target.value)}
                                                        className={classes.fieldSenha}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            classes: { notchedOutline: classes.noBorder }
                                                        }}
                                                        style={{ borderRadius: 10, height: 50 }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => setShowPassword(true)}
                                                                    onMouseDown={() => setShowPassword(false)}
                                                                >
                                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </DialogContentText>


                                    </DialogContent>
                                    <DialogActions style={{ justifyContent: 'center' }} >
                                        <Button onClick={(handleClickClose)} className={classes.calcButton} style={{ marginRight: 20 }}>
                                            Cancelar
                                        </Button>

                                        <Button onClick={() => (handleClickClose())} className={classes.calcButton} style={{ marginLeft: 20 }}>
                                            Confirmar
                                        </Button>

                                    </DialogActions>
                                </Dialog>
                            </div> : null}

                    </Box>
                </div> : null}





            </div > : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '5px'

    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    noBorder: {
        border: "none",
    },
    fieldGrande: {
        backgroundColor: 'white',
        width: '900px',
        height: '50px',

        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    fieldMedioGrande: {
        backgroundColor: 'white',
        width: '750px',
        height: '50px',

        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    fieldGambiarra: {
        backgroundColor: '0px 4px 4px rgba(0, 0, 0, 1)',
        width: '330px',
        height: '50px',

    },
    fieldMedio: {
        backgroundColor: 'white',
        width: '592.5px',
        height: '50px',

        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    fieldMetadeMedio: {
        backgroundColor: 'white',
        width: '288.75px',
        height: '50px',

        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    fieldPeq: {
        backgroundColor: 'white',
        width: '292.5px',
        height: '50px',

        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    fieldMuitoPeq: {
        backgroundColor: 'white',
        width: '187.5px',
        height: '50px',

        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    fieldSenha: {
        backgroundColor: 'white',
        width: '240px',
        height: '50px',

        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },

    autoCompleteFieldSmall: {
        marginTop: 15,
        minWidth: 104,
        maxWidth: 104,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteField: {
        marginTop: 15,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 15,
        width: 471,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },

    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    inputTitleTab: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        //whiteSpace: 'nowrap',
    },
    inputCheckbox: {

        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000'
    },
    inputh1: {
        fontFamily: 'Poppins',
        color: '#383838'
    },
    root: {
        "&$checked": {
            color: "black",
            backgroundColor: 'white'
        }
    },
    tab: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        color: colors.grey50

    },
    checked: {
        borderColor: 'black'
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
    calcDiv: {
        marginTop: 40,
        width: 'fit-content',
        height: 45,

    },
    nutriDiv: {
        paddingRight: 28,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 228
    },
    inputForm: {
        width: '50%',
        fontFamily: 'Poppins',
        color: '#383838'
    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    }, table: {
        minWidth: 400,
        maxWidth: '100%'
    },
    rowSize: {
        maxWidth: 500
    },
    tableModels: {
        marginTop: 20,
        maxWidth: '100%',
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
}));

export default PerfilUsuario;
