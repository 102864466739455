import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import api_amr from 'services/api_amr';
import { getToken } from 'utils/utils';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { makeStyles } from "@material-ui/core/styles";
import MateriasDoughnutGrafico from '../Outros/MateriasDoughnutGrafico';


import logoLoader from 'assets/img/loader_gif.gif';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// import HistPrecoGrafico from './HistPrecoGrafico';

const OverLayLogoAMRcarregando = () => {
    /* Componente a ser renderizado como overlay customizado caso a tabela esteja carregando */
    return (
        <div style={{ width: 'auto', height: '70%', position: 'relative' }}>
            <img src={logoLoader} alt="AMRLoader" style={{ width: 'auto', height: 'auto', marginTop: 30 }} />
        </div>
    );
};

const ArrowCellRenderer = ({ value }) => {
    let color;
    let arrow = "";

    if (parseFloat(value) > 0) {
        color = "green";
        arrow = "↑";
    } else if (parseFloat(value) < 0) {
        color = "red";
        arrow = "↓";
    } else {
        color = "black";
    }

    return (
        <div style={{ color: color }}>
            {value} {arrow}
        </div>
    );
};

export default function MinhasFormulacoesTabelaEditavel({ props, }) {
    /*
     * Componente Tabela Editalvel utilizando a biblioteca AG-Grid
     * Para mais informações consulte a documentação em: https://www.ag-grid.com/react-data-grid/
    */

    // Variaveis/States
    const gridRef = useRef(null);
    const classes = useStyles()
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState('')
    const [openChart, setOpenChart] = useState(false)
    const [showWarning, setShowWarning] = useState(false)


    useEffect(() => {
        handleGridReady()
    }, [openChart])  // REMOVER PARAMETROS

    const CellButton = (params) => (
        <div >
            <button onClick={() => OpenChart(params.data)} className={classes.tableCellButton}>Visualizar</button>
        </div>

    );

    const OpenChart = (rowData) => {
        setOpenChart(true);
        setSelected(rowData);
    };

    const columnDefs = [
        {
            headerName: "Composição",
            flex: 1,
            cellRenderer: CellButton,
            wrapText: true,
            autoHeight: true,
            headerClass: classes.centerHeader,
        },
        // { headerName: "Micronutrientes", field: "micronutrientes", flex: 1, width: 350, wrapText: true, autoHeight: true, },
        {
            headerName: "Macronutrientes", field: "npk", flex: 1, headerClass: classes.centerHeader,

        },
        {
            headerName: "FCA(FOB PORTO) BIG BAG $",
            field: "preco_atual_FOB_porto_bag",
            flex: 1,

            valueFormatter: (params) => `${parseFloat(params.value).toLocaleString('pt-br',
                { style: 'currency', currency: 'USD' })}`
        },
        {
            headerName: "R$",
            field: "preco_atual_FOB_porto_bag_RS",
            flex: 1,
            headerClass: classes.centerHeader,
            valueFormatter: (params) => `${parseFloat(params.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`
        },
        {
            headerName: "Dolar",
            field: "dolar",
            flex: 1,
            headerClass: classes.centerHeader,
            valueFormatter: (params) => `${parseFloat(params.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`
        },
        { headerName: "Porto", field: "nome_porto", flex: 1, headerClass: classes.centerHeader, },

    ];

    // Function to get the cell style based on value
    const getCellStyle = (value) => {
        let color;
        if (parseFloat(value) > 0) {
            color = "green";
        } else if (parseFloat(value) < 0) {
            color = "red";
        } else {
            color = "black";
        }
        return { color };
    };

    const handleGridReady = async () => {
        /*
         * Esta função é executada quando o evento onGridReady ocorre.
         * O evento ocorre quando a tabela é renderizada, portanto serve como um useEffect do componente AG-Grid
         */

        // Chamada de dados e definição das colunas no back
        try {
            const response = await api_amr.get('/return_formulacao_calculada', {
                headers: { 'x-api-key': getToken() }
            });

            /*
            * Antes de setar as configurações é necessário altera-la para adicionar um formatador de valores
            * O proprio ag-grid tem um propriedade para onde é passado uma função parar formatar os dados, essa é a maneira correta,
            * para maiores informações, acesse a documentação em: https://www.ag-grid.com/react-data-grid/value-formatters/#value-formatter-vs-cell-renderer
            * 
            * Aqui tambem podemos adicionar as configurações para cada coluna, como filtros que podem ser de texto, data ou numero. Para maiores
            * informações, acesse a documentação em: https://www.ag-grid.com/react-data-grid/filtering-overview/
            */

            // setData(filterAndSortByAbreviacao(response.data.dados));
            setData(response.data);
            if (response.data.length === 0) {
                setShowWarning(true)
            }
        } catch (error) {
            console.error("Erro ao buscar dados: ");
        }
    };

    const noRowsOverlayComponent = useMemo(() => {
        return OverLayLogoAMRcarregando
    }, []);

    const loadingOverlayComponent = useMemo(() => {
        return OverLayLogoAMRcarregando
    }, []);

    const onSelectionChanged = useCallback(() => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelected(selectedRows.length === 1 ? parseInt(selectedRows[0].id_materias) : '')
        setOpenChart(true)
    }, []);

    const handleClose = () => {
        gridRef.current.api.deselectAll()
        setOpenChart(false)
    }

    if (showWarning) {
        return <Typography>Não existem formulações salvas</Typography>
    }

    return (
        <div id="myGrid" className="ag-theme-alpine" style={{ height: "100%", width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ height: 300 }}>
                <AgGridReact
                    ref={gridRef}//Referencia da tabela                
                    rowData={data}//Dados da tabela
                    columnDefs={columnDefs}//Carrega as configurações aplicaveis as colunas individualmente               
                    onGridReady={handleGridReady}//Adicioanando um Listener para o evento de Carregamento da tabela              
                    loadingOverlayComponent={loadingOverlayComponent}//Adicioanando um Listener para o evento de carregamento dos dados
                    noRowsOverlayComponent={noRowsOverlayComponent}//Adicioanando um Listener para o evento de não ter dados para serem mostrados      
                    suppressDragLeaveHidesColumns={true}//Impede que uma coluna seja arrastada para fora da grid e seja removida  
                    rowHeight={25}
                    frameworkComponents={{ arrowCellRenderer: ArrowCellRenderer }}
                    // rowSelection={'single'}
                    onSelectionChanged={onSelectionChanged}
                />

                <Dialog
                    open={openChart}
                    onClose={() => {
                        if (selected === '') {
                            setOpenChart(false);
                        }
                    }}
                    maxWidth="sm"
                    fullWidth='false'

                >
                    <DialogContent className={classes.mainWrapper}>
                        <div >
                            <div className={classes.bar}>
                                <CloseRoundedIcon onClick={() => setOpenChart(false)} className={classes.icon} />
                            </div>
                            <div className={classes.chartCenter}>
                                <MateriasDoughnutGrafico
                                    Chartdata={selected}
                                />
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

            </div>
        </div >
    );
}

const useStyles = makeStyles({
    mainWrapper: {
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        height: '380px',
        width: '100%',
        border: '1px solid lightgray',
        boxSizing: 'border-box',
    },

    contentWrapper: {
        overflow: 'auto',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        background: 'white',

    },

    bar: {
        minHeight: 22,
        display: 'flex',
        justifyContent: 'flex-end',
        fontFamily: 'Poppins',
        fontSize: 14,
        userSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
    },

    icon: {
        color: '#747474',
        fontSize: 22,
        cursor: 'pointer',
        '&:hover': {
            color: '#383838'
        }
    },
    chartCenter: {
        display: 'flex',
        flexDirection: 'column',
        borderTop: '1px solid lightgray',
        alignItems: 'center',
    },
    centerHeader: {
        textAlign: 'center',
    },
    tableCellButton: {
        border: '1px solid white',
        fontFamily: 'Poppins',
        fontSize: '10px',
        background: '#A3CC51',
        color: 'white',
        borderRadius: 6,
        marginTop: 10,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },
    },
})
