import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../../assets/img/logo_amr.png";

export default function NotesComponent({ parametros }) {
  const [notes, setNotes] = useState('');
  const classes = useStyles();

  useEffect(() => {
    setNotes(parametros.text);
  }, [parametros]);

  return (
    <div className={classes.stickyNote}>
      <div className={classes.backgroundLogo}></div>
      <div className={classes.noteHeader}></div>
      <div className={classes.noteContent}>
        <p>{notes}</p>
      </div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  backgroundLogo: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${logo})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '70%',
    opacity: 0.4,
    zIndex: 1,
  },

  stickyNote: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: 'white',
    overflow: 'hidden',
    position: 'relative',

  },

  noteHeader: {
    backgroundColor: 'lightgray',
    color: '#333',
    padding: '5px',
    fontSize: '12px',
    fontWeight: 'bold',
  },

  noteContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    fontFamily: 'Poppins',
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
    zIndex: 1
  },
}));
