import React, { useEffect, useState } from "react";
import api_amr from 'services/api_amr';
import { Grid, Tabs, Tab } from '@material-ui/core';
import useStyles from '../styles/styles';
import CardMateria from "../../Dashboard/components/CardMateria";
import AutocompleteTopDashboard from "../../Dashboard/components/AutocompleteTopDashboard";
import NoticiasDashboard from "../../Dashboard/components/NoticiasDashboard";
import GraficoDashboard from "../../Dashboard/components/GraficoDashboard";
import RelacaoTroca from "../components/RelacaoTroca";
import GraficoPrevisao from "../components/Futuros";
import PrevisaoClimatica from "../../PrevisaoClimatica/PrevisaoClimatica";
import { getToken, checarPermissao, getId } from '../../../utils/utils';
import Loader from 'react-loader-spinner';

export default function Dashboard(props) {
    const classes = useStyles();
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedMateria, setSelectedMateria] = useState(81);
    const [datePick, setDatePick] = useState('2024-01-01');
    const [selectedPorto, setSelectedPorto] = useState(54);
    const [hoveredNews, setHoveredNews] = useState(null);
    const [returnDashboard, setReturnDashboard] = useState(null);
    const [error, setError] = useState(null);
    const [permitido, setPermitido] = useState(false)

    // const [selectedCultura, setSelectedCultura] = useState()
    const [cardMateriaInfo, setCardMateriaInfo] = useState({
        abreviacao: 'Ureia',
        tipo: 'Nitrogenado',
        nitrogenio: 46,
        fosforo: 0,
        potassio: 0
    });

    const [selectedCultura, setSelectedCultura] = useState({
        "id": 4,
        "cultura": "INDICADOR DA SOJA ESALQ/BM&FBOVESPA - PARANAGUÁ"
    })
    useEffect(() => {
        async function fetchMyAPI() {
            setPermitido(await checarPermissao('dashboard', props))
        }
        fetchMyAPI()
    })

    const renderTabContent = () => {
        switch (tabIndex) {
            case 0:
                return <GraficoDashboard
                    materia={cardMateriaInfo}
                    hoveredNews={hoveredNews}
                    chartData={returnDashboard?.data}
                    selectedMateria={selectedMateria}
                    selectedPorto={selectedPorto}
                    datePick={datePick}
                    handleSetPosts={handlePosts} />;
            case 1:
                return <RelacaoTroca
                    hoveredNews={hoveredNews}
                    materia={selectedMateria}
                    idCultura={selectedCultura.id}
                    nomeCultura={selectedCultura.cultura}
                    date={datePick} porto={selectedPorto}
                    handlePosts={handlePosts} />
            case 2:
                return <PrevisaoClimatica />
            case 3:
                return <GraficoPrevisao />
            default:
                return null;
        }
    };

    const handlePosts = (teste) => {
        setReturnDashboard(teste)
    }

    const handleNewsHover = (newsItem) => {
        setHoveredNews(newsItem);
    };

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleMateriaSelection = (materia) => {
        if (materia) {
            setCardMateriaInfo(materia);
            setSelectedMateria(materia.id_materias);
        } else {
            setSelectedMateria(null);
        }
    };

    const handleSecondAutocomplete = (porto) => {
        if (porto) {
            setSelectedPorto(porto.id);
        } else {
            setSelectedPorto(null);
        }
    };

    const handleDatePick = (date) => {
        setDatePick(date);
    };

    const handleCultura = (cultura) => {
        if (cultura) {
            setSelectedCultura(cultura);
        } else {
            setSelectedCultura(null);
        }
    }

    return (
        permitido ?
            <div className={classes.root}>
                <Grid container style={{ padding: 20 }}>
                    <Grid container item xs={12} lg={tabIndex != 2 ? 7 : 12} className={classes.chartAndCardWrapper}>
                        <Grid item xs={12}>
                            <Tabs value={tabIndex} onChange={handleChange}>
                                <Tab className={classes.tab} label="Histórico de Preços" />
                                <Tab className={classes.tab} label="Relação de Troca" />
                                <Tab className={classes.tab} label="Previsões climáticas" />


                                <Tab className={classes.tab} label="Futuros" />
                            </Tabs>
                        </Grid>


                        {((tabIndex === 1) | (tabIndex === 0)) ?
                            <Grid item xs={12}>
                                <AutocompleteTopDashboard
                                    tabIndex={tabIndex}
                                    handleFirstAutocomplete={handleMateriaSelection}
                                    handleSecondAutocomplete={handleSecondAutocomplete}
                                    handleDatePick={handleDatePick}
                                    handleCultura={handleCultura}
                                />
                            </Grid>
                            : null}


                        <Grid item xs={12} className={classes.dashChart1}>
                            {renderTabContent()}
                        </Grid>
                        {((tabIndex === 1) | (tabIndex === 0)) ?
                            <Grid item xs={12} lg={12} style={{ marginTop: 20 }}>
                                {selectedMateria && returnDashboard?.card && Array.isArray(returnDashboard.card) && returnDashboard.card.length > 0 && (
                                    <CardMateria materia={cardMateriaInfo} cardData={returnDashboard.card[0]} />
                                )}
                            </Grid>
                            : null}
                    </Grid>

                    <Grid container item xs={12} lg={5} style={{ paddingLeft: 20 }} >
                        {((tabIndex === 1) | (tabIndex === 0)) ?

                            <Grid item xs={12} className={`${classes.newsDashboardContainer} ${classes.rootNews}`} >
                                <NoticiasDashboard
                                    onNewsHover={handleNewsHover}
                                    newsData={returnDashboard?.posts}
                                />
                            </Grid>
                            : null}

                    </Grid>
                </Grid>
            </div > : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    );
}
