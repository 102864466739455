import {
  drawerWidth,

  // boxShadow,
  defaultFont,

  grayColor,

  hexToRgb
} from "assets/jss/material-dashboard-react.js";

const sidebarStyle = theme => ({
  drawerPaper: {
    width: 300,
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: 2000, // Adjusted for higher priority
    height: "100%",
    transition: "width 0.4s",
    overflowX: "hidden",
  },
  drawerPaperCollapsed: {
    width: 80,
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: 3, // Adjusted to lower priority than expanded drawer
    height: "100%",
    transition: "width 0.4s",
    overflowX: "hidden",
  },
  textHidden: {
    visibility: 'hidden',
  },
  logo: {
    position: "relative",
    padding: "15px 15px",

    zIndex: 4,
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",

      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      backgroundColor: "rgba(" + hexToRgb(grayColor[6]) + ", 0.3)"
    }
  },
  logoLink: {
    ...defaultFont,
    textTransform: "uppercase",
    padding: "3px 0",
    display: "block",
    fontSize: "14px",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: '#A3CC51'
    }
  },
  logoLinkRTL: {
    textAlign: "right"
  },
  logoImage: {
    width: "30px",
    display: "inline-block",
    maxHeight: "20px",
    marginLeft: "10px",
    marginRight: "15px",
    marginTop: "15px",
    marginBottom: '5px',
  },
  img: {
    width: "150px",
    left: '20%',
    top: "22px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0"
  },
  imgMin: {
    width: "40px",
    top: "28px",
    left: "20px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0"
  },
  list: {
    marginTop: "5px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "100",
    marginBottom: "0",
    listStyle: "none",
    position: "unset",
    overflow: "hidden",
  },
  item: {
    position: "relative",
    display: "block",
    right: -20,
    textDecoration: "none",
    "&:hover": {
      color: '#A3CC51'
    }
  },
  itemLink: {
    width: 250,
    transition: "all 300ms linear",

    borderRadius: "3px",
    position: "relative",
    display: "block",
    padding: "5px 10px",
    backgroundColor: "transparent",
    ...defaultFont
  },
  itemIcon: {
    width: "20px",
    height: "26px",
    fontSize: "20px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "#383838",
    "&:hover": {
      color: '#A3CC51'
    }
  },
  itemIconUser: {
    marginLeft: '8px',
    padding: 5,
    marginRight: 10,
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "#383838",
    "&:hover": {
      color: '#A3CC51'
    }
  },
  itemText: {
    ...defaultFont,
    width: 250,
    fontFamily: 'Poppins',
    margin: "0",
    lineHeight: "30px",
    fontSize: "14px",
    color: '#383838',
    "&:hover": {
      color: '#A3CC51',
    }
  },
  customNavLink: {
    textDecoration: 'none',
    color: 'inherit',
    shadows: 'none',
  },

  navbar: {
    '&::-webkit-scrollbar': {
      width: '0.25rem',
    },
    '&::-webkit-scrollbar-track': {
      background: '#929292',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#BFDC86',
    },
  },

  toggleButton: {
    cursor: 'pointer',
    position: 'fixed', // posiciona o botão de forma absoluta
    top: '50%',           // alinha verticalmente ao centro
    left: '44px',         // posiciona à direita do Drawer
    transform: 'translate(50%, 50%)', // centraliza
    width: 34,
    height: 34,
    backgroundColor: '#FFF', // Cor de fundo branca
    border: '3px solid #BFDC86',
    borderRadius: '50%',  // faz o botão redondo
    zIndex: 1002,            // para aparecer acima do Drawer
    color: '#606060',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: '#BFDC86',
      border: '3px solid #FFF',
    },
  },
  toggleButtonExpanded: {
    left: '264px', // posiciona ainda mais à direita quando o Drawer estiver expandido
    zIndex: 9999,            // para aparecer acima do Drawer
  },
  
  toggleButtonIcon: {
    // Para rodar o ícone 90 graus
    color: '#383838', // ou qualquer outra cor de sua escolha
    // transform: 'rotate(45deg)',
    transition: 'color 0.4s',
    '&:hover': {
      transform: 'rotate(270deg)',
      color: 'white', // ou qualquer outra cor de sua escolha
    },
    '&.rotated': {
      transform: 'rotate(180deg)',
    },
  },

  iconInverted: {
    transform: 'rotate(90deg)',
    transition: 'transform 0.4s ease-in-out',
    marginLeft: '-4px',
    marginTop: '1px',
    '&:hover': {
      color: 'white', // ou qualquer outra cor de sua escolha
    },
  },
  iconNormal: {
    marginLeft: '-3px',
    marginTop: '1px',
    // posiciona à direita do Drawer
    transform: 'rotate(-90deg)',
    transition: 'transform 0.4s ease-in-out',
    '&:hover': {
      color: 'white', // ou qualquer outra cor de sua escolha
    },
  },

});

export default sidebarStyle;
