import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from "@material-ui/lab";
import Paper from "@material-ui/core/Paper";
import { TextField } from "@material-ui/core";
import List from '@material-ui/core/List';
import { Table, TableHead, TableRow, TableCell, TableSortLabel, TableContainer, TableBody } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import api_amr from "services/api_amr";
import { getToken } from "utils/utils";
import Loader from 'react-loader-spinner';
import Button from '@material-ui/core/Button';
import { WhatsappShareButton, WhatsappIcon } from "react-share";


export default function ControleUsabilidade() {
    const classes = useStyles()
    const [posts, setPosts] = useState([])
    const [info, setInfo] = useState([])
    const [postInfo, setPostInfo] = useState('')
    const [postTags, setPostTags] = useState('')
    const [postUuid, setPostUuid] = useState('')
    const [selectedPost, setSelectedPost] = useState(0)
    const [title, setTitle] = useState('')
    const [totalViews, setTotalViews] = useState(0)
    const [postDate, setPostDate] = useState('')
    const [totalViewTable, setTotalViewTable] = useState([])

    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)

    const [itemsToShow, setItemsToShow] = useState(50)
    const loadMoreItems = () => {
        setItemsToShow(prevItemsToShow => prevItemsToShow + 50)
    }

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 6 }, (_, i) => (currentYear - i).toString());

    const [allTags, setAllTags] = useState([])

    const periodOptions = ['Últimos 3 meses', 'Últimos 6 meses', 'Último ano', 'Últimos 2 anos']

    const [selectedYear, setSelectedYear] = useState(null)
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedTagId, setSelectedTagId] = useState(null);

    const months = [
        { number: 1, name: "Janeiro" },
        { number: 2, name: "Fevereiro" },
        { number: 3, name: "Março" },
        { number: 4, name: "Abril" },
        { number: 5, name: "Maio" },
        { number: 6, name: "Junho" },
        { number: 7, name: "Julho" },
        { number: 8, name: "Agosto" },
        { number: 9, name: "Setembro" },
        { number: 10, name: "Outubro" },
        { number: 11, name: "Novembro" },
        { number: 12, name: "Dezembro" },
    ]

    const [infoTableOrder, setInforTableOrder] = useState('')
    const [totalViewsTableOrder, setTotalViewsTableOrder] = useState('')

    useEffect(() => {
        fetchMyAPI()
    }, [])

    const fetchMyAPI = async () => {
        const responsePosts = await api_amr.get('/return_posts_info', { headers: { 'x-api-key': getToken() } })
        setPosts(responsePosts.data)

        const responseViews = await api_amr.get('/return_post_views', { headers: { 'x-api-key': getToken() } })
        setTotalViewTable(responseViews.data)

        const responseTags = await api_amr.get('/return_all_tags')
        setAllTags(responseTags.data)
    }

    const handleShareString = () => {
        let url = `http://plataformaamrbi.com/pages/Noticia/${postUuid}`;
        return (
            `*${title}*\n${postDate}\n_${postTags}_\n\n${url}`
        )
    }

    const handleNoticiaInfo = async (item) => {
        setLoading(true)
        setSelectedPost(item.post_id)
        setTitle(item.value)
        setPostUuid(item.uuid)
        setPostTags(item.tag_name)
        setTotalViews(item.total_views)
        setPostDate(item.post_date)
        const response = await api_amr.get('/return_post_views', { params: { postId: item.post_id }, headers: { 'x-api-key': getToken() } })
        setInfo(response.data)
        setLoading(false)
    }

    const handleMonthSelect = (event, value) => {
        if (value) {
            setSelectedMonth(value.number)
        } else {
            setSelectedMonth(null)
        }
    }

    const handleTagSelect = (event, value) => {
        if (value) {
            setSelectedTagId(value.tag_id)
        } else {
            setSelectedTagId(null)
        }
    }

    const handleFiltro = async () => {
        setLoading2(true)
        setSelectedPost(0)
        setInfo([])
        const responsePosts = await api_amr.get('/return_posts_info', { params: { 'month': selectedMonth, 'year': selectedYear, 'tag_id': selectedTagId }, headers: { 'x-api-key': getToken() } })
        setPosts(responsePosts.data)
        setLoading2(false)
    }

    function sortInfoTable() {
        var data = [...info]
        var order = infoTableOrder === 'desc' ? 'asc' : 'desc'

        var result = []
        if (order === 'asc') {
            setInforTableOrder('asc')
            result = data.sort(function (a, b) {
                return b.views - a.views
            })
        } else {
            setInforTableOrder('desc')
            result = data.sort(function (a, b) {
                return a.views - b.views
            })
        }
        setInfo(result)
    }

    function sortTotalViewsTable() {
        var data = [...totalViewTable]
        var order = totalViewsTableOrder === 'desc' ? 'asc' : 'desc'

        var result = []
        if (order === 'asc') {
            setTotalViewsTableOrder('asc')
            result = data.sort(function (a, b) {
                return b.views - a.views
            })
        } else {
            setTotalViewsTableOrder('desc')
            result = data.sort(function (a, b) {
                return a.views - b.views
            })
        }
        setTotalViewTable(result)
    }

    const handleTotalViewTablFilter = async (period) => {
        const response = await api_amr.get('/return_post_views', { params: { period }, headers: { 'x-api-key': getToken() } })
        setTotalViewTable(response.data)
    }

    return (
        <Grid container xs={12} className={classes.root}>
            <Grid>
                <Typography variant="h5" gutterBottom className={classes.typographyTitle}>
                    Visualizações de engajamento com postagens
                </Typography>
                <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                    Tela para acompanhamento de engajamento com as publicações

                </Typography>
                <Typography variant="h5" gutterBottom className={classes.typographyDescription}>

                    -Primeira tabela mostra todas as visualizações por notícia
                </Typography>
                <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                    -Segunda tabela mostra o somatório total de visualizações por período
                </Typography>
            </Grid>
            <Grid item container  >
                <Grid item xs={4} className={classes.postsWrapper} >
                    <Grid container spacing={1} >
                        <Grid item xs={12} >
                            <Typography variant="h5" gutterBottom className={classes.typographyTitle}>
                                Notícias
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Autocomplete
                                classes={{
                                    paper: classes.paper
                                }}
                                size="small"
                                options={years}
                                onChange={(e, v) => setSelectedYear(v)}
                                renderInput={(params) => <TextField {...params} label="Ano" variant="outlined" />}
                            />
                        </Grid>

                        <Grid item xs={4} >
                            <Autocomplete
                                classes={{
                                    paper: classes.paper
                                }}
                                size="small"
                                options={months}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="Mês" variant="outlined" />}
                                onChange={handleMonthSelect}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Autocomplete
                                classes={{
                                    paper: classes.paper
                                }}
                                size="small"
                                options={allTags}
                                getOptionLabel={(option) => option.tag_name}
                                renderInput={(params) => <TextField {...params} label="Tag" variant="outlined" />}
                                onChange={handleTagSelect}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button size='small' disabled={loading2} className={classes.button} variant="contained" onClick={handleFiltro}>
                                Filtrar
                            </Button>
                        </Grid>
                        <Grid container item xs={12}>
                            {loading2 ?
                                <Loader type="ThreeDots" color="#A3CC51" height="60" width="60" />
                                : posts.slice(0, itemsToShow).map((item, index) => (
                                    <Grid container item xs={12} className={`${classes.listItem} ${selectedPost === item.post_id ? classes.selectedListItem : ''}`} component={Paper} onClick={() => handleNoticiaInfo(item)}>
                                        <Grid item
                                            xs={12}
                                            key={index}
                                        >
                                            <b>{item.value}</b>
                                        </Grid>
                                        <Grid item xs={9} className={classes.tagNameWrapper}>
                                            {item.tag_name}
                                        </Grid>

                                        <Grid item xs={3} className={classes.postDateWrapper}>
                                            {item.post_date}
                                        </Grid>
                                    </Grid>))}
                        </Grid>

                        {itemsToShow < posts.length &&
                            <Grid item xs={12} >
                                <Button size='small' variant="contained" className={classes.button} onClick={loadMoreItems}>
                                    Carregar mais
                                </Button>
                            </Grid>}


                    </Grid>
                </Grid>

                <Grid item className={classes.infoWrapper} xs={8} >
                    {info.length > 0 ?
                        <>
                            <Grid item xs={12} >
                                <Typography variant="h5" gutterBottom className={classes.typographyTitle}>
                                    Informações
                                </Typography>
                            </Grid>
                            <Grid container xs={12} component={Paper} style={{ padding: 10, }}>
                                <Grid item xs={12}>
                                    <Typography>
                                        <b>Titulo</b>: {title}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography>
                                        <b>Total Visualizações</b>: {totalViews}
                                    </Typography>
                                </Grid>

                                <Grid item xs={8}>
                                    <Typography>
                                        <b>Data de Publicação</b>: {postDate}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <WhatsappShareButton url={handleShareString(toString(postDate), postTags, title)} children={<WhatsappIcon size={25} />} />
                                </Grid>

                            </Grid>

                            {loading ? <Loader type="ThreeDots" color="#A3CC51" height="60" width="60" /> :
                                <Grid item xs={12} style={{ marginTop: 20 }}>
                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead className={classes.tableHead}>
                                                <TableRow>
                                                    <TableCell>Usuário</TableCell>
                                                    <TableCell>
                                                        <TableSortLabel
                                                            direction={infoTableOrder}
                                                            onClick={sortInfoTable}
                                                        >
                                                            Visualizações
                                                        </TableSortLabel>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {info.map((row, index) => (
                                                    <TableRow key={row.name} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#fafafa' }}>
                                                        <TableCell >{row.name}</TableCell>
                                                        <TableCell >{row.views}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>}
                        </>
                        : <Typography variant="h5" style={{ fontFamily: 'Poppins' }}>Por favor, escolha uma notícia para visualizar seus detalhes.</Typography>}
                </Grid>
            </Grid>

            <Grid item className={classes.totalViewsWrapper} xs={4}>
                <Grid item xs={12} >
                    <Typography variant="h5" gutterBottom className={classes.typographyTitle}>
                        Visualizações Totais
                    </Typography>
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Autocomplete
                        onChange={(e, v) => handleTotalViewTablFilter(v)}
                        size="small"
                        options={periodOptions}
                        renderInput={(params) => <TextField {...params} label="Período" variant="outlined" />}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell>Usuário</TableCell>
                                    <TableSortLabel
                                        direction={totalViewsTableOrder}
                                        onClick={sortTotalViewsTable}
                                    >
                                        <TableCell>Total Visualizações</TableCell>
                                    </TableSortLabel>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {totalViewTable.map((row, index) => (
                                    <TableRow key={row.name} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#fafafa' }}>
                                        <TableCell>
                                            {row.name}
                                        </TableCell>
                                        <TableCell>{row.views}</TableCell>
                                    </TableRow>))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid >
    )
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: 300
    },
    tableHead: {
        background: '#f5f5f5'
    },

    postDateWrapper: {
        fontSize: '12px',
        color: 'gray',
        justifyContent: 'flex-end',
        display: 'flex'
    },

    tagNameWrapper: {
        fontWeight: 'bold',
        fontSize: '12px',
        color: 'green'
    },

    postsWrapper: {
        maxHeight: '60vh',
        minHeight: '60vh',
        overflow: 'auto',
        padding: 20,
        border: '1px solid lightgray',
        borderRadius: 5,
        fontFamily: 'Poppins'
    },

    infoWrapper: {
        maxHeight: '60vh',
        minHeight: '60vh',
        overflow: 'auto',
        padding: 20,
        border: '1px solid lightgray',
        borderRadius: 5
    },

    totalViewsWrapper: {
        marginTop: 20,
        maxHeight: '60vh',
        overflow: 'auto',
        padding: 20,
        border: '1px solid lightgray',
        borderRadius: 5,
    },

    root: {
        padding: 20,
    },

    listItem: {
        padding: 10,
        marginTop: 10,
        marginBottom: 10,
        cursor: "pointer",
        "&:hover": {
            background: '#ededed'
        },
        fontFamily: 'Poppins',
        color: '#303030'
    },

    selectedListItem: {
        backgroundColor: '#edffed'
    },

    button: {
        background: '#A3CC51',
        color: 'white',
        fontFamily: 'Poppins'
    },

    typographyTitle: {
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    typographyDescription: {
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: 14
    }
}))