import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, MenuItem, Select, InputLabel, FormControl, Paper, Typography, Table, TableBody, TableCell, TableHead, TableRow, Box, Button } from '@material-ui/core';
import api from '../../services/api_amr';
import { getToken } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  formControl: {
    marginRight: theme.spacing(3),
    minWidth: 200,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    maxHeight: '70vh',
    overflow: 'auto',
  },
  table: {
    minWidth: 650,
    borderCollapse: 'collapse',
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
    backgroundColor: '#fff',
  },
  headerCell: {
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  stickyColumn: {
    position: 'sticky',
    left: 0,
    backgroundColor: '#f5f5f5',
    zIndex: 20,
  },
  stickyColumnRight: {
    position: 'sticky',
    right: 0,
    backgroundColor: '#f5f5f5',
    zIndex: 20,
  },
  editButton: {
    marginLeft: theme.spacing(1),
  },
  cellWithPadding: {
    paddingRight: theme.spacing(4),
  },
}));

const EntregaFertilizantesCRUD = () => {
  const classes = useStyles();
  const [anosSafra, setAnosSafra] = useState([]);
  const [culturas, setCulturas] = useState([]);
  const [selectedAnoSafra, setSelectedAnoSafra] = useState(null);
  const [selectedCultura, setSelectedCultura] = useState(null);
  const [summaryData, setSummaryData] = useState([]);
  const [allSemanas, setAllSemanas] = useState([]);
  const [isEditable, setIsEditable] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const anosSafraResponse = await api.get('/read_anos_safra', { headers: { 'x-api-key': getToken() } });
      setAnosSafra(anosSafraResponse.data);
    };
    fetchData();
  }, []);

  const handleAnoSafraChange = async (anoSafra) => {
    setSelectedAnoSafra(anoSafra);
    const culturasResponse = await api.get(`/read_culturas?ano_safra=${anoSafra.id_ano_safra}`, { headers: { 'x-api-key': getToken() } });
    setCulturas(culturasResponse.data);
    setSelectedCultura(null);
    setSummaryData([]);
    setAllSemanas([]);
  };

  const handleCulturaChange = async (cultura) => {
    setSelectedCultura(cultura);
    const response = await api.get(`/read_entregas_fertilizantes_crud?ano_safra=${selectedAnoSafra.id_ano_safra}&cultura=${cultura.id_cultura}`, { headers: { 'x-api-key': getToken() } });

    // Garantir que a resposta seja um array

    const data = response.data
    console.log('API Response Data:', data, typeof data);

    // Obter todas as semanas
    const allSemanas = [...new Set(data.map(item => `Ano${item.ano}-Semana${item.semana}`))];
    console.log('All Semanas:', allSemanas);

    // Estruturar os dados por estado
    const estados = [...new Set(data.map(item => item.estado))];
    const structuredData = estados.map(estado => {
      const estadoData = {
        estado,
        id_estado: data.find(d => d.estado === estado).id_estado,
        potencial_estimated: data.find(d => d.estado === estado).potencial_estimated,
        percentual_andamento: data.find(d => d.estado === estado).percentual_andamento,
      };
      allSemanas.forEach(semana => {
        const semanaData = data.find(d => d.estado === estado && `Ano${d.ano}-Semana${d.semana}` === semana);
        estadoData[semana] = semanaData ? semanaData.quantidade_entregue : '0';
      });
      return estadoData;
    });

    console.log('Structured Data:', structuredData);

    setSummaryData(structuredData);
    setAllSemanas(allSemanas);
    setIsEditable({});
  };

  const handleEditClick = (semana) => {
    if (isEditable[semana]) {
      handleSave(semana);
    }
    setIsEditable(prev => ({
      ...prev,
      [semana]: !prev[semana],
    }));
  };

  const handleInputChange = (e, rowIndex, semana) => {
    const newSummaryData = [...summaryData];
    newSummaryData[rowIndex][semana] = e.target.value;
    setSummaryData(newSummaryData);
  };

  const handleSave = async (semana) => {
    const dataToSave = summaryData.map(row => ({
      id_estado: row.id_estado,
      quantidade_entregue: row[semana],
      id_cultura: selectedCultura.id_cultura,
      id_ano_safra: selectedAnoSafra.id_ano_safra,
      ano: semana.split('Ano')[1].split('-')[0],
      semana: semana.split('-Semana')[1],
    }));

    await api.post('/update_entregas_fertilizantes', dataToSave, { headers: { 'x-api-key': getToken() } });
    setIsEditable(prev => ({
      ...prev,
      [semana]: false,
    }));
  };
  const formatNumber = (num) => {
    return new Intl.NumberFormat('pt-BR', { style: 'decimal', minimumFractionDigits: 2 }).format(num);
  };
  return (
    <Paper className={classes.root}>
      <Typography variant="h4" gutterBottom>Gerenciar Entregas de Fertilizantes</Typography>
      <FormControl className={classes.formControl}>
        <InputLabel>Ano Safra</InputLabel>
        <Select
          value={selectedAnoSafra}
          onChange={(e) => handleAnoSafraChange(e.target.value)}
        >
          {anosSafra.map(ano => (
            <MenuItem key={ano.id_ano_safra} value={ano}>
              {ano.descricao}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl} disabled={!selectedAnoSafra}>
        <InputLabel>Cultura</InputLabel>
        <Select
          value={selectedCultura}
          onChange={(e) => handleCulturaChange(e.target.value)}
        >
          {culturas.map(cultura => (
            <MenuItem key={cultura.id_cultura} value={cultura}>
              {cultura.nome_cultura}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {summaryData.length > 0 && (
        <Box className={classes.tableContainer}>
          <Table className={classes.table} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={`${classes.headerCell} ${classes.stickyColumn}`}>Estado</TableCell>
                {allSemanas.map(semana => (
                  <TableCell key={semana} className={`${classes.headerCell} ${classes.cellWithPadding}`}>
                    {semana}
                    <Button onClick={() => handleEditClick(semana)} className={classes.editButton}>
                      {isEditable[semana] ? 'Salvar' : 'Editar'}
                    </Button>
                  </TableCell>
                ))}
                <TableCell className={`${classes.headerCell} ${classes.stickyColumnRight}`}>Entregue</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {summaryData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell className={`${classes.tableCell} ${classes.stickyColumn}`}>
                    {`${row.estado} - ${formatNumber(row.potencial_estimated)}`}
                  </TableCell>
                  {allSemanas.map(semana => (
                    <TableCell key={semana} className={classes.tableCell}>
                      {isEditable[semana] ? (
                        <TextField
                          value={row[semana]}
                          onChange={(e) => handleInputChange(e, rowIndex, semana)}
                        />
                      ) : (
                        row[semana]
                      )}
                    </TableCell>
                  ))}
                  <TableCell className={`${classes.tableCell} ${classes.stickyColumnRight}`}> Entregue: {row.percentual_andamento ? row.percentual_andamento.toFixed(2) : '0.00'}%</TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Paper>
  );
};

export default EntregaFertilizantesCRUD;
