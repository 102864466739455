import React, { useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    autoCompleteFieldBig: {
        
        marginRight: 10,
        width: 250,
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        borderRadius: 6,
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: 150,
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
});

const monthNameToNumber = {
    'Janeiro': 1,
    'Fevereiro': 2,
    'Março': 3,
    'Abril': 4,
    'Maio': 5,
    'Junho': 6,
    'Julho': 7,
    'Agosto': 8,
    'Setembro': 9,
    'Outubro': 10,
    'Novembro': 11,
    'Dezembro': 12
};

export default function AutocompleteMapa({ activeTable, handleHistMensal, handleHistSemanal, handleSecondAutocomplete, setTipo }) {
    const classes = useStyles();

    const yearsOptions = [new Date().getFullYear(), new Date().getFullYear() - 1, new Date().getFullYear() - 2, new Date().getFullYear() - 3];
    const monthsOptions = Object.keys(monthNameToNumber);

   

    useEffect(() => {
       
    }, [activeTable]);

    return (
        <div>
            {/* <Button
                variant="contained"
                className={classes.calcButton}
                onClick={() => {
                    handleHistMensal();
                }}
            >
                Mensal
            </Button>
            <Button
                variant="contained"
                className={classes.calcButton}
                style={{ marginLeft: 20 }}
                onClick={() => {
                    handleHistSemanal();
                }}
            >
                Semanal 2023
            </Button> */}
            <Autocomplete
                size='small'
                options={activeTable === 'TableUsabilidade' ? yearsOptions : monthsOptions}
                getOptionLabel={(option) => option.toString()}
                className={classes.autoCompleteFieldBig}
                defaultValue = '2023'
                renderInput={(params) => <TextField {...params} label="Ano" variant="outlined" />}
                onChange={(e, v) => {
                    if (v) {
                        // If months options are selected, convert the month name to number
                        if (monthsOptions.includes(v)) {
                            handleSecondAutocomplete(monthNameToNumber[v]);
                        } else {
                            handleSecondAutocomplete(v);
                        }
                    } else {
                        handleSecondAutocomplete(""); // Empty string
                    }
                }}
            />
        </div>
    );
}
