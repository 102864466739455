import React from 'react';
import './Footer.css';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <div className="layout">
      
      <main className="main-content">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;