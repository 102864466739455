import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import api_amr from 'services/api_amr';
import { getToken } from 'utils/utils';
import backLogo from '../../../assets/img/logo_arm_opaque.png';
import { makeStyles } from "@material-ui/core/styles";

const colors = {
    "UR": "#0713f0", // cor fixa da materia UR
    "SAM": "#3498DB",
    "CAM": "#2980B9",
    "MAP 52": "#154079",
    "MAP 10-50": "#FFC300",
    "MAP 11-44": "#FF9A00",
    "DAP": "#FF6E00",
    "MES 09": "#FF4300",
    "MES 15": "#2ECC71",
    "NPS 12-46": "#27AE60",
    "YUC 10-40": "#1A8440",
    "YUC 09-46": "#0E5A29",
    "STP": "#9B59B6",
    "SSP 21": "#8E44AD",
    "SSP 19": "#6C3483",
    "SSP 2-18": "#4A235A",
    "SSP 3-17": "#900C3F",
    "SSP 23": "#C70039",
    "KCL": "#ED1C1C", // cor fixa da materia KCL
    "SOP": "#581845",
    "KMgS": "#FF5733",
    "B10": "#D6DBDF",
    "B2,5": "#B2BABB",
    "BoMo": "#839192",
    "Zn10": "#2C3E50",
    "Zn15": "#273746",
    "Zn20": "#1E2833",
    "Cu10": "#151C20",
    "NAM": "#F1C40F",
    "NAM_EST": "#D4AC0D",
    "DU": "#AF8C0A",
    "KCL 60.5": "#8A6607",
    "FMA BR12": "#E67E22",
    "Bentonita 90": "#CA6F1E",
    "Sulfato de Mg Kieserita": "#AF4C18",
    "SSP micrado": "#73300C",
    "NBPT": "#E74C3C",
    "NPK15-15-15": "#CB4335",
    "ASPIRE": "#A93226",
    "NPS 08-40-00+5S": "#7B0F17",
    "NPK 16-16-16": "#7B0F17"
};

const HistPrecoGrafico = ({ parametros }) => {
    const [formattedDates, setFormattedDates] = useState([]);
    const [dados, setDados] = useState([]);
    const [porto, setPorto] = useState('');
    const classes = useStyles()

    useEffect(() => {
        fetchMyApi();
    }, [parametros]);

    const fetchMyApi = async () => {

        const response = await api_amr.get('/return_component_histPreco', {
            params: {
                formato: 'grafico',
                parametros: { ...parametros, data: '2024-01-01' },
            },
            headers: { 'x-api-key': getToken() },
        });
        if (response.data != 'NOK') {
            setDados(response.data)
            const newFormattedDates = Object.values(response.data)[0].dados.map((item) => {
                const [year, month, day] = item.date.split('-');
                return `${day}/${month}/${year}`;
            });
            setFormattedDates(newFormattedDates);
            setPorto(Object.values(response.data)[0].info.nome_porto)
        }

    };

    const data = {
        labels: formattedDates,
        datasets: Object.keys(dados).map((item, index) => ({
            label: `${dados[item].info.nome_materia}`,
            data: dados[item].dados.map((item) => item.cfr),
            fill: false,
            backgroundColor: colors[dados[item].info.nome_materia],
            borderColor: colors[dados[item].info.nome_materia],
            pointRadius: 0,
        }))
    };

    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                grid: {
                    display: true,
                },
                title: {
                    display: false,

                },
                ticks: {
                    callback: function (value, index, values) {

                        return index % 10 === 0 ? formattedDates[index] : undefined
                    },
                },

            },
            y: {
                grid: {
                    display: false,
                },
                title: {
                    display: false,

                },
                ticks: {
                    callback: function (value, index, values) {
                        return parseFloat(value).toLocaleString('pt-br', { style: 'currency', currency: 'USD' }); // This will append a dollar sign to the values on the y-axis
                    },
                },

            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.dataset.label}: ${context.parsed.y}`;
                    },
                },
            },
            legend: {
                display: true, // This hides the legend labels
            },
            title: {
                display: true, // Set to true to display the title
                text: `Histórico de Preço em ${porto} `, // The text you want for the title
                font: {
                    size: 16,
                    font: 'Poppins'
                },
                display: true, // This hides the legend labels
            },

        },

    };

    return (
        <div className={classes.mainWrapper}>
            <img
                src={backLogo}
                alt="Logo"
                className={classes.img}
            />
            <Line data={data} options={options} />
        </div>
    );
};

const useStyles = makeStyles({
    mainWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: 'white'
    },

    img: {
        position: 'absolute',
        zIndex: 1,
        width: '50%',
        height: 'auto',
    }
})

export default HistPrecoGrafico;
