import React from 'react';
import { MapContainer, TileLayer, GeoJSON, Marker, ImageOverlay, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import * as d3 from 'd3-scale';
import { interpolateRgb } from 'd3-interpolate';
import L from 'leaflet';
import { rgb } from 'd3-color';
import oceans from '../../assets/oceans.json';
import mask from '../../assets/mask.json';
import logoImage from '../../assets/img/logo_arm_opaque.png';

const stateOffsets = {
  'Acre': [0, 0],
  'Alagoas': [1, -0.2],
  'Amapá': [1, 0.5],
  'Amazonas': [-1, -2],
  'Bahia': [1, 1.7],
  'Ceará': [0, 1],
  'Distrito Federal': [3330, 3330],
  'Espírito Santo': [0.5, 0],
  'Goiás': [-2, 0],
  'Maranhão': [0.7, 0],
  'Mato Grosso': [2, -1.5],
  'Mato Grosso do Sul': [-2, 1],
  'Minas Gerais': [0, -0.2],
  'Pará': [-3, -1.5],
  'Paraíba': [0, 0],
  'Paraná': [-1.4, 1.7],
  'Pernambuco': [0, 0],
  'Piauí': [-1, -1.5],
  'Rio de Janeiro': [1.3, 0.4],
  'Rio Grande do Norte': [0, 1],
  'Rio Grande do Sul': [-2, -0.4],
  'Rondônia': [-2, 0.5],
  'Roraima': [0, 1],
  'Santa Catarina': [0, -0.5],
  'São Paulo': [-2, -0.3],
  'Sergipe': [0, 0.4],
  'Tocantins': [-1, 0],
};

const applyManualOffsets = (centroid, stateName) => {
  const offset = stateOffsets[stateName] || [0, 0];
  return [centroid[0] + offset[0], centroid[1] + offset[1]];
};

const Legend = ({ colorScale, maxPercentage }) => {
  const map = useMap();

  React.useEffect(() => {
    const legend = L.control({ position: 'bottomright' });

    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'info legend');
      const height = 150;
      const gradient = `
        <div style="background: linear-gradient(to top, ${colorScale(0)}, ${colorScale(maxPercentage / 2)}, ${colorScale(maxPercentage)}); width: 20px; height: ${height}px; margin-right: 5px;"></div>
      `;

      const legendContent = `
        ${gradient}
        <div style="display: flex; flex-direction: column; justify-content: space-between; height: ${height}px;">
          <span>${maxPercentage}%</span>
          <span>${(maxPercentage / 2).toFixed(0)}%</span>
          <span>0%</span>
        </div>
      `;

      div.innerHTML = `
        <div style="display: flex; align-items: flex-end;">
          ${legendContent}
        </div>
      `;

      return div;
    };

    legend.addTo(map);

    return () => {
      legend.remove();
    };
  }, [map, colorScale, maxPercentage]);

  return null;
};

const TotalInfoBox = ({ total }) => {
  return (
    <div style={{
      position: 'absolute',
      top: '10px',
      left: '10px',
      padding: '10px',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '8px',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
    }}>
      <h4>Total de Andamento de Mercado</h4>
      <p>Total Entregue: {Math.round(total.total_entregue).toLocaleString('pt-BR')}</p>
      <p>Potencial: {Math.round(total.potencial).toLocaleString('pt-BR')}</p>
      <p>Percentual: {total.percentual.toFixed(2)}%</p>
    </div>
  );
};


const PrecificaPortoMapa = ({ data, total }) => {
  const geoJsonData = data;

  const maxPercentage = geoJsonData ? Math.max(...geoJsonData.features.map(feature => feature.properties.percentage)) : 100;

  const colorScale = d3.scaleLinear()
    .domain([0, maxPercentage / 2, maxPercentage])
    .range(["#E7E6E6", "#548235", "#1F5597"])
    .interpolate((a, b) => {
      const interpolator = interpolateRgb(a, b);
      return (t) => rgb(interpolator(t)).toString();
    });

  const onEachState = (state, layer) => {

    const stateName = state.properties.Estado;
    const percentage = state.properties.percentage;
    const total = state.properties.total_entregue.toLocaleString('pt-BR')
    layer.on({
      mouseover: (event) => {
        event.target.setStyle({
          weight: 2,
          color: '#333',
          fillOpacity: 1,
        });
        const tooltipContent = `<strong>${stateName}</strong><br>Andamento: ${percentage}%<br>Total Entregue: ${total}`;
        layer.bindTooltip(tooltipContent, { permanent: false }).openTooltip();
      },
      mouseout: (event) => {
        event.target.setStyle({
          weight: 1,
          color: 'white',
          fillOpacity: 1,
        });
        layer.closeTooltip();
      },
    });
  };

  const style = (feature) => {
    const percentage = feature.properties.percentage;
    const fillColor = colorScale(percentage);
    return {
      fillColor: fillColor,
      weight: 1,
      opacity: 1,
      color: 'white',
      fillOpacity: 1,
    };
  };

  const bounds = [
    [-33.7426, -73.9828], // Southwest coordinates
    [5.2718, -34.7931],   // Northeast coordinates
  ];
  const imageBounds = [
    [-19.0, -74.0], // Southwest coordinates
    [-12.0, -34.5],  // Northeast coordinates
  ];
  
  const maskGeoJson = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-85, -60], // Bottom left
              [-85, 15], // Top left
              [-30, 15], // Top right
              [-30, -60], // Bottom right
              [-85, -60], // Bottom left
            ],
            // Hole to leave Brazil visible
            [
              [-73.9828, -33.7426], // Bottom left
              [-73.9828, 51.2718], // Top left
              [-34.7931, 51.2718], // Top right
              [-34.7931, -33.7426], // Bottom right
              [-73.9828, -33.7426], // Bottom left
            ],
          ],
        },
      },
    ],
  };

  return (
    <MapContainer 
      center={[-14.2350, -51.9253]} 
      zoom={4} 
      style={{ height: '70vh', width: '100%', position: 'relative', zIndex: 0 }}
      maxBounds={bounds} 
      maxBoundsViscosity={1.0}
      zoomControl={false}
      scrollWheelZoom={false}
    >
      <TileLayer
        url={`https://api.mapbox.com/styles/v1/your_username/your_style_id/tiles/256/{z}/{x}/{y}@2x?access_token=your_mapbox_access_token`}
        attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
      />
      <ImageOverlay
        url={logoImage}
        bounds={imageBounds}
        opacity={0.7}
        zIndex={1000}
      />
      <GeoJSON data={oceans} style={{ fillColor: 'white', fillOpacity: 1, color: 'white', weight: 2 }} />
      <GeoJSON data={mask} style={{ fillColor: 'white', fillOpacity: 1, color: 'white', weight: 2 }} />

      {geoJsonData && (
        <>
          <GeoJSON key={JSON.stringify(geoJsonData)} data={geoJsonData} style={style} onEachFeature={onEachState} />
          {geoJsonData.features.map((feature, index) => {
            const [lng, lat] = applyManualOffsets(feature.properties.centroid, feature.properties.Estado);
            return (
              <Marker
                key={index}
                position={[lat, lng]}
                icon={L.divIcon({
                  className: 'custom-text-overlay',
                  html: `<div style="color: white; font-size: 12px; font-weight: bold; text-align: center;">${parseInt(feature.properties.percentage)}%</div>`,
                })}
              />
            );
          })}
          <Legend colorScale={colorScale} maxPercentage={maxPercentage} />
        </>
      )}
      {total && <TotalInfoBox total={total} />}
    </MapContainer>
  );
};

export default PrecificaPortoMapa;
