import React, { useEffect, useState } from "react";
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import Defensivos from './Defensivos';
import MateriasPrima from "./MateriaPrima";
import { makeStyles } from '@material-ui/core/styles';

// Defina estilos personalizados para o indicador
const useStyles = makeStyles({
    indicator: {
        backgroundColor: 'blue', // Altere para a cor desejada
    },
});

export default function CadastroDefensivos() {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Tabs
                value={value}
                onChange={handleChange}
                classes={{ indicator: classes.indicator }}
                aria-label="Abas simples"
            >
                <Tab label="Cadastro Defensivos" />
                <Tab label="Cadastro Materias-Primas" />
            </Tabs>
            {value === 0 && (
                <TabPanel>
                    <Defensivos />
                </TabPanel>
            )}
            {value === 1 && (
                <TabPanel>
                    <MateriasPrima/>
                </TabPanel>
            )}
        </div>
    );
}

function TabPanel(props) {
    const { children } = props;
    return (
        <Box p={3}>
            {children}
        </Box>
    );
}
