import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

function TableUsabilidade({ ano, classes, sortedData }) {
    console.log('sortedData table semanal', ano)
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const [sortOrder, setSortOrder] = useState('')
    const [sortedColumn, setSortedColumn] = useState('')
    const dataArr = Object.keys(sortedData).map((key) => {
        const row = sortedData[key];
        row['user'] = key;
        row['total'] = months.reduce((sum, month) => sum + (row[month] || 0), 0);
        return row;
    });

    useEffect(() => {
        handleSort('total');
    }, []);

    const [sortedDataArr, setSortedDataArr] = useState([...dataArr])

    const handleSort = (column) => {
        let newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newOrder);
        setSortedColumn(column);

        let sortedData = [...dataArr];
        sortedData.sort((a, b) => {
            if (a[column] < b[column]) {
                return newOrder === 'asc' ? -1 : 1;
            }
            if (a[column] > b[column]) {
                return newOrder === 'asc' ? 1 : -1;
            }
            return 0;
        });
        setSortedDataArr(sortedData);
    };

    return (
        <div className={classes.tableContainer}>
            <TableContainer component={Paper} >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow >
                            <TableCell style={{ maxWidth: 50 }} align="left" key="user">
                                <TableSortLabel
                                    style={{ maxWidth: 50 }}
                                    direction={sortOrder}
                                    onClick={() => handleSort('user')}
                                >
                                    Usuário
                                </TableSortLabel>
                            </TableCell>
                            {months.map(month => (
                                <TableCell style={{ maxWidth: 50 }} align="left" key={month}  >
                                    <TableSortLabel
                                        style={{ maxWidth: 50 }}
                                        direction={sortOrder}
                                        onClick={() => handleSort(month)}

                                    >
                                        {month}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            <TableCell style={{ maxWidth: 50 }} align="left" onClick={() => handleSort('total')}>
                                <TableSortLabel
                                    direction={sortOrder}
                                    onClick={() => handleSort('total')}
                                >
                                    Total {sortedColumn === "total"}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key="TOTALS" style={{ maxWidth: 50, position: "sticky", bottom: 0, backgroundColor: "lightgray" }}>
                            <TableCell style={{ maxWidth: 50, position: "sticky", bottom: 0, backgroundColor: "lightgray"}} component="th" scope="row" align="left">Totais mês</TableCell>
                            {months.map(month => {
                                let monthTotal = sortedDataArr.reduce((sum, row) => sum + (row[month] || 0), 0);
                                return <TableCell style={{ maxWidth: 50, position: "sticky", bottom: 0, backgroundColor: "lightgray" }} align="left" key={month}>{monthTotal}</TableCell>
                            })}
                            <TableCell style={{ maxWidth: 50, position: "sticky", bottom: 0, backgroundColor: "lightgray" }} align="left">
                                {sortedDataArr.reduce((sum, row) => sum + (row.total || 0), 0)}
                            </TableCell>
                        </TableRow>
                        {sortedDataArr.map((row) => (
                            <TableRow key={row.user} style={{ maxWidth: 50 }}>
                                <TableCell style={{ maxWidth: 50 }} component="th" scope="row" align="left">
                                    {row.user || "-"}
                                </TableCell>
                                {months.map(month => <TableCell style={{ maxWidth: 50 }} align="left" key={month}>{row[month] || 0}</TableCell>)}
                                <TableCell style={{ maxWidth: 50 }} align="left">{row.total || "0"}</TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ marginTop: '20px', backgroundColor: '#f8f8f8', padding: '10px' }}>
                <h2>Totals</h2>
                {months.map(month => {
                    let monthTotal = sortedDataArr.reduce((sum, row) => sum + (row[month] || 0), 0);
                    return (
                        <p key={month}>
                            <strong>{month}:</strong> {monthTotal}
                        </p>
                    );
                })}
                <p>
                    <strong>Total:</strong>
                    {sortedDataArr.reduce((sum, row) => sum + (row.total || 0), 0)}
                </p>
            </div>
        </div>
    );
}

export default TableUsabilidade;
