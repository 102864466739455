import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import api_amr from 'services/api_amr';
import { getToken } from 'utils/utils';
import backLogo from '../../../assets/img/logo_arm_opaque.png'
import { makeStyles } from "@material-ui/core/styles";


export default function RelacaoTrocaFormulacoes({ parametros }) {
    const [inputData, setInputData] = useState([])
    const [previousYearData, setPreviousYearData] = useState([])
    const [previousPYearData, setPreviousPYearData] = useState([])

    const [formattedDatesCurrent, setFormattedDatesCurrent] = useState([]);
    const [formattedDates, setFormattedDates] = useState([]);
    const [info, setInfo] = useState([]);
    const classes = useStyles()

    useEffect(() => {
        const fetchMyApi = async () => {
            const response = await api_amr.get('/return_component_relTroca', {
                params: {
                    'formato': 'grafico', 'parametros': { ...parametros, data: '2024-01-01' }
                },
                headers: { 'x-api-key': getToken() }
            })

            setInputData(response.data.dados.current_year)
            setPreviousYearData(response.data.dados.previous_year)
            setPreviousPYearData(response.data.dados.previous_p_year)

            setInfo(response.data.info)

            const newFormattedDates = response.data.dados.previous_year.map((item) => {
                const [year, month, day] = item.data.split('-');
                return `${day}/${month}/${year}`;
            });

            setFormattedDates(newFormattedDates);
            const newFormattedDatesCurrent = response.data.dados.current_year.map((item) => {
                const [year, month, day] = item.data.split('-');
                return `${day}/${month}/${year}`;
            });
            setFormattedDatesCurrent(newFormattedDatesCurrent);
        }

        fetchMyApi()
    }, [parametros])

  

    const data = {
        labels: formattedDates,
        datasets: [
            {
                label: '2024',
                data: inputData.map(item => item.rel_troca),
                fill: false,
                borderColor: '#00BCD4', // Electric blue
                backgroundColor: 'rgba(0, 188, 212, 0.5)', // Lighter shade for background
                pointRadius: 0,
            },
            {
                label: '2023',
                data: previousYearData.map(item => item.rel_troca),
                fill: false,
                borderColor: '#FF7043', // Coral
                backgroundColor: 'rgba(255, 112, 67, 0.5)', // Lighter shade for background
                pointRadius: 0,
            },
            {
                label: '2022',
                data: previousPYearData.map(item => item.rel_troca),
                fill: false,
                borderColor: '#4CAF50', // Olive green
                backgroundColor: 'rgba(76, 175, 80, 0.5)', // Lighter shade for background
                pointRadius: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                grid: {
                    display: true,
                },
                ticks: {
                    callback: function (value, index, values) {
                        return index % 25 === 0 ? formattedDates[index] : undefined
                    },
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: ` ${info.nome_materia} X ${info.nome_cultura} em: ${info.nome_porto}`,
                font: {
                    size: 12,
                },
            },
            tooltip: {
                callbacks: {
                    title: function () {
                        return '';  // Returning an empty string to hide the title
                    },
                    label: function (context) {
                        const labelYear = context.dataset.label;
                        const labelValue = context.parsed.y;
                        const labelDate = labelYear === '2024' ? formattedDatesCurrent[context.dataIndex] : formattedDates[context.dataIndex];
                        return `${labelYear} - ${labelDate}: ${labelValue}`;
                    }
                }
            }
        }
    }

    return (
        <div className={classes.mainDiv}>
            <img
                src={backLogo}
                alt="Logo"
                className={classes.img}
            />
            <Line data={data} options={options} />
        </div>
    )
}

const useStyles = makeStyles({
    mainDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: 'white'
    },

    img: {
        position: 'absolute',
        zIndex: 1,
        width: '50%',
        height: 'auto',
    }
})