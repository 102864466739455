import React, { useState } from 'react';
import { Button, Popover, Typography, IconButton, makeStyles, Grid, Box } from '@material-ui/core';
import chartGif from '../../../assets/img/line-chart.gif';
import chartGifStatic from '../../../assets/img/line-chart.png';
import tableGif from '../../../assets/img/database-table.gif';
import tableGifStatic from '../../../assets/img/database-table.png';
import cardGif from '../../../assets/img/credit-card.gif';
import cardGifStatic from '../../../assets/img/credit-card.png';
import FilterTableStatic from '../../../assets/img/FilterChart.png';
import FilterTableGif from '../../../assets/img/FilterChart.gif';
import NotepadStatic from '../../../assets/img/blocoNotas.png';
import NotepadGif from '../../../assets/img/blocoNotas.gif';
import EarthIconStatic from '../../../assets/img/earthIcon.png';
import EarthIcon from '../../../assets/img/earthIcon.gif';
import prevClimaticaStatic from '../../../assets/img/prevClimatica.png';
import prevClimatica from '../../../assets/img/prevClimatica.gif';
import imageUpStatic from '../../../assets/img/imageUp.png';
import imageUp from '../../../assets/img/imageUp.gif';
import CloseIcon from '@material-ui/icons/Close';

const PopoverAddComponent = ({ isOpen, anchorEl, onClose, onComponentSelect }) => {
    const classes = useStyles();
    const [hovered, setHovered] = useState(null);

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleComponentClick = (popoverSelectedComponent) => {
        handleClose();
        if (onComponentSelect) {
            onComponentSelect(popoverSelectedComponent);
        }
    };

    const options = [
        { key: 'card', name: 'Card', gif: cardGif, static: cardGifStatic },
        { key: 'tabela', name: 'Tabela', gif: tableGif, static: tableGifStatic },
        { key: 'grafico', name: 'Gráfico', gif: chartGif, static: chartGifStatic },
       // { key: 'tabelaEditavel', name: 'Tabela Editável', gif: FilterTableGif, static: FilterTableStatic },
        { key: 'note', name: 'Bloco de Anotações', gif: NotepadGif, static: NotepadStatic },
       // { key: 'mapa', name: 'Mapa de Portos', gif: EarthIcon, static: EarthIconStatic },
        { key: 'previsao', name: 'Previsão Climática', gif: prevClimatica, static: prevClimaticaStatic },
       // { key: 'imagem', name: 'Adicionar Imagem', gif: imageUp, static: imageUpStatic },
    ];

    return (
        <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Box className={classes.menu} >
                <Grid container >
                    <Grid item xs={12} className={classes.divCloseButton}>
                        <Box className={classes.closeButtonBox} >
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Grid>

                    {options.map((option, index) => (
                        <Grid item xs={4} className={classes.optionContainer} key={option.name} >
                            <Button
                                onClick={() => handleComponentClick(option.key)}
                                className={classes.titleButton}
                            >
                                <div className={classes.divButton}>
                                    <Box
                                        className={classes.imageContainer}
                                        onMouseEnter={() => setHovered(index)}
                                        onMouseLeave={() => setHovered(null)}
                                    >
                                        <img
                                            src={hovered === index ? option.gif : option.static}
                                            alt={option.name}
                                            className={classes.chartImage}
                                        />
                                    </Box>
                                    <Typography className={classes.nameContainer} variant="body1">{option.name}</Typography>
                                </div>
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Popover>
    )
}

const useStyles = makeStyles((theme) => ({
    menu: {
        padding: 10,
        width: 400,
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },

    divButton: {
        display: 'flex', flexDirection: 'column', alignItems: 'center'
    },

    divCloseButton: {
        display: 'flex',
        justifyContent: 'flex-end'
    },

    imageContainer: {
        width: '100%',
        borderRadius: '10px',
        border: '2px solid #f0f0f0',
        display: 'flex',
        padding: 16,
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    },

    chartImage: {
        width: '40px',
    },

    optionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    titleButton: {
        margin: 0,
        padding: 0,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },

    closeButton: {
        margin: 0,
        padding: 0,
        color: theme.palette.grey[500],
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.grey[700],
        },
    },

    closeButtonBox: {
        marginBottom: 5,
    },

    nameContainer: {
        maxWidth: '70px',
        textAlign: 'center',
        fontSize: '0.8rem',
        wordWrap: 'break-word',
        fontFamily: "'Poppins', sans-serif",
    }
}))

export default PopoverAddComponent;