import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, Grid, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import api_amr from 'services/api_amr';
import useStyles from '../styles';

function TagFilter({ onTagClick }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [tags, setTags] = useState([{ tag_name: 'Todas', tag_id: '' },   { "tag_id": 5, "tag_name": "Nitrogênio", "tag_image_link": "" },
    { "tag_id": 6, "tag_name": "Fósforo", "tag_image_link": "" },
    { "tag_id": 7, "tag_name": "Potássio", "tag_image_link": "" },
    { "tag_id": 18, "tag_name": "Destaques da Semana", "tag_image_link": "" },
    { "tag_id": 11, "tag_name": "Mapeamento de Preços", "tag_image_link": "" },
    { "tag_id": 10, "tag_name": "Andamento de Mercado", "tag_image_link": "" },
    { "tag_id": 19, "tag_name": "Enxofre, Ácidos e Rocha", "tag_image_link": "" },
    { "tag_id": 20, "tag_name": "Gás Natural e Amônia", "tag_image_link": "" },
    { "tag_id": 14, "tag_name": "Micronutriente", "tag_image_link": "" },
    { "tag_id": 8, "tag_name": "Indústria e Mineração", "tag_image_link": "" },
    { "tag_id": 21, "tag_name": "Logística e Importação", "tag_image_link": "" },
    { "tag_id": 16, "tag_name": "Informativos Técnicos de Fertilizantes", "tag_image_link": "" },
    { "tag_id": 17, "tag_name": "Legislação de Fertilizantes", "tag_image_link": "" },
    { "tag_id": 1, "tag_name": "Geopolitica", "tag_image_link": "" },
    { "tag_id": 2, "tag_name": "Macroeconomia", "tag_image_link": "" },
    { "tag_id": 3, "tag_name": "Agricultura Brasil", "tag_image_link": "" },
    { "tag_id": 4, "tag_name": "Agricultura Mundo", "tag_image_link": "" }]);
    const [additionalTags, setAdditionalTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState('');

    const theme = useTheme();

    const isSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'md'));
    const isLg = useMediaQuery(theme.breakpoints.between('lg', 'lg'));
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));

    const getVisibleTags = () => {
        if (isSm) return 3;
        if (isMd) return 4;
        if (isLg) return 6;
        if (isXl) return 7;
        return 7; // Default value
    };
    const visibleTags = getVisibleTags();
    useEffect(() => {
        fetchMyApi();
    }, []);

    const fetchMyApi = async () => {
        // const responseTags = await api_amr.get('/return_used_tags');
        // setTags([...tags, ...responseTags.data]);

        setAdditionalTags(tags.slice(visibleTags));
    };

    const handleTagClick = (tag) => {
        onTagClick(tag);
        setSelectedTag(tag.tag_id);
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        if (additionalTags.length === 0) {
            return
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box className={classes.tagFilterRoot}>
            {tags.slice(0, visibleTags).map((tag) => {
                const isSelected = selectedTag === tag.tag_id;
                const buttonStyle = {
                    color: isSelected ? "#A3CC51" : "#3D3D3D",
                };
                return (
                    <Box key={tag.tag_id} mr={1} mb={1}>
                        <button
                            onClick={() => handleTagClick(tag)}
                            className={classes.tagFilterButton}
                            style={buttonStyle}
                        >
                            {tag.tag_name}
                        </button>
                    </Box>
                );
            })}

            {additionalTags.length > 0 && (
                <Box mb={1}>
                    <Button className={`${classes.tagFilterButton} ${classes.outrosButton}`} onClick={handleClick}>
                        Outros
                    </Button>
                </Box>
            )}

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transitionDuration={{ enter: 150, exit: 150 }}
            >
                <Grid container item justifyContent="center" className={`${classes.gridContainer} ${classes.tagListGrid}`}>
                    {additionalTags.concat(Array(4 - (additionalTags.length % 4)).fill(null)).map((tag, tagIndex) => (
                        <Grid
                            item
                            container
                            xs={3}
                            key={tagIndex}
                            justifyContent="center"
                            className={`${classes.tagColumn} ${tagIndex % 4 === 3 ? classes.noBorder : ''}`}
                        >
                            {tag && (
                                <MenuItem
                                    onClick={() => handleTagClick(tag)}
                                    classes={{
                                        root: classes.tagFilterButton,
                                        selected: classes.tagFilterButtonSelected,
                                    }}
                                    className={`${classes.menuItem} ${additionalTags.indexOf(tag) % 4 === 3 ? classes.tagColumn : ''}`}
                                    style={{ color: selectedTag === tag.tag_id ? "#A3CC51" : "#3D3D3D" }}
                                    onMouseEnter={(e) => (e.target.style.color = '#A3CC51')}
                                    onMouseLeave={(e) => (e.target.style.color = selectedTag === tag.tag_id ? '#A3CC51' : '#3D3D3D')}
                                >
                                    {tag.tag_name}
                                </MenuItem>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </Menu>
        </Box>
    );
}

export default TagFilter;

