import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import api_amr from '../../services/api_amr';
import { getToken } from '../../utils/utils';

const formatDate = (date) => {
    const d = new Date(date);
    const localDate = new Date(d.getTime() + d.getTimezoneOffset() * 60000);
    const day = `0${localDate.getDate()}`.slice(-2);
    const month = `0${localDate.getMonth() + 1}`.slice(-2);
    const year = localDate.getFullYear();
    return `${day}/${month}/${year}`;
};

const RecomendacoesTable = ({ recomendacoes, onDelete }) => {
    const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleClickOpen = (id) => {
        setSelectedId(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedId(null);
    };

    const handleDelete = async () => {
        try {
            const response = await api_amr.delete('/delete_recomendacao', {
                headers: { 'x-api-key': getToken() },
                params: { id: selectedId }
            });
            if (response.data === 'OK') {
                onDelete(selectedId);
                handleClose();
            } else {
                alert('Erro ao deletar recomendação:', response.data);
            }
        } catch (error) {
            alert('Erro ao deletar recomendação:', error);
        }
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Data da Recomendação</TableCell>
                            <TableCell>Cotação (R$)</TableCell>
                            <TableCell>Custo CFR ($)</TableCell>
                            <TableCell>Frete (R$)</TableCell>
                            <TableCell>Cidade</TableCell>
                            <TableCell>Observações</TableCell>
                            <TableCell>Fornecedor</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {recomendacoes?.map((recomendacao) => (
                            <TableRow key={recomendacao.id}>
                                <TableCell>{formatDate(recomendacao.data_recomendacao)}</TableCell>
                                <TableCell>{recomendacao.cotacao}</TableCell>
                                <TableCell>{recomendacao.custo_cfr}</TableCell>
                                <TableCell>{recomendacao.frete}</TableCell>
                                <TableCell>{recomendacao.cidade === "0" ? "Sem Cidade" : `${recomendacao.cidade} - ${recomendacao.UF}`}</TableCell>
                                <TableCell>{recomendacao.observacoes}</TableCell>
                                <TableCell>{recomendacao.fornecedor}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleClickOpen(recomendacao.id)} aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmar Exclusão"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Você tem certeza que deseja deletar a recomendação?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RecomendacoesTable;
