import React, { useEffect, useState } from "react";
import './Dashboard.css';
import { checarPermissao } from '../../utils/utils';
import Loader from 'react-loader-spinner';

function PowerBIImportacoes(props) {

  const [permitido, setPermitido] = useState(false);

  useEffect(() => {
    async function fetchMyAPI() {
      setPermitido(await checarPermissao('importacao_comex_pwbi', props));
    }

    fetchMyAPI();
  }, [props]);

  return (
    permitido ? (
      <div style={{ height: '102vh', width: '90vw' }}>
        <iframe
          title="PowerBI Report"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiNDExYTM0NWUtNWZjZS00MmQzLTgxZmUtOWYxZWYyMWUwYTA3IiwidCI6IjhjZjc4MWEwLTEyNzYtNDJkOS04OTM0LWRmM2E0NGQ2Y2NhZCJ9"
          frameBorder="0"
          allowFullScreen={true}
        ></iframe>
      </div>
    ) : (
      <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    )
  );
}

export default PowerBIImportacoes;
