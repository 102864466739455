import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getToken, checarPermissao } from '../../utils/utils'
import api_amr from '../../services/api_amr'
import Loader from 'react-loader-spinner';
import Button from "@material-ui/core/Button";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function ProdutosPadraoAssociados(props) {
    const [permitido, setPermitido] = useState(false)
    const [produtosPadraoAssociados, setProdutosPadraoAssociados] = useState('')
    const [fechamentos, setFechamentos] = useState('')



    const classes = useStyles();

    useEffect(() => {
        async function fetchMyAPI() {

            setPermitido(await checarPermissao('produtos_padrao_associados', props))
            getProdutosPadraoAssociados()
            getReturnFechamentos()
        }
        fetchMyAPI();
    }, []);


    async function getProdutosPadraoAssociados() {

        const response_produtos = await api_amr.get('/return_produtos_padrao', { params: { 'all': 1 }, headers: { 'x-api-key': getToken() } });

        setProdutosPadraoAssociados(response_produtos.data)

    }

    async function getReturnFechamentos() {

        const response_fechamentos = await api_amr.get('/get_fechamentos', { params: { 'all': 1 }, headers: { 'x-api-key': getToken() } });
        setFechamentos(response_fechamentos.data)


    }


    return (
        permitido ?
            <div className={classes.container} >
                <div >
                    <h2 className={classes.inputh1}>Produtos Padrão Associados</h2>
                </div>
                <div style={{ flexDirection: 'column', width: window.innerWidth - 400 }}>

                    <div>
                        <TableContainer className={classes.tableModels}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead >
                                    <TableRow >

                                        <TableCell >
                                            Id
                                        </TableCell>
                                        <TableCell >
                                            Nome do Produto
                                        </TableCell>
                                        <TableCell >
                                            Fósforo
                                        </TableCell>
                                        <TableCell >
                                            Potássio
                                        </TableCell>
                                        <TableCell >
                                            Nitrogênio
                                        </TableCell>
                                        <TableCell >
                                            Fechamentos
                                        </TableCell>


                                    </TableRow>
                                </TableHead>


                                <TableBody>
                                    {Object.keys(produtosPadraoAssociados).map((item, index) => (

                                        <TableRow key={index}>




                                            <TableCell>
                                                {produtosPadraoAssociados[item].id}
                                            </TableCell>
                                            <TableCell>
                                                {produtosPadraoAssociados[item].nome_produto}
                                            </TableCell>
                                            <TableCell>
                                                {produtosPadraoAssociados[item].fosforo}
                                            </TableCell>
                                            <TableCell>
                                                {produtosPadraoAssociados[item].potassio}
                                            </TableCell>
                                            <TableCell>
                                                {produtosPadraoAssociados[item].nitrogenio}
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    {Object.keys(fechamentos).map((item_, index) => (

                                                        produtosPadraoAssociados[item].id === fechamentos[item_].id_produto ? < p>{fechamentos[item_].abreviacao} &nbsp; &nbsp; {fechamentos[item_].kg + ' kg'} <br /> </p> : ""

                                                    ))}
                                                </div>

                                            </TableCell>

                                        </TableRow>))
                                    }</TableBody>
                            </Table>
                        </TableContainer >

                    </div>



                </div>
            </div > : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 100,
    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    noBorder: {
        border: "none",
    },
    autoCompleteFieldSmall: {
        marginTop: 15,
        minWidth: 104,
        maxWidth: 104,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteField: {
        marginTop: 15,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 15,
        width: 471,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    inputCheckbox: {

        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000'
    },
    inputh1: {
        fontFamily: 'Poppins',

        color: '#383838'
    },
    root: {
        "&$checked": {
            color: "black",
            backgroundColor: 'white'
        }
    },
    checked: {
        borderColor: 'black'
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
    calcDiv: {
        marginTop: 40,
        width: '146px',
        marginLeft: 325,
        height: 45
    },
    nutriDiv: {
        paddingRight: 28,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 228
    },
    inputForm: {
        width: '50%',
        fontFamily: 'Poppins',
        color: '#383838'
    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    }, table: {
        minWidth: 400,
        maxWidth: '100%'
    },
    rowSize: {
        maxWidth: 500
    },
    tableModels: {
        marginTop: 20,
        maxWidth: '90%',
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
}));

export default ProdutosPadraoAssociados; 
