import React, { useEffect, useRef } from 'react';
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default function PortosMapaComponent() {
  const mapContainer = useRef(null);
  const mapInstance = useRef(null);

  useEffect(() => {
    if (!mapContainer.current) {
      return;
    }

    const map = L.map(mapContainer.current).setView([-23.9505, -46.3353], 4);
    mapInstance.current = map;

    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(map);

    const ports = [
      { name: "Porto de Santos", coordinates: [-23.9505, -46.3353] },
      { name: "Porto de Paranaguá", coordinates: [-25.5075, -48.5228] },
      { name: "Porto de Rio Grande", coordinates: [-32.0349, -52.0986] },
      { name: "Porto de Vitória", coordinates: [-20.3195, -40.3378] },
      { name: "Porto de Aratu", coordinates: [-12.8135, -38.4103] },
      { name: "Porto de Itaqui", coordinates: [-2.5616, -44.3695] },
      { name: "Porto de Santarém", coordinates: [-2.4385, -54.6996] },
      { name: "Porto de Antonina", coordinates: [-25.4263, -48.7129] },
      { name: "Porto de São Francisco do Sul", coordinates: [-26.2425, -48.6344] },
      { name: "Porto de Imbituba", coordinates: [-28.1278, -48.6548] },
      { name: "Porto de Barcarena", coordinates: [-1.5113, -48.6195] },
    ];

    ports.forEach((port, index) => {
      L.circleMarker(port.coordinates, {
        color: "blue",
        fillColor: `#${(index * 20).toString(16)}8888`,
        fillOpacity: 0.5,
        radius: 6,
      }).addTo(map)
        .bindPopup(`
            ${port.name} <br>
        `);
    });

  }, [mapContainer]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (mapInstance.current) {
        mapInstance.current.invalidateSize();
      }
    });

    resizeObserver.observe(mapContainer.current);

    // Cleanup observer on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, [mapContainer]);

  return <div ref={mapContainer} style={{ height: 540, width: "100%" }}></div>;
}
