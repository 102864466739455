import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getToken, checarPermissao } from '../../utils/utils'
import api_amr from '../../services/api_amr'
import Loader from 'react-loader-spinner';
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MuiPhoneInput from 'material-ui-phone-number';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import RegistradorPrecos from "./RegistradorPrecos";




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function a11yProps2(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function ConfigMaterias(props) {
    const [materias, setMaterias] = useState('')
    const [permitido, setPermitido] = useState(false)
    const [toUpdate, setToUpdate] = useState([]);
    const [materiaInsert, setMateriaInsert] = useState({})
    const [compatibilidade, setCompatibilidade] = useState('')
    const [update, setUpdate] = useState([])
    const [salvando, setSalvando] = useState(false)
    const [materiasArray, setMateriasArray] = useState('')
    const [selectedMateria, setSelectedMateria] = useState(true)
    const [selectedMateria2, setSelectedMateria2] = useState(true)
    const [compatibilidadeArray, setCompatibilidadeArray] = useState('')
    const [openCol, setOpenCol] = useState([])
    const [dropdown, setDropdown] = useState(false)
    const [materiasId, setMateriasId] = useState('')

    let auxRelTroca = []
    let auxPrecoPorto = []
    // const [abreviacao, setAbreviacao] = useState('');
    // const [descricao, setDescricao] = useState('');
    // const [tipo, setTipo] = useState('');
    // const [nitrogenio, setNitrogenio] = useState(0);
    // const [p2o5, setP2o5] = useState(0);
    // const [k2o, setK2o] = useState(0);
    // const [ca, setCa] = useState(0);
    // const [s, setS] = useState(0);
    // const [mg, setMg] = useState(0);
    // const [zn, setZn] = useState(0);
    // const [b, setB] = useState(0);

    // const [cu, setCu] = useState(0);
    // const [mn, setMn] = useState(0);
    // const [mo, setMo] = useState(0);
    // const [si, setSi] = useState(0);
    // const [cl, setCl] = useState(0);
    // const [is_, setIs_] = useState(0);
    // const [price, setPrice] = useState(0);
    // const [frete_mar, setFrete_mar] = useState(0);
    // const [ativo_formulas, setAtivo_formulas] = useState(1);
    const classes = useStyles();
    const theme = createTheme({
        palette: {
            primary: {
                main: '#A3CC51'
            },

        }
    });

    const handleChange = (event, newValue) => { setValue(newValue) }
    const [value, setValue] = React.useState('1');

    const nomenclatura = {
        abreviacao: ' Abreviação', nitrogenio: 'Nitrogênio', potassio: 'Potássio', fosforo: 'Fósforo',
        tipo: 'Tipo', descricao: 'Descrição', preco: 'Preço', is_: 'Índice Salino', frete_mar: 'Frete Marítimo', cfr: 'Custo Frete',
        relacao_de_troca: 'Relação de Troca', precifica_porto: 'Precifica Porto', ativo: 'Ativo Fórmulas',
        ca: 'Cálcio', s: 'Enxofre', mg: 'Magnésio', zn: 'Zinco', b: 'Boro',
        cu: 'Cobre', mn: 'Manganês', mo: 'Molibdênio', si: 'Silício', cl: 'Cloro'
    }

    useEffect(() => {
        async function fetchMyAPI() {

            setPermitido(await checarPermissao('configuracoes_materias', props))

            const response_materias = await api_amr.get('/get_materias', { headers: { 'x-api-key': getToken() } });
            setMaterias(response_materias.data);

            const response_compat = await api_amr.get('/get_compatibilidade', { headers: { 'x-api-key': getToken() } });
            setCompatibilidade(response_compat.data);

            setMateriasArray(Array.from({ length: response_materias.data.length }, i => i = false))
            setValue(0)


        }
        fetchMyAPI();
    }, []);


    async function alterSelected(index, estadoBotao, id) {
        // if (openCol !== []) { setOpenCol([]) }
        setOpenCol([])
        setDropdown(estadoBotao)
        alterSelectedMateria(index)
        getMateriasId(id)
        let aux = Array.from({ length: materiasArray.length }, i => i = false)
        aux[index] = estadoBotao
        setMateriasArray(JSON.parse(JSON.stringify(aux)))

    }

    async function alterSelected2(index, estadoBotao) {

        let aux = Array.from({ length: compatibilidadeArray.length }, i => i = false)
        aux[index] = estadoBotao
        setCompatibilidadeArray(JSON.parse(JSON.stringify(aux)))
        if (estadoBotao === true) {
            setSelectedMateria2(false)
        } else {
            setSelectedMateria2(false)
        }
    }

    const handleUpdateMaterias = (item, item_, target) => {

        let aux = toUpdate;
        aux[materias[item]['id_materias'] + '-' + item_] = target;
        setToUpdate(aux);

    };

    async function handlePriceChange() {
        let aux = '';

        Object.keys(toUpdate).forEach((item) => (aux = aux + item + '#' + toUpdate[item] + ';'));



        const response = await api_amr.post('/atualiza_materias',
            {
                toUpdate: aux,
            }, { headers: { 'x-api-key': getToken() } });

        if (response.data === 'NOK') {
            alert('Erro ao atualizar matérias!');
            window.location.reload();
        } else {
            alert('Preços alterados com sucesso!');
            window.location.reload();
        }
    }

    const handleInsertChange = (item_, target) => {
        let aux = materiaInsert;
        aux[item_] = target;
        setMateriaInsert(aux);
    };

    async function insertMateria() {

        const response = await api_amr.post('/insert_materia', {
            materiaInsert
        }, { headers: { 'x-api-key': getToken() } });
        if ((materiaInsert['abreviacao'] === '') | (materiaInsert['descricao'] === '')) {
            alert('Adicione pelo menos Abreviação, Tipo e Descrição para a matéria-prima!');
            window.location.reload();

        }

        if (response.data === 'NOK1') {
            alert('Erro: Nenhum campo preenchido');
            window.location.reload();

        }
        if (response.data === 'NOK2') {
            alert('Erro: Já há uma matéria com essa abreviação cadastrada');
            window.location.reload();

        }
        if (response.data === 'NOK') {
            alert('Erro ao inserir matéria');
            window.location.reload();

        }
        if (response.data === 'OK') {
            alert('Matéria-prima inserida com sucesso');
            window.location.reload();

        }
    };

    function montaUpdate(valor, idGrupo, target) {
        // Validação dos valores inseridos
        if (valor !== "1" && valor !== "0" && valor !== "-" && valor !== "-1" && valor !== "") {
            alert('Atenção aos valores inseridos!\n  Compatível=1,\n  Limitada=1,\n  Incompatível=-1,\n\n Valores inseridos de forma errada serão considerados como incompativeis ')
            valor = "-1"
        }
        if (valor === "") {
            valor = "-1"
        }
        let aux = update
        aux[idGrupo + target] = idGrupo + "_*_" + target + "_*_" + valor + ';'

        setUpdate(aux)

    }

    async function salvarUpdate() {
        setSalvando(true)
        let stringUpdate = ''

        Object.keys(update).forEach((key) => stringUpdate += update[key])


        if (stringUpdate === '') {
            alert('Faça alguma modificação antes de salvar...')
        } else {
            const resultado = await api_amr.post('update_compatibilidade', {
                stringUpdate,
            }, { headers: { 'x-api-key': getToken() } })
            if (resultado.data === 'OK') {
                alert('Compatibilidades atualizadas com sucesso')
            }
        }
        setSalvando(false)
    }
    const atualizaRelTrocaAux = (valores) => {
        auxRelTroca = valores;
    };
    const atualizaPrecPortoAux = (valores) => {
        auxPrecoPorto = valores;
    };
    const atualizaRelTroca = (valores) => {

        let stringUpdate = ''
        Object.keys(auxRelTroca).forEach((key) => stringUpdate += update[key])

    };
    const atualizaPrecPorto = (valores) => {
        let stringUpdate = ''
        Object.keys(auxPrecoPorto).forEach((key) => stringUpdate += update[key])

    };
    async function getMateriasId(index) {
        setHistoricoCFR('');
        setLoading(true);
    
        const response_materiasId = await api_amr.get('/get_materias_id', { 
            params: { 'id_materias': index }, 
            headers: { 'x-api-key': getToken() } 
        });
        console.log('a')
        const materiasData = response_materiasId.data;
        setMateriasId(materiasData);
    
        const response_historico = await api_amr.get('/historico_cfr_materias', { 
            params: { 'materia': index }, 
            headers: { 'x-api-key': getToken() } 
        });
        const historicoData = response_historico.data;
    
        if (materiasData.length > 0 && historicoData) {
            historicoData.forEach(item => {
                item.materiaName = materiasData[0].descricao; 
            });
        }
    
        setHistoricoCFR(historicoData);
        setLoading(false);
    }  
    const [historicoCFR, setHistoricoCFR] = useState('')
    const [selectedHist, setSelectedHist] = useState('')
    const [loading, setLoading] = useState(false)
   
    async function alterSelectedMateria(index) {


        let aux = Array.from({ length: openCol.length }, i => i = false)
        aux[index] = true
        setOpenCol(JSON.parse(JSON.stringify(aux)))


    }
    function exportToCSV(apiData, fileName) {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    return (
        permitido ?


            <div >

                {/* DIV PRINCIPAL */}
                <div style={{ borderRadius: 10, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', overflow: 'auto', background: "#FAFCF6", marginTop: '10px', width: "100%", display: 'flex', flexDirection: 'column', }}>
                    <Box >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <MuiThemeProvider theme={theme}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    
                                    <Tab
                                        title='Insira dados das matérias para cadastro'
                                        label={<b className={classes.inputTitleTab}>Cadastrar Matéria</b>}
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        label={<b className={classes.inputTitleTab}>Matérias Cadastradas</b>}
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        title='Compatível = 1 ,Limitada = 0, Incompatível = -1'
                                        label={<b className={classes.inputTitleTab}>Compatibilidade entre matérias-primas</b>}
                                        {...a11yProps(2)}
                                    />
                                    <Tab
                                        title='Edição de preços'
                                        label={<b className={classes.inputTitleTab}>Edição de preços</b>}
                                        {...a11yProps(3)}
                                    />
                                    <Tab
                                        title='Preços Cadastrados'
                                        label={<b className={classes.inputTitleTab}>Preços Cadastrados</b>}
                                        {...a11yProps(4)}
                                    />
                                     
                                    
                                    {/* ... outros Tabs, se necessário ... */}
                                </Tabs>
                            </MuiThemeProvider>


                        </Box>
                        <TabPanel value={value} index={0} >
                            <div style={{ display: 'flex', flexDirection: 'row' }} >
                                <div >
                                    <div style={{ justifyContent: 'left', marginTop: '-10px', flexWrap: 'wrap', display: 'flex', flexDirection: 'row', width: '1185px' }}>
                                        {materias !== '' ?
                                            Object.keys(materias[0]).map((item_elemnt, index_element) =>
                                                ['id_materias'].includes(item_elemnt) ? null :

                                                    <div style={{ justifyContent: 'center', flexWrap: 'wrap', display: 'flex', flexDirection: 'column', marginTop: '10px', marginLeft: '10px' }}>
                                                        <><b className={classes.inputh1}>{nomenclatura[item_elemnt]}</b>

                                                            <TextField
                                                                key={item_elemnt}
                                                                variant='outlined'

                                                                className={classes.fieldText}
                                                                onChange={(e) => handleInsertChange(item_elemnt, e.target.value)}
                                                                style={{ borderRadius: 10 }}
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    classes: { notchedOutline: classes.noBorder }
                                                                }} />

                                                        </>
                                                    </div>
                                            )

                                            : <CircularProgress />}
                                        <div style={{ marginLeft: '172.5px', marginTop: '29px' }}>

                                            <Button onClick={() => insertMateria()} className={classes.calcButton}>Cadastrar Matéria</Button>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {materias !== '' ?
                                <TableContainer style={{ borderRadius: 10, marginTop: "-13px", height: "60vh", width: "88vw" }} className={classes.tableModels}>
                                    <TableHead style={{ width: '85vw', background: '#A3CC51', color: 'black', top: 0, left: 0, zIndex: 2, zIndex: 3, position: 'sticky' }} >
                                        <TableRow>
                                            <TableCell align="center" width={100} >
                                                <b className={classes.inputh1}>Selecionar</b>
                                            </TableCell>

                                            <TableCell align="center" width={100}>
                                                <b className={classes.inputh1}>Grupo</b>
                                            </TableCell>
                                            <TableCell align="center" width={100}>
                                                <b className={classes.inputh1}>Descrição</b>
                                            </TableCell>
                                            <TableCell align="center" width={100}>
                                                <b className={classes.inputh1}>Índice Salino</b>
                                            </TableCell>
                                            <TableCell align="center" width={100}>
                                                <b className={classes.inputh1}>FOB origem ($) </b>
                                            </TableCell>
                                            <TableCell align="center" width={100}>
                                                <b className={classes.inputh1}>Frete Marítimo ($)</b>
                                            </TableCell>
                                            <TableCell align="center" width={100}>
                                                <b className={classes.inputh1}>Custo Frete CFR ($/t) </b>
                                            </TableCell>
                                            <TableCell align="center" width={100}>
                                                <b className={classes.inputh1}>Ativo Fórmulas</b>
                                            </TableCell>
                                            <TableCell align="center" width={100}>
                                                <b className={classes.inputh1}>Precificação Porto</b>
                                            </TableCell>
                                            <TableCell align="center" width={100}>
                                                <b className={classes.inputh1}>Relação de Troca</b>
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(materias).map((item, index) => (
                                            <><TableRow key={index} style={{ backgroundColor: materiasArray[index] === true ? '#e9f5d6' : 'white' }}>
                                                <TableCell align="center" style={{ width: "100px", alignItems: 'center', display: 'flex', flexDirection: 'row', background: '#A3CC51', color: 'white' }}>

                                                    {materiasArray[index] === true ? <IconButton
                                                        style={{ color: '#383838' }}
                                                        aria-label="toggle password visibility"
                                                        onClick={() => (alterSelected(index, false), setOpenCol([]), setDropdown(false))}
                                                    >

                                                        <CheckCircleOutlineIcon />
                                                    </IconButton> : <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => (alterSelected(index, true, materias[item].id_materias), setDropdown(false))}

                                                    >
                                                        <RadioButtonUncheckedIcon />
                                                    </IconButton>}

                                                    <b className={classes.inputh1}>{materias[item].abreviacao}</b>
                                                </TableCell>

                                                {Object.keys(materias[item]).map((item_elemnt, index_element) => ['abreviacao', 'id_materias', 'nitrogenio', 'fosforo', 'potassio',
                                                    'ca', 's', 'mg', 'zn', 'b', 'cu', 'mn', 'mo', 'si', 'cl',].includes(item_elemnt) ? null :
                                                    <TableCell key={item_elemnt} align="center" >
                                                        {materiasArray[index] !== true ? (toUpdate[materias[item]['id_materias'] + '-' + item_elemnt] == undefined ?
                                                            materias[item][item_elemnt] : toUpdate[materias[item]['id_materias'] + '-' + item_elemnt]) :
                                                            <TextField
                                                                key='item_elemnt'
                                                                defaultValue={toUpdate[materias[item]['id_materias'] + '-' + item_elemnt] == undefined ? materias[item][item_elemnt] : toUpdate[materias[item]['id_materias'] + '-' + item_elemnt]}
                                                                variant='outlined'
                                                                onChange={(e) => (handleUpdateMaterias(item, item_elemnt, e.target.value))}
                                                                InputProps={{
                                                                    style: { fontSize: 14 },
                                                                    disableUnderline: true,
                                                                    //classes: { notchedOutline: classes.noBorder }
                                                                }} />}

                                                    </TableCell>)}
                                            </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                                                        <Collapse in={openCol[item]} timeout="auto" unmountOnExit>
                                                            <Box sx={{ margin: 0 }}>
                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    <b className={classes.inputh1}> Composição da Matéria</b>
                                                                </Typography>

                                                                <TableContainer>

                                                                    <Table aria-label="simple table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell width={50}>
                                                                                    <b className={classes.inputh1}>Histórico CFR</b>
                                                                                </TableCell>
                                                                                <TableCell width={50}>
                                                                                    <b className={classes.inputh1}>Nitrogênio</b>
                                                                                </TableCell>
                                                                                <TableCell width={50}>
                                                                                    <b className={classes.inputh1}>Fósforo</b>
                                                                                </TableCell>
                                                                                <TableCell width={200}>
                                                                                    <b className={classes.inputh1}>Potássio</b>
                                                                                </TableCell>
                                                                                <TableCell width={200}>
                                                                                    <b className={classes.inputh1}>Cálcio</b>
                                                                                </TableCell>
                                                                                <TableCell width={200}>
                                                                                    <b className={classes.inputh1}>Enxofre</b>
                                                                                </TableCell>
                                                                                <TableCell width={200}>
                                                                                    <b className={classes.inputh1}>Magnésio</b>
                                                                                </TableCell>
                                                                                <TableCell width={200}>
                                                                                    <b className={classes.inputh1}>Zinco</b>
                                                                                </TableCell>
                                                                                <TableCell width={200}>
                                                                                    <b className={classes.inputh1}>Boro</b>
                                                                                </TableCell>
                                                                                <TableCell width={200}>
                                                                                    <b className={classes.inputh1}>Cobre</b>
                                                                                </TableCell>
                                                                                <TableCell width={200}>
                                                                                    <b className={classes.inputh1}>Manganês</b>
                                                                                </TableCell>
                                                                                <TableCell width={200}>
                                                                                    <b className={classes.inputh1}>Molibdênio</b>
                                                                                </TableCell>
                                                                                <TableCell width={200}>
                                                                                    <b className={classes.inputh1}>Silício</b>
                                                                                </TableCell>
                                                                                <TableCell width={200}>
                                                                                    <b className={classes.inputh1}>Cloro</b>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                                                                            {Object.keys(materiasId).map((item, index) => (
                                                                                <TableRow>
                                                                                    <TableCell align="center">
                                                                                        {((historicoCFR !== '')) ?
                                                                                            <button onClick={() => (exportToCSV(historicoCFR, 'historico_cfr'))}>Baixar</button> :
                                                                                            (loading === false ? <Button
                                                                                                className={classes.calcButton} // Add your button styles here
                                                                                                onClick={() => (setSelectedHist(materias[item].id_materias))} // Function to be called on click
                                                                                                variant="contained" // Optional: Adds a background color to the button. You can choose other variants.
                                                                                                color="primary" // Optional: Sets the color of the button. You can choose other colors.
                                                                                            >Histórico CFR</Button> : <Loader type="ThreeDots" color="#A3CC51" height="20" width="20" />)}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <b className={classes.inputh1}> {materiasId[item].nitrogenio}</b>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <b className={classes.inputh1}> {materiasId[item].fosforo}</b>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <b className={classes.inputh1}>  {materiasId[item].potassio}</b>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <b className={classes.inputh1}>  {materiasId[item].ca}</b>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <b className={classes.inputh1}>  {materiasId[item].s}</b>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <b className={classes.inputh1}>  {materiasId[item].mg}</b>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <b className={classes.inputh1}>  {materiasId[item].zn}</b>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <b className={classes.inputh1}>  {materiasId[item].b}</b>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <b className={classes.inputh1}>  {materiasId[item].cu}</b>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <b className={classes.inputh1}>  {materiasId[item].mn}</b>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <b className={classes.inputh1}>  {materiasId[item].mo}</b>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <b className={classes.inputh1}>  {materiasId[item].si}</b>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <b className={classes.inputh1}>  {materiasId[item].cl}</b>
                                                                                    </TableCell>


                                                                                </TableRow>

                                                                            ))}
                                                                        </TableBody>

                                                                    </Table>


                                                                </TableContainer>

                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>

                                                </TableRow></>))}


                                    </TableBody>
                                </TableContainer> : <CircularProgress />}
                            <Button onClick={() => handlePriceChange()} className={classes.calcButton}>Atualizar</Button>

                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div style={{ display: 'flex', flexDirection: 'column', height: "100%", }}>
                                <b className={classes.inputh1}> </b>
                                <div>

                                    {compatibilidade !== '' ?
                                        <TableContainer style={{ borderRadius: 10, marginTop: "-13px", height: "57vh", width: "87vw", }} className={classes.tableModels}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead style={{ background: '#A3CC51', color: 'white', left: 0, top: 0, zIndex: 3, position: 'sticky' }} >
                                                    <TableRow>
                                                        <TableCell align="center" >
                                                            <b className={classes.inputh1}>Selecionar</b>
                                                        </TableCell>


                                                        {Object.keys(compatibilidade).map((item, index) => (

                                                            <TableCell align="center" width={10}>
                                                                <b className={classes.inputh1}>{compatibilidade[item].abreviacao}</b>
                                                            </TableCell>))}


                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Object.keys(compatibilidade).map((item, index) => (
                                                        <TableRow key={index} style={{ backgroundColor: compatibilidadeArray[index] === true ? '#e9f5d6' : 'white' }} >
                                                            <TableCell align="center" style={{ width: "100px", alignItems: 'center', display: 'flex', flexDirection: 'row', background: '#A3CC51', color: 'white' }}>

                                                                {compatibilidadeArray[index] === true ? <IconButton
                                                                    style={{ color: '#383838' }}
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => alterSelected2(index, false)}
                                                                >
                                                                    < CheckCircleOutlineIcon />
                                                                </IconButton> : <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => alterSelected2(index, true)}

                                                                >
                                                                    <RadioButtonUncheckedIcon />
                                                                </IconButton>}
                                                                <b className={classes.inputh1}>{compatibilidade[item].abreviacao}</b>
                                                            </TableCell>

                                                            {Object.keys(compatibilidade[item]).map((item_elemnt, index_element) =>
                                                                ['abreviacao', 'id_materias'].includes(item_elemnt) ? null :
                                                                    <TableCell key={item_elemnt} align="center" style={{ align: "center" }} width={100}>
                                                                        {compatibilidadeArray[index] !== true ? (update[compatibilidade[item].id_materias + item_elemnt] == undefined ? compatibilidade[item][item_elemnt] : update[compatibilidade[item].id_materias + item_elemnt].split('_').pop().replace(';', '')) :
                                                                            <TextField
                                                                                key='item_elemnt'
                                                                                defaultValue={update[compatibilidade[item].id_materias + item_elemnt] == undefined ? compatibilidade[item][item_elemnt] : update[compatibilidade[item].id_materias + item_elemnt].split('_').pop().replace(';', '')}
                                                                                className={classes.fieldNecessarioPoisNaoDaCertoSemEle}

                                                                                variant='outlined'
                                                                                onChange={(e) => (montaUpdate(e.target.value, compatibilidade[item].id_materias, item_elemnt))}
                                                                                style={{ align: "center" }}
                                                                                multiline
                                                                                InputProps={{
                                                                                    style: { fontSize: 14 },
                                                                                    disableUnderline: true,
                                                                                    //classes: { notchedOutline: classes.noBorder },

                                                                                }}
                                                                            />}

                                                                    </TableCell>)}
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer> : <CircularProgress />}
                                    <Button onClick={() => salvarUpdate()} className={classes.calcButton}>Atualizar Compatibilidades</Button>
                                </div>
                            </div>


                        </TabPanel>
                        <TabPanel value={value} index={3} >
                            <div style={{ display: 'flex', flexDirection: 'column', height: "100%" }}>
                                {materias !== '' ?
                                    <TableContainer style={{
                                        borderRadius: '0.5rem',
                                        marginTop: '1rem',
                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                        height: "60vh", // Define a altura máxima do contêiner da tabela
                                        width: "88vw",
                                        overflow: 'auto' // Permite o scroll dentro do TableContainer
                                    }} className={classes.tableModels}>
                                        <TableHead style={{
                                            width: '85vw',
                                            zIndex: 3,
                                            position: 'sticky',
                                            top: 0,
                                            background: 'white'
                                        }}>
                                            <TableRow>
                                                <TableCell align="center" width={100} >
                                                    <b className={classes.inputh1}>Descrição</b>
                                                </TableCell>
                                                <TableCell align="center" width={100}>
                                                    <b className={classes.inputh1}>Preço</b>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(materias).map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center" style={{ width: "100px" }}>
                                                        <b className={classes.inputh1}>{materias[item].descricao}</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <TextField
                                                            key={item}
                                                            defaultValue={materias[item].preco}
                                                            variant='outlined'
                                                            onChange={(e) => handleUpdateMaterias(item, 'preco', e.target.value)}
                                                            InputProps={{
                                                                style: { fontSize: 14 },
                                                                disableUnderline: true,
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </TableContainer>
                                    : <CircularProgress />}
                                <Button onClick={() => handlePriceChange()} style={{
                                    marginTop: '1rem',
                                    borderRadius: '0.5rem',
                                    padding: '0.5rem 1rem',
                                    fontSize: '1rem',
                                    background: '#4CAF50',
                                    color: 'white',
                                    boxShadow: 'none',
                                    textTransform: 'none'
                                }}>Atualizar Preços</Button>
                            </div>

                        </TabPanel>
                        <TabPanel value={value} index={4} >
                                <RegistradorPrecos/>
                        </TabPanel>
                        

                    </Box>
                </div>


            </div > : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',

    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    noBorder: {
        border: "none",
    },
    autoCompleteFieldSmall: {
        marginTop: 15,
        minWidth: 104,
        maxWidth: 104,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteField: {
        marginTop: 15,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    fieldNecessarioPoisNaoDaCertoSemEle: {
        minWidth: 45,
        maxWidth: 50,
        fontSize: '9px',
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 15,
        width: 471,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    inputCheckbox: {

        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000'
    },
    inputh1: {
        fontFamily: 'Poppins',

        color: '#383838'
    },
    root: {
        "&$checked": {
            color: "black",
            backgroundColor: 'white'
        }
    },
    checked: {
        borderColor: 'black'
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '150px',
        color: 'white',
        borderRadius: 6,
        marginTop: 10,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
    calcDiv: {
        marginTop: 40,
        width: '146px',
        marginLeft: 325,
        height: 45
    },
    nutriDiv: {
        paddingRight: 28,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 228
    },
    inputForm: {
        width: '50%',
        fontFamily: 'Poppins',
        color: '#383838'
    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    },
    table: {
        minWidth: 400,
        maxWidth: '100%'
    },
    rowSize: {
        maxWidth: 500
    },
    tableModels: {
        marginTop: 20,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)',
        maxHeight: 700,
        overflow: 'auto'
    },
    fieldText: {
        backgroundColor: 'white',
        width: '220px',
        height: '49px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    }

}));

export default ConfigMaterias;
