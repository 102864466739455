import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Tabs, Tab, Box, Typography, TextField, Button, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, IconButton, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PrecificaPortoMapa from './PrecificaPortoMapa';
import BrasilState from '../../assets/br_states.json';
import CRUDMonitorBrasil from './CRUDMonitorBrasil';
import ChartsEntregaBrasil from './ChartsEntregaBrasil';
import ChartsComparativoCulturas from './ChartsComparativoCulturas';
import AtualizacoesAndamentoMercado from './AtualizacoesAndamentoMercado';
import SumarizacaoDemandas from './SumarizacaoDemandas';
import Checkbox from '@material-ui/core/Checkbox';
import { checarPermissao } from '../../utils/utils';
import Switch from '@material-ui/core/Switch';
import api from '../../services/api_amr';
import { getToken } from '../../utils/utils';
import * as turf from '@turf/turf';
import { FaCopy } from 'react-icons/fa';
import html2canvas from 'html2canvas';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '2px solid #A3CC51',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  formControl: {
    marginRight: theme.spacing(3),
    minWidth: 200,
  },
  mapContainer: {
    width: '100%',
  },
  infoContainer: {
    width: '20%',
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    zIndex: 1000,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
  infoItem: {
    marginBottom: theme.spacing(0.5),
    fontSize: '0.6rem',
    lineHeight: '0.8rem',
  },
  infoLabel: {
    fontWeight: 'bold',
  },
  infoValue: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
    wordBreak: 'break-word',
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  copyButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: '#ffffff',
    padding: theme.spacing(0.5),
    minWidth: 'auto',
    zIndex: 1100,
  },
  mapWrapper: {
    position: 'relative',
  },
  captureContainer: {
    position: 'relative',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  }
}));

const getWeekNumber = (date) => {
  const startDate = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
  return Math.ceil((days + startDate.getDay() + 1) / 7);
};

const AndamentoMercadoBrasil = (props) => {
  const classes = useStyles();
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [total1, setTotal1] = useState(null);
  const [total2, setTotal2] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [formData1, setFormData1] = useState({
    anoSafra: { id_ano_safra: 6, descricao: '2024/25' },
    cultura: { id_cultura: 10, nome_cultura: 'Soja' },
    semana: null,
  });
  const [formData2, setFormData2] = useState({
    anoSafra: { id_ano_safra: 4, descricao: '2023/24' },
    cultura: { id_cultura: 10, nome_cultura: 'Soja' },
    semana: null,
  });
  const [anosSafra, setAnosSafra] = useState([]);
  const [anosSafraD, setAnosSafraD] = useState([]); // D = demandas

  const [culturas, setCulturas] = useState([]);
  const [culturasD, setCulturasD] = useState([]);

  const [semanas, setSemanas] = useState([]);
  const [culturas2, setCulturas2] = useState([]);
  const [culturas2D, setCulturas2D] = useState([]);

  const [semanas2, setSemanas2] = useState([]);
  const [percentageDifferences, setPercentageDifferences] = useState([]);
  const captureRef1 = useRef(null);
  const captureRef2 = useRef(null);
  const [checkAssociados, setCheckAssociados] = useState(true);
  const [checkAssociados2, setCheckAssociados2] = useState(true);
  const [permitido, setPermitido] = useState(false);
  const [andamentoAMR, setAndamentoAMR] = useState(false);

  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);

  useEffect(() => {
    const fetchAnosSafra = async () => {
      setPermitido(await checarPermissao('andamento_mercado_brasil', props));
      setAndamentoAMR(await checarPermissao('andamento_mercado_amr', props));
      const responseC = await api.get('/read_culturas', { params: { ano_safra: formData1.anoSafra.id_ano_safra }, headers: { 'x-api-key': getToken() } });
      setCulturas(responseC.data);
      const responseC2 = await api.get('/read_culturas', { params: { ano_safra: formData2.anoSafra.id_ano_safra }, headers: { 'x-api-key': getToken() } });
      setCulturas2(responseC2.data);

      const responseS = await api.get('/read_semanas', { params: { ano_safra: formData1.anoSafra.id_ano_safra, cultura: formData1.cultura.id_cultura }, headers: { 'x-api-key': getToken() } });
      setSemanas(responseS.data);
      const responseS2 = await api.get('/read_semanas', { params: { ano_safra: formData2.anoSafra.id_ano_safra, cultura: formData2.cultura.id_cultura }, headers: { 'x-api-key': getToken() } });
      setSemanas2(responseS2.data);

      const today = new Date();
      const year = today.getFullYear();
      const weekNumber = getWeekNumber(today);

      setFormData1((formData1) => ({
        ...formData1,
        semana: `${year}-semana_${weekNumber}`,
      }));
      setFormData2((formData2) => ({
        ...formData2,
        semana: `${year - 1}-semana_${weekNumber}`,
      }));

      const response = await api.get('/read_anos_safra', {
        headers: {
          'x-api-key': getToken(),
        },
      });
      setAnosSafra(response.data);
      const responseD = await api.get('/read_anos_safra_demandas', {
        headers: {
          'x-api-key': getToken(),
        },
      });
      setAnosSafraD(responseD.data);
      loadData(setData1, setTotal1, {
        ...formData1,
        semana: `${(year)}-semana_${weekNumber}`,
      }, setLoading1);
      loadData(setData2, setTotal2, {
        ...formData2,
        semana: `${year - 1}-semana_${weekNumber}`,
      }, setLoading2);
    };
    fetchAnosSafra();
  }, []);

  const handleAnoSafraChange = async (event, value, setFormData) => {
    setFormData((prevFormData) => ({ ...prevFormData, anoSafra: value, cultura: null, semana: null }));
    if (value) {
      const response = await api.get('/read_culturas', { params: { ano_safra: value.id_ano_safra }, headers: { 'x-api-key': getToken() } });
      setCulturas(response.data);
      setSemanas([]);
      const responseD = await api.get('/read_culturas_demandas', { params: { ano_safra: value.id_ano_safra }, headers: { 'x-api-key': getToken() } });
      setCulturasD(responseD.data);
      
    } else {
      setCulturas([]);
      setCulturasD([]);
      setSemanas([]);
    }
  };

  const handleCulturaChange = async (event, value, setFormData) => {
    setFormData((prevFormData) => ({ ...prevFormData, cultura: value, semana: null }));
    if (value) {
      const response = await api.get('/read_semanas', { params: { ano_safra: formData1.anoSafra.id_ano_safra, cultura: value.id_cultura }, headers: { 'x-api-key': getToken() } });
      setSemanas(response.data);
    } else {
      setSemanas([]);
    }
  };

  const handleAnoSafraChange2 = async (event, value, setFormData) => {
    setFormData((prevFormData) => ({ ...prevFormData, anoSafra: value, cultura: null, semana: null }));
    if (value) {
      const response = await api.get('/read_culturas', { params: { ano_safra: value.id_ano_safra }, headers: { 'x-api-key': getToken() } });
      setCulturas2(response.data);
      setSemanas2([]);
      const responseD = await api.get('/read_culturas_demandas', { params: { ano_safra: value.id_ano_safra }, headers: { 'x-api-key': getToken() } });
      setCulturas2D(responseD.data);
      
    } else {
      setCulturas2([]);
      setSemanas2([]);
    }
  };

  const handleCulturaChange2 = async (event, value, setFormData) => {
    setFormData((prevFormData) => ({ ...prevFormData, cultura: value, semana: null }));
    if (value) {
      const response = await api.get('/read_semanas', { params: { ano_safra: formData2.anoSafra.id_ano_safra, cultura: value.id_cultura }, headers: { 'x-api-key': getToken() } });
      setSemanas2(response.data);
    } else {
      setSemanas2([]);
    }
  };

  const handleSemanaChange = (event, value, setFormData) => {
    setFormData((prevFormData) => ({ ...prevFormData, semana: value }));
  };

  const loadData = async (setData, setTotalData, formData, setLoading) => {
    // console.log('------------------------------------',!formData, formData)
    // if (!formData){
    //   alert('Preencha os campos antes de carregar os dados');
    // }

    try {
      setLoading(true);
      const response = await api.get('/read_estados_percentual_acumulado', {
        params: {
          ano_safra: formData.anoSafra.id_ano_safra,
          cultura: formData.cultura?.id_cultura,
          semana: formData.semana,
        },
        headers: { 'x-api-key': getToken() },
      });

      const totalData = response.data.find((state) => state.estado === 'Total');

      const newData = {
        ...BrasilState,
        features: BrasilState.features.map((feature) => {
          const stateData = response.data.find((state) => state.estado === feature.properties.Estado);
          const centroid = turf.centroid(feature);
          return {
            ...feature,
            properties: {
              ...feature.properties,
              percentage: stateData ? stateData.percentual : 0,
              total_entregue: stateData ? stateData.total_entregue : 0,
              centroid: centroid.geometry.coordinates,
            },
          };
        }),
      };

      setData(newData);
      setTotalData(totalData); // Set the total data
      setLoading(false);
    } catch (error) {
      alert('Erro ao carregar dados, selecione todos os campos!');
      setData([]);
      setLoading(false);
    }
  };


  const calculaTotalAMR = (demandas) => {
    // Inicializa as variáveis para somar os valores
    let total_entregue = 0;
    let potencial = 0;
    let percentualSoma = 0;

    // Itera sobre a lista de objetos e realiza as somas
    demandas.forEach(demanda => {
      total_entregue += demanda.total_fechado || 0;  // Soma total_fechado como total_entregue
      potencial += demanda.total_geral || 0;         // Soma total_geral como potencial
      percentualSoma += (demanda.percentual || 0) * (demanda.total_geral || 0); // Pondera o percentual
    });

    // Calcula o percentual final ponderado pela proporção de cada total_geral
    const percentual = potencial > 0 ? percentualSoma / potencial : 0;

    // Retorna o objeto com os valores calculados
    return {
      total_entregue,
      potencial,
      percentual
    };
  };

  const loadDataDemandas = async (setData, formData, setTotalData, setLoading) => {

    if (!formData || formData.anoSafra === null || formData.cultura === null) {
      alert('Selecione todos os campos antes de carregar os dados');
      return
    }

    try {
      setLoading(true);
      const response = await api.get('/read_estados_percentual_demandas', {
        params: {
          ano_safra: formData.anoSafra.id_ano_safra,
          cultura: formData.cultura?.id_cultura,
        },
        headers: { 'x-api-key': getToken() },
      });
      const newtotal = calculaTotalAMR(response.data)
      const newData = {
        ...BrasilState,
        features: BrasilState.features.map((feature) => {
          const stateData = response.data.find((state) => state.estado === feature.properties.Estado);
          const centroid = turf.centroid(feature);
          return {
            ...feature,
            properties: {
              ...feature.properties,
              percentage: stateData ? stateData.percentual : 0,
              total_entregue: stateData ? stateData.total_fechado : 0,
              centroid: centroid.geometry.coordinates,
            },
          };
        }),
      };

      setData(newData);
      setTotalData(newtotal)
      setLoading(false);
    } catch (error) {
      alert('Erro ao carregar os dados, verifique se todos os campos foram preenchidos');
      setData([]);
      setLoading(false);
    }
  };

  const calculatePercentageDifferences = () => {
    if (data1 && data2) {
      const differences = data1.features.map((feature1) => {
        const feature2 = data2.features.find((f) => f.properties.Estado === feature1.properties.Estado);
        const percentage1 = feature1.properties.percentage || 0;
        const percentage2 = feature2 ? feature2.properties.percentage || 0 : 0;
        const total1 = feature1.properties.total_entregue || 0;
        const total2 = feature2 ? feature2.properties.total_entregue || 0 : 0;
        return {
          estado: feature1.properties.Estado,
          percentage1,
          percentage2,
          difference: percentage1 - percentage2,
          total1,
          total2
        };
      });
      setPercentageDifferences(differences);
    }
  };

  useEffect(() => {
    calculatePercentageDifferences();
  }, [data1, data2]);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleSubmit = (event, setData, setTotalData, formData, setLoading) => {
    event.preventDefault();
    loadData(setData, setTotalData, formData, setLoading);
  };

  const handleSubmitDemandas = (event, setData, setTotalData, formData, setLoading) => {
    event.preventDefault();
    loadDataDemandas(setData, formData, setTotalData, setLoading);
  };

  const formatSemana = (semana) => {
    if (!semana) return '';
    return semana.replace(/_/g, ' ').replace(/-/g, ' ');
  };

  const copyMapImage = async (captureRef) => {
    if (captureRef.current) {
      const captureElement = captureRef.current;
      const canvas = await html2canvas(captureElement, {
        useCORS: true,
        logging: true,
        scale: 2,
        allowTaint: true,
        backgroundColor: null,
      });
      const image = canvas.toDataURL('image/png');
      const tempLink = document.createElement('a');
      tempLink.href = image;
      tempLink.download = 'mapa.png';
      tempLink.click();
    }
  };

  const formatNumber = (value) => {
    if (value == null || isNaN(value)) return '-';

    // Arredonda para o inteiro mais próximo
    const roundedValue = Math.round(value);

    // Formata com separador de milhar
    return roundedValue.toLocaleString('pt-BR');
  };

  return (
    permitido === true ? (
      <Box className={classes.root}>
        <AppBar position="static">
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Mapas" />
            <Tab label="Gráficos" />
            <Tab label="Atualizações" />
            {andamentoAMR === true ? // somente para internos
              <Tab label="Dashboard Demandas" />
              : null}
          </Tabs>
        </AppBar>
        <TabPanel value={tabIndex} index={0}>
          <Grid container spacing={3}>
            {checkAssociados === true ? (
              <Grid item xs={6}>
                <form onSubmit={(event) => handleSubmit(event, setData1, setTotal1, formData1, setLoading1)}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                      <Autocomplete
                        options={anosSafra}
                        getOptionLabel={(option) => option.descricao}
                        value={formData1.anoSafra}
                        onChange={(event, value) => handleAnoSafraChange(event, value, setFormData1)}
                        renderInput={(params) => <TextField {...params} label="Ano/Safra" variant="outlined" />}
                        style={{ width: 180, marginRight: '10px' }}
                      />
                      <Autocomplete
                        options={culturas}
                        getOptionLabel={(option) => option.nome_cultura}
                        value={formData1.cultura}
                        onChange={(event, value) => handleCulturaChange(event, value, setFormData1)}
                        renderInput={(params) => <TextField {...params} label="Cultura" variant="outlined" />}
                        style={{ width: 150, marginRight: '10px' }}
                        disabled={!formData1.anoSafra}
                      />
                      {andamentoAMR ? (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Switch
                            checked={checkAssociados}
                            onChange={() => {
                              setCheckAssociados(!checkAssociados);
                              setData1(null);
                              setTotal1(null);
                              setFormData1((prevFormData) => ({ ...prevFormData, anoSafra: null, cultura: null, semana: null }));
                            }}
                          />
                          Andamento Brasil
                        </div>
                      ) : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} />}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                      <Autocomplete
                        options={semanas}
                        getOptionLabel={(option) => option}
                        value={formData1.semana}
                        onChange={(event, value) => handleSemanaChange(event, value, setFormData1)}
                        renderInput={(params) => <TextField {...params} label="Semana" variant="outlined" />}
                        style={{ width: 280, marginRight: '10px' }}
                        disabled={!formData1.cultura}
                      />
                      <Button type="submit" variant="contained" color="primary">Carregar dados</Button>
                    </div>
                  </div>
                </form>
                <div className={classes.captureContainer} ref={captureRef1}>
                  <div className={classes.mapWrapper}>
                    <div className={classes.mapContainer}>
                      <div style={{
                        height: '70vh',
                        width: '80%',
                        position: 'relative',
                        zIndex: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'opacity 0.5s ease-in-out',
                        opacity: loading1 ? 0 : 1
                      }}>
                        {loading1 ? (
                          <CircularProgress />
                        ) : (
                          <PrecificaPortoMapa data={data1} total={total1} />
                        )}
                      </div>
                      <div className={classes.infoContainer}>
                        <Typography className={classes.infoItem}>
                          <span className={classes.infoLabel}>Ano/Safra:</span>
                          <span className={classes.infoValue}>{formData1.anoSafra?.descricao}</span>
                        </Typography>
                        <Typography className={classes.infoItem}>
                          <span className={classes.infoLabel}>Cultura:</span>
                          <span className={classes.infoValue}>{formData1.cultura?.nome_cultura}</span>
                        </Typography>
                        <Typography className={classes.infoItem}>
                          <span className={classes.infoLabel}>Semana:</span>
                          <span className={classes.infoValue}>{formatSemana(formData1.semana)}</span>
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <IconButton className={classes.copyButton} onClick={() => copyMapImage(captureRef1)}>
                    <FaCopy size={14} />
                  </IconButton>
                </div>
              </Grid>
            ) : (
              <Grid item xs={6}>
                <form onSubmit={(event) => handleSubmitDemandas(event, setData1, setTotal1, formData1, setLoading1)}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                      <Autocomplete
                        options={anosSafra}
                        getOptionLabel={(option) => option.descricao}
                        value={formData1.anoSafra}
                        onChange={(event, value) => handleAnoSafraChange(event, value, setFormData1)}
                        renderInput={(params) => <TextField {...params} label="Ano/Safra" variant="outlined" />}
                        style={{ width: 180, marginRight: '10px' }}
                      />
                      <Autocomplete
                        options={culturasD}
                        getOptionLabel={(option) => option.nome_cultura}
                        value={formData1.cultura}
                        onChange={(event, value) => handleCulturaChange(event, value, setFormData1)}
                        renderInput={(params) => <TextField {...params} label="Cultura" variant="outlined" />}
                        style={{ width: 150, marginRight: '10px' }}
                        disabled={!formData1.anoSafra}
                      />
                      {andamentoAMR ? (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '56px' }}>
                          <Switch
                            checked={checkAssociados}
                            onChange={() => {
                              setCheckAssociados(!checkAssociados);
                              setData1(null);
                              setTotal1(null);
                              setFormData1((prevFormData) => ({ ...prevFormData, anoSafra: null, cultura: null, semana: null }));
                            }}
                          />
                          Andamento AMR
                        </div>
                      ) : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} />}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '10px', height: '56px' }}>
                      <Button type="submit" variant="contained" color="primary">Carregar dados</Button>
                    </div>
                  </div>
                </form>
                <div className={classes.captureContainer} ref={captureRef1}>
                  <div className={classes.mapWrapper}>
                    <div className={classes.mapContainer}>
                      <div style={{
                        height: '70vh',
                        width: '80%',
                        position: 'relative',
                        zIndex: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'opacity 0.5s ease-in-out',
                        opacity: loading1 ? 0 : 1
                      }}>
                        {loading1 ? (
                          <CircularProgress />
                        ) : (
                          <PrecificaPortoMapa data={data1} total={total1} />
                        )}
                      </div>
                      <div className={classes.infoContainer}>
                        <Typography className={classes.infoItem}>
                          <span className={classes.infoLabel}>Ano/Safra:</span>
                          <span className={classes.infoValue}>{formData1.anoSafra?.descricao}</span>
                        </Typography>
                        <Typography className={classes.infoItem}>
                          <span className={classes.infoLabel}>Cultura:</span>
                          <span className={classes.infoValue}>{formData1.cultura?.nome_cultura}</span>
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <IconButton className={classes.copyButton} onClick={() => copyMapImage(captureRef1)}>
                    <FaCopy size={14} />
                  </IconButton>
                </div>
              </Grid>
            )}
            {checkAssociados2 === true ? (
              <Grid item xs={6}>
                <form onSubmit={(event) => handleSubmit(event, setData2, setTotal2, formData2, setLoading2)}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                      <Autocomplete
                        options={anosSafra}
                        getOptionLabel={(option) => option.descricao}
                        value={formData2.anoSafra}
                        onChange={(event, value) => handleAnoSafraChange2(event, value, setFormData2)}
                        renderInput={(params) => <TextField {...params} label="Ano/Safra" variant="outlined" />}
                        style={{ width: 180, marginRight: '10px' }}
                      />
                      <Autocomplete
                        options={culturas2}
                        getOptionLabel={(option) => option.nome_cultura}
                        value={formData2.cultura}
                        onChange={(event, value) => handleCulturaChange2(event, value, setFormData2)}
                        renderInput={(params) => <TextField {...params} label="Cultura" variant="outlined" />}
                        style={{ width: 150, marginRight: '10px' }}
                        disabled={!formData2.anoSafra}
                      />
                      {andamentoAMR ? (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Switch
                            checked={checkAssociados2}
                            onChange={() => {
                              setCheckAssociados2(!checkAssociados2);
                              setData2(null);
                              setTotal2(null);
                              setFormData2((prevFormData) => ({ ...prevFormData, anoSafra: null, cultura: null, semana: null }));
                            }}
                          />
                          Andamento Brasil
                        </div>
                      ) : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} />}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                      <Autocomplete
                        options={semanas2}
                        getOptionLabel={(option) => option}
                        value={formData2.semana}
                        onChange={(event, value) => handleSemanaChange(event, value, setFormData2)}
                        renderInput={(params) => <TextField {...params} label="Semana" variant="outlined" />}
                        style={{ width: 280, marginRight: '10px' }}
                        disabled={!formData2.cultura}
                      />
                      <Button type="submit" variant="contained" color="primary">Carregar dados</Button>
                    </div>
                  </div>
                </form>
                <div className={classes.captureContainer} ref={captureRef2}>
                  <div className={classes.mapWrapper}>
                    <div className={classes.mapContainer}>
                      <div style={{
                        height: '70vh',
                        width: '80%',
                        position: 'relative',
                        zIndex: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'opacity 0.5s ease-in-out',
                        opacity: loading2 ? 0 : 1
                      }}>
                        {loading2 ? (
                          <CircularProgress />
                        ) : (
                          <PrecificaPortoMapa data={data2} total={total2} />
                        )}
                      </div>
                      <div className={classes.infoContainer}>
                        <Typography className={classes.infoItem}>
                          <span className={classes.infoLabel}>Ano/Safra:</span>
                          <span className={classes.infoValue}>{formData2.anoSafra?.descricao}</span>
                        </Typography>
                        <Typography className={classes.infoItem}>
                          <span className={classes.infoLabel}>Cultura:</span>
                          <span className={classes.infoValue}>{formData2.cultura?.nome_cultura}</span>
                        </Typography>
                        <Typography className={classes.infoItem}>
                          <span className={classes.infoLabel}>Semana:</span>
                          <span className={classes.infoValue}>{formatSemana(formData2.semana)}</span>
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <IconButton className={classes.copyButton} onClick={() => copyMapImage(captureRef2)}>
                    <FaCopy size={14} />
                  </IconButton>
                </div>
              </Grid>
            ) : (
              <Grid item xs={6}>
                <form onSubmit={(event) => handleSubmitDemandas(event, setData2, setTotal2, formData2, setLoading2)}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                      <Autocomplete
                        options={anosSafra}
                        getOptionLabel={(option) => option.descricao}
                        value={formData2.anoSafra}
                        onChange={(event, value) => handleAnoSafraChange2(event, value, setFormData2)}
                        renderInput={(params) => <TextField {...params} label="Ano/Safra" variant="outlined" />}
                        style={{ width: 180, marginRight: '10px' }}
                      />
                      <Autocomplete
                        options={culturas2D}
                        getOptionLabel={(option) => option.nome_cultura}
                        value={formData2.cultura}
                        onChange={(event, value) => handleCulturaChange2(event, value, setFormData2)}
                        renderInput={(params) => <TextField {...params} label="Cultura" variant="outlined" />}
                        style={{ width: 150, marginRight: '10px' }}
                        disabled={!formData2.anoSafra}
                      />
                      {andamentoAMR ? (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '56px' }}>
                          <Switch
                            checked={checkAssociados2}
                            onChange={() => {
                              setCheckAssociados2(!checkAssociados2);
                              setData2(null);
                              setTotal2(null);
                              setFormData2((prevFormData) => ({ ...prevFormData, anoSafra: null, cultura: null, semana: null }));
                            }}
                          />
                          Andamento AMR
                        </div>
                      ) : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} />}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '10px', height: '56px' }}>
                      <Button type="submit" variant="contained" color="primary">Carregar dados</Button>
                    </div>
                  </div>
                </form>
                <div className={classes.captureContainer} ref={captureRef2}>
                  <div className={classes.mapWrapper}>
                    <div className={classes.mapContainer}>
                      <div style={{
                        height: '70vh',
                        width: '80%',
                        position: 'relative',
                        zIndex: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'opacity 0.5s ease-in-out',
                        opacity: loading2 ? 0 : 1
                      }}>
                        {loading2 ? (
                          <CircularProgress />
                        ) : (
                          <PrecificaPortoMapa data={data2} total={total2} />
                        )}
                      </div>
                      <div className={classes.infoContainer}>
                        <Typography className={classes.infoItem}>
                          <span className={classes.infoLabel}>Ano/Safra:</span>
                          <span className={classes.infoValue}>{formData2.anoSafra?.descricao}</span>
                        </Typography>
                        <Typography className={classes.infoItem}>
                          <span className={classes.infoLabel}>Cultura:</span>
                          <span className={classes.infoValue}>{formData2.cultura?.nome_cultura}</span>
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <IconButton className={classes.copyButton} onClick={() => copyMapImage(captureRef2)}>
                    <FaCopy size={14} />
                  </IconButton>
                </div>
              </Grid>
            )}
          </Grid>


          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              Comparação de Diferenças de Percentuais
            </Typography>
            <Typography variant="body1">
              Cultura: {formData1.cultura?.nome_cultura} - {formData2.cultura?.nome_cultura}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Semana: {formatSemana(formData1.semana)} - {formatSemana(formData2.semana)}
            </Typography>
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Estado</TableCell>
                    <TableCell>Percentual Mapa 1</TableCell>
                    <TableCell>Total entregue Mapa 1</TableCell>
                    <TableCell>Percentual Mapa 2</TableCell>
                    <TableCell>Total entregue Mapa 2</TableCell>
                    <TableCell>Diferença</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {percentageDifferences.map((diff) => (
                    <TableRow key={diff.estado}>
                      <TableCell>{diff.estado}</TableCell>
                      <TableCell>{diff.percentage1.toFixed(2)}%</TableCell>
                      <TableCell>{formatNumber(diff.total1)}</TableCell>
                      <TableCell>{diff.percentage2.toFixed(2)}%</TableCell>
                      <TableCell>{formatNumber(diff.total2)}</TableCell>
                      <TableCell>{diff.difference.toFixed(2)}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
          <ChartsEntregaBrasil />
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
          <AtualizacoesAndamentoMercado />
        </TabPanel>
        {andamentoAMR === true ?
          <TabPanel value={tabIndex} index={3}>
            <SumarizacaoDemandas />
          </TabPanel>
          : null}
      </Box>
    ) : null
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default AndamentoMercadoBrasil;