import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  // LandingPage.js
  root: {
    flexGrow: 1,
    paddingRight: theme.spacing(3), // padding para compensar a margin da barra lateral
    paddingBottom: theme.spacing(6),
    overflow: 'hidden', // esconde a scrollbar do material ui
  },

  // TopPosts.js
  customSpacing: {
    paddingRight: theme.spacing(2),
  },

  topTitle: {
    marginBottom: 10,
    fontWeight: 'bold',
    color: '#303030',
    fontFamily: 'Poppins',
    paddingLeft: theme.spacing(1), // Add some padding to the left of the title
    position: 'relative', // Set position property to 'relative'
    '&::before': {
      borderRadius: 20,
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: 4,
      backgroundColor: '#A3CC51',
    },
  },


  newsTitle: {
    lineHeight: 1.2,
    color: '#303030',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.6rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.4rem',
    },
  },

  card: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none',
    overflow: 'hidden',
    transition: 'box-shadow .2s ease-in-out',
    '&:hover': {
      cursor: 'Pointer'
    },
    background: 'transparent',
  },

  cardSubText: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    fontSize: '0.8rem',
    fontFamily: 'Poppins',
    color: '#747474',
  },

  cardMedia: {
    height: 0,
    paddingTop: '40%',
    backgroundSize: '100%, 60%, 60%',
    backgroundPosition: 'center',
    borderRadius: 10
  },

  tagTextWrapper: {
    paddingLeft: theme.spacing(1),
    position: 'relative',
    '&::before': {
      borderRadius: 20,
      content: '""',
      position: 'absolute',
      left: 0,
      top: '20%',
      bottom: '20%',
      width: 3,
      backgroundColor: '#A3CC51',
    },
  },


  // TagFilter component styles
  tagColumn: {
    borderRight: "1px solid #D1E6A8"
  },

  menuGridContainer: {
    maxWidth: "75%", // Adjust this value to your desired width
    width: "100%",
    padding: theme.spacing(1),
  },
  menuItemWidth: {
    minWidth: "calc(25% - 1px)", // Adjust this value to your desired width
  },

  tagFilterRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: '1px solid lightgray',
    borderBottom: '1px solid lightgray',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },

  tagFilterButton: {
    backgroundColor: "transparent",
    border: "none",
    color: "#3D3D3D", // Update the color here
    cursor: "pointer",
    fontSize: "1rem",
    fontFamily: "Poppins",
    fontWeight: "bold",
    textDecoration: "none",
    padding: theme.spacing(1),
    "&:hover": {
      color: "#A3CC51",
      backgroundColor: 'transparent'
    },
    '&:active': {
      backgroundColor: 'inherit', // Removes the active background color
      boxShadow: 'none', // Removes the active box shadow
    },
  },

  outrosButton: {
    textTransform: 'none', // Prevents the text from being uppercase
  },

  tagListItem: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
      cursor: "pointer",
    },
  },

  tagList: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },

  gridContainer: {
    width: "100%",
    padding: theme.spacing(1),
  },

  tagListGrid: {
    justifyContent: 'center',
  },

  // RenderPost.js
  greenButton: {
    fontFamily: 'Poppins',
    height: 40,
    fontSize: '10px',
    background: '#A3CC51',
    color: 'white',
    borderRadius: 6,
    paddingRight: 15
  },

  tag: {
    backgroundColor: '#A3CC51',
    color: 'white',
    width: 'fit-content',
    height: 'fit-content',
    paddingLeft: 10,
    paddingRight: 10,

  },

  postTitle: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(24),
    wordWrap: 'break-word',
    overflowWrap: 'brek-word',
    color: '#3d3d3d'
  },


  bigBlackTitle: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    color: '#3D3D3D',
    fontSize: theme.typography.pxToRem(42), // Decrease the font size
    lineHeight: 1.15
  },


  shareButton: {
    marginRight: theme.spacing(1),
  },

  paragraph: {
    fontFamily: 'Poppins',


    fontSize: '14px',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },

  postInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    paddingTop: 20
  },

  postInfoItem: {
    fontFamily: 'Poppins',
    color: '#494949',
    position: 'relative',
    paddingRight: theme.spacing(3),
    '&:last-child': {
      paddingRight: 0,
    },
    '&:not(:last-child)::after': {
      content: '""',
      position: 'absolute',
      right: theme.spacing(1),
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '1em',
      backgroundColor: '#8A8A8A',
    },
    '&:first-child': {
      color: '#44852D',
      fontWeight: 'bold'
    },
    display: 'flex',
    alignItems: 'center',
  },

  postInfoItem1: {
    fontFamily: 'Poppins',
    color: '#929292', // Update the color here
    position: 'relative',
    paddingRight: theme.spacing(3),
    '&:last-child': {
      paddingRight: 0,
    },
    '&:not(:last-child)::after': {
      content: '""',
      position: 'absolute',
      right: theme.spacing(1),
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '1em',
      backgroundColor: '#8A8A8A',
    },
    '&:first-child': {
      color: '#44852D',
      fontWeight: 'bold'
    },
  },



  shareButton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10
  },
  shareText: {
    marginLeft: theme.spacing(1),
    fontFamily: 'Poppins',
    fontSize: '12px'
  },

  shareItem: {
    borderRadius: theme.shape.borderRadius,
    background: '#f5f5f5', // or any other background color you'd like
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
  },

  link: {
    textDecoration: 'none',
    color: 'inherit',
  },


  // divisao
  paperNews: {
    borderRadius: 0, // Set borderRadius to 0
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)', // Adjust the color and opacity
    marginTop: 20,
    padding: 20
  },

  newsImage: {
    maxWidth: '50%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: 10, // Add border radius to the image
  },
  tagDisplay: {
    fontWeight: '550', // Change the fontWeight to '600' for "Semi Bold"
    fontSize: theme.typography.pxToRem(12), // Increase the font size
    color: '#7B7037',
  },
  sectionTitle: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(24),
    color: '#303030',
    marginBottom: theme.spacing(2),
    position: 'relative', // Add position property to 'relative'
    paddingRight: theme.spacing(2), // Add some padding to the right of the title
    '&::after': {
      borderRadius: 5, // Add rounded corners
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: 4,
      backgroundColor: '#A3CC51', // Change this to your desired green color
    },
  },
  separatorLine: {
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    marginBottom: theme.spacing(1),
  },

  paragraphList: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    color: '#929292',
    fontSize: '14px',
    display: '-webkit-box', // Add this line
    WebkitBoxOrient: 'vertical', // Add this line
    WebkitLineClamp: 2, // Add this line, change the number to control the number of lines to display
    overflow: 'hidden', // Add this line
  },


  topTitleUnderline: {
    borderBottom: '2px solid #C2C2C2', // Adjust the color and thickness as needed
    paddingBottom: '4px', // Adjust the padding as needed

  },

  // PageNav
  pageList: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  pageNumber: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    border: '2px solid #E9E9E9',
    borderRadius: 5,
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 400,
    color: '#CCCCCC',
    '&:hover': {
      backgroundColor: '#CCCCCC',
      color: '#FFFFFF',
    },
  },
  active: {
    backgroundColor: '#2EBF79',
    color: '#FFFFFF',
  },
  PageNavPos:
  {
    display: 'flex',
    justifyContent: 'center'
  },
  loaderOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
    animation: "$backgroundOscillation 1s linear infinite",
  },
  loader: {
    width: "20%",
    height: "auto",
    borderRadius: "10px",
    opacity: 0.9
  },
  "@keyframes backgroundOscillation": {
    "0%": {
      backgroundColor: "rgba(255, 255, 255, 0.8)",
    },
    "50%": {
      backgroundColor: "rgba(230, 230, 230, 0.8)",
    },
    "100%": {
      backgroundColor: "rgba(255, 255, 255, 0.8)",
    },
  },
  line: {
    marginTop: -10,
    marginBottom: -10,
  }
}))
