import React from "react"
import styles from "../styles";
import { Grid } from '@material-ui/core';
import RenderPost from "../components/RenderPost";
import TabelaDePrecos from "../components/TabelaDePrecos";

export default function LandingPage(props) {
    const classes = styles()

    return (
        <div className={classes.root} >
            <Grid container >
                <Grid item xs={12} md={7} lg={9} style={{ paddingLeft: 20 }} >
                    <RenderPost {...props} />
                </Grid>

                <Grid item xs={12} md={5} lg={3}  >
                    <TabelaDePrecos {...props} />
                </Grid>
            </Grid>
        </div>
    )
}
