
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Button from "@material-ui/core/Button";
import api from '../../services/api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getToken, checarPermissao } from '../../utils/utils'
import api_amr from '../../services/api_amr'
import { CircleFlag } from 'react-circle-flags'

import logo from "../../assets/img/logo_amr.png";

import { Line } from 'react-chartjs-2';


function PrecificaPorto(props) {
  const [porto, setPorto] = useState('')
  const [portos, setPortos] = useState('')

  const [dolar, setDolar] = useState('')
  const [frete, setFrete] = useState(0)
  const [precificado, setPrecificado] = useState('');
  const [lastUpdate, setLastUpdate] = useState('');
  const [permitido, setPermitido] = useState('')
  const classes = useStyles();

  useEffect(() => {
    async function fetchMyAPI() {
      setPermitido(await checarPermissao('precifica_porto', props))
      const response_return_portos = await api_amr.get('/return_portos', { headers: { 'x-api-key': getToken() } });
      setPortos(response_return_portos.data);

      const respose_dolar_hora = await api.get('/DolarApi');

      setDolar(respose_dolar_hora.data)

      const respose_update = await api_amr.get('/return_last_update', { headers: { 'x-api-key': getToken() } });
      setLastUpdate(respose_update.data);

    }
    fetchMyAPI();
  }, []);

  async function getPrices() {

    const response_return_precificacao_portos = await api_amr.get('/return_precificacao_portos', {
      params: { 'porto': porto.id, 'dolar': dolar, 'frete': frete }, headers: { 'x-api-key': getToken() }
    });
    setPrecificado(response_return_precificacao_portos.data);
    console.log(response_return_precificacao_portos.data)
  }
  return (
    permitido ?
      <div className={classes.container} >


        <div >
          <h2 className={classes.inputh1}>Precificação Porto</h2>

        </div>
        {/* Inputs */}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <b className={classes.inputh1} >Matérias-primas atualizadas em: {lastUpdate}</b>
            <div className={classes.inputsDiv} >{/*Margin top 10 para ajeitar o primiero elemento */}
              <div style={{ paddingRight: 28 }} >

                <div >
                  <Tooltip title="Valor do dolar em R$ a ser utilizado para calculo do valor final em Reais" placement="top-end" >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <b className={classes.inputh1}>Dólar:</b>
                      <HelpOutlineIcon className={classes.toolTipIconStyle} />
                    </div>
                  </Tooltip>
                </div>

                <TextField
                  placeholder="Dolar $"
                  variant='outlined'
                  onChange={(event) => setDolar(event.target.value)}
                  value={dolar}
                  className={classes.autoCompleteField}
                  InputProps={{
                    disableUnderline: true,
                    classes: { notchedOutline: classes.noBorder }
                  }}
                />
              </div>
              <div style={{ paddingRight: 28 }} >

                <div >
                  <Tooltip title="Valor em R$ do frete, da origem do produto até o destino final" placement="top-end" >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <b className={classes.inputh1}>Frete:</b>
                      <HelpOutlineIcon className={classes.toolTipIconStyle} />
                    </div>
                  </Tooltip>
                </div>
                <TextField
                  placeholder="Valor Frete R$"
                  variant='outlined'
                  onChange={(event) => setFrete(event.target.value)}
                  className={classes.autoCompleteField}
                  value={frete}
                  InputProps={{

                    disableUnderline: true,
                    classes: { notchedOutline: classes.noBorder }
                  }}

                />
              </div>
            </div>
            <div className={classes.inputsDiv} >
              <div style={{ paddingRight: 28 }} >

                <div >
                  <Tooltip title="Porto" placement="top-end" >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <b className={classes.inputh1}>Porto:</b>
                      <HelpOutlineIcon className={classes.toolTipIconStyle} />
                    </div>
                  </Tooltip>
                </div>
                <Autocomplete

                  options={portos}
                  getOptionLabel={(option) => (option.id + ' - ' + option.nome_porto + ' - ' + option.cidade + '/' + option.UF)}
                  className={classes.autoCompleteFieldBig}
                  onChange={(e, v) => (setPorto(v), console.log(porto))}

                  classes={{
                    input: classes.input,

                  }}
                  renderInput={(params) => <TextField
                    {...params}
                    placeholder="Selecionar Porto"
                    variant='outlined'
                    value={porto}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      classes: { notchedOutline: classes.noBorder }
                    }}

                  />}
                />

              </div>
            </div>
            <div className={classes.calcDiv}>
              <Button onClick={() => getPrices()} variant="contained" className={classes.calcButton}> Calcular</Button>
            </div>
          </div>
        </div>
        {/* resultados */}
        {precificado !== '' ?

          <TableContainer className={classes.tableModels}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={250}>Matéria-Prima</TableCell>
                  <TableCell width={250}>
                    CFR
                    <CircleFlag countryCode="us" height="20" style={{ position: 'relative', top: 10, left: 0 }} />
                    <CircleFlag countryCode="br" height="20" style={{ position: 'relative', top: 0, right: 10 }} /></TableCell>
                  <TableCell width={250}>
                    FCA Granel Porto
                    <CircleFlag countryCode="us" height="20" style={{ position: 'relative', top: 10, left: 0 }} />
                    <CircleFlag countryCode="br" height="20" style={{ position: 'relative', top: 0, right: 10 }} /></TableCell>
                  <TableCell width={250}>
                    FCA Big Bag Porto
                    <CircleFlag countryCode="us" height="20" style={{ position: 'relative', top: 10, left: 0 }} />
                    <CircleFlag countryCode="br" height="20" style={{ position: 'relative', top: 0, right: 10 }} /></TableCell>
                  <TableCell width={250}>
                    CPT (Frete incluso)
                    <CircleFlag countryCode="us" height="20" style={{ position: 'relative', top: 10, left: 0 }} />

                    <CircleFlag countryCode="br" height="20" style={{ position: 'relative', top: 0, right: 10 }} /></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(precificado).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell  >
                      {precificado[item].abreviacao}
                    </TableCell>
                    <TableCell >

                      {precificado[item].CFR_USD.toLocaleString('pt-br', { style: 'currency', currency: 'USD' })}- {Math.round(precificado[item].CFR_USD * dolar).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </TableCell>
                    <TableCell >

                      {precificado[item].FOB_porto_granel.toLocaleString('pt-br', { style: 'currency', currency: 'USD' })} - {precificado[item].FOB_porto_granel_RS.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </TableCell>
                    <TableCell >

                      {precificado[item].FOB_porto_bag.toLocaleString('pt-br', { style: 'currency', currency: 'USD' })} - {precificado[item].FOB_porto_bag_RS.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </TableCell>
                    <TableCell >

                      {precificado[item].CIF_frete.toLocaleString('pt-br', { style: 'currency', currency: 'USD' })} - {precificado[item].CIF_frete_RS.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          : null}
      </div> : null
  );
}
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 100,
  },
  inputsDiv: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 40,

  },
  inputh1: {
    fontFamily: 'Poppins',
    color: '#383838'
  },
  inputhDolar: {
    fontFamily: 'Poppins',
    fontSize: 15,
    color: '#A3CC51'
  },
  noBorder: {
    border: "none",
  },
  autoCompleteField: {
    marginTop: 10,
    minWidth: 228,
    maxWidth: 228,
    borderRadius: 6,
    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
  },
  autoCompleteFieldBig: {
    marginTop: 10,
    width: 484,
    borderRadius: 6,
    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

  },
  textField: {
    fontFamily: 'Poppins',
    fontSize: '9px',
    width: '352px',
    backgroundColor: 'white',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItens: 'center',
    borderRadius: 6,

    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
  },
  calcDiv: {
    marginTop: 40,
    width: '146px',
    marginLeft: 340,
    height: 45
  },
  calcButton: {
    fontFamily: 'Poppins',
    height: 40,
    fontSize: '10px',
    background: '#A3CC51',
    width: '146px',
    color: 'white',
    borderRadius: 6,
    '&:hover': {
      background: "rgba(163, 204, 81, 0.7)",
    },
  },
  table: {
    minWidth: 400,
    maxWidth: '100%'
  },
  rowSize: {
    maxWidth: 500
  },
  tableModels: {
    marginTop: 20,
    maxWidth: '90%',
    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

  },
  toolTipIconStyle: {
    marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
  }
}))
export default PrecificaPorto;