import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

function TableUsabilidadeSemanal({ classes, sortedData, handleSort, sortedColumn, sortOrder }) {
    const weeks = ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4', 'Semana 5'];

    let dataArr = Object.keys(sortedData).map((key) => {
        const row = sortedData[key];
        row['user'] = key;
        row['total'] = weeks.reduce((sum, week) => sum + (row[week] || 0), 0);
        return row;
    });

    // Sort the data based on the total column
    if (sortedColumn === "total") {
        dataArr.sort((a, b) => {
            if (sortOrder === "asc") {
                return a.total - b.total;
            } else {
                return b.total - a.total;
            }
        });
    }

    return (
        <div className={classes.tableContainer} >
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Usuário</TableCell>
                            {weeks.map(week => <TableCell align="right" key={week}>{week}</TableCell>)}
                            <TableCell align="right" onClick={handleSort}>
                                Total {sortedColumn === "total" && <span>{sortOrder === "asc" ? "▲" : "▼"}</span>}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataArr.map((row) => (
                            <TableRow key={row.user}>
                                <TableCell component="th" scope="row">
                                    {row.user || "-"}
                                </TableCell>
                                {weeks.map(week => <TableCell align="right" key={week}>{row[week] || "-"}</TableCell>)}
                                <TableCell align="right">{row.total || "-"}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}


export default TableUsabilidadeSemanal;
