import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import useStyles from '../styles/styles';
import Paper from '@material-ui/core/Paper';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import ThumbDownAltRoundedIcon from '@material-ui/icons/ThumbDownAltRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import { Link } from 'react-router-dom';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';


const NoticiasDashboard = ({ materia, date, onNewsHover, newsData }) => {
    const classes = useStyles();
    const [hoveredNews, setHoveredNews] = useState(null);

    const handleMouseEnter = (newsId) => {
        const hoveredNewsItem = newsData?.find((news) => news.post_id === newsId);
        setHoveredNews(hoveredNewsItem);
        if (onNewsHover) {
            onNewsHover(hoveredNewsItem);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    // Check if newsData is not empty
    if (!newsData || newsData.length === 0) {
        return <div style={{ margin: 20 }}>Nenhuma notícia disponível</div>;
    }

    return (
        <Grid container className={classes.rootNews}>
            <Grid item xs={12} style={{ marginLeft: 10, marginBottom: 10, }} >
                <Typography className={classes.title} variant="h6">
                    <LibraryBooksIcon className={classes.icon} />
                    Histórico de Notícias
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.newsList}>
                {newsData.map((newsItem) => {
                    // Find the main_title component for the current post
                    const mainTitleComponent = newsItem.components.find(component => component.type === 'main_title');

                    return (
                        <Link
                            to={{
                                pathname: `Noticia/${newsItem.post_id}`, // Assuming 'Noticia/:id' is your route for individual news posts
                                state: { post: newsItem }
                            }}
                            style={{ textDecoration: 'none' }} // optional, to remove underline from link
                        >
                            <Paper
                                key={newsItem.post_id}
                                className={`${classes.newsItem} ${hoveredNews?.post_id === newsItem.post_id ? classes.hoveredNewsItem : ''}`}
                                onMouseEnter={() => handleMouseEnter(newsItem.post_id)}
                            >
                                <Grid container direction="column">
                                    <Grid item xs={12}>
                                        <Typography className={classes.newsDate}>{formatDate(newsItem.post_date)}</Typography>
                                    </Grid>
                                    <Grid container item alignItems="center">
                                        <Grid item xs={10}>
                                            <Typography className={classes.newsTitle}>{mainTitleComponent?.value}</Typography>
                                        </Grid>
                                        <Grid item xs={2} container justifyContent="center">
                                            {newsItem.sentiment === 1 && (
                                                <TrendingUpIcon className={classes.SentimentIcon} style={{ color: 'green', fontSize: 40 }} />
                                            )}
                                            {newsItem.sentiment === -1 && (
                                                <TrendingDownIcon className={classes.SentimentIcon} style={{ color: 'red', fontSize: 40 }} />
                                            )}
                                            {newsItem.sentiment === 0 && (
                                                <RemoveCircleOutlineRoundedIcon className={classes.SentimentIcon} style={{ color: '#C9C422', fontSize: 30 }} />
                                            )}

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Link>
                    )
                })}
            </Grid>
        </Grid >
    );
};

export default NoticiasDashboard;
