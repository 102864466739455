import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import api_amr from 'services/api_amr';
import { CircleFlag } from 'react-circle-flags';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import { makeStyles } from "@material-ui/core/styles";
import { getToken } from 'utils/utils';
import Divider from '@material-ui/core/Divider';
import LoaderGif from '../../../assets/img/amr_loader_transparent.gif';
import Box from "@material-ui/core/Box";

export default function HistPrecoCard({ parametros }) {

    const classes = useStyles();
    const [materia, setMateria] = useState('')
    const [info, setInfo] = useState('')
    const [cardData, setCardData] = useState('')

    useEffect(() => {
        fetchMyApi()


    }, [parametros]);

    async function fetchMyApi() {
        const response = await api_amr.get('/return_component_histPreco', {
            params: {
                'formato': 'card', 'parametros': parametros
            },
            headers: { 'x-api-key': getToken() }
        })
        setCardData(response.data.dados)
        setInfo(response.data.info)
        setMateria(response.data.materia)
    }

    const handleColor = (value) => {
        if (value > 0) {
            return '#2FB16C';
        } else if (value < 0) {
            return '#CC0000';
        } else {
            return '#000000';
        }
    };

    const handleIcon = (value) => {
        if (value > 0) {
            return <TrendingUpIcon style={{ color: '#2FB16C', fontSize: 'medium', }} />;
        } else if (value < 0) {
            return <TrendingDownIcon style={{ color: '#CC0000', fontSize: 'medium', }} />;
        } else {
            return <RemoveCircleOutlineRoundedIcon style={{ color: '#C9C422', fontSize: 'medium', }} />;
        }
    };

    const timeFrames = [
        { label: 'Semana', key: 'variacao_semana_passada' },
        { label: 'Mês', key: 'variacao_mes_passado' },
        { label: 'Ano', key: 'variacao_ano_passado' },
    ];

    if (cardData) {
        return (
            <div className={classes.cardWrapper} >

                {/* Card Top Part */}
                <div className={classes.CardTopPart}>
                    <div className={classes.centerContent}>
                        <div className={classes.centerContentMateria} >
                            <Typography className={classes.materiaText} variant="subtitle1">Matéria:</Typography>
                            <Typography className={classes.nomeMateriaText} variant="h6">{materia.descricao}</Typography>
                        </div>
                    </div>
                    <div className={classes.centerContent}>
                        <div className={classes.centerContentTipo} >
                            <Typography className={classes.materiaText} variant="subtitle1">Tipo:</Typography>
                            <Typography className={classes.nomeMateriaText} variant="h6">{materia.tipo}</Typography>
                        </div>
                    </div>
                    <div className={classes.centerContent}>
                        <div className={classes.centerContentPorto}>
                            <Typography className={classes.materiaText} variant="subtitle1">Porto:</Typography>
                            <Typography className={classes.nomeMateriaText} variant="h6">{info.nome_porto}</Typography>
                        </div>
                    </div>
                </div>

                <div>
                    <Divider />
                </div>

                <div className={classes.CarContentFlex}>

                    {/* Card A Part */}
                    <div className={classes.variacaoDivVar}>
                        <div>
                            <Typography className={`${classes.titleText} ${classes.divTitle}`} variant="subtitle1">
                                Variação:
                            </Typography>
                        </div>
                        <div>
                            {timeFrames.map((timeFrame) => (

                                <div key={timeFrame.key} className={classes.variacaoA}>
                                    <Typography className={classes.materiaText} style={{ width: '33%', textAlign: 'left', }}>
                                        {timeFrame.label}
                                    </Typography>
                                    <Typography className={classes.biggerTextVariacao} style={{ width: '33%', textAlign: 'center' }}>
                                        <span style={{ color: handleColor(cardData[timeFrame.key]) }}>
                                            {cardData[timeFrame.key] !== undefined && cardData[timeFrame.key] !== ''
                                                ? `${cardData[timeFrame.key]}%`
                                                : ''}
                                        </span>
                                    </Typography>
                                    <div style={{ width: '33%', lineHeight: '1', textAlign: 'left', }}>
                                        {handleIcon(cardData[timeFrame.key])}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>

                    {/* Card B Part */}
                    <div className={classes.variacaoDivComp}>
                        <div ><Typography className={`${classes.titleText} ${classes.divTitle}`} variant="subtitle1" >Composição:</Typography></div>
                        <div >
                            <Box display="flex" justifyContent="space-between">
                                <Typography className={classes.materiaText}>Nitrogênio&nbsp;&nbsp;{materia.nitrogenio} </Typography>
                            </Box>
                        </div>
                        <div  >
                            <Box display="flex" justifyContent="space-between">
                                <Typography className={classes.materiaText}>Fósforo&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{materia.fosforo}</Typography>
                            </Box>
                        </div>
                        <div  >
                            <Box display="flex" justifyContent="space-between">
                                <Typography className={classes.materiaText}>Potássio&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{materia.potassio}</Typography>
                            </Box>
                        </div>
                    </div>

                    {/* Card C Part */}
                    <div className={classes.variacaoDivFob}  >
                        <div className={classes.variacaoFob}>
                            <Typography className={classes.titleText} variant="subtitle1" style={{ whiteSpace: 'nowrap' }}>
                                Preço FOB Porto &nbsp;
                            </Typography>
                        </div>

                        <div className={classes.centerContentPorto}>
                            <div>
                                <Typography className={classes.titleText} variant="subtitle1" style={{ whiteSpace: 'nowrap' }}>
                                    <CircleFlag countryCode="us" height="20" className={classes.flagUS} />
                                    <CircleFlag countryCode="br" height="20" className={classes.flagBR} />
                                </Typography>
                            </div>

                            <div style={{ marginLeft: 10, }}>
                                <Typography className={classes.biggerTextVariacao}>
                                    {cardData.valor_atual_rs.toLocaleString('pt-br', {
                                        style:
                                            'currency', currency: 'BRL'
                                    })}
                                </Typography>
                                <Typography className={classes.biggerTextVariacao}>
                                    {parseFloat(cardData.valor_atual).toLocaleString('pt-br', {
                                        style:
                                            'currency', currency: 'USD'
                                    })}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
    else {
        return (
            <div className={classes.loaderDiv}>
                <div className={classes.loader}>
                    <img src={LoaderGif} alt="Loading..." className={classes.loader} />
                </div>
            </div>
        );
    }
}


const useStyles = makeStyles((theme) => ({

    cardWrapper: {
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        marginTop: '-10px',
        backgroundColor: 'white',
        flexGrow: 1,
        overflow: 'hidden',
        fontFamily: 'Poppins',
        width: '100%',
        height: '100%',
    },

    titleText: {
        fontWeight: 'bold',
        fontSize: '11px',
        color: '#939598',
        fontFamily: 'Poppins',
    },

    biggerTextVariacao: {
        fontWeight: 500,
        fontSize: '12px',
        marginRight: 10,
        color: '#3D3D3D',
        fontFamily: 'Poppins',
        width: '5rem',
    },

    flagUS: {
        position: 'relative',
        top: 10,
        left: 0,
    },

    flagBR: {
        position: 'relative',
        top: 0,
        right: 10,
    },

    arrowDown: {
        background: '#FF005C',
        transform: 'rotate(180deg)',
        color: 'white'
    },
    arrowUp: {
        marginLeft: '50px',
        background: '#44FF57',
        borderRadius: 50,
        color: 'white'
    },
    title: {
        fontWeight: 'bold',
        color: '#3D3D3D',
        fontFamily: 'Poppins',
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: theme.spacing(1),
    },

    loader: {
        width: "70%",
        height: "auto",
        borderRadius: "10px",
        opacity: 0.9
    },
    materiaText: {
        justifyContent: 'space bwtween',
        fontWeight: 500,
        fontSize: '13px',
        color: '#3D3D3D',
        fontFamily: 'Poppins',
        marginRight: theme.spacing(1),
    },
    nomeMateriaText: {
        justifyContent: 'space bwtween',
        fontWeight: 400,
        fontSize: '13px',
        color: '#3D3D3D',
        fontFamily: 'Poppins',
    },

    CarContentFlex: {
        display: "flex",
        flexDirection: "row",
    },

    CardTopPart: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '10px'
    },

    centerContentMateria: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },

    neutralCircleIcon: {
        color: '#C9C422',
        fontSize: 30
    },

    centerContentTipo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },

    centerContentPorto: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },

    variacaoDivVar: {
        width: '40%',
        marginRight: '5px',
    },

    variacaoDivComp: {
        width: '28%',
        marginRight: '5px',
    },
    variacaoDivFob: {
        width: '32%',
    },

    variacaoFob: {
        display: 'flex',
        flexDirection: 'column'
    },

    variacaoA: {
        width: '33%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'left',
        width: '100%'
    },

    loaderDiv: {
        backgroundColor: 'white',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}))
