import React, { useEffect, useState } from "react";
import api_amr from 'services/api_amr';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import TableUsabilidadeSemanal from './ComponentTab01/TableUsabilidadeSemanal';
import TableUsabilidade from './ComponentTab01/TableUsabilidade';
import ControleUsabilidadeLocation from './ComponentTab01/ControleUsabilidadeLocation';
import AutocompleteMapa from './ComponentTab01/AutocompleteMapa';
import TableUsabilidadeUser from './ComponentTab01/TableUsabilidadeUser';
import { getToken } from '../../../utils/utils'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import UsabilidadeTabs from "../pages/UsabilidadeTabs";
import * as XLSX from 'xlsx';


const useStyles = makeStyles({
    tableContainer: {
        maxHeight: '100%',
        overflow: 'auto',
        marginBottom: 500
    },
    autoCompleteFieldBig: {
        marginTop: 10,
        marginRight: 10,
        width: 250,
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        borderRadius: 6,
    },
    typographyTitle: {
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    infoWrapper: {
        padding: 10,
        border: '1px solid lightgray',
        borderRadius: 5
    },
    typographyTitle: {
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    typographyDescription: {
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: 14
    }

});

export default function MapaUsuarios() {
    const classes = useStyles();

    const [sortOrder, setSortOrder] = useState("desc");
    const [sortedColumn, setSortedColumn] = useState("total");
    const [activeTable, setActiveTable] = useState('TableUsabilidade');
    const [returnDashboard, setReturnDashboard] = useState(null);
    const [usabilidadeGeral, setUsabilidadeGeral] = useState(null);
    const [error, setError] = useState(null);
    const [tipo, setTipo] = useState('mes');
    const [ano, setAno] = useState(new Date().getFullYear());
    const [mes, setMes] = useState('');
    const [selectedUserName, setSelectedUserName] = useState("");
    const [usabilidadeTotal, setUsabilidadeTotal] = useState("")
    const [usabilidadePagina,setUsabilidadePagina] = useState('') 
    useEffect(() => {
        setUsabilidadeGeral(null)
        setReturnDashboard(null)
        const fetchDashTableData = async () => {
            try {
                const responseReturnDashboard = await api_amr.get('/return_DashBoardUsabilidade', {
                    params: {
                        'ano': ano, 'mes': mes, 'tipo': tipo, 'all_info': 0,
                    },
                    headers: { 'x-api-key': getToken() }
                });

                if (responseReturnDashboard.data === 'NOK') {
                    throw new Error('Erro ao Carregar DashBoard.');
                } else {

                    const parsedUsabilidadeData = JSON.parse(responseReturnDashboard.data.usabilidade);
                    setReturnDashboard(responseReturnDashboard.data);
                    setUsabilidadeGeral(parsedUsabilidadeData)

                }

                const responseUsabilidadeGeral = await api_amr.get('/return_usabilidade_total_chamadas', {

                    headers: { 'x-api-key': getToken() }
                });
                setUsabilidadeTotal(responseUsabilidadeGeral.data)

                const responseUsabilidadePagina = await api_amr.get('/return_usabilidade_por_pagina', {

                    headers: { 'x-api-key': getToken() }
                });
                setUsabilidadePagina(responseUsabilidadePagina.data)
            } catch (error) {
                console.error('Failed to fetch news data: ', error);
            }
        };
        fetchDashTableData();
    }, [tipo, mes, ano]);

    const handleSort = () => {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        setSortedColumn("total");
    };
    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(usabilidadeTotal);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Histórico usabilidade Acessos");
        XLSX.writeFile(wb, "Historicco_usabilidade_acessos.xlsx");
    };
    const exportToExcelPagina = () => {
        const ws = XLSX.utils.json_to_sheet(usabilidadePagina);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Histórico usabilidade Página");
        XLSX.writeFile(wb, "Historicco_usabilidade_paginas.xlsx");
    };
    
    const handleHistMensal = () => {
        setActiveTable('TableUsabilidade');
        setTipo('mes')
    };

    const handleHistSemanal = () => {
        // cleanAutocomplete()  resets autocomplete in case button changes ?
        setMes('1')
        setActiveTable('Table02');
        setTipo('semana')
        setAno('2024')
    };

    const handleSecondAutocomplete = (value) => {
        if (activeTable === 'TableUsabilidade') {
            setAno(value);
        } else {
            setMes(value);
        }
        console.log('Received value from AutocompleteMapa:', value);
    };

    const handleUserSelection = (userName) => {
        setSelectedUserName(userName);
    };



    return (
        <Grid container style={{ padding: 10 }}>
            <Grid>
                <Typography variant="h5" gutterBottom className={classes.typographyTitle}>
                    Visualizações de engajamento geral
                </Typography>
                <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                    Tela para acompanhamento de engajamento geral da plataforma
                </Typography>
                <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                    -Primeira tabela mostra o somatório de TODAS as chamadas na plataforma
                </Typography>
                <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                    -Primeiro gráfico mostra os acessos por localidade
                </Typography>
                <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                    -Segunda tabela apresenta o total de acessos, por usuário às páginas
                </Typography>
                <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                {usabilidadeTotal!== '' ?
                <button onClick={exportToExcel}>Baixar Histórico usabilidade Acessos</button>
                : null}
                 {usabilidadePagina!== '' ?
                <button onClick={exportToExcelPagina}>Baixar Histórico usabilidade Página</button>
                : null}
                </Typography>
            </Grid>
          
            <Grid item xs={12}>
                <Paper className={classes.infoWrapper}>

                    <Grid item xs={12} style={{ height: 100, padding: 10, marginTop: 10 }}>
                        <Typography variant="h5" gutterBottom className={classes.typographyTitle}>
                            {`Histórico Total de Chamadas ${ano}`}
                        </Typography>
                        <AutocompleteMapa
                            activeTable={activeTable}
                            handleHistMensal={handleHistMensal}
                            handleHistSemanal={handleHistSemanal}
                            handleSecondAutocomplete={handleSecondAutocomplete}
                            setTipo={setTipo}
                        />
                    </Grid>

                    <Grid item xs={12} style={{ height: 370 }}>
                        {usabilidadeGeral && Object.keys(usabilidadeGeral).length > 0 ? (
                            activeTable === 'TableUsabilidade' ? (
                                <TableUsabilidade
                                    classes={classes}
                                    sortedData={usabilidadeGeral}
                                    handleSort={handleSort}
                                    sortedColumn={sortedColumn}
                                    sortOrder={sortOrder}
                                    ano={ano}
                                />
                            ) : (
                                <TableUsabilidadeSemanal
                                    classes={classes}
                                    activeTable={activeTable}
                                    sortedData={usabilidadeGeral}
                                    handleSort={handleSort}
                                    sortedColumn={sortedColumn}
                                    sortOrder={sortOrder}
                                />
                            )
                        ) : (
                            <p>Carregando...</p>
                        )}
                    </Grid>

                </Paper>
            </Grid>

            <Grid container spacing={2}>

                <Grid item xs={12} md={6}>
                    <Paper className={classes.infoWrapper}>

                        <Typography variant="h5" gutterBottom className={classes.typographyTitle} style={{ marginLeft: 10 }}>
                            {`Mapa Geral de Chamadas, Últimos 3 meses`}
                        </Typography>
                        <Grid item xs={12} style={{ height: 400, padding: 10, marginTop: 10 }}>
                            {returnDashboard && returnDashboard.localization && returnDashboard.localization.length > 0 ? (
                                <ControleUsabilidadeLocation locations={returnDashboard.localization} />
                            ) : (
                                <p>Carregando...</p>
                            )}
                        </Grid>

                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper className={classes.infoWrapper}>
                        <Typography variant="h5" gutterBottom className={classes.typographyTitle} style={{ marginLeft: 10 }}>
                            {`Histórico de Páginas por Usuário`}
                        </Typography>
                        <Grid item xs={12} style={{ height: 400, padding: 10, marginTop: 10 }}>
                            <div style={{ height: '100%', width: '100%', overflow: 'auto' }}>
                                <TableUsabilidadeUser
                                    classes={classes}

                                    sortedColumn={sortedColumn}
                                    sortOrder={sortOrder}
                                    handleUserSelection={handleUserSelection}
                                />
                            </div>
                        </Grid>

                    </Paper>
                </Grid>

            </Grid>

        </Grid>
    );

}