import axios from 'axios';
let link = window.location.href
let url = ''

url = 'https://back.macs.dev.br:5002/'

 
const api2 = axios.create({
  baseURL: url,

});

export default api2;
