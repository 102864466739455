import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, TextField, Divider, Button } from '@material-ui/core';
import ComponentParams from './ComponentParams';
import Preview from './Preview';
import Grid from "@material-ui/core/Grid";


// Link com os icones: https://v4.mui.com/pt/components/material-icons/#material-icons
import HistoryIcon from '@material-ui/icons/History';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ViewAgendaOutlinedIcon from '@material-ui/icons/ViewAgendaOutlined';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import NotesOutlinedIcon from '@material-ui/icons/NotesOutlined';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import FilterDramaOutlinedIcon from '@material-ui/icons/FilterDramaOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import PhotoSizeSelectActualOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActualOutlined';
import ShowChartIcon from '@material-ui/icons/ShowChart';


export default function ComponentPreview({ open, onClose, onAddComponent, popoverComponent, adding }) {
    const classes = useStyles()
    const [selectedComponent, setSelectedComponent] = useState('')
    const [selectedData, setSelectedData] = useState('')
    const [selectedParams, setSelectedParams] = useState({})
    const [componentTitle, setComponentTitle] = useState('')

    const componentsWithData = ['card', 'grafico', 'tabela']

    useEffect(() => {
        setSelectedComponent(popoverComponent)
    }, [popoverComponent])

    useEffect(() => {
        setSelectedParams({})
    }, [selectedData])

    useEffect(() => {
        setSelectedData('')
    }, [selectedComponent])

    const returnColorComponent = (name) => {
        if (selectedComponent === name) {
            return '#daedb4'
        } else {
            return 'transparent'
        }
    }

    const returnColorType = (name) => {
        if (selectedData === name) {
            return '#daedb4'
        } else {
            return 'transparent'
        }
    }

    const selectDataType = (value) => {
        setSelectedData(value)
    }

    const handleParamSelect = (paramName, paramValue) => {

        let aux = { ...selectedParams }
        aux[paramName] = paramValue

        setSelectedParams(aux)
    }

    function StepBadge({ number, text }) {
        const classes = useStyles();

        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontFamily: 'Poppins' }}>
                <div className={classes.stepBadge}>
                    {number}
                </div>
                {text}
            </div>
        );
    }

    function getDataTitle(key) {
        const mapping = {
            'relacao_troca': 'Relação de Troca',
            'relacao_troca_formulacoes': 'Relação de Troca Formulacoes',
            'historico_preco': 'Histórico de Preços',
            'dolar': 'Dólar',
            'futuros': 'Futuros',
            'formulas_salvas': 'Formulações Salvas',
        };

        return mapping[key] || ''
    }

    function getComponentTitle(key) {
        const mapping = {
            'card': 'Card',
            'tabela': 'Tabela',
            'grafico': 'Gráfico',
            'note': 'Bloco de Notas',
            'previsao': 'Previsão do Tempo',
            'mapa': 'Mapa',
            'imagem': 'Imagem'
        };
        return mapping[key] || ''
    }

    function validateSave() {
        if (!isFormValid()) {
            return
        }

        let auxTitle = `${getComponentTitle(selectedComponent)} ${getDataTitle(selectedData)}`
        if (componentTitle !== '') {
            auxTitle = componentTitle
        }

        onAddComponent(selectedComponent, selectedData, selectedParams, auxTitle)
        closeClean()
    }

    const isFormValid = () => {
        if (!selectedComponent) {
            alert("Please select a component!");
            return false;
        }

        if (!validateData()) {
            return false;
        }

        if (!validateParams()) {
            return false;
        }

        return true;
    }

    const validateData = () => {
        if (['note', 'previsao'].includes(selectedComponent) && selectedData) {
            alert("Por favor, selecione um tipo de dado!");
            return false;
        }
        return true;
    }

    const validateParams = () => {

        if (['note', 'previsao', 'mapa', 'imagem'].includes(selectedComponent)) {
            if (selectedComponent === 'note' && !selectedParams.text) {
                alert("Por favor, forneça o texto da nota!")
                return false
            }
            if (selectedComponent === 'previsao' && (!selectedParams.regiao || !selectedParams.periodo)) {
                alert('Por favor, selecione os campos de região e periodo.')
                return false
            }
            if (selectedComponent === 'imagem' && (!selectedParams.link)) {
                alert('Por favor, insira um link valido para uma imagem.')
                return false
            }
            return true
        }

        switch (selectedData) {
            case 'historico_preco':
                if (selectedComponent === 'tabela') {
                    if (!selectedParams.porto) {
                        alert("Por favor, forneça o valor para o campo 'Porto' na tabela!")
                        return false
                    }
                } else {
                    if (!selectedParams.id_materia || !selectedParams.porto) {
                        alert("Por favor, forneça os valores para os campos 'Matéria' e 'Porto'")
                        return false
                    }
                }
                break

            case 'relacao_troca':
                if (!selectedParams.id_materia || !selectedParams.porto || !selectedParams.cultura) {
                    alert("Por favor, forneça os valores para os campos 'Matéria', 'Porto' e 'Cultura333'")
                    return false
                }
                break
            case 'relacao_troca_formulacoes':
                if (!selectedParams.id_formulacao || !selectedParams.porto || !selectedParams.cultura) {
                    alert(JSON.stringify(selectedParams));
                    return false
                }
                break
            case 'futuros':
                if (!selectedParams.tabela || !selectedParams.contratos) {
                    alert("Por favor, forneça os valores para os campos 'Tabela' e 'Contratos'")
                    return false

                }
                break

            case 'dolar':
                break // não precisa de parametros no dolar

            case 'formulas_salvas':
                break // não precisa de parametros no dolar

            default:
                alert("Parâmetros fornecidos inválidos.")
                return false
        }
        return true
    }

    const closeClean = () => {
        setSelectedComponent('')
        setSelectedData('')
        setSelectedParams({})
        setComponentTitle('')
        onClose()
    }

    return (
        <Dialog open={open} onClose={closeClean} maxWidth="lg" fullWidth >
            <DialogTitle>Adicione o componente ao dashboard</DialogTitle>

            <DialogContent className={classes.dialog}>
                {/* 0. Component Preview */}
                <Grid container className={classes.preview}>
                    <Grid item xs={8}>
                        <Preview selectedComponent={selectedComponent} selectedData={selectedData} selectedParams={selectedParams} />
                    </Grid>
                </Grid>

                <div className={classes.section}>
                    {/* 1. Component Selection */}
                    <StepBadge number={1} text='Selecione o Componente' />

                    <div className={classes.rowDiv}>
                        <div className={classes.row}>
                            <div
                                onClick={() => setSelectedComponent('card')}
                                className={classes.listItem} style={{ background: returnColorComponent('card') }}>
                                <ViewAgendaOutlinedIcon className={classes.icon} />
                                Card
                            </div>
                            <div
                                onClick={() => setSelectedComponent('tabela')}
                                className={classes.listItem} style={{ background: returnColorComponent('tabela') }}>
                                <TableChartOutlinedIcon className={classes.icon} />
                                Tabela
                            </div>
                            <div
                                onClick={() => setSelectedComponent('grafico')}
                                className={classes.listItem} style={{ background: returnColorComponent('grafico') }}>
                                <InsertChartOutlinedIcon className={classes.icon} />
                                Gráfico
                            </div>
                            <div
                                onClick={() => setSelectedComponent('note')}
                                className={classes.listItem} style={{ background: returnColorComponent('note') }}>
                                <NotesOutlinedIcon className={classes.icon} />
                                Bloco de Notas
                            </div>
                            <div
                                onClick={() => setSelectedComponent('previsao')}
                                className={classes.listItem} style={{ background: returnColorComponent('previsao') }}>
                                <FilterDramaOutlinedIcon className={classes.icon} />
                                Previsão Climatica
                            </div>
                            <div
                                onClick={() => setSelectedComponent('mapa')}
                                className={classes.listItem} style={{ background: returnColorComponent('mapa') }}>
                                <MapOutlinedIcon className={classes.icon} />
                                Mapa
                            </div>
                            <div
                                onClick={() => setSelectedComponent('imagem')}
                                className={classes.listItem} style={{ background: returnColorComponent('imagem') }}>
                                <PhotoSizeSelectActualOutlinedIcon className={classes.icon} />
                                Imagem
                            </div>
                        </div>
                    </div>
                </div>

                <Divider />

                <div className={classes.section}>
                    {/* 2. Component Customization */}
                    <StepBadge number={2} text='Customização dos dados' />

                    <div className={classes.rowDiv}>
                        {componentsWithData.includes(selectedComponent) ?
                            <div className={classes.row}>
                                <div
                                    onClick={() => selectDataType('historico_preco')}
                                    className={classes.listItem} style={{ background: returnColorType('historico_preco') }}>
                                    <HistoryIcon className={classes.icon} />
                                    Histórico de Preços
                                </div>
                                <div
                                    onClick={() => selectDataType('relacao_troca')}
                                    className={classes.listItem} style={{ background: returnColorType('relacao_troca') }}>
                                    <SwapHorizIcon className={classes.icon} />
                                    Relação de Trocas
                                </div>
                                {selectedComponent !== 'card' ?
                                    <div onClick={() => selectDataType('futuros')}
                                        className={classes.listItem} style={{ background: returnColorType('futuros') }}>
                                        <ShowChartIcon className={classes.icon} />
                                        Futuros
                                    </div> : null}

                                {selectedComponent === 'card' ?
                                    <div
                                        onClick={() => selectDataType('dolar')}
                                        className={classes.listItem} style={{ background: returnColorType('dolar') }}>
                                        <AttachMoneyIcon className={classes.icon} />
                                        Dólar
                                    </div>
                                    : null}

                                {selectedComponent === 'tabela' ?
                                    <div
                                        onClick={() => selectDataType('formulas_salvas')}
                                        className={classes.listItem} style={{ background: returnColorType('formulas_salvas') }}>
                                        <AttachMoneyIcon className={classes.icon} />
                                        Formulações Salvas
                                    </div>
                                    : null}
                                {selectedComponent === 'grafico' ?
                                    <div onClick={() => selectDataType('relacao_troca_formulacoes')}
                                        className={classes.listItem} style={{ background: returnColorType('relacao_troca_formulacoes') }}>
                                        <ShowChartIcon className={classes.icon} />
                                        Relação de Troca Formulaçoes
                                    </div> : null}

                            </div>
                            : null}

                        <div style={{ marginTop: 10 }}>
                            <ComponentParams selectedComponent={selectedComponent} selectedData={selectedData} onParamSelect={handleParamSelect} />
                        </div>
                    </div>
                </div>

                <Divider />

                <div className={classes.section}>
                    {/* 3. Title Section */}
                    <StepBadge number={3} text='Adicione um título' />

                    <div className={classes.rowDiv}>
                        <TextField
                            onChange={(e) => setComponentTitle(e.target.value)}
                            size='small'
                            InputProps={{
                                classes: {
                                    root: classes.inputRoot,
                                }, disableUnderline: true,
                            }}
                        />
                    </div>
                </div>

                <Divider />

                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 10 }}>
                    <Button
                        disabled={adding}
                        onClick={validateSave}
                        variant='contained'
                        size='small'
                        color='primary'>
                        Salvar
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

const useStyles = makeStyles(() => ({
    dialog: {
        overflowX: 'hidden',
        justifyContent: 'center',
    },

    rowDiv: {
        paddingLeft: 40
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    preview: {
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid lightgray',
        height: 300
    },

    inputRoot: {
        fontSize: 14,
        width: 250,
        borderRadius: 2,
        paddingLeft: 10,
        border: '1px solid lightgray'
    },

    listItem: {
        '&:hover': {
            background: '#f0f0f0 !important',
        },
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontSize: '0.8rem',
        padding: 5,
        borderRadius: 5,
        cursor: 'Pointer'
    },

    icon: {
        marginRight: 5,
        fontSize: 16
    },

    stepBadge: {
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        backgroundColor: '#A3CC51',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        marginRight: 10,
        color: 'white'
    },

    section: {
        marginTop: 10,
        marginBottom: 10
    }
}));