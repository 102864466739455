import React, { useState, useEffect } from "react";
import { TextField, Button, makeStyles } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import api_amr from '../../services/api_amr';
import { getToken } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
    formField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
}));

const formatDateForInput = (date) => {
    const d = new Date(date);
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const day = `0${d.getDate()}`.slice(-2);
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
};

const AddRecomendacaoForm = ({ demandaId, onClose }) => {
    const [newRecomendacao, setNewRecomendacao] = useState({
        data_recomendacao: '',
        cotacao: '',
        custo_cfr: '',
        observacoes: '',
        fornecedor: '',
        cidade: '',
        frete: ''
    });
    const [cidades, setCidades] = useState([]);
    const [selectedCidade, setSelectedCidade] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        const today = new Date();
        const formattedDate = formatDateForInput(today);
        setNewRecomendacao((prevState) => ({
            ...prevState,
            data_recomendacao: formattedDate,
        }));

        const fetchCidades = async () => {
            try {
                const response = await api_amr.get('/return_cidades_brasil', {
                    headers: { 'x-api-key': getToken() }
                });
                setCidades(response.data);
            } catch (error) {
                alert('Erro ao buscar cidades:', error);
            }
        };

        fetchCidades();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewRecomendacao({ ...newRecomendacao, [name]: value });
    };

    const handleCidadeChange = (event, value) => {
        setSelectedCidade(value);
        setNewRecomendacao({ ...newRecomendacao, cidade: value ? value.id : '' });
    };

    const handleAddRecomendacao = async () => {
        try {
            const response = await api_amr.post('/add_recomendacao', { ...newRecomendacao, demanda_id: demandaId }, { headers: { 'x-api-key': getToken() } });
            if (response.data === 'OK') {
                alert('Recomendação adicionada com sucesso');
                onClose();
            } else {
                alert('Erro ao adicionar recomendação:', response.data);
            }
        } catch (error) {
            alert('Erro ao adicionar recomendação:', error);
        }
    };

    return (
        <div>
            <TextField
                label="Data da Recomendação"
                name="data_recomendacao"
                type="date"
                variant="outlined"
                className={classes.formField}
                value={newRecomendacao.data_recomendacao}
                onChange={handleInputChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                label="Cotação (R$)"
                name="cotacao"
                variant="outlined"
                className={classes.formField}
                value={newRecomendacao.cotacao}
                onChange={handleInputChange}
                type="number"
            />
            <TextField
                label="Custo CFR ($)"
                name="custo_cfr"
                variant="outlined"
                className={classes.formField}
                value={newRecomendacao.custo_cfr}
                onChange={handleInputChange}
                type="number"
            />
            <TextField
                label="Frete (R$)"
                name="frete"
                variant="outlined"
                className={classes.formField}
                value={newRecomendacao.frete}
                onChange={handleInputChange}
                type="number"
            />
            <Autocomplete
                options={cidades}
                getOptionLabel={(option) => `${option.cidade} - ${option.UF}`}
                className={classes.formField}
                value={selectedCidade}
                onChange={handleCidadeChange}
                renderInput={(params) => (
                    <TextField {...params} label="Cidade" variant="outlined" />
                )}
            />
            <TextField
                label="Observações"
                name="observacoes"
                variant="outlined"
                className={classes.formField}
                value={newRecomendacao.observacoes}
                onChange={handleInputChange}
                multiline
                rows={4}
            />
            <TextField
                label="Fornecedor"
                name="fornecedor"
                variant="outlined"
                className={classes.formField}
                value={newRecomendacao.fornecedor}
                onChange={handleInputChange}
                multiline
                rows={4}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleAddRecomendacao}
            >
                Salvar Recomendação
            </Button>
        </div>
    );
};

export default AddRecomendacaoForm;
