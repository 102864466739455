import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getToken, checarPermissao } from '../../utils/utils'
import api_amr from '../../services/api_amr'
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip as AliasTool,
    Filler,
    Legend,
    BarElement,
} from 'chart.js';
import logo from "../../assets/img/logo_arm_opaque.png";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";

import { Line, Bar } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    AliasTool,
    Filler,
    Legend,
    BarElement
);


function HistRelacaoDeTroca(props) {

    const [graficoAuxKCLs, setGraficoAuxKCLs] = useState('')
    const [graficoAuxKCLm, setGraficoAuxKCLm] = useState('')
    const [graficoAuxSSPs, setGraficoAuxSSPs] = useState('')
    const [graficoAuxSSPm, setGraficoAuxSSPm] = useState('')
    const [graficoAuxMAPs, setGraficoAuxMAPs] = useState('')
    const [graficoAuxMAPm, setGraficoAuxMAPm] = useState('')
    const [permitido, setPermitido] = useState(false)

    const [idMateria, setIdMateria] = useState(0)
    const [listaMaterias, setListaMaterias] = useState(0)
    const [porto, setPorto] = useState('')
    const [portos, setPortos] = useState('')

    const classes = useStyles();

    useEffect(() => {
        async function fetchMyAPI() {
            setPermitido(await checarPermissao('hist_rel_troca', props))

            //DEFAULT_GRID_COL_TYPE_KEY()
            const reponse_materia_lista = await api_amr.get('/get_materias', { headers: { 'x-api-key': getToken() } });
            setListaMaterias(reponse_materia_lista.data)

            const response_return_portos = await api_amr.get('/return_portos', { headers: { 'x-api-key': getToken() } });
            setPortos(response_return_portos.data);
            //54 = paranguá
            // 99 = kcl
            // 84 = MAP 52
            //94 = ssp  21
            setGraficoAuxSSPs(await getRelTroca('soja', 54, 99, false, 'KCL'))
            setGraficoAuxSSPm(await getRelTroca('milho', 54, 99, false, 'KCL'))

            setGraficoAuxKCLm(await getRelTroca('soja', 54, 84, false, 'MAP 52'))
            setGraficoAuxKCLs(await getRelTroca('milho', 54, 84, false, 'MAP 52'))
            setGraficoAuxMAPs(await getRelTroca('soja', 54, 94, false, 'SSP 21'))
            setGraficoAuxMAPm(await getRelTroca('milho', 54, 94, false, 'SSP 21'))
        }
        fetchMyAPI();
    }, []);
    async function getRelTroca(tipo = 'soja', porto, id_materia, manual = false, nome_mat = '') {

        const response_rel_troca = await api_amr.get('/return_relacao_de_troca', { params: { 'materia': id_materia, 'tipo': tipo, 'id_porto': porto }, headers: { 'x-api-key': getToken() } })
        const dados = response_rel_troca.data
        let state = {
            labels: ['Jan', 'Fev', 'Mar',
                'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            options: {
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                stacked: false,
                plugins: {
                    title: {
                        display: true,
                        text: 'Intervalo Histórico relação de troca ' + nome_mat + ' ' + tipo
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false
                        }
                    },
                    y: {

                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false
                        }
                    }
                },
            },
            datasets: [
                {
                    label: '2024',
                    data: dados.atual,
                    borderColor: '#0066C0',
                    backgroundColor: '#1F4B80',
                },
                {
                    label: '2023',
                    data: dados.passado,
                    borderColor: '#4CB1CB',
                    backgroundColor: '#4CB1CB',
                },
                {
                    fill: 1,
                    label: 'Max',
                    data: dados.max,
                    showLine: false,
                    pointRadius: 0,
                    backgroundColor: 'rgba(237, 237, 237, 0.6)'


                },
                {
                    fill: 1,
                    label: 'Min',
                    data: dados.min,
                    showLine: false,
                    pointRadius: 0,
                    backgroundColor: 'rgba(237, 237, 237, 0.6)'


                }
            ],
            barras: {
                labels: ['2017', '2018', '2019', '2020', '2021', '2022'],

                options: {

                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: 'Médias anuais ' + nome_mat + ' ' + tipo
                        }
                    }, scales: {
                        x: {

                            grid: {
                                display: true,
                                drawBorder: true,
                                drawOnChartArea: false
                            }
                        },
                        y: {
                            max: Math.ceil(Math.max(...dados.max, ...dados.atual) / 5) * 5,
                            grid: {
                                display: true,
                                drawBorder: true,
                                drawOnChartArea: false
                            }
                        },

                    },
                },


                datasets: [
                    {
                        label: 'Médias',
                        data: dados.medias_ano,
                        borderColor: 'gray',
                        backgroundColor: 'gray',
                        barPercentage: 0.8,
                        categoryPercentage: 0.7,
                    }],
            },
        }

        if (manual === true) {
            return state
        }
        return state
    }

    return (
        permitido ?
            <div className={classes.container} >

                {/* <Autocomplete
                    options={listaMaterias}
                    getOptionLabel={(option) => (option.id_materias + ' - ' + option.abreviacao)}
                    className={classes.autoCompleteFieldBig}
                    onChange={(e, v) => setIdMateria(v)}

                    classes={{
                        input: classes.input,
                    }}
                    renderInput={(params) => <TextField
                        {...params}
                        placeholder="Selecionar Matéria" 
                        variant='outlined'
                        value={idMateria}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            classes: { notchedOutline: classes.noBorder }
                        }}
                    />}
                />
                <Autocomplete

                    options={portos}
                    getOptionLabel={(option) => (option.id + ' - ' + option.nome_porto + ' - ' + option.cidade + '/' + option.UF)}
                    className={classes.autoCompleteFieldBig}
                    onChange={(e, v) => (setPorto(v))}

                    classes={{
                        input: classes.input,

                    }}
                    renderInput={(params) => <TextField
                        {...params}
                        placeholder="Selecionar Porto"
                        variant='outlined'
                        value={porto}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            classes: { notchedOutline: classes.noBorder }
                        }}

                    />}
                />
                <div className={classes.calcDiv}>
                    <Button onClick={() => (
                     getRelTrocaKCLm('milho', porto, true),
                     getRelTrocaKCLs('soja', porto, true),
                     getRelTrocaSSPs('soja', porto, true),
                     getRelTrocaSSPm('milho', porto, true),
                     getRelTrocaMAPs('soja', porto, true),
                     getRelTrocaMAPm('milho', porto, true),
                     getRelTroca(idMateria, 'soja', porto, true), 
                     getRelTroca2(idMateria, 'milho', porto, true),
                     console.log(idMateria),console.log(porto))} 
                     variant="contained" className={classes.calcButton}> Selecionar</Button>
                </div> */}

                <div >
                    <h2 className={classes.inputh1}>Histórico Relação de troca {porto !== null ? porto.nome_porto : ''}</h2>

                </div>


                {graficoAuxKCLs !== '' ?
                    <div style={{ display: 'flex', flexDirection: 'row', width: 600, height: 300, backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                        <Line data={graficoAuxKCLs} options={graficoAuxKCLs.options} Legend />

                        <div style={{ width: 250 }}>
                            <Bar

                                options={graficoAuxKCLs.barras.options}
                                data={graficoAuxKCLs.barras}

                            />
                        </div>


                    </div> : <CircularProgress style={{ 'color': '#A3CC51' }} />}
                {graficoAuxKCLm !== '' ?
                    <div style={{ display: 'flex', flexDirection: 'row', width: 600, height: 300, backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                        <Line data={graficoAuxKCLm} options={graficoAuxKCLm.options} Legend />

                        <div style={{ width: 250 }}>
                            <Bar
                                options={graficoAuxKCLm.barras.options}
                                data={graficoAuxKCLm.barras}

                            />
                        </div>

                    </div> : <CircularProgress style={{ 'color': '#A3CC51' }} />}
                {graficoAuxMAPs !== '' ?
                    <div style={{ display: 'flex', flexDirection: 'row', width: 600, height: 300, backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                        <Line data={graficoAuxMAPs} options={graficoAuxMAPs.options} Legend />

                        <div style={{ width: 250 }}>
                            <Bar
                                options={graficoAuxMAPs.barras.options}
                                data={graficoAuxMAPs.barras}

                            />
                        </div>

                    </div> : <CircularProgress style={{ 'color': '#A3CC51' }} />}
                {graficoAuxMAPm !== '' ?
                    <div style={{ display: 'flex', flexDirection: 'row', width: 600, height: 300, backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                        <Line data={graficoAuxMAPm} options={graficoAuxMAPm.options} Legend />


                        <div style={{ width: 250 }}>
                            <Bar
                                options={graficoAuxMAPm.barras.options}
                                data={graficoAuxMAPm.barras}

                            />
                        </div>

                    </div> : <CircularProgress style={{ 'color': '#A3CC51' }} />}
                {graficoAuxSSPm !== '' ?
                    <div style={{ display: 'flex', flexDirection: 'row', width: 600, height: 300, backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                        <Line data={graficoAuxSSPm} options={graficoAuxSSPm.options} Legend />

                        <div style={{ width: 250 }}>
                            <Bar
                                options={graficoAuxSSPm.barras.options}
                                data={graficoAuxSSPm.barras}

                            />
                        </div>

                    </div> : <CircularProgress style={{ 'color': '#A3CC51' }} />}
                {graficoAuxSSPs !== '' ?
                    <div style={{ display: 'flex', flexDirection: 'row', width: 600, height: 300, backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                        <Line data={graficoAuxSSPs} options={graficoAuxSSPs.options} Legend />

                        <div style={{ width: 250 }}>
                            <Bar
                                options={graficoAuxSSPs.barras.options}
                                data={graficoAuxSSPs.barras}

                            />
                        </div>

                    </div> : <CircularProgress style={{ 'color': '#A3CC51' }} />}

            </div> : null
    );
}
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 100,
    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    inputh1: {
        fontFamily: 'Poppins',
        color: '#383838'
    },
    inputhDolar: {
        fontFamily: 'Poppins',
        fontSize: 15,
        color: '#A3CC51'
    },
    noBorder: {
        border: "none",
    },
    autoCompleteField: {
        marginTop: 10,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 10,
        width: 484,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    calcDiv: {
        marginTop: 40,
        width: '146px',
        marginLeft: 340,
        height: 45
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },
    },
    table: {
        minWidth: 400,
        maxWidth: '100%'
    },
    rowSize: {
        maxWidth: 500
    },
    tableModels: {
        marginTop: 20,
        maxWidth: '90%',
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    }
}))
export default HistRelacaoDeTroca;
