import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button";
import api from "services/api_amr";
import { getToken } from '../../utils/utils.js'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import gpStyle from "./GPstyle";
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PageNav from "./components/PageNav.js";
import { getComponentValue } from "./utils.js";
import { Link } from 'react-router-dom';


export default function GerenciarPosts(props) {
    const [posts, setPosts] = useState([])
    const [delAlert, setDelAlert] = useState(false)
    const [delId, setDelId] = useState('')
    const [deleting, setDeleting] = useState(false)
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const classes = gpStyle()

    useEffect(() => {
        fetchMyApi()
    }, [])

    const fetchMyApi = async () => {
        const responsePosts = await api.get('/return_posts_new', { params: { page: 1, tag: '', posts_per_page: 10, include_latest: 1 },headers: { 'x-api-key': getToken() } })
        if (responsePosts.data !== '') {
            setPosts(responsePosts.data.posts)
            setTotalPages(responsePosts.data.pages)
        }
    }

    const deletePost = async () => {
        setDeleting(true);

        try {
            const responseDelete = await api.get('/delete_post_new', {
                params: { postId: delId },
                headers: { 'x-api-key': getToken() },
            });

            if (responseDelete.status === 200) {
                fetchMyApi()
            } else {
                // Handle any specific error codes or show a general error message
                alert(`Erro ao deletar publicação. Cod.: ${responseDelete.status}`)
                console.error('Failed to delete post:', responseDelete);
            }
            setDelAlert(false)
        } catch (error) {
            // Handle unexpected errors (e.g., network issues)
            console.error('Error occurred while deleting post:', error);
        }

        setDeleting(false);
    }

    const handleDelete = (postId) => {
        setDelId(postId)
        setDelAlert(true)
    }

    const getMorePosts = async (page) => {
        const responsePosts = await api.get('/return_posts_new', { params: { page, tag: '', posts_per_page: 10, include_latest: 1 },headers: { 'x-api-key': getToken() } })
        setPosts(responsePosts.data.posts)
        setCurrentPage(page)
    }

    return (
        <div className={classes.mainDiv}>
            <div>
                <h2>Gerenciar Publicações</h2>
                <Button variant="contained" onClick={() => props.history.push({ pathname: "/pages/CriarPost", params: { edit: false } })} className={classes.calcButton}>Criar Postagem</Button>
            </div>

            <div>
                <h2>Lista de Publicações</h2>
                <div className={classes.infiniteScroll}>
                    {posts.map((post, index) =>
                        <div key={index} className={classes.postDiv}>
                            {new Date(post.post_date).toLocaleString('pt-br', { dateStyle: 'short', timeZone: 'UTC' })}
                            <div>
                                {post.user_name}
                            </div>

                            <div>
                                <div style={{ fontFamily: 'Poppins', fontSize: 28, fontWeight: 'bold' }}>{getComponentValue(post, 'main_title')}</div>
                            </div>

                            <div>
                                {getComponentValue(post, 'paragraph')}
                            </div>

                            <div className={classes.buttonDiv}>
                                <div style={{ marginRight: 10 }}>
                                    <Button
                                        component={Link}
                                        to={{
                                            pathname: `Noticia/${post.uuid}`,
                                            state: { post: post },
                                        }}
                                        className={classes.calcButton}
                                        style={{ marginRight: 5 }}
                                    >
                                        Vizualizar
                                    </Button>
                                    <Button                                    
                                        component={Link}
                                        to={{
                                            pathname: '/pages/CriarPost',
                                            state: { post: post, isEditing: true, aiPost: false },
                                        }}
                                        className={classes.calcButton}
                                    >
                                        Editar
                                    </Button>
                                </div>

                                <div onClick={() => handleDelete(post.post_id)} className={classes.miniButtonRed}>
                                    <DeleteForeverRoundedIcon style={{ marginTop: 4, fontSize: 30, color: 'white' }} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <Dialog open={delAlert} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <DialogContentText>
                            Deseja remover a publicação?
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDelAlert(false)} color="primary">
                            Cancelar
                        </Button>

                        <Button disabled={deleting} onClick={() => deletePost()} color="primary">
                            Confirmar
                        </Button>

                    </DialogActions>
                </Dialog>
                <PageNav currentPage={currentPage} totalPages={totalPages} onPageChange={(page) => getMorePosts(page)} />
            </div>
        </div>
    )
}
