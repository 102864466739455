
import React, { useState, useEffect } from "react";
import navio_login from 'assets/img/navio_login.png';
import logo_amr from 'assets/img/logo_amr.png'
import "@fontsource/poppins";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

import api_amr from "../services/api_amr";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from "@material-ui/core/Button";
import { verificaSenha } from '../utils/utils' 


function Cadastro(props) {
  const [showPassword, setShowPassword] = useState(false)
  const [senha, setSenha] = useState('')
  const [senha2, setSenha2] = useState('')
  const [cpf, setCpf] = useState('')
  const [email, setEmail] = useState('')
  const [nome, setNome] = useState('')

  const classes = useStyles();


  useEffect(() => {
   
  }, [])



  async function Cadasto_rapido() {
    if ((nome === '') | (email === '')  | (cpf === '') ) {
      alert('Alguns campos obrigatórios estão incompletos\n' + (nome === '' ? 'Nome\n' : '')
          + (email === '' ? 'Email\n' : '') +  (cpf === '' ? 'CPF \n' : ''))
      return
  }
    if (senha !== senha2) {
      alert('Senhas diferentes')
      return
    }
    else {
      const erros = verificaSenha(senha)
      if (erros.length !== 0) {
        alert(erros.join("\n"))
        return
      }
      else {
        const response_cadastro = await api_amr.post('/cadastro_rapido', { 'email': email, 'password': senha, 'cpf': cpf, 'nome': nome },);
        if (response_cadastro.data === 'OK') {
          alert('Cadastrado com Sucesso')
          window.location.reload()
          return
        }
        if (response_cadastro.data === 'NC') {
          alert('Usuario ja Cadastrado')
        }
        else {
          alert('Erro ao realizar cadastro')
        }
      }
    }

  }


  return (
    <div className={classes.container}>
      <div className={classes.imgDiv}>
        <img src={navio_login} alt="login" style={{ width: '100%' }} />
      </div>
      <div className={classes.loginDiv}>
        <img src={logo_amr} alt="login" style={{ width: '35%', height: '7%' }} />
        <h2 style={{ fontFamily: 'Poppins', fontSize: '20px', color: '#383838', textAlign: 'left'}}> Cadastro</h2>

        <div style={{ display: 'flex', flexDirection: 'column' }}>


          <TextField
            key='NOME'
            label="NOME*"
            variant='outlined'
            onChange={(event) => setNome(event.target.value)}
            value={nome}
            className={classes.autoCompleteFieldBig}

            InputProps={{
              disableUnderline: true,
              classes: { notchedOutline: classes.noBorder }
            }}
          />
          <TextField
            key='CPF'
            label="CPF*"
            variant='outlined'
            onChange={(event) => setCpf(event.target.value)}
            value={cpf}
            className={classes.autoCompleteFieldBig}

            InputProps={{
              disableUnderline: true,
              classes: { notchedOutline: classes.noBorder }
            }}
          />
          <TextField
            key='email'
            label="Email*"
            variant='outlined'
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            className={classes.autoCompleteFieldBig}

            InputProps={{
              disableUnderline: true,
              classes: { notchedOutline: classes.noBorder }
            }}
          />
        </div>
        <div>
          <InputBase
            key='senha'
            variant='outlined'
            autoComplete="new-password"
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={senha}
            placeholder='Senha'
            onChange={(event) => setSenha(event.target.value)}
            className={classes.autoCompleteField}
            InputProps={{
              disableUnderline: true,
              classes: { notchedOutline: classes.noBorder }
            }}
            style={{ height: 50, paddingLeft: 15 }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(true)}
                  onMouseDown={() => setShowPassword(false)}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
          <InputBase
            variant='outlined'
            id="standard-adornment-password2"
            autoComplete="new-password"
            type={showPassword ? 'text' : 'password'}
            value={senha2}
            placeholder='Redigite a senha'
            onChange={(event) => setSenha2(event.target.value)}
            className={classes.autoCompleteField}
            InputProps={{
              disableUnderline: true,
              classes: { notchedOutline: classes.noBorder }
            }}
            style={{ height: 50, paddingLeft: 15, marginLeft: 15 }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(true)}
                  onMouseDown={() => setShowPassword(false)}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
          
        </div>
        <div className={classes.calcDiv}>
            <Button onClick={() => Cadasto_rapido()} variant="contained" className={classes.calcButton}> Cadastrar</Button>
          </div>

      </div>
    </div >


  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row'
  },
  imgDiv: {
    width: '50%',
    height: '100%'
  },
  loginDiv: {
    width: '50%',
    height: '100%',

    alignItems: 'center',
    justifyContent: 'center',

    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    fontFamily: 'Poppins',
    fontSize: '9px',
    width: '352px',
    backgroundColor: 'white',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItens: 'center',
    borderRadius: 6,

    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
  },
  autoCompleteField: {
    marginTop: 15,
    minWidth: 228,
    maxWidth: 228,

    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
  },
  autoCompleteFieldBig: {
    marginTop: 15,
    width: 471,

    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

  },
  input: {
    fontFamily: 'Poppins',
    fontSize: '9px',
    width: '352px'
  },
  calcButton: {
    fontFamily: 'Poppins',
    height: 40,
    fontSize: '10px',
    background: '#A3CC51',
    width: '146px',
    color: 'white',
    borderRadius: 6,
    '&:hover': {
      background: "rgba(163, 204, 81, 0.7)",
    },

  },
  calcDiv: {
    marginTop: 40,
    width: '146px',
    marginLeft: 325,
    height: 45
  }
}));



export default Cadastro;
