import React, { useEffect, useState } from "react"
import mnStyle from "./mnStyle"
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import PersonIcon from '@material-ui/icons/Person';
import warningImage from '../../../assets/img/login_warning.png'
import api from "services/api_amr";
import { getToken, capitalizeString } from '../../../utils/utils'
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import Button from "@material-ui/core/Button";
import Loader from 'react-loader-spinner';
import { WhatsappShareButton, FacebookShareButton, TwitterShareButton, FacebookIcon, WhatsappIcon, TwitterIcon } from "react-share";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LaptopWindows } from "@material-ui/icons";

const useStyles = makeStyles({
    newsBorder: {
        border: '2px solid #A3CC51', /* Change this value to your desired color */
        borderRadius: '20px',
        padding: '20px',
         /* Change this value to your desired background color */
        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',
        /* Add a subtle shadow */
    }
});

export default function MostrarNoticia(props) {
    const classes = mnStyle()
    const classesTeste = useStyles();
    const [post, setPost] = useState('')
    const [postInfo, setPostInfo] = useState('')
    const [postTags, setPostTags] = useState('')
    const [postTagsPrimarias, setPostTagsPrimarias] = useState('')
    const [showLoginWarning, setShowLoginWaring] = useState(false)
    const smallerThan600px = useMediaQuery("(max-width: 600px)")

    useEffect(() => {
        fetchMyApi()
    }, [])

    const fetchMyApi = async () => {

        if (getToken() === '') {
            await api.get('/increment_post_view_unlogged', { params: { 'postId': props.match.params.nid } })
        } else {
            await api.get('/increment_post_view_logged', { params: { 'postId': props.match.params.nid }, headers: { 'x-api-key': getToken() } })
        }

        if (await loginWarning()) {
            const responseInfo = await api.get('/return_post_info', { params: { 'postId': props.match.params.nid } })
            console.log(responseInfo.data)
            setPostInfo(responseInfo.data[0])
            setPostTags(capitalizeTags(responseInfo.data[0].tags))
            setPostTagsPrimarias(capitalizeTags(responseInfo.data[0].tagsPrimarias))
            localStorage.setItem('cachedLink', window.location.href)
        } else {
            const responsePost = await api.get('/return_post_components_by_id', { params: { 'postId': props.match.params.nid } })
            const responseInfo = await api.get('/return_post_info', { params: { 'postId': props.match.params.nid } })

            let aux_response = responsePost.data
            // esse loop transforma o base64 em um blob (é um for porque só o for consegue usar o await)
            for (let index = 0; index < aux_response.length; index++) {
                if (aux_response[index].type === 'image' || aux_response[index].type === 'audio' || aux_response[index].type === 'video' || aux_response[index].type === 'file') {
                    let blob = await fetch(aux_response[index].value).then(r => r.blob())
                    aux_response[index].value = blob
                }
            }
            console.log(responseInfo.data)
            setPostInfo(responseInfo.data[0])
            setPostTags(capitalizeTags(responseInfo.data[0].tags))
            setPostTagsPrimarias(capitalizeTags(responseInfo.data[0].tagsPrimarias))
            setPost(aux_response)
        }
    }

    const loginWarning = async () => {
        const response = await api.get('/post_login_required', { params: { 'postId': props.match.params.nid } })
        let loginRequired = parseInt(response.data.login_required)

        if ((loginRequired === 1) &( getToken() === '')) {
            setShowLoginWaring(true)
           
            return true
        } else {
            return false
        }
    }


    const renderComponent = (item, index) => {
        switch (item.type) {
            case 'paragraph':
                return (
                    <div key={index} >
                        {item.value.split(/\n/).map((line, index) => <div key={line}><p>{line}</p></div>)}
                    </div>
                )
            case 'title':
                return (
                    <div key={index}>
                        <h1>{item.value}</h1>
                    </div>
                )
            case 'image':
                return (
                    <img className={classes.imageDiv} src={URL.createObjectURL(item.value)} />
                )
            case 'audio':
                return (
                    <audio style={{ width: '100%' }} controls>
                        <source src={URL.createObjectURL(item.value)} type="audio/mp3" />
                    </audio>
                )
            case 'video':
                return (
                    <div key={index} >
                        <video width="100%" controls>
                            <source src={URL.createObjectURL(item.value)} type="video/mp4" />
                        </video>
                    </div>
                )
            case 'file':
                return (
                    <div key={index}>
                        <a href={URL.createObjectURL(item.value)} style={{ textDecoration: 'none', width: '100%', display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', textAlign: 'center' }} download>

                            <div>

                                <DescriptionRoundedIcon style={{ fontSize: '30px' }} />
                            </div>
                            {item.filename}
                        </a>
                    </div>
                )
            default:
                return null
        }
    }

    const capitalizeTags = (tags) => {
        tags = tags.split(', ')
        let aux = []
        tags.map(tag => aux.push(capitalizeString(tag)))
        return aux.join(', ')
    }

    const handleShareString = (post_date, post_tags, post_title) => {
        return (
            `*${post_title}*\n${new Date(post_date).toLocaleString('pt-br', { dateStyle: 'long', timeZone: 'UTC' })}\n_${post_tags}_\n\n${window.location.href}`
        )
    }

    return (
        <div className={classes.mainDiv}>
            <div className={classes.postDiv} style={{ alignItems: smallerThan600px ? 'center' : '' }}>
                {post !== '' ?
                    < div className={classes.postDiv2} style={{ marginLeft: smallerThan600px ? '0' : '20%' }}>

                        < div className={classesTeste.newsBorder}>

                            <h1 style={{ fontSize: 'calc(1.4rem + 1vw)' }}>{postInfo.title}</h1>

                            <div style={{ marginBottom: 30 }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className={classes.miniUser}>
                                        <PersonOutlineRoundedIcon style={{ fontSize: 20 }} />
                                        {postInfo.post_username}
                                    </div>

                                    <div className={classes.miniVerified}>
                                        {Boolean(postInfo.verified) ?
                                            <>
                                                <CheckRoundedIcon style={{ fontSize: 20 }} /> Notícia verificada
                                            </> : null}
                                    </div>
                                </div>

                                <div style={{ color: '#7E6F23', fontWeight: 'bold', marginRight: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {postTagsPrimarias}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {postTags}
                                    </div>

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', right: 0, alignContent: 'center', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', right: 0, alignContent: 'center', alignItems: 'center' }}>
                                        <div style={{ marginRight: 20, fontSize: 14, color: 'rgba(0, 0, 0, .4)', fontWeight: 'bold' }}>
                                            {new Date(postInfo.post_date).toLocaleString('pt-br', { dateStyle: 'long', timeZone: 'UTC' })}
                                        </div>
                                    </div>

                                    <div className={classes.shareDiv}>
                                        <WhatsappShareButton url={handleShareString(postInfo.post_date, postTags, postInfo.title)} children={<WhatsappIcon size={25} />} />
                                        <FacebookShareButton url={window.location.href} children={<FacebookIcon size={25} />} />
                                        <TwitterShareButton url={window.location.href} children={<TwitterIcon size={25} />} />
                                    </div>
                                </div>
                            </div>
                            {postInfo !== '' ? <p>{postInfo.summary.split(/\n/).map(line => <div key={line}>{line}</div>)}</p> : ''}
                           

                            {post !== '' && !showLoginWarning ?
                                post.map((component, index) =>
                                    <React.Fragment key={index}>
                                        <div >
                                            {renderComponent(component, index)}

                                        </div>
                                    </React.Fragment>)
                                : showLoginWarning ? null :
                                    <div className={classes.loaderDiv}>
                                        <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
                                    </div>
                            }
                            <Button variant="contained" onClick={() => props.history.push({ pathname: '/Noticias' })} className={classes.button}>Voltar</Button>
                        </div>

                    </div> :
                    <div className={classes.loaderDiv}>
                        <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
                    </div>}
            </div>
            {showLoginWarning ?
                                <div className={classes.warningDiv}>
                                    <img src={warningImage} style={{ width: '100%', maxWidth: 800 }} />

                                    <Button onClick={() => props.history.push({ pathname: "/" })} className={classes.button}>
                                        <PersonIcon style={{ marginRight: 5 }} />
                                        Login
                                    </Button>
                                </div>
                                : null
                            }
        </div >
    )
}
