import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import api_amr from '../../services/api_amr';
import { getToken } from '../../utils/utils';
import { niveisInvestimentoOptions, segmentosOptions } from './autocompleteOptions';
import TabelaDefensivos from './TabelaDefensivos';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    margin: 'auto',
    maxWidth: 1200,
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(4),
  },
}));


const Defensivos = () => {
  const classes = useStyles();

  const [defensivo, setDefensivo] = useState(null);
  const [defensivosOptions, setDefensivosOptions] = useState([]);

  const [culturas, setCulturas] = useState([]);
  const [cultura, setCultura] = useState(null);

  const [pragas, setPragas] = useState([]);
  const [praga, setPraga] = useState([]);

  const [doseMinima, setDoseMinima] = useState('');
  const [doseMaxima, setDoseMaxima] = useState('');
  const [doseUsual, setDoseUsual] = useState('')

  const [segmento, setSegmento] = useState(null);
  const [funcaoPrincipal, setFuncaoPrincipal] = useState(null);
  const [funcoesPrincipaisOptions, setFuncoesPrincipaisOptions] = useState([]);

  const [nivelInvestimento, setNivelInvestimento] = useState('');

  const [defensivosComDose, setDefensivosComDose] = useState([]);

  // State for Modal
  const [openModal, setOpenModal] = useState(false);
  const [selectedDefensivoRow, setSelectedDefensivoRow] = useState(null);
  const [regioesOptions, setRegioesOptions] = useState([]);
  const [regiao, setRegiao] = useState(null);
  const [preco, setPreco] = useState('');
  const [dataPreco, setDataPreco] = useState('');
  const [precos, setPrecos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [defensivosResponse, defensivosComDoseResponse, regioesResponse] = await Promise.all([
          api_amr.get('/retorna_nomes_comerciais_defensivos', {
            headers: { 'x-api-key': getToken() },
          }),
          api_amr.get('/retorna_defensivos_com_dose', {
            headers: { 'x-api-key': getToken() },
          }),
          api_amr.get('/retorna_regioes', {
            headers: { 'x-api-key': getToken() },
          }),
        ]);

        setDefensivosOptions(defensivosResponse.data);
        setDefensivosComDose(defensivosComDoseResponse.data);
        setRegioesOptions(regioesResponse.data);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (defensivo) {
      const fetchCulturas = async () => {
        try {
          const response = await api_amr.get('/retorna_culturas_defensivos', {
            headers: { 'x-api-key': getToken() },
            params: { id_marca: defensivo.id },
          });
          setCulturas(response.data);
        } catch (error) {
          console.error('Erro ao buscar culturas:', error);
        }
      };

      fetchCulturas();
    } else {
      setCulturas([]);
      setCultura(null);
    }
  }, [defensivo]);

  useEffect(() => {
    if (defensivo && cultura) {
      const fetchPragas = async () => {
        try {
          const response = await api_amr.get('/retorna_pragas_defensivos', {
            headers: { 'x-api-key': getToken() },
            params: {
              id_marca: defensivo.id,
              id_cultura: cultura.id_cultura,
            },
          });
          setPragas(response.data || []);
        } catch (error) {
          console.error('Erro ao buscar pragas:', error);
          setPragas([]);
        }
      };

      fetchPragas();
    } else {
      setPragas([]);
      setPraga([]);
    }
  }, [defensivo, cultura]);

  useEffect(() => {
    if (segmento && segmento.funcoesPrincipais.length > 0) {
      setFuncoesPrincipaisOptions(segmento.funcoesPrincipais);
    } else {
      setFuncoesPrincipaisOptions([]);
      setFuncaoPrincipal(null);
    }
  }, [segmento]);

  const handleSubmit = async () => {
    try {
      // Verificar se os campos obrigatórios estão preenchidos
      if (!defensivo || !cultura || praga.length === 0 || !doseMinima || !doseMaxima || !segmento || !funcaoPrincipal || !nivelInvestimento) {
        alert('Por favor, preencha todos os campos obrigatórios.');
        return;
      }

      await api_amr.post(
        '/salva_dose_defensivos',
        {
          id_marca: defensivo.id,
          id_cultura: cultura.id_cultura,
          id_praga: praga.map((p) => p.id_praga), // Enviar array de IDs de pragas
          dose_min: doseMinima,
          dose_max: doseMaxima,
          dose_usual: doseUsual,
          segmento: segmento ? segmento.name : null,
          funcao_principal: funcaoPrincipal,
          nivel_investimento: nivelInvestimento,
        },
        {
          headers: { 'x-api-key': getToken() },
        }
      );
      alert('Dados salvos com sucesso!');

      // Limpar os campos do formulário após o salvamento
      setDefensivo(null);
      setCultura(null);
      setPraga([]);
      setDoseMinima('');
      setDoseMaxima('');
      setDoseUsual('');
      setSegmento(null);
      setFuncaoPrincipal(null);
      setNivelInvestimento('');

      // Atualizar a tabela de defensivos com dose
      const response = await api_amr.get('/retorna_defensivos_com_dose', {
        headers: { 'x-api-key': getToken() },
      });
      setDefensivosComDose(response.data);
    } catch (error) {
      console.error('Erro ao salvar dados:', error);
      alert('Ocorreu um erro ao salvar os dados. Por favor, tente novamente.');
    }
  };


  const handleOpenModal = (row) => {
    setSelectedDefensivoRow(row);
    setOpenModal(true);
    fetchPrecos(row);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedDefensivoRow(null);
    setRegiao(null);
    setPreco('');
    setDataPreco('');
    setPrecos([]);
  };

  const fetchPrecos = async (row) => {
    try {
      const response = await api_amr.get('/retorna_precos_defensivo', {
        headers: { 'x-api-key': getToken() },
        params: {
          id_marca: row.id_marca_comercial,
          id_cultura: row.id_cultura,
          id_praga: row.id_praga,
        },
      });
      setPrecos(response.data);
    } catch (error) {
      console.error('Erro ao buscar preços:', error);
    }
  };

  const handleSavePreco = async () => {
    try {
      await api_amr.post(
        '/salva_preco_defensivo',
        {
          id_marca: selectedDefensivoRow.id_marca_comercial,
          id_regiao: regiao.id_regiao,
          data_preco: dataPreco,
          preco: preco,
        },
        {
          headers: { 'x-api-key': getToken() },
        }
      );
      alert('Preço salvo com sucesso!');
      // Refresh the prices
      fetchPrecos(selectedDefensivoRow);
      // Reset form fields
      setRegiao(null);
      setPreco('');
      setDataPreco('');
    } catch (error) {
      console.error('Erro ao salvar preço:', error);
    }
  };

  return (
    <Paper className={classes.paper} elevation={3}>
      <Typography variant="h4" className={classes.title} align="center">
        Cadastro de Defensivos
      </Typography>
      <Grid container spacing={3}>
        {/* Defensivo */}
        <Grid item xs={12}>
          <Autocomplete
            options={defensivosOptions}
            getOptionLabel={(option) => option?.marca_comercial || ''}
            value={defensivo}
            onChange={(event, newValue) => setDefensivo(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Defensivo" variant="outlined" fullWidth />
            )}
          />
        </Grid>

        {/* Cultura */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={culturas}
            getOptionLabel={(option) => option?.CULTURA || ''}
            value={cultura}
            disabled={!defensivo}
            onChange={(event, newValue) => setCultura(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Cultura"
                variant="outlined"
                fullWidth
                disabled={!culturas.length}
              />
            )}
          />
        </Grid>

        {/* Praga */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            multiple
            options={pragas || []}
            value={praga || []}
            getOptionLabel={(option) => option?.PRAGA_NOME_COMUM || ''}
            disabled={!defensivo || !cultura}
            onChange={(event, newValue) => setPraga(newValue)}
            disableCloseOnSelect
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  color="primary"
                  checked={selected}
                  style={{ marginRight: 8 }}
                />
                {option.PRAGA_NOME_COMUM}
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Praga"
                variant="outlined"
                fullWidth
                disabled={!pragas.length}
              />
            )}
          />
        </Grid>

        {/* Dose Mínima */}
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {/* Dose Mínima */}
            <Grid item xs={12} sm={4}>
              <TextField
                label="Dose Mínima"
                variant="outlined"
                fullWidth
                value={doseMinima}
                onChange={(e) => setDoseMinima(e.target.value)}
                type='number'
              />
            </Grid>

            {/* Dose Máxima */}
            <Grid item xs={12} sm={4}>
              <TextField
                label="Dose Máxima"
                variant="outlined"
                fullWidth
                value={doseMaxima}
                onChange={(e) => setDoseMaxima(e.target.value)}
                type='number'

              />
            </Grid>

            {/* Dose Usual */}
            <Grid item xs={12} sm={4}>
              <TextField
                label="Dose Usual"
                variant="outlined"
                fullWidth
                value={doseUsual}
                onChange={(e) => setDoseUsual(e.target.value)}
                type='number'

              />
            </Grid>
          </Grid>
        </Grid>

        {/* Segmento */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={segmentosOptions}
            getOptionLabel={(option) => option.name}
            value={segmento}
            onChange={(event, newValue) => setSegmento(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Segmento" variant="outlined" fullWidth />
            )}
          />
        </Grid>

        {/* Função Principal */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={funcoesPrincipaisOptions}
            getOptionLabel={(option) => option}
            value={funcaoPrincipal}
            disabled={!segmento}
            onChange={(event, newValue) => setFuncaoPrincipal(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Função Principal"
                variant="outlined"
                fullWidth
                disabled={!funcoesPrincipaisOptions.length}
              />
            )}
          />
        </Grid>

        {/* Nível de Investimento */}
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Nível de Investimento</FormLabel>
            <RadioGroup
              row
              value={nivelInvestimento}
              onChange={(event) => setNivelInvestimento(event.target.value)}
            >
              {niveisInvestimentoOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio color="primary" />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* Botão de Salvar */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            size="large"
            className={classes.button}
          >
            Salvar
          </Button>
        </Grid>

        {/* Tabela de Defensivos com Dose */}
        <Grid item xs={12} className={classes.table}>
          <TabelaDefensivos
            defensivosComDose={defensivosComDose}
            regioesOptions={regioesOptions}
            openModal={openModal}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
            handleSavePreco={handleSavePreco}
            selectedDefensivoRow={selectedDefensivoRow}
            regiao={regiao}
            setRegiao={setRegiao}
            preco={preco}
            setPreco={setPreco}
            dataPreco={dataPreco}
            setDataPreco={setDataPreco}
            precos={precos}
          />
        </Grid>
      </Grid>


    </Paper>
  );
};

export default Defensivos;
