// pega o valor de um componente dentro do post
// se for um arquivo tem que retornar o link em vez do value
export const getComponentValue = (post, componentType) => {
    const component = post.components.find((component) => component.type === componentType);
    const specials = ['image', 'audio', 'video', 'file','cover_image']
    if (specials.includes(componentType)) {
      

      
        return component ? component.link : null
    }

    return component ? component.value : null
}


// corta uma string e adiciona 3 pontinhos caso tenha mais coisa depois do corte
export function truncateString(str, maxLength) {
    if (str.length > maxLength) {
        return str.slice(0, maxLength) + '...'
    } else {
        return str
    }
}
