import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Box,
    Typography,
    Paper,
    Grid,
    Tooltip,
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import { getToken } from 'utils/utils';
import api_amr from 'services/api_amr';

export default function Notificacoes() {
    const [titulo, setTitulo] = useState('')
    const [mensagem, setMensagem] = useState('')

    const [grupos, setGrupos] = useState([])
    const [associados, setAssociados] = useState([])
    const [usuarios, setUsuarios] = useState([])

    const [gruposSelecionados, setGruposSelecionados] = useState([])
    const [associadosSelecionados, setAssociadosSelecionados] = useState([])
    const [usuariosSelecionados, setUsuariosSelecionados] = useState([])

    const [openDialog, setOpenDialog] = useState(false)
    const [selectedNotificacaoId, setSelectedNotificacaoId] = useState(null);

    const [notificacoes, setNotificacoes] = useState([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchApi = async () => {
            setLoading(true)
            try {
                const responseGrupos = await api_amr.get('/get_groups', { headers: { 'x-api-key': getToken() } });
                if (responseGrupos.status === 200) {
                    setGrupos(responseGrupos.data)
                } else {
                    alert('Erro ao carregar grupos:', responseGrupos.status)
                }
            } catch (error) {
                console.error(error)
            }

            try {
                const responseAssociados = await api_amr.get('/get_associados', { headers: { 'x-api-key': getToken() } })
                if (responseAssociados.status === 200) {
                    setAssociados(responseAssociados.data)
                } else {
                    console.error('Erro ao carregar associados:', responseAssociados.status)
                }
            } catch (error) {
                console.error(error)
            }

            try {
                const responseUsuarios = await api_amr.get('/get_user_info', { params: { 'all': 1 }, headers: { 'x-api-key': getToken() } })
                if (responseUsuarios.status === 200) {
                    setUsuarios(responseUsuarios.data)
                } else {
                    console.error('Erro ao carregar usuarios:', responseUsuarios.status)
                }
            } catch (error) {
                console.error(error)
            }

            try {
                const responseNotificacoes = await api_amr.get('/retornar_todas_notificacoes', { headers: { 'x-api-key': getToken() } })
                if (responseNotificacoes.status === 200) {
                    setNotificacoes(responseNotificacoes.data.notificacoes)
                } else {
                    console.error('Erro ao carregar notificacoes:', responseNotificacoes.status)
                }
            } catch (error) {
                console.error(error)
            }
            setLoading(false)
        }
        fetchApi()
    }, [])

    const handleGroupChange = (event, value) => {
        const ids = value.map(item => item.id)
        setGruposSelecionados(ids)
    }

    const handleAssociateChange = (event, value) => {
        const ids = value.map(item => item.id_associado)
        setAssociadosSelecionados(ids)
    }

    const handleUserChange = (event, value) => {
        const ids = value.map(item => item.id)
        setUsuariosSelecionados(ids)
    }

    const enviarNotificacao = async () => {

        if (!titulo || !mensagem) {
            alert('Título e mensagem são obrigatórios');
            return
        }

        if (gruposSelecionados.length === 0 && associadosSelecionados.length === 0 && usuariosSelecionados.length === 0) {
            alert('Ao menos um grupo, associado ou usuário deve ser selecionado')
            return
        }

        setLoading(true)
        try {
            const response = await api_amr.post('/criar_notificacao',
                {
                    titulo,
                    mensagem,
                    'grupos': gruposSelecionados,
                    'associados': associadosSelecionados,
                    'usuarios': usuariosSelecionados
                },
                { headers: { 'x-api-key': getToken() } })

            if (response.status === 200) {
                console.log('Notificação enviada com sucesso!')
                window.location.reload()
            } else {
                alert('Falha ao enviar notificação. Por favor, tente novamente.')
            }
        } catch {
            alert('Erro ao enviar notificação. Por favor, verifique sua conexão e tente novamente.')
        }
        setLoading(false)
    }

    const removeNotificacao = async () => {
        setLoading(true)
        try {
            const response = await api_amr.post('/remover_notificacao', { 'id_notificacao': selectedNotificacaoId }, { headers: { 'x-api-key': getToken() } })
            if (response.status === 200) {
                console.log('Notificação removida com sucesso!')
                window.location.reload()
            } else {
                alert('Falha ao remover notificação. Por favor, tente novamente.')
            }
        } catch {
            alert('Erro ao remover notificação. Por favor, verifique sua conexão e tente novamente.')
        }
        setLoading(false)
    }

    const handleConfirmDelete = () => {
        removeNotificacao()
        setOpenDialog(false)
    }

    const handleOpenDialog = (id) => {
        setOpenDialog(true)
        setSelectedNotificacaoId(id)
    }

    return (
        <Box sx={{ height: '100%', padding: 20 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} style={{ padding: 10 }}>
                        <Typography variant="h6">
                            Criar e enviar notificações
                        </Typography>

                        <TextField
                            size='small'
                            label="Título"
                            variant='outlined'
                            fullWidth
                            margin="dense"
                            onChange={(e) => setTitulo(e.target.value)}
                        />
                        <TextField
                            size='small'
                            label="Mensagem"
                            multiline
                            minRows={4}
                            variant='outlined'
                            fullWidth
                            margin="dense"
                            onChange={(e) => setMensagem(e.target.value)}
                        />

                        <Typography variant="subtitle2">
                            Selecione quem vai receber as notificações
                        </Typography>

                        <Autocomplete
                            multiple
                            options={grupos}
                            onChange={handleGroupChange}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.name === value.name || option === ''}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" margin='dense' label="Grupos" placeholder="Grupos" />
                            )}
                        />

                        <Autocomplete
                            multiple
                            options={associados}
                            onChange={handleAssociateChange}
                            getOptionLabel={(option) => option.name_associado}
                            getOptionSelected={(option, value) => option.name_associado === value.name_associado || option === ''}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" margin='dense' label="Associados" placeholder="Associados" />
                            )}
                        />

                        <Autocomplete
                            multiple
                            options={usuarios}
                            onChange={handleUserChange}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.name === value.name || option === ''}

                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" margin='dense' label="Usuários" placeholder="Usuários" />
                            )}
                        />

                        <Button variant="contained" color="primary" onClick={enviarNotificacao} style={{ marginTop: 10 }}>
                            Enviar
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={8} style={{ display: 'flex' }} >
                    <Paper elevation={3} style={{ padding: 10, flex: 1 }}>
                        <Typography variant="h6" gutterBottom>
                            Notificações enviadas
                        </Typography>

                        {notificacoes.length === 0 ?
                            <Box>
                                <Typography variant='subtitle2'>Nenhuma notificação foi enviada ainda</Typography>
                            </Box>
                            : null}

                        {notificacoes.map((notificacao, index) =>
                            <Paper key={index} style={{ padding: 10, position: 'relative', margin: 10 }}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    {new Date(notificacao.criado_em).toLocaleString('pt-br')}
                                </Typography>
                                <Typography>
                                    <strong>{notificacao.titulo}</strong>
                                </Typography>
                                <Typography variant="body2">
                                    {notificacao.mensagem}
                                </Typography>

                                <Tooltip title="Deletar Notificação">
                                    <Box
                                        onClick={() => handleOpenDialog(notificacao.id)}
                                        style={{
                                            width: 30, height: 30, backgroundColor: 'red',
                                            position: 'absolute', right: 0, top: 0,
                                            alignItems: 'center', justifyContent: 'center', display: 'flex', cursor: 'pointer'
                                        }}>
                                        <CloseIcon style={{ color: 'white' }} />
                                    </Box>
                                </Tooltip>
                            </Paper>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            <Backdrop
                style={{ color: '#fff', zIndex: 100 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>{"Confirmar Ação"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tem certeza de que deseja deletar esta notificação?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}