import React, { useState, useEffect } from "react";
import navio_login from 'assets/img/navio_login.png';
import logo_amr from 'assets/img/logo_amr.png'
import "@fontsource/poppins";

import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';


import { makeStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../flux/actions/tokenIndex";
import { setUser } from "../flux/actions/userIndex";
import { getCachedLink } from "utils/utils";

import api_amr from "../services/api_amr";

import Modal from '@material-ui/core/Modal';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';


function getToken() {
  const token = localStorage.getItem('token')
  if (token === null) {
    return ''
  }
  else {
    return token
  }

}
function getUser() {
  const user = localStorage.getItem('usuario')
  if (user === null) {
    return ''
  }
  else {
    return user
  }
}

function SignInSide(props) {
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  const [logando, setLogando] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const classes = useStyles();
  const [verificando, setVerificando] = useState(false)
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [token, setToken] = useState('');
  const [responseInfo, setResponseInfo] = useState('');

  useEffect(() => {
    if (getUser() !== '') {
      if (getCachedLink() !== '') {
        window.location.replace(getCachedLink())
      } else {
        props.history.push({ pathname: "/Noticias" })
      }
    }
  }, [])


  async function validateLogin() {
    setLogando(true)
    const MAX_ATTEMPTS = 3

    for (let index = 0; index < MAX_ATTEMPTS; index++) {
      try {
        const response = await api_amr.post('/login',
          { 'email': login, 'password': pass },
          { timeout: 10000 })

        if (response.data === 'NOK1') {
          alert('Usuário ou senha inválidos')
          break  // if login failed, no need to retry, so break the loop
        } else if (response.data === 'NOK') {
          alert('Erro ao realizar login')
          break  // if login failed, no need to retry, so break the loop
        } else {
          let token = response.data.token
          setToken(response.data.token)
          localStorage.setItem('token', token)

          const response_permissions = await api_amr.get('/get_user_permissions', { headers: { 'x-api-key': token } })
          localStorage.setItem('permissoes', JSON.stringify(response_permissions.data[0]))

          const response_info = await api_amr.get('/get_user_info', { params: { 'all': 0 }, headers: { 'x-api-key': token } })
          setResponseInfo(response_info)
          // Verifica se os termos foram aceitos
          if (!response_info.data[0].terms_accepted) {
            setTermsModalOpen(true); // Abre o modal com os termos e condições
          } else {
            // Se os termos foram aceitos, continue o processo usual
            localStorage.setItem('usuario', JSON.stringify(response_info.data[0]))
            window.location.reload()
          }

          break // if everything is successful, break the loop
        }
      } catch (error) {
        if (index === MAX_ATTEMPTS - 1 && error.code === 'ECONNABORTED') {
          // If this was the last attempt or connection was aborted, navigate to the maintenance page
          props.history.push({ pathname: "/pages/Manutencao" })
        } else {
          // If it was not the last attempt, wait for 2 seconds before retrying
          await new Promise(resolve => setTimeout(resolve, 2000))
        }
      }
    }

    setLogando(false)
  }

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const recoverPassword = async () => {
    setVerificando(true)
    const response = await api_amr.post('/recupera_senha', { 'email': email });
    if (response.data === 'NF') {
      alert('Email não encontrado')
    }
    else if (response.data === 'OK') {
      alert('Email de recuperação enviado, verifique a sua caixa de mensagens')
    }
    else {
      alert('Ocorreu algum erro, entre em contato com o time de desenvolvimento')
    }
    setVerificando(false)
    closeModal();
  };

  async function handleAcceptTerms() {

    try {
      const response_atualiza_informacoes = await api_amr.post('/update_terms_acceptance', { 'aceito': 'true' }, { headers: { 'x-api-key': token } });

      if (response_atualiza_informacoes.data === 'OK') {
        alert('Dados atualizados');
      } else {
        alert('Erro ao atualizar dados');
      }

      localStorage.setItem('usuario', JSON.stringify(responseInfo.data[0]))
      window.location.reload();

    } catch (error) {
      console.error("There was an error updating the data:", error);
      alert('Erro ao atualizar dados');
    }
  }

  function handleDeclineTerms() {
    // Se o usuário recusar, você pode deslogá-lo ou redirecionar para outra página
    localStorage.clear();
    window.location.reload();
  }

  return (
    <div className={classes.container}>
      <div className={classes.imgDiv}>
        <img src={navio_login} alt="login" style={{ width: '100%' }} />
      </div>
      <div className={classes.loginDiv}>

        <img src={logo_amr} alt="login" style={{ width: '35%', height: '7%' }} />
        <p style={{ fontFamily: 'Poppins', fontSize: '14px', color: '#383838' }}> Entre com seus dados para acessar a plataforma.</p>
        <div style={{ marginTop: 20, width: '352px' }}>
          <p style={{ fontFamily: 'Poppins', fontSize: '10px' }}> Login</p>
          <TextField
            onChange={(event) => setLogin(event.target.value)}
            placeholder='login@gmail.com'
            className={classes.textField}
            InputProps={{
              classes: { input: classes.input },
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon style={{ color: 'gray', marginLeft: 5 }} />
                </InputAdornment>
              ),
            }}>

          </TextField>

        </div>
        <div style={{ marginTop: 20, width: '352px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '352px' }}>
            <p style={{ fontFamily: 'Poppins', fontSize: '10px' }}> Senha</p>
            {/* <Button autoCapitalize="false" style={{ textTransform: 'none', fontFamily: 'Poppins', fontSize: '10px', marginRight: 0, marginLeft: 'auto', color: '#A3CC51' }}> Esqueceu a senha?</Button> */}
          </div>
          <TextField
            onChange={(event) => setPass(event.target.value)}
            placeholder='*********'
            className={classes.textField}
            type="password"
            InputProps={{
              classes: { input: classes.input },
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon style={{ color: 'gray', marginLeft: 5 }} />
                </InputAdornment>
              ),
            }}>

          </TextField>

        </div>
        <div style={{ marginTop: 40, width: '352px', height: 45 }}>
          <Button onClick={() => validateLogin()} variant="contained" style={{ fontFamily: 'Poppins', height: 40, fontSize: '10px', background: '#A3CC51', width: '352px', color: 'white', borderRadius: 6 }}> Entrar</Button>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
          <p style={{ fontFamily: 'Poppins', fontSize: '10px', color: '#383838' }}> Esqueceu sua senha?&ensp;</p>
          <Button style={{ fontFamily: 'Poppins', fontSize: '10px', color: '#A3CC51', textTransform: 'none' }} onClick={openModal}>Clique aqui!</Button>

          {/* <p style={{ fontFamily: 'Poppins', fontSize: '10px', color: '#383838' }}> Ainda não possui uma conta?&ensp;</p>
          <Button onClick={() => window.location.replace('http://localhost:3000/cadastro')} style={{ fontFamily: 'Poppins', fontSize: '10px', color: '#A3CC51', textTransform: 'none' }}>Cadastre-se!</Button> */}
        </div>
      </div>
      <Modal open={isModalOpen} onClose={closeModal} className={classes.dialog}>
        <div style={{ background: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 20 }}>

          <img src={logo_amr} alt="login" style={{ width: '35%', margin: 50 }} />
          <TextField
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email cadastrado"
            className={classes.textField}

          />
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 50, justifyContent: 'center' }}>
            <Button onClick={recoverPassword} disabled={verificando} className={classes.recoverButton}>Recuperar senha</Button>

            <Button onClick={closeModal} className={classes.recoverButton}>Voltar</Button>
          </div>

        </div>
      </Modal>
      <Dialog
        open={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ margin: 10 }} >
          <DialogContentText className={classes.termsText} id="alert-dialog-description">
            <header>
              <h2>TERMOS DE USO</h2>
              <h3>AMR BI</h3>
            </header>

            <section>
              <p>Olá! Que bom contar com seu interesse!</p>
              <p>Antes de usar nossos serviços, tire um tempo para ler nossos Termos de Uso e Serviços e conhecer as regras que regem nossa relação com você.</p>
              <p>Abaixo, esclareceremos alguns pontos que julgamos importantes. Caso persista alguma dúvida acerca de quaisquer spontos discutidos ou não neste documento, por favor, não hesite em contatar-nos pelo endereço
                <a href="mailto:marcio.coltro@amrbi.com.br"> marcio.coltro@amrbi.com.br</a>.</p>
            </section>
            <section>
              <h4>1. DEFINIÇÕES:</h4>
              <p>No presente instrumento, entendemos as expressões abaixo de acordo com as seguintes definições:</p>
              <dl>
                <dt>EMPRESA:</dt>
                <dd>AMR FERCONSULT LTDA., empresa individual de responsabilidade limitada, com seus atos constitutivos arquivados na Junta Comercial do Estado do Goiás, inscrita no Cadastro Nacional de Pessoas Jurídicas sob o nº 32.744.573/0001-16 e com sede à Fazenda Piracanjuba, s/n, sala 01, Zona Rural, Bela Vista de Goiás/GO, CEP: 75.240-000.
                </dd>
                <br></br>
                <dt>PLATAFORMA:</dt>
                <dd>USUÁRIOS:</dd>
                <dd>
                  a) USUÁRIO TITULAR: Pessoa Jurídica de Direito Privado ou Pessoa Física absolutamente capaz nos termos da legislação brasileira vigente que procederá à contratação da PLATAFORMA. Caberá a ele fazer todo gerenciamento do sistema, designar outros usuários para operarem a PLATAFORMA, atribuindo acessos específicos para tanto, bem como alimentar a PLATAFORMA com dados dos usuários respondentes ee sendo responsável por manter, todas as informações atualizadas e respondendo legalmente pelo cliente.
                </dd>
                <dd>
                  b) USUÁRIO SUBSIDIÁRIO : Pessoa designada pelo USUÁRIO TITULAR para utilizar a PLATAFORMA de maneira específica, de acordo com a permissão do USUÁRIO TITULAR.
                </dd>
              </dl>
            </section>

            <section>
              <h4>2. ADESÃO:</h4>

              <p>
                Este instrumento regula as condições de uso da PLATAFORMA, sendo um contrato entre os USUÁRIOS e a EMPRESA celebrado quando do uso da PLATAFORMA.
              </p>
              <p>
                A utilização dos serviços oferecidos através da PLATAFORMA indica expressamente que você concorda com todos os termos e condições contidos neste instrumento e com as disposições legais aplicáveis à espécie.
              </p>
              <p>
                VOCÊ ENTENDE E CONCORDA QUE A EMPRESA CONSIDERARÁ O USO DA PLATAFORMA COMO ACEITAÇÃO DESTES TERMOS E TODAS AS
                DEMAIS DISPOSIÇÕES LEGAIS PERTINENTES À ESPÉCIE.
              </p>
              <p>
                AO ACEITAR OS TERMOS DO PRESENTE INSTRUMENTO, O USUÁRIO AUTORIZA EXPRESSAMENTE O TRATAMENTO DE SEUS DADOS,
                A FIM DE GARANTIR A MANUTENÇÃO E O BOM DESEMPENHO DAS FUNCIONALIDADES DA PLATAFORMA.
              </p>
              <p>
                O USUÁRIO, NESTE ATO, MANIFESTA O SEU COMPLETO CONSENTIMENTO PARA O COMPARTILHAMENTO DOS DADOS COLETADOS E
                TRATADOS PELA EMPRESA, NOS TERMOS DESTE INSTRUMENTO, COM OUTRAS EMPRESAS QUE FAÇAM PARTE DE SEU GRUPO ECONÔMICO, OU SEJAM SUAS PRESTADORAS DE SERVIÇO.
              </p>
              <p>
                Caso você NÃO CONCORDE com as disposições previstas neste instrumento, NÃO acesse, visualize, baixe ou utilize
                de qualquer forma nenhuma página, conteúdo, informação ou serviço da EMPRESA.
              </p>
              <p>
                Os presentes termos estão disponíveis para leitura, a qualquer momento, na PLATAFORMA,
                em https://plataformaamrbi.com/.
              </p>
            </section>

            <section>
              <h4> 3. QUEM SOMOS E O QUE FAZEMOS:</h4>

              <p> A EMPRESA é uma Plataforma de Business Intelligence que oferece assessoria exclusiva para produtores, cooperativas e empresas agrícolas em relação ao mercado de fertilizantes sobre tendências dos setores, variáveis do mercado e orientação na tomada de decisões mais assertivas relacionadas ao agronegócio.</p>

            </section>

            <section>
              <h4> 4. CONDIÇÕES GERAIS DE USO:</h4>

              <p>Para o uso da plataforma da AMR BI, os USUÁRIOS realizarão o seu cadastro na PLATAFORMA, ato no qual os USUÁRIOS fornecerão a AMR BI os seus Dados Pessoais, nome de usuário (“login”) e senha que serão utilizados para acesso aos conteúdos e funcionalidades da PLATAFORMA.</p>

              <p>  No momento do cadastro, os USUÁRIOS poderão utilizar todos os serviços disponibilizados na PLATAFORMA, declarando, para tanto, terem lido, compreendido e aceitado todos os dispositivos contidos neste Termos de Uso.</p>

              <p> O USUÁRIO se compromete a utilizar das funcionalidades da PLATAFORMA com boa-fé, de acordo com a legislação em vigor, a moral e os bons costumes.</p>

              <p>  A EMPRESA apenas disponibiliza a PLATAFORMA,  que oferece os serviços de assessoria exclusiva para produtores, cooperativas e empresas agrícolas no que tange o mercado de fertilizantes, sendo a sua responsabilidade restrita apenas ao funcionamento correto da PLATAFORMA e de suas funcionalidades conforme este instrumento.</p>

              <p>    Os USUÁRIOS declaram-se cientes, neste ato, de que todas as informações incluídas na PLATAFORMA pelos USUÁRIOS, são de responsabilidade única e exclusiva destes, sendo que a EMPRESA não possui gerência acerca da assertividade deste conteúdo, não podendo, ser responsabilizada no caso de inclusão de informações e dados incorretos.</p>

              <p>  O USUÁRIO TITULAR reconhece expressamente que, através deste instrumento, recebe da EMPRESA a outorga de uma licença de uso da PLATAFORMA, esta intransferível, sendo vedado o sublicenciamento, para uso em território nacional ou estrangeiro, pelo tempo em que perdurar a adesão a este termo, restando vedado o uso da PLATAFORMA em desacordo com o previsto neste instrumento.</p>

              <p>  O USUÁRIO é o único responsável pela segurança de sua senha e pelo uso de seu cadastro na PLATAFORMA, por isto, recomendamos que não compartilhem com terceiros tais informações e, caso estas informações sejam, por qualquer motivo, extraviadas ou hackeadas, o USUÁRIO deverá informar imediatamente à EMPRESA, através do marcio.coltro@amrbi.com.br, a fim de que se possa solucionar a questão.</p>

              <p>  Cabe somente aos USUÁRIOS responderem por quaisquer danos causados a terceiros, a outros USUÁRIOS, à PLATAFORMA ou à própria EMPRESA, decorrentes do uso das funcionalidades da PLATAFORMA.</p>

              <p> Os USUÁRIOS não devem utilizar a PLATAFORMA para quaisquer fins ou meios ilegais, difamatórios, discriminatórios, abusivos, ofensivos, pornográficos, obscenos, agressivos, injuriosos, vexatórios, enganosos, caluniosos, violentos, vulgares, ou de assédio, ameaça ou uso de falsa identidade, ou seja, qualquer uso escuso que possa prejudicar a EMPRESA, outros USUÁRIOS ou terceiros.</p>

              <p> Em nenhuma hipótese a EMPRESA será responsabilizada por quaisquer danos suportados pelos USUÁRIOS por eventual indisponibilidade temporária da PLATAFORMA.</p>

              <p> O USUÁRIO deve possuir todos os softwares e hardwares necessários para acessar à PLATAFORMA, incluindo, mas não se limitando, a computador e dispositivo móvel com acesso à Internet, cabendo à EMPRESA, tão somente, disponibilizar a PLATAFORMA ao USUÁRIO, nos termos deste instrumento.</p>

              <p>  O uso da PLATAFORMA pelos USUÁRIOS fica condicionado ao seu cadastro prévio, bem como ao respeito às disposições constantes neste instrumento.</p>

              <p> A EMPRESA se compromete a manter em total e absoluto sigilo todos os dados e informações, acerca do negócio do USUÁRIO, tais como medições relacionadas as fazendas, valores, número de hectares, tipo de plantio, transações, inseridos na PLATAFORMA para fins de cálculos e devido cumprimento das funcionalidades da PLATAFORMA.</p>

              <p> A EMPRESA ressalta que as notícias compartilhadas na PLATAFORMA possuem o único e exclusivo intuito de informar os USUÁRIOS acerca de assuntos relevantes e que possam ser do seu interesse sobre o agronegócio mundial, não refletindo estas a opinião da EMPRESA ou seus responsáveis legais.</p>


            </section>

            <section>
              <h4>5. CADASTRO:</h4>


              <p>  Os serviços oferecidos pela EMPRESA estão disponíveis para pessoas físicas absolutamente capazes e pessoas jurídicas devidamente ativas perante à Receita Federal.</p>

              <p>   Para a utilização regular da PLATAFORMA, o USUÁRIO deverá efetuar cadastro, preenchendo todos os dados solicitados pela PLATAFORMA no momento do cadastramento.</p>

              <p>  Para que os USUÁRIOS realizem o seu cadastro na PLATAFORMA, deverão fornecer à EMPRESA os seguintes dados: (i) Nome completo ou razão social; (ii) Número do Cadastro de Pessoa Física (CPF) ou Cadastro Nacional de Pessoas Jurídicas (CNPJ); (iii) Endereço; (iv) Endereço eletrônico/E-mail; (v) Telefone; (vi) Data de nascimento.</p>

              <p> É de exclusiva responsabilidade dos USUÁRIOS fornecer, atualizar e garantir a veracidade dos dados cadastrais, não recaindo à EMPRESA qualquer tipo de responsabilidade civil e criminal resultante de dados inverídicos, incorretos ou incompletos fornecidos pelos USUÁRIOS.</p>

              <p> A EMPRESA se reserva o direito de utilizar todos os meios válidos e possíveis para identificar seus USUÁRIOS, bem como de solicitar dados adicionais e documentos que estime serem pertinentes a fim de conferir os dados informados. Neste caso, o uso da PLATAFORMA pelo USUÁRIO fica condicionado ao envio dos documentos eventualmente solicitados.</p>

              <p> Caso um cadastro seja considerado suspeito de conter dados errôneos ou inverídicos, a EMPRESA se reserva ao direito de suspender, temporária ou definitivamente, sem necessidade de aviso prévio, o USUÁRIO responsável pelo cadastro. No caso de suspensão não assistirá ao USUÁRIO direito a qualquer tipo de indenização ou ressarcimento por perdas e danos, lucros cessantes ou ainda danos morais.</p>

              <p>O USUÁRIO poderá ter acesso às informações coletadas e sobre o tratamento de dados realizado pela EMPRESA, de forma gratuita, através de solicitação para marcio.coltro@amrbi.com.br, ou através de seu cadastro na PLATAFORMA, podendo editar ou excluir as referidas informações a qualquer tempo.</p>

              <p>A coleta dos dados do USUÁRIO visa identificá-lo, bem como habilitá-lo ao correto uso da PLATAFORMA.</p>

              <p>Ao consentir com os termos do presente instrumento, o USUÁRIO declara expressamente estar ciente que a coleta dos seus dados é primordial para o bom funcionamento da PLATAFORMA, autorizando, desde já, o tratamento de dados pela EMPRESA.</p>

              <p>Poderá um USUÁRIO TITULAR solicitar mais de um perfil de  cadastro na PLATAFORMA, podendo ainda esses USUÁRIOS adicionais possuírem níveis de acesso diferentes do USUÁRIO TITULAR, sendo este, nesse caso o perfil do USUÁRIO SUBSIDIÁRIO.</p>

              <p> A PLATAFORMA oferece a possibilidade de o USUÁRIO utilizar de algumas de suas funcionalidades sem a necessidade de cadastro, entretanto, a ausência de cadastro não descaracteriza o USUÁRIO, nem permite o desrespeito a este instrumento.</p>

              <p> O USUÁRIO acessará o seu cadastro na PLATAFORMA por meio de login e senha, comprometendo-se a não informar a terceiros estes dados, responsabilizando-se integralmente pelo uso que deles seja feito. O USUÁRIO será o único responsável pelas operações efetuadas em sua conta, uma vez que o acesso só será possível mediante a utilização de senha de seu exclusivo conhecimento.</p>

              <p> O USUÁRIO compromete-se a notificar a EMPRESA imediatamente, por meio dos canais de contato mantidos pela EMPRESA na PLATAFORMA, a respeito de qualquer uso não autorizado de sua conta.</p>

              <p> O USUÁRIO compromete-se a notificar a EMPRESA imediatamente, por meio dos canais de contato mantidos pela EMPRESA na PLATAFORMA, a respeito de qualquer conhecimento de irregularidades de outros USUÁRIOS que possam ocasionar danos aos próprios USUÁRIOS da PLATAFORMA, a esta, a EMPRESA ou a terceiros.</p>

              <p> Em nenhuma hipótese será permitida a cessão, a venda, o aluguel ou outra forma de transferência do cadastro do USUÁRIO.</p>

              <p>  Ao seu exclusivo critério a EMPRESA poderá excluir, inabilitar, criar limites no uso do serviço, suspender, bloquear, por tempo indeterminado, sem aviso prévio ou contrapartida indenizatória, cadastros de USUÁRIOS que sejam considerados ofensivos, que infrinjam os termos deste instrumento ou a legislação em vigor.</p>

              <p> A EMPRESA se reserva ao direito de não permitir novo cadastro de USUÁRIOS que já tenham sido cancelados, inabilitados, bloqueados, excluídos ou suspensos da PLATAFORMA. Não se permitirá, ainda, a criação de novos cadastros por pessoas cujos cadastros originais tenham sido cancelados, bloqueados, inabilitados, excluídos ou suspensos por infrações às políticas da EMPRESA ou à legislação vigente.</p>

              <p>A EMPRESA se reserva o direito de, unilateralmente, sem prévio aviso, anuência ou contrapartida indenizatória, recusar qualquer solicitação de cadastro de um USUÁRIO na PLATAFORMA, bem como cancelar, inabilitar, bloquear, excluir ou suspender o uso de um cadastro previamente aceito.</p>

              <p> Ao concordar com o presente instrumento, o USUÁRIO declara estar ciente de que é o único responsável pelo seu cadastro, sendo certo que qualquer prejuízo causado pela inserção de informações desatualizadas, inexatas ou inverídicas, não poderão ser imputados à EMPRESA ou à PLATAFORMA.</p>

            </section>

            <section>
              <h4>6. DAS FUNCIONALIDADES:</h4>
              <p>
                Aos CLIENTES a PLATAFORMA oferece as seguintes funcionalidades: Acesso a notícias selecionadas, com fontes nos principais mercados globais, capacidade de informar sobre as tendências do setor em tempo real. A análise e estratégias ajudam os USUÁRIOS na tomada de decisões para melhorar as margens e a sua lucratividade.</p>
              <p>
                Análise de nutrientes por meio de dados inseridos na plataforma para diferentes composições de fertilizantes para cada lavoura.
              </p>
              <p>
                Informe semanais com destaques, cotações e valores das bolsas internacionais.
              </p>
              <p>
                A EMPRESA poderá a qualquer tempo, sem necessidade de aviso prévio, ou contrapartida indenizatória, editar e/ou excluir as funcionalidades existentes, bem como incluir novas funcionalidades à PLATAFORMA.
              </p>
            </section>

            <section>
              <h4>7. DAS OBRIGAÇÕES DOS USUÁRIOS:</h4>

              <p> Sempre que ocorrerem quaisquer problemas com a PLATAFORMA, prover toda a documentação e informações que relatem as circunstâncias em que os problemas ocorreram, objetivando facilitar e agilizar os trabalhos, assim como quaisquer detalhes solicitados pela EMPRESA.</p>

              <p> Os USUÁRIOS indenizarão e isentarão a EMPRESA, seus executivos, agentes e empregados de todas as responsabilidades, danos e custos (incluindo conciliação e honorários advocatícios razoáveis) que surgirem de uma reivindicação de terceiros relacionada ao uso da PLATAFORMA ou infração destes Termos.</p>

              <p> Todo conteúdo analítico disponibilizado na Plataforma é de propriedade da AMR, e o Usuário se compromete a não publicar, republicar ou compartilhar sem o consentimento da EMPRESA com terceiros.</p>

            </section>

            <section>
              <h4> 8. DAS OBRIGAÇÕES DA EMPRESA:</h4>

              <p>  A EMPRESA garante que a PLATAFORMA deverá funcionar regularmente, se respeitadas as condições de uso definidas neste objeto, fornecendo acesso a PLATAFORMA pelo prazo estabelecido em contrato.</p>

              <p> A EMPRESA envida todos os seus esforços para manter a PLATAFORMA disponível aos USUÁRIOS, de forma contínua e permanente, ficando sujeita, todavia, a eventos extraordinários, como desastres naturais, falhas ou colapsos nos sistemas centrais de comunicação e acesso à internet ou fatos de terceiros e falhas técnicas que fogem da sua esfera de vigilância e responsabilidade.</p>
            </section>

            <section>
              <h4>  9. DISPOSIÇÕES GERAIS:</h4>

              <p>  Qualquer cláusula ou condição deste instrumento que, por qualquer razão, venha a ser reputada nula ou ineficaz por qualquer juízo ou tribunal, não afetará a validade das demais disposições destes Termos, as quais permanecerão plenamente válidas e vinculantes, gerando efeitos em sua máxima extensão.</p>

              <p>   A falha da EMPRESA em exigir quaisquer direitos ou disposições dos presentes Termos não constituirá renúncia, podendo esta exercer regularmente o seu direito, dentro dos prazos legais.</p>

              <p>  Todos os materiais, patentes, marcas, registros, domínios, nomes, privilégios, criações, imagens e todos os direitos conexos e relacionados com a PLATAFORMA e desenvolvidos pela EMPRESA, são e permanecerão de única e exclusiva propriedade da EMPRESA, concordando os USUÁRIOS em não praticar ato ou fato que, por qualquer modo, prejudique os direitos previstos aqui e tampouco reivindicar qualquer direito ou privilégio sobre os mesmos.</p>

              <p> A EMPRESA poderá alterar este instrumento a qualquer momento, bastando, para tanto, publicarmos uma versão revisada em nosso site. Por este motivo, recomendamos veementemente que sempre visite esta seção de nosso site, lendo-a, periodicamente. Mas, para contribuir com o bom relacionamento, também enviaremos um e-mail informando acerca dessas mudanças.</p>

              <p>  O presente instrumento constitui o entendimento integral entre o USUÁRIO e a EMPRESA e é regido pelas Leis Brasileiras, ficando eleito o foro da cidade de domicílio do USUÁRIO como único competente para dirimir questões decorrentes do presente instrumento, com renúncia expressa a qualquer outro foro, por mais privilegiado que seja.</p>

              ***
            </section>



          </DialogContentText>
        </DialogContent >
        <DialogActions style={{ display: 'flex', justifyContent: 'center', }}>
          <div className={classes.termsButtom} style={{ display: 'flex', justifyContent: 'center', }}>
            <Button onClick={handleDeclineTerms} className={classes.recoverButtonCancel} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleAcceptTerms} className={classes.recoverButton} color="primary" autoFocus>
              Aceitar
            </Button>
          </div>
        </DialogActions>

      </Dialog>
    </div >
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row'
  },
  imgDiv: {
    width: '50%',
    height: '100%'
  },
  loginDiv: {
    width: '50%',
    height: '100%',

    alignItems: 'center',
    justifyContent: 'center',

    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    fontFamily: 'Poppins',
    fontSize: '9px',
    width: '352px',
    backgroundColor: 'white',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItens: 'center',
    borderRadius: 6,

    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
  },
  input: {
    fontFamily: 'Poppins',
    fontSize: '9px',
    width: '352px'
  },

  // styles para o modal
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
  },
  textFieldModal: {
    marginTop: theme.spacing(2),
    margin: 0,
    minHeight: '250px',
  },
  recoverButton: {
    marginTop: 5,
    margin: 5,
    fontFamily: 'Poppins',
    height: 30,
    fontSize: '10px',
    background: '#A3CC51',
    width: '90px',
    color: 'white',
    borderRadius: 6,
    '&:hover': {
      background: "rgba(163, 204, 81, 0.7)",
    },
  },
  recoverButtonCancel: {
    marginTop: 5,
    margin: 5,
    fontFamily: 'Poppins',
    height: 30,
    fontSize: '10px',
    width: '90px',
    borderRadius: 6,
  },
  termsButtom: {
  },
  termsText: {
    color: '#303030',
    marginTop: 5,
    margin: 5,
    fontFamily: 'Poppins',
    fontSize: '12px',
    width: '90%',
    borderRadius: 6,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    overflowX: 'hidden'
  },

}));


function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToken: setToken,
      setUser: setUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInSide);
