import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Box, Typography } from "@material-ui/core";
import { getToken, checarPermissao, validateCEP } from '../../utils/utils';
import api_amr from '../../services/api_amr';
import Loader from 'react-loader-spinner';
import { TextField, Button, CircularProgress, InputAdornment, IconButton, InputBase, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MuiPhoneInput from 'material-ui-phone-number';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/Remove';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ConfigFazendas from './ConfigFazendas'
import ConfigFazendasEdicao from './ConfigFazendasEdicao'
import CadastroDemandas from './CadastroDemandas'
import ConfigDemandas from './ConfigDemandas'
import VisualizarRecomendacoes from './VisualizarRecomendacoes'


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function ConfigClientes(props) {
    const [tabIndex, setTabIndex] = useState(0);
    const [grupos, setGrupos] = useState('');
    const [permitido, setPermitido] = useState(false);
    const [nomeCadastro, setNomeCadastro] = useState('');
    const [senha, setSenha] = useState('');
    const [senha2, setSenha2] = useState('');
    const [user, setUser] = useState('');
    const [cpfCnpj, setCpfCnpf] = useState('');
    const [telefone, setTelefoneCadastro] = useState('');
    const [cidade, setCidade] = useState('');
    const [cidadesList, setCidadesList] = useState('');
    const [estado, setEstado] = useState('');
    const [cep, setCep] = useState('');
    const [email, setEmail] = useState('');
    const [grupo, setGrupo] = useState('');
    const [seg, setSeg] = useState('');
    const [end, setEnd] = useState('');
    const [bairroSetor, setBairroSetor] = useState('');
    const [segments, setSegments] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [users, setUsers] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [usersArray, setUsersArray] = useState([]);
    const [farmName, setFarmName] = useState('');
    const [inscricaoEstadual, setInscricaoEstadual] = useState('');
    const [cidadeFazenda, setCidadeFazenda] = useState('');
    const [estadoFazenda, setEstadoFazenda] = useState('');
    const [userFarms, setUserFarms] = useState('');
    const [culturas, setCulturas] = useState('');
    const [cultura, setCultura] = useState([]);
    const [numCulturas, setNumCulturas] = useState(0);
    const [culturasFazenda, setCulturasFazenda] = useState('');
    const [openCol, setOpenCol] = useState([]);
    const [admUserSelected, setAdmUserSelected] = useState(null);
    const [atualizando, setAtualizando] = useState(false);
    const [associados, setAssociados] = useState('');
    const [associado, setAssociado] = useState('');
    const [associadoFilial, setAssociadoFilial] = useState('');
    const [nomeFazenda, setNomeFazenda] = useState('');
    const [car, setCar] = useState('');
    const [carVerificado, setCarVerificado] = useState(false);
    const [vinculos, setVinculos] = useState([]);
    const [gerandoHist, setGerandoHist] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        async function fetchMyAPI() {
            setPermitido(await checarPermissao('configuracoes_clientes', props));
            const response_grupos = await api_amr.get('/get_groups', { headers: { 'x-api-key': getToken() } });
            setGrupos(response_grupos.data);
            const response_associados = await api_amr.get('/get_associados', { headers: { 'x-api-key': getToken() } });
            setAssociados(response_associados.data);
            const response_segments = await api_amr.get('/get_segments', { headers: { 'x-api-key': getToken() } });
            setSegments(response_segments.data);
            const response_users = await api_amr.get('/get_user_info', { params: { 'all': 1 }, headers: { 'x-api-key': getToken() } });
            setUsers(response_users.data);
            setUsersArray(Array.from({ length: response_users.data.length }, i => i = false));
            const response_culturas = await api_amr.get('/return_culturas', { headers: { 'x-api-key': getToken() } });
            setCulturas(response_culturas.data);
            getCidadesList();
            fetchVinculos();
        }
        fetchMyAPI();
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const downloadUsersExcel = (users) => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(users);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        saveAs(blob, 'users.xlsx');
    };

    function s2ab(s) {
        const buffer = new ArrayBuffer(s.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buffer;
    }

    async function cadastraUsuario() {
        if (senha !== senha2) {
            alert('Senhas diferentes');
            return;
        }
        if ((nomeCadastro === '') | (email === '') | (senha === '') | (cpfCnpj === '') | (seg === '') | (grupo === '')) {
            alert('Alguns campos obrigatórios estão incompletos\n' + (nomeCadastro === '' ? 'Nome\n' : '') + (email === '' ? 'Email\n' : '') + (senha === '' ? 'Senha\n' : '') + (cpfCnpj === '' ? 'CPF CNPJ\n' : '') + (seg === '' ? 'Segmentação\n' : '') + (grupo === '' ? 'Grupo\n' : ''));
            return;
        }
        if (!validateCEP(cep)) {
            alert('CEP Inválido');
            return;
        }
        const response_create = await api_amr.post('/create_user',
            {
                'email': email, 'password': senha, 'name': nomeCadastro, 'grupo': grupo, 'seg': seg, 'associado': associado.id_associado, 'associado_filial': associadoFilial,
                'cellphone': telefone, 'endereco': end, 'cep': cep, 'id_cidade': cidade.id, 'cpf_cnpj': cpfCnpj, 'bairro_setor': bairroSetor
            }, { headers: { 'x-api-key': getToken() } }
        );
        if (response_create.data === 'OK') {
            alert('Usuário adicionado com sucesso!');
            window.location.reload();
        }
        if (response_create.data === 'F_NOK') {
            alert('Email já em uso!');
        }
    }

    async function atualizaUsuariobyADM() {
        if ((nomeCadastro === '') && (cpfCnpj === '') && (telefone === admUserSelected.cellphone) && (cep === '') && (cidade === '') && (bairroSetor === '') && (end === '') && (senha === '') && (senha2 === '') && (grupo === '') && (seg === '') && (associado === '') && (associadoFilial === '')) {
            alert("Nenhum dado foi alterado!");
            return;
        }
        if (senha !== senha2) {
            alert('Senhas diferentes');
            return;
        }
        if (!validateCEP(cep) && cep !== '') {
            alert('CEP Inválido');
            return;
        }
        setAtualizando(true);
        const response_atualiza_informacoes = await api_amr.post('/update_user', {
            'id': (admUserSelected !== null ? admUserSelected.id : ''), 'nome': nomeCadastro, cpfCnpj, telefone, cep, 'id_cidade': (cidade !== '' ? cidade.id : ''), 'associado': (associado !== '' ? associado.id_associado : ''), 'associado_filial': associadoFilial, 'bairro_setor': bairroSetor, 'endereco': end, 'password': senha, 'grupo': (grupo !== '' ? grupo.id : ''), 'seg': (seg !== '' ? seg.id : '')
        }, { headers: { 'x-api-key': getToken() } });
        console.log(response_atualiza_informacoes.data);
        if (response_atualiza_informacoes.data === 'OK1') {
            alert('Dados atualizados');
            window.location.reload();
        } else {
            alert('Erro ao atualizar dados');
        }
        setAtualizando(false);
    }

    async function getFazendas(index = selectedUser) {
        const response_fazendas = await api_amr.get('/return_farms', { params: { 'id_user': users[index].id, 'all': 0 }, headers: { 'x-api-key': getToken() } });
        setUserFarms(response_fazendas.data);
        setOpenCol(Array.from({ length: response_fazendas.data.length }, i => i = false));
    }

    async function getCidadesList() {
        const response_cidades_list = await api_amr.get('/return_cidades_brasil', { headers: { 'x-api-key': getToken() } });
        setCidadesList(response_cidades_list.data);
    }

    async function getCulturas(index) {
        const response_culturas = await api_amr.get('/return_culturas_cadastradas', { params: { 'id_farm': index }, headers: { 'x-api-key': getToken() } });
        setCulturasFazenda(response_culturas.data);
    }

    async function alterSelectedFarm(index, id_farm) {
        let aux = Array.from({ length: openCol.length }, i => i = false);
        aux[index] = true;
        setOpenCol(JSON.parse(JSON.stringify(aux)));
        getCulturas(id_farm);
    }

    async function alterSelected(index) {
        let aux = Array.from({ length: usersArray.length }, i => i = false);
        aux[index] = true;
        setUsersArray(JSON.parse(JSON.stringify(aux)));
        setSelectedUser(index);
        getFazendas(index);
    }

    async function createFarm() {
        if ((farmName === '') || (inscricaoEstadual === '') || (cidadeFazenda === '')) {
            alert('Alguns campos obrigatórios estão incompletos\n' + (farmName === '' ? 'Nome\n' : '') + (inscricaoEstadual === '' ? '(Insc. Estadual)\n' : '') + (cidadeFazenda === '' ? 'Cidade\n' : ''));
            return;
        }
        const response_fazendas = await api_amr.post('/create_farm', {
            'id_user': users[selectedUser].id, 'farm_name': farmName, 'inscr_estadual': inscricaoEstadual, 'id_cidade': cidadeFazenda.id, 'cultura': cultura
        }, { headers: { 'x-api-key': getToken() } });
        if (response_fazendas.data === 'OK') {
            alert('Fazenda adicionada com sucesso');
            setFarmName('');
            setInscricaoEstadual('');
            setCidadeFazenda(null);
            setNumCulturas(0);
            setCultura([]);
            getFazendas();
            return;
        }
        if (response_fazendas.data === 'F_NOK') {
            alert('Inscrição Estadual já em uso');
        } else {
            alert('Erro ao adicionar fazenda');
        }
    }

    function arraysCulturas(index, value, tipo) {
        let aux = cultura;
        aux[index][tipo] = value;
        setCultura(aux);
        console.log(aux);
    }

    function updateNumCulturas(inc_dec) {
        if (inc_dec === 1) {
            setNumCulturas(numCulturas + 1);
            let aux = cultura;
            aux[numCulturas] = { cultura: '', hectares: 0, n: 0, p2o5: 0, k2o: 0 };
            setCultura(aux);
        } else if (inc_dec === -1) {
            setNumCulturas(numCulturas - 1);
            let aux = cultura;
            aux.pop();
            setCultura(aux);
        }
    }

    const handleCar = async (value) => {
        setCar(value);
        const response = await api_amr.get('/verifica_car', { params: { 'car': value }, headers: { 'x-api-key': getToken() } });
        console.log('verificação CAR', response.data);
        if (response.status === 200) {
            setCarVerificado(true);
        }
    }

    const handleVinculo = async () => {
        setGerandoHist(true);
        if (nomeFazenda === '') {
            alert("É obrigatório adicionar o nome da fazenda");
        }
        if (!carVerificado) {
            alert("É obrigatório inserir um CAR válido");
        }
        const response = await api_amr.post('/vincula_car_user', { 'user_id': admUserSelected.id, 'nome_fazenda': nomeFazenda, 'car': car }, { headers: { 'x-api-key': getToken() } });
        if (response.status === 200) {
            setCarVerificado(false);
            setNomeFazenda('');
            setCar('');
            alert('Vinculado com sucesso');
            setGerandoHist(false);
            fetchVinculos();
        }
        if (response.status === 206) {
            setGerandoHist(false);
            alert(response.data.mensagem);
        }
    }

    const handleClearInput = () => {
        setCarVerificado(false);
        setNomeFazenda('');
        setCar('');
        setVinculos([]);
    }

    const fetchVinculos = async () => {
        const response = await api_amr.get('/retorna_fazendas_vinculo', { headers: { 'x-api-key': getToken() } });
        if (response.status === 200) {
            setVinculos(response.data.vinculos);
        }
    }

    const handleRemoveVinculo = async (car) => {
        const response = await api_amr.post('/remove_fazendas_vinculo', { 'car': car, 'user_id': admUserSelected.id }, { headers: { 'x-api-key': getToken() } });
        if (response.status === 200) {
            alert(response.data.mensagem);
        } else {
            alert('ocorreu algum erro');
        }
        fetchVinculos();
    }

    return (
        permitido ?
            <div className={classes.container}>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="tabs example">
                    <Tab label="Clientes" />
                    <Tab label="Cadastro Fazendas" />
                    <Tab label="Edição Fazendas" />
                    <Tab label="Cadastro Demandas" />
                    <Tab label="Configuração Demandas" />
                    <Tab label="Recomendaçoes" />

                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                    {/* Conteúdo da aba Clientes */}
                    <div>
                        <h2 className={classes.inputh1}>Selecionar Cliente</h2>
                        <div>
                            {users !== '' ?
                                <Autocomplete
                                    options={users}
                                    getOptionLabel={(option) => option.id + '-' + option.name}
                                    className={classes.autoCompleteFieldBig}
                                    onChange={(e, v) => (setAdmUserSelected(v), setTelefoneCadastro(v !== null ? v.cellphone : ''))}
                                    classes={{
                                        input: classes.input,
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder="Selecionar Usuario"
                                        variant='outlined'
                                        value={user}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}
                                    />}
                                />
                                : null}
                        </div>
                        <div>
                            <h2 className={classes.inputh1}>{admUserSelected !== null ? "Atualizar Dados do Cliente" : "Cadastrar Cliente"}</h2>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '80vw' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <TextField
                                    key='nome'
                                    placeholder={admUserSelected !== null ? admUserSelected.name : ''}
                                    variant='outlined'
                                    onChange={(event) => setNomeCadastro(event.target.value)}
                                    value={nomeCadastro}
                                    className={classes.autoCompleteField}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                    label={admUserSelected !== null ? 'Nome' : 'Nome Cliente*'}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <TextField
                                    variant='outlined'
                                    placeholder={admUserSelected !== null ? admUserSelected.cpf_cnpj : ''}
                                    onChange={(event) => (event.target.value, setCpfCnpf(event.target.value))}
                                    value={(cpfCnpj.length >= 11 && cpfCnpj.length !== 13 ? cpfCnpj : cpfCnpj.length === 13 ? '' : cpfCnpj)}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                    label={admUserSelected !== null ? 'CPF/CNPJ' : "CPF/CNPJ*(Apenas Números)"}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </div>
                            <form autoComplete={"off"}>
                                <TextField
                                    key='email'
                                    disabled={admUserSelected !== null ? true : false}
                                    placeholder={admUserSelected !== null ? admUserSelected.email : ''}
                                    variant='outlined'
                                    onChange={(event) => setEmail(event.target.value)}
                                    value={admUserSelected !== null ? admUserSelected.email : email}
                                    className={classes.autoCompleteFieldBig}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                    label="Email*"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </form>
                            <div>
                                <InputBase
                                    key='senha'
                                    variant='outlined'
                                    id="standard-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={senha}
                                    autoComplete='new-password'
                                    placeholder='Senha'
                                    onChange={(event) => setSenha(event.target.value)}
                                    className={classes.autoCompleteField}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                    style={{ height: 50, paddingLeft: 15 }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(true)}
                                                onMouseDown={() => setShowPassword(false)}
                                            >
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <InputBase
                                    variant='outlined'
                                    id="standard-adornment-password2"
                                    type={showPassword ? 'text' : 'password'}
                                    value={senha2}
                                    placeholder='Redigite a senha'
                                    onChange={(event) => setSenha2(event.target.value)}
                                    className={classes.autoCompleteField}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                    style={{ height: 50, paddingLeft: 15, marginLeft: 15 }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(true)}
                                                onMouseDown={() => setShowPassword(false)}
                                            >
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <MuiPhoneInput
                                    defaultCountry={"br"}
                                    key='cellphone'
                                    variant='outlined'
                                    onChange={(e) => (setTelefoneCadastro(e))}
                                    value={telefone}
                                    className={classes.autoCompleteField}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                    label='Telefone'
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <TextField
                                    placeholder={admUserSelected !== null ? admUserSelected.cep : ''}
                                    variant='outlined'
                                    onChange={(event) => (event.target.value, setCep(event.target.value))}
                                    value={cep.length >= 8 && cep.length !== 9 ? cep : cep.length === 9 ? '' : cep}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                    label="CEP"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    placeholder={admUserSelected !== null ? admUserSelected.endereco : ''}
                                    variant='outlined'
                                    onChange={(event) => setEnd(event.target.value)}
                                    value={end}
                                    className={classes.autoCompleteField}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                    label="Endereço"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <TextField
                                    placeholder={admUserSelected !== null ? admUserSelected.bairro_setor : ''}
                                    variant='outlined'
                                    onChange={(event) => setBairroSetor(event.target.value)}
                                    value={bairroSetor}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                    label="Bairro Setor"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </div>
                            <div>
                                <Autocomplete
                                    options={cidadesList}
                                    getOptionLabel={(option) => (option.id + ' - ' + option.cidade + ' - ' + option.UF)}
                                    className={classes.autoCompleteFieldBig}
                                    onChange={(e, v) => setCidade(v)}
                                    classes={{
                                        input: classes.input,
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder={admUserSelected !== null ? admUserSelected.cidade : "Selecionar Cidade"}
                                        variant='outlined'
                                        value={cidade}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}
                                        label={admUserSelected !== null ? 'Cidade' : ''}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {associados !== '' ? <Autocomplete
                                    options={associados}
                                    getOptionLabel={(option) => option.name_associado}
                                    className={classes.autoCompleteField}
                                    onChange={(e, v) => (setAssociado(v))}
                                    classes={{
                                        input: classes.input,
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder={admUserSelected !== null ? admUserSelected.name_associado : 'Associado'}
                                        variant='outlined'
                                        value={associado}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}
                                        label={admUserSelected !== null ? 'Associado' : ''}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />}
                                /> : <CircularProgress />}
                                {associados !== '' ? <TextField
                                    key='associado_filial'
                                    variant='outlined'
                                    onChange={(event) => setAssociadoFilial(event.target.value)}
                                    placeholder={admUserSelected !== null ? admUserSelected.associado_filial : 'Associado'}
                                    value={associadoFilial}
                                    className={classes.autoCompleteField}
                                    style={{ marginLeft: 15 }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                    label="Filial Associado"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                /> : <CircularProgress />}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {grupos !== '' ? <Autocomplete
                                    options={grupos}
                                    getOptionLabel={(option) => option.name}
                                    className={classes.autoCompleteField}
                                    onChange={(e, v) => (setGrupo(v))}
                                    classes={{
                                        input: classes.input,
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder={admUserSelected !== null ? admUserSelected.grupo_nome : 'Grupo'}
                                        variant='outlined'
                                        value={grupo}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}
                                        label={admUserSelected !== null ? 'Grupo' : ''}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />}
                                /> : <CircularProgress />}
                                {segments ? <Autocomplete
                                    options={segments}
                                    getOptionLabel={(option) => option.name}
                                    className={classes.autoCompleteField}
                                    onChange={(e, v) => setSeg(v)}
                                    classes={{
                                        input: classes.input,
                                    }}
                                    style={{ marginLeft: 15 }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder={admUserSelected !== null ? admUserSelected.seg_nome : 'Segmentação'}
                                        variant='outlined'
                                        value={seg}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}
                                        label={admUserSelected !== null ? 'Segmentação' : ''}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />}
                                /> : <CircularProgress />}
                            </div>
                            <div className={classes.calcDiv}>
                                <Button onClick={() => (admUserSelected !== null ? atualizaUsuariobyADM() : cadastraUsuario())} variant="contained" className={classes.calcButton}> {admUserSelected !== null ? "Atualizar Dados do Usuario" : "Cadastrar usuário"}</Button>
                            </div>
                            <div className={classes.calcDiv}>
                                <Button onClick={() => downloadUsersExcel(users)} variant="contained" className={classes.calcButton}>Download Tabela de usuários</Button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '50vh', overflow: 'auto' }}>
                            <b className={classes.inputh1}>Usuários Cadastrados</b>
                            {users !== '' ? <TableContainer className={classes.tableModels}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={50}>
                                            </TableCell>
                                            <TableCell width={100}>
                                                <b className={classes.inputh1}>Nome</b>
                                            </TableCell>
                                            <TableCell width={100}>
                                                <b className={classes.inputh1}>Email</b>
                                            </TableCell>
                                            <TableCell width={50}>
                                                <b className={classes.inputh1}>Grupo</b>
                                            </TableCell>
                                            <TableCell width={50}>
                                                <b className={classes.inputh1}>Segmentação</b>
                                            </TableCell>
                                            <TableCell width={150}>
                                                <b className={classes.inputh1}>Endereço</b>
                                            </TableCell>
                                            <TableCell width={100}>
                                                <b className={classes.inputh1}>Bairro Setor</b>
                                            </TableCell>
                                            <TableCell width={200}>
                                                <b className={classes.inputh1}>Cidade-Estado</b>
                                            </TableCell>
                                            <TableCell width={100}>
                                                <b className={classes.inputh1}>CPF/CNPJ</b>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(users).map((item, index) => (
                                            <TableRow key={index} style={{ backgroundColor: usersArray[index] === true ? '#e9f5d6' : 'white' }} >
                                                <TableCell >
                                                    {usersArray[index] === true ? <IconButton
                                                        style={{ color: '#A3CC51' }}
                                                        aria-label="toggle password visibility"
                                                        onClick={() => alterSelected(index)}
                                                    >
                                                        <CheckCircleOutlineIcon />
                                                    </IconButton> : <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => alterSelected(index)}
                                                    >
                                                        <RadioButtonUncheckedIcon />
                                                    </IconButton>}
                                                </TableCell>
                                                <TableCell  >
                                                    <b className={classes.inputh1}>{users[item].name}</b>
                                                </TableCell>
                                                <TableCell  >
                                                    <b className={classes.inputh1}>{users[item].email}</b>
                                                </TableCell>
                                                <TableCell  >
                                                    <b className={classes.inputh1}>{users[item].grupo}</b>
                                                </TableCell>
                                                <TableCell  >
                                                    <b className={classes.inputh1}>{users[item].segmentacao}</b>
                                                </TableCell>
                                                <TableCell  >
                                                    <b className={classes.inputh1}>{users[item].endereco}</b>
                                                </TableCell>
                                                <TableCell  >
                                                    <b className={classes.inputh1}>{users[item].bairro_setor}</b>
                                                </TableCell>
                                                <TableCell width={200}>
                                                    <b className={classes.inputh1}>{users[item].cidade}</b>
                                                </TableCell>
                                                <TableCell  >
                                                    <b className={classes.inputh1}>{users[item].cpf_cnpj}</b>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> : <CircularProgress />}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>

                    <ConfigFazendas />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <ConfigFazendasEdicao />
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>
                    <CadastroDemandas />
                </TabPanel>
                <TabPanel value={tabIndex} index={4}>
                    <ConfigDemandas />
                </TabPanel>
                <TabPanel value={tabIndex} index={5}>
                    <VisualizarRecomendacoes />
                </TabPanel>
                
            </div> : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    )
}


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 100,
    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    noBorder: {
        border: "none",
    },
    autoCompleteFieldSmall: {
        marginTop: 15,
        minWidth: 104,
        maxWidth: 104,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteField: {
        marginTop: 15,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 15,
        width: 471,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    PhoneInput: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },

    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    inputCheckbox: {

        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000'
    },
    inputh1: {
        fontFamily: 'Poppins',

        color: '#383838'
    },
    root: {
        "&$checked": {
            color: "black",
            backgroundColor: 'white'
        }
    },
    checked: {
        borderColor: 'black'
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
    calcDiv: {
        marginTop: 40,
        width: '146px',
        marginLeft: 325,
        height: 45
    },
    nutriDiv: {
        paddingRight: 28,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 228
    },
    inputForm: {
        width: '50%',
        fontFamily: 'Poppins',
        color: '#383838'
    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    }, table: {
        minWidth: 400,
        maxWidth: '100%'
    },
    rowSize: {
        maxWidth: 500
    },
    tableModels: {
        marginTop: 20,
        maxWidth: '90%',
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
}));

export default ConfigClientes;
