import React, { useEffect, useState } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { TextField, Typography, makeStyles } from "@material-ui/core";
import { getToken } from 'utils/utils';
import api_amr from 'services/api_amr';

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        fontSize: 14,
        width: 150,
        paddingLeft: 10,
        marginTop: 5
    },

    inputRootGrow: {
        fontSize: 14,
        minWidth: 150,
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 10,
        marginTop: 5
    },

    formDiv: {
        display: 'flex',
        flexDirection: 'row'
    },

    inputDiv: {
        maxHeight: 30,
        marginRight: 20,
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid lightgray',
        alignItems: 'center',
        borderRadius: 2
    },

    label: {
        borderRight: '1px solid lightgray',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: 5,
        paddingRight: 5
    },

    typo: {
        fontSize: 14
    },

    alertText: {
        color: 'red',
        fontWeight: 'bold',
        marginTop: theme.spacing(1),
    }

}))

const ComponentParams = ({ selectedComponent, selectedData, onParamSelect }) => {
    const [culturas, setCulturas] = useState([]);
    const [portos, setPortos] = useState([]);
    const [materias, setMaterias] = useState([]);
    const [formulacoes, setFormulacoes] = useState([]);

    const [regiao] = useState([{ 'id': 'america_sul', 'nome': 'América do Sul' }, { 'id': 'america_norte', 'nome': 'América do Norte' }, { 'id': 'asia', 'nome': 'Ásia' }]);
    const [periodo] = useState([{ 'id': 'acumulado', 'nome': 'Acumulado' }, { 'id': '1_7dias', 'nome': '1 a 7 dias' }, { 'id': '8_14dias', 'nome': '8 a 14 dias' }]);
    const [imageOptions] = useState([{ 'id': 'link', 'nome': 'Link' }, { 'id': 'file', 'nome': 'Arquivo' }])
    const [imagemTipo, setImagemTipo] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLinkValid, setIsLinkValid] = useState(true);
    const [materiasMulti, setMateriasMulti] = useState([]);

    const classes = useStyles()

    useEffect(() => {
        fetchApi()
    }, [])

    const fetchApi = async () => {
        const responseCulturas = await api_amr.get('/return_culturas', { headers: { 'x-api-key': getToken() } })
        setCulturas(responseCulturas.data)

        const responsePortos = await api_amr.get('/return_portos', { headers: { 'x-api-key': getToken() } })
        setPortos(responsePortos.data)

        const responseMaterias = await api_amr.get('/get_materias', { headers: { 'x-api-key': getToken() } })
        setMaterias(responseMaterias.data)

        const responseFormulacoes = await api_amr.get('/return_formulacoes_usuario', { headers: { 'x-api-key': getToken() } })
        setFormulacoes(responseFormulacoes.data)
    }

    const formHistoricoPreco1 = () => {
        return (
            <div className={classes.formDiv}>
                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Matéria</Typography>
                    </div>
                    <Autocomplete
                        size='small'
                        key={'historico_preco-id_materia-1'}
                        disableClearable
                        onChange={(e, value) => value ? onParamSelect('id_materia', value.id_materias) : onParamSelect('id_materia', '')}
                        getOptionLabel={(option) => option.abreviacao}
                        options={materias}
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }} />}
                    />
                </div>

                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Porto</Typography>
                    </div>

                    <Autocomplete
                        size='small'
                        key={'historico_preco-porto-2'}
                        disableClearable
                        onChange={(e, value) => value ? onParamSelect('porto', value.id) : onParamSelect('porto', '')}
                        getOptionLabel={(option) => option.nome_porto}
                        options={portos}
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }} />}
                    />
                </div>
            </div>
        )
    }

    const handleMultipleParam = (value) => {

        if (Array.isArray(value) && Array.isArray(materiasMulti)) {

            const newIds = value.map(v => v.id_materias);

            if (newIds.length > materiasMulti.length) {
                const addedItem = newIds.find(id => !materiasMulti.includes(id));
                if (addedItem) {
                    const newMateriasMulti = [...materiasMulti, addedItem];
                    setMateriasMulti(newMateriasMulti);
                    onParamSelect('id_materia', newMateriasMulti);
                }
            } else if (newIds.length < materiasMulti.length) {
                const removedItem = materiasMulti.find(id => !newIds.includes(id));
                if (removedItem !== undefined) {
                    const newMateriasMulti = materiasMulti.filter(id => id !== removedItem);
                    setMateriasMulti(newMateriasMulti);
                    onParamSelect('id_materia', newMateriasMulti);
                }
            }
        } else {
            console.error("Invalid input or state");
        }
    };


    const formHistoricoPrecoGrafico = () => {
        return (
            <div className={classes.formDiv}>
                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Matéria</Typography>
                    </div>
                    <Autocomplete
                        size='small'
                        key={'historico_preco-id_materia-2'}
                        disableClearable
                        multiple={true}
                        onChange={(e, value) => handleMultipleParam(value)}
                        getOptionLabel={(option) => option.abreviacao}
                        options={materias}
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRootGrow,
                            }, disableUnderline: true,
                        }} />}
                    />
                </div>


                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Porto</Typography>
                    </div>

                    <Autocomplete
                        size='small'
                        key={'historico_preco-porto-2'}
                        disableClearable
                        onChange={(e, value) => value ? onParamSelect('porto', value.id) : onParamSelect('porto', '')}
                        getOptionLabel={(option) => option.nome_porto}
                        options={portos}
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }} />}
                    />
                </div>

            </div>
        )
    }

    const formHistoricoPreco2 = () => {
        return (
            <div className={classes.formDiv}>
                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Porto</Typography>
                    </div>
                    <Autocomplete
                        size='small'
                        key={'historico_preco-porto-1'}
                        disableClearable
                        onChange={(e, value) => value ? onParamSelect('porto', value.id) : onParamSelect('porto', '')}
                        getOptionLabel={(option) => option.nome_porto}
                        options={portos}
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }} />}
                    />
                </div>

            </div>
        )
    }

    const formFormulas_salvas = () => {
        return (
            <div className={classes.formDiv}>
            </div>
        )
    }

    const formRelacaoTroca = () => {
        return (
            <div className={classes.formDiv}>
                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Matéria</Typography>
                    </div>
                    <Autocomplete
                        key={'relacao_troca-id_materia'}
                        disableClearable
                        onChange={(e, value) => value ? onParamSelect('id_materia', value.id_materias) : onParamSelect('id_materia', '')}
                        getOptionLabel={(option) => option.abreviacao}
                        options={materias}
                        size='small'
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }} />}
                    />
                </div>

                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Porto</Typography>
                    </div>
                    <Autocomplete
                        size='small'
                        key={'relacao_troca-nome_porto'}
                        disableClearable
                        onChange={(e, value) => value ? onParamSelect('porto', value.id) : onParamSelect('porto', '')}
                        getOptionLabel={(option) => option.nome_porto}
                        options={portos}
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }} />}
                    />
                </div>

                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Cultura</Typography>
                    </div>
                    <Autocomplete
                        size='small'
                        key={'relacao_troca-cultura'}
                        disableClearable
                        onChange={(e, value) => value ? onParamSelect('cultura', value.id) : onParamSelect('cultura', '')}
                        getOptionLabel={(option) => option.cultura}
                        options={culturas}
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }} />}
                    />
                </div>

            </div>
        )
    }
    const formRelacaoTrocaFormulacoes = () => {
        return (
            <div className={classes.formDiv}>
                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Formulacoes</Typography>
                    </div>
                    <Autocomplete
                        key={'relacao_troca_formulacoes-id_formulacao'}
                        disableClearable
                        onChange={(e, value) => value ? onParamSelect('id_formulacao', value.id_formulacao) : onParamSelect('id_formulacao', '')}
                        getOptionLabel={(option) => option.nome_formula}
                        options={formulacoes}
                        size='small'
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }} />}
                    />
                </div>

                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Porto</Typography>
                    </div>
                    <Autocomplete
                        size='small'
                        key={'relacao_troca_formulacoes-nome_porto'}
                        disableClearable
                        onChange={(e, value) => value ? onParamSelect('porto', value.id) : onParamSelect('porto', '')}
                        getOptionLabel={(option) => option.nome_porto}
                        options={portos}
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }} />}
                    />
                </div>

                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Cultura</Typography>
                    </div>
                    <Autocomplete
                        size='small'
                        key={'relacao_troca_formulacoes-cultura'}
                        disableClearable
                        onChange={(e, value) => value ? onParamSelect('cultura', value.id) : onParamSelect('cultura', '')}
                        getOptionLabel={(option) => option.cultura}
                        options={culturas}
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }} />}
                    />
                </div>

            </div>
        )
    }

    const formNote = () => {
        return (
            <div className={classes.formDiv}>
                <TextField
                    onChange={(e) => onParamSelect('text', e.target.value)}
                    size='small'
                    key={'notes-text'}
                    id="feedback"
                    label={"Anotação"}
                    type="text"
                    fullWidth
                    variant="outlined"
                    multiline
                    minRows={4}
                />
            </div>
        )
    }

    const formPrevisao = () => {
        return (
            <div className={classes.formDiv}>
                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Região</Typography>
                    </div>
                    <Autocomplete
                        size='small'
                        key={'regiao-previsao'}
                        disableClearable
                        onChange={(e, value) => value ? onParamSelect('regiao', value.id) : onParamSelect('regiao', '')}
                        getOptionLabel={(option) => option.nome}
                        options={regiao}
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }} />}
                    />
                </div>
                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Periodo</Typography>
                    </div>
                    <Autocomplete
                        size='small'
                        key={'periodo-previsao'}
                        disableClearable
                        onChange={(e, value) => value ? onParamSelect('periodo', value.id) : onParamSelect('periodo', '')}
                        getOptionLabel={(option) => option.nome}
                        options={periodo}
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }} />}
                    />
                </div>
            </div>
        )
    }

    const handleLinkChange = (event) => {
        const value = event.target.value;
        // Basic validation for image extension
        const imageExtensions = ['jpg', 'png', 'jpeg', 'gif'];
        const isValid = imageExtensions.some(ext => value.endsWith(ext));

        if (isValid) {
            setIsLinkValid(true); // Set validation state

            onParamSelect('link', value) // Your existing logic here
        } else {
            setIsLinkValid(false); // Set validation state
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        // Do something with the file (e.g., upload it to the server or preview it)
    };

    const formImage = () => {
        return (
            <div className={classes.formDiv}>

                {/* <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Origem da Imagem</Typography>
                    </div>
                    <Autocomplete
                        size='small'
                        key={'Imagem-tipo'}
                        disableClearable
                        onChange={(e, value) => value ? (onParamSelect('link', value.id), setImagemTipo(value.id)) : (onParamSelect('link', ''), setImagemTipo(value.id))}
                        getOptionLabel={(option) => option.nome}
                        options={imageOptions}
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }} />}
                    />
                </div>
                {imagemTipo === 'link' && ( */}
                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>link</Typography>
                    </div>
                    <TextField
                        onChange={handleLinkChange}
                        size='small'
                        InputProps={{
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }}
                    />
                    {!isLinkValid && <Typography className={classes.alertText}>Link inválido</Typography>} {/* Alert Text */}
                </div>
                {/* )}
                {imagemTipo === 'file' && (
                    <div className={classes.inputDiv}>
                        <div className={classes.label}>
                            <Typography className={classes.typo}>Enviar Arquivo</Typography>
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </div>
                )} */}
            </div>
        )

    }

    const [tabelaSelected, setTabelaSelected] = useState('');
    const [contratos, setContratos] = useState([]);
    const [selectedContrato, setSelectedContrato] = useState([])
    const tabelaFuturoOptions = [
        { tab: 'ureia_egy_futuro', name: 'Ureia Egyto' },
        { tab: 'ureia_me_futuro', name: 'Ureia Mediterrâneo' },
        { tab: 'dap_nola_futuro', name: 'DAP Nola' },
        { tab: 'map_br_futuro', name: 'MAP Brasil' },
        { tab: 'ureia_us_futuro', name: 'Ureia US' },
        { tab: 'ureia_br_futuro', name: 'Ureia BR' },
    ]

    useEffect(() => {
        if (tabelaSelected !== '') {
            setContratos('')
            FetchContratos()
        }
    }, [tabelaSelected])



    const FetchContratos = async () => {
        const response = await api_amr.get('/return_futurosContratos', { params: { 'tabela': tabelaSelected }, headers: { 'x-api-key': getToken() } })
        if (response.data !== 'NOK') {
            setContratos(response.data)
        }

    }
    const handleMultipleContratos = (value) => {
       
        if (value.length === 0) {
            setSelectedContrato([]);
            onParamSelect('contratos', []);
            return;
        }

        if (Array.isArray(value) && Array.isArray(selectedContrato)) {

            const contratos_aux = value.map(v => v.data);

            if (contratos_aux.length > selectedContrato.length) {
                const addedItem = contratos_aux.find(data => !selectedContrato.includes(data));
                if (addedItem) {
                    const newselectedContrato = [...selectedContrato, addedItem];
                    setSelectedContrato(newselectedContrato);
                    onParamSelect('contratos', newselectedContrato);

                }
            } else if (contratos_aux.length < selectedContrato.length) {
                const removedItem = selectedContrato.find(data => !contratos_aux.includes(data));
                if (removedItem !== undefined) {
                    const newselectedContrato = selectedContrato.filter(data => data !== removedItem);
                    setSelectedContrato(newselectedContrato);
                    onParamSelect('contratos', newselectedContrato);

                }
            }
        } else {
            console.error("Invalid input or state");
        }
    };
    const handleSelectTabela = () =>{
        onParamSelect('contratos', '');
        setSelectedContrato([]);

    }
    useEffect(()=>{
        handleSelectTabela()
    },[tabelaSelected])
    
    const formFuturos = () => {
        return (
            <div className={classes.formDiv}>
                <div className={classes.inputDiv}>
                    <div className={classes.label}>
                        <Typography className={classes.typo}>Tabela</Typography>
                    </div>
                    <Autocomplete
                        key={'futuros-tabela'}
                        disableClearable
                        onChange={(e, value) =>  value ? (onParamSelect('tabela', value.tab), setTabelaSelected(value.tab)) : (onParamSelect('tabela', ''), setTabelaSelected(value.tab))}
                        getOptionLabel={(option) => option.name}
                        options={tabelaFuturoOptions}
                        size='small'
                        renderInput={(params) => <TextField {...params} InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                            }, disableUnderline: true,
                        }} />}
                    />

                </div>



                {(tabelaSelected && contratos) ? (
                    <div className={classes.inputDiv}>
                        <div className={classes.label}>
                            <Typography className={classes.typo}>Contratos</Typography>
                        </div>
                        <Autocomplete
                            size='small'
                            key={'futuros-contratos'}
                            disableClearable
                            multiple={true}
                            onChange={(e, value) => handleMultipleContratos(value)}
                            getOptionLabel={(option) => option.data}
                            options={contratos}
                            renderInput={(params) => <TextField {...params} InputProps={{
                                ...params.InputProps,
                                classes: {
                                    root: classes.inputRootGrow,
                                }, disableUnderline: true,
                            }} />}
                        />
                    </div>
                ) : null}
            </div>

        )
    }


    const componentDataTypeMappings = {
        card: {
            historico_preco: formHistoricoPreco1,
            relacao_troca: formRelacaoTroca,
        },
        tabela: {
            historico_preco: formHistoricoPreco2,
            relacao_troca: formRelacaoTroca,
            futuros: formFuturos,
            formulas_salvas: formFormulas_salvas,

        },
        grafico: {
            historico_preco: formHistoricoPrecoGrafico,
            relacao_troca: formRelacaoTroca,
            relacao_troca_formulacoes:formRelacaoTrocaFormulacoes,
            futuros: formFuturos,
        },
        note: formNote,
        previsao: formPrevisao,
        imagem: formImage,
    }

    const renderParams = () => {
        if (selectedComponent === 'note') {
            return formNote()
        }
        if (selectedComponent === 'previsao') {
            return formPrevisao()
        }
        if (selectedComponent === 'imagem') {
            return formImage()
        }
        const componentMapping = componentDataTypeMappings[selectedComponent]
        return componentMapping && componentMapping[selectedData] ? componentMapping[selectedData]() : null
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {renderParams()}
            </div>
        </div>)
}

export default ComponentParams
