import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import api_amr from 'services/api_amr';
import { getToken } from 'utils/utils';
import backLogo from '../../../assets/img/logo_arm_opaque.png'
import { makeStyles } from "@material-ui/core/styles";

const colors = {
    "UR": "#0713f0", //cor fixa da materia UR
    "SAM": "#3498DB",
    "CAM": "#2980B9",
    "MAP 52": "#154079",
    "MAP 10-50": "#FFC300",
    "MAP 11-44": "#FF9A00",
    "DAP": "#FF6E00",
    "MES 09": "#FF4300",
    "MES 15": "#2ECC71",
    "NPS 12-46": "#27AE60",
    "YUC 10-40": "#1A8440",
    "YUC 09-46": "#0E5A29",
    "STP": "#9B59B6",
    "SSP 21": "#8E44AD",
    "SSP 19": "#6C3483",
    "SSP 2-18": "#4A235A",
    "SSP 3-17": "#900C3F",
    "SSP 23": "#C70039",
    "KCL": "#ED1C1C", //cor fixa da materia KCL
    "SOP": "#581845",
    "KMgS": "#FF5733",
    "B10": "#D6DBDF",
    "B2,5": "#B2BABB",
    "BoMo": "#839192",
    "Zn10": "#2C3E50",
    "Zn15": "#273746",
    "Zn20": "#1E2833",
    "Cu10": "#151C20",
    "NAM": "#F1C40F",
    "NAM_EST": "#D4AC0D",
    "DU": "#AF8C0A",
    "KCL 60.5": "#8A6607",
    "FMA BR12": "#E67E22",
    "Bentonita 90": "#CA6F1E",
    "Sulfato de Mg Kieserita": "#AF4C18",
    "SSP micrado": "#73300C",
    "NBPT": "#E74C3C",
    "NPK15-15-15": "#CB4335",
    "ASPIRE": "#A93226",
    "NPS 08-40-00+5S": "#7B0F17",
    "NPK 16-16-16": "#7B0F17"
};

export default function RelacaoTroca({ parametros }) {
    const [inputData, setInputData] = useState([])
    const [previousYearData, setPreviousYearData] = useState([])
    
    const [formattedDatesCurrent, setFormattedDatesCurrent] = useState([]);
    const [formattedDates, setFormattedDates] = useState([]);
    const [info, setInfo] = useState([]);
    const classes = useStyles()

    useEffect(() => {
        const fetchMyApi = async () => {
            const response = await api_amr.get('/return_component_relTroca', {
                params: {
                    'formato': 'grafico', 'parametros': { ...parametros, data: '2024-01-01' }
                },
                headers: { 'x-api-key': getToken() }
            })
            
            setInputData(response.data.dados.current_year)
            setPreviousYearData(response.data.dados.previous_year)
            setInfo(response.data.info)

            const newFormattedDates = response.data.dados.previous_year.map((item) => {
                const [year, month, day] = item.data.split('-');
                return `${day}/${month}/${year}`;
            });

            setFormattedDates(newFormattedDates);
            const newFormattedDatesCurrent = response.data.dados.current_year.map((item) => {
                const [year, month, day] = item.data.split('-');
                return `${day}/${month}/${year}`;
            });
            setFormattedDatesCurrent(newFormattedDatesCurrent);
        }

        fetchMyApi()
    }, [parametros])

    function hexToRgba(hex, alpha = 1) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    const data = {
        labels: formattedDates,
        datasets: [
            {
                label: '2024',
                data: inputData.map(item => item.rel_troca),
                fill: false,
                backgroundColor: hexToRgba(colors[info?.nome_materia] ?? "#000", 1),
                borderColor: hexToRgba(colors[info?.nome_materia] ?? "#000", 1),
                pointRadius: 0,
            },

            {
                label: '2023',
                data: previousYearData.map(item => item.rel_troca),
                fill: false,
                borderColor: hexToRgba(colors[info?.nome_materia] ?? "#000", 0.4),
                backgroundColor: hexToRgba(colors[info?.nome_materia] ?? "#000", 0.4),
                pointRadius: 0,
            },

        ],
    }

    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                grid: {
                    display: true,
                },
                ticks: {
                    callback: function (value, index, values) {
                        return index % 25 === 0 ? formattedDates[index] : undefined
                    },
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: true,
                text: ` ${info.nome_materia} X ${info.nome_cultura} em: ${info.nome_porto}`,
                font: {
                    size: 12,
                },
            },
            tooltip: {
                callbacks: {
                    title: function () {
                        return '';  // Returning an empty string to hide the title
                    },
                    label: function (context) {
                        const labelYear = context.dataset.label;
                        const labelValue = context.parsed.y;
                        const labelDate = labelYear === '2023' ? formattedDatesCurrent[context.dataIndex] : formattedDates[context.dataIndex];
                        return `${labelYear} - ${labelDate}: ${labelValue}`;
                    }
                }
            }
        }
    }

    return (
        <div className={classes.mainDiv}>
            <img
                src={backLogo}
                alt="Logo"
                className={classes.img}
            />
            <Line data={data} options={options} />
        </div>
    )
}

const useStyles = makeStyles({
    mainDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: 'white'
    },

    img: {
        position: 'absolute',
        zIndex: 1,
        width: '50%',
        height: 'auto',
    }
})