import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getToken, checarPermissao } from 'utils/utils';
import api_amr from 'services/api_amr';
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { Tabs, Tab, Grid, Typography } from "@material-ui/core";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Paper from "@material-ui/core/Paper";

export default function TabelaDePrecos(props) {
    //Inicio das definicioes iniciais, tem mais consts lá embaixo mas se mudar as posicões e afins da erro no codigo
    const [permitido, setPermitido] = useState(false);
    const classes = useStyles();
    const [portos, setPortos] = useState([])
    const [precos, setPrecos] = useState('')
    const [selectedPorto, setSelectedPorto] = useState(null);
    const [value, setValue] = useState(0)

    //Fim das definicioes iniciais

    //Inicio Useeffect
    //This block of code uses the useEffect hook to fetch data from the API and check user permissions. 
    useEffect(() => {
        async function fetchMyAPI() {

            if (getToken() === '') {
                return
            }

            setPermitido(await checarPermissao('tabela_de_precos', props))

            const response_return_portos = await api_amr.get('/return_portos', { headers: { 'x-api-key': getToken() } });
            setPortos(response_return_portos.data)

            const initialPorto = response_return_portos.data.find((porto) => porto.id === 54);
            setSelectedPorto(initialPorto);

            getTabela('54')
            setValue(1)
        }
        fetchMyAPI();
    }, [props]);
    //Fim do useEffect

    async function getTabela(idPorto) {
        const response_get_tabela_precos = await api_amr.get('/get_tabela_de_precos', { params: { porto: idPorto }, headers: { 'x-api-key': getToken() } });
        setPrecos(filterAndSortByAbreviacao(response_get_tabela_precos.data))
    }

    function filterAndSortByAbreviacao(array) {
        let allowedValues = [
            'UR',
            'SAM',
            'NAM',
            'MAP 52',
            'MAP 10-50',
            'MAP 11-44',
            'DAP',
            'STP',
            'SSP 21',
            'SSP 19',
            'SSP 2-18',
            'SSP 3-17',
            'SSP 23',
            'MES 09',
            'MES 15',
            'NPS 12-46',
            'NPK15-15-15',
            'NPK 16-16-16',
            'KCL',
            'KMgS',
            'ASPIRE',
            'Sulfato de Mg Kieserita'
        ]

        let filteredArray = array.filter(obj => allowedValues.includes(obj.abreviacao))

        filteredArray.sort((a, b) => allowedValues.indexOf(a.abreviacao) - allowedValues.indexOf(b.abreviacao))

        return filteredArray
    }


    const CustomTab = (props) => {
        const classes = useStyles();
        const { children, hideIndicator, ...other } = props;

        return (
            <div style={{ width: '33.3%', alignItems: 'baseline', paddingTop: 10 }}>
                <Tab
                    classes={hideIndicator ? { indicator: classes.hideTabIndicator } : {}}
                    {...other}
                >
                    {children}
                </Tab>
            </div>
        );
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#A3CC51'
            },
        }
    });

    function getVariacaoKey() {
        switch (value) {
            case 0:
                return 'variacao_semana_passada';
            case 1:
                return 'variacao_mes_passado';
            case 2:
                return 'variacao_ano_passado';
            default:
                return '';
        }
    }

    function formatVariacao(variacao) {
        if (isNaN(variacao) || Math.abs(variacao) === Infinity) {
            return "N/A";
        }
        return `${variacao}%`;
    }

    if (permitido) {
        return (
            <Grid container item style={{ paddingLeft: 10 }} >
                <Grid item>
                    <Typography className={classes.inputh2}>Cotações de Preços FOB PORTO BAG</Typography>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={4} className={classes.autoCompleteGrid}>
                        <MuiThemeProvider theme={theme}>
                            <Autocomplete
                                PaperComponent={({ children }) => (
                                    <Paper className={classes.autoCompleteOption}>{children}</Paper>
                                )}
                                options={portos}
                                getOptionLabel={(option) => option.nome_porto}
                                onChange={(e, v) => {
                                    getTabela(v.id);
                                    setSelectedPorto(v);
                                }}
                                disableClearable
                                value={selectedPorto}
                                className={`${classes.autoComplete}`} // Apply the firstTab class only for index 0
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Porto"
                                        value=""
                                        fullWidth
                                        InputProps={{
                                            ...params.InputProps,
                                            style: { fontSize: '12px', paddingLeft: 5 } // Adjust the font size if needed
                                        }}
                                    />
                                )}
                            />
                        </MuiThemeProvider>
                    </Grid>

                    <Grid item xs={8}>
                        <MuiThemeProvider theme={theme}>

                            <Tabs
                                indicatorColor="primary"
                                value={value}
                                onChange={(event, newValue) => setValue(newValue)}>
                                <CustomTab
                                    value={0}
                                    style={{ background: value === 0 ? 'white' : '#F0F0F0' }}
                                    label='Semanal'
                                    className={`${classes.tabLabel}`}
                                />
                                <CustomTab
                                    value={1}
                                    style={{ background: value === 1 ? 'white' : '#F0F0F0' }}
                                    label='Mensal'
                                    className={`${classes.tabLabel}`}
                                />
                                <CustomTab
                                    value={2}
                                    style={{ background: value === 2 ? 'white' : '#F0F0F0' }}
                                    label='Anual'
                                    className={`${classes.tabLabel}`}
                                />
                            </Tabs>
                        </MuiThemeProvider>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow >
                                    <TableCell className={classes.tableCell}>
                                        <b className={classes.inputh1}>Matéria-Prima</b>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <b className={classes.inputh1}> Preço Atual(USD)</b>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <b className={classes.inputh1}> Variação</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(precos).map((item, index) => (
                                    <TableRow key={index} >
                                        <TableCell className={classes.tableCell}>
                                            <b className={classes.inputh1}> {precos[item].abreviacao}</b>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <b className={classes.inputh1}> {precos[item].valor_atual}</b>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {precos[item][getVariacaoKey()] > 0 && !isNaN(precos[item][getVariacaoKey()]) && Math.abs(precos[item][getVariacaoKey()]) !== Infinity ? (
                                                    <TrendingUpIcon className={`${classes.icon} ${classes.green}`} style={{ fontSize: '0.7rem' }} />
                                                ) : precos[item][getVariacaoKey()] < 0 && !isNaN(precos[item][getVariacaoKey()]) && Math.abs(precos[item][getVariacaoKey()]) !== Infinity ? (
                                                    <TrendingDownIcon className={`${classes.icon} ${classes.red}`} style={{ fontSize: '0.7rem' }} />
                                                ) : (
                                                    <div style={{ width: '0.5rem', height: '0.5rem', fontSize: '0.5rem' }} />
                                                )}

                                                <b
                                                    className={`${classes.inputh1} ${precos[item][getVariacaoKey()] > 0 && !isNaN(precos[item][getVariacaoKey()]) && Math.abs(precos[item][getVariacaoKey()]) !== Infinity
                                                        ? classes.green
                                                        : precos[item][getVariacaoKey()] < 0 && !isNaN(precos[item][getVariacaoKey()]) && Math.abs(precos[item][getVariacaoKey()]) !== Infinity
                                                            ? classes.red
                                                            : classes.grey
                                                        }`}

                                                >
                                                    {precos[item][getVariacaoKey()] === 0 || isNaN(precos[item][getVariacaoKey()]) || Math.abs(precos[item][getVariacaoKey()]) === Infinity
                                                        ? "-"
                                                        : formatVariacao(precos[item][getVariacaoKey()])}
                                                </b>
                                            </div>
                                        </TableCell>


                                    </TableRow>))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    } else { return null }
}

const useStyles = makeStyles((theme) => ({
    autoCompleteGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 10
    },

    autoComplete: {
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
        borderRadius: theme.shape.borderRadius,
        '& .MuiInputBase-root': {
            '& input': {
                color: 'rgba(0, 0, 0, 0.54)', // Slightly gray font
                '&::placeholder': {
                    color: 'rgba(0, 0, 0, 0.54)',
                },
            },
            '&:before': {
                // Remove the underline
                display: 'none',
            },
        },
        width: '100%',
    },

    tableCell: {
        padding: 0,
    },

    inputh1: {
        fontFamily: "Poppins",
        fontSize: '0.65rem',
    },

    inputh2: {
        fontFamily: "Poppins",
        fontSize: '1.1rem',
        color: '#3d3d3d',
        position: 'relative', // Add this line
        '&::after': { // Add this block
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: -3, // Adjust this value to move the underline up or down
            width: '100%',
            height: 2, // Adjust this value to change the thickness of the underline
            background: 'linear-gradient(to right, #31DD06, rgba(0, 0, 0, 0))', // Adjust the color and opacity as needed
        },
    },

    hideTabIndicator: {
        display: 'none',
    },

    flexGrow: {
        flexGrow: 1,
    },

    option: {
        padding: theme.spacing(1), // Adjust the padding as needed

        fontSize: '0.7rem',
    },

    autoCompleteOption: {
        fontFamily: "Poppins",
        minWidth: '180px', // Set a minimum width to prevent shrinking
        whiteSpace: 'nowrap',
        fontSize: '0.9rem', // Set the desired font size for the options
    },

    container: {
        display: "flex",
        flexDirection: "column"
    },

    tabLabel: {
        textTransform: 'none',
        width: '100%',
        minWidth: 'auto',
        fontSize: '12px',
        background: '#F0F0F0',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5
    },

    root: {
        "& .MuiDataGrid-cell": {
            borderRight: "1px solid #000"
        }
    },

    green: {
        color: "green"
    },

    red: {
        color: "red"
    },

    grey: {
        color: "grey"
    }
}));