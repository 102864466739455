import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, CircularProgress } from '@material-ui/core';
import useStyles from '../styles/styles';
import { CircleFlag } from 'react-circle-flags';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';


const CardMateria = ({ materia, cardData }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (materia && cardData && Object.keys(materia).length > 0 && Object.keys(cardData).length > 0) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [cardData, materia]);

    const renderArrowIcon = (value) => {
        let icon;

        if (value < 0) {
            icon = <ArrowUpwardIcon className={classes.arrowDown} />
        } else if (value > 0) {
            icon = <ArrowUpwardIcon className={classes.arrowUp} />
        } else {
            icon = <RemoveCircleOutlineRoundedIcon className={classes.neutralCircleIcon} style={{ color: '#C9C422', fontSize: 30 }} />;
        }

        return icon
    };

    const renderTextWithArrowIcon = (text, value) => (
        <Grid container direction="column" alignItems="center" spacing={1}>
            <Grid item>{renderArrowIcon(value)}</Grid>
            <Grid item>
                <Typography className={classes.biggerTextVariacao}>{text}</Typography>
            </Grid>
        </Grid>
    );

    const handleColor = (value) => {
        console.log(value)
        if (value > 0) {
            return '#44FF57';
        } else if (value < 0) {
            return '#FF005C';
        } else {
            return '#000000';
        }
    };

    const handleIcon = (value) => {
        if (value > 0) {
            return <TrendingUpIcon style={{ color: '44FF57', marginTop: 6 }} />;
        } else if (value < 0) {
            return <TrendingDownIcon style={{ color: '#FF005C', marginTop: 6 }} />;
        } else {
            return <RemoveCircleOutlineRoundedIcon style={{ color: '#C9C422', marginTop: 6 }} />;
        }
    };

    return (
        loading ? <CircularProgress style={{ 'color': '#A3CC51' }} /> :
            <div className={classes.cardWrapper}>
                <Card className={classes.rootCard} >
                    <CardContent className={classes.cardContent} >
                        <Grid container>
                            <Grid item container xs={6} className={classes.centerContent}>
                                <Typography className={classes.titleText} variant="subtitle1">Matéria:</Typography>
                                <Typography className={classes.biggerTextMateria} variant="h6">{materia.descricao}</Typography>
                            </Grid>
                            <Grid item container xs={6} className={classes.centerContent}>
                                <Typography className={classes.titleText} variant="subtitle1">Tipo:</Typography>
                                <Typography className={classes.biggerTextVariacao1} variant="h6">{materia.tipo}</Typography>
                            </Grid>
                            <Grid container item xs={4} className={classes.variacaoGrid} >
                                <Grid item xs={12} >
                                    <Typography className={`${classes.titleText} ${classes.gridTitle}`} variant="subtitle1">Variação:</Typography>
                                </Grid>
                                <Grid container item xs={12} alignItems="center">
                                    <Grid item xs={5}>
                                        <Typography className={classes.biggerTextVariacao}>
                                            Semana
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography className={classes.biggerTextVariacao} >
                                            <span style={{ color: handleColor(cardData.variacao_semana_passada) }}>
                                                {cardData.variacao_semana_passada !== undefined && cardData.variacao_semana_passada !== '' ? `${cardData.variacao_semana_passada}%` : '0%'}
                                            </span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} >
                                        {handleIcon(cardData.variacao_semana_passada)}
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} alignItems="center">
                                    <Grid item xs={5}>
                                        <Typography className={classes.biggerTextVariacao}>
                                            Mês
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography className={classes.biggerTextVariacao}>
                                            <span style={{ color: handleColor(cardData.variacao_mes_passado) }}>
                                                {cardData.variacao_mes_passado !== undefined && cardData.variacao_mes_passado !== '' ? `${cardData.variacao_mes_passado}%` : ''}
                                            </span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} >
                                        {handleIcon(cardData.variacao_mes_passado)}
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} alignItems="center">
                                    <Grid item xs={5}>
                                        <Typography className={classes.biggerTextVariacao}>
                                            Ano
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography className={classes.biggerTextVariacao}>
                                            <span style={{ color: handleColor(cardData.variacao_ano_passado) }}>
                                                {cardData.variacao_ano_passado !== undefined && cardData.variacao_ano_passado !== '' ? `${cardData.variacao_ano_passado}%` : ''}
                                            </span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {handleIcon(cardData.variacao_ano_passado)}
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid container item xs={3} className={classes.composicaoGrid} >
                                <Grid item xs={12}><Typography className={`${classes.titleText} ${classes.gridTitle}`} variant="subtitle1" >Composição:</Typography></Grid>
                                <Grid container item xs={12} justifyContent="space-between">
                                    <Typography className={classes.biggerTextVariacao}>Nitrogênio&nbsp;&nbsp;{materia.nitrogenio} </Typography>
                                </Grid>
                                <Grid container item xs={12} justifyContent="space-between">
                                    <Typography className={classes.biggerTextVariacao}>Fósforo&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{materia.fosforo}</Typography>
                                </Grid>
                                <Grid container item xs={12} justifyContent="space-between">
                                    <Typography className={classes.biggerTextVariacao}>Potássio&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{materia.potassio}</Typography>
                                </Grid>
                            </Grid>

                            <Grid container item xs={5} className={classes.precoFreteGrid} >
                                <Grid item xs={12} >
                                    <Typography className={classes.titleText} variant="subtitle1">
                                        Preço FOB Porto &nbsp;
                                        <CircleFlag countryCode="us" height="20" className={classes.flagUS} />
                                        <CircleFlag countryCode="br" height="20" className={classes.flagBR} />
                                    </Typography>
                                </Grid>
                                <Grid xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Grid item xs={6} >
                                        <Typography className={classes.biggerTextVariacao}>
                                            {parseFloat(cardData.valor_atual).toLocaleString('pt-br', {
                                                style:
                                                    'currency', currency: 'USD'
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Typography className={classes.biggerTextVariacao}>
                                            {cardData.valor_atual_rs.toLocaleString('pt-br', {
                                                style:
                                                    'currency', currency: 'BRL'
                                            })}
                                        </Typography>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
    );
};

export default CardMateria;
