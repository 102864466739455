import React, { useEffect, useState } from "react";
import { getToken } from '../../utils/utils';
import api_amr from '../../services/api_amr';
import { Autocomplete } from "@material-ui/lab";
import { TextField, Button, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
    card: {
        minWidth: 275,
        padding: theme.spacing(2),
        boxShadow: theme.shadows[3],
    },
    formField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

function ConfigFazendas(props) {
    const [cidadesList, setCidadesList] = useState([]);
    const [users, setUsers] = useState('')
    const [portosList, setPortosList] = useState([])

    const [associados, setAssociados] = useState([]);
    const [selectedAssociado, setSelectedAssociado] = useState(null);
    const [farmDetails, setFarmDetails] = useState({
        nomeFazenda: '',
        carFazenda: '',
        estadoCidade: '',
        cep: '',
        endereco: '',
        area: 0,
        id_usuario: '',
        id_porto: ''
    });

    const classes = useStyles();

    useEffect(() => {
        async function fetchData() {
            try {
                const [associatesResponse, citiesResponse, portsResponse] = await Promise.all([
                    api_amr.get('/get_associados', { headers: { 'x-api-key': getToken() } }),
                    api_amr.get('/return_cidades_brasil', { headers: { 'x-api-key': getToken() } }),
                    api_amr.get('/return_portos', { headers: { 'x-api-key': getToken() } })
                ]);

                setAssociados(associatesResponse.data);
                setCidadesList(citiesResponse.data);
                setPortosList(portsResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);



    function handleInputChange(event) {
        const { name, value } = event.target;
        setFarmDetails({ ...farmDetails, [name]: value });
    }

    const handleSubmit = async () => {
        const [estado, cidade] = farmDetails.estadoCidade.split(' - ');
        const payload = {
            id_associado: selectedAssociado ? selectedAssociado.id_associado : null,
            nome_fazenda: farmDetails.nomeFazenda,
            car_fazenda: farmDetails.carFazenda,
            estado: estado,
            cidade: cidade,
            cep: farmDetails.cep,
            endereco: farmDetails.endereco,
            area: farmDetails.area,
            id_usuario: farmDetails.id_usuario,
            id_porto: farmDetails.id_porto
        };

        try {
            const response = await api_amr.post('/create_farm', payload, { headers: { 'x-api-key': getToken() } });
            if (response.data === 'OK') {
                alert('Fazenda criada com sucesso');
                // Limpar os campos e o associado
                setFarmDetails({
                    nomeFazenda: '',
                    carFazenda: '',
                    estadoCidade: '',
                    cep: '',
                    endereco: '',
                    area: 0,
                    id_usuario: '',
                    id_porto: ''
                });
                setSelectedAssociado(null);
            } else {
                console.error('Erro ao criar fazenda:', response.data);
            }
        } catch (error) {
            console.error('Erro ao criar fazenda:', error);
        }
    }
    async function selecionarAssociado(valor) {
        setSelectedAssociado(valor)
        const response_Users = await api_amr.get('/get_users', { params: { 'associado': valor.id_associado }, headers: { 'x-api-key': getToken() } });
        setUsers(response_Users.data);
    }
    return (
        <div className={classes.container}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Configurar Fazenda
                    </Typography>
                    <Autocomplete
                        options={associados}
                        getOptionLabel={(option) => option.name_associado}
                        onChange={(event, newValue) => selecionarAssociado(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Selecionar Associado"
                                variant="outlined"
                                className={classes.formField}
                            />
                        )}
                    />
                    {selectedAssociado && (
                        <>
                            {users !== '' ?
                                < Autocomplete
                                    options={users}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => setFarmDetails({ ...farmDetails, id_usuario: newValue ? newValue.id : '' })}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Selecionar Usuário"
                                            variant="outlined"
                                            className={classes.formField}
                                            size="small"
                                        />
                                    )}
                                /> : null}
                            <TextField
                                label="Nome Fazenda"
                                name="nomeFazenda"
                                variant="outlined"
                                className={classes.formField}
                                value={farmDetails.nomeFazenda}
                                onChange={handleInputChange}
                                size="small"
                            />
                            <TextField
                                label="CAR Fazenda"
                                name="carFazenda"
                                variant="outlined"
                                className={classes.formField}
                                value={farmDetails.carFazenda}
                                onChange={handleInputChange}
                                size="small"
                            />
                            <Autocomplete
                                options={cidadesList}
                                getOptionLabel={(option) => `${option.UF} - ${option.cidade}`}
                                onChange={(event, newValue) => setFarmDetails({ ...farmDetails, estadoCidade: newValue ? `${newValue.UF} - ${newValue.cidade}` : '' })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Estado e Cidade"
                                        variant="outlined"
                                        className={classes.formField}
                                        size="small"
                                    />
                                )}
                            />
                            <TextField
                                label="CEP"
                                name="cep"
                                variant="outlined"
                                className={classes.formField}
                                value={farmDetails.cep}
                                onChange={handleInputChange}
                                size="small"
                            />
                            <TextField
                                label="Endereço"
                                name="endereco"
                                variant="outlined"
                                className={classes.formField}
                                value={farmDetails.endereco}
                                onChange={handleInputChange}
                                size="small"
                            />
                            <TextField
                                label="Área"
                                name="area"
                                variant="outlined"
                                className={classes.formField}
                                value={farmDetails.area}
                                onChange={handleInputChange}
                                size="small"
                                type="number"

                            />
                            <Autocomplete
                                options={portosList}
                                getOptionLabel={(option) => option.nome_porto}
                                onChange={(event, newValue) =>
                                    setFarmDetails({
                                        ...farmDetails,
                                        id_porto: newValue ? newValue.id : ''
                                    })
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Porto provável"
                                        variant="outlined"
                                        className={classes.formField}
                                        size="small"
                                    />
                                )}
                            />

                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleSubmit}
                            >
                                Cadastrar Fazenda
                            </Button>
                        </>
                    )}
                </CardContent>
            </Card>
        </div>
    );
}

export default ConfigFazendas;
