// Import statements
import React, { useState, useRef } from "react";
import api_amr from 'services/api_amr';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Container, Button, CircularProgress, Grid } from "@material-ui/core";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
// useStyles declaration
const useStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: '100%',
        overflow: 'auto',
        marginBottom: 200
    },
    autoCompleteFieldBig: {
        marginTop: 10,
        marginRight: 10,
        width: 250,
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        borderRadius: 6,
    },
    typographyTitle: {
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    infoWrapper: {
        padding: 10,
        border: '1px solid lightgray',
        borderRadius: 5
    },
    bodyRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#f5f5f5',
        },
        '&:hover': {
            backgroundColor: '#e0e0e0',
        }
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',  // 50% of viewport height
    },
    button: {
        background: '#A3CC51',
        color: 'white',
        fontFamily: 'Poppins'
    },
    typographyDescription: {
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: 14,
        
    }
}));

// Main component function
export default function GepetoHistoricoUsabilidade() {
    const classes = useStyles();
    const chatRef = useRef();

    const [question, setQuestion] = useState('');
    const [data, setData] = useState([]);
    const [gerando, setGerando] = useState(false)
    const [typingMessageIndex, setTypingMessageIndex] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');

    const handleSort = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedData = [...data].sort((a, b) => {
        if (a[orderBy] < b[orderBy]) {
            return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const typingMessages = [
        '.',
        '..',
        '...',
        '....',
    ];

    const generateTable = () => {
        if (data.length === 0) {
            return null;
        }

        let keys = Object.keys(data[0]);

        return (
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead stickyHeader >
                        <TableRow>
                            {keys.map((key, index) => (
                                <TableCell
                                    align="left"
                                    className={classes.header}
                                    key={index}
                                    sortDirection={orderBy === key ? order : false}
                                >
                                    <TableSortLabel
                                        align="left"
                                        active={orderBy === key}
                                        direction={orderBy === key ? order : 'asc'}
                                        onClick={handleSort(key)}
                                    >
                                        {key}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.slice(0, 50).map((row, index) => (
                            <TableRow key={index} className={classes.bodyRow}>
                                {keys.map((key, subIndex) => (
                                    <TableCell scope="row" align="left" key={subIndex}>{row[key]}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const handleSubmit = async () => {
        setGerando(true)
        const responseData = await api_amr.get('/historico_gepeto', { params: { request: question } });

        if (responseData.data === "NOK") {
            window.alert('Tente novamente')
            setGerando(false)
            setData([])
            return
        }

        setData(responseData.data)
        setGerando(false)
    };

    return (
        <Container className={classes.root}>
            <Grid>
                <Typography variant="h5" gutterBottom className={classes.typographyTitle}>
                    Extração de informações de usabilidade integrado com Inteligência Artificial
                </Typography>
                <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                    Tela para consultas de informações de forma dinâmica, alguns exemplos de utilização abaixo:
                </Typography>
                <Button onClick={() => setQuestion('A quantidade de logins realizada por usuário em fevereiro de 2023, mostre o nome do usuário na tabela')}>
                    <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                       EX 1: A quantidade de logins realizada por usuário em fevereiro de 2023, mostre o nome do usuário na tabela
                    </Typography>
                </Button>
                <Button onClick={() => setQuestion('a quantidade de vezes que um 2 20 18 foi gerado, divida por nome de usuario')}>
                    <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                       EX 2: A quantidade de vezes que um 2 20 18 foi gerado, divida por nome de usuario
                    </Typography>
                </Button>
                <Button onClick={() => setQuestion('A evolução da quantidade total de chamadas realizadas mês a mês separadas por ano com a porcentagem de diferenca de um para outro')}>
                    <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                       EX 3: A evolução da quantidade total de chamadas realizadas mês a mês separadas por ano  com a porcentagem de diferenca de um para outro
                    </Typography>
                </Button>
                <Button onClick={() => setQuestion(' Porto mais utilizado para fechar formulações por nome do porto')}>
                    <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                       EX 4: Porto mais utilizado para fechar formulações por nome do porto
                    </Typography>
                </Button>
              
            </Grid>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={9}>
                    <TextField
                        label="Pergunta"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        className={classes.textField}
                        fullWidth
                        multiline
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        className={classes.button}
                        fullWidth
                    >
                        Consultar
                    </Button>
                </Grid>
            </Grid>

            {gerando ?
                <div className={classes.progressContainer}>
                    <CircularProgress />
                </div>
                :
                <div>
                    {data.length > 0 && generateTable()}
                </div>
            }
        </Container>
    );
}
