import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api_amr from "../../services/api_amr";
import { Button, TextField, FormControl, Select, MenuItem, InputLabel, Typography, Paper, Grid, Checkbox, FormControlLabel, List, ListItem, ListItemText } from "@material-ui/core";
import { getToken, checarPermissao } from '../../utils/utils';
import { Autocomplete } from "@material-ui/lab";
import logoLoader from 'assets/img/loader_gif.gif';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr', // Divide o espaço do container igualmente
        gap: theme.spacing(2), // Espaço entre as colunas
        padding: theme.spacing(2), // Padding ao redor do container
        alignItems: 'start', // Alinha os itens ao início, para que eles não estiquem
        minHeight: '100vh',
    },
    formModule: {
        maxWidth: 600,
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        // Remova as propriedades relacionadas à posição absoluta
    },
    resultModule: {
        padding: theme.spacing(3),
        margin: theme.spacing(2),
        visibility: 'hidden',
        opacity: 0,
        maxWidth: 600,
        // Remova as propriedades relacionadas à posição absoluta e transformações
    },
    resultModule: {
        padding: theme.spacing(3),
        margin: theme.spacing(2),
        visibility: 'hidden',
        opacity: 0,
        maxWidth: 600,
        // Remova as propriedades relacionadas à posição absoluta e transformações
    },
    visible: {
        visibility: 'visible',
        opacity: 1,
        // Remova as propriedades de posicionamento
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: '100%', // Para garantir que não ultrapasse a largura desejada
    },
    moveLeft: {
        position: 'absolute',
        left: '10%', // Ajuste isso conforme necessário para mover para a esquerda
        top: '50%',
        transform: 'translateY(-50%)',
        transition: 'left 0.5s ease-in-out',
    },
}));

export default function Formulador(props) {
    const classes = useStyles();


    const [permitido, setPermitido] = useState(false);
    //Conteudo autocomplete
    const [portos, setPortos] = useState([]);
    const [materias, setMaterias] = useState([])

    const [resultVisible, setResultVisible] = useState(false);
    const [carregando, setCarregando] = useState(false)

    const [materiaSelecionada, setMateriaSelecionada] = useState(null);
    const [minMax, setMinMax] = useState({ min: '', max: '' });

    // Dados para enviar
    const [n, setN] = useState(0)
    const [p, setP] = useState(0)
    const [k, setK] = useState(0)
    const [porto, setPorto] = useState(''); //id do porto
    const [microNutrientes, setMicroMutrientes] = useState([{ 'ca': 0 }, { 's': 0 }, { 'mg': 0 }, { 'zn': 0 }, { 'b': 0 }, { 'cu': 0 }, { 'mn': 0 }, { 'mo': 0 }, { 'si': 0 }, { 'cl': 0 }])
    const [materiasObrigatorias, setMateriasObrigatorioas] = useState({}) // lista de objetos com ids e min max de quantidade. Ex: {81:(50, 1000), 99:(50,1000), 97:(50,1000)}
    const [materiasRemovidas, setMateriasRemovidas] = useState([]) // lista de ids. Ex: [110,82]
    const [dustrol, setDustrol] = useState(false)
    const [inibidor, setInibidor] = useState(false)

    //Dados Resposta
    const [macroNutrientes, setMacroNutrientes] = useState({});
    const [microNutrientesFormulacao, setMicronutrientesFormulacao] = useState('')
    const [custo, setCusto] = useState(0)
    const [formulacao, setFormulacao] = useState([])

    const nomenclatura = {
        ca: 'Cálcio', s: 'Enxofre', mg: 'Magnésio', zn: 'Zinco', b: 'Boro',
        cu: 'Cobre', mn: 'Manganês', mo: 'Molibdênio', si: 'Silício', cl: 'Cloro'
    }

    useEffect(() => {
        async function fetchMyAPI() {
            setPermitido(await checarPermissao('configuracoes_clientes', props))
            const response_return_portos = await api_amr.get('/return_portos', { headers: { 'x-api-key': getToken() } });
            setPortos(response_return_portos.data)
            const response_materias = await api_amr.get('/return_indice_materias', { headers: { 'x-api-key': getToken() } });
            setMaterias(response_materias.data);
        }
        fetchMyAPI()
    }, []);

    // Função para lidar com o envio do formulário
    const handleSubmit = async (event) => {

        try {
            event.preventDefault();
            // Implementação da lógica de envio

            setCarregando(true)
            const response = await api_amr.get('/formuladorRS', {
                params: {
                    'n': n,
                    'p': p,
                    'k': k,
                    'id_porto': porto,
                    'materiais_removidas': JSON.stringify(materiasRemovidas),
                    'micronutrientes': JSON.stringify(microNutrientes),
                    'materias_obrigatorias': JSON.stringify(materiasObrigatorias),
                    'dustrol': dustrol,
                    'inibidor': inibidor
                },

                headers: {
                    'x-api-key': getToken()
                }
            })
            //não encontrou uma formulacao
            if (response.status === 407) {
                alert(response.data.mensagem)
            }
            // Dados incompletos
            else if (response.status === 408) {
                alert(response.data.mensagem)
            }
            //encontrou uma formulacao entao seta os resultados
            else if (response.status === 200) {

                // Processamento dos macronutrientes para string
                const macroNutrientesStr = Object.entries(response.data.composicao.macronutriente).map(([nutriente, quantidade]) => `${nutriente}: ${quantidade.toFixed(2)}`).join(', ');

                // Processamento dos micronutrientes para formato adequado
                const micronutrientesFormulacaoStr = response.data.composicao.micronutriente.map(micro => `${micro.nome}: ${micro.quantidade.toFixed(2)}`).join(', ');

                // setMacroNutrientes(macroNutrientesStr);
                setMacroNutrientes(response.data.composicao.macronutriente);
                setMicronutrientesFormulacao(micronutrientesFormulacaoStr);
                setCusto(response.data.custo.toFixed(2));
                setFormulacao(response.data.formulacao);
                setResultVisible(true);
                setCarregando(false);
            }
            else {
                alert('Ocorreu algum erro, entre em contato com o time de desenvolvimento')
                setCarregando(false)
                return
            }
        } catch (error) {
            console.log(error)
            setCarregando(false)
        }
    };

    const handleMicronutrientChange = (name, value) => {
        setMicroMutrientes(current =>
            current.map(item => (Object.keys(item)[0] === name ? { [name]: value } : item))
        );

    };
    const handleCheckboxDustrol = (event) => {
        setDustrol(event.target.checked);
    };
    const handleCheckboxInibidor = (event) => {
        setInibidor(event.target.checked);
    };

    const handleAddMateria = () => {
        if (materiaSelecionada && minMax.min && minMax.max) {
            setMateriasObrigatorioas(prevMaterias => ({
                ...prevMaterias,
                [materiaSelecionada.id]: [parseInt(minMax.min), parseInt(minMax.max)]
            }));
            // Limpeza após adição
            setMateriaSelecionada(null);
            setMinMax({ min: '', max: '' });
        } else {
            // Você pode adicionar uma lógica de validação ou alerta aqui, caso alguma entrada esteja faltando
            alert("Por favor, preencha todos os campos antes de adicionar.");
        }
    };

    return (
        permitido ? (
            <div className={classes.container}>
                <div>
                    <Paper elevation={3} className={classes.formModule}>
                        <form onSubmit={handleSubmit}>
                            <Typography variant="h6">Formulador</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField style={{ maxWidth: 200 }} label="N" type="number" value={n} onChange={(e) => setN(e.target.value)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField style={{ maxWidth: 200 }} label="P" type="number" value={p} onChange={(e) => setP(e.target.value)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField style={{ maxWidth: 200 }} label="K" type="number" value={k} onChange={(e) => setK(e.target.value)} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={portos}
                                        getOptionLabel={(option) => `${option.id} - ${option.nome_porto} - ${option.cidade}/${option.UF}`}
                                        onChange={(event, newValue) => setPorto(newValue ? newValue.id : '')}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Selecionar Porto"
                                                placeholder="Digite para buscar..."
                                                style={{ maxWidth: 600 }}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid container spacing={2} >
                                    {microNutrientes.map((nutriente, index) => {
                                        const nutrientName = Object.keys(nutriente)[0];
                                        return (
                                            <Grid item xs={4} key={index}>
                                                <TextField
                                                    label={nomenclatura[nutrientName]}
                                                    type="number"
                                                    value={nutriente[nutrientName]}
                                                    onChange={(e) => handleMicronutrientChange(nutrientName, e.target.value)}
                                                    style={{ maxWidth: 200 }}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        options={materias}
                                        getOptionLabel={(option) => `${option.id} - ${option.abrev}`}
                                        onChange={(event, newValue) => {
                                            // Mapeia newValue para extrair apenas os ids
                                            const novasMateriasRemovidasIds = newValue.map((item) => item.id);
                                            // Atualiza o estado com os novos ids
                                            setMateriasRemovidas(novasMateriasRemovidasIds);
                                            console.log(novasMateriasRemovidasIds);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Matérias a serem Removidas"
                                                placeholder="Selecione as matérias removidas..."
                                                style={{ maxWidth: 600 }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={materias}
                                        getOptionLabel={(option) => `${option.id} - ${option.abrev}`}
                                        onChange={(event, newValue) => setMateriaSelecionada(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Selecionar Matéria"
                                                placeholder="Selecione a matéria..."
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Min"
                                        type="number"
                                        fullWidth
                                        value={minMax.min}
                                        onChange={(e) => setMinMax({ ...minMax, min: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Max"
                                        type="number"
                                        fullWidth
                                        value={minMax.max}
                                        onChange={(e) => setMinMax({ ...minMax, max: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddMateria}
                                    >
                                        Adicionar Matéria
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <List>
                                        {Object.entries(materiasObrigatorias).map(([id, [min, max]]) => (
                                            <ListItem key={id}>
                                                <ListItemText primary={`Matéria ID: ${id}, Min: ${min}, Max: ${max}`} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox checked={dustrol} onChange={handleCheckboxDustrol} name="dustrol" />}
                                        label="adicionar Dustrol?"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox checked={inibidor} onChange={handleCheckboxInibidor} name="inibidor" />}
                                        label="Adicionar inibidor NBPT?"
                                    />
                                </Grid>
                            </Grid>

                            <Button type="submit" variant="contained" color="primary">Calcular</Button>
                        </form>
                    </Paper>
                </div>
                <Paper elevation={3} className={`${classes.resultModule} ${resultVisible && classes.visible}`}>
                    {carregando ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <img src={logoLoader} alt="Carregando..." style={{ width: 100, height: 100 }} />
                        </div>
                    ) : (
                        <div>
                            <Typography variant="h6" style={{ marginBottom: '20px' }}>Resultados da Formulação</Typography>
                            {/* Exibição dos macronutrientes */}
                            <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}> 
                                <Typography variant="subtitle1">Macro Nutrientes:</Typography>
                                <did style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                <Typography paragraph>Nitrogênio: {macroNutrientes.nitrogenio ? macroNutrientes.nitrogenio.toFixed(2) : '0'}</Typography>
                                <Typography paragraph>Fósforo: {macroNutrientes.fosforo ? macroNutrientes.fosforo.toFixed(2) : '0'}</Typography>
                                <Typography paragraph>Potássio: {macroNutrientes.potassio ? macroNutrientes.potassio.toFixed(2) : '0'}</Typography>
                                </did>
                            </div>
                            {/* Exibição dos micronutrientes */}
                            <div>
                                <Typography variant="subtitle1">Micro Nutrientes:</Typography>
                                <Typography paragraph>{microNutrientesFormulacao}</Typography>
                            </div>
                            {/* Custo */}
                            <div>
                                <Typography variant="subtitle1">Custo Total:</Typography>
                                <Typography paragraph>{`US$ ${custo}`}</Typography>
                            </div>
                            {/* Lista de formulações */}
                            <div>
                                <Typography variant="subtitle1">Formulação:</Typography>
                                {formulacao.length > 0 ? formulacao.map((item, index) => (
                                    <Typography key={index}>{`${item.materia}: ${item.quantidade} kg`}</Typography>
                                )) : <Typography>Nenhuma formulação encontrada.</Typography>}
                            </div>
                        </div>
                    )}
                </Paper>
            </div>
        ) : null
    );
}
