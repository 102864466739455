import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableHead, TableRow, TableCell, TableContainer, TableBody, Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import api_amr from "services/api_amr";
import { getToken } from "utils/utils";
import Loader from 'react-loader-spinner';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

export default function UsabilidadeFormulas() {
    const classes = useStyles();
    const [npkRank, setNpkRank] = useState([])
    const [portosRank, setPortosRank] = useState([])
    const [prefsRank, setPrefsRank] = useState([])
    const [remRank, setRemRank] = useState([])
    const [microRank, setMicroRank] = useState([])
    const [userRank, setUserRank] = useState([])

    const [dataInicial, setDataInicial] = useState('2023-01-01')
    const [dataFinal, setDataFinal] = useState('2024-12-31')

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchApi()
    }, [])

    const fetchApi = async () => {
        setLoading(true)
        const response = await api_amr.get('/retorna_DashBoardRetornaFormulas', { params: { dataInicial, dataFinal }, headers: { 'x-api-key': getToken() } })
        setNpkRank(response.data.NPKCombinations)
        setPortosRank(response.data.PortosMostUsed)
        setPrefsRank(response.data.PrefMostUsed)
        setRemRank(response.data.RemMostUsed)
        setMicroRank(response.data.AddMicroMostUsed)
        setUserRank(response.data.user)
        setLoading(false)
    }

    const handleFiltro = async () => {
        fetchApi()
    }

    if (loading) {
        return <Loader style={{ marginLeft: 20 }} type="ThreeDots" color="#A3CC51" height="80" width="80" />
    }

    return (
        <Grid container style={{ padding: 20 }} >
             <Grid>
                <Typography variant="h5" gutterBottom className={classes.typographyTitle}>
                    Acompanhamento de utilização do Retorna Fórmulas
                </Typography>
                <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                    Tela para acompanhamento de utilização do Retorna Fórmulas
                </Typography>
                <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                    -Fechamentos mais utilizados
                </Typography>
                <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                    -Usabilidade por usuário
                </Typography>
                <Typography variant="h5" gutterBottom className={classes.typographyDescription}>
                    -Utilização de configurações avançadas
                </Typography>
            </Grid>
            <Grid container style={{marginTop:10}} >
                <TextField
                    size="small"
                    value={dataInicial}
                    onChange={(e) => setDataInicial(e.target.value)}
                    variant="outlined"
                    label='Data Inicial'
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }} />
                <TextField
                    size="small"
                    style={{ marginLeft: 20, marginRight: 20 }}
                    value={dataFinal}
                    onChange={(e) => setDataFinal(e.target.value)}
                    variant="outlined"
                    label='Data Final'
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }} />

                <Button size="small" className={classes.button} variant="contained" onClick={handleFiltro}>Filtrar</Button>
            </Grid>

            <Grid container spacing={4} style={{ marginTop: 10 }}>
                <Grid item xs={6} >
                    <Box mb={2}>
                        <Typography className={classes.typo}>
                            Combinações mais comuns NPK
                        </Typography>

                        <TableContainer className={classes.table} component={Paper}>
                            <Table size="small" stickyHeader>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell align="left">Nitrogenio</TableCell>
                                        <TableCell align="left">Fosforo</TableCell>
                                        <TableCell align="left">Potassio</TableCell>
                                        <TableCell align="left">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {npkRank.map((row, index) => (
                                        <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#fafafa' }}>
                                            <TableCell align="left">{row.nitrogenio}</TableCell>
                                            <TableCell align="left">{row.fosforo}</TableCell>
                                            <TableCell align="left">{row.potassio}</TableCell>
                                            <TableCell align="left">{row.count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    <Box mb={2}>
                        <Typography className={classes.typo}>
                            Portos mais utilizados
                        </Typography>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table size="small" stickyHeader>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell align="left">Porto</TableCell>
                                        <TableCell align="left">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {portosRank.map((row, index) => (
                                        <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#fafafa' }}>
                                            <TableCell align="left">{row.porto}</TableCell>
                                            <TableCell align="left">{row.count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    <Box mb={2}>
                        <Typography className={classes.typo}>
                            Micros mais utilizados
                        </Typography>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table size="small" stickyHeader>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell align="left">Materia</TableCell>
                                        <TableCell align="left">Quantidade</TableCell>
                                        <TableCell align="left">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {microRank.map((row, index) => (
                                        <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#fafafa' }}>
                                            <TableCell align="left">{row.materia}</TableCell>
                                            <TableCell align="left">{row.quantidade}</TableCell>
                                            <TableCell align="left">{row.count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>

                <Grid item xs={6} >
                    <Box mb={2}>
                        <Typography className={classes.typo}>
                            Usuários que mais utilizam
                        </Typography>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table size="small" stickyHeader>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell align="left">Usuário</TableCell>
                                        <TableCell align="left">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userRank.map((row, index) => (
                                        <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#fafafa' }}>
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="left">{row.count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    <Box mb={2}>
                        <Typography className={classes.typo}>
                            Matérias obrigatórias mais utilizadas
                        </Typography>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table size="small" stickyHeader>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell align="left">Matéria</TableCell>
                                        <TableCell align="left">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {prefsRank.map((row, index) => (
                                        <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#fafafa' }}>
                                            <TableCell align="left">{row.materia}</TableCell>
                                            <TableCell align="left">{row.count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>


                    <Box mb={2}>
                        <Typography className={classes.typo}>
                            Matérias Removidas
                        </Typography>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table size="small" stickyHeader>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell align="left">Materia</TableCell>
                                        <TableCell align="left">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {remRank.map((row, index) => (
                                        <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#fafafa' }}>
                                            <TableCell align="left">{row.materia}</TableCell>
                                            <TableCell align="left">{row.count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>


                </Grid>
            </Grid>
        </Grid >
    )
}

const useStyles = makeStyles({
    table: {
        maxHeight: 500
    },

    typo: {
        fontWeight: 'bold',
        fontFamily: 'Poppins'
    },

    button: {
        background: '#A3CC51',
        color: 'white',
        fontFamily: 'Poppins'
    },

    tableHead: {
        background: '#f5f5f5'
    },
    typographyTitle: {
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    typographyDescription: {
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: 14
    }
})