import api_amr from "../services/api_amr";


// Converte arquivos para base64
async function filesToBase64(filesArray) {
    var files = filesArray;
    var fileLocal;

    var aux = []

    for (var i = 0; i < files.length; i++) {

        fileLocal = files[i];
        let base64 = await toBase64(fileLocal)
        aux = [...aux, base64]
    }
    return aux
}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

// Valida a permissão do usuário pra acessar a pagina
async function checarPermissao(pagina, props) {
    let permission = ''
    const MAX_ATTEMPTS = 2
    
    for (let attempt = 0; attempt < MAX_ATTEMPTS; attempt++) {
        try {
            permission = await api_amr.get('/check_permission', {
                params: { 'page': pagina },
                headers: { 'x-api-key': getToken() },
                timeout: 5000
            })
            break
        }
        
        catch (err) {
            if (attempt === MAX_ATTEMPTS - 1) {
                if (err.response && err.response.status === 401) {
                    alert('Sessão expirada! Realize o Login novamente');
                    localStorage.clear();
                    props.history.push({ pathname: "/" });
                    return false
                } else  {
                    props.history.push({ pathname: "/pages/Manutencao" })
                    return false
                } 
            }

            await new Promise(resolve => setTimeout(resolve, 1000))
        }
    }
    
    if (permission.data === 1) {
        return true

    } else {

        if ((pagina !== 'tabela_de_precos')&(pagina !== 'andamento_mercado_amr')) {
            alert('Seu usuário não tem permissão para acessar essa página!')
            window.location.replace('/')
        }

        return false
    }
}

function verificaSenha(senha) {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;
    const passwordLength = senha.length;
    const uppercasePassword = uppercaseRegExp.test(senha);
    const lowercasePassword = lowercaseRegExp.test(senha);
    const digitsPassword = digitsRegExp.test(senha);
    const specialCharPassword = specialCharRegExp.test(senha);
    const minLengthPassword = minLengthRegExp.test(senha);
    let errMsg = [];
    if (passwordLength === 0) {
        errMsg.push("Vazio");
    }
    if (!uppercasePassword) {
        errMsg.push("Precisa ter uma letra maiúscula");
    }
    if (!lowercasePassword) {
        errMsg.push("Precisa ter uma letra minúscula");
    }
    if (!digitsPassword) {
        errMsg.push("Pelo menos um dígito");
    }
    if (!specialCharPassword) {
        errMsg.push("Pelo menos um caracter especial");
    }
    if (!minLengthPassword) {
        errMsg.push("Mínimo 8 caracteres");
    }
    return errMsg
}


function DataFormatoCerto(data) {
    return new Date(data).toISOString().split('T')[0]

}
function DataMaior(data1, data2) {
    //=======MODO de usar======
    //
    // if (DataMaior(dataInicio,dataFinal)){
    //     alert('Data inicial nao pode ser maior que data final')
    //     return
    // }
    return data1 > data2
}
function getUser() {
    const userString = localStorage.getItem('id')
    if (userString === null) {
        return ''
    } else {
        return userString
    }
}
function getId() {
    const userString = JSON.parse(localStorage.getItem('usuario')).id
    if (userString === null) {
        return ''
    } else {
        return userString
    }
}
function getToken() {
    const token = localStorage.getItem('token')
    if (token === null) {
        return ''
    } else {
        return token
    }
}

function getUserInfo() {
    const user = localStorage.getItem('usuario')
    if (user === null) {
        return ''
    } else {
        return user
    }
}

function formatStringUniversalDateToLocal(data) {
    let aux = data.split('-')
    return aux[2] + '-' + aux[1] + '-' + aux[0]
}
function validateCPF(data) {
    return data
}

function validateCNPJ(data) {
    return data
}
// teoricamente pega o ultimo link do sistema que o usuário acessou, mas você tem que setar esse link no localStorage
// então vamos tentar usar isso aqui só como um padrão caso precise salvar um link no localStorage
const getCachedLink = () => {
    const cachedLink = localStorage.getItem('cachedLink')
    if (cachedLink === null) {
        return ''
    } else {
        return cachedLink
    }
}

// deixa a primeira letra da uma string maiuscula
function capitalizeString(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


function validateInscEstadual(data) {
    return data
}

function validateCEP(data) {
    return data
}


export {
    filesToBase64,
    checarPermissao,
    getUser,
    getToken,
    formatStringUniversalDateToLocal,
    getUserInfo,
    verificaSenha,
    DataFormatoCerto,
    DataMaior,
    validateCPF,
    validateCNPJ,
    validateCEP,
    validateInscEstadual,
    getCachedLink,
    capitalizeString, getId
}


