import React, { useState, useEffect } from "react";
import navio_login from 'assets/img/navio_login.png';
import logo_amr from 'assets/img/logo_amr.png'
import "@fontsource/poppins";
import { makeStyles } from "@material-ui/core/styles";
import api_amr from "services/api_amr";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import { verificaSenha } from '../utils/utils' 

function Recovery(props) {

    const classes = useStyles();

    const [permitido, setPermitido] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [senha, setSenha] = useState('')
    const [senha2, setSenha2] = useState('')

    useEffect(() => {
        async function fetchMyAPI() {
            const response_verify = await api_amr.post('/verify_uuid', { 'uuid': props.match.params.uuid });
            
            if (response_verify.data === 'OK') {
                setPermitido(true)
            }
            else {
                alert("Link Invalido")
                
                window.location.replace(window.location.href.includes('http://localhost:3000/') ? 'http://localhost:3000' : 'https://plataformaamrbi.com')
            }
        }
        fetchMyAPI();
    }, []);

    async function Altera_senha(){
        if (senha !== senha2) {
            alert('Senhas diferentes')
            return
          }
          else {
            const erros = verificaSenha(senha)
            if (senha === ''){
              alert('insira uma senha ')
              return
            }
            else {
                const response = await api_amr.post('/changeSenha', { 'password': senha, 'uuid':props.match.params.uuid});
                if(response.data === 'OK'){
                    alert("Senha alterada com sucesso")
                    window.location.replace(window.location.href.includes('http://localhost:3000/') ? 'http://localhost:3000' : 'https://plataformaamrbi.com')
                }  
                else if(response.data === 'NF'){
                    alert("Você não tem permissão para fazer essa alteração")
                    window.location.replace(window.location.href.includes('http://localhost:3000/') ? 'http://localhost:3000' : 'https://plataformaamrbi.com')
                }  
                else{
                    alert('Ocorreu algum erro, entre em contato com o time de desenvolvimento')
                    window.location.replace(window.location.href.includes('http://localhost:3000/') ? 'http://localhost:3000' : 'https://plataformaamrbi.com')
                }              
              }
            }
    }

    return (
        permitido ? (
            <div className={classes.container}>
                <div className={classes.imgDiv}>
                    <img src={navio_login} alt="login" style={{ width: '100%' }} />
                </div>
                <div className={classes.loginDiv}>
                    <img src={logo_amr} alt="login" style={{ width: '35%', height: '7%', marginBottom:50 }} />
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        <InputBase
                            key='senha'
                            variant='outlined'
                            autoComplete="new-password"
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={senha}
                            placeholder='insira nova Senha'
                            onChange={(event) => setSenha(event.target.value)}
                            className={classes.autoCompleteField}
                            InputProps={{
                                disableUnderline: true,
                                classes: { notchedOutline: classes.noBorder }
                            }}
                            style={{ height: 50, paddingLeft: 15 }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(true)}
                                        onMouseDown={() => setShowPassword(false)}
                                    >
                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <InputBase
                            key='senha'
                            variant='outlined'
                            autoComplete="new-password"
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={senha2}
                            placeholder='Repita a Senha'
                            onChange={(event) => setSenha2(event.target.value)}
                            className={classes.autoCompleteField}
                            InputProps={{
                                disableUnderline: true,
                                classes: { notchedOutline: classes.noBorder }
                            }}
                            style={{ height: 50, paddingLeft: 15 }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(true)}
                                        onMouseDown={() => setShowPassword(false)}
                                    >
                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <div style={{justifyContent:'center', alignItens:'center', marginTop:40}}>
                            <Button onClick={() =>Altera_senha()} variant="contained" className={classes.calcButton}> Alterar Senha</Button>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'row'
    },
    imgDiv: {
        width: '50%',
        height: '100%'
    },
    loginDiv: {
        width: '50%',
        height: '100%',

        alignItems: 'center',
        justifyContent: 'center',

        display: 'flex',
        flexDirection: 'column'
    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    input: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px'
    },
    calcButton: {
        alignSelf:'center',
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
          background: "rgba(163, 204, 81, 0.7)",
        },
    
      },
      autoCompleteField: {
        marginTop: 15,
        minWidth: 228,
        maxWidth: 228,
    
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
      },
}));

export default Recovery;
