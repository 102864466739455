// App.js
import React, { useState, useEffect } from 'react';
import api from '../../services/api_amr';
import { makeStyles, TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, Tabs, Tab, Typography, Accordion, AccordionSummary, MenuItem, Select, InputLabel, FormControl, AccordionDetails, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Box, IconButton } from '@material-ui/core';
import { getToken, capitalizeString } from '../../utils/utils'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Slider from '@material-ui/core/Slider';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import EntregaFertilizantesCRUD from './EntregaFertilizantesCRUD'
import Autocomplete from '@material-ui/lab/Autocomplete';

// const EstadoCRUD = () => {
//   const [estados, setEstados] = useState([]);
//   const [novoEstado, setNovoEstado] = useState({ nome_estado: '', abreviacao_estado: '' });
//   const [editEstado, setEditEstado] = useState(null);

//   useEffect(() => {
//     const fetchEstados = async () => {
//       const response = await api.get('/read_estados', { headers: { 'x-api-key': getToken() } });
//       setEstados(response.data);
//     };
//     fetchEstados();
//   }, []);

//   const handleCreate = async () => {
//     await api.post('/create_estado', novoEstado, { headers: { 'x-api-key': getToken() } })
//     setNovoEstado({ nome_estado: '', abreviacao_estado: '' });
//     const response = await api.get('/read_estados', { headers: { 'x-api-key': getToken() } });
//     setEstados(response.data);
//   };

//   const handleUpdate = async () => {
//     await api.put('/update_estado', editEstado, { headers: { 'x-api-key': getToken() } })
//     setEditEstado(null);
//     const response = await api.get('/read_estados', { headers: { 'x-api-key': getToken() } });
//     setEstados(response.data);
//   };

//   const handleDelete = async (id) => {
//     await api.delete('/delete_estado', { data: { id_estado: id } });
//     const response = await api.get('/read_estados', { headers: { 'x-api-key': getToken() } });
//     setEstados(response.data);
//   };

//   return (
//     <Paper>
//       <h1>Gerenciar Estados</h1>
//       <TextField
//         label="Nome do Estado"
//         value={novoEstado.nome_estado}
//         onChange={(e) => setNovoEstado({ ...novoEstado, nome_estado: e.target.value })}
//       />
//       <TextField
//         label="Abreviação do Estado"
//         value={novoEstado.abreviacao_estado}
//         onChange={(e) => setNovoEstado({ ...novoEstado, abreviacao_estado: e.target.value })}
//       />
//       <Button onClick={handleCreate} variant="contained" color="primary">Adicionar Estado</Button>

//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>ID</TableCell>
//             <TableCell>Nome</TableCell>
//             <TableCell>Abreviação</TableCell>
//             <TableCell>Ações</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {estados.map((estado) => (
//             <TableRow key={estado.id_estado}>
//               <TableCell>{estado.id_estado}</TableCell>
//               <TableCell>
//                 {editEstado && editEstado.id_estado === estado.id_estado ? (
//                   <TextField
//                     value={editEstado.nome_estado}
//                     onChange={(e) => setEditEstado({ ...editEstado, nome_estado: e.target.value })}
//                   />
//                 ) : (
//                   estado.nome_estado
//                 )}
//               </TableCell>
//               <TableCell>
//                 {editEstado && editEstado.id_estado === estado.id_estado ? (
//                   <TextField
//                     value={editEstado.abreviacao_estado}
//                     onChange={(e) => setEditEstado({ ...editEstado, abreviacao_estado: e.target.value })}
//                   />
//                 ) : (
//                   estado.abreviacao_estado
//                 )}
//               </TableCell>
//               <TableCell>
//                 {editEstado && editEstado.id_estado === estado.id_estado ? (
//                   <Button onClick={handleUpdate} variant="contained" color="secondary">Salvar</Button>
//                 ) : (
//                   <Button onClick={() => setEditEstado(estado)} variant="contained" color="secondary">Editar</Button>
//                 )}
//                 <Button onClick={() => handleDelete(estado.id_estado)} variant="contained" color="secondary">Deletar</Button>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </Paper>
//   );
// };


// const AnosSafraCRUD = () => {
//   const [anosSafra, setAnosSafra] = useState([]);
//   const [novoAnoSafra, setNovoAnoSafra] = useState({ descricao: '' });
//   const [editAnoSafra, setEditAnoSafra] = useState(null);

//   useEffect(() => {
//     const fetchAnosSafra = async () => {
//       const response = await api.get('/read_anos_safra', { headers: { 'x-api-key': getToken() } });
//       setAnosSafra(response.data);
//     };
//     fetchAnosSafra();
//   }, []);

//   const handleCreate = async () => {
//     await api.post('/create_ano_safra', novoAnoSafra, { headers: { 'x-api-key': getToken() } });
//     setNovoAnoSafra({ descricao: '' });
//     const response = await api.get('/read_anos_safra', { headers: { 'x-api-key': getToken() } });
//     setAnosSafra(response.data);
//   };

//   const handleUpdate = async () => {
//     await api.put('/update_ano_safra', editAnoSafra, { headers: { 'x-api-key': getToken() } });
//     setEditAnoSafra(null);
//     const response = await api.get('/read_anos_safra', { headers: { 'x-api-key': getToken() } });
//     setAnosSafra(response.data);
//   };

//   const handleDelete = async (id) => {
//     await api.delete('/delete_ano_safra', { data: { id_ano_safra: id } });
//     const response = await api.get('/read_anos_safra', { headers: { 'x-api-key': getToken() } });
//     setAnosSafra(response.data);
//   };

//   return (
//     <Paper>
//       <h1>Gerenciar Anos Safra</h1>
//       <TextField
//         label="Descrição do Ano Safra"
//         value={novoAnoSafra.descricao}
//         onChange={(e) => setNovoAnoSafra({ ...novoAnoSafra, descricao: e.target.value })}
//       />
//       <Button onClick={handleCreate} variant="contained" color="primary">Adicionar Ano Safra</Button>

//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>ID</TableCell>
//             <TableCell>Descrição</TableCell>
//             <TableCell>Ações</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {anosSafra.map((anoSafra) => (
//             <TableRow key={anoSafra.id_ano_safra}>
//               <TableCell>{anoSafra.id_ano_safra}</TableCell>
//               <TableCell>
//                 {editAnoSafra && editAnoSafra.id_ano_safra === anoSafra.id_ano_safra ? (
//                   <TextField
//                     value={editAnoSafra.descricao}
//                     onChange={(e) => setEditAnoSafra({ ...editAnoSafra, descricao: e.target.value })}
//                   />
//                 ) : (
//                   anoSafra.descricao
//                 )}
//               </TableCell>
//               <TableCell>
//                 {editAnoSafra && editAnoSafra.id_ano_safra === anoSafra.id_ano_safra ? (
//                   <Button onClick={handleUpdate} variant="contained" color="secondary">Salvar</Button>
//                 ) : (
//                   <Button onClick={() => setEditAnoSafra(anoSafra)} variant="contained" color="secondary">Editar</Button>
//                 )}
//                 <Button onClick={() => handleDelete(anoSafra.id_ano_safra)} variant="contained" color="secondary">Deletar</Button>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </Paper>
//   );
// }

// const CulturaCRUD = () => {
//   const [culturas, setCulturas] = useState([]);
//   const [novaCultura, setNovaCultura] = useState({ nome_cultura: '' });
//   const [editCultura, setEditCultura] = useState(null);

//   useEffect(() => {
//     const fetchCulturas = async () => {
//       const response = await api.get('/read_culturas', { headers: { 'x-api-key': getToken() } });
//       setCulturas(response.data);
//     };
//     fetchCulturas();
//   }, []);

//   const handleCreate = async () => {
//     await api.post('/create_cultura', novaCultura, { headers: { 'x-api-key': getToken() } })
//     setNovaCultura({ nome_cultura: '' });
//     const response = await api.get('/read_culturas', { headers: { 'x-api-key': getToken() } });
//     setCulturas(response.data);
//   };

//   const handleUpdate = async () => {
//     await api.put('/update_cultura', editCultura, { headers: { 'x-api-key': getToken() } })
//     setEditCultura(null);
//     const response = await api.get('/read_culturas', { headers: { 'x-api-key': getToken() } });
//     setCulturas(response.data);
//   };

//   const handleDelete = async (id) => {
//     await api.delete('/delete_cultura', { data: { id_cultura: id } });
//     const response = await api.get('/read_culturas', { headers: { 'x-api-key': getToken() } });
//     setCulturas(response.data);
//   };

//   return (
//     <Paper>
//       <h1>Gerenciar Culturas</h1>
//       <TextField
//         label="Nome da Cultura"
//         value={novaCultura.nome_cultura}
//         onChange={(e) => setNovaCultura({ ...novaCultura, nome_cultura: e.target.value })}
//       />
//       <Button onClick={handleCreate} variant="contained" color="primary">Adicionar Cultura</Button>

//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>ID</TableCell>
//             <TableCell>Nome</TableCell>
//             <TableCell>Ações</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {culturas.map((cultura) => (
//             <TableRow key={cultura.id_cultura}>
//               <TableCell>{cultura.id_cultura}</TableCell>
//               <TableCell>
//                 {editCultura && editCultura.id_cultura === cultura.id_cultura ? (
//                   <TextField
//                     value={editCultura.nome_cultura}
//                     onChange={(e) => setEditCultura({ ...editCultura, nome_cultura: e.target.value })}
//                   />
//                 ) : (
//                   cultura.nome_cultura
//                 )}
//               </TableCell>
//               <TableCell>
//                 {editCultura && editCultura.id_cultura === cultura.id_cultura ? (
//                   <Button onClick={handleUpdate} variant="contained" color="secondary">Salvar</Button>
//                 ) : (
//                   <Button onClick={() => setEditCultura(cultura)} variant="contained" color="secondary">Editar</Button>
//                 )}
//                 <Button onClick={() => handleDelete(cultura.id_cultura)} variant="contained" color="secondary">Deletar</Button>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </Paper>
//   );
// };

const AreaTotalCRUD = () => {
  const [areasTotais, setAreasTotais] = useState([]);
  const [novaAreaTotal, setNovaAreaTotal] = useState({ id_estado: '', id_ano_safra: '', area_total_graos: '', area_total_cana_de_acucar: '', area_total_cafe: '' });
  const [editAreaTotal, setEditAreaTotal] = useState(null);

  useEffect(() => {
    const fetchAreasTotais = async () => {
      const response = await api.get('/read_areas_totais', { headers: { 'x-api-key': getToken() } });
      setAreasTotais(response.data);
    };
    fetchAreasTotais();
  }, []);

  const handleCreate = async () => {
    await api.post('/create_area_total', novaAreaTotal, { headers: { 'x-api-key': getToken() } });
    setNovaAreaTotal({ id_estado: '', id_ano_safra: '', area_total_graos: '', area_total_cana_de_acucar: '', area_total_cafe: '' });
    const response = await api.get('/read_areas_totais', { headers: { 'x-api-key': getToken() } });
    setAreasTotais(response.data);
  };

  const handleUpdate = async () => {
    await api.put('/update_area_total', editAreaTotal, { headers: { 'x-api-key': getToken() } });
    setEditAreaTotal(null);
    const response = await api.get('/read_areas_totais', { headers: { 'x-api-key': getToken() } });
    setAreasTotais(response.data);
  };

  const handleDelete = async (id) => {
    await api.delete('/delete_area_total', { data: { id_area: id } });
    const response = await api.get('/read_areas_totais', { headers: { 'x-api-key': getToken() } });
    setAreasTotais(response.data);
  };

  return (
    <Paper>
      <h1>Gerenciar Áreas Totais</h1>
      <TextField
        label="ID do Estado"
        value={novaAreaTotal.id_estado}
        onChange={(e) => setNovaAreaTotal({ ...novaAreaTotal, id_estado: e.target.value })}
      />
      <TextField
        label="ID do Ano Safra"
        value={novaAreaTotal.id_ano_safra}
        onChange={(e) => setNovaAreaTotal({ ...novaAreaTotal, id_ano_safra: e.target.value })}
      />
      <TextField
        label="Área Total de Grãos"
        value={novaAreaTotal.area_total_graos}
        onChange={(e) => setNovaAreaTotal({ ...novaAreaTotal, area_total_graos: e.target.value })}
      />
      <TextField
        label="Área Total de Cana-de-Açúcar"
        value={novaAreaTotal.area_total_cana_de_acucar}
        onChange={(e) => setNovaAreaTotal({ ...novaAreaTotal, area_total_cana_de_acucar: e.target.value })}
      />
      <TextField
        label="Área Total de Café"
        value={novaAreaTotal.area_total_cafe}
        onChange={(e) => setNovaAreaTotal({ ...novaAreaTotal, area_total_cafe: e.target.value })}
      />
      <Button onClick={handleCreate} variant="contained" color="primary">Adicionar Área Total</Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>ID Estado</TableCell>
            <TableCell>ID Ano Safra</TableCell>
            <TableCell>Área Total Grãos</TableCell>
            <TableCell>Área Total Cana-de-Açúcar</TableCell>
            <TableCell>Área Total Café</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {areasTotais.map((area) => (
            <TableRow key={area.id_area}>
              <TableCell>{area.id_area}</TableCell>
              <TableCell>
                {editAreaTotal && editAreaTotal.id_area === area.id_area ? (
                  <TextField
                    value={editAreaTotal.id_estado}
                    onChange={(e) => setEditAreaTotal({ ...editAreaTotal, id_estado: e.target.value })}
                  />
                ) : (
                  area.id_estado
                )}
              </TableCell>
              <TableCell>
                {editAreaTotal && editAreaTotal.id_area === area.id_area ? (
                  <TextField
                    value={editAreaTotal.id_ano_safra}
                    onChange={(e) => setEditAreaTotal({ ...editAreaTotal, id_ano_safra: e.target.value })}
                  />
                ) : (
                  area.id_ano_safra
                )}
              </TableCell>
              <TableCell>
                {editAreaTotal && editAreaTotal.id_area === area.id_area ? (
                  <TextField
                    value={editAreaTotal.area_total_graos}
                    onChange={(e) => setEditAreaTotal({ ...editAreaTotal, area_total_graos: e.target.value })}
                  />
                ) : (
                  area.area_total_graos
                )}
              </TableCell>
              <TableCell>
                {editAreaTotal && editAreaTotal.id_area === area.id_area ? (
                  <TextField
                    value={editAreaTotal.area_total_cana_de_acucar}
                    onChange={(e) => setEditAreaTotal({ ...editAreaTotal, area_total_cana_de_acucar: e.target.value })}
                  />
                ) : (
                  area.area_total_cana_de_acucar
                )}
              </TableCell>
              <TableCell>
                {editAreaTotal && editAreaTotal.id_area === area.id_area ? (
                  <TextField
                    value={editAreaTotal.area_total_cafe}
                    onChange={(e) => setEditAreaTotal({ ...editAreaTotal, area_total_cafe: e.target.value })}
                  />
                ) : (
                  area.area_total_cafe
                )}
              </TableCell>
              <TableCell>
                {editAreaTotal && editAreaTotal.id_area === area.id_area ? (
                  <Button onClick={handleUpdate} variant="contained" color="secondary">Salvar</Button>
                ) : (
                  <Button onClick={() => setEditAreaTotal(area)} variant="contained" color="secondary">Editar</Button>
                )}
                <Button onClick={() => handleDelete(area.id_area)} variant="contained" color="secondary">Deletar</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

const AreaTotalPorCulturaCRUD = () => {
  const [areasPorCultura, setAreasPorCultura] = useState([]);
  const [novaAreaPorCultura, setNovaAreaPorCultura] = useState({ id_estado: '', id_cultura: '', id_ano_safra: '', area_por_cultura: '' });
  const [editAreaPorCultura, setEditAreaPorCultura] = useState(null);

  useEffect(() => {
    const fetchAreasPorCultura = async () => {
      const response = await api.get('/read_areas_totais_por_cultura', { headers: { 'x-api-key': getToken() } });
      setAreasPorCultura(response.data);
    };
    fetchAreasPorCultura();
  }, []);

  const handleCreate = async () => {
    await api.post('/create_area_total_por_cultura', novaAreaPorCultura, { headers: { 'x-api-key': getToken() } });
    setNovaAreaPorCultura({ id_estado: '', id_cultura: '', id_ano_safra: '', area_por_cultura: '' });
    const response = await api.get('/read_areas_totais_por_cultura', { headers: { 'x-api-key': getToken() } });
    setAreasPorCultura(response.data);
  };

  const handleUpdate = async () => {
    await api.put('/update_area_total_por_cultura', editAreaPorCultura, { headers: { 'x-api-key': getToken() } });
    setEditAreaPorCultura(null);
    const response = await api.get('/read_areas_totais_por_cultura', { headers: { 'x-api-key': getToken() } });
    setAreasPorCultura(response.data);
  };

  const handleDelete = async (id) => {
    await api.delete('/delete_area_total_por_cultura', { data: { id_area_cultura: id } });
    const response = await api.get('/read_areas_totais_por_cultura', { headers: { 'x-api-key': getToken() } });
    setAreasPorCultura(response.data);
  };

  return (
    <Paper>
      <h1>Gerenciar Áreas Totais por Cultura</h1>
      <TextField
        label="ID do Estado"
        value={novaAreaPorCultura.id_estado}
        onChange={(e) => setNovaAreaPorCultura({ ...novaAreaPorCultura, id_estado: e.target.value })}
      />
      <TextField
        label="ID da Cultura"
        value={novaAreaPorCultura.id_cultura}
        onChange={(e) => setNovaAreaPorCultura({ ...novaAreaPorCultura, id_cultura: e.target.value })}
      />
      <TextField
        label="ID do Ano Safra"
        value={novaAreaPorCultura.id_ano_safra}
        onChange={(e) => setNovaAreaPorCultura({ ...novaAreaPorCultura, id_ano_safra: e.target.value })}
      />
      <TextField
        label="Área por Cultura"
        value={novaAreaPorCultura.area_por_cultura}
        onChange={(e) => setNovaAreaPorCultura({ ...novaAreaPorCultura, area_por_cultura: e.target.value })}
      />
      <Button onClick={handleCreate} variant="contained" color="primary">Adicionar Área por Cultura</Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>ID Estado</TableCell>
            <TableCell>ID Cultura</TableCell>
            <TableCell>ID Ano Safra</TableCell>
            <TableCell>Área por Cultura</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {areasPorCultura.map((area) => (
            <TableRow key={area.id_area_cultura}>
              <TableCell>{area.id_area_cultura}</TableCell>
              <TableCell>
                {editAreaPorCultura && editAreaPorCultura.id_area_cultura === area.id_area_cultura ? (
                  <TextField
                    value={editAreaPorCultura.id_estado}
                    onChange={(e) => setEditAreaPorCultura({ ...editAreaPorCultura, id_estado: e.target.value })}
                  />
                ) : (
                  area.id_estado
                )}
              </TableCell>
              <TableCell>
                {editAreaPorCultura && editAreaPorCultura.id_area_cultura === area.id_area_cultura ? (
                  <TextField
                    value={editAreaPorCultura.id_cultura}
                    onChange={(e) => setEditAreaPorCultura({ ...editAreaPorCultura, id_cultura: e.target.value })}
                  />
                ) : (
                  area.id_cultura
                )}
              </TableCell>
              <TableCell>
                {editAreaPorCultura && editAreaPorCultura.id_area_cultura === area.id_area_cultura ? (
                  <TextField
                    value={editAreaPorCultura.id_ano_safra}
                    onChange={(e) => setEditAreaPorCultura({ ...editAreaPorCultura, id_ano_safra: e.target.value })}
                  />
                ) : (
                  area.id_ano_safra
                )}
              </TableCell>
              <TableCell>
                {editAreaPorCultura && editAreaPorCultura.id_area_cultura === area.id_area_cultura ? (
                  <TextField
                    value={editAreaPorCultura.area_por_cultura}
                    onChange={(e) => setEditAreaPorCultura({ ...editAreaPorCultura, area_por_cultura: e.target.value })}
                  />
                ) : (
                  area.area_por_cultura
                )}
              </TableCell>
              <TableCell>
                {editAreaPorCultura && editAreaPorCultura.id_area_cultura === area.id_area_cultura ? (
                  <Button onClick={handleUpdate} variant="contained" color="secondary">Salvar</Button>
                ) : (
                  <Button onClick={() => setEditAreaPorCultura(area)} variant="contained" color="secondary">Editar</Button>
                )}
                <Button onClick={() => handleDelete(area.id_area_cultura)} variant="contained" color="secondary">Deletar</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}



function CRUDMonitorBrasil() {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div>
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="simple tabs example">
        {/* <Tab label="Estados" /> */}
        {/* <Tab label="Culturas" /> */}
        {/* <Tab label="Anos Safra" /> */}
        <Tab label="Entrega de Fertilizantes" />

        <Tab label="Área Total" />
        <Tab label="Área por Cultura" />
      </Tabs>
      {/* {tabIndex === 0 && <EstadoCRUD />} */}
      {/* {tabIndex === 1 && <CulturaCRUD />} */}
      {/* {tabIndex === 2 && <AnosSafraCRUD />} */}
      {tabIndex === 0 && <EntregaFertilizantesCRUD />}

      {tabIndex === 1 && <AreaTotalCRUD />}
      {tabIndex === 2 && <AreaTotalPorCulturaCRUD />}


      {/* Adicione as outras abas aqui */}
    </div>
  );
}

export default CRUDMonitorBrasil;
