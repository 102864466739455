import React, { useEffect, useState } from "react";
import { getToken } from '../../utils/utils';
import api_amr from '../../services/api_amr';
import { Autocomplete } from "@material-ui/lab";
import { TextField, Button, Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Dialog, DialogActions, DialogContent, DialogTitle, TableSortLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
    card: {
        minWidth: 275,
        maxWidth: 1200,
        padding: theme.spacing(2),
        boxShadow: theme.shadows[3],
    },
    formField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    dialog: {
        padding: theme.spacing(2),
    },
    largeObservationField: {
        width: '100%',
        height: '100px',
    },
    filterField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
}));

function ConfigDemandas(props) {
    const [demandas, setDemandas] = useState([]);
    const [editDemanda, setEditDemanda] = useState(null);
    const [fazendas, setFazendas] = useState([]);
    const [materias, setMaterias] = useState([]);
    const [culturas, setCulturas] = useState([]);
    const [open, setOpen] = useState(false);
    const [anosSafra, setAnosSafra] = useState([]);
    const [nomesMaterias, setNomeMaterias] = useState([])
    const [orderBy, setOrderBy] = useState('');
    const [orderDirection, setOrderDirection] = useState('asc');
    const [escolhaMaterias, setEscolhaMaterias] = useState(true)//True é para matérias primas que já existem, false mostra as cadastradas manualmente com campo aberto
    const [filters, setFilters] = useState({
        search: '',
        cultura: '',
        associado: '',
        produto: 0,
        status: '',
        anoSafra: '',
        nome_materia: '',
        id_fechamento:0
    });
    const classes = useStyles();

    async function fetchDemandas() {
        const response = await api_amr.get('/get_demandas', { headers: { 'x-api-key': getToken() } });
        setDemandas(response.data);

    }
    useEffect(() => {
        fetchDemandas();
    }, []);

    useEffect(() => {
        async function fetchFazendas() {
            const response = await api_amr.get('/get_farms', { headers: { 'x-api-key': getToken() } });
            setFazendas(response.data);
        }
        fetchFazendas();
    }, []);

    useEffect(() => {
        async function fetchMaterias() {
            const response_materias = await api_amr.get('/get_materias', { headers: { 'x-api-key': getToken() } });
            setMaterias(response_materias.data);
        }
        fetchMaterias();
    }, []);

    useEffect(() => {
        async function fetchCulturas() {
            const response_culturas = await api_amr.get('/get_culturas', { headers: { 'x-api-key': getToken() } });
            setCulturas(response_culturas.data);
        }
        fetchCulturas();
    }, []);

    useEffect(() => {
        async function fetchAnosSafra() {
            const response_AnosSafra = await api_amr.get('/read_anos_safra', { headers: { 'x-api-key': getToken() } });
            setAnosSafra(response_AnosSafra.data);
        }
        fetchAnosSafra();
    }, []);
    async function fetchNomeMaterias() {
        const response_NomeMaterias = await api_amr.get('/get_nomes_materias', { headers: { 'x-api-key': getToken() } });
        setNomeMaterias(response_NomeMaterias.data);
    }
    useEffect(() => {
       
        fetchNomeMaterias();
    }, []);

    function handleInputChange(event) {
        const { name, value } = event.target;
        setEditDemanda({ ...editDemanda, [name]: value });
    }

    const handleStatusChange = (event, newValue) => {
        setEditDemanda({ ...editDemanda, status: newValue });
    };

    const handleTipoChange = (event, newValue) => {
        setEditDemanda({ ...editDemanda, tipo_compra: newValue });
    };

    function handleAutocompleteChangeCultura(event, newValue) {
        setEditDemanda({ ...editDemanda, id_cultura: newValue ? newValue.id_cultura : '', nome_cultura: newValue ? newValue.nome_cultura : '' });
    }

    function handleMateriaChange(event, newValue) {
        setEditDemanda({ ...editDemanda, id_materia: newValue ? newValue.id_materias : '', produto: newValue ? newValue.abreviacao : '' });
    }
    const handleNomeMateriaChange = (event, newValue) => {
        console.log(newValue);

        // Determine if newValue is a string or an object
        const selectedValue = typeof newValue === 'string' ? newValue : newValue ? newValue.nome_materia : '';

        setEditDemanda({
            ...editDemanda,
            produto: selectedValue, // Use the determined value
            id_materia:0,
            nome_materia: selectedValue, // Use the determined value
            id_fechamento: newValue.id_formulacao,
        });
        
    };

    function formatDate(date) {
        if (!date) return '';
        const d = new Date(date);
        const month = `0${d.getMonth() + 1}`.slice(-2);
        const day = `0${d.getDate()}`.slice(-2);
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
    }

    function adjustDateToLocal(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        return localDate.toISOString().split('T')[0];
    }

    async function handleDeleteDemanda() {
        try {
            const response = await api_amr.post('/delete_demanda', editDemanda, { headers: { 'x-api-key': getToken() } });
            if (response.data === 'OK') {
                alert('Demanda atualizada com sucesso');
                fetchDemandas(); // Atualizar a lista de demandas após a edição
                handleClose(); // Fechar o modal após a edição
            } else {
                console.error('Erro ao atualizar demanda:', response.data);
            }
        } catch (error) {
            console.error('Erro ao atualizar demanda:', error);
        }
    }
    async function handleUpdateDemanda() {
        try {
            const response = await api_amr.post('/update_demanda', editDemanda, { headers: { 'x-api-key': getToken() } });
            if (response.data === 'OK') {
                alert('Demanda atualizada com sucesso');
                fetchNomeMaterias()
                fetchDemandas()
                setEscolhaMaterias(true)
                handleClose(); // Fechar o modal após a edição
            } else {
                console.error('Erro ao atualizar demanda:', response.data);
            }
        } catch (error) {
            console.error('Erro ao atualizar demanda:', error);
        }
    }

    const handleClickOpen = (demanda) => {
        setEditDemanda({
            ...demanda,
            data_entrega: adjustDateToLocal(demanda.data_entrega),
            data_abertura: adjustDateToLocal(demanda.data_abertura)
        });
        setEscolhaMaterias(demanda.id_materia != 0)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditDemanda(null);
    };

    const handleSort = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleFilterChange = (event, value, name) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value || '',  // Ensure the filter value is a string
        }));
    };

    const filteredDemandas = demandas.filter((demanda) => {
        const searchTerm = filters.search.toLowerCase();

        return (
            (demanda.nome_fazenda.toLowerCase().includes(searchTerm) ||
                demanda.name_associado.toLowerCase().includes(searchTerm) ||
                demanda.produto.toLowerCase().includes(searchTerm) ||
                demanda.quantidade.toString().toLowerCase().includes(searchTerm) ||
                demanda.nome_cultura.toLowerCase().includes(searchTerm) ||
                demanda.ano_safra.toLowerCase().includes(searchTerm) ||
                demanda.status.toLowerCase().includes(searchTerm) ||
                demanda.preco_total_pago.toString().toLowerCase().includes(searchTerm) ||
                demanda.fornecedor.toLowerCase().includes(searchTerm) ||
                demanda.obs.toLowerCase().includes(searchTerm)) &&
            (!filters.cultura || demanda.nome_cultura === filters.cultura) &&
            (!filters.associado || demanda.name_associado === filters.associado) &&
            (!filters.produto || demanda.produto === filters.produto) &&
            (!filters.status || demanda.status === filters.status) &&
            (!filters.anoSafra || demanda.ano_safra === filters.anoSafra)
        );
    });

    const sortedDemandas = filteredDemandas.sort((a, b) => {
        if (orderBy) {
            if (a[orderBy] < b[orderBy]) return orderDirection === 'asc' ? -1 : 1;
            if (a[orderBy] > b[orderBy]) return orderDirection === 'asc' ? 1 : -1;
            return 0;
        }
        return demandas;
    });

    return (
        <div className={classes.container}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Configurar Demandas
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Autocomplete
                                options={culturas}
                                getOptionLabel={(option) => option.nome_cultura}
                                onChange={(event, value) => handleFilterChange(event, value?.nome_cultura, 'cultura')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Cultura"
                                        variant="outlined"
                                        size="small"
                                        className={classes.filterField}
                                    />
                                )}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Autocomplete
                                options={fazendas}
                                getOptionLabel={(option) => option.name_associado}
                                onChange={(event, value) => handleFilterChange(event, value?.name_associado, 'associado')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Associado"
                                        variant="outlined"
                                        size="small"
                                        className={classes.filterField}
                                    />
                                )}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Autocomplete
                                options={materias}
                                getOptionLabel={(option) => option.abreviacao}
                                onChange={(event, value) => handleFilterChange(event, value?.abreviacao, 'produto')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Produto"
                                        variant="outlined"
                                        size="small"
                                        className={classes.filterField}
                                    />
                                )}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Autocomplete
                                options={['Aberto', 'Fechado']}
                                getOptionLabel={(option) => option}
                                onChange={(event, value) => handleFilterChange(event, value, 'status')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Status"
                                        variant="outlined"
                                        size="small"
                                        className={classes.filterField}
                                    />
                                )}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Autocomplete
                                options={anosSafra}
                                getOptionLabel={(option) => option.descricao}
                                onChange={(event, value) => handleFilterChange(event, value?.descricao, 'anoSafra')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Ano Safra"
                                        variant="outlined"
                                        size="small"
                                        className={classes.filterField}
                                    />
                                )}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label="Filtrar"
                                name="search"
                                variant="outlined"
                                size="small"
                                onChange={(event) => handleFilterChange(event, event.target.value, 'search')}
                                className={classes.filterField}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'nome_fazenda'}
                                            direction={orderBy === 'nome_fazenda' ? orderDirection : 'asc'}
                                            onClick={() => handleSort('nome_fazenda')}
                                        >
                                            Nome Fazenda
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'name_associado'}
                                            direction={orderBy === 'name_associado' ? orderDirection : 'asc'}
                                            onClick={() => handleSort('name_associado')}
                                        >
                                            Associado
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'produto'}
                                            direction={orderBy === 'produto' ? orderDirection : 'asc'}
                                            onClick={() => handleSort('produto')}
                                        >
                                            Produto
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'quantidade'}
                                            direction={orderBy === 'quantidade' ? orderDirection : 'asc'}
                                            onClick={() => handleSort('quantidade')}
                                        >
                                            Quantidade
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'nome_cultura'}
                                            direction={orderBy === 'nome_cultura' ? orderDirection : 'asc'}
                                            onClick={() => handleSort('nome_cultura')}
                                        >
                                            Cultura
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'ano_safra'}
                                            direction={orderBy === 'ano_safra' ? orderDirection : 'asc'}
                                            onClick={() => handleSort('ano_safra')}
                                        >
                                            Ano Safra
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'status'}
                                            direction={orderBy === 'status' ? orderDirection : 'asc'}
                                            onClick={() => handleSort('status')}
                                        >
                                            Status
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'tipo_compra'}
                                            direction={orderBy === 'tipo_compra' ? orderDirection : 'asc'}
                                            onClick={() => handleSort('tipo_compra')}
                                        >
                                            Tipo Compra
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'preco_total_pago'}
                                            direction={orderBy === 'preco_total_pago' ? orderDirection : 'asc'}
                                            onClick={() => handleSort('preco_total_pago')}
                                        >
                                            Preço Total Pago
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'fornecedor'}
                                            direction={orderBy === 'fornecedor' ? orderDirection : 'asc'}
                                            onClick={() => handleSort('fornecedor')}
                                        >
                                            Fornecedor
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedDemandas.map((demanda) => (
                                    <TableRow key={demanda.id_demanda}>
                                        <TableCell>{demanda.nome_fazenda}</TableCell>
                                        <TableCell>{demanda.name_associado}-{demanda.name}</TableCell>
                                        <TableCell>{demanda.produto}</TableCell>
                                        <TableCell>{demanda.quantidade}</TableCell>
                                        <TableCell>{demanda.nome_cultura}</TableCell>
                                        <TableCell>{demanda.ano_safra}</TableCell>
                                        <TableCell>{demanda.status}</TableCell>

                                        <TableCell>{demanda.tipo_compra}</TableCell>

                                        <TableCell>{demanda.preco_total_pago}</TableCell>
                                        <TableCell>{demanda.fornecedor}</TableCell>

                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleClickOpen(demanda)}
                                            >
                                                Editar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            <Dialog open={open} onClose={handleClose} className={classes.dialog} fullWidth maxWidth="md">
                <DialogTitle>Editar Demanda</DialogTitle>
                <DialogContent>
                    {editDemanda && (
                        <Grid container spacing={3}>
                            <Checkbox checked={escolhaMaterias} onChange={() => setEscolhaMaterias(!escolhaMaterias)} />
                            {escolhaMaterias ? <h3>Matérias plataforma</h3> : <h3>Outras Matérias</h3>}
                            {escolhaMaterias == true ? <Grid item xs={12}>

                                <Autocomplete
                                    options={materias}
                                    getOptionLabel={(option) => option.abreviacao}
                                    value={{ abreviacao: editDemanda.produto }}
                                    onChange={(event, newValue) => handleMateriaChange(event, newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Selecionar Produto"
                                            variant="outlined"
                                            className={classes.formField}
                                            size="small"
                                        />
                                    )}
                                />
                            </Grid> :
                                <Grid item xs={12}>
                                    <Autocomplete
                                        freeSolo
                                        options={nomesMaterias}
                                        getOptionLabel={(option) => option.nome_materia || ''}
                                        getOptionSelected={(option, value) => option.nome_materia === value.nome_materia}
                                        value={nomesMaterias.find((opt) => opt.nome_materia === editDemanda.produto) || { nome_materia: editDemanda.produto }}
                                        onInputChange={handleNomeMateriaChange}
                                        onChange={handleNomeMateriaChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Selecionar Produto"
                                                variant="outlined"
                                                className={classes.formField}
                                                size="small"
                                            />
                                        )}
                                    />
                                </Grid>}

                            <Grid item xs={12}>
                                <Autocomplete
                                    options={culturas}
                                    getOptionLabel={(option) => option.nome_cultura}
                                    value={{ nome_cultura: editDemanda.nome_cultura }}
                                    onChange={(event, newValue) => handleAutocompleteChangeCultura(event, newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Selecionar Cultura"
                                            variant="outlined"
                                            className={classes.formField}
                                            size="small"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Quantidade (tons)"
                                    name="quantidade"
                                    variant="outlined"
                                    className={classes.formField}
                                    value={editDemanda.quantidade}
                                    onChange={handleInputChange}
                                    size="small"
                                    fullWidth
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={['Aberto', 'Fechado']}
                                    getOptionLabel={(option) => option}
                                    value={editDemanda.status}
                                    onChange={handleStatusChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Status"
                                            name="status"
                                            variant="outlined"
                                            className={classes.formField}
                                            size="small"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Preço Total Pago"
                                    name="preco_total_pago"
                                    variant="outlined"
                                    className={classes.formField}
                                    value={editDemanda.preco_total_pago}
                                    onChange={handleInputChange}
                                    size="small"
                                    fullWidth
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Cotação Dólar (data compra)"
                                    name="cotacao_dolar"
                                    variant="outlined"
                                    className={classes.formField}
                                    value={editDemanda.cotacao_dolar}
                                    onChange={handleInputChange}
                                    size="small"
                                    fullWidth
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Fornecedor"
                                    name="fornecedor"
                                    variant="outlined"
                                    className={classes.formField}
                                    value={editDemanda.fornecedor}
                                    onChange={handleInputChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Observações"
                                    name="obs"
                                    variant="outlined"
                                    className={`${classes.formField} ${classes.largeObservationField}`}
                                    value={editDemanda.obs}
                                    onChange={handleInputChange}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Data de Entrega"
                                    name="data_entrega"
                                    type="date"
                                    variant="outlined"
                                    className={classes.formField}
                                    value={editDemanda.data_entrega}
                                    onChange={handleInputChange}
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Tipo Compra"
                                    name="tipo_compra"

                                    variant="outlined"
                                    className={classes.formField}
                                    value={editDemanda.tipo_compra}
                                    onChange={handleInputChange}
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Data de Abertura da Demanda"
                                    name="data_abertura"
                                    type="date"
                                    variant="outlined"
                                    className={classes.formField}
                                    value={editDemanda.data_abertura}
                                    onChange={handleInputChange}
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleUpdateDemanda} color="primary">
                        Atualizar
                    </Button>
                    <Box flexGrow={1} />
                    <Button onClick={handleDeleteDemanda} color="secondary">
                        Deletar demanda
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ConfigDemandas;
