import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getToken, checarPermissao } from '../../utils/utils';
import api_amr from '../../services/api_amr';
import Loader from 'react-loader-spinner';
import { TextField } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

function ConfigGrupos(props) {
    const [grupos, setGrupos] = useState('');
    const [permitido, setPermitido] = useState(false);
    const [nomeGrupo, setNomeGrupo] = useState('');
    const [update, setUpdate] = useState([]);
    const [salvando, setSalvando] = useState(false);
    const [associado, setAssociado] = useState('');
    const [associados, setAssociados] = useState('');

    const [data_contrato, setDataContrato] = useState('');

    const classes = useStyles();

    useEffect(() => {
        async function fetchMyAPI() {
            setPermitido(await checarPermissao('configuracoes_grupos', props));
            getPermissoesGrupos();
        }
        fetchMyAPI();
    }, []);

    async function cadastraGrupo() {
        if ((nomeGrupo === '')) {
            alert('Alguns campos obrigatórios estão incompletos\n' + (nomeGrupo === '' ? 'Nome\n' : ''));
            return;
        }
        const response_create = await api_amr.post('/create_group',
            {
                'nome': nomeGrupo
            }, { headers: { 'x-api-key': getToken() } }
        );
        if (response_create.data === 'OK') {
            alert('Grupo adicionado com sucesso!');
            window.location.reload();
        }
    }

    async function cadastraAssociado() {
        if ((associado === '')) {
            alert('Alguns campos obrigatórios estão incompletos\n' + (associado === '' ? 'Nome\n' : ''));
            return;
        }
        const response_create = await api_amr.post('/create_associado',
            {
                'name_associado': associado,
                'data_contrato': data_contrato
            }, { headers: { 'x-api-key': getToken() } }
        );
        if (response_create.data === 'OK') {
            alert('Associado adicionado com sucesso!');
            window.location.reload();
        }
    }

    async function getPermissoesGrupos() {
        const response_permissoes = await api_amr.get('/return_group_permissions', { headers: { 'x-api-key': getToken() } });
        setGrupos(response_permissoes.data);

        const response_associados = await api_amr.get('/get_associados', { headers: { 'x-api-key': getToken() } });
        setAssociados(response_associados.data);
        console.log(response_associados.data);
    }

    function montaUpdate(valor, idGrupo, target) {
        let aux = update;
        aux[idGrupo + target] = idGrupo + "_*_" + target + "_*_" + valor + ';';
        setUpdate(aux);
    }

    async function salvarUpdate() {
        setSalvando(true);
        let stringUpdate = '';

        Object.keys(update).forEach((key) => stringUpdate += update[key]);

        if (stringUpdate === '') {
            alert('Faça alguma modificação antes de salvar...');
        } else {
            const resultado = await api_amr.post('update_permissoes', {
                stringUpdate,
            }, { headers: { 'x-api-key': getToken() } });
            if (resultado.data === 'OK') {
                alert('Permissões atualizadas com sucesso');
            }
        }
        setSalvando(false);
    }

    async function onDelete(id) {
        setSalvando(true);
        const resultado = await api_amr.post('delete_associado', {
            id,
        }, { headers: { 'x-api-key': getToken() } });
        setSalvando(false);
    }

    async function onDefensivosChange(id, checked) {
        const response_update = await api_amr.post('/update_associado',
            {
                'id_associado': id,
                'defensivos': checked ? 1 : 0
            }, { headers: { 'x-api-key': getToken() } }
        );
        if (response_update.data !== 'OK') {
            alert('Erro ao atualizar o campo Defensivos.');
        } else {


            const response_associados = await api_amr.get('/get_associados', { headers: { 'x-api-key': getToken() } });
            setAssociados(response_associados.data);

        }
    }

    return (
        permitido ?
            <div className={classes.container} >
                <div >
                    <h2 className={classes.inputh1}>Configurações Grupos</h2>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '90vw' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 300 }}>
                        <b className={classes.inputh1}>Cadastro</b>
                        <div>
                            <TextField
                                key='nome'
                                label="Nome Grupo*"
                                variant='outlined'
                                onChange={(event) => setNomeGrupo(event.target.value)}
                                value={nomeGrupo}
                                className={classes.autoCompleteFieldBig}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: { notchedOutline: classes.noBorder }
                                }}
                            />
                        </div>

                        <div className={classes.calcDiv}>
                            <Button onClick={() => cadastraGrupo()} variant="contained" className={classes.calcButton}> Cadastrar grupo</Button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                        {grupos !== '' ? <TableContainer className={classes.tableModels}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={100} style={{ position: 'sticky', background: '#A3CC51', color: 'white', left: 0, top: 0, zIndex: 3 }}>
                                            <b className={classes.inputh1}>Nome Grupo</b>
                                        </TableCell>
                                        {Object.keys(grupos[0]).map((item, index) =>
                                            ['name', 'id_permissoes', 'id', 'group_id'].includes(item) ? null :
                                                <TableCell width={100} key={item}>
                                                    <b className={classes.inputh1}>{item}</b>
                                                </TableCell>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(grupos).map((item, index) => (
                                        <TableRow key={index} >
                                            <TableCell style={{ position: 'sticky', background: '#A3CC51', color: 'white', left: 0, top: 0, zIndex: 3 }}>
                                                <b className={classes.inputh1}>{grupos[item].name}</b>
                                            </TableCell>
                                            {Object.keys(grupos[item]).map((item_elemnt, index_element) =>
                                                ['name', 'id_permissoes', 'id', 'group_id'].includes(item_elemnt) ? null :
                                                    <TableCell width={100} key={item_elemnt}>
                                                        <Checkbox
                                                            defaultChecked={grupos[item][item_elemnt] === 1 ? true : false}
                                                            onChange={(e) => montaUpdate(e.target.checked, grupos[item].id_permissoes, item_elemnt)}
                                                        />
                                                    </TableCell>)}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> : <CircularProgress />}
                        <div className={classes.modDiv}>
                            <Button onClick={() => salvarUpdate()} variant="contained" className={classes.calcButton}> Salvar modificações</Button>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 className={classes.inputh1}>Configurações Associados</h2>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '90vw' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 300 }}>
                        <b className={classes.inputh1}>Cadastro</b>
                        <div>
                            <TextField
                                key='nome'
                                label="Nome Associado*"
                                variant='outlined'
                                onChange={(event) => setAssociado(event.target.value)}
                                value={associado}
                                className={classes.autoCompleteFieldBig}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: { notchedOutline: classes.noBorder }
                                }}
                            />
                        </div>
                        <div>
                            <TextField
                                type="date"
                                label="Data Contrato"
                                variant="outlined"
                                value={data_contrato}
                                onChange={(event) => setDataContrato(event.target.value)}
                                className={classes.autoCompleteFieldBig}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: { notchedOutline: classes.noBorder }
                                }}
                            />
                        </div>
                        <div className={classes.calcDiv}>
                            <Button onClick={() => cadastraAssociado()} variant="contained" className={classes.calcButton}> Cadastrar Associado</Button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                        {associados !== '' ? <TableContainer className={classes.tableModels}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Data Contrato</TableCell>
                                        <TableCell>Defensivos</TableCell>
                                        <TableCell>Deletar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {associados.map((associado) => (
                                        <TableRow key={associado.id_associado}>
                                            <TableCell>{associado.id_associado}</TableCell>
                                            <TableCell>{associado.name_associado}</TableCell>
                                            <TableCell>
                                                {associado.data_contrato ? associado.data_contrato : 'N/A'}
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={associado.defensivos === 1}
                                                    onChange={(e) => onDefensivosChange(associado.id_associado, e.target.checked)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => onDelete(associado.id_associado)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> : null}
                    </div>
                </div>
            </div> : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    )
}


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 100,
    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    noBorder: {
        border: "none",
    },
    autoCompleteFieldSmall: {
        marginTop: 15,
        minWidth: 104,
        maxWidth: 104,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteField: {
        marginTop: 15,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 15,
        width: 471,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    inputCheckbox: {

        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000'
    },
    inputh1: {
        fontFamily: 'Poppins',

        color: '#383838'
    },
    root: {
        "&$checked": {
            color: "black",
            backgroundColor: 'white'
        }
    },
    checked: {
        borderColor: 'black'
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
    calcDiv: {
        marginTop: 40,
        width: '146px',
        marginLeft: 325,
        height: 45
    },
    modDiv: {
        marginTop: 40,
        width: '146px',

        height: 45
    },
    nutriDiv: {
        paddingRight: 28,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 228
    },
    inputForm: {
        width: '50%',
        fontFamily: 'Poppins',
        color: '#383838'
    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    }, table: {
        minWidth: 400,
        maxWidth: '100%'
    },
    rowSize: {
        maxWidth: 500
    },
    tableModels: {
        marginTop: 20,
        maxWidth: '90%',
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
        , overflow: 'auto', height: 450
    },
}));

export default ConfigGrupos;
