import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import html2canvas from 'html2canvas';
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from '@material-ui/icons/Edit';
import EditComponent from "./EditComponent";

export default function Socket({ children, titulo, locked, layoutItem, onRemoveComponent }) {
    const classes = useStyles();
    const [isHovered, setIsHovered] = useState(false);
    const [editComponent, setEditComponent] = useState(false)
    const socketRef = useRef(null);
    const contentRef = useRef(null);

    async function handleCopyToClipboard() {
        const ClipboardItem = window.ClipboardItem;

        if (!contentRef.current) {
            console.error('Content component not found');
            return;
        }

        const socketContent = contentRef.current;

        const canvas = await html2canvas(socketContent);

        canvas.toBlob(blob => {
            const item = new ClipboardItem({
                'image/png': blob
            });
            navigator.clipboard.write([item]).catch(err => {
                console.error('Error copying image: ', err);
            });
        }, 'image/png');
    }

    return (
        <div
            ref={socketRef}
            className={classes.mainWrapper}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className={classes.bar} style={{ cursor: locked ? 'move' : '' }} id="myDragHandle">
                {titulo || <div className={classes.spacer}></div>}

                {!locked && (isHovered &&
                    <div style={{ height: 22, display: 'flex', alignItems: 'center', zIndex: 1000 }}>
                        <Tooltip title="Copiar" arrow>
                            <button className={classes.copyButton} onClick={handleCopyToClipboard}>
                                <FileCopyIcon />
                            </button>
                        </Tooltip>
                        <Tooltip title="Deletar">
                            <CloseRoundedIcon
                                onClick={() => onRemoveComponent(layoutItem.i)}
                                className={classes.icon} />
                        </Tooltip>
                    </div>)}

                {locked && (isHovered &&
                    <div style={{ height: 22, display: 'flex', alignItems: 'center', zIndex: 1000 }}>
                        <Tooltip title="Editar">
                            <EditIcon
                                onClick={() => setEditComponent(true)}
                                className={classes.icon} style={{ fontSize: 18 }} />
                        </Tooltip>

                        <span style={{ marginLeft: 2, marginRight: 2 }}></span>

                        <Tooltip title="Deletar">
                            <CloseRoundedIcon
                                onClick={() => onRemoveComponent(layoutItem.i)}
                                className={classes.icon} />
                        </Tooltip>
                    </div>
                )}
            </div>

            <div className={classes.contentWrapper} ref={contentRef}>
                <div className={classes.content}>{children}</div>
            </div>

            {editComponent && <EditComponent open={editComponent} onClose={() => setEditComponent(false)} layoutItem={layoutItem} />}
        </div>
    );
}

const useStyles = makeStyles({
    mainWrapper: {
        background: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        border: '2px solid transparent',
        boxSizing: 'border-box',
        borderImageSlice: 1,
        borderImageSource: `radial-gradient(75% 75% at 50% 50%, #FFFFFFFF 62%, #a3cc51 82%)`
    },

    contentWrapper: {
        overflow: 'auto',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        background: '#fffff',
        padding: 8,
        border: '1px solid transparent',
    },

    spacer: {
        flex: 1,
    },

    content: {
        width: '100%',
        height: '100%',
        background: 'white'
    },

    bar: {
        background: 'linear-gradient(356deg, rgba(162,217,138,0.48082983193277307) 16%, rgba(151,243,255,0.1026785714285714) 58%)',
        minHeight: 22,
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid lightgray',
        padding: 5,
        fontFamily: 'Poppins',
        fontSize: 14,
        userSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
    },

    icon: {
        color: '#747474',
        fontSize: 22,
        cursor: 'pointer',
        '&:hover': {
            color: '#383838'
        },
        zIndex: 1000
    },

    copyButton: {
        color: '#747474',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '20%',
        transition: 'background 0.3s ease',
        '& svg': {
            fontSize: '1rem',
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: '0 0 0 2px rgba(0, 120, 255, 0.5)',
        }
    }
})