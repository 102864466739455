import React, { useEffect, useState } from 'react';
import api_amr from 'services/api_amr';
import { makeStyles } from "@material-ui/core/styles";
import { getToken } from 'utils/utils';
import LoaderGif from '../../../assets/img/amr_loader_transparent.gif';
import circleFlags from '../../../assets/img/UsdBrlCircleFlag.png'

const useStyles = makeStyles((theme) => ({
  loader: {
    width: "70%",
    height: "auto",
    borderRadius: "10px",
    opacity: 0.9
  },

  mainWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: 'white',
    justifyContent: 'center'
  }
}))

export default function DolarComponent() {
  const classes = useStyles();
  const [dolar, setDolar] = useState('');
  const dolarValue = parseFloat(dolar.valor).toFixed(3);
  const dolarVariation = parseFloat(dolar.variacao).toFixed(3);
  const isPositiveVariation = parseFloat(dolar.variacao) >= 0;

  useEffect(() => {
    const fetchMyApi = async () => {
      const response = await api_amr.get('/return_component_dolar', {
        headers: { 'x-api-key': getToken() }
      });
      setDolar(response.data);
    }

    fetchMyApi()
  }, [])

  if (!dolar) {
    return (
      <div className={classes.loader}>
        <img src={LoaderGif} alt="Loading..." className={classes.loader} />
      </div>
    )
  }

  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet" className={classes.mainWrapper}>
      <image href={circleFlags} x="0" y="0" height="100%" width="25%" />
      <text x="30%" y="20%" dominantBaseline="alphabetic" textAnchor="start" fontSize="15">Dólar/Real</text>
      <text x="30%" y="40%" dominantBaseline="alphabetic" textAnchor="start" fontSize="15">USDBRL</text>
      <text x="30%" y="60%" dominantBaseline="alphabetic" textAnchor="start" fontSize="15" fill={isPositiveVariation ? '#A3CC51' : 'red'}>
        {isPositiveVariation ? `+${dolarVariation}` : dolarVariation}
      </text>
      <text x="30%" y="80%" dominantBaseline="alphabetic" textAnchor="start" fontSize="20">{dolarValue}</text>
    </svg>
  )
}