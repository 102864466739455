import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getToken, checarPermissao } from '../../utils/utils'
import Checkbox from '@material-ui/core/Checkbox';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../services/api';
import api_amr from '../../services/api_amr';

import { CircleFlag } from 'react-circle-flags'
import { Chart } from 'react-google-charts';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import { Autocomplete } from "@material-ui/lab";

import Button from "@material-ui/core/Button";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';



import { TextField } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

const LoadingIndicator = (props) => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        promiseInProgress && (
            <div
                style={{
                    width: '100%',
                    height: '100',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
            </div>
        )
    );
};

function ListaDePrecos(props) {
    const [permitido, setPermitido] = useState(false)
    const [nomeListaCadastro, setNomeListaCadastro] = useState('')
    const [listaPrecos, setListaPrecos] = useState('')
    const [listasArray, setListasArray] = useState([])
    const [formulasLista, setFormulasLista] = useState('')
    const [openCol, setOpenCol] = useState([])
    const [fechamentos, setFechamentos] = useState('')
    const [listAdd, setListAdd] = useState('')
    const [N_, setN_] = useState(0)
    const [P2O5_, setP2O5_] = useState(0)
    const [K2O_, setK2O_] = useState(0)
    //retorna formulas
    const [dustrol, setDustrol] = useState(false);
    const [inibidor, setInibidor] = useState(false);
    const [porto, setPorto] = useState('')
    const [portos, setPortos] = useState('')
    const [dolar, setDolar] = useState('')
    const [frete, setFrete] = useState(0)
    const [N, setN] = useState(0)
    const [P2O5, setP2O5] = useState(0)
    const [K2O, setK2O] = useState(0)
    const [jurousd, setJurousd] = useState(0)
    const [jurobr, setJurobr] = useState(0)
    const [vencimento, setVencimento] = useState((new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).toLocaleDateString('pt-BR'))
    const [pref, setPref] = useState([]);
    const [rem, setRem] = useState([]);
    const [micro, setMicro] = useState([]);
    const [materias, setMaterias] = useState('');
    const [micronutri, setMicronutri] = useState('');
    const [lastUpdate, setLastUpdate] = useState('');
    const [formulas, setFormulas] = useState('');
    const classes = useStyles();

    useEffect(() => {
        async function fetchMyAPI() {
            setPermitido(await checarPermissao('lista_de_precos', props))
            retornaListas()
            const response_materias = await api_amr.get('/return_indice_materias', { headers: { 'x-api-key': getToken() } });
            setMaterias(response_materias.data);


            const response_return_portos = await api_amr.get('/return_portos', { headers: { 'x-api-key': getToken() } });
            setPortos(response_return_portos.data);

            const respose_dolar_hora = await api.get('/DolarApi');

            setDolar(respose_dolar_hora.data)

            const respose_update = await api_amr.get('/return_last_update', { headers: { 'x-api-key': getToken() } });
            setLastUpdate(respose_update.data);

            const response_micronutri = await api_amr.get('/return_micro_nutrientes', { headers: { 'x-api-key': getToken() } });
            setMicronutri(response_micronutri.data);

        }
        fetchMyAPI();
    }, []);


    async function retornaListas() {
        const response_listas = await api_amr.get('/get_lista_precos', { headers: { 'x-api-key': getToken() } })
        console.log(response_listas.data)
        setListasArray(Array.from({ length: response_listas.data.length }, i => i = false))
        setListaPrecos(response_listas.data)
    }


    async function alterSelected(index) {
        let aux = Array.from({ length: listasArray.length }, i => i = false)
        aux[index] = true
        setListasArray(JSON.parse(JSON.stringify(aux)))
        getFormulacoes(index) // Puxa formulações e fechamento dessa lista de preço
    }

    async function alterSelectedFormulacao(index) {
        let aux = Array.from({ length: openCol.length }, i => i = false)
        aux[index] = true
        setOpenCol(JSON.parse(JSON.stringify(aux)))
        getFechamentos(index)
    }

    async function getFormulacoes(index) {
        const response_formulacoes = await api_amr.get('/get_lista_formulacoes', { params: { 'id_lista': listaPrecos[index].id }, headers: { 'x-api-key': getToken() } })
        setFormulasLista(response_formulacoes.data)
        setOpenCol(Array.from({ length: response_formulacoes.data.length }, i => i = false))

    }

    async function getFormulacoes2(id) {
        const response_formulacoes = await api_amr.get('/get_lista_formulacoes', { params: { 'id_lista': id }, headers: { 'x-api-key': getToken() } })
        setFormulasLista(response_formulacoes.data)

    }

    async function getFechamentos(index) {
        const response_fechamentos = await api_amr.get('/get_fechamentos_formulacoes', { params: { 'id_formulacao': formulasLista[index].id_formulacao }, headers: { 'x-api-key': getToken() } })
        setFechamentos(response_fechamentos.data)

    }
    async function cadastraListaDePreco() {
        const respose_cadastra_preco = await api_amr.post('/cadastra_lista_precos', { nomeListaCadastro }, { headers: { 'x-api-key': getToken() } })
        if (respose_cadastra_preco.data === 'OK') {
            alert('Lista cadastrada')
            window.location.reload()
        }
    }

    async function deletaLista(id) {
        const response_edit_lista_preco = await api_amr.post('/deleta_lista', {
            'id_lista': id
        }, { headers: { 'x-api-key': getToken() } })

        if (response_edit_lista_preco.data === 'OK') {
            alert('Removida com sucesso')
            retornaListas()
        }
        else {
            alert('Erro ao remover lista')
            return
        }
    }

    async function deletaFormulacao(id) {
        const response_edit_lista_preco = await api_amr.post('/deleta_formulacao', {
            'id_lista': id
        }, { headers: { 'x-api-key': getToken() } })

        if ((response_edit_lista_preco.data === 'OK')) {
            alert('Removida com sucesso')
            getFormulacoes2(id)
        }
        else {
            alert('Erro ao remover formulação')
            return
        }

    }

    // parte do retorna formulas
    async function getFormulas() {
        setFormulas('')
        let auxPref = [];
        let auxRem = [];
        let auxMicro = [];
        pref.forEach((index) => (auxPref = [...auxPref, index.id]));
        rem.forEach((index) => (auxRem = [...auxRem, index.id]));
        Object.keys(micro).map((index) => (auxMicro = [...auxMicro, index + ':' + micro[index]])); // funciona com object.keys mas com foreach não, vai sbaer o pq dessa bosta


        if (N === 0 && P2O5 === 0 && K2O === 0) {
            alert('Selecione os nutrientes!');
        } else if (porto === '') {
            alert('Selecione o porto de Origem!');
        } else {

            const response_formulas = await api_amr.get('/returnFormulas', {
                params: {
                    nitrogenio: N,
                    p2o5: P2O5,
                    k2o: K2O,
                    'porto': porto.id,
                    dolar: dolar,
                    frete: frete,
                    pref: auxPref.toString(),
                    rem: auxRem.toString(),
                    add_micro: auxMicro.join(['-']),
                    dustrol: dustrol.toString(),
                    inibidor: inibidor.toString(),
                    'valorJuroUSD': jurousd === '' ? 0.0 : jurousd,
                    'valorJuroBR': jurobr === '' ? 0.0 : jurobr,
                    'dataVencimento': vencimento
                }, headers: { 'x-api-key': getToken() }
            });

            setFormulas('');

            if (response_formulas.data === 'NOK') {
                alert('Não foi possível gerar formulas com essas configurações!');
            } else {
                setFormulas(response_formulas.data);
                setK2O_(K2O)
                setN_(N)
                setP2O5_(P2O5)
            }
        }

    }

    function montaMicro(item, val) {
        let aux = micro;
        if (val >= 50) {

            aux[item] = val;
            setMicro(aux);
        } else {
            aux[item] = 0;
            setMicro(aux);
        }

    }
    console.log(micro)

    function montaArray(formulas_item) {
        let array_formulas = [["Produto", "Kg"]]
        Object.keys(formulas_item).map((item_produto, index_produto) =>
            (item_produto.includes('_peso') & formulas_item[item_produto] !== 0) ?
                array_formulas.push([((item_produto.split('#')[0] !== item_produto ? item_produto.split('#')[1] : item_produto.split('_')[0]).split('_')[0]), formulas_item[item_produto]])
                : null)
        return array_formulas
    }

    async function insereFormulacaoListaPreco(index_formulas) {
        let listId = listaPrecos.filter(lista => lista.nome_lista == listAdd)
        let aux_dados = []
        Object.keys(formulas[index_formulas]).map((item_produto, index_produto) =>
            (item_produto.includes('_peso') & formulas[index_formulas][item_produto] !== 0) ?
                aux_dados.push(formulas[index_formulas][item_produto] + '-' + item_produto.split('_')[0]) : null
        )
        console.log({ aux_dados })
        const response_cadastra_fechamento = await api_amr.post('/insert_fechamento_formulacao', { N_, P2O5_, K2O_, 'fechamento': aux_dados.toString(), 'listId': listId[0].id }, { headers: { 'x-api-key': getToken() } })
        if (response_cadastra_fechamento.data === 'OK') {
            alert('Fechamento cadastrado com sucesso')

        }
    }


    return (
        permitido ?
            <div className={classes.container} >
                <div >
                    <h2 className={classes.inputh1}>Lista de preços</h2>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: window.innerWidth - 100 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 200 }}>
                        <b className={classes.inputh1}>Cadastro de lista de preços</b>
                        <div>
                            <TextField
                                key='nome_lista'
                                label="Nome da lista de preços"
                                variant='outlined'
                                onChange={(event) => setNomeListaCadastro(event.target.value)}
                                value={nomeListaCadastro}
                                className={classes.autoCompleteField}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: { notchedOutline: classes.noBorder }
                                }}
                            />

                        </div>
                        <Button onClick={() => cadastraListaDePreco()} className={classes.calcButton}>Cadastrar lista</Button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', height: 600 }}>
                        <div >
                            {listaPrecos !== '' ? <TableContainer className={classes.tableModelsSmall}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={50} align='center'>
                                                <b className={classes.inputh1}>Id</b>
                                            </TableCell>
                                            <TableCell width={70} align='center'>
                                                <b className={classes.inputh1}>Nome Lista</b>
                                            </TableCell>
                                            <TableCell width={70} align='center'>
                                                <DeleteIcon />
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(listaPrecos).map((item, index) => (
                                            <TableRow key={index} style={{ backgroundColor: listasArray[index] === true ? '#e9f5d6' : 'white' }} >
                                                <TableCell style={{ display: 'flex', flexDirection: 'row' }} align='center'>
                                                    <p>{listaPrecos[item].id}</p>
                                                    {listasArray[index] === true ? <IconButton
                                                        style={{ color: '#A3CC51' }}

                                                        onClick={() => alterSelected(index)}
                                                    >
                                                        < CheckCircleOutlineIcon />
                                                    </IconButton> : <IconButton

                                                        onClick={() => alterSelected(index)}

                                                    >
                                                        <RadioButtonUncheckedIcon />
                                                    </IconButton>}


                                                </TableCell >
                                                <TableCell align='center'>
                                                    <b className={classes.inputh1}>{listaPrecos[item].nome_lista}</b>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <Button onClick={() => deletaLista(listaPrecos[item].id)} className={classes.calcButton}>Remover lista</Button>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> : <CircularProgress />}
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '60%', height: '100%' }}>
                        <div >
                            {formulasLista !== '' ? <TableContainer className={classes.tableModels}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={50} align='center'>

                                            </TableCell>
                                            <TableCell width={70} align='center'>
                                                <b className={classes.inputh1}>Nitrogênio</b>
                                            </TableCell>
                                            <TableCell width={70} align='center'>
                                                <b className={classes.inputh1}>Fósforo</b>
                                            </TableCell>
                                            <TableCell width={70} align='center'>
                                                <b className={classes.inputh1}> Potássio</b>
                                            </TableCell>
                                            <TableCell width={70} align='center'>
                                                <DeleteIcon />
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(formulasLista).map((item, index) => (
                                            <React.Fragment key={index}>
                                                <TableRow key={index} style={{ backgroundColor: openCol[item] === true ? '#e9f5d6' : 'white' }}>

                                                    <TableCell width={50} align='center'>
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => alterSelectedFormulacao(item)}
                                                        >
                                                            {openCol[item] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                        </IconButton>
                                                    </TableCell>

                                                    <TableCell align='center'>
                                                        <b className={classes.inputh1}>{formulasLista[item].nitrogenio}</b>
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <b className={classes.inputh1}> {formulasLista[item].fosforo}</b>
                                                    </TableCell>
                                                    <TableCell align='center' >
                                                        <b className={classes.inputh1}>{formulasLista[item].potassio}</b>
                                                    </TableCell>
                                                    <TableCell align='center' >
                                                        <Button onClick={() => deletaFormulacao(formulasLista[item].id_formulacao)} className={classes.calcButton}>Remover</Button>
                                                    </TableCell>

                                                </TableRow>
                                                <TableRow >
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Collapse in={openCol[item]} timeout="auto" unmountOnExit>
                                                            <Box sx={{ margin: 1 }}>
                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    <b className={classes.inputh1}>Fechamentos Cadastrados</b>
                                                                </Typography>
                                                                {Object.keys(fechamentos).map((item, index) => (

                                                                    <Table aria-label="purchases" key={item}>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                {Object.keys(JSON.parse(fechamentos[item])).map((item2, index2) => (
                                                                                    <TableCell><b className={classes.inputh1}>{JSON.parse(fechamentos[item])[item2].abreviacao}</b></TableCell>

                                                                                ))}
                                                                                <TableCell align="right">
                                                                                    <b className={classes.inputh1}> Custo Total</b>
                                                                                </TableCell>
                                                                            </TableRow>

                                                                        </TableHead>
                                                                        <TableBody>

                                                                            <TableRow >
                                                                                {Object.keys(JSON.parse(fechamentos[item])).map((item2, index2) => (
                                                                                    <TableCell><b className={classes.inputh1}>{JSON.parse(fechamentos[item])[item2].quantidade} kg</b></TableCell>

                                                                                ))}
                                                                                <TableCell align="right"><b className={classes.inputh1}>{JSON.parse(fechamentos[item])[0].custo_total} U$</b></TableCell>
                                                                            </TableRow>

                                                                        </TableBody>
                                                                    </Table>
                                                                ))}

                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>

                                </Table>
                            </TableContainer> : <CircularProgress />}
                        </div>
                    </div>
                </div>
                <div className={classes.container} style={{ marginTop: 40 }}>
                    <div >
                        <h2 className={classes.inputh1}>Cadastrar fechamentos na lista de preços</h2>

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {/* Inputs esquerda */}
                        <div>
                            <b className={classes.input} >Matérias-primas atualizadas em: {lastUpdate}</b>
                            <div className={classes.inputsDiv} >{/*Margin top 10 para ajeitar o primiero elemento */}
                                <div style={{ paddingRight: 28 }} >

                                    <div >
                                        <Tooltip title="Porto" placement="top-end"   >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Porto:</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <Autocomplete

                                        options={portos}
                                        getOptionLabel={(option) => option.id + '-' + option.nome_porto + '-' + option.cidade + '/' + option.UF}
                                        className={classes.autoCompleteField}
                                        onChange={(e, v) => setPorto(v)}

                                        classes={{
                                            input: classes.input,

                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            placeholder="Selecionar Porto"
                                            variant='outlined'
                                            value={porto}
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                classes: { notchedOutline: classes.noBorder }
                                            }}

                                        />}
                                    />

                                </div>
                                <div style={{ paddingRight: 28 }} >

                                    <div >
                                        <Tooltip title="Valor do dolar em R$ a ser utilizado para calculo do valor final em Reais" placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Dólar:</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>

                                    <TextField
                                        placeholder="Dolar $"
                                        variant='outlined'
                                        onChange={(event) => setDolar(event.target.value)}
                                        value={dolar}
                                        className={classes.autoCompleteField}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}
                                    />
                                </div>
                                <div style={{ paddingRight: 28 }} >

                                    <div >
                                        <Tooltip title="Valor em R$ do frete, da origem do produto até o destino final" placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Frete:</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        placeholder="Valor Frete R$"
                                        variant='outlined'
                                        onChange={(event) => setFrete(event.target.value)}
                                        className={classes.autoCompleteField}
                                        value={frete}
                                        InputProps={{

                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />


                                </div>
                            </div>
                            <div className={classes.inputsDiv}>
                                <div style={{ paddingRight: 28 }} >
                                    <div >
                                        <Tooltip title="Quantidade de Nitrogênio da fórmula a ser calculada seguindo o modelo N-P-K, por exemplo: em um produto 15-12-10 o nitrôgenio seria 15" placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Nitrogênio (N)</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        className={classes.autoCompleteField}
                                        placeholder="Digite..."
                                        variant='outlined'
                                        onChange={(event) => setN(event.target.value)}
                                        value={N}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />


                                </div>
                                <div style={{ paddingRight: 28 }} >
                                    <div >
                                        <Tooltip title="Quantidade de Fósforo (P2O5) da fórmula a ser calculada seguindo o modelo N-P-K, por exemplo: em um produto 15-12-10 o Fósforo seria 12" placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Fósforo (P2O5)</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        className={classes.autoCompleteField}
                                        placeholder="Digite..."
                                        variant='outlined'
                                        value={P2O5}
                                        onChange={(event) => setP2O5(event.target.value)}
                                        InputProps={{

                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />

                                </div>
                                <div style={{ paddingRight: 28 }} >

                                    <div >
                                        <Tooltip title="Quantidade de Potássio (K2O) da fórmula a ser calculada seguindo o modelo N-P-K, por exemplo: em um produto 15-12-10 o Potássio seria 10" placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Potássio (K2O)</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        className={classes.autoCompleteField}
                                        placeholder="Digite..."
                                        variant='outlined'
                                        value={K2O}
                                        onChange={(event) => setK2O(event.target.value)}
                                        InputProps={{

                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />
                                </div>
                            </div>
                            <div className={classes.inputsDiv}>
                                <div style={{ paddingRight: 28 }} >

                                    <div >
                                        <Tooltip title="Juro USD" placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Juro USD</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>

                                    <TextField
                                        className={classes.autoCompleteField}
                                        placeholder="Digite..."
                                        variant='outlined'
                                        onChange={(event) => setJurousd(event.target.value)}
                                        value={jurousd}
                                        InputProps={{

                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />

                                </div>
                                <div style={{ paddingRight: 28 }} >
                                    <div >
                                        <Tooltip title="Juro BRL" placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Juro BRL</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>

                                    <TextField
                                        className={classes.autoCompleteField}
                                        placeholder="Digite..."
                                        variant='outlined'
                                        value={jurobr}
                                        onChange={(event) => setJurobr(event.target.value)}
                                        InputProps={{

                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />


                                </div>
                                <div style={{ paddingRight: 28 }} >
                                    <div >
                                        <Tooltip title="Data de Vencimento" placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Data de Vencimento</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        className={classes.autoCompleteField}
                                        placeholder="Digite..."
                                        variant='outlined'
                                        value={vencimento}
                                        onChange={(event) => setVencimento(event.target.value)}
                                        InputProps={{

                                            disableUnderline: true,
                                            classes: { notchedOutline: classes.noBorder }
                                        }}

                                    />


                                </div>
                            </div>
                            <div className={classes.inputsDiv}>
                                <div style={{ paddingRight: 28 }} >

                                    <div >
                                        <Tooltip title="Matérias-primas que devem ser utilizadas para fechamento " placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Matérias obrigatórias nas fórmulas</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>


                                    <Autocomplete
                                        multiple

                                        getOptionLabel={(option) => option.id + '-' + option.abrev}
                                        className={classes.autoCompleteFieldBig}
                                        style={{ width: 360 }}
                                        options={materias}
                                        onChange={(e, v) => setPref(v)}

                                        classes={{
                                            input: classes.input,

                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            placeholder="Selecionar"
                                            variant='outlined'
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                classes: { notchedOutline: classes.noBorder }
                                            }}

                                        />}
                                    />

                                </div>
                                <div style={{ paddingRight: 28 }} >

                                    <div >
                                        <Tooltip title="Matérias-primas que não deve ser utilizadas no calculo de fechamento" placement="top-end" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <b className={classes.input}>Matérias para remover das fórmulas</b>
                                                <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                            </div>
                                        </Tooltip>
                                    </div>

                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option) => option.id + '-' + option.abrev}
                                        className={classes.autoCompleteFieldBig}
                                        style={{ width: 360 }}
                                        options={materias}
                                        onChange={(e, v) => setRem(v)}
                                        classes={{
                                            input: classes.input,
                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            placeholder="Selecionar"
                                            variant='outlined'
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                classes: { notchedOutline: classes.noBorder }
                                            }}

                                        />}
                                    />

                                </div>

                            </div>
                            <div className={classes.inputsDiv}>
                                <div style={{ paddingRight: 28 }} >


                                    <Checkbox
                                        checked={dustrol}
                                        onChange={() => setDustrol(!dustrol)}


                                    />
                                    <b className={classes.inputCheckbox}>Inserir Dustrol</b>
                                </div>
                                <div style={{ paddingRight: 28 }} >

                                    <Checkbox
                                        checked={inibidor}
                                        onChange={() => setInibidor(!inibidor)}
                                        classes={{
                                            root: classes.root,
                                            checked: classes.checked,
                                        }}
                                    />
                                    <b className={classes.inputCheckbox}>Inserir inibidor NBPT</b>
                                </div>
                            </div>

                        </div>
                        {/* Fim Inputs esquerda */}
                        {/* Inputs Direita */}
                        <div style={{ marginLeft: 15 }}>
                            <b className={classes.input} style={{ position: 'relative' }}>Micronutrientes</b>
                            <div style={{ fontFamily: 'Poppins', fontSize: '12px' }}><b className={classes.input}>(Valor mínimo: 50 kg)</b></div>
                            {Object.keys(micronutri.slice(0, micronutri.length / 2)).map((item, index) => (
                                <div className={classes.inputsDiv} id={item} key={item}>
                                    <div className={classes.nutriDiv} >
                                        <div >
                                            <Tooltip title="Matérias-primas que não deve ser utilizadas no calculo de fechamento" placement="top-end" >
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <b className={classes.input}>{micronutri[item * 2].descNome === 'SSP micrado - Base Micrada' ? 'BM 02-18-00+2%Zn +1%B +1%Cu +2%Mn' : micronutri[item * 2].descNome}</b>
                                                    <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                                </div>
                                            </Tooltip>
                                        </div>

                                        <TextField
                                            placeholder='0'
                                            className={classes.autoCompleteField}
                                            variant='outlined'
                                            id={micronutri[item * 2].abrev}
                                            onChange={(evt) => montaMicro(micronutri[item * 2].id, evt.target.value)}
                                            InputProps={{

                                                disableUnderline: true,
                                                classes: { notchedOutline: classes.noBorder }
                                            }}

                                        />
                                    </div>
                                    <div className={classes.nutriDiv} >
                                        <div >
                                            <Tooltip title="Matérias-primas que não deve ser utilizadas no calculo de fechamento" placement="top-end" >
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <b className={classes.input}>{micronutri[item * 2 + 1].descNome === 'SSP micrado - Base Micrada' ? 'BM 02-18-00+2%Zn +1%B +1%Cu +2%Mn' : micronutri[item * 2 + 1].descNome}</b>
                                                    <HelpOutlineIcon className={classes.toolTipIconStyle} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <TextField
                                            placeholder='0'
                                            className={classes.autoCompleteField}
                                            variant='outlined'
                                            id={micronutri[item * 2 + 1].abrev}
                                            onChange={(evt) => montaMicro(micronutri[item * 2 + 1].id, evt.target.value)}
                                            InputProps={{

                                                disableUnderline: true,
                                                classes: { notchedOutline: classes.noBorder }
                                            }}

                                        />
                                    </div>
                                </div>
                            ))}
                            <div className={classes.calcDiv}>
                                <Button onClick={() => trackPromise(getFormulas())} variant="contained" className={classes.calcButton}> Calcular</Button>
                            </div>
                        </div>
                        {/* Inputs Direita */}
                    </div>

                    {formulas !== '' ?
                        <div>
                            <div >
                                <h2 className={classes.inputh1}>Fechamentos calculados</h2>

                            </div>
                            <div>
                                {Object.keys(formulas).map((item, index) =>

                                    <div key={item} style={{ marginTop: 20, width: 1200, height: 300, borderRadius: 6, display: 'flex', flexDirection: 'row', boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                                            <Autocomplete

                                                options={listaPrecos}
                                                getOptionLabel={(option) => option.nome_lista}
                                                className={classes.autoCompleteField}
                                                onInputChange={(e, v) => setListAdd(v)}

                                                classes={{
                                                    input: classes.input,

                                                }}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    placeholder="Selecionar lista preço"
                                                    variant='outlined'

                                                    value={listAdd}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        disableUnderline: true,
                                                        classes: { notchedOutline: classes.noBorder }
                                                    }}

                                                />}
                                            />
                                            {listAdd !== '' ?
                                                <Button onClick={() => insereFormulacaoListaPreco(item)} variant="contained" className={classes.calcButton}> Adicionar à lista preço {listAdd}</Button>

                                                : null}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', overflow: 'auto' }}>

                                            <div style={{ marginLeft: 15, marginTop: 5, height: '50%' }}>
                                                <h3 className={classes.inputh1}>Matérias-primas</h3>
                                                {Object.keys(formulas[item]).map((item_produto, index_produto) =>
                                                    (item_produto.includes('_peso') & formulas[item][item_produto] !== 0) ?
                                                        <div style={{ display: 'flex', flexDirection: 'row' }} key={item_produto}>
                                                            <p className={classes.inputForm}> {item_produto.split('_')[0].split('#')[1]} </p>
                                                            <p className={classes.inputForm}>  {formulas[item][item_produto]} kg</p>
                                                        </div> : null
                                                )}

                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                            <div style={{ marginLeft: 15, marginTop: 5 }}>
                                                <h3 className={classes.inputh1}>
                                                    <b className={classes.inputh1}>Total  </b>
                                                    <CircleFlag countryCode="us" height="20" style={{ position: 'relative', top: 10, left: 0 }} />
                                                    <CircleFlag countryCode="br" height="20" style={{ position: 'relative', top: 0, right: 10 }} />
                                                </h3>

                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <b className={classes.inputForm}>  FCA (FOB PORTO) BIG BAG: </b>
                                                    <p className={classes.inputForm}>  {formulas[item]['preco_total'].toLocaleString('pt-br', { style: 'currency', currency: 'USD' })} - {(formulas[item]['preco_total_brl']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        : <LoadingIndicator />}

                </div>
            </div > : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 100,
    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    noBorder: {
        border: "none",
    },
    autoCompleteFieldSmall: {
        marginTop: 15,
        minWidth: 104,
        maxWidth: 104,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteField: {
        marginTop: 15,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 15,
        width: 471,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    inputCheckbox: {

        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000'
    },
    inputh1: {
        fontFamily: 'Poppins',

        color: '#383838'
    },
    root: {
        "&$checked": {
            color: "black",
            backgroundColor: 'white'
        }
    },
    checked: {
        borderColor: 'black'
    },
    calcButton: {
        fontFamily: 'Poppins',
        maxHeight: 70,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        marginTop: 15,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
    calcDiv: {
        marginTop: 40,
        width: '146px',
        marginLeft: 325,
        height: 45
    },
    nutriDiv: {
        paddingRight: 28,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 228
    },
    inputForm: {
        width: '50%',
        fontFamily: 'Poppins',
        color: '#383838'
    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    }, table: {
        minWidth: 400,
        maxWidth: '100%'
    },
    rowSize: {
        maxWidth: 500
    },
    tableModels: {
        marginTop: 20,
        maxWidth: '100%',
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)',
        maxHeight: 1000,
        overflow: 'auto'
    },
    tableModelsSmall: {
        marginTop: 20,
        maxWidth: '100%',
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)',
        maxHeight: 400,
        overflow: 'auto'
    },
}));

export default ListaDePrecos;
