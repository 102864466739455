import React, { useState, useEffect } from "react";
import styles from "../styles";
import { Grid } from '@material-ui/core';
import TopPosts from "../components/TopPosts";
import NewsListDisplay from "../components/NewsListDisplay";
import TabelaDePrecos from "../components/TabelaDePrecos";
import LoaderGif from '../../../assets/img/loader_gif.gif'
export default function LandingPage(props) {
    const classes = styles()
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setShowLoader(false);
        }, 1200);
    }, []);

    return (
        <div className={classes.root} >
            {showLoader && (
                <div className={classes.loaderOverlay}>
                    <img src={LoaderGif} alt="Loading..." className={classes.loader} />
                </div>
            )}
            <Grid container >
                <Grid item xs={12} md={7} lg={10} xl={10} style={{ borderRight: '1px solid lightgray' }}>
                    <Grid container >
                        <Grid item xs={12} >
                            <TopPosts />
                        </Grid>

                        <Grid item xs={12} >
                            <NewsListDisplay />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={5} lg={2} xl={2} >
                    <TabelaDePrecos {...props} />
                </Grid>
            </Grid>
        </div>
    )
}
