import {
  drawerWidth,
  transition,
  container
} from "assets/jss/material-dashboard-react.js";

const appStyle = () => ({
  mainPanel: {
    height: '100%',
    width: `calc(100% - ${drawerWidth - 10}px)`,
    overflow: "hidden",
    float: "right",
    ...transition,
  },

  mainPanel2: {
    height: '100%',
    width: `calc(100% - ${80}px)`,
    overflow: "hidden",
    float: "right",
    ...transition,
  },

  content: {
    width: '100%',
    height: '100%',
  },

  container,
  map: {
    marginTop: "70px"
  }
});

export default appStyle;
