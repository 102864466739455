import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import moment from "moment";

export default function GraficoPrecos({ dados }) {
  const chartRef = useRef(null);
  const [materiaSelecionada, setMateriaSelecionada] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [eixoY, setEixoY] = useState('cfr_eqv');

  useEffect(() => {
    if (dados && Object.keys(dados).length > 0 && materiaSelecionada) {
      // Filtrar os dados pela matéria selecionada
      const dadosMateria = dados[materiaSelecionada]
        .filter(item => {
          const data = moment(item.data, "ddd, DD MMM YYYY HH:mm:ss [GMT]"); // Corrigindo o parsing da data
          return (
            (!dataInicio || data.isSameOrAfter(moment(dataInicio))) &&
            (!dataFim || data.isSameOrBefore(moment(dataFim)))
          );
        });

      if (dadosMateria.length > 0) {
        // Inicializando o gráfico apenas se os dados existirem
        const chartInstance = echarts.init(chartRef.current);

        // Configurações do gráfico de dispersão (scatter plot)
        const options = {
          title: {
            text: `Preços da Matéria: ${materiaSelecionada}`,
          },
          tooltip: {
            trigger: "item",
            formatter: function (params) {
              const data = params.data;
              return eixoY === 'cfr_eqv'
                ? `Data: ${data[0]}<br/>CFR EQV: ${data[1]}<br/>Margem: ${data[2]}`
                : `Data: ${data[0]}<br/>Margem: ${data[1]}<br/>CFR EQV: ${data[2]}`;
            }
          },
          xAxis: {
            type: "category",
            name: "Data",
            data: [...new Set(dadosMateria.map(item => formatarData(item.data)))], // Eixo x com datas únicas
            axisLabel: {
              formatter: (value) => value,
            },
          },
          yAxis: {
            type: "value",
            name: eixoY === 'cfr_eqv' ? "CFR EQV" : "Margem",
          },
          series: [
            {
              name: "Preços",
              type: "scatter",
              data: dadosMateria.map(item => 
                eixoY === 'cfr_eqv'
                  ? [formatarData(item.data), item.cfr_eqv, item.margem]
                  : [formatarData(item.data), item.margem, item.cfr_eqv]
              ),
              symbolSize: 10,
            },
          ],
        };

        // Configurando as opções no gráfico
        chartInstance.setOption(options);

        // Ajustando para redimensionamento
        const handleResize = () => {
          if (chartInstance) {
            chartInstance.resize();
          }
        };
        window.addEventListener("resize", handleResize);

        // Cleanup para evitar vazamentos de memória
        return () => {
          window.removeEventListener("resize", handleResize);
          chartInstance.dispose();
        };
      }
    }
  }, [dados, materiaSelecionada, dataInicio, dataFim, eixoY]);

  useEffect(() => {
    if (dados && Object.keys(dados).length > 0) {
      setMateriaSelecionada(Object.keys(dados)[0]);    
    }
  }, [dados]);

  // Lista de matérias disponíveis para seleção
  const materiasDisponiveis = Object.keys(dados);

  // Função para formatar a data no formato "dd/MM/yyyy"
  const formatarData = (data) => {
    return moment(data, "ddd, DD MMM YYYY HH:mm:ss [GMT]").format("DD/MM/YYYY");
  };

  return (
    <div style={{background:'white', padding:'20px', border:'1px solid green', borderRadius:'5px'}}>
      <div style={{ marginBottom: "20px", }}>
        {/* Seletor de Matéria */}
        <label>
          Matéria:
          <select value={materiaSelecionada} onChange={(e) => setMateriaSelecionada(e.target.value)}>
            <option value="">Selecione uma matéria</option>
            {materiasDisponiveis.map((materia) => (
              <option key={materia} value={materia}>
                {materia}
              </option>
            ))}
          </select>
        </label>

        {/* Filtro de Data */}
        <label style={{ marginLeft: "20px" }}>
          Data Início:
          <input
            type="date"
            value={dataInicio}
            onChange={(e) => setDataInicio(e.target.value)}
          />
        </label>
        <label style={{ marginLeft: "20px" }}>
          Data Fim:
          <input
            type="date"
            value={dataFim}
            onChange={(e) => setDataFim(e.target.value)}
          />
        </label>

        {/* Seletor de Eixo Y */}
        <label style={{ marginLeft: "20px" }}>
          Visualizar por:
          <select value={eixoY} onChange={(e) => setEixoY(e.target.value)}>
            <option value="cfr_eqv">CFR EQV</option>
            <option value="margem">Margem</option>
          </select>
        </label>
      </div>

      {/* Gráfico */}
      <div style={{ width: "100%", height: "500px" }}>
        <div ref={chartRef} style={{ width: "100%", height: "100%" }}></div>
      </div>
    </div>
  );
}
