import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ControleUsabilidade from '../components/ControleUsabilidade';
import MapaUsuarios from '../components/MapaUsuarios';
import UsabilidadeFormulas from '../components/UsabilidadeFormulas/UsabilidadeFormulas';
import GepetoHistoricoUsabilidade from '../components/GepetoHistoricoUsabilidade';
import GepetoHistoricoMaterias from '../components/GepetoHistoricoMaterias';


function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ margin: 20 }}>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

export default function UsabilidadeTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Grid item xs={11} className={classes.root}>
            <Tabs value={value} onChange={handleChange} style={{ margin: 20 }} component={Paper}>
                <Tab className={classes.tab}  label="Engajamento Postagens"/>
                <Tab className={classes.tab} label="Controle Usabilidade" />
                <Tab className={classes.tab} label="Usabilidade Retorna Fórmulas" />
                {/* <Tab className={classes.tab} label="Log Geral IA" /> */}
                {/* <Tab className={classes.tab} label="Histórico Matérias IA" /> */}
            </Tabs>
            <TabPanel value={value} index={0}>
                <Paper className={classes.paper}>
                    <ControleUsabilidade />
                </Paper>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Paper className={classes.paper}>
                    <MapaUsuarios />
                </Paper>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Paper className={classes.paper}>
                    <UsabilidadeFormulas />
                </Paper>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Paper className={classes.paper}>
                <GepetoHistoricoUsabilidade />
                </Paper>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Paper className={classes.paper}>
                <GepetoHistoricoMaterias />
                </Paper>
            </TabPanel>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    tab: {
        fontWeight: 'bold',
        color: 'black'
    },
}));