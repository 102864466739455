import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import api from 'services/api_amr';
import Button from "@material-ui/core/Button";
import { getToken, checarPermissao } from '../../utils/utils'
import { useLocation, useHistory } from 'react-router-dom';

const New = (props) => {
    const [text, setText] = useState([])
    const [news, setNews] = useState([])
    const [permitido, setPermitido] = useState(false)
    const classes = useStyles()

    const history = useHistory();

    useEffect(() => {
        fetchMyApi()

    }, [])

    const fetchMyApi = async () => {
        setPermitido(await checarPermissao('news', props))
        const response = await api.get('/get_news_text', { params: { 'id': props.match.params.nid }, headers: { 'x-api-key': getToken() } })
        setText(response.data)
        const responseNews = await api.get('/get_new_by_id', { params: { 'id': props.match.params.nid }, headers: { 'x-api-key': getToken() } })
        setNews(responseNews.data[0])
    }

    if (permitido) {
        return (
            <div style={{ width: '88vw', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Grid container className={classes.mainDiv} direction='column' spacing={2}>
                    <img src={news.image} width='100%' />
                    <h1>
                        {news.title}
                    </h1>

                    <a href={news.link}>Link Notícia</a>


                    <div className={classes.tags}>
                        {news.tags}
                    </div>

                    <div className={classes.dateStyle}>
                        {new Date(news.extraction_date).toLocaleString('pt-br', { dateStyle: 'short', timeZone: 'UTC' })}

                    </div>

                    <div className={classes.dateStyle}>
                        {news.publication_date}

                    </div>
                    {text.map((obj, index) =>
                        <Grid key={index} item xs={12}>
                            {obj.text}
                        </Grid>)}
                    <Button className={classes.button} onClick={() => history.push({
                        pathname: "/pages/CriarPost",
                        state: { 'id': news.id, isAi: true },
                    })}>Gerar publicação</Button>

                    <Button className={classes.button} onClick={() => props.history.push({ pathname: '/News' })}>Voltar</Button>
                </Grid>
            </div>
        )
    } else {
        return null
    }

};
const useStyles = makeStyles({
    button: {

        width: '154px',
        height: '43px',
        color: 'white',
        background: '#A3CC51',
        borderRadius: '18px',
        fontWeight: 'bold',
    },
    mainDiv: {
        margin: 20,
        fontFamily: 'Poppins',
        display: 'flex',
        flexDirection: 'column',

        maxWidth: 800,
        border: '2px solid #A3CC51', /* Change this value to your desired color */
        borderRadius: '20px',
        padding: '20px',
        /* Change this value to your desired background color */
        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',
        /* Add a subtle shadow */

    },

    tags: {

        fontWeight: 'bold',
        color: 'gray',
        fontSize: 'calc(12px + 0.2vw)'

    },

    dateStyle: {
        fontSize: 'calc(9px + 0.2vw)'
    }
});

export default New;