import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { getComponentValue } from "../utils.js";
import useStyles from '../styles';
import { Link } from 'react-router-dom';
import { truncateString } from '../utils.js';


const NewsDisplay = ({ newsData }) => {
    const classes = useStyles()

    return (
        <Grid container>
            {newsData?.map((post, index) => (
                <Grid item container xs={12} key={index} className={classes.paperNews}>
                    <Grid item xs={2}>
                        <Link
                            to={{
                                pathname: `Noticia/${post.uuid}`,
                                state: { post: post },
                            }}

                            className={classes.link}>

                            <img style={{ width: '90%', borderRadius: 10, }} src={getComponentValue(post, 'cover_image') !== null ?getComponentValue(post, 'cover_image') : 'https://back.plataformaamrbi.com/files/posts/file_server/115/logo_amr2.png'} />
                        </Link>
                    </Grid>
                    <Grid item xs={10} >
                        <Link
                            to={{
                                pathname: `Noticia/${post.uuid}`,
                                state: { post: post },
                            }}
                            className={classes.link}>
                            <Typography variant="h6" className={classes.tagDisplay}>
                                {post.tag_name.toUpperCase()}
                            </Typography>
                            <Typography variant="h3" className={classes.postTitle}>
                                {getComponentValue(post, 'main_title') !== null ? truncateString(getComponentValue(post, 'main_title'), 128) : null}
                            </Typography>
                            <Typography className={classes.paragraphList}>
                                {getComponentValue(post, 'paragraph') !== null ? truncateString(getComponentValue(post, 'paragraph'), 128) : null}
                            </Typography>
                            <Grid item className={classes.postInfo}>
                                <Typography variant="subtitle2" className={classes.postInfoItem}>
                                    {post.user_name}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.postInfoItem1}>
                                    {new Date(post.post_date).toLocaleDateString('pt-BR', { month: 'long', day: 'numeric' }).split(' de ').reverse().join(' ').replace(/^\w/, c => c.toUpperCase())}
                                </Typography>

                                <Typography variant="subtitle2" className={classes.postInfoItem}>
                                    <span style={{ display: 'flex', alignItems: 'center', color: '#929292' }}>
                                        <VisibilityIcon fontSize="inherit" style={{ marginRight: 4 }} />
                                        {((post.views_counter_logged || 0) + (post.views_counter_unlogged || 0))}
                                    </span>
                                </Typography>
                                {Boolean(post.verified) && (
                                    <Typography variant="subtitle2" className={classes.postInfoItem}>
                                        <span style={{ display: 'flex', alignItems: 'center', color: '#929292' }}>
                                            <CheckRoundedIcon fontSize="inherit" style={{ marginRight: 4 }} />
                                            Verificado
                                        </span>
                                    </Typography>
                                )}
                                
                            </Grid>
                        </Link>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};


export default NewsDisplay;
